// https://github.com/floydspace/isin-validator/blob/master/src/index.ts

const COUNTRY_CODES = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, the Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, the Former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.s.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
};

const PSEUDO_COUNTRY_CODES = {
    XS: true,
    XA: true,
    XB: true,
    XC: true,
    XD: true,
    XF: true,
    QS: true,
    QT: true,
    QW: true,
    EU: true
};

const ALL_COUNTRY_CODES = { ...PSEUDO_COUNTRY_CODES, ...COUNTRY_CODES };

function calcCrossSum(input: number): number {
    let i = input;
    let qs = 0;

    do {
        qs += i % 10;
        i = Math.floor(i / 10);
    } while (i > 0);
    return qs;
}

function calculateCheckDigit(countryCode: string, NSIN: string) {
    const s = countryCode + NSIN;
    const nums: number[] = [];
    const weights: number[] = [];
    let crossSum = 0;

    for (let i = 0; i < s.length; i += 1) {
        const c = s[i];
        if (c === "0") {
            nums.push(0);
        } else if (c === "1") {
            nums.push(1);
        } else if (c === "2") {
            nums.push(2);
        } else if (c === "3") {
            nums.push(3);
        } else if (c === "4") {
            nums.push(4);
        } else if (c === "5") {
            nums.push(5);
        } else if (c === "6") {
            nums.push(6);
        } else if (c === "7") {
            nums.push(7);
        } else if (c === "8") {
            nums.push(8);
        } else if (c === "9") {
            nums.push(9);
        } else if (c === "A") {
            nums.push(1);
            nums.push(0);
        } else if (c === "B") {
            nums.push(1);
            nums.push(1);
        } else if (c === "C") {
            nums.push(1);
            nums.push(2);
        } else if (c === "D") {
            nums.push(1);
            nums.push(3);
        } else if (c === "E") {
            nums.push(1);
            nums.push(4);
        } else if (c === "F") {
            nums.push(1);
            nums.push(5);
        } else if (c === "G") {
            nums.push(1);
            nums.push(6);
        } else if (c === "H") {
            nums.push(1);
            nums.push(7);
        } else if (c === "I") {
            nums.push(1);
            nums.push(8);
        } else if (c === "J") {
            nums.push(1);
            nums.push(9);
        } else if (c === "K") {
            nums.push(2);
            nums.push(0);
        } else if (c === "L") {
            nums.push(2);
            nums.push(1);
        } else if (c === "M") {
            nums.push(2);
            nums.push(2);
        } else if (c === "N") {
            nums.push(2);
            nums.push(3);
        } else if (c === "O") {
            nums.push(2);
            nums.push(4);
        } else if (c === "P") {
            nums.push(2);
            nums.push(5);
        } else if (c === "Q") {
            nums.push(2);
            nums.push(6);
        } else if (c === "R") {
            nums.push(2);
            nums.push(7);
        } else if (c === "S") {
            nums.push(2);
            nums.push(8);
        } else if (c === "T") {
            nums.push(2);
            nums.push(9);
        } else if (c === "U") {
            nums.push(3);
            nums.push(0);
        } else if (c === "V") {
            nums.push(3);
            nums.push(1);
        } else if (c === "W") {
            nums.push(3);
            nums.push(2);
        } else if (c === "X") {
            nums.push(3);
            nums.push(3);
        } else if (c === "Y") {
            nums.push(3);
            nums.push(4);
        } else if (c === "Z") {
            nums.push(3);
            nums.push(5);
        }
    }
    for (let i = 0; i < nums.length; i += 1) {
        if (i % 2 === 0) {
            weights.push(2);
        } else {
            weights.push(1);
        }
    }
    weights.reverse();
    for (let i = 0; i < nums.length; i += 1) {
        crossSum += calcCrossSum(nums[i] * weights[i]);
    }
    const diff = 10 - (crossSum % 10);
    if (diff === 10) {
        return 0;
    }
    return diff;
}

export interface Options {
    checkCountryCode?: boolean;
    checkCheckDigit?: boolean;
}

export const validIsin = (ISIN: string, options: Options = {}): [boolean, Error] => {
    if (ISIN.length !== 12) {
        return [false, new Error("ISIN must be 12 characters long")];
    }

    if (!/^[A-Z0-9]+$/.test(ISIN)) {
        return [false, new Error("ISIN contains not only [A-Z0-9]")];
    }

    const countryCode = ISIN.substr(0, 2);
    if (!/^[A-Z]+$/.test(countryCode)) {
        return [false, new Error("Country code contains not only [A-Z]")];
    }
    if (options.checkCountryCode !== false) {
        if (!(countryCode in ALL_COUNTRY_CODES)) {
            return [false, new Error("Country code is wrong")];
        }
    }

    const NSIN = ISIN.substr(2, 9);
    if (!/^[A-Z0-9]+$/.test(NSIN)) {
        return [false, new Error("NSIN contains not only [A-Z0-9]")];
    }

    let checkDigit: string | number = ISIN[11];
    if (!/^[0-9]+$/.test(checkDigit)) {
        return [false, new Error("Check digit contains not only [0-9]")];
    }
    if (options.checkCheckDigit !== false) {
        checkDigit = parseInt(checkDigit, 10);
        if (checkDigit !== calculateCheckDigit(countryCode, NSIN)) {
            return [false, new Error("Check digit is wrong")];
        }
    }

    return [true, null];
};
