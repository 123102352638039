import { useMemo } from "react";

import { DateHelper, TimeSeries } from "../../../../common/src";

import { SelectedRowType } from "./interfaces";
import { isValidRow } from "./functions";

export interface PerformanceData {
    performanceSeries: TimeSeries;
    alignedTimeSeries: TimeSeries[];
    years: string[];
    value: number;
}

export const usePerformance = (selectedRow: SelectedRowType): PerformanceData => {
    return useMemo(() => {
        if (!isValidRow(selectedRow)) {
            return {
                performanceSeries: null,
                alignedTimeSeries: [],
                years: [],
                value: 0
            };
        }

        // TimeSeries range only handle Date...
        const start = new Date(selectedRow.startDate);
        const end = new Date(selectedRow.endDate);
        const performanceSeries = selectedRow.performance.range(start, end).normalize(100);
        const alignedTimeSeries = [performanceSeries];
        for (const benchmark of selectedRow.benchmarks) {
            // Start series.
            const dates = [benchmark.__dates[0]];
            const series = [100];
            for (let i = 1; i < benchmark.length; i++) {
                dates.push(benchmark.__dates[i]);
                // Create trading index, i.e. only get return when position is on. Do not earn return the same day position starts
                // Since we divide by previous value this cannot be 0!
                if (
                    TimeSeries.isNotNilAndFinite(benchmark.__values[i - 1]) &&
                    benchmark.__values[i - 1] &&
                    selectedRow.positionFlag[i] &&
                    selectedRow.positionFlag[i - 1]
                ) {
                    series.push((series[i - 1] * benchmark.__values[i]) / benchmark.__values[i - 1]);
                } else {
                    series.push(series[i - 1]);
                }
            }
            const tradingBenchmarkSeries = new TimeSeries(dates, series, benchmark.name);
            alignedTimeSeries.push(tradingBenchmarkSeries.range(start, end).normalize(100));
        }

        // Nav value @ endDate
        let endDateIndex = selectedRow.dateSeries.findIndex((d) => d === selectedRow.endDate);
        if (endDateIndex === -1) endDateIndex = selectedRow.dateSeries.length;
        const value = selectedRow.values[endDateIndex];

        const years = [];
        const year = DateHelper.getYear(selectedRow.endDate);
        for (let i = year; i >= year - 3; i--) {
            if (i >= DateHelper.getYear(selectedRow.performance.__dates[0])) {
                years.push(i);
            }
        }

        return {
            performanceSeries,
            alignedTimeSeries,
            years,
            value
        };
    }, [selectedRow]);
};
