import React, { Fragment } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

interface ITooltipLabel {
    text: string | React.ReactNode;
    content: string;
    delayOnShow?: number;
    className?: string;
}

export const TooltipLabel = ({ text, content, delayOnShow = 500, className = "d-block" }: ITooltipLabel): React.ReactElement => {
    const anchorId: string = "tooltip-" + Math.random().toString();
    return (
        <Fragment>
            <span className={className} id={anchorId} data-tooltip-delay-show={delayOnShow} style={{ cursor: "pointer" }}>
                {text}
            </span>
            <Tooltip anchorId={anchorId} content={content} style={{ maxWidth: "400px", whiteSpace: "pre-line" }} />
        </Fragment>
    );
};
