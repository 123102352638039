import { createContext } from "react";

export interface IClient {
    _id: string;
    name: string;
    dashName: string;
}

export const ClientSelectorPlaceHolder: IClient = {
    _id: "000000000000000000000000",
    name: "Select client",
    dashName: "Select-client"
};

export type IClientContext = {
    client: IClient;
    setClient: (client: IClient) => void;
};

const defaultClientContext: IClientContext = {
    client: ClientSelectorPlaceHolder,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setClient: (): void => {}
};

export const ClientContext = createContext<IClientContext>(defaultClientContext);

export const getLocalClientState = (): IClient => {
    return JSON.parse(localStorage.getItem("client"));
};

export const setLocalClientState = (client: IClient) => {
    localStorage.setItem("client", JSON.stringify(client));
};

export const clearLocalClientState = () => {
    localStorage.removeItem("client");
};

export const initialClientState = getLocalClientState() || ClientSelectorPlaceHolder;

export const clientReducer = (state: IClient, newState) => {
    if (state._id !== newState._id) {
        setLocalClientState(newState);
    }
    return { ...state, ...newState };
};
