import { DateHelper } from "./calendar";
import { RandomNumberGenerator } from "./RandomNumberGenerator";

const { floor, pow } = Math;
const rng = new RandomNumberGenerator(new Date().getTime().toFixed());

export class Position {
    id: string;
    name: string;
    longName: string;
    quantity: number;
    price: number;
    priceValuationDate: string;
    value: number;
    static createRandomPosition() {
        const randomwords = [
            "Alpha",
            "Bravo",
            "Charlie",
            "Delta",
            "Echo",
            "Golf",
            "Hotel",
            "Kilo",
            "Lima",
            "November",
            "Romeo",
            "Sierra",
            "Tango",
            "Uniform",
            "Victor",
            "Zulu"
        ];
        const worddict = randomwords.reduce((p, c) => {
            p[c.slice(0, 1)] = c;
            return p;
        }, {});
        const chars = Object.keys(worddict).join("");
        const randomInt = (n) => floor(n * rng.random());
        const randomHex = (n) => [...Array(n)].map(() => floor(rng.random() * 16).toString(16)).join("");
        const randomName = (n) => [...Array(n)].map(() => chars[floor(rng.random() * chars.length)]).join("");
        const randomDate = () => DateHelper.dateToString(DateHelper.addDays(new Date(), -floor(3 * rng.random())));
        const randomPrice = () => pow(10, 1 + 3 * rng.random());
        const randomSettledValue = () => (rng.random() < 0.5 ? 0 : randomPrice() * randomPrice());
        const randomSettledQuantity = () => (rng.random() < 0.5 ? 0 : randomPrice());
        const randomWord = (arr) => arr[randomInt(arr.length)];
        const randomCurrency = () => randomWord(["SEK", "EUR", "USD", "GBP"]);
        const q = randomPrice();
        const p = randomPrice();
        if (randomInt(5) === 0) {
            // Bankaccount?
            const name = "BANKACCOUNT " + randomCurrency();
            return {
                id: randomHex(24),
                name,
                longName: name,
                quantity: null,
                price: null,
                priceValuationDate: null,
                value: q * p,
                localCurrencyValue: randomSettledValue(),
                localCurrencySettledValue: randomSettledValue(),
                cashFlow: randomSettledValue(),
                unsettledIngoing: randomSettledValue(),
                unsettledOutgoing: randomSettledValue(),
                unsettledInstrumentQuantity: null,
                settledInstrumentQuantity: null,
                currency: randomCurrency(),
                accruedInterest: 0,
                productType: "CashAccount",
                category: "Interest",
                country: randomWord(["SE", "NO", "DK", "FI"]),
                type: null,
                isin: "" + (1e4 + randomInt(9e4)) + "-" + (1e4 + randomInt(9e4)),
                portfolioCurrency: randomCurrency(),
                source: null,
                fxRate: rng.random() < 0.5 ? null : pow(10, 0.5 + rng.random()),
                date: randomDate(),
                startValue: randomPrice() * randomPrice(),
                fxPriceValuationDate: rng.random() < 0.5 ? null : randomDate(),
                fxSource: rng.random() < 0.5 ? null : randomCurrency(),
                bloombergTicker: null
            } as Position;
        } else {
            const name = randomName(3);
            return {
                id: randomHex(24),
                name,
                longName: name
                    .split("")
                    .map((d) => worddict[d])
                    .join(" "),
                quantity: q,
                price: p,
                priceValuationDate: randomDate(),
                value: q * p,
                localCurrencyValue: randomSettledValue(),
                localCurrencySettledValue: randomSettledValue(),
                cashFlow: randomSettledValue(),
                unsettledIngoing: randomSettledValue(),
                unsettledOutgoing: randomSettledValue(),
                unsettledInstrumentQuantity: randomSettledQuantity(),
                settledInstrumentQuantity: randomSettledQuantity(),
                currency: randomCurrency(),
                accruedInterest: randomSettledQuantity(),
                productType: randomWord([
                    "Unknown",
                    "Account",
                    "Bond",
                    "Shares",
                    "Fund",
                    "FutureForward",
                    "Index",
                    "Swap",
                    "Option",
                    "Liability",
                    "Cash",
                    "RealEstate",
                    "System",
                    "MarginAccount",
                    "SecurityLoan"
                ]),
                category: randomWord(
                    "Unknown, Equity, Fx, System, RealEstate, Liability, HedgeFund, Alternative, Interim, Interest, Margin".split(", ")
                ),
                country: randomWord(["SE", "NO", "DK", "FI"]),
                type: null,
                isin: randomName(2) + (1e10 + randomInt(1e9)),
                portfolioCurrency: randomCurrency(),
                source: randomWord(["Bloomberg", "Reuter", "Nasdaq"]),
                fxRate: rng.random() < 0.5 ? null : pow(10, 0.5 + rng.random()),
                date: randomDate(),
                startValue: randomPrice() * randomPrice(),
                fxPriceValuationDate: rng.random() < 0.5 ? null : randomDate(),
                fxSource: rng.random() < 0.5 ? null : randomCurrency(),
                bloombergTicker: name + " " + randomWord(["SS", "DC", "FH", "NO"]) + " Equity"
            } as Position;
        }
    }
}
