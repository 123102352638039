import React from "react";
import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { Language } from "../Language";
import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface CreditRatingProps {
    data: any;
    locale?: Language;
    showHeader: boolean;
    cdsData?: any;
    cdsKey?: string;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const CreditRating = ({
    data,
    showHeader,
    config,
    layout,
    cdsData,
    cdsKey,
    locale = Language.Sv,
    ...props
}: CreditRatingProps): React.ReactElement => {
    let trace: any;
    let header = null;
    if (locale === Language.En) {
        header = "Credit Ratings";
    } else {
        if (locale === Language.Sv) {
            header = "Kreditrating";
        }
    }
    if (cdsData) {
        trace = {
            labels: data.data.cdsData[cdsKey].ratings.map((d) => d.rating),
            values: data.data.cdsData[cdsKey].ratings.map((d) => d.value),
            textinfo: "percent+label",
            textposition: "inside",
            hovertemplate: "<extra></extra>%{label}<br>%{percent}",
            font: { size: 14 },
            type: "pie",
            hole: 0.4,
            sort: false
        };
    } else {
        trace = {
            labels: data.data.ratings.map((d) => d.rating),
            values: data.data.ratings.map((d) => d.value),
            textinfo: "percent+label",
            textposition: "inside",
            hovertemplate: "<extra></extra>%{label}<br>%{percent}",
            font: { size: 14 },
            type: "pie",
            hole: 0.4,
            sort: false
        };
    }
    const color = chroma("#2186C5");
    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: [0, 0.5, -0.5, 1, -1, 1.5, -1.5, 2, -2, 2.5, -2.5].map((d) => color.darken(d).hex()),
        showlegend: false,
        margin: { t: -20, b: 0, l: 50, r: 0 }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}
            <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
