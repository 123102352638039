import React, { useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { Grid, Column } from "../../../../components/src";

import swaplab2Url from "swaplab2/dist/swaplab2.wasm?url";
import { Swaplab2Module, setSwaplab2 } from "swaplab2";

import { numberFormatFun } from "../../../../common/src";
import { Position } from "../../types.generated";
import { getResultsByInstrumentId } from "./Utils";
import { Link } from "react-router-dom";

const enum AccountModelTypeEnum {
    Account = "Account"
}

export const getData = gql`
    fragment instrumentFields on Instrument {
        _id
        name
        bloombergTicker
        modelType
        modelNotionalScaling
        issuerProgramId
        firstTradeDate
        valuations(date: $dateString) {
            currency
            date
            price
            cleanPrice
            accruedInterest
        }
        pxLast(date: $dateString) {
            date
            value
        }
    }
    fragment legFields on InstrumentModelLegsItem {
        _t
        calendars
        call
        currency
        dayAdjustment
        dayCount
        exercise
        expiry
        fixingType
        maturityDate
        maturityPeriod
        notional
        payLeg
        rate
        rateIndexId
        recoveryRate
        rollingPeriod
        spread
        startDateOffset
        strike
        underlyingId
        volatility
        cashFlows {
            endDate
            exDate
            fixings {
                endDate
                date
                rate
                startDate
            }
            notional
            payDate
            startDate
        }
    }
    fragment modelFields on InstrumentModel {
        _t
        quoteCurrency
    }
    query positions(
        $clientIdsIn: [GraphQLObjectId!]
        $dateString: GraphQLDateString
        $date: GraphQLSwedenDate
        $withMappings: Boolean!
        $withFx: Boolean!
    ) {
        positions(filter: { clientIds: $clientIdsIn, endDate: $dateString }, filterZeroPositions: true) {
            currency
            quantity
            isCashAccount
            instrument {
                ...instrumentFields
                model {
                    ...modelFields
                    legs {
                        ...legFields
                        underlyingInstrument {
                            ...instrumentFields
                            model {
                                ...modelFields
                                legs {
                                    ...legFields
                                }
                            }
                        }
                    }
                }
            }
        }
        valuationmappings(date: $dateString) @include(if: $withMappings) {
            _id
            instrumentId
            issuerProgramId
            mappingType
            modelType
            parameterType
            referenceId
            currency
            collectionName
            status
            curveDefinition {
                _id
                name
                type
                definition {
                    currency
                    dayCount
                    forwardPeriod
                    instruments {
                        instrument {
                            ...instrumentFields
                            model {
                                ...modelFields
                                legs {
                                    ...legFields
                                }
                            }
                        }
                    }
                }
            }
        }
        fxValuations(date: $date) @include(if: $withFx) {
            name
            price
        }
    }
`;

export function SwapLab2Page(): React.ReactElement {
    const [swaplab2Loaded, setSwaplab2Loaded] = useState(false);
    const [table, setTable] = useState([]);
    // See https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
    const today = new Date();
    const evaluationDate = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000).toISOString().substring(0, 10);
    const [{ fetching, error, data }] = useQuery({
        query: getData,
        variables: {
            clientIdsIn: "58e64b9523d2772e1859b705",
            dateString: evaluationDate,
            date: evaluationDate,
            withFx: true,
            withMappings: true
        }
    });

    if (!swaplab2Loaded) {
        Swaplab2Module({ locateFile: (path) => (path.endsWith(".wasm") ? swaplab2Url : path) }).then((loaded) => {
            setSwaplab2(loaded);
            setSwaplab2Loaded(true);
        });
    }

    useEffect(() => {
        if (data) {
            const tableData = data.positions.map((position: Position) => ({
                _id: position.instrument._id,
                name: position.instrument.name,
                modelType: position.instrument.modelType ? position.instrument.modelType : AccountModelTypeEnum.Account,
                currency: position.currency,
                quantity: position.quantity,
                bloombergTicker: position.instrument.bloombergTicker,
                valuationDate: position.instrument.valuations ? position.instrument.valuations.date : null,
                valuationPrice: position.instrument.valuations ? position.instrument.valuations.price : null,
                valuationCleanPrice: position.instrument.valuations ? position.instrument.valuations.cleanPrice : null,
                valuationAccruedInterest: position.instrument.valuations ? position.instrument.valuations.accruedInterest : null,
                theorPrice: null,
                theorCleanPrice: null,
                theorAccruedInterest: null
            }));
            setTable(tableData);
        }
    }, [data]);

    if (fetching) return <p>Loading</p>;
    if (!swaplab2Loaded) return <p>Loading SwapLab2</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div className="container page">
            <div className="row">
                <button
                    type="submit"
                    disabled={false}
                    className="btn btn-primary btn-sm"
                    onClick={async () => {
                        // Since we want to replicate valuations - use same date
                        const positionWithModel: Position = data.positions.find((position: Position) => position.instrument.model);
                        const evalDate = positionWithModel.instrument.valuations.date;
                        const results = getResultsByInstrumentId(data.positions, data.valuationmappings, [], evalDate);
                        const updatedTable = [];
                        for (const row of table) {
                            const newRow = { ...row };
                            if (results[newRow._id]) {
                                newRow.theorPrice = results[newRow._id].presentValue;
                                newRow.theorCleanPrice = results[newRow._id].cleanPrice;
                                newRow.theorAccruedInterest = results[newRow._id].accruedInterest;
                            }
                            updatedTable.push(newRow);
                        }
                        setTable(updatedTable);
                    }}
                >
                    Calculate
                </button>
            </div>
            <div className="row">
                <Grid header="SwapLab2 - CAPTOR IRIS BOND" data={table} sortable tableClassName="table-xs">
                    <Column
                        field="name"
                        className="grid-column-sticky nowrap maxwidth150px left"
                        format={(value, item) => {
                            return item ? (
                                <Link to={"/instruments/" + item._id} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column field="modelType" title="Model type" />
                    <Column field="currency" />
                    <Column field="quantity" />
                    <Column field="valuationDate" title="Valuation date" />
                    <Column field="valuationPrice" title="Valuation price" format={numberFormatFun("#\xa0##0.0000")} className={"right"} />
                    <Column
                        field="valuationCleanPrice"
                        title="Valuation clean price"
                        format={numberFormatFun("#\xa0##0.0000")}
                        className={"right"}
                    />
                    <Column
                        field="valuationAccruedInterest"
                        title="Valuation accrued interes"
                        format={numberFormatFun("#\xa0##0.0000")}
                        className={"right"}
                    />
                    <Column field="theorPrice" title="Theor. price" format={numberFormatFun("#\xa0##0.0000")} className={"right"} />
                    <Column
                        field="theorCleanPrice"
                        title="Theor. clean price"
                        format={numberFormatFun("#\xa0##0.0000")}
                        className={"right"}
                    />
                    <Column
                        field="theorAccruedInterest"
                        title="Theor. accrued interest"
                        format={numberFormatFun("#\xa0##0.0000")}
                        className={"right"}
                    />
                </Grid>
            </div>
        </div>
    );
}
