import React from "react";
import { Language } from "../Language";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface IInterestRateBucketsProps {
    data: any;
    locale?: Language;
    showReferencePortfolio: boolean;
    config?: PartialDeep<Plotly.Config>;
    style?: React.CSSProperties | undefined;
    layout?: Partial<Plotly.Layout>;
}

export const InterestRateBuckets = ({
    data,
    layout,
    locale = Language.Sv,
    showReferencePortfolio,
    config,
    ...props
}: IInterestRateBucketsProps): React.ReactElement => {
    let refname: string = null;
    let header: string = null;
    let explanation: string = null;
    if (locale === "En") {
        refname = "Reference portfolio";
        header = "Interest Rate Risk";
        explanation = "The graph shows the effect on fund NAV in % when shifting the underlying curve down by 0.01%.";
    } else {
        if (locale === "Sv") {
            refname = "Referensportfölj";
            header = "Ränterisk";
            explanation = "Grafen visar effekten på fondens NAV i procent vid en 0,01% skift nedåt av underliggande räntekurva.";
        }
    }
    const trace1: Partial<Plotly.PlotData> = {
        name: data.clientName,
        labels: data.data.interestRateRisks.map((d) => d.maturity.toFixed(0)),
        y: data.data.interestRateRisks.map((d) => d.value),
        type: "bar",
        line: { width: 0 },
        marker: { color: PlotlyDefaults.getColor(1) }
    };
    const c2 = PlotlyDefaults.getColor(11);
    const trace2: Partial<Plotly.PlotData> = {
        name: refname,
        labels: data.data.interestRateRisks.map((d) => d.maturity.toFixed(0)),
        y: data.data.interestRateRisks.map((d) => d.referenceValue),
        type: "scatter",
        line: { color: c2, width: 3 },
        marker: { color: c2 }
    };
    const plotlydata: PartialDeep<Plotly.PlotData>[] = showReferencePortfolio !== false ? [trace1, trace2] : [trace1];

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        xaxis: {
            showticklabels: true,
            ticktext: trace1.labels.map((item) => item.toString()),
            tickvals: trace1.labels.map((d, i) => i),
            showgrid: false
        },
        yaxis: {
            tickformat: ",.2%",
            hoverformat: ",.4%",
            tick0: 0,
            range: [0, 0.00032],
            dtick: 0.0001,
            gridwidth: 2
        },
        margin: { l: 44, r: 10, t: 4, b: 4 },
        separators: ", ",
        showlegend: true,
        legend: {
            orientation: "h",
            y: -0.2,
            traceorder: "normal"
        },
        bargap: 0.5
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }
    return (
        <React.Fragment>
            <h3>{header}</h3>
            <Plot data={plotlydata as Plotly.PlotData[]} config={thisConfig as Plotly.Config} layout={thisLayout} {...props} />
            <br />
            <small>{explanation}</small>
        </React.Fragment>
    );
};
