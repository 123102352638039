import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface IUseOfProceedsProps {
    useOfProceeds: any;
    totalExposure: number;
    layout?: Partial<Plotly.Layout>;
    config?: PartialDeep<Plotly.Config>;
    style?: React.CSSProperties | undefined;
}

export const UseOfProceeds = ({ useOfProceeds, totalExposure, layout, config, ...props }: IUseOfProceedsProps): React.ReactElement => {
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        yaxis: {
            showticklabels: true,
            automargin: true,
            ticktext: Object.keys(useOfProceeds),
            tickvals: Object.keys(useOfProceeds).map((d, i) => i),
            showgrid: false
        },
        xaxis: {
            tickformat: ".0%",
            hoverformat: ".2%"
        },
        width: 600,
        height: 350,
        margin: { t: 70 }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <Plot
            data={[
                {
                    y: Object.keys(useOfProceeds),
                    x: Object.values(useOfProceeds).map((d: any) => d / totalExposure),
                    type: "bar",
                    orientation: "h"
                }
            ]}
            layout={thisLayout}
            config={thisConfig}
            {...props}
        />
    );
};
