import { createTheme } from "@fluentui/react";

export const greenTheme = createTheme({
    defaultFontStyle: { fontFamily: "Gotham Rounded-Book", fontWeight: "regular" },
    fonts: {
        small: {
            fontSize: "12px"
        },
        medium: {
            fontSize: "13px"
        },
        large: {
            fontSize: "20px",
            fontWeight: "semibold"
        },
        xLarge: {
            fontSize: "22px",
            fontWeight: "semibold"
        }
    },
    palette: {
        themePrimary: "#2186c4", //"#44a24d" green,
        themeLighterAlt: "#f5fbf6",
        themeLighter: "#daf0dc",
        themeLight: "#bce3c0",
        themeTertiary: "#82c889",
        themeSecondary: "#55ae5e",
        themeDarkAlt: "#3e9346",
        themeDark: "#075985", // "#347c3b" green dark,
        themeDarker: "#111111",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#ccc",
        neutralPrimaryAlt: "#111", //"#3b3a39",
        neutralPrimary: "#333",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff"
    }
});
