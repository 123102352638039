import React, { Fragment, useState, ReactElement, useMemo } from "react";
import { gql, useMutation } from "urql";
import { cloneDeep } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Alert, Button } from "react-bootstrap";

import { SubmitButton, TextField, SelectField, NullableSelectField, NumberField, SearchListField } from "../../components/form";
import { CurrencyEnum, Instrument, InstrumentProductTypeEnum, InstrumentCategory, CollectionNameEnum } from "../../types.generated";
import { useAlertTimeOut } from "../../common/Utils";
import { emptyObjectId, recursivelyRemoveKey } from "../../../../common/src";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { aliasLabel, defaultAlias } from "../../components/alias";

const UPDATE_PARTY_INSTRUMENT = gql`
    mutation updateInstrument($input: UpdateInstrumentInput!) {
        updateInstrument(input: $input) {
            _id
            clientIds
        }
    }
`;

interface IPartyInstrumentForm {
    partyId: string;
    instrument: Instrument;
    externalAccounts: {
        _id: string;
        name: string;
    }[];
    accounts: {
        _id: string;
        name: string;
    }[];
    parties: {
        _id: string;
        name: string;
    }[];

    onUpdate: any;
}

const currencyOptions: {
    key: string;
    value: string;
}[] = [
    { key: null, value: "None" },
    ...Object.keys(CurrencyEnum).map((currency) => {
        return { key: currency, value: currency };
    })
];

export const PartyInstrumentForm = ({
    partyId,
    instrument,
    externalAccounts,
    accounts,
    parties,
    onUpdate
}: IPartyInstrumentForm): ReactElement => {
    const [currentInstrument] = useState(cloneDeep(instrument));
    const [_, updatePartyInstrument] = useMutation(UPDATE_PARTY_INSTRUMENT);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const aliasOptions: { _id: string; name: string }[] = useMemo(() => {
        let options = [{ _id: "000000000000000000000000", name: "None" }];
        if (parties) {
            options = [{ _id: "000000000000000000000000", name: CollectionNameEnum.None }, ...parties];
        }
        return options;
    }, [parties]);

    useAlertTimeOut(alert, setAlert, 5);

    return (
        <div id="partyinstrumentform" className="partyinstrumentform form">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={currentInstrument}
                validationSchema={Yup.object({
                    longName: Yup.string().required().min(2)
                })}
                validate={() => {
                    const errors: any = {};
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const input: any = recursivelyRemoveKey(submitValues, "__typename");

                    await updatePartyInstrument({ input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({ color: "success", visible: true, message: `The instrument has been updated successfully!` });
                                onUpdate(result.data.clientIds);
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => {
                    return (
                        <Fragment>
                            <div className="row mt-5">
                                <div className="col-sm-12">
                                    <h4>Party Instrument</h4>
                                    <Form autoComplete="off">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextField name="name" label="Name" type="text" className="" disabled={isSubmitting} />
                                            </div>
                                            <div className="col-sm-6">
                                                <TextField
                                                    name="longName"
                                                    label="Long name*"
                                                    type="text"
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <SelectField
                                                    name="accountId"
                                                    label="Account"
                                                    options={[
                                                        ...[{ key: emptyObjectId, value: "None" }],
                                                        ...accounts.map((d) => ({ key: d._id, value: d.name }))
                                                    ]}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <SelectField
                                                    name="category"
                                                    label="Category"
                                                    options={Object.keys(InstrumentCategory).sort()}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <SelectField
                                                    name="productType"
                                                    label="Product type"
                                                    options={Object.keys(InstrumentProductTypeEnum).sort()}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <SelectField
                                                    name="externalAccountId"
                                                    label="External account"
                                                    options={[
                                                        ...[{ key: emptyObjectId, value: "None" }],
                                                        ...externalAccounts.map((d) => ({ key: d._id, value: d.name }))
                                                    ]}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <TextField
                                                    name="bloombergTicker"
                                                    label="Bloomberg ticker"
                                                    type="text"
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <TextField name="bic" label="BIC" type="text" className="" disabled={isSubmitting} />
                                            </div>
                                            <div className="col-sm-4">
                                                <NumberField
                                                    name="quantityDecimals"
                                                    label="Quantity decimals"
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <TextField name="iban" label="Iban" type="text" className="" disabled={isSubmitting} />
                                            </div>
                                            <div className="col-sm-3">
                                                <NullableSelectField
                                                    name="currency"
                                                    label="Currency"
                                                    options={currencyOptions}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <TextField name="isin" label="Isin" type="text" className="" disabled={isSubmitting} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-5">
                                                <h5>Aliases</h5>
                                                <FieldArray
                                                    name="aliases"
                                                    render={(arrayHelpers) => (
                                                        <Fragment>
                                                            {values.aliases && values.aliases.length > 0 ? (
                                                                values.aliases.map((alias, index) => (
                                                                    <div key={index} className="form-group form-row">
                                                                        <div>
                                                                            <fieldset>
                                                                                <legend>{index + 1}</legend>
                                                                                <SelectField
                                                                                    name={`aliases[${index}].collection`}
                                                                                    label="Collection"
                                                                                    className="text-success"
                                                                                    options={["None", "Party"]}
                                                                                    disabled={isSubmitting}
                                                                                    size={5}
                                                                                />
                                                                                <SearchListField
                                                                                    className=""
                                                                                    name={`aliases[${index}].documentId`}
                                                                                    label={aliasLabel(alias.collection, alias.documentId)}
                                                                                    options={aliasOptions}
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    className=""
                                                                                    name={`aliases[${index}].key`}
                                                                                    label="Key"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    className=""
                                                                                    name={`aliases[${index}].value`}
                                                                                    label="Value"
                                                                                    disabled={false}
                                                                                />
                                                                                <TextField
                                                                                    className=""
                                                                                    name={`aliases[${index}].comment`}
                                                                                    label="Comment"
                                                                                    disabled={false}
                                                                                />
                                                                            </fieldset>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <Button
                                                                                className="me-1 mt-2 btn-danger btn-sm"
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                            >
                                                                                -
                                                                            </Button>
                                                                            <Button
                                                                                className="btn-sm mt-2"
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.insert(index, defaultAlias)}
                                                                            >
                                                                                +
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <Button
                                                                    className="btn-sm ms-1"
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.push(defaultAlias)}
                                                                >
                                                                    +
                                                                </Button>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {alert.visible ? (
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}

                                        <div className="d-inline-flex align-items-baseline">
                                            <SubmitButton disabled={isSubmitting} label={"Update"} />
                                            <a
                                                href={`/transactionitems?clientIds=${partyId}&instrument.name=${currentInstrument.name}`}
                                                className="ms-4"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Transactions
                                            </a>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
};
