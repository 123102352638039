import React, { ReactElement, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { cloneDeep } from "lodash";

import {
    DateHelper,
    emptyObjectId,
    addDays,
    sortTransactions,
    groupTransactionItemsByInstrument,
    getValuationInstrumentIds,
    calcPositions,
    Valuations
} from "../../../../common/src";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";

import { GeneralLedger } from "../../../../common/src/generalledger/GeneralLedger";
import { AccountingRun, AccountingRunStatusEnum, Instrument, Valuation } from "../../types.generated";
import { ConfirmAccountingRunButton } from "./ConfirmAccountingRunButton";
import { UpdateAccountingRunButton } from "./UpdateAccountingRunButton";
import { CreateAccountingRunButton } from "./CreateAccountingRunButton";
import { TAccountChartEditor } from "./TAccountChartEditor";
import { PositionsGrid } from "./PositionsGrid";
import { useAlertTimeOut } from "../../common/Utils";
import { getInstruments, getValuations, getTAccountChart } from "./queries";
import { Alert } from "react-bootstrap";

const UPDATE_ACCOUNTING_RUN = gql`
    mutation UpdateAccountingRun($input: UpdateAccountingRunInput!) {
        updateAccountingRun(input: $input) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
        }
    }
`;

const GET_MANUAL_JOURNAL_ENTRIES = gql`
    query GetManualJournalEntries($accountingRunId: GraphQLObjectId!) {
        journalEntries(accountingRunId: $accountingRunId, batch: M) {
            _id
            batch
            number
            effectiveDate
            description
            portfolioTransactionId
            clientId
            transactions {
                amount
                tAccountNumber
                type
                instrumentId
            }
        }
    }
`;

export const currentPeriod = (endDate: string): string => {
    return DateHelper.getYear(endDate).toFixed(0);
};

const openingDate = (endDate: string): string => {
    return `${currentPeriod(endDate)}-01-01`;
};

const getJournalEntries = (gl: GeneralLedger) => {
    return gl.journalEntries.map((je) => {
        const res = { ...je };
        delete res._id;
        delete res.accountingRunId;
        res.transactions = je.transactions.map((t) => {
            const res = { ...t };
            delete res.journalEntry;
            return res;
        });
        return res;
    });
};

function getTAccountMappings(data: any) {
    const result = [];
    if (data) {
        const chart = cloneDeep(data.tAccountChart);
        chart.tAccountMappings.forEach((m) => (m.tAccountChart = chart));
        result.push(...chart.tAccountMappings);
    }
    return result;
}

interface IAccountingRunEditor {
    clientId: any;
    accountingRun: AccountingRun;
    preliminaryRun: AccountingRun;
    confirmedRun: AccountingRun;
    user: any;
    previousPeriod: string;
    lastAccountingRunNumber: number;
    startDate: string;
    endDate: string;
    dataTransactions: any;
    previousJournalEntries: any[];
    masterTAccountCharts: any[];
    clientTAccountCharts: any[];
    accountingReadWriteAccess: boolean;
    onUpdated: () => void;
}

export const AccountingRunEditor = ({
    clientId,
    accountingRun,
    preliminaryRun,
    confirmedRun,
    user,
    previousPeriod,
    lastAccountingRunNumber,
    startDate,
    endDate,
    dataTransactions,
    previousJournalEntries,
    masterTAccountCharts,
    clientTAccountCharts,
    accountingReadWriteAccess,
    onUpdated
}: IAccountingRunEditor): ReactElement => {
    const [masterTAccountChartId, setMasterTAccountChartId] = useState(accountingRun ? accountingRun.masterTAccountChartId : emptyObjectId);
    const [clientTAccountChartId, setClientTAccountChartId] = useState(
        accountingRun
            ? accountingRun.clientTAccountChartId
            : clientTAccountCharts && clientTAccountCharts.length
              ? clientTAccountCharts[clientTAccountCharts.length - 1]._id
              : emptyObjectId
    );

    const [__stateUpdate, executeUpdateAccountingRun] = useMutation(UPDATE_ACCOUNTING_RUN);

    const isPending = !!accountingRun;

    // calculate accountingNumber
    const accountingRunNumber = accountingRun ? accountingRun.number : lastAccountingRunNumber + 1;

    // get client
    const client = dataTransactions.party;

    // fxPairInstruments
    const fxPairInstruments = dataTransactions.instruments;

    // transactions
    const transactions = cloneDeep(dataTransactions.transactions);
    transactions.forEach((t) => (t.tradeTimestamp = new Date(t.tradeTimestamp)));
    sortTransactions(transactions);

    let startFrom = startDate; // 'startDate' property is from accounting runs

    // startDate from transactions
    if (startDate === null && transactions.length > 0) {
        startFrom = addDays(transactions[0].tradeDate, -1);
        startDate = startFrom;
    }

    // GET_INSTRUMENTS
    const itemsByInstrument = groupTransactionItemsByInstrument(transactions, endDate);
    const ids = Object.keys(itemsByInstrument);

    const [{ fetching: loadingInstruments, error: errorInstruments, data: dataInstruments }] = useQuery({
        query: getInstruments,
        variables: { ids },
        pause: !transactions || transactions.length === 0
    });

    // GET_VALUATIONS
    const { sequrityInstrumentIds, fxPairInstrumentIds } = getValuationInstrumentIds(
        itemsByInstrument,
        fxPairInstruments,
        startFrom,
        endDate
    );

    // SecurityValuations
    const [{ fetching: loadingSecurityValuations, error: errorSecurityValuations, data: dataSecurityValuations }] = useQuery({
        query: getValuations,
        variables: { clientId, startDate: addDays(startFrom, -7), endDate, instrumentIds: sequrityInstrumentIds },
        pause: !sequrityInstrumentIds || sequrityInstrumentIds.length === 0
    });

    // FxValuations
    const [{ fetching: loadingFxValuations, error: errorFxValuations, data: dataFxValuations }] = useQuery({
        query: getValuations,
        variables: {
            clientId,
            startDate: transactions && transactions.length ? addDays(transactions[0].tradeDate, -7) : null,
            endDate,
            instrumentIds: fxPairInstrumentIds
        },
        pause: !fxPairInstrumentIds || fxPairInstrumentIds.length === 0
    });

    // fetch masterTAccountChart
    const [{ fetching: loadingMasterTAccountChart, error: errorMasterTAccountChart, data: dataMasterTAccountChart }] = useQuery({
        query: getTAccountChart,
        variables: { id: masterTAccountChartId },
        pause: masterTAccountChartId === emptyObjectId
    });

    // fetch clientTAccountChart
    const [{ fetching: loadingClientTAccountChart, error: errorClientTAccountChart, data: dataClientTAccountChart }] = useQuery({
        query: getTAccountChart,
        variables: { id: clientTAccountChartId },
        pause: clientTAccountChartId === emptyObjectId
    });

    // fetch manual journal entries
    const [{ fetching: loadingManualJournalEntries, error: errorManualJournalEntries, data: dataManualJournalEntries }] = useQuery({
        query: GET_MANUAL_JOURNAL_ENTRIES,
        variables: { accountingRunId: accountingRun ? accountingRun._id : emptyObjectId },
        pause: isPending === false
    });

    // alert
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    // error
    if (errorInstruments) return <div>{errorInstruments.toString()}</div>;
    if (errorSecurityValuations) return <div>{errorSecurityValuations.toString()}</div>;
    if (errorFxValuations) return <div>{errorFxValuations.toString()}</div>;
    if (errorMasterTAccountChart) return <div>{errorMasterTAccountChart.toString()}</div>;
    if (errorClientTAccountChart) return <div>{errorClientTAccountChart.toString()}</div>;
    if (errorManualJournalEntries) return <div>{errorManualJournalEntries.toString()}</div>;

    // loading
    if (loadingInstruments) return <div>loading...</div>;
    if (loadingSecurityValuations) return <div>loading...</div>;
    if (loadingFxValuations) return <div>loading...</div>;
    if (loadingMasterTAccountChart) return <div>loading...</div>;
    if (loadingClientTAccountChart) return <div>loading...</div>;
    if (loadingManualJournalEntries) return <div>loading...</div>;

    // positions
    const seqInstruments: Instrument[] = dataInstruments ? dataInstruments.instruments : [];
    const fxValutions: Valuation[] = dataFxValuations ? dataFxValuations.valuations : [];
    const seqValutions: Valuation[] = dataSecurityValuations ? dataSecurityValuations.valuations : [];

    const valuations = [...seqValutions, ...fxValutions];
    const instruments = [...seqInstruments, ...fxPairInstruments];
    const valdb = Valuations.fromArray(valuations, instruments);

    const fxValuationsByInstrumentName: Record<string, Valuation> = {};
    Object.keys(valdb.instrumentsById).forEach((instrumentId) => {
        const instrument = valdb.instrumentsById[instrumentId];
        const valuation = valdb.byInstruments[instrumentId] ? valdb.byInstruments[instrumentId][0] : null;
        if (valuation && fxPairInstrumentIds.includes(instrumentId)) {
            fxValuationsByInstrumentName[instrument.name] = valuation;
        }
    });

    const positions = calcPositions(itemsByInstrument, valdb, startDate, endDate, "SEK");
    const instrumentsById = valdb.instrumentsById;

    // tAccountMappings
    const masterTAccountMappings = getTAccountMappings(dataMasterTAccountChart);
    const clientTAccountMappings = getTAccountMappings(dataClientTAccountChart);
    const tAccountMappings = [...masterTAccountMappings, ...clientTAccountMappings];

    // GL is need for journalEntries
    const gl = GeneralLedger.createAccounting(
        positions,
        tAccountMappings,
        client,
        seqInstruments,
        transactions,
        [],
        startFrom, //startDate,
        openingDate(endDate),
        endDate,
        previousPeriod,
        currentPeriod(endDate),
        previousJournalEntries,
        fxValuationsByInstrumentName
    );
    const journalEntries = getJournalEntries(gl);

    let manualJournalEntries = [];
    if (dataManualJournalEntries && dataManualJournalEntries.journalEntries && manualJournalEntries.length === 0) {
        manualJournalEntries = dataManualJournalEntries.journalEntries;
    }

    let input = {
        _id: accountingRun ? accountingRun._id : null,
        accountingPeriod: currentPeriod(endDate),
        clientId,
        endDate,
        type: "Transaction",
        masterTAccountChartId: masterTAccountChartId,
        clientTAccountChartId: clientTAccountChartId,
        journalEntries
    };

    input = recursivelyRemoveKey(input, "__typename");

    return (
        <div>
            <p>Current run number: {accountingRunNumber}</p>
            <p>
                Master T-account chart:&nbsp;
                <TAccountChartEditor
                    isPendingRun={isPending}
                    actualTAccountChartId={accountingRun ? accountingRun.masterTAccountChartId : emptyObjectId}
                    tAccountChart={dataMasterTAccountChart ? dataMasterTAccountChart.tAccountChart : null}
                    allTAccountCharts={masterTAccountCharts}
                    onChange={(id) => {
                        setMasterTAccountChartId(id);
                    }}
                    onSaveClick={async () => {
                        await executeUpdateAccountingRun({ input })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({
                                        color: "danger",
                                        visible: true,
                                        message: result.error.toString()
                                    });
                                } else {
                                    onUpdated();
                                }
                            })
                            .catch((error) => {
                                setAlert({
                                    color: "danger",
                                    visible: true,
                                    message: error.message
                                });
                            });
                    }}
                />
            </p>
            <p>
                Client T-account chart:&nbsp;{" "}
                <TAccountChartEditor
                    isPendingRun={isPending}
                    actualTAccountChartId={accountingRun ? accountingRun.clientTAccountChartId : emptyObjectId}
                    tAccountChart={dataClientTAccountChart ? dataClientTAccountChart.tAccountChart : null}
                    allTAccountCharts={clientTAccountCharts}
                    onChange={(id) => {
                        setClientTAccountChartId(id);
                    }}
                    onSaveClick={async () => {
                        await executeUpdateAccountingRun({ input })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    onUpdated();
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.message });
                            });
                    }}
                />
            </p>
            <p>Pending id: {isPending ? accountingRun._id : "(none)"}</p>

            <div className="row">
                {positions ? (
                    <PositionsGrid
                        positions={positions}
                        party={client}
                        instrumentsById={instrumentsById}
                        tAccountMappings={tAccountMappings}
                    />
                ) : null}
            </div>

            <div id="are-buttons" className="row">
                <div className="col-12">
                    {isPending && accountingReadWriteAccess ? (
                        <UpdateAccountingRunButton
                            input={input}
                            confirmedRun={confirmedRun}
                            endDate={endDate}
                            pendingRun={isPending && accountingRun ? accountingRun : null}
                            manualJournalEntries={manualJournalEntries}
                            onUpdated={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : !isPending && !preliminaryRun && accountingReadWriteAccess ? (
                        <CreateAccountingRunButton
                            input={input}
                            confirmedRun={confirmedRun}
                            onCreated={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : null}
                    &nbsp;
                    {(isPending && accountingReadWriteAccess) || preliminaryRun ? (
                        <ConfirmAccountingRunButton
                            endDate={endDate}
                            accountingRun={isPending ? accountingRun : preliminaryRun}
                            user={user}
                            updateToStatus={
                                isPending ? AccountingRunStatusEnum.Preliminary : preliminaryRun ? AccountingRunStatusEnum.Confirmed : null
                            }
                            onConfirmed={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : null}
                </div>
            </div>

            {alert.visible ? (
                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
        </div>
    );
};
