import React, { Fragment, useEffect, useState } from "react";
import { cloneDeep, groupBy, keyBy, round, sortBy } from "lodash";
import { gql, useQuery } from "urql";
import { Row } from "@tanstack/react-table";
import stableStringify from "json-stable-stringify";
import { Panel } from "@fluentui/react";

import { emptyObjectId, mapping, numberFormatFun, twoDecPriceFormat } from "../../../../../../common/src";
import { Grid, Column } from "../../../../../../components/src";
import { Excel } from "../../../../../../components/src/Svgs";

import {
    AccountingBatchType,
    AccountingCompanyTypeEnum,
    AccountingRun,
    CurrencyEnum,
    DefaultCashInstrument,
    FxValuation,
    InstrumentModelTypeEnum,
    InstrumentProductTypeEnum,
    Nav,
    NavPosition,
    NavTransaction,
    ReportStatusEnum,
    TAccountChart,
    TAccountGroup1Enum,
    TAccountGroup2Enum,
    TAccountGroup3Enum,
    TradingManagerColumn,
    TransactionItemType,
    TransactionStatus
} from "../../../../types.generated";
import { AccountingFormatter } from "../AccountingFormatter";
import { exportToXlsx, sheetType } from "../../../../common/exportToXlsx";
import { ClientOption } from "../StatementsReportingPage";
import { TableGrouper } from "../../../../components/react-table/TableGrouper";
import { usePrevious } from "../../../../common/Utils";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MiniTransactionForm } from "../../../MiniTransactionForm";

type FinancialStatementsPropType = {
    client: ClientOption;
    date: string;
    selectedAccountingRun: AccountingRun;
    accountingRuns: AccountingRun[];
    positions: TradingManagerColumn[];
    previousPositions: TradingManagerColumn[];
    latestTAccountChart: TAccountChart;
};

const GET_CREATE_REDEEMS_DIVIDENDS = gql`
    query getTransactionItems($filter: TransactionItemFilterInput) {
        transactionItems(filter: $filter) {
            _id
            transactionTradeDate
            type
            amount
            instrumentId
            currency
        }
    }
`;

const GET_NAVS = gql`
    query getNavs($clientId: GraphQLObjectId, $date: GraphQLDateString, $lastOnly: Boolean) {
        navs(clientId: $clientId, date: $date, lastOnly: $lastOnly) {
            _id
            date
            status
            client {
                accounts {
                    defaultAccount
                    defaultCashInstruments {
                        partyInstrumentId
                    }
                }
            }
            data {
                positions {
                    instrumentId
                    name
                    value
                    quantity
                }
                classes {
                    instrumentId
                    navPerUnit
                    unitsAfterOrders
                    name
                    fxRate
                    navAfterAdjustmentsAfterOrders
                    weight
                }
                transactions {
                    accountingAmount
                    amount
                    instrumentId
                    name
                    currency
                }
            }
        }
    }
`;

const GET_FX_VALUATIONS = gql`
    query fxValuations($date: GraphQLSwedenDate) {
        fxValuations(date: $date) {
            name
            price
        }
    }
`;

const GET_CASH_LADDER = gql`
    query cashLadder($endDate: GraphQLDateString, $clientIds: [GraphQLObjectId!]!) {
        cashLadder(endDate: $endDate, clientIds: $clientIds) {
            instrumentId
            instrument {
                name
                modelType
                productType
                currency
            }
            dates
            balances
        }
    }
`;

const GetFxRate = (
    accountingCurrency: CurrencyEnum,
    currency: CurrencyEnum,
    instrumentName: string,
    fxValuationsByCurrency: Record<string, FxValuation>
): number => {
    let fxRate = 1;
    if (accountingCurrency && currency !== accountingCurrency) {
        if (fxValuationsByCurrency[currency + accountingCurrency]) {
            fxRate = fxValuationsByCurrency[currency + accountingCurrency].price;
        } else if (fxValuationsByCurrency[accountingCurrency + currency]) {
            fxRate = 1 / fxValuationsByCurrency[accountingCurrency + currency].price;
        } else {
            console.log("No fxRate found for " + instrumentName + " and currencies " + currency + accountingCurrency);
        }
    }
    return fxRate;
};

export function FinancialStatementsTab({
    client,
    date,
    selectedAccountingRun,
    accountingRuns,
    positions,
    previousPositions,
    latestTAccountChart
}: FinancialStatementsPropType): React.ReactElement {
    const [cashLadderByInstrumentName, setCashLadderByInstrumentName] = useState({});
    const [createRedeemsDividendsVariables, setCreateRedeemsDividendsVariables] = useState(null);
    const [{ fetching: loadingCreateRedeemsDividends, error: errorCreateRedeemsDividends, data: dataCreateRedeemsDividends }] = useQuery({
        query: GET_CREATE_REDEEMS_DIVIDENDS,
        variables: createRedeemsDividendsVariables,
        pause: createRedeemsDividendsVariables ? false : true
    });

    const [{ fetching: loadingNav, error: errorNav, data: dataNav }] = useQuery({
        query: GET_NAVS,
        variables: { clientId: client._id, date: date, lastOnly: true },
        pause: !client || !client._id
    });
    const [{ data: dataFxValuations, fetching: loadingFx, error: errorFx }] = useQuery({
        query: GET_FX_VALUATIONS,
        variables: { date: date }
    });

    const [{ fetching: loadingCashLadder, error: errorCashLadder, data: dataCashLadder }] = useQuery({
        query: GET_CASH_LADDER,
        variables: { endDate: date, clientIds: [client._id] },
        pause: !client || !client._id || !date
    });
    const previousDataCashLadder = usePrevious(dataCashLadder);
    const location = useLocation();
    const navigate = useNavigate();
    const { portfolioTransactionId } = useParams<"portfolioTransactionId">();

    useEffect(() => {
        if (dataCashLadder && dataCashLadder.cashLadder && stableStringify(dataCashLadder) !== stableStringify(previousDataCashLadder)) {
            const cashBalanceByInstrumentName: Record<string, Record<string, string | number>> = {};
            dataCashLadder.cashLadder.forEach((cashAccount) => {
                const datesReversed = cloneDeep(cashAccount.dates).reverse();
                const balancesReversed = cloneDeep(cashAccount.balances).reverse();

                for (let i = 0; i < datesReversed.length; i++) {
                    const dateCashLadder = datesReversed[i];
                    if (dateCashLadder <= date) {
                        if (
                            cashAccount.instrument &&
                            cashAccount.instrument.name &&
                            cashAccount.instrument.modelType &&
                            cashAccount.instrument.modelType === InstrumentModelTypeEnum.Balance &&
                            cashAccount.instrument.productType &&
                            cashAccount.instrument.productType === InstrumentProductTypeEnum.CashAccount
                        ) {
                            cashBalanceByInstrumentName[cashAccount.instrument.name] = {
                                amount: balancesReversed[i],
                                currency: cashAccount.instrument.currency,
                                instrumentId: cashAccount.instrumentId
                            };
                            break;
                        }
                    }
                }
            });

            setCashLadderByInstrumentName(cashBalanceByInstrumentName);
        }
    }, [dataCashLadder, date, previousDataCashLadder]);

    const customSortGroup1 = React.useMemo(
        () =>
            (rowA: Row<any>, rowB: Row<any>): number => {
                //console.log("customSortGroup1");
                const valuesA = rowA.original;
                const valuesB = rowB.original;
                let sortingA = 0;
                let sortingB = 0;
                if (mapping[valuesA.group1].sorting) {
                    sortingA = mapping[valuesA.group1].sorting;
                }

                if (mapping[valuesB.group1].sorting) {
                    sortingB = mapping[valuesB.group1].sorting;
                }
                if (sortingA > sortingB) return 1;
                if (sortingB > sortingA) return -1;
                return 0;
            },
        []
    );

    const customSortGroup2 = React.useMemo(
        () =>
            (rowA: Row<any>, rowB: Row<any>): number => {
                //console.log("customSortGroup2");
                const valuesA = rowA.original;
                const valuesB = rowB.original;
                const group1 = TAccountGroup1Enum.Equity;
                let sortingA = 0;
                let sortingB = 0;
                if (mapping[group1] && mapping[group1][valuesA.group2] && mapping[group1][valuesA.group2].sorting) {
                    sortingA = mapping[group1][valuesA.group2].sorting;
                }

                if (mapping[group1] && mapping[group1][valuesB.group2] && mapping[group1][valuesB.group2].sorting) {
                    sortingB = mapping[group1][valuesB.group2].sorting;
                }
                if (sortingA > sortingB) return 1;
                if (sortingB > sortingA) return -1;
                return 0;
            },
        []
    );

    const customSortGroup3 = React.useMemo(
        () =>
            (rowA: Row<any>, rowB: Row<any>): number => {
                //console.log("customSortGroup3");
                const valuesA = rowA.original;
                const valuesB = rowB.original;
                const group1 = TAccountGroup1Enum.Equity;
                let sortingA = 0;
                let sortingB = 0;
                if (
                    mapping[group1] &&
                    mapping[group1][valuesA.group2] &&
                    mapping[group1][valuesA.group2][valuesA.group3] &&
                    mapping[group1][valuesA.group2][valuesA.group3].sorting
                ) {
                    sortingA = mapping[group1][valuesA.group2][valuesA.group3].sorting;
                }

                if (
                    mapping[group1] &&
                    mapping[group1][valuesB.group2] &&
                    mapping[group1][valuesB.group2][valuesB.group3] &&
                    mapping[group1][valuesB.group2][valuesB.group3].sorting
                ) {
                    sortingB = mapping[group1][valuesB.group2][valuesB.group3].sorting;
                }
                if (sortingA > sortingB) return 1;
                if (sortingB > sortingA) return -1;
                return 0;
            },
        []
    );

    const columns = React.useMemo(
        () => [
            {
                header: "Group1",
                accessorKey: "group1",
                sortingFn: customSortGroup1,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1].translation.Sv &&
                        mapping[values.group1].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group1;
                    }
                }
            },
            {
                header: "Group2",
                accessorKey: "group2",
                sortingFn: customSortGroup2,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1][values.group2] &&
                        mapping[values.group1][values.group2].translation.Sv &&
                        mapping[values.group1][values.group2].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1][values.group2].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group2;
                    }
                }
            },
            {
                header: "Group3",
                accessorKey: "group3",
                sortingFn: customSortGroup3,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1][values.group2] &&
                        mapping[values.group1][values.group2][values.group3] &&
                        mapping[values.group1][values.group2][values.group3].translation.Sv &&
                        mapping[values.group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group3;
                    }
                }
            },
            {
                header: "T-account",
                accessorKey: "tAccount"
            },
            {
                header: "Instrument",
                accessorKey: "instrumentName",
                // Use another two-stage aggregator here to
                // first count the UNIQUE values from the rows
                // being aggregated, then sum those counts if
                // they are aggregated further
                aggregationFn: "uniqueCount",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    return (
                        <span>
                            <Link className="me-1" to={`/instruments/${values.instrumentId}`}>
                                {values.instrumentName}
                            </Link>
                        </span>
                    );
                },
                aggregatedCell: (cellProps) => `${cellProps.getValue()} Unique Names`
            },
            {
                header: "Transaction",
                accessorKey: "portfolioTransactionId",
                getCanGroup: () => false,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (values.portfolioTransaction && values.portfolioTransaction.externalId && values.batch === AccountingBatchType.T) {
                        return (
                            <span>
                                <Link
                                    className="me-1"
                                    to={`/statementsreporting/financialstatements/${values.portfolioTransactionId}${location.search}`}
                                >
                                    {values.portfolioTransaction.externalId}
                                </Link>
                            </span>
                        );
                    } else {
                        return "-";
                    }
                }
            },
            {
                header: "Amount",
                accessorKey: "amount",
                aggregationFn: "sum",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            }
        ],
        [client.accountingCompanyType, customSortGroup1, customSortGroup2, customSortGroup3, location.search]
    );

    const assetsSortBy = React.useMemo(
        () => [
            { id: "group1", desc: false },
            { id: "group2", desc: false },
            { id: "group3", desc: false }
        ],
        []
    );

    const columnsResult = React.useMemo(
        () => [
            {
                header: "Group2",
                accessorKey: "group2",
                sortingFn: customSortGroup2,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    const group1 = TAccountGroup1Enum.Equity;
                    if (
                        mapping[group1] &&
                        mapping[group1][values.group2] &&
                        mapping[group1][values.group2].translation.Sv &&
                        mapping[group1][values.group2].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[group1][values.group2].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group2;
                    }
                }
            },
            {
                header: "Group3",
                accessorKey: "group3",
                sortingFn: customSortGroup3,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    const group1 = TAccountGroup1Enum.Equity;
                    if (
                        mapping[group1] &&
                        mapping[group1][values.group2] &&
                        mapping[group1][values.group2][values.group3] &&
                        mapping[group1][values.group2][values.group3].translation.Sv &&
                        mapping[group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group3;
                    }
                }
            },
            {
                header: "T-account",
                accessorKey: "tAccount"
            },
            {
                header: "Instrument",
                accessorKey: "instrumentName",
                // Use another two-stage aggregator here to
                // first count the UNIQUE values from the rows
                // being aggregated, then sum those counts if
                // they are aggregated further
                aggregationFn: "uniqueCount",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    return (
                        <span>
                            <Link className="me-1" to={`/instruments/${values.instrumentId}`}>
                                {values.instrumentName}
                            </Link>
                        </span>
                    );
                },
                aggregatedCell: (cellProps) => `${cellProps.getValue()} Unique Names`
            },
            {
                header: "Transaction",
                accessorKey: "portfolioTransactionId",
                getCanGroup: () => false,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (values.portfolioTransaction && values.portfolioTransaction.externalId && values.batch === AccountingBatchType.T) {
                        return (
                            <span>
                                <Link
                                    className="me-1"
                                    to={`/statementsreporting/financialstatements/${values.portfolioTransactionId}${location.search}`}
                                >
                                    {values.portfolioTransaction.externalId}
                                </Link>
                            </span>
                        );
                    } else {
                        return "-";
                    }
                }
            },
            {
                header: "Amount",
                accessorKey: "amount",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregationFn: "sum",
                aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            }
        ],
        [client.accountingCompanyType, customSortGroup2, customSortGroup3, location.search]
    );
    const columnsSortBy = React.useMemo(
        () => [
            { id: "group2", desc: false },
            { id: "group3", desc: false }
        ],
        []
    );

    const columnsLiabilitiesEquities = React.useMemo(
        () => [
            {
                header: "Group1",
                accessorKey: "group1",
                sortingFn: customSortGroup1,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1].translation.Sv &&
                        mapping[values.group1].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group1;
                    }
                }
            },
            {
                header: "Group2",
                accessorKey: "group2",
                sortingFn: customSortGroup2,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1][values.group2] &&
                        mapping[values.group1][values.group2].translation.Sv &&
                        mapping[values.group1][values.group2].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1][values.group2].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group2;
                    }
                }
            },
            {
                header: "Group3",
                accessorKey: "group3",
                sortingFn: customSortGroup3,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (
                        mapping[values.group1] &&
                        mapping[values.group1][values.group2] &&
                        mapping[values.group1][values.group2][values.group3] &&
                        mapping[values.group1][values.group2][values.group3].translation.Sv &&
                        mapping[values.group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType]
                    ) {
                        return mapping[values.group1][values.group2][values.group3].translation.Sv[client.accountingCompanyType];
                    } else {
                        return values.group3;
                    }
                }
            },
            {
                header: "T-account",
                accessorKey: "tAccount"
            },
            {
                header: "Instrument",
                accessorKey: "instrumentName",
                // Use another two-stage aggregator here to
                // first count the UNIQUE values from the rows
                // being aggregated, then sum those counts if
                // they are aggregated further
                aggregationFn: "uniqueCount",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    return (
                        <span>
                            <Link className="me-1" to={`/instruments/${values.instrumentId}`}>
                                {values.instrumentName}
                            </Link>
                        </span>
                    );
                },
                aggregatedCell: (cellProps) => `${cellProps.getValue()} Unique Names`
            },
            {
                header: "Transaction",
                accessorKey: "portfolioTransactionId",
                getCanGroup: () => false,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const values = row.original;
                    if (values.portfolioTransaction && values.portfolioTransaction.externalId && values.batch === AccountingBatchType.T) {
                        return (
                            <span>
                                <Link
                                    className="me-1"
                                    to={`/statementsreporting/financialstatements/${values.portfolioTransactionId}${location.search}`}
                                >
                                    {values.portfolioTransaction.externalId}
                                </Link>
                            </span>
                        );
                    } else {
                        return "-";
                    }
                }
            },
            {
                header: "Amount",
                accessorKey: "amount",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                aggregationFn: "sum",
                aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            }
        ],
        [client.accountingCompanyType, customSortGroup1, customSortGroup2, customSortGroup3, location.search]
    );

    const liabilitiesSortBy = React.useMemo(
        () => [
            { id: "group1", desc: false },
            { id: "group2", desc: false },
            { id: "group3", desc: false }
        ],
        []
    );

    const expandedAssets = {
        ["group1:" + TAccountGroup1Enum.Assets]: true,
        ["group1:" + TAccountGroup1Enum.Assets + ">group2:" + TAccountGroup2Enum.A]: true,
        ["group1:" + TAccountGroup1Enum.Assets + ">group2:" + TAccountGroup2Enum.C]: true
    };
    const expandedLiabilitiesEquities = {
        ["group1:" + TAccountGroup1Enum.Liabilities]: true,
        ["group1:" + TAccountGroup1Enum.Liabilities + ">group2:" + TAccountGroup2Enum.A]: true,
        ["group1:" + TAccountGroup1Enum.Liabilities + ">group2:" + TAccountGroup2Enum.B]: true,
        ["group1:" + TAccountGroup1Enum.Equity]: true
    };
    const expandedResult = {
        ["group2:" + TAccountGroup2Enum.ProfitLossCurrentYear]: true,
        ["group2:" + TAccountGroup2Enum.ProfitLossCurrentYear + ">group3:" + TAccountGroup3Enum.Revenue]: true,
        ["group2:" + TAccountGroup2Enum.ProfitLossCurrentYear + ">group3:" + TAccountGroup3Enum.Expenses]: true
    };

    useEffect(() => {
        if (client && client._id && accountingRuns && accountingRuns[0] && accountingRuns[0].journalEntries.length && date) {
            const startDate = sortBy(accountingRuns[0].journalEntries, "effectiveDate")[0].effectiveDate;

            setCreateRedeemsDividendsVariables({
                filter: {
                    itemTypeIn: [
                        TransactionItemType.CreateRedeem,
                        TransactionItemType.CreateRedeemAdjustmentAmount,
                        TransactionItemType.CreateRedeemAdjustmentShares,
                        TransactionItemType.DividendPaid
                    ],
                    clientIds: [client._id],
                    statusIn: [
                        TransactionStatus.Confirmed,
                        TransactionStatus.Instructed,
                        TransactionStatus.Pending,
                        TransactionStatus.Preliminary,
                        TransactionStatus.Settled
                    ],
                    endDate: date,
                    startDate: startDate
                }
            });
        }
    }, [accountingRuns, client, date]);

    if (!selectedAccountingRun)
        return (
            <div>
                <p>No accounting run found for date {date}</p>
            </div>
        );

    if (loadingCreateRedeemsDividends) return <p>Loading transactions</p>;
    if (errorCreateRedeemsDividends) return <p>error transactions: {JSON.stringify(errorCreateRedeemsDividends, null, 2)}</p>;
    if (loadingNav) return <p>Loading navs</p>;
    if (loadingFx) return <p>Loading fx valuations</p>;
    if (loadingCashLadder) return <p>Loading cash ladder</p>;
    if (errorFx) return <p>error fx valuations: {JSON.stringify(errorFx, null, 2)}</p>;
    if (errorNav) return <p>error navs: {JSON.stringify(errorNav, null, 2)}</p>;
    if (errorCashLadder) return <p>error cash ladder: {JSON.stringify(errorCashLadder, null, 2)}</p>;

    const accounting = new AccountingFormatter(selectedAccountingRun, accountingRuns);

    const journalEntries = accounting.getAccountingJournalEntries(selectedAccountingRun, accountingRuns);
    const { transactionsGrouped, tAccountMappingMissing } = accounting.getAccountingTransactionsGrouped(
        journalEntries,
        client.types,
        latestTAccountChart
    );
    const assets = accounting.getSortedGroupedData(transactionsGrouped.assets, "Assets");
    const liabilitiesEquities = accounting.getSortedGroupedData(transactionsGrouped.liabilitiesEquities, "LiabilitiesEquities");
    const result = accounting.getSortedGroupedData(transactionsGrouped.result, "Result");
    const sumGroupedData = transactionsGrouped.sum;

    const fxValuations: FxValuation[] = cloneDeep(dataFxValuations.fxValuations);
    const fxValuationsByCurrency: Record<string, FxValuation> = keyBy(fxValuations, "name");

    // Check against positions
    let equity = 0;
    const positionsByName: Record<
        string,
        {
            exposure: number;
            localExposure: number;
            quantity: number;
            modelType: InstrumentModelTypeEnum;
            instrumentId: string;
            currency: CurrencyEnum;
        }
    > = {};
    positions.forEach((position) => {
        equity += position.exposure;
        if (position.name) {
            if (!positionsByName[position.name]) {
                positionsByName[position.name] = {
                    exposure: position.exposure,
                    localExposure: position.localExposure,
                    quantity: position.quantity,
                    modelType: position.instrument.modelType,
                    instrumentId: position.instrumentId,
                    currency: position.currency
                };
            } else {
                positionsByName[position.name].exposure += position.exposure;
                positionsByName[position.name].localExposure += position.localExposure;
                positionsByName[position.name].quantity += position.quantity;
            }
        }
    });

    let ingoingEquity = 0;
    if (previousPositions && previousPositions.length)
        previousPositions.forEach((position) => {
            ingoingEquity += position.exposure;
        });

    let createRedeemTotalAmount = 0;
    let dividendsPaidAmount = 0;
    if (dataCreateRedeemsDividends && dataCreateRedeemsDividends.transactionItems && dataCreateRedeemsDividends.transactionItems.length) {
        for (const transactionItem of dataCreateRedeemsDividends.transactionItems) {
            const fxRate = GetFxRate(
                client.accountingCurrency as unknown as CurrencyEnum,
                transactionItem.currency,
                transactionItem.externalAccountName,
                fxValuationsByCurrency
            );
            const amount = -round(transactionItem.amount * fxRate, 2);

            if (
                transactionItem.type === TransactionItemType.CreateRedeem ||
                transactionItem.type === TransactionItemType.CreateRedeemAdjustmentAmount ||
                transactionItem.type === TransactionItemType.CreateRedeemAdjustmentShares
            ) {
                createRedeemTotalAmount += amount;
            } else if (transactionItem.type === TransactionItemType.DividendPaid) {
                dividendsPaidAmount += amount;
            }
        }
    }

    let navCheck: Record<string, number | string>[] = [];
    const navInstrumentIdsByName: Record<string, string> = {};
    let navPositionsByName: Record<string, NavPosition[]> = {};
    let navTransactionsByCurrency: Record<string, NavTransaction[]> = {};
    let navEndDate: Nav = null;
    let defaultCashInstrumentsByInstrumentId: Record<string, DefaultCashInstrument> = {};
    if (dataNav && dataNav.navs) {
        for (const nav of dataNav.navs) {
            if (nav.status === ReportStatusEnum.Active) {
                navEndDate = nav;
            }
        }
        if (navEndDate) {
            const totalNavCheck = {
                class: "Total",
                units: "",
                nav: "",
                equity: 0,
                shareOfNav: 0,
                accountingValue: sumGroupedData.liabilitiesEquities.equity.total,
                difference: 0
            };
            if (navEndDate.data.positions) {
                navPositionsByName = groupBy(navEndDate.data.positions, "name");
            }
            if (navEndDate.data.transactions) {
                navTransactionsByCurrency = groupBy(navEndDate.data.transactions, "currency");
            }
            if (navEndDate.client && navEndDate.client.accounts) {
                const defaultAccount = navEndDate.client.accounts.find((account) => account.defaultAccount);
                defaultCashInstrumentsByInstrumentId = keyBy(defaultAccount.defaultCashInstruments, "partyInstrumentId");
            }
            for (const fundClass of navEndDate.data.classes) {
                navInstrumentIdsByName[fundClass.name] = fundClass.instrumentId;
                const navPerClass = {
                    class: fundClass.name,
                    units: fundClass.unitsAfterOrders,
                    nav: fundClass.navPerUnit,
                    equity: round(parseFloat(fundClass.navAfterAdjustmentsAfterOrders) * parseFloat(fundClass.fxRate), 2).toString(),
                    shareOfNav: fundClass.weight,
                    accountingValue: "",
                    difference: ""
                };
                totalNavCheck.equity += parseFloat(navPerClass.equity);
                totalNavCheck.shareOfNav += parseFloat(navPerClass.shareOfNav);
                navCheck.push(navPerClass);
            }
            totalNavCheck.difference = totalNavCheck.accountingValue + totalNavCheck.equity;
            navCheck = sortBy(navCheck, "class");
            navCheck.push(totalNavCheck);
        }
    }

    //Check bank accounts + each position
    interface BankAccountCheck {
        name: string;
        accountingValue: number;
        portfolioValue: number;
        valueDifference: number;
        accountingLocalValue: number;
        portfolioLocalValue: number;
        localValueDifference: number;
    }
    interface PositionCheck {
        name: string;
        accountingValue: number;
        portfolioValue: number;
        navValue: number;
        valueDifference: number;
        navValueDifference: number;
        accountingQuantity: number;
        portfolioQuantity: number;
        navQuantity: number;
        quantityDifference: number;
        navQuantityDifference: number;
    }
    const positionCheckByName: Record<string, PositionCheck> = {};
    const positionInstrumentIdsByName: Record<string, string> = {};
    const bankAccountCheck: BankAccountCheck[] = [];
    const sortedPositions = sortBy(Object.values(transactionsGrouped.positions), "name");
    const accountingPositionsByName = keyBy(Object.values(transactionsGrouped.positions), "name");

    for (const position of sortedPositions) {
        position.amount = round(position.amount, 2);
        position.quantity = round(position.quantity, 2);
        if (
            cashLadderByInstrumentName[position.name] ||
            (!cashLadderByInstrumentName[position.name] &&
                position.modelType === InstrumentModelTypeEnum.Balance &&
                position.productType &&
                position.productType === InstrumentProductTypeEnum.CashAccount)
        ) {
            let fxRate = 1;
            if (cashLadderByInstrumentName[position.name]) {
                fxRate = GetFxRate(
                    client.accountingCurrency as unknown as CurrencyEnum,
                    cashLadderByInstrumentName[position.name].currency,
                    position.name,
                    fxValuationsByCurrency
                );
            }
            const portfolioValue = cashLadderByInstrumentName[position.name]
                ? round(cashLadderByInstrumentName[position.name].amount * fxRate, 2)
                : 0;
            const portfolioValueLocal = cashLadderByInstrumentName[position.name]
                ? round(cashLadderByInstrumentName[position.name].amount, 2)
                : 0;

            // Do not report if both are "zero" to avoid over reporting
            /*if (round(position.amount, 2) === 0 && portfolioValue === 0) {
                continue;
            } else {*/
            const bankAccount = {
                name: position.name,
                accountingValue: position.amount,
                portfolioValue: portfolioValue,
                valueDifference: position.amount - portfolioValue,
                accountingLocalValue: position.quantity,
                portfolioLocalValue: portfolioValueLocal,
                localValueDifference: position.quantity - portfolioValueLocal
            };
            bankAccountCheck.push(bankAccount);
        }
        // excluding margin accounts but want to include position for bank accounts
        // (based on tradeDate instead of valueDate in bank accounts check)
        if (
            position.modelType !== InstrumentModelTypeEnum.Balance ||
            (position.modelType === InstrumentModelTypeEnum.Balance &&
                position.productType &&
                position.productType === InstrumentProductTypeEnum.CashAccount)
        ) {
            const portfolioValue = positionsByName[position.name] ? round(positionsByName[position.name].exposure, 2) : 0;
            const portfolioQuantity = positionsByName[position.name] ? round(positionsByName[position.name].quantity, 2) : 0;

            // Do not report if both are "zero" to avoid over reporting
            if (position.amount === 0 && portfolioValue === 0) {
                continue;
            } else {
                let navValue = 0;
                let navQuantity = 0;
                if (navPositionsByName[position.name]) {
                    let navPositionsValue = 0;
                    let navPositionsQuantity = 0;
                    for (const navPosition of navPositionsByName[position.name]) {
                        navPositionsValue += parseFloat(navPosition.value);
                        navPositionsQuantity += parseFloat(navPosition.quantity);
                    }
                    let navTransactionsValue = 0;
                    let navTransactionsQuantity = 0;
                    if (defaultCashInstrumentsByInstrumentId[position.instrumentId] && navTransactionsByCurrency[position.currency]) {
                        for (const transaction of navTransactionsByCurrency[position.currency]) {
                            navTransactionsValue += parseFloat(transaction.accountingAmount);
                            navTransactionsQuantity += parseFloat(transaction.amount);
                        }
                    }
                    navValue = navPositionsValue + navTransactionsValue;
                    navQuantity = navPositionsQuantity + navTransactionsQuantity;
                }
                const positionCheck = {
                    name: position.name,
                    accountingValue: position.amount + position.forwardAmount,
                    portfolioValue: portfolioValue,
                    navValue: navValue,
                    valueDifference: position.amount + position.forwardAmount - portfolioValue,
                    navValueDifference: position.amount + position.forwardAmount - navValue,
                    accountingQuantity: position.quantity + position.forwardQuantity,
                    portfolioQuantity: portfolioQuantity,
                    navQuantity: navQuantity,
                    quantityDifference: position.quantity + position.forwardQuantity - portfolioQuantity,
                    navQuantityDifference: position.quantity + position.forwardQuantity - navQuantity
                };
                positionInstrumentIdsByName[position.name] = position.instrumentId;
                positionCheckByName[position.name] = positionCheck;
            }
        }
    }

    // Assert we do not have any "positions" in portfolio that is missing in accounting
    for (const [name, position] of Object.entries(positionsByName)) {
        // Do not check bank accounts here
        if (
            cashLadderByInstrumentName[name] ||
            (!cashLadderByInstrumentName[name] &&
                accountingPositionsByName[name] &&
                accountingPositionsByName[name].modelType === InstrumentModelTypeEnum.Balance &&
                accountingPositionsByName[name].productType &&
                accountingPositionsByName[name].productType === InstrumentProductTypeEnum.CashAccount)
        ) {
            continue;
        } else if (position.modelType !== InstrumentModelTypeEnum.Balance) {
            const accountingPosition = accountingPositionsByName[name]
                ? {
                      amount: round(accountingPositionsByName[name].amount, 2),
                      quantity: round(accountingPositionsByName[name].quantity, 2),
                      instrumentId: accountingPositionsByName[name].instrumentId
                  }
                : { amount: 0, quantity: 0, instrumentId: position.instrumentId };
            const portfolioValue = round(position.exposure, 2);
            const portfolioQuantity = round(position.quantity, 2);
            if (!positionCheckByName[name] && round(position.exposure, 2) !== 0) {
                let navValue = 0;
                let navQuantity = 0;
                if (navPositionsByName[name]) {
                    let navPositionsValue = 0;
                    let navPositionsQuantity = 0;
                    for (const navPosition of navPositionsByName[name]) {
                        navPositionsValue += parseFloat(navPosition.value);
                        navPositionsQuantity += parseFloat(navPosition.quantity);
                    }
                    let navTransactionsValue = 0;
                    let navTransactionsQuantity = 0;
                    if (defaultCashInstrumentsByInstrumentId[position.instrumentId] && navTransactionsByCurrency[position.currency]) {
                        for (const transaction of navTransactionsByCurrency[position.currency]) {
                            navTransactionsValue += parseFloat(transaction.accountingAmount);
                            navTransactionsQuantity += parseFloat(transaction.amount);
                        }
                    }
                    navValue = navPositionsValue + navTransactionsValue;
                    navQuantity = navPositionsQuantity + navTransactionsQuantity;
                }
                positionInstrumentIdsByName[name] = accountingPosition.instrumentId;
                positionCheckByName[name] = {
                    name,
                    accountingValue: accountingPosition.amount,
                    portfolioValue: portfolioValue,
                    navValue: navValue,
                    valueDifference: accountingPosition.amount - portfolioValue,
                    navValueDifference: accountingPosition.amount - navValue,
                    accountingQuantity: accountingPosition.quantity,
                    portfolioQuantity: portfolioQuantity,
                    navQuantity: navQuantity,
                    quantityDifference: accountingPosition.quantity - portfolioQuantity,
                    navQuantityDifference: accountingPosition.quantity - navQuantity
                };
            }
        }
    }

    const positionsCheck = sortBy(Object.values(positionCheckByName), "name");

    const resultCheck = equity - createRedeemTotalAmount - dividendsPaidAmount - ingoingEquity;

    const positionSumCheck = [
        {
            name: "Equity",
            portfolioValue: equity,
            accountingValue: sumGroupedData.liabilitiesEquities.equity.total,
            difference: sumGroupedData.liabilitiesEquities.equity.total + equity
        },
        {
            name: "CreateRedeem",
            portfolioValue: createRedeemTotalAmount,
            accountingValue: sumGroupedData.liabilitiesEquities.equity.createRedeems,
            difference: sumGroupedData.liabilitiesEquities.equity.createRedeems + createRedeemTotalAmount
        },
        {
            name: "Dividends paid",
            portfolioValue: dividendsPaidAmount,
            accountingValue: sumGroupedData.liabilitiesEquities.equity.dividendsPaid,
            difference: sumGroupedData.liabilitiesEquities.equity.dividendsPaid + dividendsPaidAmount
        },
        {
            name: "Ingoing equity",
            portfolioValue: ingoingEquity,
            accountingValue: sumGroupedData.liabilitiesEquities.equity.ingoingEquity,
            difference: sumGroupedData.liabilitiesEquities.equity.ingoingEquity + ingoingEquity
        },
        {
            name: "Result",
            portfolioValue: resultCheck,
            accountingValue: sumGroupedData.result,
            difference: sumGroupedData.result + resultCheck
        }
    ];

    const statusCheck = [
        {
            name: "Accounting run",
            status: selectedAccountingRun.status
        },
        {
            name: "T-account mapping",
            status: tAccountMappingMissing ? "Missing" : "OK"
        }
    ];

    return (
        <Fragment>
            {client._id !== emptyObjectId ? (
                <div>
                    <div className="row">
                        <div className="text-end p-2">
                            <Link
                                to={
                                    "/journalentries/" +
                                    client._id +
                                    "/" +
                                    selectedAccountingRun.accountingPeriod +
                                    "/" +
                                    selectedAccountingRun.number
                                }
                            >
                                Journal entries
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-5">
                            <h5>Assets</h5>
                            <TableGrouper
                                columns={columns}
                                data={assets}
                                expanded={expandedAssets}
                                groupBy={["group1", "group2", "group3", "tAccount", "instrumentName"]}
                                compactMode={true}
                                captionColumn="portfolioTransactionId"
                                sortBy={assetsSortBy}
                            />
                        </div>
                        <div className="col-12 col-xl-5">
                            <h5>Liabilities</h5>
                            <TableGrouper
                                columns={columnsLiabilitiesEquities}
                                data={liabilitiesEquities}
                                expanded={expandedLiabilitiesEquities}
                                groupBy={["group1", "group2", "group3", "tAccount", "instrumentName"]}
                                compactMode={true}
                                captionColumn="portfolioTransactionId"
                                sortBy={liabilitiesSortBy}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-xl-5">
                            <h5>Income statement</h5>
                            <TableGrouper
                                columns={columnsResult}
                                data={result}
                                expanded={expandedResult}
                                groupBy={["group2", "group3", "tAccount", "instrumentName"]}
                                compactMode={true}
                                captionColumn="portfolioTransactionId"
                                sortBy={columnsSortBy}
                            />
                        </div>
                        <div className="col-12 col-xl-3">
                            <h5>{"Status check"}</h5>
                            <Grid data={statusCheck} sortable tableClassName="table-xs" hideDownload={true}>
                                <Column className="nowrap" title="" field="name"></Column>
                                <Column
                                    field="status"
                                    className={(d) => {
                                        if (d && d.status && (d.status === "Missing" || d.status === "Pending")) {
                                            return "nowrap btn-sm btn-danger text-white";
                                        } else {
                                            return "nowrap ";
                                        }
                                    }}
                                ></Column>
                            </Grid>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button
                                id="export-excel"
                                className="export-link"
                                type="button"
                                onClick={() => {
                                    const sheetNames = ["Position check", "NAV check", "Bank accounts check", "Positions check"];
                                    const data: sheetType[] = [positionSumCheck, navCheck, bankAccountCheck, positionsCheck];
                                    let fileName = "";
                                    if (client && client.label) {
                                        fileName = client.label + " " + " financial statements " + date + ".xlsx";
                                    } else {
                                        fileName = "Financial statements " + date + ".xlsx";
                                    }
                                    exportToXlsx(data, fileName, sheetNames);
                                }}
                            >
                                <Excel />
                            </button>
                        </div>
                    </div>
                    <div className="d-inline-flex flex-wrap mt-2">
                        <div className="me-3">
                            <h5>{"Position check"}</h5>
                            <Grid data={positionSumCheck} sortable tableClassName="table-xs" hideDownload={true}>
                                <Column className="grid-column-sticky nowrap" field="name"></Column>
                                <Column
                                    field="portfolioValue"
                                    title="Portfolio value"
                                    format={twoDecPriceFormat}
                                    className="nowrap right"
                                ></Column>
                                <Column
                                    field="accountingValue"
                                    title="Accounting value"
                                    format={twoDecPriceFormat}
                                    className="nowrap right"
                                ></Column>
                                <Column
                                    field="difference"
                                    format={twoDecPriceFormat}
                                    className={(d) => {
                                        if (d && d.difference && Math.abs(round(d.difference, 2)) !== 0) {
                                            return "right nowrap btn-sm btn-danger text-white";
                                        } else {
                                            return "right nowrap ";
                                        }
                                    }}
                                ></Column>
                            </Grid>
                        </div>
                        {client.accountingCompanyType !== AccountingCompanyTypeEnum.GeneralPortfolio ? (
                            <div>
                                <div className="me-3">
                                    <h5>{"NAV check"}</h5>
                                    <Grid data={navCheck} sortable tableClassName="table-xs" hideDownload={true}>
                                        <Column
                                            className="grid-column-sticky nowrap"
                                            field="class"
                                            format={(value) => {
                                                if (navInstrumentIdsByName[value]) {
                                                    return <Link to={`/instruments/${navInstrumentIdsByName[value]}`}>{value}</Link>;
                                                } else {
                                                    return value;
                                                }
                                            }}
                                        ></Column>
                                        <Column
                                            className="nowrap right"
                                            field="units"
                                            format={(s) => numberFormatFun("# ##0.####")(parseFloat(s))}
                                        ></Column>
                                        <Column
                                            className="nowrap right"
                                            field="nav"
                                            format={(s) => numberFormatFun("# ##0.####")(parseFloat(s))}
                                        ></Column>
                                        <Column
                                            className="nowrap right"
                                            field="shareOfNav"
                                            title="%"
                                            format={(s) => numberFormatFun("# ##0.##")(parseFloat(s) * 100)}
                                        ></Column>
                                        <Column
                                            className="nowrap right"
                                            field="equity"
                                            format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                                        ></Column>
                                        <Column
                                            className="nowrap right"
                                            field="accountingValue"
                                            title="Accounting value"
                                            format={twoDecPriceFormat}
                                        ></Column>
                                        <Column
                                            field="difference"
                                            format={twoDecPriceFormat}
                                            className={(d) => {
                                                if (d && d.difference && Math.abs(round(d.difference, 2)) !== 0) {
                                                    return "right nowrap btn-sm btn-danger text-white";
                                                } else {
                                                    return "right nowrap ";
                                                }
                                            }}
                                        ></Column>
                                    </Grid>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className="row d-inline-flex">
                        <h5>{"Bank accounts check"}</h5>
                        <Grid data={bankAccountCheck} sortable tableClassName="table-xs" hideDownload={true}>
                            <Column
                                className="grid-column-sticky nowrap"
                                format={(value) => {
                                    if (cashLadderByInstrumentName[value]) {
                                        return <Link to={`/instruments/${cashLadderByInstrumentName[value].instrumentId}`}>{value}</Link>;
                                    } else {
                                        return value;
                                    }
                                }}
                                field="name"
                            ></Column>
                            <Column
                                className="nowrap right"
                                field="portfolioLocalValue"
                                title="Portfolio value (local)"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                className="nowrap right"
                                field="accountingLocalValue"
                                title="Accounting value (local)"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                field="localValueDifference"
                                title="Difference"
                                format={twoDecPriceFormat}
                                className={(d) => {
                                    if (d && d.localValueDifference && Math.abs(round(d.localValueDifference, 2)) !== 0) {
                                        return "right nowrap btn-sm btn-danger text-white";
                                    } else {
                                        return "right nowrap ";
                                    }
                                }}
                            ></Column>
                            <Column
                                className="nowrap right"
                                field="portfolioValue"
                                title="Portfolio value"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                className="nowrap right"
                                field="accountingValue"
                                title="Accounting value"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                field="valueDifference"
                                title="Difference"
                                format={twoDecPriceFormat}
                                className={(d) => {
                                    if (d && d.valueDifference && Math.abs(round(d.valueDifference, 2)) !== 0) {
                                        return "right nowrap btn-sm btn-danger text-white";
                                    } else {
                                        return "right nowrap ";
                                    }
                                }}
                            ></Column>
                        </Grid>
                    </div>
                    <div className="row d-inline-flex">
                        <h5>{"Positions check"}</h5>
                        <Grid data={positionsCheck} sortable tableClassName="table-xs" hideDownload={true}>
                            <Column
                                className="grid-column-sticky nowrap"
                                field="name"
                                format={(value) => {
                                    if (positionInstrumentIdsByName[value]) {
                                        return <Link to={`/instruments/${positionInstrumentIdsByName[value]}`}>{value}</Link>;
                                    } else {
                                        return value;
                                    }
                                }}
                            ></Column>
                            <Column
                                className="nowrap right"
                                field="portfolioQuantity"
                                title="Portfolio quantity"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            {client.accountingCompanyType !== AccountingCompanyTypeEnum.GeneralPortfolio ? (
                                <Column
                                    className="nowrap right"
                                    field="navQuantity"
                                    title="NAV quantity"
                                    format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                                ></Column>
                            ) : null}
                            <Column
                                className="nowrap right"
                                field="accountingQuantity"
                                title="Accounting quantity"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                field="quantityDifference"
                                title="Difference portfolio"
                                format={twoDecPriceFormat}
                                className={(d) => {
                                    if (d && d.quantityDifference && Math.abs(round(d.quantityDifference, 2)) !== 0) {
                                        return "right nowrap btn-sm btn-danger text-white";
                                    } else {
                                        return "right nowrap ";
                                    }
                                }}
                            ></Column>
                            {client.accountingCompanyType !== AccountingCompanyTypeEnum.GeneralPortfolio ? (
                                <Column
                                    field="navQuantityDifference"
                                    title="Difference NAV"
                                    format={twoDecPriceFormat}
                                    className={(d) => {
                                        if (d && d.navQuantityDifference && Math.abs(round(d.navQuantityDifference, 2)) !== 0) {
                                            return "right nowrap btn-sm btn-danger text-white";
                                        } else {
                                            return "right nowrap ";
                                        }
                                    }}
                                ></Column>
                            ) : null}
                            <Column
                                className="nowrap right"
                                field="portfolioValue"
                                title="Portfolio value"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            {client.accountingCompanyType !== AccountingCompanyTypeEnum.GeneralPortfolio ? (
                                <Column
                                    className="nowrap right"
                                    field="navValue"
                                    title="NAV value"
                                    format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                                ></Column>
                            ) : null}
                            <Column
                                className="nowrap right"
                                field="accountingValue"
                                title="Accounting value"
                                format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                            ></Column>
                            <Column
                                field="valueDifference"
                                title="Difference portfolio"
                                format={twoDecPriceFormat}
                                className={(d) => {
                                    if (d && d.valueDifference && Math.abs(round(d.valueDifference, 2)) !== 0) {
                                        return "right nowrap btn-sm btn-danger text-white";
                                    } else {
                                        return "right nowrap ";
                                    }
                                }}
                            ></Column>
                            {client.accountingCompanyType !== AccountingCompanyTypeEnum.GeneralPortfolio ? (
                                <Column
                                    field="navValueDifference"
                                    title="Difference NAV"
                                    format={twoDecPriceFormat}
                                    className={(d) => {
                                        if (d && d.navValueDifference && Math.abs(round(d.navValueDifference, 2)) !== 0) {
                                            return "right nowrap btn-sm btn-danger text-white";
                                        } else {
                                            return "right nowrap ";
                                        }
                                    }}
                                ></Column>
                            ) : null}
                        </Grid>
                    </div>

                    <Panel
                        isOpen={portfolioTransactionId != null}
                        isBlocking={false}
                        onDismiss={() => navigate(`/statementsreporting/financialstatements/${location.search}`)}
                        layerProps={{ eventBubblingEnabled: true }}
                    >
                        <MiniTransactionForm clientId={client._id} id={portfolioTransactionId} type={null} queryArgMode={true} />
                    </Panel>
                </div>
            ) : null}
        </Fragment>
    );
}
