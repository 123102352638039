import React from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQueryState } from "../../common/use-query-state";

import { FxRisk } from "./FxRisk";
import { DateForm } from "../../common/dateForm";

export interface Props {
    lookThrough: boolean;
}

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const FxRiskView = (props: Props): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));
    const { id } = useParams<"id">();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }
    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={endDate} dateName="endDate"></DateForm>
                {!check_today_date(endDate) ? (
                    <Alert variant="danger" className="ms-2">
                        Historical or future date selected
                    </Alert>
                ) : null}
            </div>
            <br />
            <FxRisk clientId={id} endDate={endDate} lookThrough={props.lookThrough} />
        </div>
    );
};
