import { cloneDeep, sortBy } from "lodash";

import {
    AccountingRun,
    AccountingBatchType,
    TAccountGroup1Enum,
    TAccountGroup2Enum,
    AccountingTransactionType,
    InstrumentModelTypeEnum,
    InstrumentProductTypeEnum,
    AccountingRunTypeEnum,
    PartyType,
    TAccountChart,
    CurrencyEnum
} from "../../../types.generated";
import { AccountingTransactionExtended, JournalEntryExtended } from "../../../../../common/src/generalledger/types";

type GroupedTransactionsType = {
    assets: AccountingTransactionExtended[];
    result: AccountingTransactionExtended[];
    liabilitiesEquities: AccountingTransactionExtended[];
    sum: {
        assets: number;
        result: number;
        liabilitiesEquities: {
            liabilities: number;
            equity: { total: number; dividendsPaid: number; createRedeems: number; ingoingEquity: number };
        };
    };
    positions: PositionType;
};
type PositionType = Record<
    string,
    {
        instrumentId: string;
        name: string;
        amount: number;
        quantity: number;
        forwardAmount: number;
        forwardQuantity: number;
        modelType: InstrumentModelTypeEnum;
        productType: InstrumentProductTypeEnum;
        currency: CurrencyEnum;
    }
>;
export class AccountingFormatter {
    constructor(selectedAccountingRun: AccountingRun, previousAccountingRuns: AccountingRun[]) {
        this.selectedAccountingRun = selectedAccountingRun;
        this.previousAccountingRuns = previousAccountingRuns;
    }
    selectedAccountingRun: AccountingRun;
    previousAccountingRuns: AccountingRun[];

    // Getting all journalEntries from accountingRuns for requested accountingPeriod
    getAccountingJournalEntries(selectedAccountingRun: AccountingRun, accountingRuns: AccountingRun[]): JournalEntryExtended[] {
        const journalEntries: JournalEntryExtended[] = [];
        const maxNumber = selectedAccountingRun ? selectedAccountingRun.number : 0;
        let currentEndDate: string = null;
        const allAccountingRuns = cloneDeep(accountingRuns);
        allAccountingRuns.forEach((accountingRun) => {
            if (maxNumber && accountingRun.number > Number(maxNumber)) {
                return;
            }
            //Make sure IBs not added twice, NOT for first accountingrun of the year
            if (maxNumber && accountingRun.number === Number(maxNumber) && allAccountingRuns.length !== 1) {
                const je = cloneDeep(accountingRun.journalEntries) as any[];
                je.forEach(function (journalEntry: JournalEntryExtended & { accountingRun: AccountingRun }) {
                    journalEntry.accountingRun = accountingRun as any;
                    if (journalEntry.batch !== AccountingBatchType.IB) {
                        journalEntries.push(journalEntry);
                    }
                });
            } else {
                const je = cloneDeep(accountingRun.journalEntries) as any[];
                je.forEach(
                    (journalEntry: JournalEntryExtended & { accountingRun: AccountingRun }) =>
                        (journalEntry.accountingRun = accountingRun as any)
                );
                journalEntries.push(...je);
            }
            if (
                accountingRun.type === AccountingRunTypeEnum.Transaction &&
                (currentEndDate === null || accountingRun.endDate > currentEndDate)
            ) {
                currentEndDate = accountingRun.endDate;
                // currentAccountingRun = d;
            }
        });
        return journalEntries;
    }

    getAccountingTransactionsGrouped(
        journalEntries: JournalEntryExtended[],
        partyTypes: PartyType[],
        latestTAccountChart: TAccountChart
    ): {
        transactionsGrouped: GroupedTransactionsType;
        instrumentBalanceGroupedByType: Record<string, any>[];
        tAccountMappingMissing: boolean;
    } {
        const balanceTypes: AccountingTransactionType[] = [
            AccountingTransactionType.OpeningBalance,
            AccountingTransactionType.InitialCost,
            AccountingTransactionType.ValueChange,
            AccountingTransactionType.DividendPaid
        ];
        const unrealizedTypes: AccountingTransactionType[] = [AccountingTransactionType.Unrealized, AccountingTransactionType.UnrealizedFx];
        const realizedTypes: AccountingTransactionType[] = [AccountingTransactionType.Realized, AccountingTransactionType.RealizedFx];

        const incomeFeeTypes: AccountingTransactionType[] = [
            AccountingTransactionType.ManagementFee,
            AccountingTransactionType.CustodyFee,
            AccountingTransactionType.Rebate,
            AccountingTransactionType.Commission,
            AccountingTransactionType.StampDuty,
            AccountingTransactionType.TaxRestitution,
            AccountingTransactionType.ForeignTax,
            AccountingTransactionType.Tax,
            AccountingTransactionType.AccruedInterest,
            AccountingTransactionType.Dividend,
            AccountingTransactionType.Fee,
            AccountingTransactionType.Interest,
            AccountingTransactionType.Rounding
        ];
        const incomeTypes = unrealizedTypes.concat(realizedTypes).concat(incomeFeeTypes);

        const transactions: AccountingTransactionExtended[] = [];
        journalEntries.forEach((je) => {
            je.transactions.forEach((t) => (t.journalEntry = je));

            // eslint-disable-next-line prefer-spread
            transactions.push.apply(transactions, je.transactions);
        });

        const accountBalancesDict: Record<string, { description: string; balance: number }> = {};
        const assets: AccountingTransactionExtended[] = [];
        const result: AccountingTransactionExtended[] = [];
        const liabilitiesEquities: AccountingTransactionExtended[] = [];
        const positions: PositionType = {};
        const sum = {
            assets: 0,
            liabilitiesEquities: { liabilities: 0, equity: { total: 0, dividendsPaid: 0, createRedeems: 0, ingoingEquity: 0 } },
            result: 0
        };
        // Instrument balance by accounting transaction type
        const instrumentBalanceById: Record<string, Record<string, any>> = {};

        const balance = {
            Name: "",
            Balance: 0,
            SumUnrealized: 0,
            SumRealized: 0,
            SumIncomeFees: 0,
            SumIncome: 0
        };

        const allTypes = incomeTypes.concat(balanceTypes);

        for (const incomeType of allTypes) {
            if (!balance[incomeType]) {
                balance[incomeType] = 0;
            }
        }

        let tAccountMappingMissing = false;
        const regExp = /[a-zA-Z]/g;
        const total: Record<string, any> = cloneDeep(balance);
        total.Name = "TOTAL";
        transactions.forEach((t) => {
            if (regExp.test(t.tAccountNumber)) {
                tAccountMappingMissing = true;
            }
            let accountBalance = accountBalancesDict[t.tAccountNumber];
            if (typeof accountBalance === "undefined") {
                accountBalance = { description: null, balance: 0 };
                accountBalancesDict[t.tAccountNumber] = accountBalance;
            }
            accountBalance.balance += t.amount;
            if (t.journalEntry.accountingRun.clientTAccountChart) {
                const tAccount = t.journalEntry.accountingRun.clientTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber);
                if (tAccount) {
                    accountBalance.description = tAccount.description;
                }
            }
            const instrumentId = t.instrument && t.instrument._id ? t.instrument._id : null;

            if (instrumentId && !instrumentBalanceById[instrumentId]) {
                instrumentBalanceById[instrumentId] = cloneDeep(balance);
                instrumentBalanceById[instrumentId]["Name"] = t.instrument.name;
            }
            if (instrumentId && instrumentBalanceById[instrumentId]) {
                if (allTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId][t.type] += t.amount;
                }

                if (balanceTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId]["Balance"] += t.amount;
                }
                if (unrealizedTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId]["SumUnrealized"] += t.amount;
                }
                if (realizedTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId]["SumRealized"] += t.amount;
                }
                if (incomeFeeTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId]["SumIncomeFees"] += t.amount;
                }
                if (incomeTypes.includes(t.type)) {
                    instrumentBalanceById[instrumentId]["SumIncome"] += t.amount;
                }
            }

            total[t.type] += t.amount;

            //Grouping transactions by result, assets and so forth
            const res: any = {};
            res.batchAndNumber = t.journalEntry.batch + t.journalEntry.number;
            res.batch = t.journalEntry.batch;
            res.effectiveDate = t.journalEntry.effectiveDate;
            res.amount = t.amount;
            res.quantity = t.quantity;
            res.type = t.type;
            res.instrumentId = t.instrumentId;
            res.instrumentName = t.instrument ? t.instrument.name : null;
            res.modelType = t.instrument ? t.instrument.modelType : "";
            res.productType = t.instrument ? t.instrument.productType : "";
            const tAccount = t.journalEntry.accountingRun.clientTAccountChart
                ? t.journalEntry.accountingRun.clientTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber)
                : null;
            const latestTAccount = latestTAccountChart ? latestTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber) : null;

            res.tAccount = t.tAccountNumber + (tAccount ? " - " + tAccount.description : "");
            res.portfolioTransactionId = t.journalEntry.portfolioTransactionId ? t.journalEntry.portfolioTransactionId : null;
            res.portfolioTransaction = t.journalEntry.portfolioTransaction ? t.journalEntry.portfolioTransaction : null;
            res.group1 = latestTAccount ? latestTAccount.group1 : null;
            res.group2 = latestTAccount ? latestTAccount.group2 : null;
            res.group3 = latestTAccount ? latestTAccount.group3 : null;
            res.group4 = latestTAccount ? latestTAccount.group4 : null;
            res.group5 = latestTAccount ? latestTAccount.group5 : null;

            if (
                res.instrumentName &&
                (!partyTypes.includes(PartyType.Fund) ||
                    (partyTypes.includes(PartyType.Fund) &&
                        (t.instrument.modelType !== InstrumentModelTypeEnum.FundInternal || !t.instrument.modelType))) &&
                (res.type === AccountingTransactionType.InitialCost ||
                    res.type === AccountingTransactionType.ValueChange ||
                    res.type === AccountingTransactionType.AccruedInterest ||
                    res.type === AccountingTransactionType.ForwardCash)
            ) {
                if (res.type === AccountingTransactionType.ForwardCash) {
                    if (positions[res.instrumentName]) {
                        positions[res.instrumentName].forwardAmount += res.amount;
                        positions[res.instrumentName].forwardQuantity += res.quantity;
                    } else {
                        positions[res.instrumentName] = {
                            name: res.instrumentName,
                            instrumentId: res.instrumentId,
                            forwardAmount: res.amount,
                            amount: 0,
                            forwardQuantity: res.quantity,
                            quantity: 0,
                            modelType: res.modelType,
                            productType: res.productType,
                            currency: t.instrument ? t.instrument.currency : null
                        };
                    }
                } else {
                    if (positions[res.instrumentName]) {
                        positions[res.instrumentName].amount += res.amount;
                        positions[res.instrumentName].quantity += res.quantity;
                    } else {
                        positions[res.instrumentName] = {
                            name: res.instrumentName,
                            instrumentId: res.instrumentId,
                            forwardAmount: 0,
                            amount: res.amount,
                            forwardQuantity: 0,
                            quantity: res.quantity,
                            modelType: res.modelType,
                            productType: res.productType,
                            currency: t.instrument ? t.instrument.currency : null
                        };
                    }
                }
            }
            if (res.group1 && res.group1 === TAccountGroup1Enum.Assets) {
                assets.push(res);
                sum.assets += res.amount;
            }

            if (res.group1 && (res.group1 === TAccountGroup1Enum.Equity || res.group1 === TAccountGroup1Enum.Liabilities)) {
                liabilitiesEquities.push(res);
                if (res.group1 === TAccountGroup1Enum.Equity) {
                    sum.liabilitiesEquities.equity.total += res.amount;
                    if (res.group2 === TAccountGroup2Enum.DividendPaid) {
                        sum.liabilitiesEquities.equity.dividendsPaid += res.amount;
                    }
                    if (res.group2 === TAccountGroup2Enum.ShareCapital && res.batch === AccountingBatchType.T) {
                        sum.liabilitiesEquities.equity.createRedeems += res.amount;
                    }
                    if (res.batch === AccountingBatchType.IB) {
                        sum.liabilitiesEquities.equity.ingoingEquity += res.amount;
                    }
                }
                if (res.group1 === TAccountGroup1Enum.Liabilities) {
                    sum.liabilitiesEquities.liabilities += res.amount;
                }
            }
            if (res.group2 && res.group2 === TAccountGroup2Enum.ProfitLossCurrentYear) {
                result.push(res);
                sum.result += res.amount;
            }
        });

        const instrumentBalanceGroupedByType =
            instrumentBalanceById && Object.values(instrumentBalanceById) ? sortBy(Object.values(instrumentBalanceById), "Name") : [];
        instrumentBalanceGroupedByType.push(total);

        const transactionsGrouped = { assets, result, liabilitiesEquities, sum, positions };

        return { transactionsGrouped, instrumentBalanceGroupedByType, tAccountMappingMissing };
    }

    getSortedGroupedData(data: AccountingTransactionExtended[], type: string): AccountingTransactionExtended[] {
        if (type === "Assets") {
            data.sort((a, b) =>
                a["group2"] > b["group2"]
                    ? 1
                    : b["group2"] > a["group2"]
                      ? -1
                      : 0 || a["group3"] > b["group3"]
                        ? 1
                        : b["group3"] > a["group3"]
                          ? -1
                          : 0 || a["tAccount"] > b["tAccount"]
                            ? 1
                            : b["tAccount"] > a["tAccount"]
                              ? -1
                              : 0 || a["instrumentName"] > b["instrumentName"]
                                ? 1
                                : b["instrumentName"] > a["instrumentName"]
                                  ? -1
                                  : 0
            );
        } else if (type === "Result") {
            data.sort((a, b) =>
                a["group2"] > b["group2"]
                    ? 1
                    : b["group2"] > a["group2"]
                      ? -1
                      : 0 || a["group3"] > b["group3"]
                        ? 1
                        : b["group3"] > a["group3"]
                          ? -1
                          : 0 || a["tAccount"] > b["tAccount"]
                            ? 1
                            : b["tAccount"] > a["tAccount"]
                              ? -1
                              : 0 || a["instrumentName"] > b["instrumentName"]
                                ? 1
                                : b["instrumentName"] > a["instrumentName"]
                                  ? -1
                                  : 0
            );
        } else if (type === "LiabilitiesEquities") {
            data.sort((a, b) =>
                a["group1"] > b["group1"]
                    ? 1
                    : b["group1"] > a["group1"]
                      ? -1
                      : 0 || a["group2"] > b["group2"]
                        ? 1
                        : b["group2"] > a["group2"]
                          ? -1
                          : 0 || a["group3"] > b["group3"]
                            ? 1
                            : b["group3"] > a["group3"]
                              ? -1
                              : 0 || a["tAccount"] > b["tAccount"]
                                ? 1
                                : b["tAccount"] > a["tAccount"]
                                  ? -1
                                  : 0 || a["instrumentName"] > b["instrumentName"]
                                    ? 1
                                    : b["instrumentName"] > a["instrumentName"]
                                      ? -1
                                      : 0
            );
        }

        return data;
    }
}
