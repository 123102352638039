import React from "react";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { AccountingFrequencyEnum } from "../types.generated";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

export const dateFormater = (date: string): React.ReactElement => {
    //YYYY-MM-DD[T]HH:mm:ssZZ
    return <div>{date ? <time dateTime={date}>{dayjs(date).format("YYYY-MM-DD HH:mm:ss")}</time> : null}</div>;
};

export const dateFormaterShort = (date: string): React.ReactElement => {
    //YYYY-MM-DD[T]HH:mm:ssZZ
    return <div>{date ? <time dateTime={date}>{dayjs(date).format("YYYY-MM-DD")}</time> : null}</div>;
};

export const serializeDate = (value: any, format = "YYYY-MM-DD", addDays?: number): string => {
    return addDays ? dayjs(value).add(addDays, "day").format(format) : dayjs(value).format(format);
};

export const serializeSwedenDate = (value: any, format = "YYYY-MM-DD", addDays?: number): string => {
    return addDays
        ? dayjs.tz(value, "Europe/Stockholm").add(addDays, "day").format(format)
        : dayjs.tz(value, "Europe/Stockholm").format(format);
};

export const serializeSwedenDateIsoString = (value: any, addDays?: number): string => {
    return addDays
        ? dayjs.tz(value, "Europe/Stockholm").add(addDays, "day").toISOString()
        : dayjs.tz(value, "Europe/Stockholm").toISOString();
};

export const daysFromTodayCount = (date: any): number => {
    return dayjs().startOf("day").diff(dayjs(date)) / 1000 / 3600 / 24;
};

export const yesterday = (): string => {
    return dayjs().subtract(1, "day").format("YYYY-MM-DD");
};

export const previousAccountingPeriodYear = (date: string, frequency: AccountingFrequencyEnum): string => {
    if (frequency === AccountingFrequencyEnum.Quarterly) {
        return dayjs(date).subtract(1, "quarter").endOf("quarter").year().toString();
    } else if (frequency === AccountingFrequencyEnum.Monthly) {
        return dayjs(date).subtract(1, "month").endOf("month").year().toString();
    } else {
        console.log("Please specify a valid accounting frequency on party instead of " + frequency);
    }
};
