import React from "react";
import { FactTable } from "../FactTable";
import { numberFormatFun, Portfolio } from "../../../common/src";

const { round } = Math;
const format = numberFormatFun("0,0%");

interface IPortfolioPeriodReturnsTableProps {
    value: any;
}

export class PortfolioPeriodReturnsTable extends React.Component<IPortfolioPeriodReturnsTableProps> {
    render() {
        var table = this.props.value;
        var list = [{ key: null, values: table.ranges }];
        var values = table.timeSeries.map((d) => table.ranges.map((e) => round(1e3 * Portfolio.calcReturn(d, e)) / 1e3));
        if (values.length === 2) {
            let diffs = [];
            values[0].forEach((d, i) => {
                let v = Number.NaN;
                if (!isNaN(d) && !isNaN(values[1][i])) {
                    v = d - values[1][i];
                }
                diffs.push(v);
            });
            values.push(diffs);
        }
        values.forEach((d, i) => list.push({ key: table.names[i], values: d.map((e) => format(e)) }));
        return <FactTable className={"cols" + table.ranges.length + " evenrowsgray"} header="" list={list} />;
    }
}

interface IPortfolioPeriodReturnsProps {
    value: any;
    date?: string;
    useGenericNames: boolean;
    ranges: Array<string>;
}

class PortfolioPeriodReturns extends React.Component<IPortfolioPeriodReturnsProps> {
    render() {
        var { value: monthlyReturnsPortf, useGenericNames, ranges, date } = this.props;
        var pm = monthlyReturnsPortf.returnTimeSeries;
        var bm = monthlyReturnsPortf.benchmarkTimeSeries;
        var tss = [pm, bm];
        if (!ranges) {
            ranges = ["1M", "3M", "6M", "1Y", "3Y", "5Y"];
        }
        var names = [
            useGenericNames || !monthlyReturnsPortf.name ? "Portfölj" : monthlyReturnsPortf.name,
            useGenericNames || !monthlyReturnsPortf.benchmarkName ? "Benchmark" : monthlyReturnsPortf.benchmarkName,
            useGenericNames ? "Diff" : "DIFF"
        ];
        var years = Portfolio.timeSeriesYears(pm);

        if (years.length > ranges.length) {
            years = years.slice(0, ranges.length);
        }
        while (years.length < ranges.length) {
            years.push(null);
        }
        return (
            <div className="portfolio-period-returns">
                <h4>Avkastning (%)</h4>
                {date ? <p>Per {date}</p> : null}
                <PortfolioPeriodReturnsTable value={{ names, timeSeries: tss, ranges }} />
                <PortfolioPeriodReturnsTable value={{ names, timeSeries: tss, ranges: years }} />
            </div>
        );
    }
}

export default PortfolioPeriodReturns;
