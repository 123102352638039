import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface EsgPieProps {
    data: any;
    showLegend?: boolean;
    textData?: any;
    textInfo?:
        | "label"
        | "label+text"
        | "label+value"
        | "label+percent"
        | "label+text+value"
        | "label+text+percent"
        | "label+value+percent"
        | "text"
        | "text+value"
        | "text+percent"
        | "text+value+percent"
        | "value"
        | "value+percent"
        | "percent"
        | "none";
    header?: string;
    layout?: Partial<Plotly.Layout>;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
}
export const EsgPie = ({ header, showLegend, layout, config, data, textData, textInfo, ...props }: EsgPieProps): React.ReactElement => {
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#2186c5", "#43a04c", "#ba46b8", "#009688", "#9e9e9e", "#e5878a"],
        //colorway: ["#B3E5FC", "#C8E4C9", "#FACBBC", "#FFECB3", "#E1BEE7", "#BDBDBD"],
        showlegend: showLegend,
        legend: { orientation: "v", traceorder: "normal" },
        yaxis: { hoverformat: ".2%" },
        margin: { t: 70 }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {header ? <h3>{header}</h3> : null}
            <Plot
                data={[
                    {
                        labels: Object.keys(data),
                        values: Object.values(data).map((d: any) => d),
                        type: "pie",
                        text: textData ? Object.values(textData).map((d: any) => d.length) : Object.values(data).map((d: any) => d.length),
                        textinfo: textInfo ? textInfo : "percent",
                        textposition: "inside",
                        hole: 0.4
                    }
                ]}
                config={thisConfig as Plotly.Config}
                layout={thisLayout}
                {...props}
            />
        </React.Fragment>
    );
};
