import React from "react";
import Plotly from "plotly.js-finance-dist";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../../../components/src";

const Plot = createPlotlyComponent(Plotly);

export function TestPage(): React.ReactElement {
    const trace: Partial<Plotly.PlotData> = {
        labels: ["one", "two", "two"],
        values: [1, 2, 2],
        textinfo: "label+percent",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        type: "pie",
        hole: 0.4
    };

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#43a04c", "#307e41", "#1F5F32", "#bdbdbd"],
        showlegend: false,
        legend: { orientation: "v", traceorder: "normal" }
    };
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    const thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    return (
        <div className="container">
            <div className="row">{trace ? <Plot data={[trace]} config={thisConfig} layout={thisLayout} /> : null}</div>
        </div>
    );
}
