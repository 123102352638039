import { DateHelper } from ".";

/**
 * This calendar follows swedish bank holidays according to Sveriges Riksbank.
 */
// See following link for more info: https://www.riksbank.se/sv/press-och-publicerat/kalender/bankhelgdagar-2018/
export class SwedenCalendar {
    constructor() {
        this.name = "Sweden";
    }
    name: string;
    isBusinessDay(date) {
        const w = DateHelper.getDayOfWeek(date);
        // Saturdays & Sundays
        if (w === 6 || w === 0) {
            return false;
        }
        const m = DateHelper.getMonth(date) + 1;
        const d = DateHelper.getDay(date);
        // New Year's Day & Epiphany (swedish: Trettondedag jul)
        if (m === 1)
            if (d === 1 || d === 6)
                //jan
                return false;
        // Christmas Eve, Christmas Day & Boxing Day (swedish: Annandag jul)
        if (m === 12)
            if (d === 24 || d === 25 || d === 26 || d === 31)
                //dec
                return false;
        if (m === 5)
            if (d === 1)
                // May Day
                //maj
                return false;
        const y = DateHelper.getYear(date);
        if (m === 6) {
            //National day
            if (d === 6) {
                return false;
            }
            //Midsummer eve
            if (w === 5 && d >= 19 && d <= 25) {
                return false;
            }
        }
        const doy = DateHelper.getDayOfYear(y, m - 1, d);
        const e = doy - DateHelper.getEasterDay(y);
        if (e === -2 || e === 1 || e === 39)
            // Good Friday, Easter Monday & Ascension (swedish: Kristi himmelsfärd)
            return false;
        return true;
    }
}
