import React from "react";
import { Alert } from "react-bootstrap";
import { useQueryState } from "../../common/use-query-state";
import { DateForm } from "../../common/dateForm";

import { Dashboard } from "./Dashboard";

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const DashboardView = (): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));

    return (
        <div>
            <div className="d-flex mt-3 mb-4">
                    <DateForm defaultDateString={endDate} dateName="endDate"></DateForm>
                {!check_today_date(endDate) ? <Alert variant="danger" className="ms-2">Historical or future date selected</Alert> : null}
            </div>
            <Dashboard endDate={endDate} />
        </div>
    );
};
