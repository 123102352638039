import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { emptyObjectId } from "../../../../common/src";
import { SYSTEM_PARTY_ID } from "../../Constants";

interface ITAccountChartEditor {
    isPendingRun: boolean;
    actualTAccountChartId: any;
    tAccountChart: any;
    allTAccountCharts: any[];
    onChange: (v: string) => void;
    onSaveClick: (v: any) => void;
}

export const TAccountChartEditor = ({
    isPendingRun,
    actualTAccountChartId,
    tAccountChart,
    allTAccountCharts,
    onChange,
    onSaveClick
}: ITAccountChartEditor): ReactElement => {
    let modified = false;
    if (tAccountChart && isPendingRun) {
        modified = tAccountChart._id !== actualTAccountChartId;
    } else if (!tAccountChart && isPendingRun) {
        modified = actualTAccountChartId !== emptyObjectId;
    }
    // Only using default clientTAccountChart, will be None for master as default
    let newestTAccountChart: any;
    if (allTAccountCharts && allTAccountCharts.length) {
        if (
            allTAccountCharts[allTAccountCharts.length - 1].client &&
            allTAccountCharts[allTAccountCharts.length - 1].client._id.toString() !== SYSTEM_PARTY_ID.toString()
        ) {
            newestTAccountChart = allTAccountCharts[allTAccountCharts.length - 1];
        }
    }

    // add button to -> create completely new tachart or from one of the other clients?
    return (
        <span>
            {modified ? (
                <span>
                    <Button
                        type="submit"
                        className="btn btn-sm"
                        onClick={async () => {
                            onSaveClick(tAccountChart ? tAccountChart._id : emptyObjectId);
                        }}
                    >
                        Save
                    </Button>
                    &nbsp;
                </span>
            ) : null}
            {allTAccountCharts ? (
                <select
                    id="ace_tAccountChart"
                    name="ace_tAccountChart"
                    value={tAccountChart ? tAccountChart._id : newestTAccountChart ? newestTAccountChart._id : emptyObjectId}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                >
                    {allTAccountCharts.map((d, i) => (
                        <option key={i} value={typeof d === "string" ? d : d._id !== null ? d._id : ""}>
                            {d.version && d.name
                                ? d.name + " (v" + d.version + ")"
                                : d.version && d.client.name
                                  ? d.client.name + " (v" + d.version + ")"
                                  : d.name}
                        </option>
                    ))}
                </select>
            ) : null}
        </span>
    );
};
