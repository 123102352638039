import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { Alert } from "react-bootstrap";
import { gql, useQuery, useMutation } from "urql";

import { SubmitButton, MultipleSelectField } from "../../components/form";
import { AccountingBatchType, Party, TAccountTypeEnum, UpdateJournalEntryInput } from "../../types.generated";

import { useAlertTimeOut } from "../Utils";
import { FlatFileExportRow, writeToFlatFile } from "../../containers/accounting/ExportAccountingFunctions";
import { cloneDeep } from "lodash";

interface InputExportAccountingFile {
    party: Partial<Party>;
    clientId: string;
    accountingRunId: string;
    latestExternalNumber: number;
}

const updateJournalEntries = gql`
    mutation updateJournalEntries($input: [UpdateJournalEntryInput!]!) {
        updateJournalEntries(input: $input) {
            _id
        }
    }
`;

const getAccountingRun = gql`
    query getAccountingRun($_id: GraphQLObjectId!) {
        accountingRun(_id: $_id) {
            accountingPeriod
            number
            type
            endDate
            status
            client {
                accountingCompanyType
                accountingFrequency
            }
            journalEntries {
                _id
                batch
                number
                externalNumber
                effectiveDate
                description
                portfolioTransactionId
                portfolioTransaction {
                    type
                }
                transactions {
                    amount
                    quantity
                    tAccountNumber
                    type
                    instrumentId
                }
            }
            clientTAccountChart {
                tAccounts {
                    number
                    type
                }
                tAccountMappings {
                    selector {
                        instruments {
                            instrumentId
                            alias
                        }
                    }
                }
            }
        }
    }
`;

export function ExportAccountingForm(props: InputExportAccountingFile): React.ReactElement {
    const [{ data, fetching, error }] = useQuery({
        query: getAccountingRun,
        variables: { _id: props.accountingRunId },
        requestPolicy: "network-only"
    });

    const [_state, executeMutation] = useMutation(updateJournalEntries);

    //Default is to exclude IB batch
    const defaultBatchTypes: Record<AccountingBatchType, AccountingBatchType> = cloneDeep(AccountingBatchType);
    delete defaultBatchTypes[AccountingBatchType.IB];

    const [values] = useState({ includeBatches: Object.values(defaultBatchTypes) });
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    if (fetching) return <div>Loading</div>;

    if (error) return <div>{"Error data: " + error.message}</div>;

    if (values === null) return <div></div>;

    return (
        <div id="exportaccountingform" className="exportaccountingform form">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={values}
                validate={(validateValues) => {
                    const errors = {};

                    const requiredFields = ["includeBatches"];

                    requiredFields.forEach((d) => {
                        if (!validateValues[d] || (Array.isArray(validateValues[d]) && !validateValues[d].length)) {
                            errors[d] = "Required";
                        }
                    });
                    //console.log("validateValues", validateValues);
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues) => {
                    //console.log("submitValues: ", submitValues);
                    const input = { ...submitValues };

                    const startDate =
                        data.accountingRun && data.accountingRun.journalEntries && data.accountingRun.journalEntries.length
                            ? data.accountingRun.journalEntries.sort((a, b) =>
                                  a.effectiveDate > b.effectiveDate ? 1 : b.effectiveDate > a.effectiveDate ? -1 : 0
                              )[0].effectiveDate
                            : null;

                    const fileName =
                        "Captor_" + props.party.name.split(" ").join("_") + "_" + data.accountingRun.endDate.split("-").join("") + ".KAP";

                    // Turn array to dict since we will loop and check
                    const batchesToIncludeDict: Record<AccountingBatchType, boolean> = Object.assign(
                        {},
                        ...input.includeBatches.map((batch) => ({ [batch]: true }))
                    );

                    const idsToExcludeDict: Record<string, boolean> = {
                        "636a150f4e467e5ec8584c3a": true,
                        "636a150f4e467e5ec8584c45": true,
                        "636a14f14e467e5ec8584c08": true,
                        "636a14f14e467e5ec8584bff": true
                    };

                    // Get all data
                    const partyInstrumentById: Record<string, boolean> = {};
                    for (const instrumentId of props.party.instrumentIds) {
                        partyInstrumentById[instrumentId] = true;
                    }

                    // Loop tAccountMappings to get vp numbers
                    const vpNumberByInstrumentId: Record<string, string> = {};
                    if (
                        data.accountingRun.clientTAccountChart &&
                        Array.isArray(data.accountingRun.clientTAccountChart.tAccountMappings) &&
                        data.accountingRun.clientTAccountChart.tAccountMappings.length
                    ) {
                        for (const mapping of data.accountingRun.clientTAccountChart.tAccountMappings) {
                            if (mapping.selector && Array.isArray(mapping.selector.instruments) && mapping.selector.instruments.length) {
                                for (const instrument of mapping.selector.instruments) {
                                    vpNumberByInstrumentId[instrument.instrumentId] = instrument.alias;
                                }
                            }
                        }
                    }
                    // Loop tAccounts to get accountType
                    const costBearerByAccountNumber: Record<string, string> = {};
                    if (
                        data.accountingRun.clientTAccountChart &&
                        Array.isArray(data.accountingRun.clientTAccountChart.tAccounts) &&
                        data.accountingRun.clientTAccountChart.tAccounts.length
                    ) {
                        for (const tAccount of data.accountingRun.clientTAccountChart.tAccounts) {
                            // Bliwa spec 10A for result 10AB for balance accounts
                            costBearerByAccountNumber[tAccount.number] = tAccount.type === TAccountTypeEnum.Income ? "10A" : "10AB";
                        }
                    }

                    const filteredAndSortedJournalEntries = data.accountingRun.journalEntries.filter(
                        (journalEntry) =>
                            batchesToIncludeDict[journalEntry.batch] === true &&
                            journalEntry.effectiveDate >= startDate &&
                            idsToExcludeDict[journalEntry._id.toString()] !== true
                    );
                    // Sort by effectiveDate then id to get consistent sorting...
                    filteredAndSortedJournalEntries.sort((a, b) =>
                        a.effectiveDate > b.effectiveDate
                            ? 1
                            : b.effectiveDate > a.effectiveDate
                              ? -1
                              : a._id > b._id
                                ? 1
                                : b._id > a._id
                                  ? -1
                                  : 0
                    );
                    const exportRows: FlatFileExportRow[] = [];
                    // Bliwa specifications
                    const companyId = props.clientId === "6272657c4a5888295fe9b50f" ? 5 : 1;
                    const verificationSeries = props.clientId === "6272657c4a5888295fe9b50f" ? "CAP5" : "CAP1";

                    //let verificationNumber = 105;
                    let verificationNumber = cloneDeep(props.latestExternalNumber);
                    const updateJournalEntriesInput: UpdateJournalEntryInput[] = [];
                    for (const journalEntry of filteredAndSortedJournalEntries) {
                        verificationNumber += 1;
                        // Only update if no number is set
                        if (!journalEntry.externalNumber) {
                            updateJournalEntriesInput.push({
                                _id: journalEntry._id,
                                clientId: props.clientId,
                                accountingRunId: props.accountingRunId,
                                externalNumber: verificationNumber
                            });
                        }

                        for (const transaction of journalEntry.transactions) {
                            const vpNumber = vpNumberByInstrumentId[transaction.instrumentId]
                                ? vpNumberByInstrumentId[transaction.instrumentId]
                                : "";

                            const costBearer = costBearerByAccountNumber[transaction.tAccountNumber]
                                ? costBearerByAccountNumber[transaction.tAccountNumber]
                                : "";

                            // No quantity for party instruments per Bliwa spec
                            const quantity = partyInstrumentById[transaction.instrumentId] === true ? null : transaction.quantity;
                            exportRows.push({
                                text: "Created by Captor",
                                date: journalEntry.effectiveDate,
                                amount: transaction.amount,
                                accountNumber: transaction.tAccountNumber,
                                costBearer,
                                verificationNumber: journalEntry.externalNumber ? journalEntry.externalNumber : verificationNumber,
                                companyId,
                                verificationSeries,
                                vpNumber,
                                quantity
                            });
                        }
                    }
                    if (updateJournalEntriesInput && updateJournalEntriesInput.length > 0) {
                        await executeMutation({
                            input: updateJournalEntriesInput
                        }).catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        });
                    }

                    // Write to file
                    writeToFlatFile(fileName, exportRows);
                }}
            >
                {({ isSubmitting, errors }) => {
                    if (Object.keys(errors).length > 0) {
                        console.log(errors);
                    }
                    return (
                        <Fragment>
                            <h4>{"Options"}</h4>
                            <div>
                                <div>
                                    <Form autoComplete="off">
                                        <div className="row">
                                            <div className="col">
                                                <MultipleSelectField
                                                    name="includeBatches"
                                                    label={"Batches to include"}
                                                    options={Object.values(AccountingBatchType)}
                                                    className=""
                                                    disabled={false}
                                                    size={11}
                                                />
                                            </div>
                                        </div>
                                        {alert.visible ? (
                                            <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                        <SubmitButton disabled={isSubmitting} label={"Download Visma file"} />
                                    </Form>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}
