import React, { useState, Fragment, ReactElement } from "react";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";

import { Svgs } from "../../../../components/src";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";

import { TextField, SelectField, MultipleSelectField, SubmitButton } from "../../components/form";
import { userHaveAccessTo } from "../../common/Permissions";
import { PermissionTypeEnum, RoleTypeEnum, UpdateRoleInput, DocumentStatusEnum, PermissionAssetEnum } from "../../types.generated";
import { useAlertTimeOut } from "../../common/Utils";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { MarkDownField } from "../../components/form/MarkDownField";

const PERMISSION_TYPES = Object.keys(PermissionTypeEnum).sort();
const ROLE_ASSETS = Object.keys(PermissionAssetEnum).sort();

const GET_ME = gql`
    query {
        me {
            personalNumber
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

const GET_CLIENTS = gql`
    query {
        parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const GET_ROLE = gql`
    query role($id: GraphQLObjectId!) {
        role(_id: $id) {
            _id
            status
            roleType
            permissionType
            name
            assets
            clientIds
            comment
        }
    }
`;

const CREATE_ROLE = gql`
    mutation createRole($input: CreateRoleInput!) {
        createRole(input: $input) {
            _id
        }
    }
`;

const UPDATE_ROLE = gql`
    mutation updateRole($input: UpdateRoleInput!) {
        updateRole(input: $input) {
            _id
        }
    }
`;

export function AdminRolePage(): ReactElement {
    const { id }: any = useParams();

    const isEditMode = !!id;
    const isCreateMode = !isEditMode;

    const [{ fetching: loadingMe, error: errorMe, data: getMe }] = useQuery({ query: GET_ME });
    const [{ fetching: loading, error, data }] = useQuery({ query: GET_ROLE, variables: { id }, pause: isCreateMode });
    const [{ fetching: loadingClients, error: errorClients, data: getClients }] = useQuery({ query: GET_CLIENTS });
    const [_, createRole] = useMutation(CREATE_ROLE);
    const [__, updateRole] = useMutation(UPDATE_ROLE);
    const navigate = useNavigate();
    const location = useLocation();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    if (loadingMe || loading || loadingClients)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (errorMe) return <pre>{JSON.stringify(errorMe, null, 2)}</pre>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
    if (errorClients) return <pre>{JSON.stringify(errorClients, null, 2)}</pre>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Admin, getMe.me.roles)) {
        return <div>You don't have access to this page</div>;
        //return <pre>{JSON.stringify(data, null, 2)}</pre>;
    }

    const { parties } = getClients;

    // default role
    let role: any = {
        status: DocumentStatusEnum.Active,
        name: "",
        assets: [],
        clientIds: [],
        comment: "",
        permissionType: "Read",
        roleType: RoleTypeEnum.Backend
    };

    // edit mode
    if (isEditMode) {
        role = data.role;
    }

    const pageTitle = isCreateMode ? "New role" : "Edit role";
    const submitButtonLabel = isCreateMode ? "Create" : "Update";

    return (
        <div className="admin-page">
            <h1>{pageTitle}</h1>
            <div className="row">
                <Formik
                    enableReinitialize={true}
                    initialValues={role}
                    validate={(validateFormData: UpdateRoleInput) => {
                        const errors: any = {};
                        if (validateFormData.roleType === RoleTypeEnum.Frontend) {
                            if (validateFormData.clientIds.length > 0) {
                                errors.clientIds = "For Frontend, must be an empty list";
                            }
                            if (validateFormData.permissionType !== PermissionTypeEnum.Read) {
                                errors.permissionType = "For Frontend, must be Read";
                            }
                        }
                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .min(2, "Must be at least 2 characters")
                            .max(25, "Must be 25 characters or less")
                            .required("Required")
                    })}
                    onSubmit={async (submitValues, { setSubmitting }) => {
                        let input = submitValues;
                        if (isCreateMode) {
                            let newRoleId: any;
                            await createRole({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        //resetForm(role);
                                        newRoleId = result.data.createRole._id;
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `New role '${input.name}' created successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    // redirect to edit role page on success
                                    if (newRoleId) {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(newRoleId);
                                        navigate(path.join("/"), { replace: true });
                                    }
                                });
                        } else {
                            input = recursivelyRemoveKey(input, "__typename");
                            //delete input.__typename;
                            console.log(input);
                            await updateRole({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `The role '${input.name}' updated successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Fragment>
                            <div className="col-12">
                                <Form autoComplete="off">
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <SelectField
                                                name="status"
                                                label="Status"
                                                options={Object.keys(DocumentStatusEnum)}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                            <TextField name="name" label="Name" type="text" className="" disabled={isSubmitting} />
                                            <MultipleSelectField
                                                name="assets"
                                                label="Assets"
                                                options={ROLE_ASSETS}
                                                className=""
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                            <SelectField
                                                name="roleType"
                                                label="Role type"
                                                options={Object.keys(RoleTypeEnum)}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="permissionType"
                                                label="Permission type"
                                                options={PERMISSION_TYPES}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <MultipleSelectField
                                                name="clientIds"
                                                label="Clients"
                                                options={parties.map((d) => ({ key: d._id, value: d.name }))}
                                                className=""
                                                disabled={isSubmitting}
                                                size={20}
                                            />
                                        </div>
                                        <div className="form-group col-2"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-10">
                                            <div>
                                                <MarkDownField name="comment" label="Comment" className="" height={250} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-10 mb-2">
                                            <div>
                                                {alert.visible ? (
                                                    <Alert
                                                        style={{ marginTop: "10px" }}
                                                        variant={alert.color}
                                                        onClose={onDismissAlert}
                                                        dismissible
                                                    >
                                                        {alert.message}
                                                    </Alert>
                                                ) : null}
                                                <SubmitButton disabled={isSubmitting} label={submitButtonLabel} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Fragment>
                    )}
                </Formik>
            </div>
        </div>
    );
}
