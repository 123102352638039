import React from "react";
import { gql, useQuery } from "urql";
import { twoDecPriceFormat } from "../../../common/src";
import { DateForm } from "../common/dateForm";
import { useQueryArgs } from "../common/Utils";
import { Nav, NavFundClass, Party, PartyFundInfoClass, TransferAgentOrder, TransferAgentOrderEnum } from "../types.generated";
import { serializeSwedenDate } from "../components/dateFormater";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { ReactTable } from "../components/react-table/ReactTable";

const getNavs = gql`
    query getNavs($date: GraphQLDateString) {
        navs(date: $date, lastOnly: true) {
            clientId
            data {
                classes {
                    name
                    navCurrency
                    navPerUnit
                }
            }
            date
        }
    }
`;

const getTransferAgentOrders = gql`
    query getTransferAgentOrders($date: GraphQLDateString) {
        transferAgentOrders: transferAgentOrders(filter: { tradeDate: $date }) {
            _id
            transactionType
            currency
            amount
            units
            instrument {
                _id
                name
            }
            clientId
            createTimestamp
        }
        parties: parties(filter: { typeIn: [Fund] }) {
            _id
            name
            fundInfo {
                classes {
                    startNAVPerUnit
                    startDate
                    instrumentId
                }
            }
        }
    }
`;

export function TransferAgentOrdersSummaryPage(): React.ReactElement {
    const { queryArgs } = useQueryArgs();

    let tradeDate = new Date().toISOString().slice(0, 10);
    if (queryArgs.tradeDate) {
        tradeDate = queryArgs.tradeDate.toString();
    }

    const [{ fetching: loadingOrders, error: errorOrders, data: dataOrders }] = useQuery({
        query: getTransferAgentOrders,
        variables: { date: tradeDate },
        requestPolicy: "network-only"
    });
    const [{ fetching, error, data }] = useQuery({ query: getNavs, variables: { date: tradeDate }, requestPolicy: "network-only" });

    const columns = React.useMemo(
        () => [
            {
                header: "Fund name",
                accessorKey: "name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <a href={`/parties/${row.original.clientId}`} target={"_blank"} rel="noreferrer">
                            {cellProps.getValue()}
                        </a>
                    );
                },
                size: 250
            },
            {
                header: "Netted order value",
                accessorKey: "nettedOrderValue",
                filterFn: "startsWith",
                cell: (cellProps) => (
                    <div style={{ textAlign: "right", paddingRight: "2px" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
                ),
                size: 130
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter,
                size: 80
            },
            {
                header: "Last timestamp",
                accessorKey: "lastTimestamp",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                size: 100
            },
            {
                header: "",
                accessorKey: "-",
                cell: () => <div></div>,
                filter: null,
                size: "auto"
            }
        ],
        []
    );

    if (fetching || loadingOrders) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}`</div>;
    if (errorOrders) return <div>Error! ${errorOrders.message}`</div>;

    const datarows = [];

    // sum orders across classes and use estimated NAV when units are used. start from parties to ensure all of them in the result
    dataOrders.parties.forEach((fund: Party) => {
        const fundOrders: TransferAgentOrder[] = dataOrders.transferAgentOrders.filter((o: TransferAgentOrder) => o.clientId === fund._id);
        // no orders case
        if (fundOrders.length === 0) {
            datarows.push({
                name: fund.name,
                clientId: fund._id,
                nettedOrderValue: 0,
                currency: "",
                lastTimestamp: ""
            });
            return;
        }
        // a fund could have orders in different currencies
        const currencies = [...new Set(fundOrders.map((o: TransferAgentOrder) => o.currency))];
        // calculate nettedOrderValue per currency
        const rows = currencies.map((currency) => {
            const ordersWithCurrency: TransferAgentOrder[] = fundOrders.filter((o) => o.currency === currency);
            let lastTimestamp = new Date("1970-01-01");
            //sum orders across classes and use estimated NAV when units are used
            const nettedOrderValue = ordersWithCurrency.reduce((total: number, v: TransferAgentOrder) => {
                // calculate amount - amount or units * class.navPerUnit
                let amount: number = parseFloat(v.amount);
                const units = parseFloat(v.units);
                if (units > 0) {
                    // units case
                    let navPerUnit = 0;
                    // find navPerUnit from nav if exist
                    const nav: Nav = data.navs.find((n: Nav) => n.clientId === fund._id);
                    if (nav) {
                        const nfc: NavFundClass = nav.data.classes.find(
                            (c: NavFundClass) => c.name === v.instrument.name && c.navCurrency === currency
                        );
                        navPerUnit = parseFloat(nfc.navPerUnit);
                    } else {
                        const fic = fund.fundInfo.classes.find((c: PartyFundInfoClass) => c.instrumentId === v.instrument._id);
                        navPerUnit = parseFloat(fic.startNAVPerUnit);
                    }
                    amount = units * navPerUnit;
                }
                // SELL
                if (v.transactionType === TransferAgentOrderEnum.SELL) {
                    amount = amount * -1;
                }
                // lastTimestamp
                const lastOrder = new Date(v.createTimestamp);
                if (lastOrder > lastTimestamp) {
                    lastTimestamp = lastOrder;
                }
                return total + amount;
            }, 0);

            return {
                name: fund.name,
                clientId: fund._id,
                nettedOrderValue,
                currency,
                lastTimestamp: serializeSwedenDate(lastTimestamp, "YYYY-MM-DD HH:mm")
            };
        });

        datarows.push(...rows);
    });

    return (
        <div>
            <div className="d-flex mb-2">
                <DateForm defaultDateString={tradeDate} dateName={"tradeDate"}></DateForm>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ReactTable columns={columns} data={datarows} />
                </div>
            </div>
        </div>
    );
}
