import React from "react";
import { FactTable } from "./FactTable";
import { numberFormatFun, TimeSeries } from "../../common/src";
import { cloneDeep } from "lodash";

const format = numberFormatFun("0.0%");

type PeriodReturnsTableInput = {
    timeseries: TimeSeries[];
    ranges: string[];
};

export const PeriodReturnsTable = ({ timeseries, ranges }: PeriodReturnsTableInput): React.ReactElement => {
    const list = [{ key: null, values: ranges }];

    const allReturns = [];

    for (let i = 0; i < timeseries.length; i++) {
        const ts = timeseries[i].resampleToMonthly();
        const periodReturn = [];

        for (let j = 0; j < ranges.length; j++) {
            const m = /([0-9]+)([MY])/.exec(ranges[j]);

            if (m) {
                let months = parseInt(m[1], 10);
                if (m[2] === "Y") months *= 12;
                const tsReturn = ts.endValue / ts.__values[ts.length - 1 - months];
                if (months < 14) {
                    periodReturn.push(tsReturn - 1);
                } else {
                    periodReturn.push(Math.pow(tsReturn, 12 / (months - 1)) - 1);
                }
                //If the range is a year, e.g 2019, 2020, return is calculated using specificYearReturn()
            } else if (typeof ranges[j] === "number" && isFinite(Number(ranges[j]))) {
                periodReturn.push(timeseries[i].specificYearReturn(parseFloat(ranges[j])));
            } else {
                periodReturn.push(Number.NaN);
            }
        }
        allReturns.push(periodReturn);
    }
    const diffs = [];
    if (allReturns.length > 1) {
        // if at least one benchmark
        const masterSeriesReturns = allReturns[0];
        for (let j = 1; j < allReturns.length; j++) {
            const benchMarkReturn = allReturns[j];
            const diff = masterSeriesReturns.map((x, i) => parseFloat(format(x)) - parseFloat(format(benchMarkReturn[i])));
            diffs.push(diff);
        }
    }

    //add the client returns first
    list.push({ key: timeseries[0].name, values: allReturns[0].map((e) => format(e)) });

    for (let i = 1; i < allReturns.length; i++) {
        const returns = cloneDeep(allReturns[i]);
        const diff = diffs[i - 1];
        for (let j = 0; j < returns.length; j++) {
            returns[j] = format(returns[j]) + "[" + format(diff[j] / 100) + "]";
        }
        list.push({ key: timeseries[i].name, values: returns });
    }

    return <FactTable className="firstheader evenrowsgray" header="" list={list} />;
};
