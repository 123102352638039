import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { ThemeProvider } from "@fluentui/react";
import { GqlClient } from "./GqlClient";
import { REACT_APP_GIT_HASH } from "./env";

import { Login, loginContext } from "./containers/Login";
import "./portal.css";

import { greenTheme } from "./ThemeProvider";

initializeIcons();

console.log("REACT_APP_GIT_HASH", REACT_APP_GIT_HASH);
let bugsnagClient = null;
// WARNING: We limit this due to GDPR. We do not send user data to any US servers.
if (REACT_APP_GIT_HASH !== "dev") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bugsnagClient = Bugsnag.start({
        apiKey: "5f3ece5377a7532c073d477be803299b",
        plugins: [new BugsnagPluginReact()],
        trackInlineScripts: false,
        collectUserIp: false,
        releaseStage: REACT_APP_GIT_HASH,
        appVersion: REACT_APP_GIT_HASH,
        enabledBreadcrumbTypes: []
    });
} else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bugsnagClient = Bugsnag.start({
        apiKey: "5f3ece5377a7532c073d477be803299b",
        plugins: [new BugsnagPluginReact()],
        trackInlineScripts: false,
        enabledReleaseStages: [],
        collectUserIp: false,
        releaseStage: REACT_APP_GIT_HASH,
        appVersion: REACT_APP_GIT_HASH,
        enabledBreadcrumbTypes: []
    });
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const Authenticated = (): React.ReactElement => {
    return (
        <Login>
            <loginContext.Consumer>
                {() => {
                    return <GqlClient />;
                }}
            </loginContext.Consumer>
        </Login>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <BrowserRouter>
                <ThemeProvider theme={greenTheme}>
                    <div className="app">
                        <Routes>
                            <Route path="*" element={<Authenticated />} />
                        </Routes>
                    </div>
                </ThemeProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
);
