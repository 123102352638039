import React from "react";
import { Button, Modal } from "react-bootstrap";

interface YesNoModalPropTypes {
    warningText: string;
    yesText?: string;
    noText?: string;
    modal: { showModal: boolean; payload: any };
    setModal({ showModal, payload }: { showModal: boolean; payload: any }): void;
    onYes?: () => void;
    onNo?: () => void;
}

export const YesNoModal = ({
    warningText,
    onYes,
    onNo = null,
    modal,
    setModal,
    yesText = "Delete",
    noText = "Cancel"
}: YesNoModalPropTypes): React.ReactElement => {
    return (
        <div>
            <Modal show={modal.showModal} onHide={() => setModal({ showModal: false, payload: null })}>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center p-4">
                        <div>
                            <p style={{ whiteSpace: "pre-line" }}>{warningText}</p>
                        </div>
                        <div>
                            <Button
                                variant="success"
                                onClick={() => {
                                    if (onNo) {
                                        onNo();
                                    }
                                    setModal({ showModal: false, payload: null });
                                }}
                            >
                                {noText}
                            </Button>
                            <Button
                                variant="danger"
                                className="ms-1"
                                onClick={() => {
                                    if (onYes) {
                                        onYes();
                                    }
                                    setModal({ showModal: false, payload: null });
                                }}
                            >
                                {yesText}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
