import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";
import { orderBy } from "lodash";

import { Svgs } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";

import { ReactTable } from "../../components/react-table/ReactTable";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { userHaveAccessTo } from "../../common/Permissions";
import { PermissionAssetEnum } from "../../types.generated";

const GET_ME = gql`
    query {
        me {
            personalNumber
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

const GET_ROLES = gql`
    query {
        roles {
            _id
            status
            name
            assets
            updateTimestamp
            permissionType
            roleType
            clientIds
            clients {
                _id
                name
            }
            nbrOfActiveUsers
            nbrOfUsers
        }
    }
`;

export function AdminRolesPage(): React.ReactElement {
    const [{ fetching: loadingMe, error: errorMe, data: getMe }] = useQuery({
        query: GET_ME,
        requestPolicy: "cache-and-network"
    });
    //const { loading: loadingMe, error: errorMe, data: getMe } = useQuery(GET_ME);
    const [{ fetching: loading, error, data }] = useQuery({
        query: GET_ROLES,
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(() => {
        const clientById: Record<string, { _id: string; name: string }> = {};

        if (data && data.roles) {
            for (const role of data.roles) {
                role.clients.forEach((client) => {
                    clientById[client._id] = client;
                });
            }
        }

        return [
            {
                accessorKey: "_id",
                header: "Id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/admin/roles/${row.original._id}`}>
                            <div>#</div>
                        </Link>
                    );
                },
                size: 1
            },
            {
                accessorKey: "status",
                header: "Status",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    return <div className="text-center">{cellProps.getValue()}</div>;
                }
            },
            {
                accessorKey: "nbrOfActiveUsers",
                header: "Nbr of active users",
                filterFn: "startsWith",
                cell: (cellProps) => {
                    return <div className="text-center">{numberFormatFun("# ##0")(cellProps.getValue())}</div>;
                }
            },
            {
                accessorKey: "nbrOfUsers",
                header: "Nbr of users",
                filterFn: "startsWith",
                cell: (cellProps) => {
                    return <div className="text-center">{numberFormatFun("# ##0")(cellProps.getValue())}</div>;
                }
            },
            {
                accessorKey: "name",
                header: "Name"
            },
            {
                accessorKey: "roleType",
                header: "Role type"
            },
            {
                accessorKey: "clientIds",
                header: "Clients",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row) {
                        return (
                            <div className="col">
                                {row.original.clientIds.map((id: string) => {
                                    const client = clientById[id];
                                    if (client) {
                                        return (
                                            <Link className="row" key={id} to={`/parties/${id}`}>
                                                {clientById[id].name}
                                            </Link>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                //filter: SelectColumnFilterArrayOfIds(Object.values(clientById)),
                width: 200
            },
            {
                accessorKey: "updateTimestamp",
                header: "Update timestamp"
            }
        ];
    }, [data]);

    if (loadingMe || loading)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );
    if (errorMe) return <pre>{JSON.stringify(errorMe, null, 2)}</pre>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Admin, getMe.me.roles)) {
        return <div />;
    }

    const { roles } = data;

    const sortedRoles = orderBy(roles, ["nbrOfActiveUsers"], ["desc"]);

    return <ReactTable columns={columns} data={sortedRoles} defaultHiddenColumns={[]} />;
}
