import React, { Fragment, useState, useEffect, useContext, ReactElement } from "react";
import { gql, useQuery } from "urql";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import queryString from "query-string";
import { cloneDeep } from "lodash";

import { SelectField } from "../components/form";
import { PartyStatusEnum, PartyType } from "../types.generated";
import { useQueryArgs } from "../common/Utils";
import { ClientContext, IClient } from "./ClientContext";
import { emptyObjectId } from "../../../common/src";

export const GET_ClIENTS = gql`
    query getClients($typeIn: [PartyType!], $statusIn: [PartyStatusEnum!]) {
        clients: parties(filter: { typeIn: $typeIn, withTransactions: true, statusIn: $statusIn }) {
            _id
            name
            dashName
        }
    }
`;

interface IClientContextSelectorProps {
    typeIn: PartyType[];
    defaultClientName?: string;
    carryQueryArgs?: boolean;
    statusIn?: PartyStatusEnum[];
}

export function ClientContextSelector({
    typeIn = [PartyType.Client],
    defaultClientName = "Select client",
    carryQueryArgs = true,
    statusIn = [PartyStatusEnum.Confirmed]
}: IClientContextSelectorProps): React.ReactElement {
    const { client, setClient } = useContext(ClientContext);
    const navigate = useNavigate();
    const location = useLocation();
    const allParams = useParams();
    const [formData, setFormData] = useState({ clientName: defaultClientName });
    const clientDashName = allParams.clientDashName;
    const { queryArgs } = useQueryArgs();
    const [{ fetching, error, data }] = useQuery({ query: GET_ClIENTS, variables: { typeIn: typeIn, statusIn } });

    const defaultClient = React.useMemo(() => {
        return { _id: emptyObjectId, name: defaultClientName, dashName: defaultClientName.replace(/ /g, "-") };
    }, [defaultClientName]);

    const dataClients: {
        _id: string;
        name: string;
        dashName: string;
    }[] = React.useMemo(() => {
        if (data && data.clients) {
            const result: {
                _id: string;
                name: string;
                dashName: string;
            }[] = [];
            for (const client of data.clients) {
                result.push(client);
            }
            return [defaultClient, ...result];
        } else {
            return [defaultClient];
        }
    }, [data, defaultClient]);

    const { dashNameLookup, nameLookup } = React.useMemo(() => {
        const dashNameLookup: Record<string, IClient> = {};
        const nameLookup: Record<string, IClient> = {};
        const idLookup: Record<string, IClient> = {};
        for (const client of dataClients) {
            dashNameLookup[client.dashName] = client;
            nameLookup[client.name] = client;
            idLookup[client._id] = client;
        }
        return { dashNameLookup, nameLookup, idLookup };
    }, [dataClients]);

    useEffect(() => {
        if (!data && client.name !== defaultClientName) {
            setFormData({ clientName: defaultClientName });
            setClient(defaultClient);
        } else if (data) {
            // init formData from client provided in url params
            const selectedClientDashName = nameLookup[formData.clientName].dashName;
            if (clientDashName && clientDashName !== selectedClientDashName) {
                const c = dashNameLookup[clientDashName];
                if (c) {
                    setFormData({
                        clientName: c.name
                    });
                    setClient(c);
                }
            } else if (!clientDashName && client.name !== formData.clientName) {
                // init formData from client provided in ClientContext
                const c = nameLookup[client.name];
                if (c) {
                    const path = location.pathname.split("/");
                    if (path.includes(client.dashName)) {
                        path.pop();
                    }
                    path.push(client.dashName);
                    if (carryQueryArgs) {
                        navigate(path.join("/") + "?" + queryString.stringify({ ...queryArgs }));
                    } else {
                        navigate(path.join("/"));
                    }
                }
            } else if (!clientDashName && client.name === defaultClientName) {
                //Adding default client name to path for first time on a page
                const path = location.pathname.split("/");
                path.push(client.dashName);
                if (carryQueryArgs) {
                    navigate(path.join("/") + "?" + queryString.stringify({ ...queryArgs }));
                } else {
                    navigate(path.join("/"));
                }
            }
        }
    }, [
        carryQueryArgs,
        client.dashName,
        client.name,
        clientDashName,
        dashNameLookup,
        data,
        dataClients,
        defaultClient,
        defaultClientName,
        formData.clientName,
        location.pathname,
        nameLookup,
        navigate,
        queryArgs,
        setClient
    ]);

    if (fetching) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;

    const clientNames =
        dataClients.length > 1
            ? [
                  dataClients[0].name,
                  ...dataClients
                      .slice(1, dataClients.length)
                      .map((client) => client.name)
                      .sort()
              ]
            : [dataClients[0].name];

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validate={(validateFormData) => {
                const errors: any = {};
                if (data) {
                    let path = location.pathname.split("/");
                    let pathname = cloneDeep(location.pathname);
                    if (nameLookup[validateFormData.clientName]) {
                        if (validateFormData.clientName !== formData.clientName) {
                            if (clientDashName) {
                                pathname = decodeURI(pathname).replace(
                                    allParams["clientDashName"],
                                    nameLookup[validateFormData.clientName].dashName
                                );
                                path = pathname.split("/");
                            } else {
                                path.push(nameLookup[validateFormData.clientName].dashName);
                            }
                            if (carryQueryArgs) {
                                navigate(path.join("/") + "?" + queryString.stringify({ ...queryArgs }));
                            } else {
                                navigate(path.join("/"));
                            }
                        }
                    } else if (validateFormData.clientName === defaultClientName) {
                        pathname = pathname.replace(allParams["clientDashName"], nameLookup[validateFormData.clientName].dashName);
                        path = pathname.split("/");

                        if (carryQueryArgs) {
                            navigate(path.join("/") + "?" + queryString.stringify({ ...queryArgs }));
                        } else {
                            navigate(path.join("/"));
                        }
                        setClient(defaultClient);
                    }
                }
                return Object.keys(errors).length > 0 ? errors : {};
            }}
            onSubmit={null}
        >
            {({ isSubmitting }) => {
                let label: string | ReactElement = "Client";
                if (client && client._id && client._id !== "000000000000000000000000") {
                    label = <Link to={"/parties/" + client._id}>Client</Link>;
                }
                return (
                    <Fragment>
                        <Form autoComplete="off">
                            <SelectField className="" name="clientName" label={label} options={clientNames} disabled={isSubmitting} />
                        </Form>
                    </Fragment>
                );
            }}
        </Formik>
    );
}
