import React, { useState, useEffect, useMemo } from "react";
import { gql } from "urql";
import { Dropdown } from "react-bootstrap";
import { sortBy, cloneDeep, groupBy, keyBy } from "lodash";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { YesNoModal } from "../../components/YesNoModal";
import { exportToXlsx, sheetType } from "../../common/exportToXlsx";
import { Svgs } from "../../../../components/src";
import { DateHelper } from "../../../../common/src";
import { DateForm } from "../../common/dateForm";
import { useQueryArgs, usePrevious, isValidYYYYMMDD } from "../../common/Utils";
import stableStringify from "json-stable-stringify";

import { useGetNavsQuery, useDeleteNavMutation } from "./NavsPage.generated";
import { ReactTable } from "../../components/react-table/ReactTable";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";

//import { GetNavsQuery } from "./NavsPage.generated";
//import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

export const deleteNav = gql`
    mutation deleteNav($_id: GraphQLObjectId!) {
        deleteNav(_id: $_id)
    }
`;

export const getNavs = gql`
    query getNavs($startDate: GraphQLDateString) {
        navs(startDate: $startDate) {
            _id
            clientId
            client {
                name
            }
            date
            createTimestamp
            status
            updateUserInfo {
                name
            }
            publishedTo {
                key
            }
        }
    }
`;

interface Props {
    navsData: any[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    setModal: Function;
    refetch?: () => void;
}

const Navs = ({ setModal, navsData }: Props) => {
    const download = () => {
        const currentRecords: sheetType = navs;
        //console.log(currentRecords);
        exportToXlsx([currentRecords], "navs.xlsx");
    };

    let navs: any[] = cloneDeep(navsData);
    navs = sortBy(navs, "date");
    navs = navs.reverse();

    navs.forEach((nav) => {
        nav.clientName = nav.client.name;
        nav.updatedBy = nav.updateUserInfo.name;
        nav.canDelete = false;
    });

    const navsByClientId = groupBy(navs, (nav) => {
        return nav.clientId;
    });

    Object.keys(navsByClientId).map((clientId) => {
        // this is embarrassingly inefficient but I am in a hurry
        let l: any[] = navsByClientId[clientId];
        l = sortBy(l, "date");
        l = l.reverse();
        l = l.filter((item) => item.status !== "Deleted");
        // delete is allowed for last 2 records only
        l.slice(0, 2).forEach((n) => {
            n.canDelete = true;
        });
    });

    // https://github.com/TanStack/table/issues/4241 does not seem to work now
    // const columnHelper = createColumnHelper<GetNavsQuery["navs"]>();
    /*
            columnHelper.accessor("_id", {
                header: "Id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/nav2/${row.original._id}`}>
                            <div>#</div>
                        </Link>
                    );
                },
                size: 1
            }),
    */

    const columns = useMemo(
        () => [
            {
                accessorKey: "_id",
                header: "Id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Link to={`/nav2/${row.original._id}`}>
                            <div>#</div>
                        </Link>
                    );
                },
                size: 1
            },
            {
                accessorKey: "clientName",
                header: "Client",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.clientId}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                accessorKey: "date",
                header: "Date",
                cell: (cellProps) => {
                    return <div className="text-center">{cellProps.getValue()}</div>;
                }
            },
            {
                accessorKey: "createTimestamp",
                header: "Create timestamp",
                cell: (cellProps) => {
                    return <div className="text-center">{cellProps.getValue()}</div>;
                }
            },
            {
                accessorKey: "updatedBy",
                header: "Updated by"
            },
            {
                accessorKey: "status",
                header: "Status",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const value = cellProps.getValue();
                    if (value !== "Deleted" && row.original.canDelete) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                    {value}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {["Deleted"].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={async () => {
                                                    if (changeTo === "Deleted") {
                                                        setModal({
                                                            showModal: true,
                                                            payload: {
                                                                _id: row.original._id,
                                                                publishedToKeys:
                                                                    row.original.publishedTo && row.original.publishedTo.length
                                                                        ? Object.keys(keyBy(row.original.publishedTo, "key"))
                                                                        : null
                                                            }
                                                        });
                                                    }
                                                }}
                                            >
                                                Change to {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else {
                        return <div>{value}</div>;
                    }
                }
            }
        ],
        [setModal]
    );

    return (
        <div>
            <div
                style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                onClick={() => {
                    download();
                }}
            >
                <Svgs.Excel />
            </div>
            <ReactTable columns={columns} data={navs} defaultHiddenColumns={[]} />
        </div>
    );
};

export function NavsPage(): React.ReactElement {
    const { queryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    let startDate: string = DateHelper.addDays(new Date(), -20).toISOString().slice(0, 10);
    if (queryArgs.startDate && isValidYYYYMMDD(queryArgs.startDate)) {
        startDate = queryArgs.startDate as string;
    }

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [{ fetching: loading, error, data }, refetch] = useGetNavsQuery({
        variables: { startDate: startDate },
        requestPolicy: "cache-and-network"
    });

    const [_, deleteNavFn] = useDeleteNavMutation();

    const [modal, setModal] = useState({ showModal: false, payload: null });

    useEffect(() => {
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            refetch();
        }
    }, [previousQueryArgs, queryArgs, refetch]);

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={
                        modal.payload.publishedToKeys
                            ? "Are you sure you want to delete NAV with id " +
                              modal.payload._id +
                              "? " +
                              "\n\n" +
                              "WARNING" +
                              "\n" +
                              "NAV reports has been sent, make sure to contact the following recipients of deleted nav to inform them about the change:\n- " +
                              modal.payload.publishedToKeys.join("\n- ")
                            : "Are you sure you want to delete NAV with id " + modal.payload._id + "? "
                    }
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        const variables: { _id: string } = modal.payload;
                        deleteNavFn(variables)
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    refetch();
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}
            {alert.visible ? (
                <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
            <div className="d-flex mb-2">
                <DateForm defaultDateString={startDate} label="Start date" dateName={"startDate"}></DateForm>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <Navs setModal={setModal} navsData={data.navs} />
                </div>
            </div>
        </div>
    );
}
