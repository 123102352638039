import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { CountryCodeEnum, CurrencyEnum, InstrumentModelTypeEnum, InstrumentPosition, TrueFalseNone } from "../../types.generated";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { FiTypeEnum, getFiTypeForBond } from "../../common/accounting/FiReport";
import { ReactTable } from "../../components/react-table/ReactTable";
import { DateForm } from "../../common/dateForm";
import { useQueryState } from "../../common/use-query-state";
import { sortBy } from "lodash";

const GET_DATA = gql`
    query allInstrumentsInPosition($endDate: GraphQLDateString) {
        allInstrumentsInPosition(endDate: $endDate, modelTypes: Bond) {
            instrument {
                _id
                name
                isin
                modelType
                currency
                issuerId
                issuer {
                    name
                    country
                    industryCode
                }
                issuerProgramId
                issuerProgram {
                    name
                    covered
                    issuer {
                        name
                    }
                }
            }
        }
    }
`;

interface ProcessedData {
    _id: string;
    name: string;
    isin: string;
    modelType: InstrumentModelTypeEnum;
    currency: CurrencyEnum;
    issuerId: string;
    issuerName: string;
    issuerCountry: CountryCodeEnum;
    issuerProgramId: string;
    issuerProgramName: string;
    issuerProgramCovered: TrueFalseNone;
    issuerProgramIssuerName: string;
    fiClassification: FiTypeEnum;
}

export const getTodayDateAsString = (): string => {
    const today = new Date();
    const todayString = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000).toISOString().substring(0, 10);
    return todayString;
};

export const SwedishFiClassificationPage = (): React.ReactElement => {
    const [endDate] = useQueryState("endDate", getTodayDateAsString());
    const [{ fetching, data, error }] = useQuery({
        query: GET_DATA,
        variables: {
            endDate: endDate
        },
        requestPolicy: "cache-and-network"
    });

    const processedData: ProcessedData[] = React.useMemo(() => {
        if (data) {
            const positions = data.allInstrumentsInPosition as InstrumentPosition[];
            const processedData: ProcessedData[] = [];

            for (const position of positions) {
                const instrument = position.instrument;
                if (instrument) {
                    const fiClassification: FiTypeEnum = instrument.issuer
                        ? getFiTypeForBond(instrument.issuer, instrument.issuerProgram)
                        : null;
                    const dataItem: ProcessedData = {
                        _id: instrument._id,
                        name: instrument.name,
                        isin: instrument.isin,
                        modelType: instrument.modelType,
                        currency: instrument.currency,
                        issuerId: instrument.issuerId,
                        issuerName: instrument.issuer ? instrument.issuer.name : null,
                        issuerCountry: instrument.issuer ? instrument.issuer.country : null,
                        issuerProgramId: instrument.issuerProgramId,
                        issuerProgramName: instrument.issuerProgram ? instrument.issuerProgram.name : null,
                        issuerProgramCovered: instrument.issuerProgram ? instrument.issuerProgram.covered : TrueFalseNone.None,
                        issuerProgramIssuerName: instrument.issuerProgram
                            ? instrument.issuerProgram.issuer
                                ? instrument.issuerProgram.issuer.name
                                : null
                            : null,
                        fiClassification
                    };
                    processedData.push(dataItem);
                }
            }
            return sortBy(processedData, "name");
        } else {
            return [];
        }
    }, [data]);

    const columns = useMemo(() => {
        if (!processedData) return [];
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 150
            },
            {
                header: "Instrument",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Isin",
                accessorKey: "isin",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter,
                size: 35
            },
            {
                header: "Issuer",
                accessorKey: "issuerName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerId) {
                        return <Link to={`/parties/${row.original.issuerId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 100
            },
            {
                header: "Issuer country",
                id: "issuerCountry",
                accessorKey: "issuerCountry",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerId) {
                        return <Link to={`/parties/${row.original.issuerId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "Program",
                id: "issuerProgramName",
                accessorKey: "issuerProgramName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerProgramId) {
                        return <Link to={`/issuerprograms/${row.original.issuerProgramId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 100
            },
            {
                header: "Program covered",
                id: "issuerProgramCovered",
                accessorKey: "issuerProgramCovered",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerProgramId) {
                        return <Link to={`/issuerprograms/${row.original.issuerProgramId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "FI classification",
                accessorKey: "fiClassification",
                filter: SelectColumnFilter,
                size: 100
            }
        ];
    }, [processedData]);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.allInstrumentsInPosition === null) return <div>No bonds in position</div>;
    return (
        <div>
            <div className="row mt-4">
                <div className="col-xs-6 col-sm-4">
                    <DateForm defaultDateString={endDate} label="End date" dateName={"endDate"} className="width-fixed"></DateForm>
                </div>
            </div>
            <div className="row-col mt-4">
                <ReactTable columns={columns} data={processedData} defaultHiddenColumns={["_id"]} exportToXlsxFile="classification.xlsx" />
            </div>
        </div>
    );
};
