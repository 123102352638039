import React from "react";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { TimeSeries } from "../../common/src";

import * as PlotlyDefaults from "./PlotlyDefaults";

const Plot = createPlotlyComponent(Plotly);

type TimeSeriesChartInput = {
    timeseries: TimeSeries[];
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: PartialDeep<Plotly.Layout>;
};

export const TimeSeriesChart = ({ timeseries, layout, config, ...props }: TimeSeriesChartInput): React.ReactElement => {
    const colors = ["#66BC6B", "#BDBDBD", "#B3E5FC", "#1F5F32", "#757575", "#01579B", "#AB47BC", "#4A148C"];

    const traces = timeseries.map((ts, index) =>
        PlotlyDefaults.toTrace(ts.normalize(1).add(-1), ts.name, colors[index])
    ) as Partial<Plotly.PlotData>[];

    const maxArray = traces.map((trace) => (trace.y as any).reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const max = Math.max(maxArray.reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const localLayout: PartialDeep<Plotly.Layout> = {
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        yaxis: { tickformat: max < 0.06 ? ".1%" : ".0%" },

        xaxis: {
            title: "",
            hoverformat: "%Y-%m-%d",
            zeroline: false
        },
        width: null,
        height: null,

        margin: { l: 50, b: 70, t: 5 },
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",

            y: -0.25,
            yanchor: "bottom"
        }
    };
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return <Plot data={traces} config={thisConfig} layout={thisLayout} useResizeHandler={true} className="w-100" {...props} />;
};
