import React, { useState } from "react";
import { gql, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { sortBy } from "lodash";
import { Tabs, Tab } from "react-bootstrap";
import { Party } from "../../types.generated";

import {
    CollateralView,
    CashAccountView,
    TransactionView,
    TradingManagerView,
    FxView,
    CashLadderView,
    ReportsView,
    CounterpartyView,
    FxRiskView,
    PortfolioManagerCommentTabs,
    DashboardView,
    PreTradeView
} from "../../components/portfoliomanager";
import { SelectDropdown } from "../../components/SelectDropdown";

const getClients = gql`
    query {
        parties(filter: { typeIn: [Fund], statusIn: [Confirmed] }) {
            _id
            name
        }
    }
`;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const SelectClients = ({ selectedClientId, setSelectedClientId }) => {
    const [{ fetching, error, data }] = useQuery({ query: getClients });

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>`Error! ${error.message}`</div>;
    if (typeof selectedClientId === "undefined") {
        document.title = "All portfolios";
    } else {
        const obj = data.parties.find((o) => o._id === selectedClientId);
        if (typeof obj !== "undefined") {
            document.title = obj.name;
        }
    }
    let options = [];
    data.parties.forEach((party: Party) => {
        options.push({ key: party._id, text: party.name });
    });
    // Temp to add funds up to inception of fund. Delete after fund started
    if (data.parties.filter((e) => e._id === "649d4a855de897ec4079d879").length == 0) {
        options.push({ key: "649d4a855de897ec4079d879", text: "Captor Perenne Short Term Bond" });
    }
    if (data.parties.filter((e) => e._id === "649d36255de897ec4079bbae").length == 0) {
        options.push({ key: "649d36255de897ec4079bbae", text: "Captor Global Fixed Income" });
    }

    options = sortBy(options, "text");
    options.unshift({ key: "all", text: "All" }); // 'All' option

    return (
        <div className="row">
            <div className="col-12 col-sm-4">
                <SelectDropdown
                    placeholder="All"
                    className="form-control form-select"
                    defaultValue={selectedClientId}
                    options={options}
                    onChange={(e) => {
                        setSelectedClientId(e.key);
                    }}
                />
            </div>
        </div>
    );
};

export const PortfolioManager = (): React.ReactElement => {
    const { id, tab } = useParams<"id" | "tab">();
    const [activeTab, setActiveTab] = useState(tab || "transactions");
    const [checkedTransaction, setCheckedTransaction] = useState(false);
    const [checked2, setChecked2] = useState(true);
    const [tradingView, setTradingView] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleCheckboxChangeTransaction = (e) => {
        setCheckedTransaction(e.target.checked);
    };

    const handleCheckboxChange2 = (e) => {
        setChecked2(e.target.checked);
    };

    const handleTradingViewChange = (e) => {
        setTradingView(e.target.checked);
    };

    const setSelectedClientId = (id: string) => {
        if (tab) {
            navigate(`/pm/${id}/${tab}${location.search}`);
        } else {
            navigate(`/pm/${id}${location.search}`);
        }
    };

    const switchTab = (tab: string): void => {
        setActiveTab(tab);

        if (id) {
            navigate(`/pm/${id}/${tab}${location.search}`);
        } else {
            navigate(`/pm/all/${tab}${location.search}`);
        }
    };

    const trans_link = id === "all" || !id ? "/transactions" : "/transactions?qClientId=" + id;

    return (
        <div>
            <div className="row mb-4">
                <div className="col-10">
                    <h4>Client</h4>
                    <SelectClients selectedClientId={id} setSelectedClientId={setSelectedClientId} />
                </div>
            </div>

            <Tabs onSelect={switchTab} activeKey={activeTab} transition={false}>
                <Tab eventKey="transactions" title="Transactions">
                    {activeTab === "transactions" && (
                        <div>
                            <div>
                                <label>
                                    <Checkbox checked={checkedTransaction} onChange={handleCheckboxChangeTransaction} />
                                    <span>
                                        {"Show all types and history (Or go to "} <a href={trans_link}> /transactions</a>{" "}
                                        {"for detailed view.)"}
                                    </span>
                                </label>
                            </div>
                            <TransactionView viewAll={checkedTransaction} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="positions" title="Positions">
                    {activeTab === "positions" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                                <label className="ms-4">
                                    <Checkbox checked={tradingView} onChange={handleTradingViewChange} />
                                    <span>Trading view</span>
                                </label>
                            </div>
                            <TradingManagerView lookThrough={checked2} tradingView={tradingView} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="cashAccounts" title="Cash accounts">
                    {activeTab === "cashAccounts" && <CashAccountView />}
                </Tab>
                <Tab eventKey="collateral" title="Collateral">
                    {activeTab === "collateral" && <CollateralView />}
                </Tab>
                <Tab eventKey="fx" title="FX">
                    {activeTab === "fx" && <FxView />}
                </Tab>
                <Tab eventKey="cashLadder" title="Cash ladder">
                    {activeTab === "cashLadder" && <CashLadderView />}
                </Tab>
                <Tab eventKey="counterparties" title="Counterparties">
                    {activeTab === "counterparties" && <CounterpartyView />}
                </Tab>
                <Tab eventKey="fxRisk" title="FX risk">
                    {activeTab === "fxRisk" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <FxRiskView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="reports" title="Reports">
                    {activeTab === "reports" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <ReportsView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="comment" title="Comment">
                    {activeTab === "comment" && <PortfolioManagerCommentTabs />}
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                    {activeTab === "dashboard" && (
                        <div>
                            <DashboardView />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="preTrade" title="Pre trade">
                    {activeTab === "preTrade" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <PreTradeView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
};
