import { gql } from "urql";

export const getParty = gql`
    query getParty($_id: GraphQLObjectId!, $includeVersions: Boolean = false) {
        party(_id: $_id) {
            _id
            aliases {
                collection
                documentId
                key
                value
                comment
            }
            clientIds
            name
            longName
            email
            accountant
            accountingCurrency
            accountingFrequency
            accountingCompanyType
            address
            approval
            bloombergEquityTicker
            businessIdentifierCode
            ceo
            complaintsManager
            creditRatingScale
            country
            description
            industryCode
            inLedgerTradeDate
            issuerType
            legalEntityIdentifier
            legalNumber
            number
            nace
            sustainalyticsId
            parentId
            phoneNumber
            regulator
            status
            types
            timezone
            updateTimestamp
            versions @include(if: $includeVersions)
            website
            accounts {
                _id
                defaultAccount
                description
                name
                number
                securityExternalAccountId
                clientId
                clientName
                defaultCashInstruments {
                    currency
                    partyInstrumentId
                }
                defaultDebtInstruments {
                    currency
                    partyInstrumentId
                }
                defaultAccruedInstruments {
                    currency
                    partyInstrumentId
                }
                transientCashInstruments {
                    currency
                    partyInstrumentId
                }
                modelIndexBenchmark {
                    name
                }
                type
                parentAccountId
                benchmarks {
                    instrumentId
                    offset
                    currency
                    comment
                    showBenchmark
                    showExternal
                    mainBenchmark
                }
                weights {
                    date
                    model
                    min
                    max
                    expectedReturn
                }
                childrenAccounts {
                    _id
                    description
                    name
                }
            }
            creditRatings {
                creditRatingAgencyId
                grade
            }
            externalAccounts {
                _id
                custodianAccountId
                custodianAccountNumber
                custodianId
                description
                name
                number
                type
                emirReporting
                roundingAmount
                threshold
                minimumTransferAmount
                attachments {
                    fileId
                    fileName
                    mimeType
                    mD5
                    updateTimestamp
                }
            }
            fundInfo {
                endDate
                fundDescription
                calendarNames
                classes {
                    mainClass
                    dividendPaying
                    enabled
                    fxHedgeAccountId
                    instrumentId
                    managementFee
                    managementFeeStruct
                    minimumInitialSubscription
                    minimumSubscriptionAdded
                    numberOfDecimalsNAVPerUnit
                    numberOfDecimalsUnits
                    redemptionFee
                    shareClass
                    sIFA_ID
                    sIFA_UNIT
                    startDate
                    startNAVPerUnit
                    subscriptionFee
                    tradeCutOff
                    sfdr
                    fxHedged
                    cleanClass
                    carryOwnCostPossible
                }
                createRedeemInstrumentId
                custodianId
                depositaryId
                fundCompanyId
                fundManager
                fundType
                fundReportTypes
                portfolio
                taId
                tradeFrequency
                riskLevel
                esgComponents
                fundReportComponents
            }
            issuer {
                esg {
                    productInvolvement {
                        involvement
                        range
                    }
                    globalStandardScreen {
                        status
                        norm
                        date
                        principle
                        comment
                    }
                    shareholderEngagement {
                        method
                        date
                        principle
                        norm
                        agm
                        comment
                    }
                    comments {
                        comment
                        date
                    }
                }
                iorp
                transitionCompany
                captorDnshCompliant
                ghgEmissions
                carbonFootprint
                ghgIntensityOfInvesteeCompanies
                fossilFuelCompaniesExposure
                nonRenewableEnergyShare
                energyIntensityHighImpact
                activitiesAffectingBiodiversityAreas
                pollutionOfWater
                hazardousWasteRatio
                deforestation
                recordableWorkAccidents
                lackOfProcessesAndCompliance
                unadjustedGenderPayGap
                managementSupervisoryGenderDiversity
                scienceBasedTargetInitiative
                nearTermTargetClassification
                netZeroCommitted
            }
            instruments {
                _id
                aliases {
                    collection
                    documentId
                    key
                    value
                    comment
                }
                bloombergTicker
                category
                currency
                externalAccountId
                quantityDecimals
                accountId
                isin
                longName
                name
                productType
                status
                bic
                iban
                clientIds
            }
            standardSettlementInstructions {
                agentBusinessIdentifierCode
                beneficiaryBusinessIdentifierCode
                iban
                placeOfSettlementId
                securityAccount
                buyerSellerSafe
                type
                tradeType
            }
            beneficialOwner {
                _id
                name
            }
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }

        parties: parties {
            _id
            name
        }

        instruments {
            _id
            name
        }

        instrumentsUsedBy(clientIds: [$_id]) {
            _id
            name
        }

        custodians: parties(filter: { typeIn: [Custodian] }) {
            _id
            name
        }

        creditRatingAgencies: parties(filter: { typeIn: [CreditRatingAgency] }) {
            _id
            name
        }

        placesOfSettlement: parties(filter: { typeIn: [PlaceOfSettlement] }) {
            _id
            name
        }

        fundInternals: instruments(filter: { modelTypeIn: [FundInternal] }) {
            _id
            name
        }

        issuerInstruments: instruments(filter: { issuerIdIn: [$_id] }) {
            _id
            name
            currency
        }

        issuerPrograms: issuerprograms(filter: { issuerIdIn: [$_id] }) {
            _id
            name
            category
            spoProvider {
                _id
                name
            }
            status
        }
    }
`;

export const updatePartyFundInfo = gql`
    mutation updatePartyFundInfo($partyId: GraphQLObjectId!, $input: PartyFundInfoInput!) {
        party: updatePartyFundInfo(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

export const updatePartyIssuer = gql`
    mutation updatePartyIssuer($partyId: GraphQLObjectId!, $input: PartyIssuerInput!) {
        party: updatePartyIssuer(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

export const deletePartyAccount = gql`
    mutation deletePartyAccount($partyId: GraphQLObjectId!, $accountId: GraphQLObjectId!) {
        deletePartyAccount(partyId: $partyId, accountId: $accountId)
    }
`;

export const deletePartyExternalAccount = gql`
    mutation deletePartyExternalAccount($partyId: GraphQLObjectId!, $externalAccountId: GraphQLObjectId!) {
        deletePartyExternalAccount(partyId: $partyId, externalAccountId: $externalAccountId)
    }
`;

export const createPartyBase = gql`
    mutation createPartyBase($partyId: GraphQLObjectId!, $input: PartyBaseInput!) {
        party: createPartyBase(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

export const updatePartyBase = gql`
    mutation updatePartyBase($partyId: GraphQLObjectId!, $input: PartyBaseInput!) {
        party: updatePartyBase(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

export const deleteInstrument = gql`
    mutation deleteInstrument($_id: GraphQLObjectId!) {
        deleteInstrument(_id: $_id)
    }
`;
