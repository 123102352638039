import React, { Fragment } from "react";

import { numberFormatFun, numberFormat, twoDecPriceFormat } from "../../../../common/src";
import { Grid, Column } from "../../../../components/src";
import { TooltipLabel } from "../../components/TooltipLabel";
import { ReconciledExposureCollateralType } from "../../types.generated";

interface NetExposureGridInput {
    header: string;
    data: ReconciledExposureCollateralType[];
}
export const NetExposureGrid = ({ header, data }: NetExposureGridInput): React.ReactElement => {
    const variationMarginHeader = "Variation margin reconciliation";
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <Grid header={header} data={data} sortable tableClassName="table-xs">
                        <Column field="name" title="Name" className="grid-column-sticky" />
                        <Column field="currency" title="Ccy" />
                        <Column
                            field={header === variationMarginHeader ? "variationMarginCollateral" : "initialMarginCollateral"}
                            title="Our cash balance"
                            format={(value) => {
                                return twoDecPriceFormat(-Number(value));
                            }}
                            className={"right"}
                        />
                        <Column
                            field={
                                header === variationMarginHeader
                                    ? "counterpartyVariationMarginCollateral"
                                    : "counterpartyInitialMarginCollateral"
                            }
                            title="Cpty cash balance"
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "Missing report";
                                } else {
                                    return twoDecPriceFormat(Number(value));
                                }
                            }}
                            className={(item) => {
                                if (item && item.missingReport) {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field={
                                header === variationMarginHeader
                                    ? "variationMarginCollateralDifference"
                                    : "initialMarginCollateralDifference"
                            }
                            title={<TooltipLabel text={"Difference"} content="Our cash balance - Cpty cash balance" />}
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "-";
                                } else if (
                                    ((item && header === variationMarginHeader && item.variationMarginCollateralDifference !== 0) ||
                                        (item && header !== variationMarginHeader && item.initialMarginCollateralDifference !== 0)) &&
                                    item.name !== "Sum" &&
                                    item.name !== "Sum base ccy"
                                ) {
                                    return (
                                        <TooltipLabel text={twoDecPriceFormat(value)} content="Difference tolerance is 0, please check." />
                                    );
                                } else {
                                    return twoDecPriceFormat(value);
                                }
                            }}
                            className={(d) => {
                                if (
                                    ((d && header === variationMarginHeader && d.variationMarginCollateralDifference !== 0) ||
                                        (d && header !== variationMarginHeader && d.initialMarginCollateralDifference !== 0)) &&
                                    d.name !== "Sum" &&
                                    d.name !== "Sum base ccy" &&
                                    !d.missingReport
                                ) {
                                    return "right nowrap btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field={
                                header === variationMarginHeader
                                    ? "variationMarginSecuritiesCollateral"
                                    : "initialMarginSecuritiesCollateral"
                            }
                            title={
                                <TooltipLabel
                                    text={"Our securities collateral valuation"}
                                    content="Our securities collateral valuation. Does not include haircuts."
                                />
                            }
                            format={numberFormatFun("# ##0")}
                            className={"right"}
                        />
                        <Column
                            field={
                                header === variationMarginHeader
                                    ? "counterpartyVariationMarginSecuritiesCollateral"
                                    : "counterpartyInitialMarginSecuritiesCollateral"
                            }
                            title={
                                <TooltipLabel
                                    text={"Cpty securities collateral valuation"}
                                    content="Counterparty securities collateral valuation. Include haircuts."
                                />
                            }
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "Missing report";
                                } else {
                                    return numberFormat(-Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport) {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field={
                                header === variationMarginHeader
                                    ? "variationMarginSecuritiesCollateralDifference"
                                    : "initialMarginSecuritiesCollateralDifference"
                            }
                            title="Difference"
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "-";
                                } else {
                                    return numberFormat(Number(value), "# ##0");
                                }
                            }}
                            className={"right"}
                        />
                        <Column
                            field={header === variationMarginHeader ? "netExposure" : "initialMarginRequirement"}
                            title={header === variationMarginHeader ? "Our net exposure" : "Cpty initial margin required"}
                            format={(value, item) => {
                                if (item && item.missingReport && header !== variationMarginHeader) {
                                    return "Missing report";
                                } else {
                                    return numberFormat(Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport && header !== variationMarginHeader) {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field={header === variationMarginHeader ? "counterpartyNetExposure" : "counterpartyInitialMarginNetRequirement"}
                            title="Cpty net exposure"
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "Missing report";
                                } else {
                                    return numberFormat(-Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport) {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        {header === variationMarginHeader ? (
                            <Column
                                field={"netExposureDifference"}
                                title={<TooltipLabel text={"Difference"} content="Our net exposure - Cpty net exposure" />}
                                format={(value, item) => {
                                    if (item && item.missingReport) {
                                        return "-";
                                    } else {
                                        return numberFormat(Number(value), "# ##0");
                                    }
                                }}
                                className={"right"}
                            />
                        ) : null}
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};
