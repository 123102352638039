import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";

import { PartialDeep } from "type-fest";
import { TimeSeries, DateHelper, numberFormatFun } from "../../../common/src";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

const format = numberFormatFun("0.0%");

interface IBarReturnsProps {
    timeseries: TimeSeries[];
    resampleFrequency?: "yearly" | "monthly";
    showReferencePortfolio?: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const BarReturns = ({
    layout,
    timeseries,
    resampleFrequency,
    showReferencePortfolio,
    config,
    ...props
}: IBarReturnsProps): React.ReactElement => {
    let resampledTs: TimeSeries;
    let trace: any;
    let text: string[];
    const textfont = 14;
    let x: string[] | Date[];
    let y: number[];
    let labels: number[];

    const masterTimeSerie = timeseries[0];
    const referenceTraces = [];

    if (resampleFrequency === "monthly") {
        resampledTs = masterTimeSerie.resampleToCalenderMonthly().return();
        text = resampledTs.__dates.map((d) => new Date(d).toLocaleString("default", { month: "short" }));
        labels = resampledTs.__dates.map((d) => DateHelper.getMonth(d));
        x = resampledTs.__dates;
        y = resampledTs.__values;

        trace = {
            name: masterTimeSerie.name,
            labels: labels,
            x: x,
            y: y,
            textposition: "auto",
            text: text,
            textfont: {
                size: textfont
            },
            type: "bar",
            hovertemplate: "<extra></extra>%{x| %b %Y} %{y:.1%}",
            line: { width: 0 },
            marker: { color: PlotlyDefaults.getColor(1) }
        };

        if (showReferencePortfolio) {
            for (let i = 1; i < timeseries.length; i++) {
                const referenceTs = timeseries[i];
                const resampledReferenceTs = referenceTs.resampleToCalenderMonthly().return();
                const referenceTrace = {
                    x: resampledReferenceTs.__dates,
                    y: resampledReferenceTs.__values,
                    name: referenceTs.name,
                    type: "bar",
                    hovertemplate: "<extra></extra>%{x| %b %Y} %{y:.1%}",
                    marker: { color: "#bdbdbd" },
                    textfont: {
                        size: textfont
                    }
                };
                referenceTraces.push(referenceTrace);
            }
        }
    } else {
        const years = [];
        const year = DateHelper.getYear(masterTimeSerie.end);
        for (let i = year; i >= DateHelper.getYear(masterTimeSerie.start); i--) {
            if (i >= DateHelper.getYear(masterTimeSerie.start)) {
                years.push(i);
            }
        }

        const returns = years.map((year) => masterTimeSerie.specificYearReturn(year));

        text = returns.map((r) => format(r));
        labels = null;
        x = years;
        y = returns;

        trace = {
            name: masterTimeSerie.name,
            labels: labels,
            x: x,
            y: y,
            textposition: "outside",
            text: text,
            textfont: {
                size: textfont
            },
            type: "bar",
            hovertemplate: "<extra></extra>%{x| %Y} %{y:.1%}",
            line: { width: 0 },
            marker: { color: PlotlyDefaults.getColor(1) }
        };

        if (showReferencePortfolio) {
            for (let i = 1; i < timeseries.length; i++) {
                const referenceTs = timeseries[i];
                const referenceReturn = years.map((year) => referenceTs.specificYearReturn(year));
                const referencetext = referenceReturn.map((r) => format(r));
                const referenceTrace = {
                    x: years,
                    y: referenceReturn,
                    name: referenceTs.name,
                    type: "bar",
                    hovertemplate: "<extra></extra>%{x| %Y} %{y:.1%}",
                    marker: { color: "#bdbdbd" },
                    text: referencetext,
                    textposition: "outside",
                    textfont: {
                        size: textfont
                    }
                };
                referenceTraces.push(referenceTrace);
            }
        }
    }

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        legend: { traceorder: "normal", x: 0.5, y: -0.2, orientation: "v" },
        xaxis: { type: "date", tickformat: "%Y", dtick: "M12" }, //dtick: "M1" },
        yaxis: {
            tickformat: ".0%",
            automargin: true,
            showgrid: false
        },
        margin: { t: 40, b: 40 }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const traces = showReferencePortfolio ? [trace, ...referenceTraces] : [trace];

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }
    return (
        <React.Fragment>
            <div className="plotly-print">
                <Plot data={traces as Plotly.PlotData[]} config={thisConfig as Plotly.Config} layout={thisLayout} {...props} />
            </div>
        </React.Fragment>
    );
};
