import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { gql, useMutation, useQuery } from "urql";
import { Alert } from "react-bootstrap";
import stableStringify from "json-stable-stringify";

import { useQueryState } from "../../common/use-query-state";
import { AnnualReportComponent } from "./AnnualReportComponent";
import { AnnualReportEditor } from "./AnnualReportEditor";
import { CreateReportInput, ReportStatusEnum } from "../../types.generated";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { generateRandomString } from "../../common/Utils";

const GET_REPORT = gql`
    query report($_id: GraphQLObjectId!) {
        report(_id: $_id) {
            _id
            name
            status
            clientId
            clientName
            date
            type
            updateUserId
            updateUserInfo {
                name
            }
            data
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation updateReport($input: UpdateReportInput!) {
        report: updateReport(input: $input) {
            _id
            name
            status
            clientId
            clientName
            date
            type
            updateUserId
            updateUserInfo {
                name
            }
            data
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($input: CreateReportInput!) {
        report: createReport(input: $input) {
            _id
            name
            status
            clientId
            clientName
            date
            type
            updateUserId
            updateUserInfo {
                name
            }
            data
        }
    }
`;

export const AnnualReportPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [edit, setEdit] = useQueryState("edit", false);
    const [report, setReport] = useState(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [{ fetching, error, data }] = useQuery({
        query: GET_REPORT,
        variables: { _id: id },
        requestPolicy: "cache-and-network"
    });

    const [_, createReport] = useMutation(CREATE_REPORT);

    useEffect(() => {
        if (!report && data && data.report) {
            setReport(data.report);
        } else if (data && stableStringify(data.report) !== stableStringify(report)) {
            setReport(data.report);
        }
    }, [data, id, report]);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>Error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <div className="print-none">
                <Link to={"/reporting/annualreports"}>Annual reports</Link>
            </div>

            <div className="mt-2 print-none">
                <Button
                    type="button"
                    className="btn-sm"
                    onClick={() => {
                        setEdit(!edit);
                    }}
                >
                    {!edit ? "Edit" : "Stop editing"}
                </Button>
                <Button
                    type="button"
                    className="btn-sm m-3"
                    onClick={async () => {
                        const input: CreateReportInput = {
                            clientId: report.clientId,
                            data: report.data,
                            date: report.date,
                            name: report.name + "_" + generateRandomString(10),
                            status: ReportStatusEnum.Active,
                            type: "AnnualReport"
                        };

                        await createReport({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setReport(result.data.report);
                                    navigate("/reporting/annualreports/" + result.data.report._id, { replace: false });
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                >
                    Clone
                </Button>
                <Button
                    title="Print"
                    className="icon-print print-none"
                    onClick={() => {
                        const title = document.title;
                        document.title = "annual-report-" + report.date + "-" + report.clientName.replace(/ /g, "-").toLowerCase();
                        window.print();
                        document.title = title;
                    }}
                >
                    <i className="glyphicon glyphicon-print"></i>
                </Button>
            </div>

            <div className="form-row">
                {alert.visible ? (
                    <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                        {alert.message}
                    </Alert>
                ) : null}
            </div>

            {report && report.status === ReportStatusEnum.Locked ? (
                <div className="print-none">
                    <i style={{ color: "red" }}>{"This report has status Locked, not possible to edit anymore."}</i>
                </div>
            ) : null}
            {report ? (
                <div className="mt-2">{edit ? <AnnualReportEditor report={report} /> : <AnnualReportComponent report={report} />}</div>
            ) : null}
        </div>
    );
};
