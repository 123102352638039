import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const SlashPage = (): React.ReactElement => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/client", { replace: true });
    }, [navigate]);

    return <div></div>;
};
