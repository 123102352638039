import React, { ReactElement, useState } from "react";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const getAttachment = gql`
    query getAttachment($_id: GraphQLObjectId!) {
        attachment(_id: $_id) {
            fileId
            fileName
            base64
            mimeType
        }
    }
`;

export const PdfViewPage = (): ReactElement => {
    const { id }: any = useParams();
    const [numPages, setNumPages] = useState(null);

    const [{ fetching, error, data }] = useQuery({ query: getAttachment, variables: { _id: id } });

    if (fetching) return <p>Loading</p>;
    if (error) return <p>Error: {JSON.stringify(error, null, 2)}</p>;

    const dataURI = "data:" + data.attachment.mimeType + ";base64," + data.attachment.base64;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const allPages = [...Array(numPages + 1).keys()].slice(1);

    return (
        <div className="pb-4">
            <Document file={dataURI} onLoadSuccess={onDocumentLoadSuccess}>
                {allPages.map((page, ndx) => (
                    <Page key={`page${ndx}`} pageNumber={page} />
                ))}
            </Document>
        </div>
    );
};
