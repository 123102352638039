import React from "react";

import Icndoc from "./icn_doc.svg";
import Icnfont2 from "./icn_font-2.svg";
import Icnpdf from "./icn_pdf.svg";
import Icnpng from "./icn_png.svg";
import Icnxlm from "./icn_xlm.svg";
import Icnzip from "./icn_zip.svg";

export const FileIcon = ({ mimeType }: { mimeType: string }) => {
    if (mimeType === "aplication/msword") {
        return <img src={Icndoc} alt="msword" />;
    } else if (mimeType === "application/zip") {
        return <img src={Icnzip} alt="zip" />;
    } else if (mimeType === "application/pdf") {
        return <img src={Icnpdf} alt="pdf" />;
    } else if (mimeType === "application/vnd.ms-excel") {
        return <img src={Icnxlm} alt="excel" />;
    } else if (mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return <img src={Icnxlm} alt="excel" />;
    } else if (mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return <img src={Icndoc} alt="msword" />;
    } else if (mimeType === "image/bmp") {
        return <img src={Icnpng} alt="bmp" />;
    } else if (mimeType === "image/jpg") {
        return <img src={Icnpng} alt="jpg" />;
    } else if (mimeType === "image/png") {
        return <img src={Icnpng} alt="png" />;
    } else if (mimeType === "text/csv") {
        return <img src={Icnxlm} alt="csv" />;
    } else if (mimeType === "text/plain") {
        return <img src={Icnfont2} alt="text" />;
    } else {
        return <img src={Icnpng} alt="unknown" />;
    }
};
