import React, { Fragment, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { cloneDeep } from "lodash";
import { twoDecPriceFormat } from "../../../common/src";

import { YesNoModal } from "../components/YesNoModal";
import { DateForm } from "../common/dateForm";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { MiniTransactionForm } from "../containers/MiniTransactionForm";
import { CorporateActionForm } from "../containers/CorporateActionForm";
import { useQueryState } from "../common/use-query-state";
import { MultipleSelectDetailsGrid } from "./MultipleSelectDetailsGrid";
import { Panel, IColumn, ICommandBarItemProps } from "@fluentui/react";
import { SwiftStatusEnum } from "../types.generated";

const GET_SWIFTS_MINI = gql`
    query miniSwifts($miniFilterIn: MiniSwiftFilterInput) {
        miniSwifts(miniFilterIn: $miniFilterIn) {
            _id
            fundId
            fund {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            isin
            type
            instrument {
                _id
                name
            }
            currency
            amount
            foreignTax
            msgType
            caev
            status
            text
            externalId
            error
            correspondingTransactionId
            correspondingTransaction {
                _id
                status
            }
            updateTimestamp
            parsed
            reportIds
            name
        }
    }
`;

const UPDATE_SWIFT_TRANSACTION = gql`
    mutation UpdateSwifts($input: [UpdateSwiftInput!]!) {
        updateSwifts(input: $input) {
            _id
            status
        }
    }
`;

const swiftGroups = [
    SwiftStatusEnum.Pending,
    SwiftStatusEnum.Preliminary,
    SwiftStatusEnum.Waiting,
    SwiftStatusEnum.Confirmed,
    SwiftStatusEnum.Settled
];

const groupByStatus = (swifts, swiftGroups) => {
    const result = {};
    swiftGroups.forEach((status) => {
        result[status] = [];
    });

    swifts = sortBy(swifts, "exDate").reverse();
    swifts.forEach((swift) => {
        if (swiftGroups.includes(swift.status)) {
            result[swift.status].push(swift);
        }
    });
    const optimalSwiftGroups = cloneDeep(swiftGroups);
    const optimalResult = cloneDeep(result);
    const emptyStatuses = [];
    for (const key in result) {
        if (result[key].length === 0) {
            delete optimalResult[key];
            emptyStatuses.push(key);
        }
    }
    for (let i = optimalSwiftGroups.length - 1; i >= 0; i--) {
        const status = optimalSwiftGroups[i];
        if (emptyStatuses.includes(status)) {
            optimalSwiftGroups.splice(i, 1);
        }
    }
    return [optimalResult, optimalSwiftGroups];
};

export const CorporateActionsUpdateGrid = ({ items, onStatusUpdated }): React.ReactElement => {
    const location = useLocation();
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [stateUpdateSwift, updateSwiftStatus] = useMutation(UPDATE_SWIFT_TRANSACTION);

    useEffect(() => {
        if (stateUpdateSwift && stateUpdateSwift.data && !stateUpdateSwift.error) {
            if (onStatusUpdated) {
                onStatusUpdated();
            }
            setSelected(null);
        }
    }, [onStatusUpdated, stateUpdateSwift]);

    const columns: IColumn[] = [
        {
            key: "fundName",
            name: "Client",
            fieldName: "fundName",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 160,
            onRender: (item: any) => {
                return item.fund && item.fund._id ? <Link to={"/parties/" + item.fund._id}>{item.fund.name}</Link> : null;
            }
        },

        {
            key: "externalId",
            name: "Event ref",
            fieldName: "externalId",

            isResizable: true,
            minWidth: 110,
            onRender: (item: any) => {
                return item._id ? (
                    <Link to={"/swiftcorporateactions/corporateaction/" + item._id + location.search}>{item.externalId}</Link>
                ) : (
                    <span>{item.externalId}</span>
                );
            }
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 60
        },

        {
            key: "type",
            name: "Type",
            fieldName: "type",
            isResizable: true,
            minWidth: 50,
            onRender: (item: any) => {
                return item._id ? (
                    <Link to={"/swiftcorporateactions/corporateaction/" + item._id + location.search}>{item.caev}</Link>
                ) : (
                    <span>{item.caev}</span>
                );
            }
        },
        {
            key: "instrumentName",
            name: "Instrument",
            fieldName: "instrumentName",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return item.instrument ? <Link to={"/instruments/" + item.instrument._id}>{item.instrument.name}</Link> : null;
            }
        },
        {
            key: "foreignTax",
            name: "Foreign tax",
            fieldName: "foreignTax",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.foreignTax)}
                    </span>
                );
            }
        },
        {
            key: "amount",
            name: "Amount",
            fieldName: "amount",
            isResizable: true,
            minWidth: 60,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.amount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80,
            onRender: (item: any) => {
                return <span>{item.exDate}</span>;
            }
        },
        {
            key: "paymentDate",
            name: "Value date",
            fieldName: "paymentDate",
            minWidth: 80
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            isResizable: true,
            minWidth: 120
        },
        {
            key: "correspondingTransactionId",
            name: "Matched with",
            fieldName: "correspondingTransactionId",
            minWidth: 80,
            onRender: (item: any) => {
                return item.correspondingTransactionId ? (
                    <Link to={"/swiftcorporateactions/minitransaction/" + item.correspondingTransactionId + location.search}>
                        {item.correspondingTransactionId}
                    </Link>
                ) : (
                    <span>{item._id}</span>
                );
            }
        },
        {
            key: "updateTimestamp",
            name: "Update timestamp",
            fieldName: "updateTimestamp",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return <span>{dateFormater(item.updateTimestamp)}</span>;
            }
        }
    ];

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            updateStatus(selected, "Pending");
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to Preliminary",
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            updateStatus(selected, "Preliminary");
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            updateStatus(selected, "Confirmed");
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            updateStatus(selected, "Settled");
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            setModal({
                                showModal: true,
                                payload: { newStatus: "Deleted", ids: selected }
                            });
                        }
                    }
                ]
            }
        }
    ];

    const onSelectedChange = (e) => {
        setSelected(e.map((i) => i._id));
    };

    const updateStatus = async (itemIds: string[], newStatus) => {
        const allUpdates = itemIds.map(
            async (id: string) =>
                await updateSwiftStatus({
                    input: { _id: id, status: newStatus }
                })
        );
        await Promise.all(allUpdates);
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete selected corporate actions?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updateStatus(modal.payload.ids, "Deleted");
                    }}
                />
            ) : null}
            <MultipleSelectDetailsGrid
                key={items}
                items={items}
                columns={columns}
                commands={selected && selected.length > 0 ? commands : []}
                onSelectedChange={onSelectedChange}
            />
        </div>
    );
};

export const CorporateActionsPage = ({ exDateStart }: { exDateStart: string }): React.ReactElement => {
    const recordDate = serializeSwedenDate(new Date(exDateStart).setDate(new Date(exDateStart).getDate() + 1));

    // All swifts except deleted
    const filterAll = {
        statusIn: [
            SwiftStatusEnum.Pending,
            SwiftStatusEnum.Preliminary,
            SwiftStatusEnum.Waiting,
            SwiftStatusEnum.Confirmed,
            SwiftStatusEnum.Settled
        ],
        rdteStart: recordDate
    };
    const [{ fetching: loading, error, data: swifts }, refetch] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterAll },
        requestPolicy: "network-only"
        //pollInterval: 5000
    });

    if (loading) return <p>Loading corporate action swifts</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const allSwifts = swifts.miniSwifts;
    const [groups, optimalSwiftGroups] = groupByStatus(allSwifts, swiftGroups);

    return (
        <div>
            {optimalSwiftGroups.map((status) => (
                <div key={status} className="mt-4">
                    <h2>{status}</h2>
                    <CorporateActionsUpdateGrid items={groups[status]} onStatusUpdated={refetch} />
                </div>
            ))}
        </div>
    );
};

export function SwiftCorporateActionsPage(): React.ReactElement {
    const params: any = useParams();
    const id = params.id;
    const type = params.type;
    const [exDateStart] = useQueryState("exDateStart", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));

    const location = useLocation();
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="d-flex">
                <DateForm defaultDateString={exDateStart} dateName={"exDateStart"}></DateForm>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <CorporateActionsPage exDateStart={exDateStart} />
                </div>

                <Panel
                    isOpen={id != null}
                    isBlocking={false}
                    onDismiss={() => {
                        navigate({
                            pathname: "/swiftcorporateactions",
                            search: location.search
                        });
                    }}
                    layerProps={{ eventBubblingEnabled: true }}
                >
                    {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                    {id && type === "corporateaction" ? <CorporateActionForm id={id} tradeDate={exDateStart} /> : null}
                </Panel>
            </div>
        </Fragment>
    );
}
