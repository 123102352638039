import { cloneDeep, round } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

enum MatchingCoacsStatus {
    Mismatch = "Mismatch",
    Matched = "Matched",
    Preliminary = "Preliminary",
    Confirmed = "Confirmed"
}

interface UpdateFields {
    swiftTransactionId: string;
    swiftExternalId: string;
    swiftTransactionError: string;
    transactionId: string;
    transactionError: string;
    matchingStatus: MatchingCoacsStatus;
}

export const matchToleranceValue = (firstValue, secondValue) => {
    // Matching values with tolerance:
    const tolerance = 0.0;
    if (firstValue === secondValue || Math.abs(round(firstValue - secondValue, 2)) <= tolerance) {
        return true;
    } else {
        return false;
    }
};

// Matching booked corporate actions with the counterpartys parsed swifts
export const matchCoacsTransactionsFnc = (transactions, miniSwiftsUnknown, miniSwiftsToMatch) => {
    const counterpartyCoacsToMatch = cloneDeep(miniSwiftsToMatch);
    const counterpartyCoacsUnknown = cloneDeep(miniSwiftsUnknown);
    const coacs = cloneDeep(transactions);

    const updateFieldsList = [];
    for (let c = 0; c < coacs.length; c++) {
        const transaction = coacs[c];
        let matchingStatus = "Mismatch";
        for (let b = 0; b < counterpartyCoacsToMatch.length; b++) {
            const counterpartyTransaction = counterpartyCoacsToMatch[b];
            // Check if matching criteria fulfilled, make sure not matching already matched
            // coacs with new transaction unless the old one has been deleted
            if (
                ((counterpartyTransaction.correspondingTransactionId &&
                    counterpartyTransaction.correspondingTransactionId.toString() === transaction._id.toString() &&
                    transaction.brokerTradeId &&
                    counterpartyTransaction.externalId.toString() === transaction.brokerTradeId.toString()) ||
                    (counterpartyTransaction.correspondingTransactionId &&
                        counterpartyTransaction.correspondingTransaction &&
                        counterpartyTransaction.correspondingTransactionId.toString() !== transaction._id.toString() &&
                        counterpartyTransaction.correspondingTransaction.status === "Deleted") ||
                    (!counterpartyTransaction.correspondingTransactionId &&
                        !counterpartyTransaction.correspondingTransaction &&
                        !transaction.brokerTradeId)) &&
                ((counterpartyTransaction.instrument &&
                    transaction.instrumentId.toString() === counterpartyTransaction.instrument._id.toString() &&
                    transaction.clientId.toString() === counterpartyTransaction.fundId.toString() &&
                    counterpartyTransaction.exDate &&
                    dayjs.tz(transaction.tradeTimestamp, "Europe/Stockholm").format("YYYY-MM-DD") === counterpartyTransaction.exDate) ||
                    (counterpartyTransaction.instrument &&
                        transaction.instrumentId.toString() === counterpartyTransaction.instrument._id.toString() &&
                        transaction.clientId.toString() === counterpartyTransaction.fundId.toString() &&
                        counterpartyTransaction.caev === "INTR"))
            ) {
                matchingStatus = "Confirmed";
                // Removing old errors
                let newError;

                // Check if transactions match and generate error messages/confirm

                if (transaction.currency !== counterpartyTransaction.currency) {
                    newError = newError ? newError + ", currency does not match" : "Currency does not match";

                    matchingStatus = "Matched";
                }

                if (transaction.valueDate !== counterpartyTransaction.paymentDate) {
                    newError = newError ? newError + ", value date does not match" : "Value date does not match";

                    matchingStatus = "Matched";
                }

                // Preliminary/confirmed transactions
                if (
                    (counterpartyTransaction.caev === "DRIP" || counterpartyTransaction.caev === "DVOP") &&
                    !counterpartyTransaction.amount &&
                    !counterpartyTransaction.foreignTax
                ) {
                    matchingStatus = "Preliminary";
                } else if (
                    counterpartyTransaction.caev === "DVCA" ||
                    counterpartyTransaction.caev === "INTR" ||
                    counterpartyTransaction.caev === "SHPR" ||
                    counterpartyTransaction.caev === "CAPG" ||
                    counterpartyTransaction.caev === "CAPD" ||
                    counterpartyTransaction.caev === "DRIP" ||
                    counterpartyTransaction.caev === "DVOP"
                ) {
                    if (!matchToleranceValue(transaction.amount, counterpartyTransaction.amount)) {
                        newError = newError ? newError + ", amount does not match" : "Amount does not match";
                        matchingStatus = "Matched";
                    }

                    if (
                        (transaction.foreignTax ||
                            counterpartyTransaction.foreignTax ||
                            (transaction.foreignTax && counterpartyTransaction.foreignTax)) &&
                        !matchToleranceValue(-transaction.foreignTax, counterpartyTransaction.foreignTax)
                    ) {
                        newError = newError ? newError + ", foreignTax does not match" : "ForeignTax does not match";
                        matchingStatus = "Matched";
                    }
                } else {
                    matchingStatus = "Mismatch";
                }

                // Update status, transactions, correspondingTransactionId, brokerTradeId, error  when matched/unmatched
                const updateFields = {} as UpdateFields;
                // Only update if new information
                const correspondingTransactionIdString = counterpartyTransaction.correspondingTransactionId
                    ? counterpartyTransaction.correspondingTransactionId.toString()
                    : null;
                if (
                    counterpartyTransaction.parsed.block2.msgType === "566" ||
                    (transaction.error && transaction.error !== newError) ||
                    (counterpartyTransaction.error && counterpartyTransaction.error !== newError) ||
                    transaction.brokerTradeId !== counterpartyTransaction.externalId ||
                    correspondingTransactionIdString !== transaction._id
                ) {
                    if (matchingStatus === "Confirmed") {
                        updateFields.transactionId = transaction._id;
                        updateFields.swiftTransactionId = counterpartyTransaction._id;
                        updateFields.swiftExternalId = counterpartyTransaction.externalId;
                        updateFields.matchingStatus = MatchingCoacsStatus.Confirmed;
                        updateFieldsList.push(updateFields);

                        // To know which brokerTransactions are matched
                        counterpartyTransaction.correspondingTransactionId = transaction._id;
                    } else if (matchingStatus === "Preliminary") {
                        updateFields.transactionId = transaction._id;
                        updateFields.swiftTransactionId = counterpartyTransaction._id;
                        updateFields.swiftExternalId = counterpartyTransaction.externalId;
                        updateFields.matchingStatus = MatchingCoacsStatus.Preliminary;
                        updateFieldsList.push(updateFields);

                        // To know which brokerTransactions are matched
                        counterpartyTransaction.correspondingTransactionId = transaction._id;
                    } else {
                        updateFields.transactionError = newError;
                        updateFields.swiftTransactionError = newError;
                        updateFields.transactionId = transaction._id;
                        updateFields.swiftTransactionId = counterpartyTransaction._id;
                        updateFields.swiftExternalId = counterpartyTransaction.externalId;
                        updateFields.matchingStatus =
                            matchingStatus === "Mismatch" ? MatchingCoacsStatus.Mismatch : MatchingCoacsStatus.Matched;
                        updateFieldsList.push(updateFields);

                        // To know which brokerTransactions are matched/confirmed
                        counterpartyTransaction.correspondingTransactionId = transaction._id;
                    }
                }
            }
        }
        if (matchingStatus === "Mismatch" && transaction.error !== "No matching corporate action transaction found.") {
            // No matching broker transaction found
            const updateFields = {} as UpdateFields;
            updateFields.transactionError = "No matching corporate action transaction found.";
            updateFields.transactionId = transaction._id;
            updateFields.matchingStatus = MatchingCoacsStatus.Mismatch;
            updateFieldsList.push(updateFields);
        }
    }

    // Concat counterpartyCoacsUnknown with counterpartyCoacsToMatch to update mismatching swifts
    const allSwifts = counterpartyCoacsToMatch.concat(counterpartyCoacsUnknown);

    // Add error message to coacs transaction if no matching transaction
    for (let c = 0; c < allSwifts.length; c++) {
        const swift = allSwifts[c];
        if (!swift.correspondingTransactionId && swift.error !== "No matching transaction found.") {
            const updateFields = {} as UpdateFields;
            updateFields.swiftTransactionError = "No matching transaction found.";
            updateFields.swiftTransactionId = swift._id;
            updateFields.matchingStatus = MatchingCoacsStatus.Mismatch;
            updateFieldsList.push(updateFields);
        }
    }
    //Return update for transaction-swift
    return updateFieldsList;
};
