import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

import { Language } from "../Language";

interface IGreenShareProps {
    data: any;
    locale: Language;
    showHeader: boolean;
    showLegend?: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const GreenShare = ({ data, locale, showHeader, config, layout, showLegend, ...props }: IGreenShareProps): React.ReactElement => {
    let header = null;
    if (locale === "En") {
        header = "Sustainability";
    } else {
        if (locale === "Sv") {
            header = "Hållbarhet";
        }
    }
    const trace: Partial<Plotly.PlotData> = {
        labels: data.data.greenShares.sort().map((d) => d.sustainabilityClass),
        values: data.data.greenShares.map((d) => d.value),
        textinfo: "label+percent",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        //font: { size: 20 },
        type: "pie",
        hole: 0.4
        //sort: false
    };

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#1F5F32", "#2ca02c", "#C8E4C9", "#2ca02c", "#bdbdbd"],
        showlegend: showLegend,
        legend: { orientation: "v", traceorder: "normal", x: 0.8 }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader ? <h3>{header}</h3> : null}
            <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
