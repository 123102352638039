import React from "react";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { DateHelper, TimeSeries } from "../../../common/src";
import { PlotlyDefaults, Svgs } from "../../../components/src";
import { exportToXlsx } from "../common/exportToXlsx";

const Plot = createPlotlyComponent(Plotly);

type TimeSeriesChartInput = {
    timeseries: TimeSeries[];
    style?: React.CSSProperties | undefined;
    showXlsxButton?: boolean;
};

export const TimeSeriesChart = ({ timeseries, showXlsxButton = false, ...props }: TimeSeriesChartInput): React.ReactElement => {
    const colors = ["#66BC6B", "#BDBDBD", "#B3E5FC", "#1F5F32", "#757575", "#01579B", "#AB47BC", "#4A148C"];

    const traces = timeseries.map((ts, index) =>
        PlotlyDefaults.toTrace(ts.normalize(1).add(-1), ts.name, colors[index])
    ) as Partial<Plotly.PlotData>[];

    const maxArray = traces.map((trace) => (trace.y as any).reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const max = Math.max(maxArray.reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const override: PartialDeep<Plotly.Layout> = {
        autosize: true,
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        yaxis: { tickformat: max < 0.06 ? ".1%" : ".0%", automargin: true },
        xaxis: {
            title: "",
            hoverformat: "%Y-%m-%d",
            zeroline: false,
            automargin: true
        },
        width: null,
        height: null,
        margin: { l: 0, b: 0, t: 0, r: 0 },
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",
            x: 0.01,
            xanchor: "left",
            y: 0.99,
            yanchor: "top"
        }
    };
    const layout = PlotlyDefaults.mergeLayout(defaultLayout, override);

    const config = { displayModeBar: false };

    const thisConfig = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), config);

    return (
        <div>
            {showXlsxButton ? (
                <div
                    style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                    onClick={() => {
                        const data: Record<string, Record<string, string | number>> = {};
                        timeseries.forEach((timeSeries) => {
                            for (const index in timeSeries.__dates) {
                                const date = DateHelper.timestampToString(timeSeries.__dates[index]);
                                const value = timeSeries.__values[index];
                                if (data[date]) {
                                    data[date][timeSeries.name] = value;
                                } else {
                                    const timeSeriesItem: Record<string, string | number> = {};
                                    timeSeriesItem["Date"] = date;
                                    timeSeriesItem[timeSeries.name] = value;
                                    data[date] = timeSeriesItem;
                                }
                            }
                        });
                        exportToXlsx([Object.values(data)], "performance.xlsx", ["performance"]);
                    }}
                >
                    <Svgs.Excel />
                </div>
            ) : null}
            <div className="plotly-print">
                <Plot data={traces} config={thisConfig} layout={layout} {...props} />
            </div>
        </div>
    );
};
