import { Position } from "./Position";
import { SwedenCalendar, DateHelper } from "./calendar";
import { isNil } from "lodash";
import { stdev, corr, TimeSeries, AlignMethod } from "./timeseries";

const { floor, random, log, exp, sqrt, pow } = Math;
const { timestampToComparable, addDays, getYear, getMonth, getEndOfMonth } = DateHelper;

export class Portfolio {
    constructor() {
        this.name = null;
        this.id = null;
        this.value = null;
        this.date = null;
        this.client = null;
        this.benchmarkName = null;
        this.valueTimeSeries = null;
        this.returnTimeSeries = null;
        this.benchmarkTimeSeries = null;
        this.cashFlowTimeSeries = null;
        this.riskFreeTimeSeries = null;
        this.positions = null;
        Object.preventExtensions(this);
    }
    name: string;
    id: string;
    value: number;
    date: Date | string | number;
    client: { id: string };
    benchmarkName: string;
    valueTimeSeries: TimeSeries;
    returnTimeSeries: TimeSeries;
    benchmarkTimeSeries: TimeSeries;
    cashFlowTimeSeries: TimeSeries;
    riskFreeTimeSeries: TimeSeries;
    positions: Position[];

    static createRandomPortfolio(seed: string, startDate: Date, endDate: Date): Portfolio {
        const cal = new SwedenCalendar();
        const isBusinessDay = (d) => cal.isBusinessDay(d);
        const res = new Portfolio();
        res.name = "PORTFOLIO " + seed;
        const id0 = "000000000000000000000000";
        const s = seed;
        res.id = id0.substring(0, id0.length - s.length) + s;
        res.client = { id: id0 };
        res.date = endDate;
        res.benchmarkName = "BENCHMARK";
        res.valueTimeSeries = TimeSeries.generateRandomTimeSeries(startDate, endDate, isBusinessDay, 0.07, 0.12, 0.01, 1, seed + "1").mult(
            4000
        );
        res.returnTimeSeries = TimeSeries.generateRandomTimeSeries(startDate, endDate, isBusinessDay, 0.06, 0.13, 0, 1, seed + "2");
        res.benchmarkTimeSeries = TimeSeries.generateRandomTimeSeries(
            startDate,
            endDate,
            isBusinessDay,
            0.07,
            0.12,
            0.01,
            0.75,
            seed + "3"
        ).mult(3);
        res.riskFreeTimeSeries = TimeSeries.generateRandomTimeSeries(
            startDate,
            endDate,
            isBusinessDay,
            0.015,
            0.005,
            0,
            0,
            seed + "4"
        ).mult(0.02);
        res.positions = [...Array(12 + floor(20 * random()))].map(() => Position.createRandomPosition());
        res.value = res.valueTimeSeries.__values[res.valueTimeSeries.length - 1];
        return res;
    }

    static averageAnnualReturn(tsret) {
        if (tsret.count < 1) {
            return 0.0;
        }
        const tscum = tsret.add(1).cumProd();
        const p = tscum.periodicity();
        if (p === 0) {
            return 0.0;
        }
        const t = tscum.count / p;
        if (t < 1.0) {
            return tscum.endValue - 1;
        }
        return exp(log(tscum.endValue) / t) - 1;
    }

    static volatility(tslog: TimeSeries) {
        if (tslog.count < 2) {
            return 0.0;
        }
        // return d3.deviation(tslog.values) * sqrt(tslog.periodicity());
        // return jStat.stdev(tslog.values, true) * sqrt(tslog.periodicity());
        return stdev(tslog.__values) * sqrt(tslog.periodicity());
    }

    static add(list, label, value, format) {
        list.push({
            label: label,
            value: value,
            format: format
        });
    }

    /*
	Beräkning nyckeltal:
	Input till beräkningen av nyckeltal är tre tidsserier som är justerade till månatligt data. Varje månads slutvärde används.
	Tidsserierna är:
	1) Portföljens/fondens värdetidsserie, justerad för insättningar/uttag, inklusive direktavkastningar och avgifter.
	2) Benchmarktidsserie (jämförelseindex) också som totalavkstning
	3) Tidsserie för riskfri ränteplacering för portföljens valuta.
	Max 5 år, 60 månader används.

	Årsavkastning är total avkastning slutvärde genom startvärde uttryckt som årsavkastning. Ingen justering till årsavkastning om kortare
	löptid än ett år.
	Diff är skillnad i årsavkastning mellan portföljen och benchmark.
	Volatilitet beräknas på portföljen resp benchmarks månatliga avkastningar.
	Korrelation är korrelationen på månatlig avkastning mellan portföljen och benchmarks.
	Beta beräknas som Korrelation x volatilitet portfolio / volatilitet benchmark.
	Tracking error är volatilitet på månatlig överavkastning, alltså avkastning portföljen minus benchmark.
	Informationskvot är årsavkastning på överavkastningen (portfölj minus benchmark) genom tracking error.
	Sharpekvot är årsavkastning på överavkastningen (portfölj minus riskfri ränta) genom volatiliteten på överavkastning (portfölj minus
	riskfri ränta).
	Jensens alpha (visas som tillval) är årsavkastning på överavkastningen (portfölj minus riskfri)	minus årsavkastning på överavkastningen
	(benchmark minus riskfri) gånger beta.
	*/
    static calcPerformanceIndicators(mp) {
        const useLog = false;
        let value = null;
        if (typeof mp.valueTimeSeries !== "undefined") value = mp.valueTimeSeries.endValue;
        const pfReturn: TimeSeries = mp.returnTimeSeries.clone();
        const pfLog: TimeSeries = pfReturn.add(1).log();
        const volatilityPortfolio = Portfolio.volatility(useLog ? pfLog : pfReturn);
        const pfAnnualReturn = Portfolio.averageAnnualReturn(pfReturn);
        let bmAnnualReturn = null;
        let diff = null;
        let volatilityBenchmark = null;
        let correlation = null;
        let beta = null;
        let trackingError = null;
        let annualReturnVsBenchmark = null;
        let informationRatio = null;
        let excessReturnPortfolio = null;
        let excessReturnBenchmark = null;
        // eslint-disable-next-line
        let _jensensAlpha = null;
        let sharpeRatio = null;
        if (typeof mp.benchmarkTimeSeries !== "undefined") {
            const bmReturn = mp.benchmarkTimeSeries.clone();
            bmAnnualReturn = Portfolio.averageAnnualReturn(bmReturn);
            diff = pfAnnualReturn - bmAnnualReturn;
            const bmLog = bmReturn.add(1).log();
            volatilityBenchmark = Portfolio.volatility(useLog ? bmLog : bmReturn);
            // correlation = jStat.corrcoeff(useLog ? pfLog.values : pfReturn.values, useLog ? bmLog.values : bmReturn.values);
            correlation = corr(useLog ? pfLog.__values : pfReturn.__values, useLog ? bmLog.__values : bmReturn.__values);
            beta = (correlation * volatilityPortfolio) / volatilityBenchmark;
            // const tsVsBench = TimeSeries.weightedTimeSeries([1.0, -1.0], [pfReturn, bmReturn]);
            const tsVsBench = TimeSeries.weightedTimeSeries([1.0, -1.0], [pfLog, bmLog]).exp().add(-1);
            trackingError = Portfolio.volatility(useLog ? tsVsBench.add(1).log() : tsVsBench);
            annualReturnVsBenchmark = Portfolio.averageAnnualReturn(tsVsBench);
            informationRatio = annualReturnVsBenchmark / trackingError;
            if (typeof mp.riskFreeTimeSeries !== "undefined") {
                const rfReturn = mp.riskFreeTimeSeries.clone();
                const rfLog = rfReturn.add(1).log();
                // const tsPortfVsRiskF = TimeSeries.weightedTimeSeries([1.0, -1.0], [pfReturn, rfReturn]);
                // const tsBenchVsRiskF = TimeSeries.weightedTimeSeries([1.0, -1.0], [bmReturn, rfReturn]);
                const tsPortfVsRiskF = TimeSeries.weightedTimeSeries([1.0, -1.0], [pfLog, rfLog]).exp().add(-1);
                const tsBenchVsRiskF = TimeSeries.weightedTimeSeries([1.0, -1.0], [bmLog, rfLog]).exp().add(-1);
                excessReturnPortfolio = Portfolio.averageAnnualReturn(tsPortfVsRiskF);
                excessReturnBenchmark = Portfolio.averageAnnualReturn(tsBenchVsRiskF);
                _jensensAlpha = excessReturnPortfolio - beta * excessReturnBenchmark;
                const volatilityVsRiskFree = Portfolio.volatility(useLog ? tsPortfVsRiskF.add(1).log() : tsPortfVsRiskF);
                const annualReturnVsRiskFree = Portfolio.averageAnnualReturn(tsPortfVsRiskF);
                sharpeRatio = annualReturnVsRiskFree / volatilityVsRiskFree;
            }
            // debug
            // pfReturn.items.map((d,i)=>[d.timestamp, d.value,bmReturn.items[i].value,rfReturn.items[i].value]
            //   .map(g=>g.toString()).join('\t')).join('\n')
        }
        let npos = 0;
        let ntot = 0;
        pfReturn.__values.forEach((v) => {
            if (v === 0) return;
            ntot++;
            if (v > 0) npos++;
        });
        const positiveMonthsRatio = npos / ntot;
        const res = [];
        let perffmt = "0,0%";
        if (pfAnnualReturn !== null) {
            if (bmAnnualReturn !== null) {
                if (volatilityPortfolio < 0.1 && volatilityBenchmark < 0.1) perffmt = "0,00%";
            } else {
                if (volatilityPortfolio < 0.1) perffmt = "0,00%";
            }
        }
        const volfmt = "0,0%";
        const valfmt = "# ##0" + (value < 10000 ? ",00" : "");
        const decfmt = "0,00";
        const pctfmt = "0%";

        Portfolio.add(res, "Värde", value, valfmt);
        Portfolio.add(res, "Årsavkastning, portfölj", pfAnnualReturn, perffmt);
        Portfolio.add(res, "Årsavkastning, benchmark", bmAnnualReturn, perffmt);
        Portfolio.add(res, "Diff", diff, perffmt);
        Portfolio.add(res, "Volatilitet, portfölj", volatilityPortfolio, volfmt);
        Portfolio.add(res, "Volatilitet, benchmark", volatilityBenchmark, volfmt);
        Portfolio.add(res, "Beta", beta, decfmt);
        Portfolio.add(res, "Korrelation mot benchmark", correlation, decfmt);
        Portfolio.add(res, "Tracking error", trackingError, volfmt);
        Portfolio.add(res, "Informationskvot", informationRatio, decfmt);
        Portfolio.add(res, "Sharpekvot", sharpeRatio, decfmt);
        Portfolio.add(res, "Andel positiva månader", positiveMonthsRatio, pctfmt);
        return res;
    }

    static calcMonthlyTimeSeries(
        portf,
        startDate: any = null,
        endDate: any = null,
        laterStartDate: any = null,
        earlierEndDate: any = null,
        businessDayCheck: any = null
    ): Portfolio {
        let ts: TimeSeries = portf.returnTimeSeries;
        const monthNumber = (d) => 12 * getYear(d) + getMonth(d);
        const monthNumberToEndOfMonthDate = (d) => {
            const m = (d % 12) + 1;
            const y = (d - m + 1) / 12;
            return getEndOfMonth(y, m - 1);
        };
        if (!startDate) {
            startDate = ts.__dates[0];
        }
        let startMonth = monthNumber(ts.__dates[0]);
        if (ts.length >= 2) {
            const m = monthNumber(ts.__dates[1]) - 1;
            if (m < startMonth) {
                startMonth = m;
            }
        }
        // const startMonth = min(monthNumber(ts.items[0].timestamp), monthNumber(ts.items[1].timestamp) - 1);
        if ((typeof startDate !== "undefined" && startDate !== null && monthNumber(startDate) < startMonth) || laterStartDate) {
            startMonth = monthNumber(startDate);
        }
        let endMonth = monthNumber(ts.__dates[ts.length - 1]);
        if (typeof endDate !== "undefined" && endDate !== null) {
            const mn = monthNumber(endDate);
            if (mn > endMonth || earlierEndDate) {
                endMonth = mn;
            }
        }

        if (!portf.riskFreeTimeSeries || portf.riskFreeTimeSeries.length === 0) {
            const ts = new TimeSeries([monthNumberToEndOfMonthDate(startMonth)], [1]);
            portf.riskFreeTimeSeries = ts;
        }
        const ns = ["returnTimeSeries", "benchmarkTimeSeries", "riskFreeTimeSeries"];
        const res = new Portfolio();
        ns.forEach((d) => (res[d] = new TimeSeries([], [])));
        if (portf.valueTimeSeries) {
            res.valueTimeSeries = portf.valueTimeSeries.clone();
        }
        for (let i = startMonth; i <= endMonth; i++) {
            const syncTimestamp = monthNumberToEndOfMonthDate(i);
            let date = syncTimestamp;
            if (!isNil(businessDayCheck)) {
                while (!businessDayCheck(date)) {
                    date = addDays(date, -1);
                }
            }
            for (let j = 0; j < ns.length; j++) {
                const n = ns[j];
                ts = portf[n];
                if (!ts) continue;
                let v = ts.latestValue(syncTimestamp);
                if (!isFinite(v)) {
                    v = ts.length > 0 ? ts.startValue : 0;
                }
                // res[n].push(new TimeSeriesItem(TimeSeries.timestampClone(timestamp), v));
                const pts: TimeSeries = res[n];
                pts.__dates.push(date);
                pts.__values.push(v);
            }
        }
        ns.forEach((n) => {
            const pts = res[n];
            res[n] = new TimeSeries(pts.__dates, pts.__values).return();
        });
        res.name = portf.name;
        res.benchmarkName = portf.benchmarkName;
        return res;
    }

    static timeSeriesYears(ts) {
        let res = ts.__values.reduce((p, c, i) => {
            const d = ts.__dates[i];
            p[d.getFullYear()] = true;
            return p;
        }, {});
        res = Object.keys(res).map((d) => parseInt(d, 10));
        res.sort((d1, d2) => d2 - d1);
        return res;
    }

    static fromJson(json) {
        const res = { ...json };
        res.date = new Date(json.date);
        res.startDate = new Date(json.startDate);
        ["value", "return", "benchmark", "riskFree", "cashFlow"].forEach((d) => {
            const id = d + "TimeSeries";
            const ts = json[id];
            if (ts) {
                res[id] = TimeSeries.fromJson(ts);
            }
        });
        return res;
    }

    static fromFundInfo(data) {
        let portf: Portfolio;
        if (typeof data.classes === "undefined") {
            // Old fundinfo
            portf = Portfolio.fromJson(data);
        } else {
            // New fundinfo
            const fundClass = data.classes[0];
            const benchmark = data.benchmarks[0];
            portf = {
                name: data.name,
                returnTimeSeries: TimeSeries.fromJson(fundClass.returnTimeSeries),
                benchmarkName: benchmark.longName,
                benchmarkTimeSeries: TimeSeries.fromJson(benchmark.returnTimeSeries)
            } as Portfolio;

            if (benchmark.instrumentCurrency !== fundClass.navCurrency) {
                const fxName = benchmark.instrumentCurrency + fundClass.navCurrency;
                const fxRates = data.fxRates.find((d) => d.name === fxName);
                if (fxRates) {
                    const bmts = portf.benchmarkTimeSeries;
                    let fxts = TimeSeries.fromJson(fxRates);
                    if (timestampToComparable(bmts.start) < timestampToComparable(fxts.start)) {
                        (fxts.__dates as any[]).unshift(bmts.start);
                        fxts.__values.unshift(bmts.startValue);
                    }
                    fxts = TimeSeries.align(bmts, fxts, AlignMethod.Latest);
                    portf.benchmarkTimeSeries = bmts.mult(fxts);
                }
            }
        }
        return portf;
    }

    // Calculates a return in a range
    // Range is either a specific year or
    // a string like '1M', '3M', '6M', '1Y', '3Y', '5Y'
    // For longer ranges than a year the return are
    // converted to annual equivalent return
    static calcReturn(monthlyReturnsTimeSeries: TimeSeries, range) {
        if (!monthlyReturnsTimeSeries || !range) {
            return Number.NaN;
        }
        if (typeof range === "string" && range.toLowerCase() === "mtd") {
            range = "1M";
        }
        const y = Number(range);
        let res = null;
        if (!Number.isNaN(y)) {
            res = monthlyReturnsTimeSeries.__values.reduce(
                (p, c, i) => {
                    if (getYear(monthlyReturnsTimeSeries.__dates[i]) === y) {
                        p.count++;
                        p.prod *= 1.0 + c;
                    }
                    return p;
                },
                { count: 0, prod: 1.0 }
            );
            if (res.count === 0) {
                return Number.NaN;
            }
            return res.prod - 1.0;
        }
        const m = /([0-9]+)([MY])/.exec(range.toUpperCase());
        if (m) {
            let months = parseInt(m[1], 10);
            if (m[2] === "Y") months *= 12;
            const n = monthlyReturnsTimeSeries.length;
            res = monthlyReturnsTimeSeries.__values.reduce(
                (p, c, i) => {
                    if (i >= n - months) {
                        p.count++;
                        p.prod *= 1.0 + c;
                    }
                    return p;
                },
                { count: 0, prod: 1.0 }
            );
            if (res.count < months) {
                return Number.NaN;
            }
            if (res.count <= 12) {
                return res.prod - 1.0;
            }
            return pow(res.prod, 12 / res.count) - 1.0;
        }
        return Number.NaN;
    }
}
