import React, { useMemo, useContext, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Client, cacheExchange, fetchExchange, Provider as UrqlProvider } from "urql";
import { AuthUtilities, authExchange } from "@urql/exchange-auth";

import { loginContext, getDefaultLoginValue } from "./containers/Login";
import { useInterval } from "./common/Utils";
import { App } from "./App";

import { ContextProvider } from "./contexts/ContextProvider";
import { TermsOfServicePage } from "./containers/TermsOfServicePage";
import { REACT_APP_API_URI } from "./env";

export const GqlClient = (): React.ReactElement => {
    const [pollInterval, setPollInterval] = useState(0);
    const { login, setLogin } = useContext(loginContext);

    const urqlClient = useMemo(() => {
        const urqlClient = new Client({
            url: REACT_APP_API_URI + "/graphql",
            exchanges: [
                cacheExchange,
                authExchange(async (utils: AuthUtilities) => {
                    return {
                        addAuthToOperation(operation) {
                            if (!login.token) return operation;
                            return utils.appendHeaders(operation, {
                                Authorization: `Bearer ${login.token}`
                            });
                        },
                        willAuthError() {
                            if (!login.loggedIn) {
                                return true;
                            }
                            const futureDate = new Date();
                            futureDate.setMinutes(futureDate.getMinutes() + 30);
                            const future = futureDate.toISOString();
                            if (future > login.expires) {
                                return true;
                            }
                            return false;
                        },
                        didAuthError(error, _operation) {
                            const result =
                                (error.response && error.response.status === 401) ||
                                error.graphQLErrors.some((e) => e.extensions?.code === "FST_JWT_NO_AUTHORIZATION_IN_HEADER");

                            if (!result) {
                                console.error(error);
                            }
                            return result;
                        },
                        async refreshAuth() {
                            setLogin(getDefaultLoginValue());
                        }
                    };
                }),
                fetchExchange
            ]
            // fetchOptions: () => {
            //     return {
            //         headers: { authorization: login.token ? `Bearer ${login.token}` : "" }
            //     };
            // }
        });
        return urqlClient;
    }, [login.token, login.loggedIn, login.expires, setLogin]);

    useInterval(
        () => {
            if (pollInterval === 0) {
                setPollInterval(30000);
            }
            //console.log("token expires at: ", login.expires);
            if (login.loggedIn) {
                const futureDate = new Date();
                futureDate.setMinutes(futureDate.getMinutes() + 30);
                const future = futureDate.toISOString();
                if (future > login.expires) {
                    console.log("future: ", future);
                    console.log("token expires at: ", login.expires);
                    console.log("logout");
                    Bugsnag.leaveBreadcrumb("useInterval token expired");
                    setLogin(getDefaultLoginValue());
                }
            }
        },
        // Delay in milliseconds or null to stop it
        login.loggedIn ? pollInterval : null
    );

    return (
        <UrqlProvider value={urqlClient}>
            <loginContext.Provider value={{ login, setLogin }}>
                {login.acceptedTerms ? (
                    <ContextProvider>
                        <App />
                    </ContextProvider>
                ) : (
                    <TermsOfServicePage />
                )}
            </loginContext.Provider>
        </UrqlProvider>
    );
};
