import { DateHelper } from ".";
/**
 * This calendar business days Monday to Friday.
 */
export class MondayToFridayCalendar {
    constructor() {
        this.name = "Monday to Friday";
    }
    name: string;
    isBusinessDay(date) {
        const w = DateHelper.getDayOfWeek(date);
        return w >= 1 && w <= 5;
    }
}
