import React, { Fragment, useEffect, useMemo, useState } from "react";
import { gql } from "urql";
import { Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";
import { Alert, Button, Dropdown } from "react-bootstrap";

import { TicketTypeEnum, TicketStatusEnum, TicketFilterInput, TicketInput } from "../types.generated";
import { removeNull, usePrevious, useQueryArgs } from "../common/Utils";
import { Form, Formik } from "formik";
import { SearchListField, SubmitButton } from "../components/form";
import { cloneDeep, sortBy } from "lodash";
import { useGetTicketsQuery, useGetClientsUsersQuery } from "./TicketsPage.generated";
import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter, SelectColumnFilterArrayOfIds, SelectColumnFilterType } from "../components/react-table/ReactTableFilters";
import { YesNoModal } from "../components/YesNoModal";
import { useUpsertTicketsMutation } from "./TicketPage.generated";

export const getClientsUsers = gql`
    query getClientsUsers {
        me {
            _id
        }
        ticketUsers {
            _id
            name
            clients {
                _id
                name
            }
        }
    }
`;

export const getTickets = gql`
    query getTickets($filter: TicketFilterInput) {
        tickets(filter: $filter) {
            _id
            title
            clientId
            client {
                _id
                name
            }
            type
            creatorId
            creator {
                name
            }
            createTimestamp
            updateTimestamp
            state {
                status
                assigneeIds
                assignees {
                    _id
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
            previousStates {
                status
                assigneeIds
                assignees {
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
        }
    }
`;

interface FilterType {
    clientId: string;
    status: string;
    assigneeId: string;
    creatorId: string;
}

export const TicketsPage = (): React.ReactElement => {
    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    const [showFilter, setShowFilter] = useState(false);

    const [filter, setFilter] = useState<FilterType>({
        clientId: null,
        status: null,
        assigneeId: null,
        creatorId: null
    });

    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [{ fetching: loadingClientsUsers, error: errorClientsUsers, data }] = useGetClientsUsersQuery();

    const [{ fetching, error, data: dataTickets }] = useGetTicketsQuery({
        variables: {
            filter: {
                assigneeIdIn: filter.assigneeId ? [filter.assigneeId] : null,
                clientIds: filter.clientId ? [filter.clientId] : null,
                creatorIdIn: filter.creatorId ? [filter.creatorId] : null,
                statusIn: filter.status ? [filter.status as TicketStatusEnum] : null
            }
        }
    });

    const [_, upsertTickets] = useUpsertTicketsMutation();

    useEffect(() => {
        const assigneeId = data && data.me && data.me._id ? data.me._id : null;
        const ticketFilter: Partial<TicketFilterInput> = { statusIn: [TicketStatusEnum.Open] };
        if (assigneeId) ticketFilter.assigneeIdIn = [assigneeId];

        if (previousQueryArgs && !Object.keys(previousQueryArgs).length && !filter.status && assigneeId) {
            setFilter({ clientId: null, status: TicketStatusEnum.Open, assigneeId: assigneeId, creatorId: null });
            pushQueryArgs({ status: TicketStatusEnum.Open, assigneeId: assigneeId });
            setShowFilter(true);
        }
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            const newFilter: FilterType = { clientId: null, status: null, assigneeId: null, creatorId: null };
            const newTicketFilter: Partial<TicketFilterInput> = {};

            if ("clientId" in queryArgs && queryArgs["clientId"]) {
                newFilter["clientId"] = queryArgs["clientId"] as any;
                newTicketFilter.clientIds = [queryArgs["clientId"].toString()];
            }

            if ("assigneeId" in queryArgs && queryArgs["assigneeId"]) {
                newFilter["assigneeId"] = queryArgs["assigneeId"] as any;
                newTicketFilter.assigneeIdIn = [queryArgs["assigneeId"].toString()];
            }

            if ("status" in queryArgs && queryArgs["status"]) {
                newFilter["status"] = queryArgs["status"] as any;
                newTicketFilter.statusIn = [queryArgs["status"] as TicketStatusEnum];
            }

            if ("creatorId" in queryArgs && queryArgs["creatorId"]) {
                newFilter["creatorId"] = queryArgs["creatorId"] as any;
                newTicketFilter.creatorIdIn = [queryArgs["creatorId"].toString()];
            }

            if (stableStringify(filter) !== stableStringify(newFilter)) {
                setFilter(newFilter);
                setShowFilter(true);
            }
        }
    }, [data, filter, previousQueryArgs, pushQueryArgs, queryArgs]);

    const columns = useMemo(() => {
        const assigneesById: Record<string, { _id: string; name: string }> = {};

        if (dataTickets && dataTickets.tickets) {
            dataTickets.tickets.forEach((ticket) => {
                if (ticket.state && ticket.state.assigneeIds) {
                    ticket.state.assignees.forEach((assignee) => {
                        if (!(assignee._id in assigneesById)) {
                            assigneesById[assignee._id] = assignee;
                        }
                    });
                }
            });
        }

        return [
            {
                header: "_id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/tickets/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 200
            },
            {
                header: "Client(Owner)",
                id: "client.name",
                accessorKey: "client.name",
                size: 200,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter
            },
            {
                header: "Creator",
                id: "creator.name",
                accessorKey: "creator.name",
                size: 200,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.creator) {
                        return <Link to={`/admin/users/${row.original.creatorId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter
            },
            {
                header: "Assignees",
                id: "state.assigneeIds",
                accessorKey: "state.assigneeIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    return (
                                        <Link className="row" key={id} to={`/admin/users/${id}`}>
                                            {assigneesById[id].name}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(assigneesById)),
                size: 200
            },
            {
                header: "Types",
                accessorKey: "type",
                filter: SelectColumnFilterType(TicketTypeEnum)
            },
            {
                header: "Title",
                accessorKey: "title",
                size: 200
            },
            {
                header: "Status",
                id: "state.status",
                accessorKey: "state.status",
                filter: SelectColumnFilterType(TicketStatusEnum),
                size: 75,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const value = cellProps.getValue();
                    if (value !== "Open") {
                        return <div>{value}</div>;
                    }
                    return (
                        <Dropdown>
                            <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                {value}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    key={row.original._id}
                                    onClick={async () => {
                                        const payload: TicketInput = {
                                            _id: row.original._id,
                                            title: row.original.title,
                                            clientId: row.original.clientId,
                                            state: {
                                                status: TicketStatusEnum.Closed,
                                                assigneeIds: row.original.state.assigneeIds,
                                                comment: ""
                                            },
                                            type: row.original.type
                                        };
                                        setModal({ showModal: true, payload });
                                    }}
                                >
                                    Close ticket
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
            {
                header: "Created",
                accessorKey: "createTimestamp",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return value.substring(0, 10);
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 90
            }
        ];
    }, [dataTickets]);

    if (fetching || loadingClientsUsers) return <div>Loading...</div>;
    if (error)
        return (
            <div>
                <p>error tickets:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    if (errorClientsUsers)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(errorClientsUsers, null, 2)}</pre>
            </div>
        );

    const users: { _id: string; name: string }[] = [];
    const statuses: { _id: string; name: string }[] = [];
    const clientsById: Record<string, { _id: string; name: string }> = {};

    data.ticketUsers.forEach((user) => {
        users.push({ _id: user._id, name: user.name });
        user.clients.forEach((client) => {
            if (!clientsById[client._id]) {
                clientsById[client._id] = { _id: client._id, name: client.name };
            }
        });
    });
    Object.values(TicketStatusEnum).forEach((status) => {
        statuses.push({ _id: status, name: status });
    });

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to close the ticket with id " + modal.payload._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    yesText="Close ticket"
                    onYes={async () => {
                        const input: TicketInput = modal.payload;
                        await upsertTickets({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}

            <div className="row">
                <div className="col-sm-12">
                    {alert.visible ? (
                        <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                            {alert.message}
                        </Alert>
                    ) : null}
                </div>
            </div>

            <div className="row">
                <div className="col">Number of tickets: {dataTickets.tickets.length}</div>
                <div className="col text-end">
                    <Button
                        type="button"
                        className="btn btn-sm"
                        onClick={() => {
                            const win = window.open("/tickets/new", "_self");
                            win.focus();
                        }}
                    >
                        New ticket
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col ps-0">
                    {showFilter ? (
                        <Button variant="link" onClick={toggleFilter}>
                            Hide filter
                        </Button>
                    ) : (
                        <Button variant="link" onClick={toggleFilter}>
                            Show filter
                        </Button>
                    )}
                </div>
            </div>
            {showFilter ? (
                <div className="row">
                    <div className="col">
                        <Formik
                            enableReinitialize={true}
                            initialValues={filter}
                            validate={() => {
                                const errors: any = {};
                                return Object.keys(errors).length > 0 ? errors : {};
                            }}
                            onSubmit={async (submitValues) => {
                                const newValues: Partial<TicketFilterInput> = {};

                                if (submitValues.clientId) {
                                    newValues.clientIds = [cloneDeep(submitValues.clientId)];
                                }
                                if (submitValues.assigneeId) {
                                    newValues.assigneeIdIn = [cloneDeep(submitValues.assigneeId)];
                                }
                                if (submitValues.creatorId) {
                                    newValues.creatorIdIn = [cloneDeep(submitValues.creatorId)];
                                }
                                if (submitValues.status) {
                                    newValues.statusIn = [cloneDeep(submitValues.status) as TicketStatusEnum];
                                }

                                pushQueryArgs(removeNull(submitValues));
                            }}
                        >
                            {({ isSubmitting }) => {
                                return (
                                    <Form autoComplete="off">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="clientId"
                                                    label="Client"
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(Object.values(clientsById), "name")}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="assigneeId"
                                                    label={"Assignee"}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(users, "name")}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="creatorId"
                                                    label={"Creator"}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(users, "name")}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="status"
                                                    label={"Status"}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(statuses, "name")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-sm-4">
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <SubmitButton disabled={fetching} label={"Apply"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            ) : null}

            <div className="row">
                <Fragment>
                    <div className="col">
                        <ReactTable
                            columns={columns}
                            data={
                                dataTickets.tickets
                                    ? [...dataTickets.tickets].sort(function (x, y) {
                                          return new Date(y.createTimestamp).getTime() - new Date(x.createTimestamp).getTime();
                                      })
                                    : []
                            }
                            defaultHiddenColumns={[]}
                        />
                    </div>
                </Fragment>
            </div>
        </div>
    );
};
