import React from "react";

interface PagePropTypes {
    header?: string;
    className?: string;
    children?: any;
}

export class Page extends React.Component<PagePropTypes> {
    render(): React.ReactNode {
        const { className, header, children } = this.props;
        return (
            <div className={className}>
                <h2>{header}</h2>
                {children}
            </div>
        );
    }
}
