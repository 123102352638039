import React, { ReactElement } from "react";
import { gql, useQuery } from "urql";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { Excel } from "../../../components/src/Svgs";
import { TimeSeries, DateHelper } from "../../../common/src";
import { PlotlyDefaults } from "../../../components/src";

import { useQueryState } from "../common/use-query-state";
import { exportToXlsx, sheetType } from "../common/exportToXlsx";

const Plot = createPlotlyComponent(Plotly);

const getAum = gql`
    query getAum($endDate: GraphQLDateString!) {
        iris: performance(clientId: "58e64b9523d2772e1859b705", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }

        nordic: performance(clientId: "594a204da0a4f328dce4c1a8", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }

        global: performance(clientId: "594a2cf723d2773014ec27b7", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }

        dahlia: performance(clientId: "5b0d638cafcedd32f03a8ac7", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
        aster: performance(clientId: "605b2e5cc34cf5001154c90d", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
        aster_short: performance(clientId: "62690a20071ef07765246144", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
        aster_high_yield: performance(clientId: "62690582071ef0776524606c", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
        global_fixed_income: performance(clientId: "649d36255de897ec4079bbae", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
        perenne: performance(clientId: "649d4a855de897ec4079d879", filter: { endDate: $endDate }) {
            dates
            values
            cashFlows
        }
    }
`;

export const AumPage = (): ReactElement => {
    const todaysDate = DateHelper.dateToString(DateHelper.today()).substring(0, 10);
    const yesterday = DateHelper.dateToString(DateHelper.dateAddDays(new Date(todaysDate), -1));

    const [endDate] = useQueryState("endDate", yesterday);

    const [{ data, fetching, error }] = useQuery({ query: getAum, variables: { endDate: endDate } });

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const tss: TimeSeries[] = [];
    let ts: TimeSeries;

    let firstDateString = "2900-12-30";
    const dataByKey: Record<string, { firstDate: string; lastDate: string; index: number }> = {};
    const totalAumArray: number[] = [];
    const totalCashFlowArray: number[] = [];

    if (data) {
        for (const key of Object.keys(data)) {
            const value: { dates: string[]; values: number[]; cashFlows: number[] } = data[key];
            ts = new TimeSeries(
                value.dates,
                value.values.map((value) => {
                    return value / 1000000.0;
                }),
                key
            );

            if (value.dates[0] < firstDateString) firstDateString = value.dates[0];

            dataByKey[key] = { firstDate: value.dates[0], lastDate: value.dates[value.dates.length - 1], index: -1 };
            tss.push(ts);
        }

        // Date vector of unique dates
        const dateDict: Record<string, number> = {};
        // Loop to end
        let date = new Date(firstDateString);
        const lastDate = new Date(endDate);
        const addDays = (date: Date, days: number) => {
            const result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        };

        lastDate.setDate(lastDate.getDate() + 1);
        while (date.getTime() < lastDate.getTime()) {
            dateDict[date.toISOString().substring(0, 10)] = 1;
            date = addDays(date, 1);
        }
        const allDates: string[] = Object.keys(dateDict);

        for (let i = 0; i < allDates.length; i++) {
            const date: string = allDates[i];
            let dateAum = 0;
            let dateCashFlow = 0;
            for (const key of Object.keys(data)) {
                const positionData = dataByKey[key];
                const value: { dates: string[]; values: number[]; cashFlows: number[] } = data[key];
                if (date < positionData.firstDate || date > positionData.lastDate) {
                    continue;
                } else {
                    // Increase index as we start at -1 by purpose
                    positionData.index += 1;
                    dateAum += value.values[positionData.index] / 1000000.0;
                    dateCashFlow +=
                        Math.abs(value.cashFlows[positionData.index]) > 1e-4 ? value.cashFlows[positionData.index] / 1000000.0 : 0;
                }
            }
            totalAumArray.push(dateAum);
            totalCashFlowArray.push(dateCashFlow);
        }
        const totalCashFlowTimeSeries = new TimeSeries(allDates, totalCashFlowArray, "Flows");
        const cumCashFlowTimeSeries = totalCashFlowTimeSeries.cumSum();

        const totalAumTimeSeries = new TimeSeries(allDates, totalAumArray, "Total AUM");
        tss.push(totalAumTimeSeries);
        tss.push(cumCashFlowTimeSeries);
    }

    const plotData: Plotly.Data[] = tss.map((ts) => {
        const data = PlotlyDefaults.toTrace(ts, ts.name);
        if ("hovertemplate" in data) {
            data["hovertemplate"] = `<extra></extra><b>${ts.name}</b><br><br>Date %{x| %Y-%m-%d}<br>Value %{y}`;
        }
        return data;
    });

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();

    const override: PartialDeep<Plotly.Layout> = {
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",
            x: 0.01,
            xanchor: "left",
            y: 0.99,
            yanchor: "top"
        }
    };

    const layout = PlotlyDefaults.mergeLayout(defaultLayout, override);

    return (
        <div className="full-height">
            <div className="row">
                <div className="col">
                    <button
                        id="export-excel"
                        className="export-link"
                        type="button"
                        onClick={() => {
                            if (data) {
                                const table: sheetType[] = [];
                                const sheetNames = Object.keys(data);
                                sheetNames.forEach((sheetName) => {
                                    const fund = data[sheetName];
                                    const listOfDicts = [];
                                    fund["dates"].forEach((_, index) => {
                                        listOfDicts.push({
                                            dates: fund["dates"][index],
                                            values: fund["values"][index],
                                            cashFlows: fund["cashFlows"][index]
                                        });
                                    });
                                    table.push(listOfDicts);
                                });
                                exportToXlsx(table, "aum.xlsx", sheetNames);
                            }
                        }}
                    >
                        <Excel />
                    </button>
                </div>
            </div>

            <Plot
                data={plotData}
                layout={layout}
                config={{ ...PlotlyDefaults.getDefaultConfig(), scrollZoom: true, modeBarButtonsToRemove: [] }}
                useResizeHandler={true}
                style={{ width: "100%", height: "80vh" }}
            />
        </div>
    );
};
