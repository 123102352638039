import React, { useState } from "react";
import { ICommandBarItemProps, Spinner, SpinnerSize } from "@fluentui/react";
import { TransactionsGrid } from "./TransactionsGrid";
import { YesNoModal } from "./YesNoModal";
import { BankAccountTransactionInput, TransactionStatus, UpdateTransactionInput } from "../types.generated";

interface IUpdateTransactionsStatusGrid {
    items: any;
    visibleColumns?: string[];
    onUpdateStatus(input: UpdateTransactionInput[] | BankAccountTransactionInput[]): any;
}

export const UpdateTransactionsStatusGrid = ({
    items,
    visibleColumns,
    onUpdateStatus
}: IUpdateTransactionsStatusGrid): React.ReactElement => {
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: { input: [] } });
    const [spinner, setSpinner] = useState(false);
    const hasSelected = selected && selected.length > 0;

    const selectedChange = (e: any[]) => {
        setSelected(e);
    };

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Pending };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to Preliminary",
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Preliminary };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Confirmed };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "instructed",
                        text: "Change to Instructed",
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Instructed };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Settled };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "internal",
                        text: "Change to Internal",
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Internal };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "history",
                        text: "Change to History",
                        onClick: () => {
                            setSpinner(true);
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.History };
                            });
                            onUpdateStatus(input);
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            const input = selected.map((t: any) => {
                                return { _id: t._id, status: TransactionStatus.Deleted };
                            });
                            setModal({
                                showModal: true,
                                payload: { input: input }
                            });
                        }
                    }
                ]
            }
        }
    ];

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete selected transactions?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: { input: modal.payload.input }
                    }}
                    setModal={setModal}
                    onYes={() => {
                        setSpinner(true);
                        onUpdateStatus(modal.payload.input as unknown as UpdateTransactionInput[]);
                    }}
                    onNo={() => {
                        setSpinner(false);
                    }}
                />
            ) : null}
            {spinner ? <Spinner label="Updating status..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.medium} /> : null}
            <TransactionsGrid
                items={items}
                onSelectedChange={selectedChange}
                canSelect={true}
                commands={hasSelected ? commands : []}
                visibleColumns={visibleColumns}
            />
        </div>
    );
};
