import React from "react";

export const PrintButton = (): React.ReactElement => {
    return (
        <button
            onClick={() => window.print()}
            title="Print"
            className="print-none"
            style={{
                background: "inherit"
            }}
        >
            <i className="glyphicon glyphicon-print" />
        </button>
    );
};
