import React from "react";
import ReactMarkdown from "react-markdown";

export function ReactMarkdownCustom(props): React.ReactElement {
    const handleClick = (event) => {
        event.preventDefault();
        // a little bit heuristic approach, since markdown does not implement 'target' attribute
        const href = event.target.attributes["href"];
        if (href) {
            window.open(href.value, "_blank");
        }
    };

    return (
        <div onClick={handleClick}>
            <ReactMarkdown>{props.children}</ReactMarkdown>
        </div>
    );
}
