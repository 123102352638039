import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Formik, Form, FieldArray } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { startCase, sortBy, keyBy, cloneDeep } from "lodash";
import { useNavigate, useLocation, Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";
import { Alert } from "react-bootstrap";

import { emptyObjectId, recursivelyRemoveKey } from "../../../../common/src";

import { SubmitButton, TextField, SelectField, NumberField, SearchListField } from "../../components/form";
import { CollectionNameEnum, CurrencyEnum, InstrumentProductTypeEnum } from "../../types.generated";
import { useAlertTimeOut, usePrevious } from "../../common/Utils";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { aliasLabel, defaultAlias } from "../../components/alias";

export const instrumentTypes = [InstrumentProductTypeEnum.CashAccount, InstrumentProductTypeEnum.BalanceAccount];

const GET_CASH_OR_BALANCE_INSTRUMENT = gql`
    query getPartyInstrument($id: GraphQLObjectId) {
        partyInstrument: instrument(_id: $id) {
            _id
            clientIds
            name
            externalAccountId
            accountId
            quantityDecimals
            productType
            currency
            isin
            longName
            bic
            iban
            aliases {
                collection
                documentId
                key
                value
                comment
            }
        }
    }
`;

const GET_DATA = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            name
            _id
            instruments {
                _id
                clientIds
                name
                isin
                longName
                externalAccountId
                accountId
                currency
                productType
                quantityDecimals
                bic
                iban
                aliases {
                    collection
                    documentId
                    key
                    value
                    comment
                }
            }
            accounts {
                _id
                name
            }

            externalAccounts {
                _id
                name
                type
            }
        }
    }
`;

const CREATE_UPDATE_CASH_OR_BALANCE_INSTRUMENT = gql`
    mutation CreateUpdatePartyCashOrBalanceInstrument($input: CreateUpdatePartyCashOrBalanceInstrumentInput!) {
        createUpdatePartyCashOrBalanceInstrument(input: $input) {
            _id
            name
        }
    }
`;

function resetValuesFunc(productType, clientIds) {
    return {
        _id: null,
        clientIds,
        currency: null,
        externalAccountId: null,
        accountId: emptyObjectId,
        isin: null,
        longName: null,
        name: null,
        quantityDecimals: 2,
        productType,
        bic: "",
        iban: "",
        aliases: []
    };
}

export interface Props {
    id?: string;
    cid?: string;
    parties: {
        _id: string;
        name: string;
    }[];
    onCreate?: (partyId: string) => void;
    onUpdate?: (partyId: string) => void;
    productType?: string;
}

export function CashOrBalanceInstrumentForm(props: Props): React.ReactElement {
    const previousProps = usePrevious(props);

    const id = props.id ? props.id : emptyObjectId;
    const cid = props.cid ? props.cid : emptyObjectId;

    const navigate = useNavigate();
    const location = useLocation();
    const [{ fetching: getCashOrBalanceInstrumentLoading, error: getCashOrBalanceInstrumentError, data: getCashOrBalanceInstrumentData }] =
        useQuery({
            query: GET_CASH_OR_BALANCE_INSTRUMENT,
            variables: { id: id },
            requestPolicy: "network-only"
        });

    const [{ fetching: loading, error: getDataError, data }] = useQuery({ query: GET_DATA });

    const [values, setValues] = useState(null);

    const [_, createUpdatePartyCashOrBalanceInstrument] = useMutation(CREATE_UPDATE_CASH_OR_BALANCE_INSTRUMENT);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const aliasOptions: { _id: string; name: string }[] = useMemo(() => {
        let options = [{ _id: "000000000000000000000000", name: "None" }];
        if (props.parties) {
            options = [{ _id: "000000000000000000000000", name: CollectionNameEnum.None }, ...props.parties];
        }
        return options;
    }, [props.parties]);

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (stableStringify(props) !== stableStringify(previousProps)) {
            setValues(null);
        } else if (!loading && !getCashOrBalanceInstrumentLoading && !getDataError && !getCashOrBalanceInstrumentError) {
            if (values === null) {
                if (props.id) {
                    setValues(getCashOrBalanceInstrumentData.partyInstrument);
                } else {
                    setValues(resetValuesFunc(props.productType, props.cid ? props.cid : emptyObjectId));
                }
            }
        }
    }, [
        previousProps,
        props,
        values,
        getCashOrBalanceInstrumentData,
        getDataError,
        getCashOrBalanceInstrumentError,
        getCashOrBalanceInstrumentLoading,
        loading
    ]);
    if (loading || getCashOrBalanceInstrumentLoading) return <div>Loading</div>;

    if (getCashOrBalanceInstrumentError) return <div>{"Error: " + getCashOrBalanceInstrumentError.message}</div>;

    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (values === null || !instrumentTypes.includes(values.productType)) return <div></div>;

    let { clients } = cloneDeep(data);

    const clientsById = keyBy(clients, "_id");
    clients = sortBy(clients, "name");

    const createUpdateInput = [
        "_id",
        "currency",
        "externalAccountId",
        "accountId",
        "bic",
        "iban",
        "name",
        "create",
        "quantityDecimals",
        "clientIds",
        "productType",
        "aliases"
    ];

    const currencies = Object.keys(CurrencyEnum).sort();

    currencies.sort((d1, d2) => (d1 < d2 ? -1 : 1));

    const extendedValues = cloneDeep(values);

    extendedValues.clientIds = [props.cid];
    const client = clientsById[extendedValues.clientIds[0]];

    if (!extendedValues.externalAccountId && client.externalAccounts && client.externalAccounts.length) {
        extendedValues.externalAccountId = client.externalAccounts[0]._id;
    }

    const accounts =
        client.accounts && client.accounts.length
            ? [...[{ key: emptyObjectId, value: "None" }], ...client.accounts.map((d) => ({ key: d._id, value: d.name }))]
            : [{ key: emptyObjectId, value: "None" }];

    if (!extendedValues.currency) {
        extendedValues.currency = currencies[0];
    }

    return (
        <div id="cashorbalanceinstrumentform" className="cashorbalanceinstrumentform form">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={extendedValues}
                validate={(validateValues) => {
                    const errors: any = {};

                    const requiredFields = ["externalAccountId", "currency"];

                    if (validateValues.bic !== "") {
                        if (!/^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/i.test(validateValues.bic)) {
                            errors.bic = "Invalid BIC";
                        }
                    }

                    if (validateValues.iban !== "") {
                        if (!/^[A-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}$/i.test(validateValues.iban)) {
                            errors.iban = "Invalid IBAN";
                        }
                    }

                    requiredFields.forEach((d) => {
                        if (!validateValues[d]) {
                            errors[d] = "Required";
                        }
                    });
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    //console.log("submitValues: ", submitValues);
                    const input: any = recursivelyRemoveKey(submitValues, "__typename");
                    const partyId = input.clientIds;
                    //Remove fields from values that are not relevant
                    for (let f = 0; f < Object.keys(input).length; f++) {
                        const field = Object.keys(input)[f];
                        if (!createUpdateInput.includes(field)) {
                            delete input[field];
                        }
                    }
                    if (input._id !== null) {
                        input.create = false;
                        await createUpdatePartyCashOrBalanceInstrument({ input: input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({ color: "success", visible: true, message: `The instrument has been updated successfully!` });
                                    props.onUpdate(partyId);
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(result.data.createUpdatePartyCashOrBalanceInstrument._id);
                                    navigate(path.join("/"), { replace: true });
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        input.create = true;
                        await createUpdatePartyCashOrBalanceInstrument({ input: input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({ color: "success", visible: true, message: `The instrument has been created successfully!` });
                                    props.onCreate(partyId);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                    setSubmitting(false);
                }}
            >
                {({ isSubmitting, values, errors }) => {
                    const { productType } = values;
                    if (Object.keys(errors).length > 0) {
                        console.log(errors);
                    }
                    return (
                        <Fragment>
                            <div className="" style={{ float: "right" }}>
                                <div className="row mt-5">
                                    <div>
                                        <header>
                                            <h4>{startCase(productType)}</h4>
                                        </header>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <Form autoComplete="off">
                                            <div className="form-row">
                                                <SelectField
                                                    name="externalAccountId"
                                                    label="External account*"
                                                    options={client.externalAccounts.map((d) => ({ key: d._id, value: d.name }))}
                                                    className="col-6"
                                                    disabled={false}
                                                />
                                                <TextField name="name" label="Name" type="text" className="col-6" disabled={false} />
                                            </div>

                                            {productType === InstrumentProductTypeEnum.CashAccount ? (
                                                <div>
                                                    <div className="form-row">
                                                        <SelectField
                                                            name="accountId"
                                                            label="Account"
                                                            options={accounts}
                                                            className="col-4"
                                                            disabled={false}
                                                        />
                                                        <SelectField
                                                            name="currency"
                                                            label="Currency*"
                                                            options={currencies}
                                                            className="col-4"
                                                            disabled={false}
                                                        />
                                                        <TextField name="bic" label="BIC" type="text" className="col-4" disabled={false} />
                                                    </div>
                                                    <div className="form-row">
                                                        <TextField
                                                            name="iban"
                                                            label="IBAN"
                                                            type="text"
                                                            className="col-8"
                                                            disabled={false}
                                                        />
                                                        <NumberField
                                                            name="quantityDecimals"
                                                            label="Quantity decimals"
                                                            className="col-4"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="form-row">
                                                    <SelectField
                                                        name="accountId"
                                                        label="Account"
                                                        options={accounts}
                                                        className="col-4"
                                                        disabled={false}
                                                    />
                                                    <SelectField
                                                        name="currency"
                                                        label="Currency*"
                                                        options={currencies}
                                                        className="col-4"
                                                        disabled={false}
                                                    />
                                                    <NumberField
                                                        name="quantityDecimals"
                                                        label="Quantity decimals"
                                                        className="col-4"
                                                        disabled={false}
                                                    />
                                                </div>
                                            )}

                                            <div className="form-row">
                                                {values._id ? (
                                                    <TextField name="_id" label="ID*" type="text" className="col" disabled={true} />
                                                ) : null}
                                            </div>

                                            <div className="row">
                                                <div className="col-4">
                                                    <h5>Aliases</h5>
                                                    <FieldArray
                                                        name="aliases"
                                                        render={(arrayHelpers) => (
                                                            <Fragment>
                                                                {values.aliases && values.aliases.length > 0 ? (
                                                                    values.aliases.map((alias, index) => (
                                                                        <div key={index} className="form-group form-row">
                                                                            <div>
                                                                                <fieldset>
                                                                                    <legend>{index + 1}</legend>
                                                                                    <SelectField
                                                                                        name={`aliases[${index}].collection`}
                                                                                        label="Collection"
                                                                                        className="text-success"
                                                                                        options={["None", "Party"]}
                                                                                        disabled={isSubmitting}
                                                                                        size={5}
                                                                                    />
                                                                                    <SearchListField
                                                                                        className=""
                                                                                        name={`aliases[${index}].documentId`}
                                                                                        label={aliasLabel(
                                                                                            alias.collection,
                                                                                            alias.documentId
                                                                                        )}
                                                                                        options={aliasOptions}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].key`}
                                                                                        label="Key"
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].value`}
                                                                                        label="Value"
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].comment`}
                                                                                        label="Comment"
                                                                                        disabled={false}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <Button
                                                                                    className="me-1 mt-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mt-2"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.insert(index, defaultAlias)}
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <Button
                                                                        className="btn-sm ms-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.push(defaultAlias)}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            {alert.visible ? (
                                                <Alert
                                                    style={{ marginTop: "10px" }}
                                                    variant={alert.color}
                                                    onClose={onDismissAlert}
                                                    dismissible
                                                >
                                                    {alert.message}
                                                </Alert>
                                            ) : null}

                                            <div className="form-row d-inline-flex align-items-baseline">
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <SubmitButton
                                                        disabled={isSubmitting || Object.keys(errors).length > 0}
                                                        label={values._id ? "Save" : "Create"}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <Link to={`/transactionitems?clientIds=${cid}&instrument.name=${extendedValues.name}`}>
                                                        {"Transactions"}
                                                    </Link>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <Link to={"/reconciliation/custodianpositions?instrumentIdIn=" + extendedValues._id}>
                                                        {"Custodian Position"}
                                                    </Link>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}

export function NewCashOrBalanceInstrumentButton({ page }: { page: string }): React.ReactElement {
    const navigate = useNavigate();
    return (
        <DropdownButton title="New" id="visibility" align="end" size="sm">
            {instrumentTypes.map((productType, i) => (
                <Dropdown.Item key={i} as="button" onClick={() => navigate(`/${page}/new` + productType.toLowerCase())}>
                    {productType}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}
