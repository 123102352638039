import React, { Fragment, ReactElement } from "react";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PlotlyDefaults } from "../../../../components/src";

import { TimeSeries } from "../../../../common/src";

const Plot = createPlotlyComponent(Plotly);

type TimeSeriePlotPropsTypes = {
    tss: TimeSeries[];
    instrumentIds: string[];
    useRangeSelector?: boolean;
    customMargin?: Record<string, number>;
};

export const TimeSeriePlot = ({ tss, useRangeSelector = true, customMargin = {} }: TimeSeriePlotPropsTypes): ReactElement => {
    const plotData: Plotly.Data[] = tss.map((ts) => {
        const data = PlotlyDefaults.toTrace(ts, ts.name);
        if ("hovertemplate" in data) {
            data["hovertemplate"] = "<extra></extra>Date %{x| %Y-%m-%d}<br>Value %{y}";
        }
        return data;
    });

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    const thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    let rangeselector = {};
    if (useRangeSelector) {
        rangeselector = {
            buttons: [
                { step: "year", stepmode: "todate", count: 1, label: "YTD" },
                { step: "all", label: "ALL" }
            ]
        };
    }
    let margin;
    if (Object.keys(customMargin).length === 0) {
        margin = { b: 10 };
    } else {
        margin = customMargin;
    }

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        dragmode: "zoom",
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        legend: {
            x: 1.1,
            y: 0.97,
            orientation: "v",
            xanchor: "auto",
            yanchor: "auto",
            xref: "container",
            yref: "container",
            bgcolor: "#FFFFFF"
        },
        xaxis: {
            title: "",
            fixedrange: false,
            rangeselector: rangeselector,
            hoverformat: "%Y-%m-%d",
            zeroline: false,
            automargin: true
        },
        yaxis: { title: "", fixedrange: false, zeroline: false, automargin: true },
        margin: margin
    };

    const thisLayout: Partial<Plotly.Layout> = PlotlyDefaults.mergeLayout(PlotlyDefaults.getDefaultLayout(), localLayout);

    plotData.forEach((t) => {
        t.hovertemplate = `<extra></extra>${t.name}<br>Date %{x| %Y-%m-%d}<br>Value %{y:.2%}`;
    });

    return (
        <Fragment>
            <Plot
                data={plotData}
                layout={thisLayout}
                config={thisConfig}
                useResizeHandler={true}
                style={{ width: "100%", height: "70vh" }}
            />
        </Fragment>
    );
};
