import React from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { Column, Grid } from "../../../components/src";

import { BaseCalendar, BaseCalendarFilterInput, Party, PartyStatusEnum, PartyType } from "../types.generated";

const GetCalendars = gql`
    query baseCalendars($filter: BaseCalendarFilterInput) {
        baseCalendars(filter: $filter) {
            _id
            name
            description
            clientIds
            year
            baseEvents {
                event
                date
                state
            }
            status
            source
        }
    }
`;

const GetFunds = gql`
    query parties($filter: PartyFilterInput) {
        parties(filter: $filter) {
            _id
            name
            fundInfo {
                calendarNames
            }
        }
    }
`;

export const CalendarsPage = (): React.ReactElement => {
    const filter: BaseCalendarFilterInput = {};

    const [{ fetching: loading, error, data }] = useQuery({
        query: GetCalendars,
        variables: { filter },
        requestPolicy: "cache-and-network"
    });
    const [{ fetching: loadingFunds, error: errorFunds, data: dataFunds }] = useQuery({
        query: GetFunds,
        variables: { filter: { typeIn: [PartyType.Fund], statusIn: [PartyStatusEnum.Confirmed] } },
        requestPolicy: "cache-and-network"
    });

    if (loading || loadingFunds) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorFunds) return <p>error: {JSON.stringify(errorFunds, null, 2)}</p>;

    const baseCalendars: BaseCalendar[] = data && data.baseCalendars ? data.baseCalendars : [];

    const funds: Party[] = dataFunds && dataFunds.parties ? dataFunds.parties : [];

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <Grid header="Fund calendars" data={funds} sortable tableClassName="table-xs">
                        <Column
                            field="name"
                            title="Fund"
                            format={(value, item) => {
                                return item && item._id ? <Link to={"/parties/" + item._id}>{value}</Link> : value;
                            }}
                        />
                        <Column
                            field="fundInfo"
                            title="Calendars"
                            format={(value, item) => {
                                return item && item.fundInfo.calendarNames.length ? (
                                    <Link to={"/calendars/" + item._id}>{item.fundInfo.calendarNames.join(", ")}</Link>
                                ) : (
                                    item.fundInfo.calendarNames.join(", ")
                                );
                            }}
                        />
                    </Grid>
                </div>
                <div className="col-8">
                    <Grid header="All calendars" data={baseCalendars} sortable tableClassName="table-xs">
                        <Column
                            field="name"
                            title="Name"
                            format={(value, item) => {
                                return item && item._id ? <Link to={"/calendars/" + item._id}>{value}</Link> : value;
                            }}
                        />
                        <Column
                            field="description"
                            title="Description"
                            format={(value, item) => {
                                return item && item._id ? <Link to={"/calendars/" + item._id}>{value}</Link> : value;
                            }}
                        />
                        <Column
                            field="year"
                            title={"Year"}
                            format={(value, item) => {
                                return item && item._id ? <Link to={"/calendars/" + item._id}>{value}</Link> : value;
                            }}
                        />
                    </Grid>
                </div>
            </div>
        </div>
    );
};
