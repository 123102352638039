import {
    AccountingCompanyTypeEnum,
    AccountingLanguageEnum,
    TAccountGroup1Enum,
    TAccountGroup2Enum,
    TAccountGroup3Enum,
    TAccountGroup4Enum,
    TAccountGroup5Enum
} from "./types.generated";

export type TranslationMapping = {
    [key in AccountingLanguageEnum]?: CompanyMapping;
};

export type CompanyMapping = {
    [key in AccountingCompanyTypeEnum]?: string;
};

export type GroupMapping = {
    sorting: number;
    translation: TranslationMapping;
};

export type Group1Mapping = {
    [key in TAccountGroup2Enum]?: Group2Mapping;
} & GroupMapping;

export type Group2Mapping = {
    [key in TAccountGroup3Enum]?: Group3Mapping;
} & GroupMapping;

export type Group3Mapping = {
    [key in TAccountGroup4Enum]?: Group4Mapping;
} & GroupMapping;

export type Group4Mapping = {
    [key in TAccountGroup5Enum]?: GroupMapping;
} & GroupMapping;

export type TAccountGroupMappingType = {
    [key in TAccountGroup1Enum]?: Group1Mapping;
};

export const mapping: TAccountGroupMappingType = {
    Assets: {
        translation: {
            Sv: {
                SwedishMutualFund: "Tillgångar",
                GeneralPortfolio: "Tillgångar"
            }
        },
        sorting: 1,
        A: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Finansiella Instrument med positivt marknadsvärde",
                    GeneralPortfolio: "Finansiella Instrument med positivt marknadsvärde"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Överlåtbara värdepapper",
                        GeneralPortfolio: "Överlåtbara värdepapper"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Penningmarknadsinstrument",
                        GeneralPortfolio: "Penningmarknadsinstrument"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "OTC-derivatinstrument med positivt marknadsvärde",
                        GeneralPortfolio: "OTC-derivatinstrument med positivt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            D: {
                sorting: 4,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga derivatinstrument med positivt marknadsvärde",
                        GeneralPortfolio: "Övriga derivatinstrument med positivt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            E: {
                sorting: 5,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Fondandelar",
                        GeneralPortfolio: "Fondandelar"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        B: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "Placeringar med positivt marknadsvärde",
                    GeneralPortfolio: "Placeringar med positivt marknadsvärde"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        C: {
            sorting: 3,
            translation: {
                Sv: {
                    SwedishMutualFund: "C",
                    GeneralPortfolio: "C"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Bankmedel och övriga likvida medel",
                        GeneralPortfolio: "Bankmedel och övriga likvida medel"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Förutbetalda kostnader och upplupna intäkter",
                        GeneralPortfolio: "Förutbetalda kostnader och upplupna intäkter"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga tillgångar",
                        GeneralPortfolio: "Övriga tillgångar"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        }
    },
    Liabilities: {
        sorting: 1,
        translation: {
            Sv: {
                SwedishMutualFund: "Skulder",
                GeneralPortfolio: "Skulder"
            }
        },
        A: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Finansiella instrument med negativt marknadsvärde",
                    GeneralPortfolio: "Finansiella instrument med negativt marknadsvärde"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "OTC-derivat med negativt marknadsvärde",
                        GeneralPortfolio: "OTC-derivat med negativt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga derivatinstrument med negativt marknadsvärde",
                        GeneralPortfolio: "Övriga derivatinstrument med negativt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga finansiella instrument med negativt marknadsvärde",
                        GeneralPortfolio: "Övriga finansiella instrument med negativt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        B: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "B",
                    GeneralPortfolio: "B"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Skatteskulder",
                        GeneralPortfolio: "Skatteskulder"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Upplupna kostnader och förutbetalda intäkter",
                        GeneralPortfolio: "Upplupna kostnader och förutbetalda intäkter"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga skulder",
                        GeneralPortfolio: "Övriga skulder"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        }
    },
    Equity: {
        sorting: 2,
        translation: {
            Sv: {
                SwedishMutualFund: "Fondförmögenhet",
                GeneralPortfolio: "Eget kapital"
            }
        },
        ShareCapital: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Tecknade andelar",
                    GeneralPortfolio: "Aktiekapital"
                }
            },
            ShareCapital: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Tecknade andelar",
                        GeneralPortfolio: "Aktiekapital"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        RetainedEarnings: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "Balanserat resultat",
                    GeneralPortfolio: "Balanserat resultat"
                }
            },
            RetainedEarnings: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Balanserat resultat",
                        GeneralPortfolio: "Balanserat resultat"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        DividendPaid: {
            sorting: 3,
            translation: {
                Sv: {
                    SwedishMutualFund: "Lämnad utdelning",
                    GeneralPortfolio: "Lämnad utdelning"
                }
            },
            DividendPaid: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Lämnad utdelning",
                        GeneralPortfolio: "Lämnad utdelning"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        },
        ProfitLossCurrentYear: {
            sorting: 4,
            translation: {
                Sv: {
                    SwedishMutualFund: "Årets resultat",
                    GeneralPortfolio: "Årets resultat"
                }
            },
            Revenue: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Intäkter och värdeförändring",
                        GeneralPortfolio: "Intäkter och värdeförändring"
                    }
                },
                A: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på överlåtbara värdepapper",
                            GeneralPortfolio: "Värdeförändring på överlåtbara värdepapper"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                B: {
                    sorting: 2,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på penningmarknadsinstrument",
                            GeneralPortfolio: "Värdeförändring på penningmarknadsinstrument"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                C: {
                    sorting: 3,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på OTC-derivat",
                            GeneralPortfolio: "Värdeförändring på OTC-derivat"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                D: {
                    sorting: 4,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på övriga derivatinstrument",
                            GeneralPortfolio: "Värdeförändring på övriga derivatinstrument"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                E: {
                    sorting: 5,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på fondandelar",
                            GeneralPortfolio: "Värdeförändring på fondandelar"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                F: {
                    sorting: 6,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Ränteintäkter",
                            GeneralPortfolio: "Ränteintäkter"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                G: {
                    sorting: 7,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Utdelningar",
                            GeneralPortfolio: "Utdelningar"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                H: {
                    sorting: 8,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Valutakursvinster och -förluster netto",
                            GeneralPortfolio: "Valutakursvinster och -förluster netto"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                I: {
                    sorting: 9,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga finansiella intäkter",
                            GeneralPortfolio: "Övriga finansiella intäkter"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                J: {
                    sorting: 10,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga intäkter",
                            GeneralPortfolio: "Övriga intäkter"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            Expenses: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Kostnader",
                        GeneralPortfolio: "Kostnader"
                    }
                },
                ManagementFees: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Förvaltningskostnader",
                            GeneralPortfolio: "Förvaltningskostnader"
                        }
                    },
                    A: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till fondbolaget",
                                GeneralPortfolio: "Ersättning till fondbolaget"
                            }
                        }
                    },
                    B: {
                        sorting: 2,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till förvaringsinstitutet",
                                GeneralPortfolio: "Ersättning till förvaringsinstitutet"
                            }
                        }
                    },
                    C: {
                        sorting: 2,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till tillsynsmyndighet",
                                GeneralPortfolio: "Ersättning till tillsynsmyndighet"
                            }
                        }
                    }
                },
                A: {
                    sorting: 2,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Ersättning till revisorer",
                            GeneralPortfolio: "Ersättning till revisorer"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                B: {
                    sorting: 3,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Räntekostnader",
                            GeneralPortfolio: "Räntekostnader"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                C: {
                    sorting: 4,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga finansiella kostnader",
                            GeneralPortfolio: "Övriga finansiella kostnader"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                },
                D: {
                    sorting: 5,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga kostnader",
                            GeneralPortfolio: "Övriga kostnader"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            },
            Tax: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Skatt",
                        GeneralPortfolio: "Skatt"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: ""
                            }
                        }
                    }
                }
            }
        }
    }
};
