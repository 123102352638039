import React, { ReactElement } from "react";
import { gql, useMutation, useQuery } from "urql";
import { Link } from "react-router-dom";
import { Grid, Column } from "../../../../components/src";
import { Button } from "react-bootstrap";
import { Svgs } from "../../../../components/src";

import { userHaveAccessTo } from "../../common/Permissions";
import { PermissionAssetEnum } from "../../types.generated";
import { dateFormater } from "../../components/dateFormater";

const GET_ME = gql`
    query {
        me {
            personalNumber
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

const PERFORMANCE_CACHE = gql`
    query performanceCache {
        performanceCache {
            clientId
            client {
                name
            }
            createTimestamp
        }
    }
`;

const CLEAN_PERFORMANCE_CACHE = gql`
    mutation cleanPerformanceCache($clientIds: [GraphQLObjectId!]) {
        cleanPerformanceCache(clientIds: $clientIds)
    }
`;

export function AdminCleanPerformanceCachePage(): ReactElement {
    const [{ fetching: loadingMe, error: errorMe, data: getMe }] = useQuery({ query: GET_ME });
    const [{ fetching, error, data }, refetch] = useQuery({ query: PERFORMANCE_CACHE, requestPolicy: "network-only" });
    const [_, cleanPerformanceCache] = useMutation(CLEAN_PERFORMANCE_CACHE);

    if (loadingMe || fetching)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (errorMe) return <pre>{JSON.stringify(errorMe, null, 2)}</pre>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Admin, getMe.me.roles)) {
        return <div>You don't have access to this page</div>;
    }

    return (
        <div className="admin-page">
            <Grid header="Clean performance cache" data={data.performanceCache} sortable tableClassName="table-xs">
                <Column
                    field="client"
                    className="nowrap maxwidth150px left"
                    format={(_, item) => {
                        return <Link to={"/parties/" + item.clientId}>{item.client.name}</Link>;
                    }}
                />
                <Column field="createTimestamp" title="Create timestamp" format={dateFormater} />
                <Column
                    field="clientId"
                    title=""
                    format={(value) => {
                        return (
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={async () => {
                                    console.log("value: ", value);
                                    await cleanPerformanceCache({ clientIds: [value] })
                                        .catch((error) => {
                                            console.error(error);
                                        })
                                        .finally(() => {
                                            console.log("refetch()");
                                            refetch({ requestPolicy: "network-only" });
                                        });
                                }}
                            >
                                Clean cache
                            </Button>
                        );
                    }}
                />
            </Grid>
        </div>
    );
}
