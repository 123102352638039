import React, { Fragment, useState } from "react";
import { gql, useQuery } from "urql";
import { Grid, Column } from "../../../components/src";
import { SelectField } from "../components/form";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";

const getClients = gql`
    query {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
        }
    }
`;

const getIssuerprograms = gql`
    query getIssuerprograms($filter: IssuerProgramsFilterInput) {
        issuerprograms(filter: $filter) {
            _id
            spoProviderId
            category
            issuerId
            sustainableDevelopmentGoals
            useOfProceeds
            euTaxonomyEnvironmentalObjectives
            name
            client {
                name
                _id
            }
            issuer {
                name
            }
            spoProvider {
                name
            }
            attachments {
                fileName
            }
            updateUserInfo {
                name
            }
        }
    }
`;

export const IssuerPrograms = ({ clientId }: { clientId: string }): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({
        query: getIssuerprograms,
        variables: { filter: { clientIdIn: clientId === "000000000000000000000000" ? null : clientId } },
        requestPolicy: "network-only"
    });

    if (fetching) return <p>Loading IssuerPrograms</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <Grid header="Issuer Programs" data={data.issuerprograms} sortable tableClassName="table-xs">
            <Column
                field="issuer.name"
                title="Issuer"
                className="grid-column-sticky"
                format={(value, item) => {
                    return <Link to={"/parties/" + item.issuerId}>{value}</Link>;
                }}
            />
            <Column
                className="left"
                field="name"
                format={(value, item) => {
                    return <Link to={"/issuerprograms/" + item._id}>{value}</Link>;
                }}
            ></Column>
            <Column
                className="left"
                field="spoProviderId"
                title="SPO provider"
                format={(value, item) => {
                    if (item.spoProviderId && item.spoProviderId !== "000000000000000000000000") {
                        return item.spoProvider.name;
                    } else {
                        return "";
                    }
                }}
            />
            <Column
                field="sustainableDevelopmentGoals"
                title="Sustainable development goals"
                format={(goals) => {
                    if (goals) {
                        return goals.map((g) => {
                            return <div key={g}>{g}</div>;
                        });
                    }
                }}
            />
            <Column
                field="useOfProceeds"
                title="Use of proceeds"
                format={(proceeds) => {
                    if (proceeds) {
                        return proceeds.map((p) => {
                            return <div key={p}>{p}</div>;
                        });
                    }
                }}
            />
            <Column
                field="euTaxonomyEnvironmentalObjectives"
                title="EU taxonomy - Environmental objective"
                format={(proceeds) => {
                    if (proceeds) {
                        return proceeds.map((p) => {
                            return <div key={p}>{p}</div>;
                        });
                    }
                }}
            />
            <Column field="category" title="Category" />

            <Column
                className="nowrap"
                field="updateUserInfo"
                title="Updated by"
                format={(d, item) => {
                    if (item.updateUserInfo && item.updateUserInfo.name) {
                        return item.updateUserInfo.name;
                    } else {
                        return "";
                    }
                }}
            />
            <Column
                className="left"
                field="clientId"
                title="Client"
                format={(_, item) => {
                    return <Link to={"/parties/" + item.client._id}>{item.client.name}</Link>;
                }}
            />
        </Grid>
    );
};

export const IssuerProgramsPage = (): React.ReactElement => {
    const [clientId, setClientId] = useState("000000000000000000000000"); // set to System by default
    const [{ fetching, error, data }] = useQuery({ query: getClients, requestPolicy: "network-only" });

    //let clients = [{ key: "All", value: null }];

    let clients = [];
    if (data) {
        clients = data.clients.map((c) => {
            return { value: c.name, name: c.name, _id: c._id, key: c._id };
        });
        clients = [{ value: "All", name: "All", _id: "000000000000000000000000", key: "000000000000000000000000" }, ...clients];
    }

    if (fetching) return <p>Loading Clients</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div className="container page">
            <div className="row">
                <div className="col-12">
                    <div className="row" />
                    <br />
                    <Formik
                        initialValues={{ clientId }}
                        validate={(validateFormData) => {
                            if (validateFormData.clientId !== clientId) {
                                setClientId(validateFormData.clientId);
                            }
                            const errors: any = {};
                            return Object.keys(errors).length > 0 ? errors : {};
                        }}
                        onSubmit={null}
                    >
                        {({ isSubmitting }) => (
                            <Fragment>
                                <Form autoComplete="off">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <SelectField
                                                className=""
                                                name="clientId"
                                                label="Client"
                                                options={clients}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </Fragment>
                        )}
                    </Formik>

                    <IssuerPrograms clientId={clientId} />
                </div>
            </div>
        </div>
    );
};
