import React from "react";
import MarkdownIt from "markdown-it";
import markdownItMermaid from "@liradb2000/markdown-it-mermaid";
import mk from "markdown-it-katex";
import "katex/dist/katex.min.css";
const mdParser = new MarkdownIt();
mdParser.use(markdownItMermaid).use(mk, { throwOnError: false, errorColor: " #cc0000" });

export const Markdown = ({ markdown }: { markdown: string }): React.ReactElement => {
    return <span className="markdown" dangerouslySetInnerHTML={{ __html: mdParser.render(markdown) }} />;
};
