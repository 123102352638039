import { gql } from "urql";

export const getTransactions = gql`
    query getTransactions($clientId: GraphQLObjectId, $endDate: GraphQLDateString, $statusNotIn: [TransactionStatus!]) {
        party(_id: $clientId) {
            _id
            name
            accountingCurrency
            accountingFrequency
            instruments {
                _id
                name
                longName
                productType
                quantityDecimals
                category
                currency
            }
        }
        transactions(clientId: $clientId, filter: { endDate: $endDate, statusNotIn: $statusNotIn }) {
            _id
            status
            type
            tradeDate
            tradeTimestamp
            description
            items {
                transactionId
                type
                currency
                amount
                price
                quantity
                instrumentId
                instrument {
                    quantityDecimals
                }
                valueDate
                fxRate
            }
        }
        instruments(filter: { productTypeIn: FxPair }) {
            _id
            name
            longName
            productType
            quantityDecimals
            category
            currency
        }
    }
`;

export const getInstruments = gql`
    query getInstruments($ids: [GraphQLObjectId!]) {
        instruments(idIn: $ids) {
            _id
            name
            longName
            currency
            productType
            quantityDecimals
            category
        }
    }
`;

export const getValuations = gql`
    query getValuations($startDate: GraphQLSwedenDate, $endDate: GraphQLSwedenDate, $instrumentIds: [GraphQLObjectId!]) {
        valuations(startDate: $startDate, endDate: $endDate, instrumentIds: $instrumentIds) {
            _id
            date
            instrumentId
            instrument {
                name
            }
            records {
                prices {
                    type
                    value
                    currency
                }
            }
        }
    }
`;

export const getAccountingRunWithJournalEntries = gql`
    query getAccountingRunWithJournalEntries($id: GraphQLObjectId!) {
        accountingRun(_id: $id) {
            accountingPeriod
            number
            type
            status
            client {
                _id
                accountingCompanyType
                accountingFrequency
            }
            journalEntries {
                _id
                batch
                number
                effectiveDate
                description
                externalNumber
                portfolioTransactionId
                portfolioTransaction {
                    type
                    externalId
                }
                transactions {
                    amount
                    quantity
                    tAccountNumber
                    type
                    instrument {
                        _id
                        name
                    }
                }
            }
            clientTAccountChart {
                tAccounts {
                    number
                    description
                    group1
                    group2
                    group3
                    group4
                    group5
                }
            }
        }
    }
`;

export const getAccountingRunsWithJournalEntries = gql`
    query getAccountingRunsWithJournalEntries($clientId: GraphQLObjectId!, $accountingPeriod: String, $previousAccountingPeriod: String) {
        accountingRuns(clientId: $clientId, accountingPeriod: $accountingPeriod) {
            _id
            status
            type
            endDate
            accountingPeriod
            number
            roundingDecimals
            client {
                _id
                accountingCompanyType
                accountingFrequency
            }
            journalEntries {
                _id
                batch
                number
                effectiveDate
                description
                externalNumber
                portfolioTransactionId
                portfolioTransaction {
                    type
                    externalId
                }
                transactions {
                    amount
                    quantity
                    tAccountNumber
                    type
                    instrumentId
                    instrument {
                        _id
                        name
                        modelType
                        productType
                        currency
                    }
                }
            }
            clientTAccountChart {
                tAccounts {
                    number
                    description
                    group1
                    group2
                    group3
                    group4
                    group5
                }
            }
        }
        previousAccountingRuns: accountingRuns(clientId: $clientId, accountingPeriod: $previousAccountingPeriod) {
            _id
            status
            type
            endDate
            accountingPeriod
            number
            roundingDecimals
            client {
                _id
                accountingCompanyType
                accountingFrequency
            }
            journalEntries {
                _id
                batch
                number
                effectiveDate
                description
                externalNumber
                portfolioTransactionId
                portfolioTransaction {
                    type
                    externalId
                }
                transactions {
                    amount
                    quantity
                    tAccountNumber
                    type
                    instrumentId
                    instrument {
                        _id
                        name
                        modelType
                        productType
                        currency
                    }
                }
            }
            clientTAccountChart {
                tAccounts {
                    number
                    description
                    group1
                    group2
                    group3
                    group4
                    group5
                }
            }
        }
    }
`;

export const getAccountingRuns = gql`
    query getAccountingRuns($clientId: GraphQLObjectId!) {
        accountingRuns(clientId: $clientId) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
            updateUserId
        }
    }
`;

export const getTransactionItems = gql`
    query getTransactionItems($securityFilter: TransactionItemFilterInput) {
        transactionItems(filter: $securityFilter) {
            _id
            transactionTradeDate
            amount
            currency
            instrumentId
            fxRate
            externalAccount {
                type
            }
            instrument {
                _id
                name
                modelType
                productType
                issuerProgram {
                    covered
                }
                issuer {
                    industryCode
                }
            }
        }
    }
`;

export const getClients = gql`
    query getClients {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
            accountingFrequency
            accountingCurrency
            accountingCompanyType
            types
            instrumentIds
            instruments {
                name
                _id
            }
        }
        me {
            _id
            name
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
            frontendRole {
                assets
            }
        }
    }
`;

export const getMe = gql`
    query getMe {
        me {
            _id
            name
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
            frontendRole {
                assets
            }
        }
    }
`;

export const getPositions = gql`
    query getPositions($filter: PositionFilter, $groupPositionsBy: PositionSelectorType) {
        tradingmanager(filterZeroPositions: true, filter: $filter, lookThrough: false, groupPositionsBy: $groupPositionsBy) {
            instrumentId
            name
            longName
            isin
            quantity
            currency
            exposure
            localExposure
            valuationDate
            issuerIndustryCode
            externalAccountType
            isCashAccount
            fxRate
            valuationPrice
            instrument {
                _id
                modelType
                productType
                modelNotionalScaling
                issuerProgram {
                    covered
                }
            }
        }
    }
`;

export const getTAccountCharts = gql`
    query getTAccountCharts($clientId: GraphQLObjectId) {
        tAccountCharts(clientId: $clientId) {
            _id
            name
            version
            client {
                _id
                name
                accountingFrequency
            }
            locked
            tAccounts {
                number
                group1
                group2
                group3
                group4
                group5
            }
        }
    }
`;

export const getAllTAccountCharts = gql`
    query getAllTAccountCharts {
        tAccountCharts {
            _id
            name
            locked
            version
            client {
                _id
                name
            }
        }
    }
`;

export const getTAccountChart = gql`
    query getTAccountChart($id: GraphQLObjectId!) {
        tAccountChart(_id: $id) {
            _id
            name
            version
            locked
            clientId
            client {
                _id
                name
                accountingCompanyType
                instruments {
                    _id
                    name
                    longName
                    currency
                }
            }
            tAccountMappings {
                name
                selector {
                    key
                    instruments {
                        instrumentId
                        alias
                    }
                    categories
                    notMatchCategories
                    currencies
                    notMatchCurrencies
                    productTypes
                    notMatchProductTypes
                }
                values {
                    key
                    value
                }
            }
            tAccounts {
                number
                type
                description
                group1
                group2
                group3
                group4
                group5
            }
        }
    }
`;
