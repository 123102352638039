import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql, useQuery } from "urql";
import { cloneDeep, orderBy, sortBy } from "lodash";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

import { fourDecPriceFormat, twoDecPriceFormat } from "../../../common/src";
import { Svgs } from "../../../components/src";
import { BrokerTransactionForm } from "./BrokerTransactionForm";
import { CorporateActionForm } from "./CorporateActionForm";
import stableStringify from "json-stable-stringify";
import { DateHelper } from "../../../common/src";
import { Panel } from "@fluentui/react";

import { useQueryArgs, usePrevious, removeNull } from "../common/Utils";
import { exportToXlsx, sheetType } from "../common/exportToXlsx";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "../containers/MiniTransactionForm";
import { TransactionFilterInput, TransactionItemType, TransactionStatus, TransactionType } from "../types.generated";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { ReactTable } from "../components/react-table/ReactTable";
import { Form, Formik } from "formik";
import { DateField, SearchListField, SubmitButton } from "../components/form";
import { Button } from "react-bootstrap";

const GET_TRANSACTIONS = gql`
    query transactions($filter: TransactionFilterInput) {
        transactions(filter: $filter, includePortfolioSwapConstituents: true) {
            _id
            client {
                _id
                name
            }
            broker {
                name
            }
            tradeDate
            description
            type
            status
            uniqueTradeId
            brokerTradeId
            brokerTransaction {
                _id
            }
            corporateActionSwift {
                _id
            }
            updateUserInfo {
                name
            }
            items {
                type
                amount
                quantity
                price
                currency
                valueDate
                instrument {
                    _id
                    name
                }
                portfolioInstrumentId
                portfolioInstrument {
                    _id
                    name
                }
                performanceType
            }
            attachments {
                fileId
            }
            updateTimestamp
            externalId
        }
    }
`;

const defaultHiddenColumns = [
    "secondCurrency",
    "secondSettlementAmount",
    "secondInstrumentName",
    "brokerTradeId",
    "updateTimestamp",
    "externalId",
    "uniqueTradeId",
    "portfolioInstrumentName"
];

const processTransactions = (trans: any) => {
    const transactions = orderBy(cloneDeep(trans), "tradeDate", "desc");

    const clientsById: Record<string, { _id: string; name: string }> = { "": { _id: "", name: "" } };

    transactions.forEach((transaction) => {
        const firstItem = transaction.items[0];
        if (!clientsById[transaction.client._id.toString()]) {
            clientsById[transaction.client._id.toString()] = {
                _id: transaction.client._id.toString(),
                name: transaction.client.name.toString()
            };
        }

        transaction.settlementAmount = null;
        transaction.clientName = transaction.client.name;
        transaction.updatedBy = transaction.updateUserInfo ? transaction.updateUserInfo.name : "";
        if (transaction.broker) {
            transaction.brokerName = transaction.broker.name;
        } else {
            transaction.brokerName = null;
        }
        transaction.secondSettlementAmount = null;
        transaction.secondInstrumentName = null;
        transaction.secondCurrency = null;
        transaction.currency = null;
        transaction.quantity = null;
        transaction.price = null;
        if (firstItem) {
            transaction.valueDate = firstItem.valueDate;
        }
        let itemIndex = 0;
        const instrumentNameItemTypeOrder = {
            [TransactionItemType.Security]: 1,
            [TransactionItemType.Collateral]: 2,
            [TransactionItemType.CreateRedeem]: 3,
            [TransactionItemType.Interest]: 4,
            [TransactionItemType.Fee]: 5,
            [TransactionItemType.Dividend]: 6,
            [TransactionItemType.CustodyFee]: 7,
            [TransactionItemType.ManagementFee]: 8,
            [TransactionItemType.ManagementCost]: 8,
            [TransactionItemType.TaxRestitution]: 9,
            [TransactionItemType.SettlementAmount]: 10
        };

        if (transaction.corporateActionSwift) {
            transaction.coacsSwiftId = transaction.corporateActionSwift._id;
        }
        if (transaction.brokerTransaction) {
            transaction.brokerTransactionId = transaction.brokerTransaction._id;
        }

        let usedItemType: TransactionItemType = null;
        transaction.items.forEach((transactionItem) => {
            transaction.performanceType = transactionItem.performanceType;
            transaction.portfolioInstrumentName = transactionItem.portfolioInstrument ? transactionItem.portfolioInstrument.name : null;
            transaction.portfolioInstrumentId = transactionItem.portfolioInstrument ? transactionItem.portfolioInstrument._id : null;
            if (transactionItem.type === TransactionItemType.SettlementAmount) {
                if (transaction.type === TransactionType.Transfer && itemIndex === 0) {
                    transaction.instrumentName = transactionItem.instrument.name;
                    transaction.instrumentId = transactionItem.instrument._id;
                    transaction.settlementAmount = transactionItem.amount;
                    transaction.currency = transactionItem.currency;
                    itemIndex = itemIndex + 1;
                } else if (transaction.type === TransactionType.Transfer && itemIndex === 1) {
                    transaction.secondInstrumentName = transactionItem.instrument.name;
                    transaction.secondInstrumentId = transactionItem.instrument._id;
                    transaction.secondSettlementAmount = transactionItem.amount;
                    transaction.secondCurrency = transactionItem.currency;
                    itemIndex = itemIndex + 1;
                } else {
                    transaction.settlementAmount = transactionItem.amount;
                    transaction.currency = transactionItem.currency;
                }
            } else if (
                instrumentNameItemTypeOrder[transactionItem.type] &&
                (!instrumentNameItemTypeOrder[usedItemType] ||
                    instrumentNameItemTypeOrder[usedItemType] > instrumentNameItemTypeOrder[transactionItem.type])
            ) {
                usedItemType = transactionItem.type;
                transaction.instrumentName = transactionItem.instrument
                    ? transactionItem.instrument.name
                        ? transactionItem.instrument.name
                        : ""
                    : "";
                transaction.instrumentId = transactionItem.instrument ? transactionItem.instrument._id : null;
                transaction.quantity = transactionItem.quantity ? transactionItem.quantity : null;
                transaction.price = transactionItem.price ? transactionItem.price : null;
            }
        });
    });
    return { transactions, clientsById };
};

interface FilterType {
    qClientId: string;
    qStatus: TransactionStatus;
    qType: TransactionType;
    qStartDate: string;
    qEndDate: string;
    qInstrumentId: string;
}

const Transactions = (): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationSearch = useRef(location.search);
    const [showFilter, setShowFilter] = useState(true);
    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);

    const params: any = useParams();

    let { type } = params;
    let { id } = params;
    let miniTransactionType = miniTransactionTypes[0];
    if (!type) {
        type = "minitransaction";
    }
    if (id && id.startsWith("new")) {
        miniTransactionType = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
        id = "new";
    }

    const startDate: string = DateHelper.addDays(new Date(), -60).toISOString().slice(0, 10);
    const endDate: string = new Date().toISOString().slice(0, 10);

    const [filter, setFilter] = useState<FilterType>({
        qClientId: null,
        qStatus: null,
        qType: null,
        qStartDate: startDate,
        qEndDate: endDate,
        qInstrumentId: null
    });

    useEffect(() => {
        locationSearch.current = location.search;
    }, [location.search]);

    useEffect(() => {
        if (previousQueryArgs && !Object.keys(previousQueryArgs).length && !filter.qStartDate) {
            setFilter({ qClientId: null, qStatus: null, qType: null, qStartDate: startDate, qEndDate: endDate, qInstrumentId: null });
            setShowFilter(true);
        }

        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            const newFilter: FilterType = {
                qClientId: null,
                qStatus: null,
                qType: null,
                qStartDate: startDate,
                qEndDate: endDate,
                qInstrumentId: null
            };
            const newTransactionFilter: Partial<TransactionFilterInput> = {};

            if ("qClientId" in queryArgs && queryArgs["qClientId"]) {
                newFilter["qClientId"] = queryArgs["qClientId"].toString();
                newTransactionFilter.clientIds = [queryArgs["qClientId"].toString()];
            }

            if ("qStatus" in queryArgs && queryArgs["qStatus"]) {
                newFilter["qStatus"] = queryArgs["qStatus"] as TransactionStatus;
                newTransactionFilter.statusIn = [queryArgs["qStatus"] as TransactionStatus];
            }
            if ("qType" in queryArgs && queryArgs["qType"]) {
                newFilter["qType"] = queryArgs["qType"] as TransactionType;
                newTransactionFilter.typesIn = [queryArgs["qType"] as TransactionType];
            }
            if ("qStartDate" in queryArgs && queryArgs["qStartDate"]) {
                newFilter["qStartDate"] = queryArgs["qStartDate"].toString();
                newTransactionFilter.startDate = queryArgs["qStartDate"].toString();
            }
            if ("qEndDate" in queryArgs && queryArgs["qEndDate"]) {
                newFilter["qEndDate"] = queryArgs["qEndDate"].toString();
                newTransactionFilter.endDate = queryArgs["qEndDate"].toString();
            }

            if ("qInstrumentId" in queryArgs && queryArgs["qInstrumentId"]) {
                newFilter["qInstrumentId"] = queryArgs["qInstrumentId"].toString();
                newTransactionFilter.instrumentIdsIn = [queryArgs["qInstrumentId"].toString()];
            }

            if (stableStringify(filter) !== stableStringify(newFilter)) {
                setFilter(newFilter);
                setShowFilter(true);
            }
        }
    }, [endDate, filter, previousQueryArgs, queryArgs, startDate]);

    const [{ fetching, data, error }] = useQuery({
        query: GET_TRANSACTIONS,
        variables: {
            filter: {
                clientIds: filter.qClientId ? [filter.qClientId] : null,
                statusIn: filter.qStatus ? [filter.qStatus] : null,
                typesIn: filter.qType ? [filter.qType] : null,
                startDate: filter.qStartDate ? filter.qStartDate : null,
                endDate: filter.qEndDate ? filter.qEndDate : null,
                instrumentIdsIn: filter.qInstrumentId ? [filter.qInstrumentId] : null
            }
        }
    });

    const download = () => {
        const currentRecords: sheetType = transactions;
        exportToXlsx([currentRecords], "transactions.xlsx");
    };

    const { transactions, clientsById } = useMemo(() => processTransactions(data ? data.transactions : []), [data]);

    const addLocationSearch = useCallback(
        (e): string => {
            return e + locationSearch.current;
        },
        [locationSearch]
    );

    const columns = React.useMemo(
        () => [
            {
                header: "Client",
                accessorKey: "clientName",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 75
            },
            {
                header: "Trade date",
                accessorKey: "tradeDate",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>
            },
            {
                header: "Value date",
                accessorKey: "valueDate",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>
            },
            {
                header: "Broker",
                accessorKey: "brokerName",
                filter: SelectColumnFilter
            },
            {
                header: "Description",
                accessorKey: "description"
            },
            {
                header: "Type",
                accessorKey: "type",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const isMiniTransaction = miniTransactionTypes.includes(cellProps.getValue());
                    return isMiniTransaction && row.original ? (
                        <Link to={addLocationSearch(`/transactions/minitransaction/${row.original._id}`)}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                },
                filter: SelectColumnFilter
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilter
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{fourDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Price",
                accessorKey: "price",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{fourDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Settlement amount",
                accessorKey: "settlementAmount",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter
            },
            {
                header: "Instrument",
                accessorKey: "instrumentName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.instrumentId ? (
                        <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                }
            },
            {
                header: "Settlement amount 2",
                accessorKey: "secondSettlementAmount",
                filterFn: "startsWith",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                size: 165
            },
            {
                header: "Currency 2",
                accessorKey: "secondCurrency",
                filter: SelectColumnFilter,
                size: 95
            },
            {
                header: "Instrument 2",
                accessorKey: "secondInstrumentName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.secondInstrumentId ? (
                        <Link to={`/instruments/${row.original.secondInstrumentId}`}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                },
                size: 110
            },
            {
                header: "Updated by",
                accessorKey: "updatedBy",
                filter: SelectColumnFilter
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp"
            },
            {
                header: "External id",
                accessorKey: "externalId"
            },
            {
                header: "UTI",
                accessorKey: "uniqueTradeId"
            },
            {
                header: "Broker trade id",
                accessorKey: "brokerTradeId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.brokerTransaction && row.original.brokerTransaction._id) {
                        return (
                            <Link to={addLocationSearch(`/transactions/brokertransaction/${row.original.brokerTransaction._id}`)}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else if (row.original.corporateActionSwift && row.original.corporateActionSwift._id) {
                        return (
                            <Link to={addLocationSearch(`/transactions/corporateaction/${row.original.corporateActionSwift._id}`)}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else {
                        <div>{cellProps.getValue()}</div>;
                    }
                },
                size: 110
            },
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/transaction/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 70
            },
            {
                header: "Performance type",
                accessorKey: "performanceType",
                filter: SelectColumnFilter
            },
            {
                header: "Portfolio instrument",
                accessorKey: "portfolioInstrumentName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.instrumentId ? (
                        <Link to={`/instruments/${row.original.portfolioInstrumentId}`}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                }
            },
            {
                header: "Attachments",
                id: "attachments.length",
                accessorKey: "attachments.length",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter
            }
        ],
        [addLocationSearch]
    );

    if (fetching) return <div>Loading...</div>;
    if (error)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!data) return <div>No transactions</div>;

    const statuses: { _id: string; name: string }[] = [];

    Object.values(TransactionStatus).forEach((status) => {
        statuses.push({ _id: status, name: status });
    });

    const types: { _id: string; name: string }[] = [];

    Object.values(TransactionType).forEach((status) => {
        types.push({ _id: status, name: status });
    });

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <div>
            <div
                style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                onClick={() => {
                    download();
                }}
            >
                <Svgs.Excel />
            </div>
            <div className="row">
                <div className="col ps-0">
                    {showFilter ? (
                        <Button variant="link" onClick={toggleFilter}>
                            Hide filter
                        </Button>
                    ) : (
                        <Button variant="link" onClick={toggleFilter}>
                            Show filter
                        </Button>
                    )}
                </div>
            </div>
            {showFilter ? (
                <div className="row">
                    <div className="col">
                        <Formik
                            enableReinitialize={true}
                            initialValues={filter}
                            validate={() => {
                                const errors: any = {};
                                return Object.keys(errors).length > 0 ? errors : {};
                            }}
                            onSubmit={async (submitValues) => {
                                const newValues: Partial<TransactionFilterInput> = {};

                                if (submitValues.qClientId) {
                                    newValues.clientIds = [cloneDeep(submitValues.qClientId)];
                                }

                                if (submitValues.qStatus) {
                                    newValues.statusIn = [cloneDeep(submitValues.qStatus) as TransactionStatus];
                                }

                                if (submitValues.qType) {
                                    newValues.typesIn = [cloneDeep(submitValues.qType) as TransactionType];
                                }

                                if (submitValues.qStartDate) {
                                    newValues.startDate = cloneDeep(submitValues.qStartDate);
                                }

                                if (submitValues.qEndDate) {
                                    newValues.endDate = cloneDeep(submitValues.qEndDate);
                                }

                                pushQueryArgs(removeNull(submitValues));
                            }}
                        >
                            {({ isSubmitting }) => {
                                return (
                                    <Form autoComplete="off">
                                        <div className="row">
                                            <div className="form col-2">
                                                <DateField className="" name={"qStartDate"} label={"Start date"} disabled={isSubmitting} />
                                            </div>
                                            <div className="form col-2">
                                                <DateField className="" name={"qEndDate"} label={"End date"} disabled={isSubmitting} />
                                            </div>
                                            <div className="col-sm-2">
                                                <SearchListField
                                                    name="qStatus"
                                                    label={"Status"}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(statuses, "name")}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="qClientId"
                                                    label="Client"
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(Object.values(clientsById), "name")}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <SearchListField
                                                    name="qType"
                                                    label={"Type"}
                                                    className=""
                                                    disabled={isSubmitting}
                                                    options={sortBy(types, "name")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-sm-4">
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <SubmitButton disabled={fetching} label={"Apply"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            ) : null}
            <ReactTable columns={columns} data={transactions} defaultHiddenColumns={defaultHiddenColumns} />
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/transactions${location.search}`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id && type === "minitransaction" ? (
                    <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? miniTransactionType : null} />
                ) : null}
                {id && type == "brokertransaction" ? (
                    <BrokerTransactionForm id={id} startDate={filter ? (filter.qStartDate as string) : startDate} />
                ) : null}
                {id && type == "corporateaction" ? (
                    <CorporateActionForm id={id} tradeDate={filter ? (filter.qStartDate as string) : startDate} />
                ) : null}
            </Panel>
        </div>
    );
};

export function TransactionsPage(): React.ReactElement {
    return (
        <div>
            <div className="d-flex mb-2">
                <div className="ms-auto">
                    <NewMiniTransactionButton page="transactions" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Transactions />
                </div>
            </div>
        </div>
    );
}
