import React from "react";
import { Row } from "@tanstack/react-table";
import { dayDiff, dateToIsoString, calculateReturnFromSeries, yearFracActual365, percentageFormat } from "./functions";
import { BenchmarkData } from "./interfaces";

export const accountReturn = (
    row: Row<any>,
    dateSeries: string[],
    startDate: Date,
    endDate: Date,
    accountSeriesById: Record<string, number[]>,
    annualized = false
) => {
    const diff = Math.abs(dayDiff(startDate, endDate));
    const endDateString = dateToIsoString(endDate);
    let endDateIndex = dateSeries.findIndex((d) => d === endDateString);
    // If not found start from end
    if (endDateIndex === -1) endDateIndex = dateSeries.length - 1;
    if (endDateIndex - diff < 0) return null;
    // Not Applicable for cash accounts?
    if (!row.getIsGrouped() && row.original.isPartyInstrument) return <div style={{ textAlign: "right" }}>{"N.A."}</div>;

    let calculatedReturn: number = null;
    const series: number[] = row.getIsGrouped() ? accountSeriesById[row.groupingValue as string] : row.original.series;
    calculatedReturn = calculateReturnFromSeries(series, diff, endDateIndex);
    // Calculate Calculate Compound Annual Growth Rate (CAGR)
    if (annualized) {
        const yearFrac = yearFracActual365(startDate, endDate);
        // Only calculate if over one year
        if (yearFrac > 1) calculatedReturn = Math.pow(1 + calculatedReturn, 1 / yearFrac) - 1;
    }
    return calculatedReturn === null ? null : <div style={{ textAlign: "right" }}>{percentageFormat(calculatedReturn)}</div>;
};
export const benchmarkReturn = (
    row: Row<any>,
    dateSeries: string[],
    startDate: Date,
    endDate: Date,
    benchmarksById: Record<string, BenchmarkData>,
    accountPositionsById: Record<string, number[]>,
    modelSeriesById: Record<string, number[]>,
    useModelSeriesAsMain = true,
    annualized = false
) => {
    const diff = Math.abs(dayDiff(startDate, endDate));
    const endDateString = dateToIsoString(endDate);
    let endDateIndex = dateSeries.findIndex((d) => d === endDateString);
    // If not found start from end
    if (endDateIndex === -1) endDateIndex = dateSeries.length - 1;

    if (endDateIndex - diff < 0) return null;
    if (!row.getIsGrouped() && row.original.isPartyInstrument) return <div style={{ textAlign: "right" }}>{"N.A."}</div>;
    const groupingValue: any = row.groupingValue ? row.groupingValue : null;

    // Only return on index when position is on
    let totalReturn = 1;
    // for aggregated, If any leaf has position node has position
    const hasPosition = row.getIsGrouped() && groupingValue ? accountPositionsById[groupingValue] : row.original.positionFlag;

    // If use model as main index try model series first
    if (useModelSeriesAsMain) {
        const modelSeries = row.getIsGrouped() && groupingValue ? modelSeriesById[groupingValue] : [];
        if (modelSeries && modelSeries.length) {
            // Assert no nan in series
            let hasNaN = false;
            for (let i = endDateIndex - diff; i < modelSeries.length; i++) {
                if (isNaN(modelSeries[i])) {
                    hasNaN = true;
                    break;
                }
            }
            // We cannot have nan if we want to calculate returns
            if (!hasNaN) {
                // No return on first day by construction
                for (let i = endDateIndex - diff + 1; i < endDateIndex + 1; i++) {
                    // Create trading index, i.e. only get return  when position is on. Since we divide by previous value this cannot be 0!
                    if (modelSeries[i - 1] && hasPosition[i]) {
                        totalReturn *= modelSeries[i] / modelSeries[i - 1];
                    }
                }
                // Calculate Calculate Compound Annual Growth Rate (CAGR)
                if (annualized) {
                    const yearFrac = yearFracActual365(startDate, endDate);
                    // Only calculate if over one year
                    if (yearFrac > 1) totalReturn = Math.pow(totalReturn, 1 / yearFrac);
                }
                return <div style={{ textAlign: "right" }}>{percentageFormat(totalReturn - 1)}</div>;
            }
        }
    }
    // Fallback to main benchmark if not useModelSeriesAsMain or modelSeries fail
    const timeseries =
        row.getIsGrouped() && groupingValue
            ? benchmarksById[groupingValue]
            : benchmarksById[row.original.instrumentId]
              ? benchmarksById[row.original.instrumentId]
              : benchmarksById[row.original.accountId];
    if (!timeseries) return null;
    if (dateToIsoString(timeseries.series.__dates[0]) > dateToIsoString(startDate)) return null;

    // No return on first day by construction
    for (let i = endDateIndex - diff + 1; i < endDateIndex + 1; i++) {
        // Create trading index, i.e. only get return  when position is on. Since we divide by previous value this cannot be 0!
        if (timeseries.series.__values[i - 1] && hasPosition[i]) {
            totalReturn *= timeseries.series.__values[i] / timeseries.series.__values[i - 1];
        }
    }
    // Calculate Calculate Compound Annual Growth Rate (CAGR)
    if (annualized) {
        const yearFrac = yearFracActual365(startDate, endDate);
        // Only calculate if over one year
        if (yearFrac > 1) totalReturn = Math.pow(totalReturn, 1 / yearFrac);
    }
    return <div style={{ textAlign: "right" }}>{percentageFormat(totalReturn - 1)}</div>;
};
export const diffReturn = (
    row: Row<any>,
    dateSeries: string[],
    startDate: Date,
    endDate: Date,
    benchmarksById: Record<string, BenchmarkData>,
    accountSeriesById: Record<string, number[]>,
    accountPositionsById: Record<string, number[]>,
    modelSeriesById: Record<string, number[]>,
    useModelSeriesAsMain = true,
    annualized = false
) => {
    const diff = Math.abs(dayDiff(startDate, endDate));
    const endDateString = dateToIsoString(endDate);
    let endDateIndex = dateSeries.findIndex((d) => d === endDateString);
    // If not found start from end
    if (endDateIndex === -1) endDateIndex = dateSeries.length - 1;

    if (endDateIndex - diff < 0) return null;

    if (!row.getIsGrouped() && row.original.isPartyInstrument) return <div style={{ textAlign: "right" }}>{"N.A."}</div>;
    const groupingValue: any = row.groupingValue ? row.groupingValue : null;

    // Calculate account return
    let calculatedReturn: number = null;
    const series: number[] = row.getIsGrouped() ? accountSeriesById[row.groupingValue as string] : row.original.series;
    calculatedReturn = calculateReturnFromSeries(series, diff, endDateIndex);

    // Calculate Calculate Compound Annual Growth Rate (CAGR)
    if (annualized) {
        const yearFrac = yearFracActual365(startDate, endDate);
        // Only calculate if over one year
        if (yearFrac > 1) calculatedReturn = Math.pow(1 + calculatedReturn, 1 / yearFrac) - 1;
    }

    // for aggregated, If any leaf has position node has position
    let totalReturn = 1;
    // Only return on index when position is on
    const hasPosition = row.getIsGrouped() && groupingValue ? accountPositionsById[groupingValue] : row.original.positionFlag;

    // If use model as main index try model series first
    if (useModelSeriesAsMain) {
        const modelSeries = row.getIsGrouped() && groupingValue ? modelSeriesById[groupingValue] : [];
        if (modelSeries && modelSeries.length) {
            // Assert no nan in series
            let hasNaN = false;
            for (let i = endDateIndex - diff; i < modelSeries.length; i++) {
                if (isNaN(modelSeries[i])) {
                    hasNaN = true;
                    break;
                }
            }
            // We cannot have nan if we want to calculate returns
            if (!hasNaN) {
                // No return on first day by construction
                for (let i = endDateIndex - diff + 1; i < endDateIndex + 1; i++) {
                    // Create trading index, i.e. only get return  when position is on. Since we divide by previous value this cannot be 0!
                    if (modelSeries[i - 1] && hasPosition[i]) {
                        totalReturn *= modelSeries[i] / modelSeries[i - 1];
                    }
                }
                // Calculate Calculate Compound Annual Growth Rate (CAGR)
                if (annualized) {
                    const yearFrac = yearFracActual365(startDate, endDate);
                    // Only calculate if over one year
                    if (yearFrac > 1) totalReturn = Math.pow(totalReturn, 1 / yearFrac);
                }
                const benchmarkReturn = totalReturn - 1;
                return calculatedReturn === null ? null : (
                    <div style={{ textAlign: "right" }}>{percentageFormat(calculatedReturn - benchmarkReturn)}</div>
                );
            }
        }
    }

    const timeseries =
        row.getIsGrouped() && groupingValue
            ? benchmarksById[groupingValue]
            : benchmarksById[row.original.instrumentId]
              ? benchmarksById[row.original.instrumentId]
              : benchmarksById[row.original.accountId];
    if (!timeseries) return null;
    if (dateToIsoString(timeseries.series.__dates[0]) > dateToIsoString(startDate)) return null;

    totalReturn = 1;
    // No return on first day by construction
    for (let i = endDateIndex - diff + 1; i < endDateIndex + 1; i++) {
        // Create trading index, i.e. only get return  when position is on. Since we divide by previous value this cannot be 0!
        if (timeseries.series.__values[i - 1] && hasPosition[i]) {
            totalReturn *= timeseries.series.__values[i] / timeseries.series.__values[i - 1];
        }
    }
    // Calculate Calculate Compound Annual Growth Rate (CAGR)
    if (annualized) {
        const yearFrac = yearFracActual365(startDate, endDate);
        // Only calculate if over one year
        if (yearFrac > 1) totalReturn = Math.pow(totalReturn, 1 / yearFrac);
    }
    const benchmarkReturn = totalReturn - 1;

    return calculatedReturn === null ? null : (
        <div style={{ textAlign: "right" }}>{percentageFormat(calculatedReturn - benchmarkReturn)}</div>
    );
};
