import { keyBy, groupBy } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { addDays } from "./PortfolioLedger";
import {
    Instrument,
    PriceType,
    Valuation,
    ValuationPrice,
    ValuationRecord,
    TransactionItemType,
    TransactionType,
    Party,
    CurrencyEnum,
    Transaction,
    TransactionItem,
    TransactionItemPerformanceType
} from "../types.generated";

import { round } from "../timeseries";
import { RandomNumberGenerator, RandomTimeSeriesPriceType } from "../RandomNumberGenerator";
import { MondayToFridayCalendar, msPerDay, DateHelper } from "../calendar";
import { emptyObjectId, tradeTimeZone } from "../types";

dayjs.extend(utc);
dayjs.extend(timezone);

const { timestampToString } = DateHelper;

export function createValution(instrumentId: string, date: string, currency: string, price: number, rng: RandomNumberGenerator): Valuation {
    const p: ValuationPrice = { __typename: "ValuationPrice", type: PriceType.Price, value: price, currency };
    const r: ValuationRecord = { __typename: "ValuationRecord", prices: [p] };
    const v: Valuation = { __typename: "Valuation", _id: rng.randomObjectId(), instrumentId, date, records: [r] };
    return v;
}

const { max, min, sin, PI, floor, sqrt } = Math;

export class RandomPosition {
    constructor(size: number, seed: string) {
        this.rng = new RandomNumberGenerator(seed);
        this.phase = this.rng.rand();
        this.current = 0;
        this.size = size;
    }

    rng: RandomNumberGenerator;
    phase: number;
    current: number;
    size: number;

    next(y: number): number {
        const c = this.current;
        const t = this.target(y);
        let q = max(min(t - c, 0.04), -0.04) + 0.02 * this.rng.rand() - 0.01;
        if (t === 0) {
            if (this.current === 0) {
                q = 0;
            } else {
                q = max(q, -this.current);
            }
        }
        this.current += q;
        return this.size * q;
    }

    target(y: number): number {
        return max((0.5 + sin(4 * PI * (y + this.phase))) / 1.5, 0);
    }
}

export function toTimeString(t, openTradingHour, closeTradingHour): string {
    let x = openTradingHour + (closeTradingHour - openTradingHour) * t;
    const h = floor(x);
    x = 60 * (x - h);
    const m = floor(x);
    x = 60 * (x - m);
    const s = floor(x);
    x = 1000 * (x - s);
    const ms = Math.floor(x);
    const pad = (x, n) => x.toFixed().padStart(n, "0");
    return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}.${pad(ms, 3)}`;
}

export function intradayPrice(start: number, end: number, t: number, volatility: number, seed: string): number {
    const rng = new RandomNumberGenerator(seed);
    const vol = volatility / sqrt(252);
    let res = start * (1 - t) + end * t;
    res += start * vol * (2 * rng.rand() - 1) * sin(t * PI);
    res += start * vol * (1 * rng.rand() - 0.5) * sin(t * 2 * PI);
    res += start * vol * (0.5 * rng.rand() - 0.25) * sin(t * 3 * PI);
    return res;
}

function createTransactionItem(
    type: TransactionItemType,
    currency: CurrencyEnum,
    amount: number,
    quantity: number,
    price: number,
    instrumentId: string,
    valueDate: string,
    transaction: Transaction,
    fxRate: number,
    externalAccountId: string,
    accountId: string,
    _id: string
): TransactionItem {
    return {
        __typename: "TransactionItem",
        _id,
        transactionId: transaction ? transaction._id : null,
        clientId: transaction ? transaction.clientId : null,
        transactionStatus: transaction ? transaction.status : null,
        transactionTradeDate: transaction ? transaction.tradeDate : null,
        transactionType: transaction ? transaction.type : null,
        portfolioInstrumentId: emptyObjectId,
        performanceType: TransactionItemPerformanceType.Normal,
        type,
        currency,
        amount,
        quantity,
        price,
        instrumentId,
        valueDate,
        transaction,
        fxRate,
        externalAccountId,
        accountId
    };
}

export function createStockTrade(
    securityId: string,
    bankAccountId: string,
    tradeDate: string,
    tradeTimestamp: Date,
    currency: CurrencyEnum,
    quantity: number,
    price: number,
    commission: number,
    rng: RandomNumberGenerator
): Transaction {
    const settlementDays = 3;
    const valueDate = addDays(tradeDate, settlementDays);
    const amount = quantity * price + commission;
    const ti1 = createTransactionItem(
        TransactionItemType.Security,
        currency,
        quantity * price,
        quantity,
        price,
        securityId,
        valueDate,
        null,
        1,
        null,
        null,
        rng.randomObjectId()
    );
    const ti2 = createTransactionItem(
        TransactionItemType.Commission,
        currency,
        commission,
        null,
        null,
        securityId,
        valueDate,
        null,
        1,
        null,
        null,
        rng.randomObjectId()
    );
    const ti3 = createTransactionItem(
        TransactionItemType.SettlementAmount,
        currency,
        -amount,
        null,
        null,
        bankAccountId,
        valueDate,
        null,
        1,
        null,
        null,
        rng.randomObjectId()
    );
    const trans = {
        _id: rng.randomObjectId(),
        type: TransactionType.StockTrade,
        tradeDate,
        tradeTimestamp: tradeTimestamp,
        items: [ti1, ti2, ti3].filter((d) => d.amount !== 0)
    } as Transaction;
    trans.items.forEach((d) => (d.transaction = trans));
    // const res = <TransactionItem>{};
    return trans;
}

interface TestDatabaseOptions {
    startDate: string;
    endDate: string;
    baseCurrency: string;
    currencies: string[];
    tradeIntensity: number;
    numberOfStocks: number;
    seed: string;
}

const swedenDateSerialize = (isoDateTimeValue: string): string => dayjs.tz(isoDateTimeValue, tradeTimeZone).format("YYYY-MM-DD");

export function createTestDatabase({
    startDate,
    endDate,
    baseCurrency,
    currencies,
    tradeIntensity,
    numberOfStocks,
    seed
}: TestDatabaseOptions) {
    const rng = new RandomNumberGenerator(seed);
    const transactions = [];
    const valuations = [];

    const securities = rng.randomLongNames(numberOfStocks, 3, 4).map(
        (d) =>
            ({
                _id: rng.randomObjectId(),
                name: RandomNumberGenerator.longNameToName(d),
                longName: d,
                currency: rng.randomItem(currencies)
            }) as Instrument
    );
    // console.log(securities);
    // console.log(groupBy(securities, (d) => d.currency));
    const bankAccounts = Object.keys(groupBy(securities, (d) => d.currency)).map(
        (d) =>
            ({
                _id: rng.randomObjectId(),
                name: `BANKACCOUNT ${d}`,
                longName: `Bank Account ${d}`,
                currency: d
            }) as Instrument
    );
    const bankAccountsByCurrency = keyBy(bankAccounts, (d) => d.currency);
    const party: Party = {
        __typename: "Party",
        _id: rng.randomObjectId(),
        instruments: bankAccounts,
        fundInfo: null,
        name: null
    } as unknown as Party;

    const fxPairs = Object.keys(bankAccountsByCurrency).map((d) =>
        d === baseCurrency
            ? null
            : ({
                  _id: rng.randomObjectId(),
                  name: d + baseCurrency,
                  longName: d + baseCurrency,
                  currency: d
              } as Instrument)
    );
    const instruments = [...securities, ...fxPairs.filter((d) => d !== null)];
    const securityPrices = securities.map(() =>
        rng.randomTimeSeries(new Date(startDate), new Date(endDate), RandomTimeSeriesPriceType.Stock)
    );
    const fxRates = Object.keys(bankAccountsByCurrency).map((d) =>
        d === baseCurrency ? null : rng.randomTimeSeries(new Date(startDate), new Date(endDate), RandomTimeSeriesPriceType.Fx)
    );

    const cal = new MondayToFridayCalendar();
    const openTradingHour = 9;
    const closeTradingHour = 18;

    for (let i = 0; i < securities.length; i++) {
        const ts = securityPrices[i];
        const sec = securities[i];
        const fxi = Object.keys(bankAccountsByCurrency).findIndex((d) => d === sec.currency);
        const fxts = fxRates[fxi];
        const fxStartValue = fxts === null ? 1 : fxts.startValue;
        let date = startDate;
        let t = 0;
        let n = 0;
        const vol = ts.volatility();
        const rp = new RandomPosition(1e6 / fxStartValue / ts.startValue, sec.name);
        while (true) {
            t += rng.randX() / tradeIntensity;
            while (t > 1) {
                date = addDays(date, 1);
                while (!cal.isBusinessDay(date)) {
                    date = addDays(date, 1);
                }
                t -= 1;
                n++;
            }
            if (date > endDate) {
                break;
            }
            const tradeTimestamp = dayjs.tz(date + " " + toTimeString(t, openTradingHour, closeTradingHour), tradeTimeZone).toDate();
            const idx = ts.indexOf(new Date(date));
            const s0 = ts.__values[idx];
            const s1 = idx + 1 < ts.length ? ts.__values[idx + 1] : s0;
            const price = intradayPrice(s0, s1, t, vol, sec.name + "-" + n);
            const d0 = new Date(date);
            const y0 = new Date(d0.getUTCFullYear(), 0, 0);
            const yfrac = (d0.getTime() - y0.getTime()) / msPerDay / 365;
            const q = rp.next(yfrac);
            if (q !== 0) {
                const trade = createStockTrade(
                    sec._id,
                    bankAccountsByCurrency[sec.currency]._id,
                    swedenDateSerialize(tradeTimestamp.toISOString()),
                    tradeTimestamp,
                    sec.currency,
                    round(q, 0),
                    round(price, 2),
                    rng.rand() < 0.1 ? 0 : 10 * (1 + rng.randomInt(5)),
                    rng
                );
                // console.log(date, t, tradeTimestamp);
                transactions.push(trade);
            }
        }
        for (let i = 0; i < ts.length; i++) {
            const d = ts.__dates[i];
            const v = ts.__values[i + 1 < ts.length ? i + 1 : i];
            const val = createValution(sec._id, timestampToString(d), sec.currency, round(v, 2), rng);
            valuations.push(val);
        }
    }
    for (let i = 0; i < fxRates.length; i++) {
        const ts = fxRates[i];
        const instr = fxPairs[i];
        if (ts === null) {
            continue;
        }
        for (let j = 0; j < ts.length; j++) {
            const d = ts.__dates[j];
            const v = ts.__values[j + 1 < ts.length ? j + 1 : j];
            const val = createValution(instr._id, timestampToString(d), instr.currency, round(v, 2), rng);
            valuations.push(val);
        }
    }
    return { party, instruments, transactions, valuations };
}
