import { write, utils } from "xlsx";
import { saveAs } from "file-saver";
import { keys, values, reduce } from "lodash";

const portfolioToAoa = (portfolio: any) =>
    reduce(
        portfolio,
        (acc, p) => {
            acc.push(values(p));
            return acc;
        },
        [keys(portfolio[0])]
    );

const s2ab = (s) => {
    if (typeof ArrayBuffer !== "undefined") {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    } else {
        const buf = new Array(s.length);
        for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }
};

export type sheetType = Array<Record<any, any>>;

export const exportToXlsx = (sheets: sheetType[], filename = "table.xlsx", sheetNames = ["Sheet_1"]): any => {
    //sheets: one array of dicts per sheet in sheets array
    const wb = utils.book_new();
    for (let c = 0; c < sheets.length; c++) {
        const sheetData: sheetType = sheets[c];
        const sheet = utils.aoa_to_sheet(portfolioToAoa(sheetData));
        let sheetName: string = sheetNames[c] ? sheetNames[c] : "Sheet " + (c + 1);
        if (sheetName.length > 31) {
            // New Excel sheet naming conventions prohibit the use of sheet names that exceed 31 characters or contain \/? * [ ] characters.
            sheetName = sheetName.replaceAll("/", "").replaceAll("\\", "");
            sheetName = sheetName.replaceAll("?", "").replaceAll("*", "");
            sheetName = sheetName.replaceAll("[", "").replaceAll("]", "");
            sheetName = sheetName.slice(0, 31);
        }
        utils.book_append_sheet(wb, sheet, sheetName);
    }

    const wbout = write(wb, {
        bookType: "xlsx",
        bookSST: false,
        type: "binary"
    });

    try {
        const blob = new Blob([s2ab(wbout) as BlobPart], { type: "application/octet-stream" });
        saveAs(blob, filename);
    } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
    }
    return wbout;
};
