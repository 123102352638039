/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPartiesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PartyFilterInput>;
}>;


export type GetPartiesQuery = { __typename?: 'Query', parties?: Array<{ __typename?: 'Party', _id: string, types: Array<Types.PartyType | null>, name: string, legalEntityIdentifier?: string | null, legalNumber?: string | null, updateTimestamp?: any | null } | null> | null };


export const GetPartiesDocument = gql`
    query getParties($filter: PartyFilterInput) {
  parties(filter: $filter) {
    _id
    types
    name
    legalEntityIdentifier
    legalNumber
    updateTimestamp
  }
}
    `;

export function useGetPartiesQuery(options?: Omit<Urql.UseQueryArgs<GetPartiesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPartiesQuery, GetPartiesQueryVariables>({ query: GetPartiesDocument, ...options });
};