import { groupBy, keyBy } from "lodash";

import { Instrument, PriceType, ValuationPrice, Valuation } from "../types.generated";

const { pow, random } = Math;

export class Valuations {
    // constructor() {}
    static fromArray(vals: Valuation[], instruments: Instrument[]): Valuations {
        const res = new Valuations();
        // vals.forEach((v) => (v.record = v.records[v.records.length - 1]));
        res.byInstruments = groupBy(vals, "instrumentId");
        res.instrumentsByName = keyBy(instruments, "name");
        res.instrumentsById = keyBy(instruments, "_id");
        const ids = Object.keys(res.byInstruments);
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const vals = res.byInstruments[id];
            vals.sort((d1, d2) => (d1.date < d2.date ? 1 : -1));
        }
        return res;
    }

    instrumentsByName: { [name: string]: Instrument };
    instrumentsById: { [id: string]: Instrument };
    byInstruments: { [id: string]: Valuation[] };

    findInstrumentByName(name: string): Instrument {
        const res = this.instrumentsByName[name];
        if (res) {
            return res;
        }
        return null;
    }
    getFxValue(fxPairName: string, date: string): number {
        const instr = this.findInstrumentByName(fxPairName);
        if (!instr) {
            return null;
        }
        const ps = this.getPrice(instr._id, date);
        if (!ps) {
            return null;
        }
        return Valuations.priceByTypes(ps, [PriceType.Price]);
    }
    getPrice(instrumentId: string, date: string, currency: string = null): { [type: string]: ValuationPrice } {
        const vals = this.byInstruments[instrumentId];
        if (typeof vals === "undefined") {
            return null;
        }
        for (let i = 0; i < vals.length; i++) {
            const v = vals[i];
            if (v.date > date) {
                continue;
            }
            if (v.records.length === 0) {
                continue;
            }
            const r = v.records[v.records.length - 1];
            if (currency && !r.prices.every((p) => p.currency === currency)) {
                continue;
            }
            const prices = keyBy(r.prices, "type");
            if (prices[PriceType.Price] || (prices[PriceType.AccruedInterest] && prices[PriceType.CleanPrice])) {
                return prices;
            }
        }
        return null;
    }
    static priceByTypes(prices: { [type: string]: ValuationPrice }, types: PriceType[]): number {
        let res = 0;
        if (prices === null) {
            return res;
        }
        for (let i = 0; i < types.length; i++) {
            const p = prices[types[i]];
            res += p ? p.value : 0.0;
        }
        return res;
    }
}

export class MockValuations extends Valuations {
    instrumentsByName: { [name: string]: Instrument } = {};
    instrumentsById: { [id: string]: Instrument } = {};
    byInstruments: { [id: string]: Valuation[] } = {};

    constructor() {
        super();
        this.byInstruments = {};
        this.instrumentsByName = {};
        this.instrumentsById = {};
        this.askedFxPairNames = {};
        this.askedInstrumentIds = {};
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static fromArray(_vals: Valuation[] = null, _instruments: Instrument[] = null): MockValuations {
        const res = new MockValuations();
        return res;
    }

    askedFxPairNames: { [name: string]: boolean };
    askedInstrumentIds: { [id: string]: boolean };

    static randomPrice(): number {
        return pow(10, random());
    }

    findInstrumentByName(name: string): Instrument {
        return { _id: "000000000000000000000000", name } as Instrument;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getFxValue(fxPairName: string, _date: string): number {
        this.askedFxPairNames[fxPairName] = true;
        return MockValuations.randomPrice();
    }

    getPrice(instrumentId: string, date: string, currency: string | null = null): { [type: string]: ValuationPrice } {
        this.askedInstrumentIds[instrumentId] = true;
        if (!currency) {
            currency = "SEK";
        }
        if (random() < 0.5) {
            return { Price: { __typename: "ValuationPrice", type: PriceType.Price, value: MockValuations.randomPrice(), currency } };
        }
        return {
            CleanPrice: { __typename: "ValuationPrice", type: PriceType.CleanPrice, value: MockValuations.randomPrice(), currency },
            AccruedInterest: {
                __typename: "ValuationPrice",
                type: PriceType.AccruedInterest,
                value: MockValuations.randomPrice(),
                currency
            }
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static priceByTypes(_prices: { [type: string]: ValuationPrice }, _types: PriceType[]): number {
        return MockValuations.randomPrice();
    }
}
