import React, { Fragment, useState, useEffect } from "react";
import { sortBy, keyBy, cloneDeep } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import { Alert } from "react-bootstrap";
import stableStringify from "json-stable-stringify";
import { gql, useQuery, useMutation } from "urql";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { emptyObjectId } from "../../../common/src";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";

import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { YesNoModal } from "../components/YesNoModal";
import { useAlertTimeOut, usePrevious } from "./../common/Utils";
import {
    Transaction,
    SourceType,
    BestExecutionType,
    TransactionStatus,
    TransactionType,
    TransactionItem,
    CreateTransactionItemInput,
    TransactionItemType,
    CurrencyEnum,
    TransactionItemPerformanceType
} from "../types.generated";
import {
    SelectField,
    TextField,
    NumberField,
    DateField,
    SubmitButton,
    SearchListField,
    NullableDateField,
    TimestampField
} from "../components/form";
import { AttachmentTransactionForm } from "./AttachmentTransactionForm";

const today = new Date().toISOString().slice(0, 10);

export const defaultTransactionItem: CreateTransactionItemInput = {
    instrumentId: emptyObjectId,
    type: TransactionItemType.Fee,
    amount: 0.0,
    currency: CurrencyEnum.SEK,
    valueDate: today,
    quantity: 0.0,
    price: 0.0,
    fxRate: 1.0,
    externalAccountId: emptyObjectId,
    accountId: emptyObjectId
};
interface FormData {
    _id?: string;
    typename?: string;
    number?: number;
    carryOwnCostDate?: string;
    clientId?: string;
    brokerId?: string;
    clearingHouseId?: string;
    externalId?: string;
    source?: SourceType;
    parentId?: string;
    bestExecutionType?: BestExecutionType;
    brokerTradeId?: string;
    description?: string;
    createTimestamp?: string;
    error?: string;
    status?: TransactionStatus;
    tradeTimestamp?: string;
    tradeDate?: string;
    type?: TransactionType;
    uniqueTradeId?: string;
    updateTimestamp?: string;
    items?: TransactionItem[];
    updateUserInfo?: any;
}

export const GET_TRANSACTION = gql`
    query transaction($_id: GraphQLObjectId!, $includeVersions: Boolean = false) {
        transaction(_id: $_id) {
            _id
            number
            clientId
            brokerId
            carryOwnCostDate
            externalId
            uniqueTradeId
            brokerTradeId
            clearingHouseId
            tradeTimestamp
            tradeDate
            type
            description
            status
            bestExecutionType
            error
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            source
            attachments {
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            items {
                _id
                clientId
                transactionId
                transactionStatus
                transactionTradeDate
                transactionType
                performanceType
                portfolioInstrumentId
                amount
                currency
                externalAccountId
                fxRate
                instrumentId
                price
                quantity
                type
                valueDate
                accountId
                bankAccountTransactionIds
                error
            }
            versions @include(if: $includeVersions)
        }
    }
`;

const GET_DATA = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            name
            _id
            instruments {
                _id
                name
                longName
                currency
            }
            accounts {
                _id
                name
                description
            }
            externalAccounts {
                _id
                name
                description
            }
        }

        brokers: parties(filter: { typeIn: [Broker] }) {
            _id
            name
            legalEntityIdentifier
        }

        clearinghouses: parties(filter: { typeIn: [ClearingHouse] }) {
            _id
            name
            legalEntityIdentifier
        }

        instruments(filter: { typeIn: [Instrument] }) {
            _id
            name
            longName
            currency
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        transactions: updateTransactions(input: $input) {
            _id
            number
            clientId
            brokerId
            clearingHouseId
            carryOwnCostDate
            externalId
            uniqueTradeId
            brokerTradeId
            tradeTimestamp
            type
            description
            status
            bestExecutionType
            error
            createTimestamp
            updateTimestamp
            updateUserId
            source
            client {
                externalAccounts {
                    _id
                    type
                    name
                }
            }
            attachments {
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            items {
                _id
                transactionId
                performanceType
                portfolioInstrumentId
                amount
                currency
                externalAccountId
                fxRate
                instrumentId
                price
                quantity
                type
                valueDate
                accountId
            }
        }
    }
`;

const CREATE_TRANSACTION = gql`
    mutation createTransaction($input: CreateTransactionInput!) {
        transaction: createTransaction(input: $input) {
            _id
            number
            clientId
            brokerId
            carryOwnCostDate
            externalId
            uniqueTradeId
            brokerTradeId
            tradeTimestamp
            type
            description
            status
            clearingHouseId
            bestExecutionType
            error
            createTimestamp
            updateTimestamp
            updateUserId
            source
            attachments {
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            items {
                _id
                transactionId
                performanceType
                portfolioInstrumentId
                amount
                currency
                externalAccountId
                fxRate
                instrumentId
                price
                quantity
                type
                valueDate
                accountId
            }
        }
    }
`;

export const TransactionPage = (): React.ReactElement => {
    const { id }: any = useParams();
    const previousId = usePrevious(id);
    const navigate = useNavigate();

    let transactionId = "000000000000000000000000";
    if (id && id !== "new") {
        transactionId = id;
    }

    const [{ fetching: transactionLoading, error: transactionError, data: transactionData }, refetch] = useQuery({
        query: GET_TRANSACTION,
        variables: { _id: transactionId },
        requestPolicy: "network-only"
    });

    const [{ fetching: loading, error: getDataError, data }] = useQuery({ query: GET_DATA });

    const [formData, setFormData] = useState(null);

    const [_, createMutation] = useMutation(CREATE_TRANSACTION);
    const [__, updateMutation] = useMutation(UPDATE_TRANSACTIONS);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (previousId !== id) {
            setFormData(null);
        } else if (transactionData) {
            if (formData === null) {
                if (id === "new") {
                    // default
                    const today = new Date().toISOString(); //
                    const initFormData: FormData = {
                        _id: emptyObjectId,
                        bestExecutionType: BestExecutionType.Portfolio,
                        brokerId: null,
                        brokerTradeId: "",
                        carryOwnCostDate: null,
                        clientId: "000000000000000000000000",
                        clearingHouseId: "000000000000000000000000",
                        createTimestamp: today,
                        description: "",
                        error: "",
                        externalId: "",
                        items: [],
                        number: 0,
                        source: SourceType.Internal,
                        status: TransactionStatus.Pending,
                        tradeTimestamp: today,
                        type: TransactionType.BondTrade,
                        updateTimestamp: today,
                        uniqueTradeId: ""
                    };
                    setFormData(initFormData);
                } else if (transactionData.transaction) {
                    const transaction: Transaction = cloneDeep(transactionData.transaction);
                    const formData: FormData = {
                        _id: transaction._id,
                        bestExecutionType: transaction.bestExecutionType,
                        brokerId: transaction.brokerId,
                        clearingHouseId: transaction.clearingHouseId || "000000000000000000000000",
                        brokerTradeId: transaction.brokerTradeId,
                        carryOwnCostDate: transaction.carryOwnCostDate,
                        clientId: transaction.clientId,
                        createTimestamp: transaction.createTimestamp,
                        description: transaction.description,
                        error: transaction.error,
                        externalId: transaction.externalId,
                        items: transaction.items as unknown as TransactionItem[],
                        number: transaction.number,
                        source: transaction.source,
                        status: transaction.status,
                        tradeTimestamp: transaction.tradeTimestamp,
                        type: transaction.type,
                        updateTimestamp: transaction.updateTimestamp,
                        updateUserInfo: transaction.updateUserInfo,
                        uniqueTradeId: transaction.uniqueTradeId || ""
                    };
                    setFormData(formData);
                }
            }
        }
    }, [previousId, id, transactionData, formData, transactionId]);

    if (loading || transactionLoading) return <div>Loading</div>;

    if (transactionError) return <div>{"Error: " + transactionError.message}</div>;
    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (!formData) return <div>Transaction with id '{id}' does not exist!</div>;

    const isCreateMode = id === "new";

    const clients = sortBy(data.clients, "name");
    const brokers = sortBy(data.brokers, "name");
    const clearinghouses = sortBy(data.clearinghouses, "name");
    const instruments = cloneDeep(data.instruments);
    brokers.unshift({ _id: "000000000000000000000000", name: "None" });
    clearinghouses.unshift({ _id: "000000000000000000000000", name: "None" });
    let accounts;
    let externalAccounts;
    let partyInstruments;
    let partyInstrumentsById: Record<any, any> = {};

    const clientsById = keyBy(clients, "_id");
    const client = clientsById[formData.clientId];
    const accountNone = { description: "None", name: "None", __typename: "PartyAccount", _id: "000000000000000000000000" };
    const externalAccountNone = { description: "None", name: "None", __typename: "PartyAccount", _id: "000000000000000000000000" };

    if (!client) {
        accounts = [accountNone];
        externalAccounts = [externalAccountNone];
    } else {
        accounts = cloneDeep(client.accounts);
        externalAccounts = cloneDeep(client.externalAccounts);
        partyInstruments = cloneDeep(client.instruments);
        partyInstrumentsById = keyBy(partyInstruments, "_id");
        instruments.push(...partyInstruments);
        accounts.push(accountNone);
        externalAccounts.push(externalAccountNone);
    }

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete transaction with id " + modal.payload.input._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let mutationData: any;
                        updateMutation({ input: [modal.payload.input] })
                            .then((result) => {
                                mutationData = result.data.transactions[0];
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: `The transaction '${mutationData._id}' was updated and deleted successfully!`
                                });
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                if (mutationData && mutationData._id) {
                                    setFormData(mutationData);
                                }
                            });
                    }}
                />
            ) : null}

            <div className="container page">
                <h1>Transaction</h1>
                <div className="row">
                    <Formik
                        enableReinitialize={true}
                        validateOnMount={true}
                        initialValues={formData}
                        validate={(validateFormData) => {
                            const errors: any = {};
                            if (validateFormData.clientId === "000000000000000000000000") {
                                errors.clientId = "No client selected";
                            }
                            if (validateFormData.externalId === "" || !validateFormData.externalId) {
                                errors.externalId = "ExternalId is required";
                            }
                            if (validateFormData.items.length === 0) {
                                errors.items = "Transaction needs to have at least one Transaction Item";
                            }
                            if (validateFormData.tradeTimestamp.length === 0 || !validateFormData.tradeTimestamp) {
                                errors.tradeTimeStamp = "Trade timstamp is required";
                            }
                            if (validateFormData.type === "" || !validateFormData.type) {
                                errors.type = "Transaction Type is required";
                            }
                            if (validateFormData.bestExecutionType === "" || !validateFormData.bestExecutionType) {
                                errors.bestExecutionType = "Best Execution Type is required";
                            }
                            if (validateFormData.status === "" || !validateFormData.status) {
                                errors.bestExecutionType = "Transaction status is required";
                            }

                            setFormData(validateFormData);
                            return Object.keys(errors).length > 0 ? errors : {};
                        }}
                        onSubmit={async (values, { setSubmitting, setErrors }) => {
                            const submitValues: FormData = values;

                            const validatedItems: TransactionItem[] = [];
                            if (submitValues.items && submitValues.items.length) {
                                for (const item of submitValues.items) {
                                    const validatedItem = cloneDeep(item);
                                    validatedItems.push(validatedItem);
                                }
                            }

                            let input: Transaction = {
                                _id: submitValues._id,
                                __typename: "Transaction",
                                bestExecutionType: submitValues.bestExecutionType,
                                brokerId: submitValues.brokerId,
                                clearingHouseId: submitValues.clearingHouseId,
                                brokerTradeId: submitValues.brokerTradeId,
                                carryOwnCostDate: submitValues.carryOwnCostDate,
                                clientId: submitValues.clientId,
                                createTimestamp: submitValues.createTimestamp,
                                description: submitValues.description,
                                error: submitValues.error,
                                externalId: submitValues.externalId,
                                items: validatedItems,
                                number: submitValues.number,
                                status: submitValues.status,
                                tradeTimestamp: submitValues.tradeTimestamp,
                                tradeDate: submitValues.tradeDate,
                                type: submitValues.type,
                                uniqueTradeId: submitValues.uniqueTradeId,
                                source: submitValues.source,
                                parentId: submitValues.parentId,
                                updateUserInfo: submitValues.updateUserInfo

                                //issuerId: submitValues.issuerId,
                            };

                            input = recursivelyRemoveKey(input, "__typename");
                            //input = removeKey(input, "_id");
                            input = recursivelyRemoveKey(input, "createTimestamp");
                            input = recursivelyRemoveKey(input, "updateTimestamp");
                            input = recursivelyRemoveKey(input, "source");
                            input = recursivelyRemoveKey(input, "updateUserInfo");

                            let mutationData: FormData = null;
                            //delete input.attachments;
                            //let mutationData: Transaction = null;

                            if (isCreateMode) {
                                input = recursivelyRemoveKey(input, "_id");
                                await createMutation({ input })
                                    .then((result) => {
                                        if ("error" in result && result.error) {
                                            const message = formikUrqlErrorFormater(result.error, setErrors);
                                            setAlert({ color: "danger", visible: true, message });
                                        } else {
                                            mutationData = result.data.transaction;
                                            setFormData(null);
                                        }
                                        return true;
                                    })
                                    .catch((error) => {
                                        setAlert({ color: "danger", visible: true, message: error.toString() });
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        navigate("/transaction/" + mutationData._id, { replace: true });
                                    });
                            } else {
                                if (input.status && input.status === "Deleted") {
                                    setModal({ showModal: true, payload: { input: input } });
                                } else {
                                    await updateMutation({ input: [input] })
                                        .then((result) => {
                                            if ("error" in result && result.error) {
                                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                                setAlert({ color: "danger", visible: true, message });
                                            } else {
                                                mutationData = result.data.transactions[0];
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `The transaction '${mutationData._id}' updated successfully!`
                                                });
                                            }
                                            return true;
                                        })
                                        .catch((error) => {
                                            setAlert({ color: "danger", visible: true, message: error.toString() });
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            refetch();
                                            if (mutationData && mutationData._id) {
                                                setFormData(mutationData);
                                            }
                                        });
                                }
                            }
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Fragment>
                                <div className="col-12">
                                    <Form autoComplete="off" className="form">
                                        {alert.visible ? (
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                        <div className="form-group form-row">
                                            <div className="col-sm-8">
                                                <div className="form-group form-row">
                                                    <TextField className="col-sm-4" name="_id" label="Id" disabled={true} />
                                                    <SelectField
                                                        name="bestExecutionType"
                                                        label="Best execution type*"
                                                        options={Object.values(BestExecutionType)}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="clientId"
                                                        label={<Link to={"/parties/" + values.clientId}>Client*</Link>}
                                                        options={clients.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <SelectField
                                                        name="type"
                                                        label="Type*"
                                                        options={Object.values(TransactionType)}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="status"
                                                        label="Status*"
                                                        options={Object.values(TransactionStatus)}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="source"
                                                        options={Object.keys(SourceType).map((item) => {
                                                            return { key: item, value: item };
                                                        })}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <NumberField
                                                        className="col-sm-4"
                                                        name="number"
                                                        label="Number"
                                                        disabled={isSubmitting}
                                                    />
                                                    <TimestampField
                                                        name="tradeTimestamp"
                                                        label="Trade timestamp*"
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="clearingHouseId"
                                                        label={<Link to={"/parties/" + values.clearingHouseId}>Clearing house</Link>}
                                                        options={clearinghouses.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <SelectField
                                                        name="brokerId"
                                                        label={<Link to={"/parties/" + values.brokerId}>Broker</Link>}
                                                        options={brokers.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-sm-4"
                                                        disabled={isSubmitting}
                                                    />
                                                    <TextField
                                                        className="col-sm-4"
                                                        name="brokerTradeId"
                                                        label="Broker trade id"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <TextField className="col-sm-12" name="error" label="Error" disabled={isSubmitting} />
                                                </div>
                                                <div className="form-group form-row">
                                                    <NullableDateField
                                                        name="carryOwnCostDate"
                                                        label="Carry own cost date"
                                                        className="col-sm-8"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <TextField
                                                        className="col-sm-4"
                                                        name="createTimestamp"
                                                        label="Create timestamp"
                                                        disabled={true}
                                                    />
                                                    <TextField
                                                        className="col-sm-4"
                                                        name="updateTimestamp"
                                                        label="Update timestamp"
                                                        disabled={true}
                                                    />
                                                    <TextField
                                                        className="col-sm-4"
                                                        name="updateUserInfo.name"
                                                        label="Updated by"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <TextField
                                                        className="col-sm-12"
                                                        name="externalId"
                                                        label="External id*"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <TextField
                                                        className="col-sm-12"
                                                        name="uniqueTradeId"
                                                        label="Unique trade id"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group form-row">
                                                    <TextField
                                                        className="col-sm-12"
                                                        name="description"
                                                        label="Description"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <h3>Transaction Items</h3>
                                                <div className="form-group form-row">
                                                    <FieldArray
                                                        name="items"
                                                        render={(arrayHelpers) => (
                                                            <Fragment>
                                                                {values.items && values.items.length > 0 ? (
                                                                    values.items.map((item, index) => (
                                                                        <div key={index}>
                                                                            <div className="col-sm-12">
                                                                                <TextField
                                                                                    className=""
                                                                                    name={`items[${index}]._id`}
                                                                                    label="Id"
                                                                                    disabled={true}
                                                                                />
                                                                                <TextField
                                                                                    className=""
                                                                                    name={`items[${index}].transactionId`}
                                                                                    label="Transaction id"
                                                                                    disabled={true}
                                                                                />
                                                                                <SelectField
                                                                                    className=""
                                                                                    name={`items[${index}].performanceType`}
                                                                                    label="Performance type"
                                                                                    options={Object.values(
                                                                                        TransactionItemPerformanceType
                                                                                    ).sort()}
                                                                                    disabled={true}
                                                                                />
                                                                                <SearchListField
                                                                                    className=""
                                                                                    name={`items[${index}].portfolioInstrumentId`}
                                                                                    label={
                                                                                        item.instrumentId &&
                                                                                        item.portfolioInstrumentId !== emptyObjectId ? (
                                                                                            <Link
                                                                                                to={
                                                                                                    "/instruments/" +
                                                                                                    item.portfolioInstrumentId
                                                                                                }
                                                                                            >
                                                                                                Portfolio instrument
                                                                                            </Link>
                                                                                        ) : (
                                                                                            "Portfolio instrument"
                                                                                        )
                                                                                    }
                                                                                    options={instruments.concat({
                                                                                        name: "None",
                                                                                        _id: emptyObjectId
                                                                                    })}
                                                                                    disabled={true}
                                                                                />
                                                                                <SearchListField
                                                                                    className=""
                                                                                    name={`items[${index}].instrumentId`}
                                                                                    label={
                                                                                        item.instrumentId in partyInstrumentsById ? (
                                                                                            <Link
                                                                                                to={
                                                                                                    "/parties/" +
                                                                                                    values.clientId +
                                                                                                    "/instruments/" +
                                                                                                    item.instrumentId
                                                                                                }
                                                                                            >
                                                                                                Party instrument
                                                                                            </Link>
                                                                                        ) : (
                                                                                            <Link to={"/instruments/" + item.instrumentId}>
                                                                                                Instrument
                                                                                            </Link>
                                                                                        )
                                                                                    }
                                                                                    options={instruments}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <SelectField
                                                                                    className=""
                                                                                    name={`items[${index}].type`}
                                                                                    label="Type"
                                                                                    options={Object.values(TransactionItemType).sort()}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <SelectField
                                                                                    className=""
                                                                                    name={`items[${index}].currency`}
                                                                                    label="Currency"
                                                                                    options={Object.values(CurrencyEnum).sort()}
                                                                                    disabled={isSubmitting}
                                                                                />

                                                                                <DateField
                                                                                    name={`items[${index}].valueDate`}
                                                                                    label="Value date"
                                                                                    className=""
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <NumberField
                                                                                    className=""
                                                                                    name={`items[${index}].amount`}
                                                                                    label="Amount"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <NumberField
                                                                                    className=""
                                                                                    name={`items[${index}].price`}
                                                                                    label="Price"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <NumberField
                                                                                    className=""
                                                                                    name={`items[${index}].quantity`}
                                                                                    label="Quantity"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <NumberField
                                                                                    className=""
                                                                                    name={`items[${index}].fxRate`}
                                                                                    label="Fx rate"
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <SelectField
                                                                                    className=""
                                                                                    name={`items[${index}].accountId`}
                                                                                    label={
                                                                                        <Link
                                                                                            to={
                                                                                                "/parties/" +
                                                                                                values.clientId +
                                                                                                "/accounts/" +
                                                                                                item.accountId
                                                                                            }
                                                                                        >
                                                                                            Account
                                                                                        </Link>
                                                                                    }
                                                                                    options={accounts.map((d) => ({
                                                                                        key: d._id,
                                                                                        value: d.name
                                                                                    }))}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                                <SelectField
                                                                                    className=""
                                                                                    name={`items[${index}].externalAccountId`}
                                                                                    label={
                                                                                        <Link
                                                                                            to={
                                                                                                "/parties/" +
                                                                                                values.clientId +
                                                                                                "/externalAccounts/" +
                                                                                                item.externalAccountId
                                                                                            }
                                                                                        >
                                                                                            External account
                                                                                        </Link>
                                                                                    }
                                                                                    options={externalAccounts.map((d) => ({
                                                                                        key: d._id,
                                                                                        value: d.name
                                                                                    }))}
                                                                                    disabled={isSubmitting}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <Button
                                                                                    className="me-1"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className=""
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.insert(
                                                                                            index,
                                                                                            cloneDeep(defaultTransactionItem)
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div className="col-sm-4">
                                                                        <Button
                                                                            className=""
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.push(cloneDeep(defaultTransactionItem))
                                                                            }
                                                                        >
                                                                            {/* show this when user has removed all items */}
                                                                            Add a transaction item
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <h2>Graphql format</h2>
                                                <pre>{stableStringify(transactionData.transaction, { space: "  " })}</pre>
                                            </div>
                                        </div>
                                        <SubmitButton disabled={isSubmitting} label={isCreateMode ? "Create" : "Update"} />
                                    </Form>
                                    {!isCreateMode ? (
                                        <div className="row mt-3">
                                            <AttachmentTransactionForm transactionId={transactionId} />
                                        </div>
                                    ) : null}
                                </div>
                            </Fragment>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
