import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import queryString from "query-string";

import { useQueryArgs, usePrevious } from "../common/Utils";
import { DateField } from "../components/form";
import { MondayToFridayCalendar, DateHelper } from "../../../common/src";

export function defaultDate(): Date {
    const cal = new MondayToFridayCalendar();
    let d = DateHelper.addDays(new Date(), -1);
    while (!cal.isBusinessDay(d)) {
        d = DateHelper.addDays(d, -1);
    }
    return d;
}

type DateFormPropsType = {
    defaultDateString: string;
    dateName?: string;
    className?: string;
    minDate?: string;
    label?: string;
};

export const DateForm = ({
    defaultDateString,
    dateName = "endDate",
    className = "",
    minDate,
    label = null
}: DateFormPropsType): React.ReactElement => {
    const defaultFormData: any = {};
    defaultFormData[dateName] = defaultDateString;
    const [formData, setFormData] = useState(defaultFormData);
    const previousFormData: any = usePrevious(formData);

    const navigate = useNavigate();
    const location = useLocation();
    const { queryArgs } = useQueryArgs();

    useEffect(() => {
        if (previousFormData && formData[dateName] !== previousFormData[dateName]) {
            const newQueryArgs = { ...queryArgs };
            newQueryArgs[dateName] = formData[dateName];
            navigate(location.pathname + "?" + queryString.stringify(newQueryArgs));
        } else if (queryArgs && dateName in queryArgs && formData[dateName] !== queryArgs[dateName]) {
            const change: any = {};
            change[dateName] = queryArgs[dateName];
            setFormData(change);
        }
    }, [dateName, previousFormData, formData, queryArgs, navigate, location]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validate={(validateFormData) => {
                const errors: any = {};
                const date = validateFormData[dateName];
                if (date !== formData[dateName]) {
                    const change: any = {};
                    change[dateName] = validateFormData[dateName];
                    setFormData(change);
                }
                return Object.keys(errors).length > 0 ? errors : {};
            }}
            onSubmit={null}
        >
            {({ isSubmitting }) => (
                <Fragment>
                    <Form autoComplete="off">
                        <div className={"form" + (className ? " " + className : "")}>
                            <DateField
                                className=""
                                name={dateName}
                                label={
                                    label
                                        ? label
                                        : dateName
                                              .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before uppercase letters
                                              .replace(/^./, (c) => c.toUpperCase()) // Capitalize the first letter
                                              .replace(/ (\w)/g, (c) => c.toLowerCase()) // Ensure spaces followed by lowercase
                                }
                                disabled={isSubmitting}
                                minDate={minDate}
                            />
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};
