import React, { Fragment } from "react";

interface IFactTableProps {
    className: string;
    header?: string;
    list: Array<any>;
}

export class FactTable extends React.Component<IFactTableProps> {
    onResize = () => this.setSizeClass(this);
    node: any;

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        this.setSizeClass(this);
    }
    componentWillUnmount() {
        if (this.onResize) {
            window.removeEventListener("resize", this.onResize);
        }
    }
    setSizeClass(_this) {
        if (_this.node && typeof _this.autoSize === "undefined") {
            var cl = _this.node.classList;
            if (cl) {
                _this.autoSize = !cl.contains("facttable-sm") && !cl.contains("facttable-md") && !cl.contains("facttable-lg");
            }
        }
        if (_this.autoSize === true && _this.node) {
            var width = _this.node.clientWidth;
            if (!width && _this.node.parentNode) {
                width = _this.node.parentNode.clientWidth;
            }
            if (width) {
                cl = _this.node.classList;
                if (width <= 520) {
                    cl.add("facttable-sm");
                    cl.remove("facttable-md");
                    cl.remove("facttable-lg");
                } else if (width <= 920) {
                    cl.remove("facttable-sm");
                    cl.add("facttable-md");
                    cl.remove("facttable-lg");
                } else {
                    cl.remove("facttable-sm");
                    cl.remove("facttable-md");
                    cl.add("facttable-lg");
                }
            }
        }
    }

    render() {
        var { className, header, list } = this.props;
        return (
            <div
                className={"facttable" + (className ? " " + className : "")}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {header ? (
                    <header>
                        <h4>{header}</h4>
                    </header>
                ) : null}
                <ul>
                    {list.map((d, i) => (
                        <li key={i}>
                            {d.value ? (
                                <Fragment>
                                    <div title={d.key}>{d.key}</div>
                                    <div>{d.value}</div>
                                </Fragment>
                            ) : null}
                            {d.values ? <div title={d.key}>{d.key}</div> : null}
                            {d.values ? d.values.map((e, i) => <div key={i}>{e}</div>) : null}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
