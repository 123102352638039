import React, { useState, Fragment, useEffect } from "react";
import { cloneDeep } from "lodash";
import { InstrumentInput, compareCurrency, Label, SelectField } from "./form";
import { Formik, Form, FieldArray } from "formik";
import { TextField } from "./form";
import { Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { gql, useMutation } from "urql";
import stableStringify from "json-stable-stringify";

import { Grid, Column, Svgs } from "../../../components/src";

import {
    defaultTAccountMappingValues,
    selectorToString,
    newTAccountMappingSelector
} from "../../../common/src/generalledger/TAccountChart";
import {
    TAccount,
    Instrument,
    InstrumentCategory,
    InstrumentProductTypeEnum,
    TAccountChart,
    TAccountMapping,
    TAccountTypeEnum,
    TAccountGroup1Enum,
    TAccountGroup2Enum,
    TAccountGroup3Enum,
    TAccountGroup4Enum,
    TAccountGroup5Enum,
    AccountingCompanyTypeEnum
} from "../types.generated";

import { YesNoModal } from "./YesNoModal";
import { Group2Mapping, Group3Mapping, Group4Mapping, mapping } from "../../../common/src";
import { usePrevious } from "../common/Utils";

const CLONE_TACCOUNT_CHART = gql`
    mutation cloneTAccountChart($name: String, $clientId: GraphQLObjectId!) {
        cloneTAccountChart(name: $name, clientId: $clientId) {
            _id
            name
            version
            client {
                _id
                name
            }
            locked
            tAccounts {
                number
                description
                group1
                group2
                group3
                group4
                group5
            }
            tAccountMappings {
                name
                selector {
                    key
                    instruments {
                        instrumentId
                        alias
                    }
                    categories
                    notMatchCategories
                    currencies
                    notMatchCurrencies
                    productTypes
                    notMatchProductTypes
                }
                values {
                    key
                    value
                }
            }
        }
    }
`;

const DELETE_TACCOUNT_CHART = gql`
    mutation deleteTAccountChart($_id: GraphQLObjectId!) {
        deleteTAccountChart(_id: $_id)
    }
`;

const accountingKeys = ["OpeningBalance", "Rounding", "Equity"];

function toggle(value, v) {
    const f = value.findIndex((d) => d === v);
    if (f >= 0) {
        value.splice(f, 1);
    } else {
        value.push(v);
    }
    return value.slice();
}

function clickHandler(value, v, onChange, setValue) {
    const vs = toggle(value.array ? value.array : [], v);
    if (onChange) {
        onChange(vs);
    }
    setValue({ label: value.label, array: vs });
}

function SingleSelect(props) {
    const { allItems, onChange } = props;
    const [value, setValue] = useReinitializableState(props.value);
    return (
        <ul className="singleselect">
            {allItems.map((d, i) => (
                <li
                    key={i}
                    className={"button " + (d === value ? "selected" : null)}
                    onClick={(e) => {
                        let v = (e.target as HTMLTextAreaElement).innerText;
                        if (v === value) {
                            v = null;
                        }
                        if (onChange) {
                            onChange(v);
                        }
                        setValue(v);
                    }}
                >
                    {d}
                </li>
            ))}
        </ul>
    );
}

function MultiSelect(props) {
    const { allItems, onChange } = props;
    const [value, setValue] = useReinitializableState({ array: props.value }, (v: { array: string[] }) =>
        v.array ? v.array.join(",") : "null"
    );
    return (
        <ul className="multiselect">
            {allItems.map((d, i) => (
                <li
                    key={i}
                    className={"button " + (value.array ? (value.array.findIndex((e) => e === d) >= 0 ? "selected" : "") : "")}
                    onClick={(e) => clickHandler(value, (e.target as HTMLTextAreaElement).innerText, onChange, setValue)}
                >
                    {d}
                </li>
            ))}
        </ul>
    );
}

function MultiSelectWithNotMatch(props): React.ReactElement {
    const { label, allItems, onChange } = props;
    const [state, setState] = useReinitializableState(
        { values: props.values, notMatch: props.notMatch === true },
        ({ values, notMatch }) => `${values ? values.join(",") : "null"} ${notMatch ? "true" : "false"}`
    );
    return (
        <div className="multiselectwithnotmatch">
            <span className="label">{label}</span>
            <span
                className="notmatch"
                onClick={() => {
                    setState({ values: state.values, notMatch: !state.notMatch });
                    if (onChange) {
                        onChange({ values: state.values, notMatch: !state.notMatch });
                    }
                }}
            >
                {state.notMatch ? "NOT" : ""} IN
            </span>
            <br />[
            <MultiSelect
                label={label}
                allItems={allItems}
                value={state.values}
                onChange={(e) => {
                    setState({ values: e, notMatch: state.notMatch });
                    if (onChange) {
                        onChange({ values: e, notMatch: state.notMatch });
                    }
                }}
            />
            ]
        </div>
    );
}

export function TAccountMappingsSelectorInput(props): React.ReactElement {
    const [value, setValue] = useReinitializableState(props.value, selectorToString);
    const { onChange } = props;

    const updateSelectorValue = (keyVals) => {
        const v = { ...value };
        keyVals.forEach((d) => {
            v[d.key] = d.value;
        });
        if (onChange) {
            onChange(v);
        }
        setValue(v);
        return v;
    };

    const { instruments } = props;
    const currencies = Object.keys(instruments.reduce((dict, d) => (d.currency ? (dict[d.currency] = true) : true) && dict, {}));
    currencies.sort(compareCurrency);

    return (
        <div className="form">
            <Formik
                enableReinitialize={true}
                initialValues={value}
                onSubmit={() => {
                    // No submit button
                }}
                validate={(values) => {
                    if (values.instruments) {
                        updateSelectorValue([{ key: "instruments", value: values.instruments ? values.instruments : [] }]);
                    }
                }}
            >
                {({ values }) => {
                    return (
                        <Fragment>
                            <span className="label">Key</span>
                            <SingleSelect
                                allItems={accountingKeys}
                                value={values.key}
                                onChange={(e) => updateSelectorValue([{ key: "key", value: e }])}
                            />
                            <MultiSelectWithNotMatch
                                allItems={Object.keys(InstrumentCategory).filter((d) => d !== "None")}
                                values={values.categories ? values.categories : null}
                                notMatch={values.notMatchCategories}
                                label="Category"
                                onChange={(e) =>
                                    updateSelectorValue([
                                        { key: "categories", value: e.values && e.values.length > 0 ? e.values : null },
                                        { key: "notMatchCategories", value: e.notMatch }
                                    ])
                                }
                            />
                            <MultiSelectWithNotMatch
                                allItems={Object.keys(InstrumentProductTypeEnum).filter((d) => d !== "None")}
                                values={values.productTypes ? values.productTypes : null}
                                notMatch={values.notMatchProductTypes}
                                label="Product type"
                                onChange={(e) =>
                                    updateSelectorValue([
                                        { key: "productTypes", value: e.values && e.values.length > 0 ? e.values : null },
                                        { key: "notMatchProductTypes", value: e.notMatch }
                                    ])
                                }
                            />
                            <MultiSelectWithNotMatch
                                allItems={currencies}
                                values={values.currencies ? values.currencies : null}
                                notMatch={values.notMatchCurrencies}
                                label="Currency"
                                onChange={(e) =>
                                    updateSelectorValue([
                                        { key: "currencies", value: e.values && e.values.length > 0 ? e.values : null },
                                        { key: "notMatchCurrencies", value: e.notMatch }
                                    ])
                                }
                            />
                            <div className="form-group">
                                <label>
                                    <b>Instruments</b>
                                </label>
                                <FieldArray
                                    name="instruments"
                                    render={(arrayHelpers) => (
                                        <Fragment>
                                            {values.instruments && values.instruments.length > 0 ? (
                                                values.instruments.map((instrumentSelector, index) => (
                                                    <div key={index} className="form-group form-row">
                                                        <div className="col-4">
                                                            <label>Instrument</label>
                                                            <InstrumentInput
                                                                name="instrument"
                                                                values={instruments}
                                                                selected={instrumentSelector.instrumentId}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    const newValues = cloneDeep(values);
                                                                    if (newValues.instruments && newValues.instruments.length) {
                                                                        newValues.instruments[index].instrumentId = e._id;
                                                                    }
                                                                    updateSelectorValue([
                                                                        {
                                                                            key: "instruments",
                                                                            value: newValues.instruments ? newValues.instruments : []
                                                                        }
                                                                    ]);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-4">
                                                            <TextField
                                                                className=""
                                                                name={`instruments[${index}].alias`}
                                                                type="text"
                                                                label="Alias"
                                                                disabled={false}
                                                            />
                                                        </div>
                                                        <Button
                                                            className="mt-4 me-1 mb-2"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            -
                                                        </Button>
                                                        <Button
                                                            className="mt-4 mb-2"
                                                            variant="primary"
                                                            size="sm"
                                                            onClick={() => arrayHelpers.insert(index, { instrumentId: null, alias: "" })}
                                                        >
                                                            +
                                                        </Button>
                                                    </div>
                                                ))
                                            ) : (
                                                <Button
                                                    className="ms-1"
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => arrayHelpers.push({ instrumentId: null, alias: "" })}
                                                >
                                                    +
                                                </Button>
                                            )}
                                        </Fragment>
                                    )}
                                />
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}

function useReinitializableState(initialValue, toHashFunction: (any) => string = null) {
    const toHash = (value) => (toHashFunction ? toHashFunction(value) : `${value}`);
    const toState = (value) => ({ hash: toHash(initialValue), value });
    const [{ hash, value }, setState] = useState(toState(initialValue));
    if (hash !== toHash(initialValue)) {
        setState(toState(initialValue));
    }
    return [value, (value) => setState(toState(value))];
}

interface TextInputProps {
    label: string;
    className: string;
    name: string;
    defaultValue: string;
    onChange: (string) => void;
    value: string;
}

export function TextInput(props: TextInputProps): React.ReactElement {
    const { label, className, name, defaultValue, onChange } = props;
    const [value, setValue] = useReinitializableState(props.value);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={name} value={label} />
            <input
                id={name}
                name={name}
                type="text"
                className={"form-control " + (value && value !== defaultValue ? "" : "defaultvalue")}
                value={(value ? value : defaultValue) as string}
                onClick={({ target }) => (target as HTMLInputElement).setSelectionRange(0, (target as HTMLInputElement).value.length)}
                onChange={(e) => {
                    let v = e.target.value;
                    if (v) {
                        v = v.toLocaleUpperCase();
                        v = v.replace(/[^0-9A-ZÅÄÖ/]/g, "");
                    }
                    if (onChange) {
                        onChange(v);
                    }
                    setValue(v);
                }}
                spellCheck="false"
            />
        </div>
    );
}

const valuesToString = (values) => values.map((d) => `${d.key}: ${d.value}`).join(", ");

export function TAccountMappingsInput(props): React.ReactElement {
    const { onChange } = props;
    const [values, setValues] = useReinitializableState(props.values, valuesToString);

    return (
        <div className="taccountmappingsinput form">
            {Object.keys(defaultTAccountMappingValues).map((d) => {
                const val = values.find((e) => e.key === d);
                const def = defaultTAccountMappingValues[d];
                if (accountingKeys.find((e) => e === d)) {
                    return null;
                }
                return (
                    <TextInput
                        label={d}
                        name={d}
                        value={val ? val.value : null}
                        defaultValue={def}
                        className=""
                        onChange={(e) => {
                            const index = values.findIndex((e) => e.key === d);
                            if (!e || e === def) {
                                if (index >= 0) {
                                    values.splice(index, 1);
                                }
                            } else {
                                if (index >= 0) {
                                    const val = values[index];
                                    val.value = e;
                                } else {
                                    values.push({ key: d, value: e });
                                }
                            }
                            const vs = values.slice();
                            if (onChange) {
                                onChange(vs);
                            }
                            setValues(vs);
                        }}
                    />
                );
            })}
        </div>
    );
}

/**
 * This component is a list view (grid) of TAccountMappings
 * @param props.value An array of TAccountMapping
 */
export function TAccountMappingsGrid({
    value,
    instruments,
    linkTo,
    onChange
}: {
    value: TAccountMapping[];
    instruments: Instrument[];
    linkTo?: LinkToType;
    onChange?: (d: any) => void;
}): React.ReactElement {
    const data = value.map((d) => ({
        name: d.name,
        selector: selectorToString(d.selector, instruments),
        values: d.values.map((d) => `${d.key}: ${d.value}`).join(", ")
    }));

    return (
        <Grid header="T-account mappings" data={data} tableClassName="table-xs taccountmappingsgrid">
            <Column
                field="name"
                className="grid-column-sticky"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                field="selector"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                field="values"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                className="center"
                field="moveUp"
                title="Move up"
                format={(f, d, i) => (
                    <Fragment>
                        {i > 0 ? (
                            <div className="arrow up">
                                <Svgs.ArrowRight />
                            </div>
                        ) : null}
                    </Fragment>
                )}
                onClick={(e, i) => {
                    if (i === 0) {
                        return;
                    }
                    const arr = value.slice();
                    const s = arr[i];
                    arr[i] = arr[i - 1];
                    arr[i - 1] = s;
                    if (onChange) {
                        onChange(arr);
                    }
                }}
            />
            <Column
                className="center"
                field="moveDown"
                title="Move down"
                format={(f, d, i) => (
                    <Fragment>
                        {i < data.length - 1 ? (
                            <div className="arrow down">
                                <Svgs.ArrowRight />
                            </div>
                        ) : null}
                    </Fragment>
                )}
                onClick={(e, i) => {
                    if (i === data.length - 1) {
                        return;
                    }
                    const arr = value.slice();
                    const s = arr[i];
                    arr[i] = arr[i + 1];
                    arr[i + 1] = s;
                    if (onChange) {
                        onChange(arr);
                    }
                }}
            />
            <Column
                className="center"
                field="delete"
                format={() => (
                    <div className="cross">
                        <Svgs.Cross />
                    </div>
                )}
                onClick={(e, i) => {
                    const arr = value.slice();
                    arr.splice(i, 1);
                    if (onChange) {
                        onChange(arr);
                    }
                }}
            />
        </Grid>
    );
}

export function TAccountMappingForm(props): React.ReactElement {
    const [index, setIndex] = useState(props.index);
    const [value, setValue] = useState(props.value);
    if (props.index !== index) {
        setIndex(props.index);
        setValue(props.value);
    }
    const { instruments, onChange } = props;
    const updateValue = (values) => {
        const v = { selector: values.selector, values: values.values ? values.values.slice() : [], name: values.name ? values.name : null };
        if (onChange) {
            onChange(v);
        }
        setValue(v);
    };

    // console.log(value.values.map((d) => `${d.key}: ${d.value}`).join(", "));
    return (
        <div className="taccountmappingform">
            <Formik
                enableReinitialize={true}
                initialValues={value}
                onSubmit={() => {
                    // No submit button
                }}
                validate={(values) => {
                    /*if (values.number) {
                    let type;
                    const firstNumber = values.number.toString().charAt(0);
                    if (firstNumber === "1") {
                        type = "Asset";
                    } else if (firstNumber === "2") {
                        type = "Liability";
                    } else {
                        type = "Income";
                    }

                    values.type = type;
                }*/
                    if (value.name !== values.name) {
                        updateValue(values);
                    }
                }}
            >
                {() => (
                    <div className="row">
                        <Form autoComplete="off" className="col-6">
                            <TextField name="name" label={<b>Name</b>} type="text" className="" disabled={false} />
                        </Form>
                    </div>
                )}
            </Formik>
            <TAccountMappingsSelectorInput
                value={value.selector}
                instruments={instruments}
                onChange={(e) => {
                    const newValues = cloneDeep(value);
                    newValues.selector = e;
                    updateValue(newValues);
                }}
            />
            <TAccountMappingsInput
                values={value.values}
                onChange={(e) => {
                    const newValues = cloneDeep(value);
                    newValues.values = e;
                    updateValue(newValues);
                }}
            />
        </div>
    );
}

type LinkToType = (d: any, i: any) => { to: string; target?: string };

/**
 * This components lists t-accounts, passed as value
 * @param value Array of TAccount
 */
export function TAccountsGrid({
    value,
    accountingCompanyType,
    linkTo,
    onChange
}: {
    value: TAccount[];
    accountingCompanyType: AccountingCompanyTypeEnum;
    linkTo?: LinkToType;
    onChange?: (value: TAccount[]) => void;
}): React.ReactElement {
    return (
        <Grid header="T-accounts" data={value} sortable tableClassName="table-xs taccountsgrid">
            <Column
                field="number"
                className="grid-column-sticky"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                field="description"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                field="type"
                format={(value, item, index) => {
                    return <Link to={linkTo(item, index).to}>{value}</Link>;
                }}
            />
            <Column
                field="group1"
                format={(value, item, index) => {
                    if (
                        mapping[item.group1] &&
                        mapping[item.group1].translation.Sv &&
                        mapping[item.group1].translation.Sv[accountingCompanyType]
                    ) {
                        return <Link to={linkTo(item, index).to}>{mapping[item.group1].translation.Sv[accountingCompanyType]}</Link>;
                    } else {
                        return <Link to={linkTo(item, index).to}>{value}</Link>;
                    }
                }}
            />
            <Column
                field="group2"
                format={(value, item, index) => {
                    if (
                        mapping[item.group1] &&
                        mapping[item.group1][item.group2] &&
                        mapping[item.group1][item.group2].translation.Sv &&
                        mapping[item.group1][item.group2].translation.Sv[accountingCompanyType]
                    ) {
                        return (
                            <Link to={linkTo(item, index).to}>
                                {mapping[item.group1][item.group2].translation.Sv[accountingCompanyType]}
                            </Link>
                        );
                    } else {
                        return <Link to={linkTo(item, index).to}>{value}</Link>;
                    }
                }}
            />
            <Column
                field="group3"
                format={(value, item, index) => {
                    if (
                        mapping[item.group1] &&
                        mapping[item.group1][item.group2] &&
                        mapping[item.group1][item.group2][item.group3] &&
                        mapping[item.group1][item.group2][item.group3].translation.Sv &&
                        mapping[item.group1][item.group2][item.group3].translation.Sv[accountingCompanyType]
                    ) {
                        return (
                            <Link to={linkTo(item, index).to}>
                                {mapping[item.group1][item.group2][item.group3].translation.Sv[accountingCompanyType]}
                            </Link>
                        );
                    } else {
                        return <Link to={linkTo(item, index).to}>{value}</Link>;
                    }
                }}
            />
            <Column
                field="group4"
                format={(value, item, index) => {
                    if (
                        mapping[item.group1] &&
                        mapping[item.group1][item.group2] &&
                        mapping[item.group1][item.group2][item.group3] &&
                        mapping[item.group1][item.group2][item.group3][item.group4] &&
                        mapping[item.group1][item.group2][item.group3][item.group4].translation.Sv &&
                        typeof mapping[item.group1][item.group2][item.group3][item.group4].translation.Sv[accountingCompanyType] !==
                            "undefined"
                    ) {
                        return (
                            <Link to={linkTo(item, index).to}>
                                {mapping[item.group1][item.group2][item.group3][item.group4].translation.Sv[accountingCompanyType]}
                            </Link>
                        );
                    } else {
                        return <Link to={linkTo(item, index).to}>{value}</Link>;
                    }
                }}
            />
            <Column
                field="group5"
                format={(value, item, index) => {
                    if (
                        mapping[item.group1] &&
                        mapping[item.group1][item.group2] &&
                        mapping[item.group1][item.group2][item.group3] &&
                        mapping[item.group1][item.group2][item.group3][item.group4] &&
                        mapping[item.group1][item.group2][item.group3][item.group4][item.group5] &&
                        mapping[item.group1][item.group2][item.group3][item.group4].translation.Sv &&
                        typeof mapping[item.group1][item.group2][item.group3][item.group4][item.group5].translation.Sv[
                            accountingCompanyType
                        ] !== "undefined"
                    ) {
                        return (
                            <Link to={linkTo(item, index).to}>
                                {
                                    mapping[item.group1][item.group2][item.group3][item.group4][item.group5].translation.Sv[
                                        accountingCompanyType
                                    ]
                                }
                            </Link>
                        );
                    } else {
                        return <Link to={linkTo(item, index).to}>{value}</Link>;
                    }
                }}
            />
            <Column
                className="center"
                field="delete"
                format={() => (
                    <div className="cross">
                        <Svgs.Cross />
                    </div>
                )}
                onClick={(e, i) => {
                    const arr = value.slice();
                    arr.splice(i, 1);
                    if (onChange) {
                        onChange(arr);
                    }
                }}
            />
        </Grid>
    );
}

/**
 * This component is an edit form for a single TAccount.
 * @param value A TAccount
 */
export function TAccountForm({
    value,
    accountingCompanyType,
    onChange
}: {
    value: TAccount;
    accountingCompanyType: AccountingCompanyTypeEnum;
    onChange?: (value: TAccount) => void;
}): React.ReactElement {
    const [values, setValues] = useState(cloneDeep(value));
    const previousValue: any = usePrevious(value);
    const previousValues: any = usePrevious(values);
    const [groupsOptions, setGroupsOptions] = useState({
        two: [],
        three: [],
        four: [],
        five: []
    });

    const group1Options = Object.keys(TAccountGroup1Enum).map(function (groupName) {
        let groupNameTranslated: string = groupName;
        if (mapping[groupName] && mapping[groupName].translation.Sv && mapping[groupName].translation.Sv[accountingCompanyType]) {
            groupNameTranslated = mapping[groupName].translation.Sv[accountingCompanyType];
        }
        return { key: groupName as unknown as TAccountGroup1Enum, value: groupNameTranslated };
    });

    useEffect(() => {
        if (stableStringify(value) !== stableStringify(previousValue)) {
            setValues(cloneDeep(value));
        }
    }, [previousValue, value]);

    useEffect(() => {
        // Default values
        const newValues = cloneDeep(values);
        if (!newValues.group1) {
            newValues.group1 = TAccountGroup1Enum.Assets;
        }
        if (!newValues.group2) {
            newValues.group2 = TAccountGroup2Enum.A;
        }
        if (!newValues.group3) {
            newValues.group3 = TAccountGroup3Enum.A;
        }
        if (!newValues.group4) {
            newValues.group4 = TAccountGroup4Enum.None;
        }
        if (!newValues.group5) {
            newValues.group5 = TAccountGroup5Enum.None;
        }

        if (stableStringify(values) !== stableStringify(newValues)) {
            setValues(cloneDeep(newValues));
        }

        const updatedValues = cloneDeep(values);

        // Set options based on chosen groups
        let newOptionsGroup2: { key: TAccountGroup2Enum; value: string }[] = [];
        let newOptionsGroup3: { key: TAccountGroup3Enum; value: string }[] = [];
        let newOptionsGroup4: { key: TAccountGroup4Enum; value: string }[] = [];
        let newOptionsGroup5: { key: TAccountGroup5Enum; value: string }[] = [];
        if (values && values.group1 && mapping[values.group1]) {
            const optionsAndTranslationTwo = cloneDeep(mapping[values.group1]);
            delete optionsAndTranslationTwo.sorting;
            delete optionsAndTranslationTwo.translation;
            newOptionsGroup2 = Object.keys(optionsAndTranslationTwo).map(function (groupName) {
                let groupNameTranslated: string = groupName;
                if (
                    optionsAndTranslationTwo[groupName] &&
                    optionsAndTranslationTwo[groupName].translation.Sv &&
                    optionsAndTranslationTwo[groupName].translation.Sv[accountingCompanyType]
                ) {
                    groupNameTranslated = optionsAndTranslationTwo[groupName].translation.Sv[accountingCompanyType];
                }
                return { key: groupName as unknown as TAccountGroup2Enum, value: groupNameTranslated };
            });
        }
        let optionsAndTranslationThree: Group2Mapping;
        if (values && values.group1 && mapping[values.group1] && newOptionsGroup2.length > 0) {
            let key = values.group2;
            if (previousValues && previousValues.group1 !== values.group1) {
                updatedValues.group2 = newOptionsGroup2[0].key;
                key = newOptionsGroup2[0].key;
            }
            optionsAndTranslationThree = cloneDeep(mapping[values.group1][key]);
            delete optionsAndTranslationThree.sorting;
            delete optionsAndTranslationThree.translation;
            newOptionsGroup3 = Object.keys(optionsAndTranslationThree).map(function (groupName) {
                let groupNameTranslated: string = groupName;
                if (
                    optionsAndTranslationThree[groupName] &&
                    optionsAndTranslationThree[groupName].translation.Sv &&
                    optionsAndTranslationThree[groupName].translation.Sv[accountingCompanyType]
                ) {
                    groupNameTranslated = optionsAndTranslationThree[groupName].translation.Sv[accountingCompanyType];
                }
                return { key: groupName as unknown as TAccountGroup3Enum, value: groupNameTranslated };
            });
        }

        let optionsAndTranslationFour: Group3Mapping;
        if (optionsAndTranslationThree && newOptionsGroup3.length > 0) {
            let key = values.group3;
            if (previousValues && (previousValues.group2 !== values.group2 || previousValues.group1 !== values.group1)) {
                updatedValues.group3 = newOptionsGroup3[0].key;
                key = newOptionsGroup3[0].key;
            }
            optionsAndTranslationFour = cloneDeep(optionsAndTranslationThree[key]);
            delete optionsAndTranslationFour.sorting;
            delete optionsAndTranslationFour.translation;
            newOptionsGroup4 = Object.keys(optionsAndTranslationFour).map(function (groupName) {
                let groupNameTranslated: string = groupName;
                if (
                    optionsAndTranslationFour[groupName] &&
                    optionsAndTranslationFour[groupName].translation.Sv &&
                    typeof optionsAndTranslationFour[groupName].translation.Sv[accountingCompanyType] !== "undefined"
                ) {
                    groupNameTranslated = optionsAndTranslationFour[groupName].translation.Sv[accountingCompanyType];
                }
                return { key: groupName as unknown as TAccountGroup4Enum, value: groupNameTranslated };
            });
        }
        let optionsAndTranslationFive: Group4Mapping;
        if (optionsAndTranslationFour && newOptionsGroup4.length > 0) {
            let keyGroup4 = values.group4;
            if (
                previousValues &&
                (previousValues.group3 !== values.group3 ||
                    previousValues.group2 !== values.group2 ||
                    previousValues.group1 !== values.group1)
            ) {
                updatedValues.group4 = newOptionsGroup4[0].key;
                keyGroup4 = newOptionsGroup4[0].key;
            }

            optionsAndTranslationFive = cloneDeep(optionsAndTranslationFour[keyGroup4]);
            delete optionsAndTranslationFive.sorting;
            delete optionsAndTranslationFive.translation;
            newOptionsGroup5 = Object.keys(optionsAndTranslationFive).map(function (groupName) {
                let groupNameTranslated: string = groupName;
                if (
                    optionsAndTranslationFive[groupName] &&
                    optionsAndTranslationFive[groupName].translation.Sv &&
                    typeof optionsAndTranslationFive[groupName].translation.Sv[accountingCompanyType] !== "undefined"
                ) {
                    groupNameTranslated = optionsAndTranslationFive[groupName].translation.Sv[accountingCompanyType];
                }
                return { key: groupName as unknown as TAccountGroup5Enum, value: groupNameTranslated };
            });

            if (
                previousValues &&
                (previousValues.group4 !== values.group4 ||
                    previousValues.group3 !== values.group3 ||
                    previousValues.group2 !== values.group2 ||
                    previousValues.group1 !== values.group1)
            ) {
                updatedValues.group5 = newOptionsGroup5[0].key;
            }
        }

        // Setting new default values if groups changed
        if (stableStringify(values) !== stableStringify(updatedValues)) {
            setValues(cloneDeep(updatedValues));
        }

        if (
            !(groupsOptions.two.filter(({ value: id1 }) => newOptionsGroup2.some(({ value: id2 }) => id2 === id1)).length > 0) ||
            !(groupsOptions.three.filter(({ value: id1 }) => newOptionsGroup3.some(({ value: id2 }) => id2 === id1)).length > 0) ||
            !(groupsOptions.four.filter(({ value: id1 }) => newOptionsGroup4.some(({ value: id2 }) => id2 === id1)).length > 0) ||
            !(groupsOptions.five.filter(({ value: id1 }) => newOptionsGroup5.some(({ value: id2 }) => id2 === id1)).length > 0)
        ) {
            setGroupsOptions({
                two: newOptionsGroup2.length > 0 ? newOptionsGroup2 : groupsOptions.two,
                three: newOptionsGroup3.length > 0 ? newOptionsGroup3 : groupsOptions.three,
                four: newOptionsGroup4.length > 0 ? newOptionsGroup4 : groupsOptions.four,
                five: newOptionsGroup5.length > 0 ? newOptionsGroup5 : groupsOptions.five
            });
        }
    }, [accountingCompanyType, groupsOptions.five, groupsOptions.four, groupsOptions.three, groupsOptions.two, previousValues, values]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={values}
            onSubmit={() => {
                // No submit button
            }}
            validate={(values) => {
                if (values.number) {
                    let type;
                    const firstNumber = values.number.toString().charAt(0);
                    if (firstNumber === "1") {
                        type = "Asset";
                    } else if (firstNumber === "2") {
                        type = "Liability";
                    } else {
                        type = "Income";
                    }

                    values.type = type;
                }
                if (onChange) {
                    setValues(values);
                    onChange(values);
                }
            }}
        >
            {() => (
                <Form autoComplete="off" className="taccountform">
                    <TextField name="number" label="Number" type="text" className="" disabled={false} />
                    <TextField name="description" label="Description" type="text" className="" disabled={false} />
                    <SelectField name="group1" options={group1Options} label="Group 1" type="text" className="" disabled={false} />
                    <SelectField name="group2" options={groupsOptions.two} label="Group 2" type="text" className="" disabled={false} />
                    <SelectField name="group3" options={groupsOptions.three} label="Group 3" type="text" className="" disabled={false} />
                    <SelectField name="group4" options={groupsOptions.four} label="Group 4" type="text" className="" disabled={false} />
                    <SelectField name="group5" options={groupsOptions.five} label="Group 5" type="text" className="" disabled={false} />
                </Form>
            )}
        </Formik>
    );
}

export function redirect(navigate: any, id: string, tAccountIndex: number, tAccountMappingIndex: number): void {
    if (tAccountIndex === -1 && tAccountMappingIndex === -1) {
        navigate(`/taccountcharts/${id}`);
    } else {
        navigate(`/taccountcharts/${id}/${tAccountIndex}/${tAccountMappingIndex}`);
    }
}

export function TAccountChartForm(props: {
    id: string;
    tAccountChart: TAccountChart;
    tAccountIndex: number;
    tAccountMappingIndex: number;
    instruments: Instrument[];
    accountingReadWriteAccess: boolean;
    onSave?: (value: TAccountChart) => void;
    onImport?: (value: { id: any; kind: any; import: any }) => void;
    onExport?: (value: { id: any; kind: any }) => void;
}): React.ReactElement {
    const navigate = useNavigate();
    const { id, tAccountIndex, tAccountMappingIndex, instruments, accountingReadWriteAccess, onSave, onImport, onExport } = props;

    const [__stateClone, cloneTAccountChart] = useMutation(CLONE_TACCOUNT_CHART);
    const [__stateDelete, deleteTAccountChart] = useMutation(DELETE_TACCOUNT_CHART);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [tAccountChart, setTAccountChart] = useReinitializableState(props.tAccountChart, (c) => c._id);

    useEffect(() => {
        if (tAccountIndex > tAccountChart.tAccounts.length - 1 || tAccountMappingIndex > tAccountChart.tAccountMappings.length - 1) {
            navigate(`/taccountcharts/${id}`, { replace: true });
            //return <Navigate to={`/taccountcharts/${id}`} replace />;
        }
    }, [id, navigate, tAccountChart.tAccountMappings.length, tAccountChart.tAccounts.length, tAccountIndex, tAccountMappingIndex]);

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete tAccountChart with id " + modal.payload._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deleteTAccountChart({
                            _id: modal.payload._id
                        })
                            .then((result) => {
                                if (result.error) {
                                    console.error(result.error.toString());
                                } else {
                                    navigate("/taccountcharts");
                                }
                            })
                            .catch((error) => {
                                console.error(error.toString());
                            });
                    }}
                />
            ) : null}
            <div className="taccountchartform">
                <div className="row">
                    <div className="col-12">
                        <Button
                            type="submit"
                            className="btn-sm"
                            onClick={() => {
                                if (onSave) {
                                    let vs = tAccountChart.tAccounts.slice();
                                    const dict: { [key: string]: TAccount } = {};
                                    vs.forEach((d) => (dict[d.number] = d));
                                    vs = Object.values(dict);
                                    vs.sort((d1, d2) => (d1.number < d2.number ? -1 : 1));
                                    tAccountChart.tAccounts = vs;

                                    onSave(tAccountChart);
                                    redirect(navigate, id, -1, -1);
                                }
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Save
                        </Button>
                        &nbsp;
                        <Button
                            type="submit"
                            className="btn-sm"
                            disabled={!accountingReadWriteAccess ? true : false}
                            onClick={async () => {
                                await cloneTAccountChart({
                                    name: tAccountChart.name,
                                    clientId: tAccountChart.client._id
                                })
                                    .then(async (result) => {
                                        if (result.error) {
                                            console.error(result.error.toString());
                                        } else {
                                            // set new accountChart
                                            setTAccountChart(result.data.cloneTAccountChart);
                                            redirect(navigate, result.data.cloneTAccountChart._id, -1, -1);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error.toString());
                                    });
                            }}
                        >
                            Create new version
                        </Button>
                        &nbsp;
                        <Button
                            type="submit"
                            className="btn-sm"
                            onClick={async () => {
                                setModal({ showModal: true, payload: { _id: tAccountChart._id } });
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Delete
                        </Button>
                        &nbsp;
                        <input
                            type="file"
                            id="file-import-taccounts"
                            className="hidden"
                            onChange={(e) => {
                                // read file content
                                const files = e.target.files;
                                if (files.length > 0) {
                                    const file = files[0];
                                    const reader = new FileReader();
                                    reader.readAsText(file, "UTF-8");
                                    reader.onload = async function (f) {
                                        // import T-Accounts...
                                        const csv = f.target.result;
                                        onImport({ id, kind: "taccounts", import: csv });
                                    };
                                }
                            }}
                        />
                        <Button
                            type="button"
                            className="btn-sm"
                            onClick={() => {
                                document.getElementById("file-import-taccounts").click();
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Import T-Accounts
                        </Button>
                        &nbsp;
                        <Button
                            type="button"
                            className="btn-sm"
                            onClick={() => {
                                onExport({ id, kind: "taccounts" });
                            }}
                        >
                            Export T-Accounts
                        </Button>
                        &nbsp;
                        <input
                            type="file"
                            id="file-import-mappings"
                            className="hidden"
                            onChange={(e) => {
                                // read file content
                                const files = e.target.files;
                                if (files.length > 0) {
                                    const file = files[0];
                                    const reader = new FileReader();
                                    reader.readAsText(file, "UTF-8");
                                    reader.onload = async function (f) {
                                        // import Mappings...
                                        const csv = f.target.result;
                                        onImport({ id, kind: "mappings", import: csv });
                                    };
                                }
                            }}
                        />
                        <Button
                            type="button"
                            className="btn-sm"
                            onClick={() => {
                                document.getElementById("file-import-mappings").click();
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Import T-Account Mappings
                        </Button>
                        &nbsp;
                        <Button
                            type="button"
                            className="btn-sm"
                            onClick={async () => {
                                onExport({ id, kind: "mappings" });
                            }}
                        >
                            Export T-Account Mappings
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>
                            {tAccountChart.client ? "Client" : "Name"}:{" "}
                            {tAccountChart.client ? tAccountChart.client.name : tAccountChart.name}
                        </p>
                    </div>
                    <div className="col-12">
                        <p>Version: {tAccountChart.version}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <TAccountsGrid
                            value={tAccountChart.tAccounts}
                            accountingCompanyType={tAccountChart.client.accountingCompanyType}
                            linkTo={(d, i) => ({ to: `/taccountcharts/${id}/${i}/-1` })}
                            onChange={(value) => {
                                tAccountChart.tAccounts = [...value];
                                setTAccountChart(tAccountChart);
                                redirect(navigate, id, -1, -1);
                            }}
                        />
                        <Button
                            type="submit"
                            className="btn-sm"
                            onClick={() => {
                                console.log("Add T-Account");
                                let i = 0;
                                // eslint-disable-next-line no-constant-condition
                                while (true) {
                                    const f = tAccountChart.tAccounts.find((d) => d.number === i.toFixed(0).padStart(4, "0"));
                                    if (!f) {
                                        break;
                                    }
                                    i++;
                                }
                                tAccountChart.tAccounts.push({
                                    number: i.toFixed(0).padStart(4, "0"),
                                    type: TAccountTypeEnum.Asset,
                                    description: "New T-Account"
                                } as TAccount);
                                setTAccountChart(tAccountChart);
                                redirect(navigate, id, tAccountChart.tAccounts.length - 1, -1);
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Add T-Account
                        </Button>
                        {tAccountIndex >= 0 && tAccountIndex <= tAccountChart.tAccounts.length - 1 ? (
                            <TAccountForm
                                value={tAccountChart.tAccounts[tAccountIndex]}
                                accountingCompanyType={tAccountChart.client.accountingCompanyType}
                                onChange={(value) => {
                                    tAccountChart.tAccounts[tAccountIndex] = { ...value };
                                    // var { number } = value;
                                    // tAccountChart.tAccounts.sort((d1, d2) => (d1.number < d2.number ? -1 : 1));
                                    // var index = tAccountChart.tAccounts.findIndex((d) => d.number === number);
                                    setTAccountChart(tAccountChart);
                                    // redirect(id, index, -1);
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="col-12 col-lg-6 taccountsmappingsform">
                        <TAccountMappingsGrid
                            value={tAccountChart.tAccountMappings}
                            instruments={instruments}
                            linkTo={(d, i) => ({ to: `/taccountcharts/${id}/-1/${i}` })}
                            onChange={(value) => {
                                if (tAccountChart.locked === true) {
                                    return;
                                }
                                tAccountChart.tAccountMappings = [...value];
                                setTAccountChart(tAccountChart);
                                redirect(navigate, id, -1, -1);
                            }}
                        />
                        <Button
                            type="submit"
                            className="btn-sm"
                            onClick={() => {
                                tAccountChart.tAccountMappings.push({
                                    name: null,
                                    selector: newTAccountMappingSelector(),
                                    values: []
                                } as TAccountMapping);
                                //console.log("Add T-Account mapping", tAccountChart.tAccountMappings);
                                setTAccountChart(tAccountChart);
                            }}
                            disabled={tAccountChart.locked === true || !accountingReadWriteAccess ? true : false}
                        >
                            Add T-Account mapping
                        </Button>
                        {tAccountMappingIndex >= 0 ? (
                            <TAccountMappingForm
                                index={tAccountMappingIndex}
                                value={tAccountChart.tAccountMappings[tAccountMappingIndex]}
                                instruments={instruments}
                                onChange={(e) => {
                                    //console.log(selectorToString(e.selector, instruments), e.values.map((d) => `${d.key}: ${d.value}`).join(", "));
                                    if (tAccountChart.locked === true) {
                                        return;
                                    }
                                    tAccountChart.tAccountMappings[tAccountMappingIndex] = { ...e };
                                    setTAccountChart(tAccountChart);
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
