import React from "react";
import { Loader } from "./Svgs";

interface WidgetState {
    data: any;
}

interface WidgetChild {
    data?: any;
    load?: any;
    render: (...args: any[]) => any;
}

export class Widget extends React.Component<{ children?: WidgetChild }, WidgetState> {
    constructor(props: any) {
        super(props);
        this.state = { data: null };
    }
    loading: boolean;

    componentDidMount() {
        const setState = (children, data) => {
            this.loading = false;
            this.setState({ data });
        };
        const { children } = this.props;
        if (children.load && !children.data && this.loading !== true) {
            this.loading = true;
            var loadResult;
            if (children.load instanceof Promise) {
                loadResult = children.load;
            } else {
                loadResult = children.load();
            }
            if (loadResult instanceof Promise) {
                loadResult
                    .then((resp) => {
                        setState(children, Array.isArray(resp) ? resp.map((d) => d.data) : resp.data);
                        return true;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setState(children, loadResult);
            }
        }
    }
    render() {
        var { children } = this.props;
        var data = children.data;
        if (!data && this.state.data) {
            data = this.state.data;
        }
        return (
            <div className="widget">
                {data ? (
                    children.render({ data })
                ) : (
                    <div className="loader">
                        <Loader />
                    </div>
                )}
            </div>
        );
    }
}
