/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDataQueryVariables = Types.Exact<{
  instrumentIds: Array<Types.Scalars['GraphQLObjectId']['input']> | Types.Scalars['GraphQLObjectId']['input'];
  date?: Types.InputMaybe<Types.Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetDataQuery = { __typename?: 'Query', accountPositions?: Array<{ __typename?: 'InstrumentAccountPosition', instrumentId: string, accountId?: string | null, externalAccountId?: string | null, clientId: string, quantity: number, instrument?: { __typename?: 'Instrument', name?: string | null } | null, account?: { __typename?: 'PartyAccount', name: string, description?: string | null } | null, externalAccount?: { __typename?: 'PartyExternalAccount', name: string, description?: string | null } | null, client?: { __typename?: 'Party', name: string } | null } | null> | null, externalAccountPositions?: Array<{ __typename?: 'InstrumentAccountPosition', instrumentId: string, accountId?: string | null, externalAccountId?: string | null, clientId: string, quantity: number, instrument?: { __typename?: 'Instrument', name?: string | null } | null, account?: { __typename?: 'PartyAccount', name: string, description?: string | null } | null, externalAccount?: { __typename?: 'PartyExternalAccount', name: string, description?: string | null } | null, client?: { __typename?: 'Party', name: string } | null } | null> | null };


export const GetDataDocument = gql`
    query getData($instrumentIds: [GraphQLObjectId!]!, $date: GraphQLDateString, $filterZeroPositions: Boolean) {
  accountPositions: instrumentAccountPositions(
    instrumentIds: $instrumentIds
    date: $date
    groupPositionsBy: AccountId
    filterZeroPositions: $filterZeroPositions
  ) {
    instrumentId
    instrument {
      name
    }
    accountId
    account {
      name
      description
    }
    externalAccountId
    externalAccount {
      name
      description
    }
    clientId
    client {
      name
    }
    quantity
  }
  externalAccountPositions: instrumentAccountPositions(
    instrumentIds: $instrumentIds
    date: $date
    groupPositionsBy: ExternalAccountId
    filterZeroPositions: $filterZeroPositions
  ) {
    instrumentId
    instrument {
      name
    }
    accountId
    account {
      name
      description
    }
    externalAccountId
    externalAccount {
      name
      description
    }
    clientId
    client {
      name
    }
    quantity
  }
}
    `;

export function useGetDataQuery(options: Omit<Urql.UseQueryArgs<GetDataQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDataQuery, GetDataQueryVariables>({ query: GetDataDocument, ...options });
};