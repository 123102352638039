import React from "react";
import { cloneDeep } from "lodash";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { TimeSeries, AlignMethod, DateHelper } from "../../../common/src";
import { PlotlyDefaults, Svgs } from "../../../components/src";
import { exportToXlsx } from "../common/exportToXlsx";

const Plot = createPlotlyComponent(Plotly);

type PerformanceTimeSeriesChartInput = {
    masterTimeSerie: TimeSeries;
    timeseries: TimeSeries[];
    config?: Plotly.Config;
    startDate?: string;
    style?: React.CSSProperties | undefined;
    showXlsxButton?: boolean;
};
export const RelativeTimeSeriesChart = ({
    masterTimeSerie,
    timeseries,
    config,
    startDate,
    showXlsxButton = false,
    ...props
}: PerformanceTimeSeriesChartInput): React.ReactElement => {
    const maxArray = [0.05];
    const diffTimeSeries = [];

    let masterTs = cloneDeep(masterTimeSerie);

    const tsArray = cloneDeep(timeseries);
    if (startDate) {
        masterTs = masterTs.range(new Date(startDate), masterTs.end);
        for (let i = 0; i < tsArray.length; i++) {
            tsArray[i] = TimeSeries.align(masterTs, tsArray[i], AlignMethod.Latest);
        }
    }

    if (tsArray && tsArray.length > 0) {
        for (let i = 0; i < tsArray.length; i++) {
            const ts = tsArray[i];
            if (ts.name !== masterTs.name) {
                const diff = masterTs.normalize(1).subtract(ts.normalize(1));
                diff.name = ts.name;
                const max = diff.__values.reduce((p, c) => Math.max(Math.abs(c), p), 0);
                maxArray.push(max);
                diffTimeSeries.push(diff);
            }
        }
    }

    const colors = ["#66BC6B", "#BDBDBD", "#B3E5FC", "#1F5F32", "#757575", "#01579B", "#AB47BC", "#4A148C"];

    const traces = diffTimeSeries.map((ts, index) => PlotlyDefaults.toTrace(ts, ts.name, colors[index + 1])) as Partial<Plotly.PlotData>[];

    for (let i = 0; i < traces.length; i++) {
        traces[i].fill = "tozeroy";
    }
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const override: PartialDeep<Plotly.Layout> = {
        autosize: true,
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        yaxis: { tickformat: ".1%", automargin: true },
        xaxis: {
            title: "",
            hoverformat: "%Y-%m-%d",
            zeroline: false,
            automargin: true
        },
        width: null,
        height: null,
        margin: { l: 0, b: 0, t: 0, r: 0 },
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",
            x: 0.01,
            xanchor: "left",
            y: 0.99,
            yanchor: "top"
        }
    };

    const layout = PlotlyDefaults.mergeLayout(defaultLayout, override);

    config = { displayModeBar: false, ...config };

    const thisConfig = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), config);

    return (
        <div>
            {showXlsxButton ? (
                <div
                    style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                    onClick={() => {
                        const data: Record<string, Record<string, string | number>> = {};
                        traces.forEach((trace) => {
                            for (const index in trace.x) {
                                const date = DateHelper.timestampToString(trace.x[index]);
                                const value = trace.y[index];
                                if (data[date]) {
                                    data[date][trace.name] = value;
                                } else {
                                    const timeSeriesItem: Record<string, string | number> = {};
                                    timeSeriesItem["Date"] = date;
                                    timeSeriesItem[trace.name] = value;
                                    data[date] = timeSeriesItem;
                                }
                            }
                        });
                        exportToXlsx([Object.values(data)], "relative_performance.xlsx", ["relative_performance"]);
                    }}
                >
                    <Svgs.Excel />
                </div>
            ) : null}

            <div className="plotly-print">
                <Plot data={traces} layout={layout} config={thisConfig} useResizeHandler={true} {...props} />
            </div>
        </div>
    );
};

export default RelativeTimeSeriesChart;
