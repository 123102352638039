import React from "react";

export const ScreeningInformation = (): React.ReactElement => {
    return (
        <React.Fragment>
            <div className="row">
                <p>En Captor standard screening utgörs av: </p>
            </div>
            <div className="row mb-3">
                <h4>Produkter och tjänster</h4>
                <ul>
                    <li>Klusterbomber, personminor 0%</li>
                    <li>Kemiska och biologiska vapen 0%</li>
                    <li>Kärnvapen 0%</li>
                    <li>Vapen och/eller krigsmateriel 5%</li>
                    <li>Alkohol (produktion) 5%</li>
                    <li>Tobak (produktion) 5%</li>
                    <li>Kommersiell spelverksamhet (Drift och verksamhet, skräddarsydd utrustning tillbehör) 5%</li>
                    <li>Pornografi (produktion) 5%</li>
                    <li>Fossila bränslen 5%</li>
                </ul>
            </div>
            <div className="row">
                <h4>Normbaserad screening</h4>
            </div>
            <div className="row">
                <p>
                    Internationella normer avser internationella konventioner, lagar och överenskommelser såsom FN Global Compact och OECD:s
                    riktlinjer för multinationella företag som rör frågor om miljö, mänskliga rättigheter, arbetsvillkor och affärsetik.
                </p>
            </div>
        </React.Fragment>
    );
};
