import React from "react";
import { Link } from "react-router-dom";

//import { Svgs } from "../../../components/src";
import { TicketNotification } from "../containers/TicketNotification";

interface INavPropTypes {
    title: string;
}

export const Nav = ({ title }: INavPropTypes): React.ReactElement => {
    return (
        <nav className="navbar navbar-expand-sm navbar-toggleable-md navbar-inverse bg-faded">
            <div
                className="navbar__hamburger__opacity"
                onClick={() => {
                    const sel = document.querySelector("aside#sideNav");
                    if (sel) {
                        sel.classList.add("show");
                    }
                }}
            >
                <span className="navbar-toggler-icon" />
            </div>
            <div className="flexdiv navbar__logo__opacity">
                <Link to={"/client"}>
                    <h1 className="navbar-brand">{title}</h1>
                </Link>
            </div>
            <div className="navbar__bell__opacity">
                <TicketNotification />
            </div>
            {/* <Svgs.CaptorLogomark thickness={1 / 10} />*/}
        </nav>
    );
};
