import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";

import { useQueryArgs } from "../../common/Utils";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { ReactTable } from "../../components/react-table/ReactTable";
import { Svgs } from "../../../../components/src";
import { exportToXlsx } from "../../../../components/src/exportToXlsx";
import { sheetType } from "../../common/exportToXlsx";

const getTimeseries = gql`
    query getTimeseries($filter: TimeSeriesFilterInput) {
        timeseries(includeItems: false, filter: $filter) {
            _id
            status
            clientId
            client {
                _id
                name
            }
            objectId
            instrument {
                _id
                name
                longName
                productType
                currency
            }
            type
            status
            updateTimestamp
        }
    }
`;

export const TimeSeriesPage = (): React.ReactElement => {
    const { queryArgs } = useQueryArgs();
    const { instrumentId } = queryArgs;

    // instrumentId queryArg
    const filter = instrumentId ? { instrumentIdIn: [instrumentId] } : {};
    const [{ fetching, error, data }] = useQuery({ query: getTimeseries, variables: { filter } });

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/timeseries/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 100
            },
            /* TODO: All clientId seems to be set to 24x0. We should correct that in backend and frontend
            {
                Header: "Client",
                accessor: "clientName",
                minWidth: 50,
                width: 200,
                Cell: (cellProps) => {
                    const { row, value } = cellProps;
                    if (row.original.client) {
                        return (
                            <Link to={`/parties/${row.original.client._id}`} >
                                {value}
                            </Link>
                        );
                    } else {
                        return null;
                    }
                }
            },*/
            {
                header: "Instrument",
                id: "instrumentName",
                accessorKey: "instrumentName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.instrument ? (
                        <Link to={`/instruments/${row.original.instrument._id}`}>{cellProps.getValue()}</Link>
                    ) : (
                        <div>{cellProps.getValue()}</div>
                    );
                }
            },
            {
                header: "Long name",
                id: "instrument.longName",
                accessorKey: "instrument.longName"
            },
            {
                header: "Type",
                accessorKey: "type"
            },
            {
                header: "Product type",
                accessorKey: "instrumentProductType",
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Status",
                accessorKey: "status",
                size: 75
            },
            {
                header: "Currency",
                id: "instrument.currency",
                accessorKey: "instrument.currency",
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp"
            }
        ],
        []
    );

    const download = (timeseries) => {
        const rows: sheetType[] = timeseries.map((t) => {
            return {
                _id: t._id,
                instrument: t.instrumentName,
                longName: t.instrument.longName,
                type: t.type,
                productType: t.instrumentProductType,
                status: t.status,
                currency: t.instrument.currency,
                updateTimestamp: t.updateTimestamp
            };
        });
        exportToXlsx(rows, "timeseries.xlsx");
    };

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const timeseries = cloneDeep(data.timeseries);

    timeseries.forEach((timeserie) => {
        timeserie.clientName = timeserie.client ? (timeserie.client.name ? timeserie.client.name : "") : "";
        timeserie.instrumentName = timeserie.instrument ? (timeserie.instrument.name ? timeserie.instrument.name : "") : "";
        timeserie.instrumentProductType = timeserie.instrument
            ? timeserie.instrument.productType
                ? timeserie.instrument.productType
                : ""
            : "";
    });

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto p-2">
                    <Link to={"/timeseries/new"}>New</Link>
                </div>
            </div>
            <p>Number of time series: {timeseries.length}</p>
            <div
                style={{ width: "20px", height: "20px" }}
                onClick={() => {
                    download(timeseries);
                }}
            >
                <Svgs.Excel />
            </div>
            <ReactTable columns={columns} data={timeseries} defaultHiddenColumns={["status", "updateTimestamp"]} />
        </div>
    );
};
