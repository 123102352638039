import { FormikErrors } from "formik";
import { CombinedError } from "urql";

export const formikUrqlErrorFormater = (error: CombinedError, setErrors?: (errors: FormikErrors<any>) => void): string => {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const formErrors: FormikErrors<Record<string, string>> = {};
        error.graphQLErrors.forEach((e) => {
            if ("extensions" in e && "invalidArgs" in e.extensions) {
                const invalidArgs = e.extensions.invalidArgs as any[];
                invalidArgs.forEach((invalidArg) => {
                    formErrors[invalidArg] = e.message;
                });
            }
        });
        if (setErrors) {
            setErrors(formErrors);
        }
    }
    let message = error.toString();
    if (error.networkError) {
        if ("message" in error.networkError) {
            message = error.networkError.message;
        }
    }
    return message;
};
