import React from "react";

import { PeriodReturnsTable, KeyPerformanceIndicators, Language } from "../../../../components/src";

import { SelectedRowType } from "./interfaces";
import { RelativeTimeSeriesChart } from "../../components/RelativeTimeSeriesChart";
import { TimeSeriesChart } from "../../components/TimeSeriesChart";
import { usePerformance } from "./performanceGraphHooks";

interface Props {
    selectedRow: SelectedRowType;
}

export const PerformanceGraphsTables = ({ selectedRow }: Props): React.ReactElement => {
    const { performanceSeries, alignedTimeSeries, years, value } = usePerformance(selectedRow);
    return (
        <>
            <div className="row form-group mt-4 mb-4">
                <div className="col-12 col-md-6">
                    <h4 className="text-center">Performance</h4>
                    <TimeSeriesChart timeseries={alignedTimeSeries} style={{ height: "50vh" }} showXlsxButton={true} />
                </div>
                <div className="col-12 col-md-6">
                    <h4 className="text-center">Relative Performance</h4>
                    <RelativeTimeSeriesChart
                        masterTimeSerie={performanceSeries}
                        timeseries={alignedTimeSeries}
                        style={{ height: "50vh" }}
                        showXlsxButton={true}
                    />
                </div>
            </div>
            <div className="row form-group mt-4">
                <div className="col-12 col-md-6">
                    <h4 className="text-center">Key Performance Indicators (%)</h4>
                    <KeyPerformanceIndicators
                        masterTimeSeriesName={selectedRow.name}
                        timeseries={alignedTimeSeries}
                        navValue={value}
                        riskFreeTimeSeries={selectedRow.riskFree}
                        locale={Language.En}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <h4 className="text-center">Performance (%)</h4>
                    <PeriodReturnsTable timeseries={alignedTimeSeries} ranges={["1M", "3M", "6M", "1Y"]} />
                    <PeriodReturnsTable timeseries={alignedTimeSeries} ranges={years} />
                </div>
            </div>
        </>
    );
};
