import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

import { Language } from "../Language";
import { greenBlueColorway } from ".";

interface ICreditSpreadRisk {
    data: any;
    locale?: Language;
    layout?: Partial<Plotly.Layout>;
    config?: PartialDeep<Plotly.Config>;
    style?: React.CSSProperties | undefined;
}

export const CreditSpreadRisk = ({ data, config, layout, locale = Language.Sv, ...props }: ICreditSpreadRisk): React.ReactElement => {
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let header: string = null;
    let explanation: string = null;
    if (locale === "En") {
        header = "Credit Spread Risk";
        explanation = "The graph shows the effect on fund NAV in % when shifting credit spreads down by 0.01%.";
    } else {
        if (locale === "Sv") {
            header = "Kreditspreadrisk";
            explanation = "Grafen visar effekten på fondens NAV i procent vid en 0,01% skift nedåt av kreditspreadar.";
        }
    }
    let traces = data.data.ratingBuckets.series.map((d, index) => ({
        name: d.name,
        labels: d.values.map((d) => d.maturity.toFixed(0)),
        y: d.values.map((d) => d.value),
        marker: { color: greenBlueColorway[index] },
        type: "bar"
    }));

    if (traces.length === 1) {
        traces = [traces[0], { ...traces[0] }];
        traces[0].y = traces[0].y.map(() => 0);
        traces[0].showlegend = false;
        traces[0].hoverinfo = "skip";
    }

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        barmode: "stack",
        bargap: 0.5,
        xaxis: {
            showticklabels: true,
            ticktext: traces[traces.length - 1].labels,
            tickvals: traces[traces.length - 1].labels.map((d, i) => i)
        },
        // name: traces[traces.length - 1].name,
        yaxis: {
            tickformat: ",.2%",
            hoverformat: ",.4%",
            tick0: 0,
            range: [0, 0.00032],
            dtick: 0.0001,
            gridwidth: 2
        },
        legend: { orientation: "h", y: -0.1, traceorder: "normal" },
        margin: { l: 44, r: 10, t: 4, b: 4 }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            <h3>{header}</h3>
            <Plot data={traces} layout={thisLayout} config={thisConfig} {...props} />
            <br />
            <small>{explanation}</small>
        </React.Fragment>
    );
};
