import { createBrowserHistory } from "history";
import { fetchTimeSeries, oldFetchFundInfo } from "./captorWebApiHelper";
import { greenBlueColorway } from "./greenBlueColorway";
import { RiskInformation } from "./RiskInformation";
import { CreditSpreadRisk } from "./CreditSpreadRisk";
import { ComponentFactFundInfo } from "./FactFundInfo";
import { CreditRating } from "./CreditRating";
import { ReturnPerMonth } from "./ReturnPerMonth";
import { TopIssuers } from "./TopIssuers";
import { TopHoldings } from "./TopHoldings";
import { KeyNumbers } from "./KeyNumbers";
import { InterestRateBuckets } from "./InterestRateBuckets";
import { PerformanceTimeSeriesChart } from "./PerformanceTimeSeriesChart";
import { EsgShare } from "./EsgShare";
import { FundReportText } from "./FundReportText";
import { GreenShare } from "./GreenShare";
import { Sectors } from "./Sectors";
import { Currencies } from "./Currencies";
import { HowToInvest } from "./HowToInvest";
import { SectorSpread } from "./SectorSpread";
import { SectorPie } from "./SectorPie";
import { CurrencyPie } from "./CurrencyPie";
import { ReturnVolScatter } from "./ReturnVolScatter";
import { CountryPie } from "./CountryPie";
import { BarReturns } from "./BarReturns";
import { Attribution } from "./Attribution";
const history = createBrowserHistory();

export {
    fetchTimeSeries,
    oldFetchFundInfo,
    history,
    greenBlueColorway,
    RiskInformation,
    CreditSpreadRisk,
    ComponentFactFundInfo,
    CreditRating,
    ReturnPerMonth,
    TopIssuers,
    TopHoldings,
    KeyNumbers,
    InterestRateBuckets,
    FundReportText,
    GreenShare,
    Sectors,
    Currencies,
    HowToInvest,
    PerformanceTimeSeriesChart,
    EsgShare,
    SectorSpread,
    SectorPie,
    CurrencyPie,
    ReturnVolScatter,
    CountryPie,
    BarReturns,
    Attribution
};
