import { write, utils } from "xlsx";
import { saveAs } from "file-saver";
import { keys, values, reduce } from "lodash";

const portfolioToAoa = (portfolio) =>
    reduce(
        portfolio,
        (acc, p) => {
            acc.push(values(p));
            return acc;
        },
        [keys(portfolio[0])]
    );

const s2ab: any = (s) => {
    if (typeof ArrayBuffer !== "undefined") {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    } else {
        const buf = new Array(s.length);
        for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }
};

export const exportToXlsx = (portfolio, filename = "table.xlsx") => {
    const sheet = utils.aoa_to_sheet(portfolioToAoa(portfolio));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, sheet, "Sheet 1");

    const wbout = write(wb, {
        bookType: "xlsx",
        bookSST: false,
        type: "binary"
    });

    try {
        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
        saveAs(blob, filename);
    } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
    }
    return wbout;
};
