import React, { Fragment, useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { CostFilterInput, CostStatusEnum } from "../../types.generated";
import { SelectColumnFilter, SelectColumnFilterArrayOfIds, SelectColumnFilterType } from "../../components/react-table/ReactTableFilters";
import { ReactTable } from "../../components/react-table/ReactTable";

export const getCosts = gql`
    query getCosts($filter: CostFilterInput) {
        costs(filter: $filter) {
            _id
            clientId
            client {
                _id
                name
            }
            debtorIds
            debtors {
                _id
                name
            }
            description
            amount
            currency
            date
            startDate
            endDate
            createTimestamp
            updateTimestamp
            creatorId
            updateUserId
            updateUserInfo {
                name
            }
            status
            attachments {
                fileId
                fileName
                mimeType
            }
        }
    }
`;

export const CostsPage = (): React.ReactElement => {
    const filter: CostFilterInput = { statusIn: null };
    const [{ fetching: loading, error, data }] = useQuery({
        query: getCosts,
        variables: { filter },
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(() => {
        const partyById: Record<string, { _id: string; name: string }> = {};

        if (data) {
            data.costs.forEach((cost) => {
                cost.debtors.forEach((party) => {
                    if (party && !(party._id in partyById)) {
                        partyById[party._id] = party;
                    }
                });

                if (cost.client && !(cost.client._id in partyById)) {
                    partyById[cost.client._id] = cost.client;
                }
            });
        }

        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/backoffice/costs/${row.original._id}`}>#</Link>;
                },
                size: 20
            },
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                size: 150,
                Cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter
            },
            {
                header: "Debtors",
                accessorKey: "debtorIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    if (partyById[id]) {
                                        return (
                                            <Link className="row" key={id} to={`/parties/${id}`}>
                                                {partyById[id].name}
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(partyById)),
                size: 200
            },
            {
                header: "Description",
                accessorKey: "description",
                size: 200
            },
            {
                header: "Amount",
                accessorKey: "amount",
                size: 75
            },
            {
                header: "Date",
                accessorKey: "date",
                size: 75
            },
            {
                header: "Start date",
                accessorKey: "startDate",
                size: 75
            },
            {
                header: "End date",
                accessorKey: "endDate",
                size: 75
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(CostStatusEnum),
                size: 75
            },
            {
                header: "Attachments",
                id: "attachments.length",
                accessorKey: "attachments.length",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                filter: SelectColumnFilter
            }
        ];
    }, [data]);

    if (loading) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto">
                    <Link to={"/backoffice/costs/new"}>New Cost</Link>
                </div>
            </div>

            <Fragment>
                <p>Number of costs: {data.costs.length}</p>
                <ReactTable columns={columns} data={data.costs} defaultHiddenColumns={[]} />
            </Fragment>
        </div>
    );
};
