export const minDate = "0001-01-01";
export const minimumPosition = 1e-7;
export const emptyObjectId = "000000000000000000000000";
export const tradeTimeZone = "Europe/Stockholm";

export enum RealizedType {
    None = "None",
    Valuation = "Valuation",
    NoIncomeCost = "NoIncomeCost",
    IncomeCost = "IncomeCost",
    SettlementAmount = "SettlementAmount",
    RealizedProfitLoss = "RealizedProfitLoss"
}
