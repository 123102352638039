import React, { useState } from "react";
import { ICommandBarItemProps, Spinner, SpinnerSize } from "@fluentui/react";

import {
    BankAccountBalance,
    BankAccountTransactionInput,
    BankAccountTransactionStatusEnum,
    MatchBankAccountTransactionsInput,
    MatchingStatus,
    Position,
    Transaction,
    TransactionStatus,
    UpdateTransactionInput,
    UpdateTransactionItemInput
} from "../types.generated";
import { YesNoModal } from "../components/YesNoModal";
import { ITransactionDataRow, TransactionsGrid } from "../components/TransactionsGrid";
import { cloneDeep, round, sortBy } from "lodash";
import { Column, Grid } from "../../../components/src";
import { DateHelper, twoDecPriceFormat } from "../../../common/src";

interface IUpdateTransactionsStatusGrid {
    itemsByGroup: Record<string, ITransactionDataRow[]>;
    itemsReconcileByGroup: Record<string, ITransactionDataRow[]>;
    visibleColumns?: string[];
    visibleReconcileColumns?: string[];
    onUpdate(input: UpdateTransactionInput[]): any;
    onUpdateReconcileStatus(input: Partial<BankAccountTransactionInput>[]): any;
    onMatch(input: MatchBankAccountTransactionsInput[]): any;
    header?: string;
    headerReconcile?: string;
    headerGroups?: string[];
    spinner: boolean;
    transactionsByItemId: Record<string, Partial<Transaction>>;
    positionsByInstrumentId: Record<string, Partial<Position>>;
    bankBalancesByInstrumentId: Record<string, Partial<BankAccountBalance>>;
    instrumentId: string;
}

export const BankAccountReconciliationGrid = ({
    itemsByGroup,
    itemsReconcileByGroup,
    visibleColumns,
    visibleReconcileColumns,
    onUpdate,
    onUpdateReconcileStatus,
    onMatch,
    header,
    headerReconcile,
    headerGroups,
    spinner,
    transactionsByItemId,
    positionsByInstrumentId,
    instrumentId,
    bankBalancesByInstrumentId
}: IUpdateTransactionsStatusGrid): React.ReactElement => {
    const [selectedByGroup, setSelectedByGroup] = useState({});
    const [selectedReconcileByGroup, setSelectedReconcileByGroup] = useState({});
    const [modal, setModal] = useState({ showModal: false, payload: { input: [], transactions: true } });
    const [showDataByStatusGroup, setShowDataByStatusGroup] = useState({});
    const [showReconcileDataByStatusGroup, setShowReconcileDataByStatusGroup] = useState({});

    const today = new Date().toISOString().slice(0, 10);

    const selectedChange = (items: any[]) => {
        const newSelectedData: Record<string, ITransactionDataRow[]> = {};
        for (const item of items) {
            let status = item.status;

            if (item.status === TransactionStatus.Settled) {
                status = item.status;
            } else {
                if (item.valueDate > today) {
                    status = "Not settled - future";
                } else {
                    status = "Not settled";
                }
            }

            if (newSelectedData[status]) {
                newSelectedData[status].push(item);
            } else {
                newSelectedData[status] = [item];
            }
        }

        setSelectedByGroup(newSelectedData);
    };

    const selectedChangeReconcile = (reconcileItems: any[]) => {
        const newSelectedData: Record<string, ITransactionDataRow[]> = {};
        for (const reconcileItem of reconcileItems) {
            let status = null;

            if (reconcileItem.status === BankAccountTransactionStatusEnum.Settled) {
                status = reconcileItem.status;
            } else {
                if (reconcileItem.valueDate > today) {
                    status = "Not settled - future";
                } else {
                    status = "Not settled";
                }
            }

            if (newSelectedData[status]) {
                newSelectedData[status].push(reconcileItem);
            } else {
                newSelectedData[status] = [reconcileItem];
            }
        }

        setSelectedReconcileByGroup(newSelectedData);
    };

    let commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change status/valueDate",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Pending };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to Preliminary",
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Preliminary };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Confirmed };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "instructed",
                        text: "Change to Instructed",
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Instructed };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Settled };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "internal",
                        text: "Change to Internal",
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Internal };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "history",
                        text: "Change to History",
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.History };
                                });
                            onUpdate(input);
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            const input = Object.values(selectedByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: TransactionStatus.Deleted };
                                });
                            setModal({
                                showModal: true,
                                payload: { input: input, transactions: true }
                            });
                        }
                    },
                    {
                        key: "oneday",
                        text: "Set valueDate - 1 day",
                        onClick: () => {
                            if (transactionsByItemId && Object.keys(transactionsByItemId).length) {
                                const input = Object.values(selectedByGroup)
                                    .flat(1)
                                    .map((balanceItem: any) => {
                                        const transaction = transactionsByItemId[balanceItem.transactionItemId];

                                        const previousDay = DateHelper.dateAddDays(new Date(transaction.items[0].valueDate), -1);
                                        const previousDayString = DateHelper.dateToString(previousDay).substring(0, 10);
                                        const newItems: UpdateTransactionItemInput[] = [];

                                        for (const item of transaction.items) {
                                            const newItem = cloneDeep(item);
                                            delete newItem.__typename;
                                            newItem.valueDate = previousDayString;
                                            newItems.push(newItem);
                                        }
                                        return { _id: transaction._id, items: newItems };
                                    });
                                onUpdate(input);
                            }

                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "twodays",
                        text: "Set valueDate - 2 days",
                        onClick: () => {
                            if (transactionsByItemId && Object.keys(transactionsByItemId).length) {
                                const input = Object.values(selectedByGroup)
                                    .flat(1)
                                    .map((balanceItem: any) => {
                                        const transaction = transactionsByItemId[balanceItem.transactionItemId];
                                        const previousPreviousDay = DateHelper.dateAddDays(new Date(transaction.items[0].valueDate), -2);
                                        const previousPreviousDayString = DateHelper.dateToString(previousPreviousDay).substring(0, 10);
                                        const newItems: UpdateTransactionItemInput[] = [];

                                        for (const item of transaction.items) {
                                            const newItem = cloneDeep(item);
                                            delete newItem.__typename;
                                            newItem.valueDate = previousPreviousDayString;
                                            newItems.push(newItem);
                                        }
                                        return { _id: transaction._id, items: newItems };
                                    });
                                onUpdate(input);
                            }
                            setSelectedByGroup({});
                        }
                    },
                    {
                        key: "threedays",
                        text: "Set valueDate - 3 days",
                        onClick: () => {
                            if (transactionsByItemId && Object.keys(transactionsByItemId).length) {
                                const input = Object.values(selectedByGroup)
                                    .flat(1)
                                    .map((balanceItem: any) => {
                                        const transaction = transactionsByItemId[balanceItem.transactionItemId];
                                        const thirdPreviousDay = DateHelper.dateAddDays(new Date(transaction.items[0].valueDate), -3);
                                        const thirdPreviousDayString = DateHelper.dateToString(thirdPreviousDay).substring(0, 10);
                                        const newItems: UpdateTransactionItemInput[] = [];

                                        for (const item of transaction.items) {
                                            const newItem = cloneDeep(item);
                                            delete newItem.__typename;
                                            newItem.valueDate = thirdPreviousDayString;
                                            newItems.push(newItem);
                                        }
                                        return { _id: transaction._id, items: newItems };
                                    });
                                onUpdate(input);
                            }
                            setSelectedByGroup({});
                        }
                    }
                ]
            }
        }
    ];

    let commandsReconcile: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            const input = Object.values(selectedReconcileByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: BankAccountTransactionStatusEnum.Pending };
                                });
                            onUpdateReconcileStatus(input);
                            setSelectedReconcileByGroup({});
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            const input = Object.values(selectedReconcileByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: BankAccountTransactionStatusEnum.Settled };
                                });
                            onUpdateReconcileStatus(input);
                            setSelectedReconcileByGroup({});
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            const input = Object.values(selectedReconcileByGroup)
                                .flat(1)
                                .map((t: any) => {
                                    return { _id: t._id, status: BankAccountTransactionStatusEnum.Deleted };
                                });
                            setModal({
                                showModal: true,
                                payload: { input: input, transactions: false }
                            });
                        }
                    }
                ]
            }
        }
    ];

    // Only show below if not multiple of each type selected + none have status Settled
    if (
        Object.keys(selectedByGroup) &&
        Object.keys(selectedReconcileByGroup) &&
        ((Object.values(selectedByGroup).flat(1).length === 1 && Object.values(selectedReconcileByGroup).flat(1).length >= 1) ||
            (Object.values(selectedReconcileByGroup).flat(1).length === 1 && Object.values(selectedByGroup).flat(1).length >= 1))
    ) {
        const selected = Object.values(selectedByGroup).flat(1) as ICommandBarItemProps[];
        const selectedReconcile = Object.values(selectedReconcileByGroup).flat(1) as ICommandBarItemProps[];
        let matchInput: MatchBankAccountTransactionsInput[] = [];
        if (selected.length === 1) {
            const reconcileIds: string[] = [];
            let reconcileItemSettledFound = false;
            for (const reconcileItem of selectedReconcile) {
                reconcileIds.push(reconcileItem._id);
                if (reconcileItem.status === BankAccountTransactionStatusEnum.Settled) {
                    reconcileItemSettledFound = true;
                }
            }

            if (selected[0].status !== TransactionStatus.Settled && !reconcileItemSettledFound) {
                matchInput = [
                    {
                        bankAccountTransactionIds: selectedReconcile.map((transaction) => {
                            return transaction._id;
                        }),
                        bankAccountTransactionError: null,
                        transactionIdentifierIds: [{ transactionId: selected[0]._id, transactionItemId: selected[0].transactionItemId }],
                        transactionItemError: "",
                        matchingStatus: MatchingStatus.Confirmed
                    }
                ];
            }
        } else {
            const identifierIds: { transactionId: string; transactionItemId: string }[] = [];
            let itemSettledFound = false;
            for (const item of selected) {
                identifierIds.push({ transactionId: item._id, transactionItemId: item.transactionItemId });
                if (item.status === TransactionStatus.Settled) {
                    itemSettledFound = true;
                }
            }
            if (selectedReconcile[0].status !== BankAccountTransactionStatusEnum.Settled && !itemSettledFound) {
                matchInput = [
                    {
                        bankAccountTransactionIds: [selectedReconcile[0]._id],
                        bankAccountTransactionError: null,
                        transactionIdentifierIds: identifierIds,
                        transactionItemError: "",
                        matchingStatus: MatchingStatus.Confirmed
                    }
                ];
            }
        }

        if (matchInput.length) {
            commands = [
                {
                    key: "matchTransactionMenuItem",
                    text: "Match",
                    onClick: () => {
                        onMatch(matchInput);
                        setSelectedByGroup({});
                        setSelectedReconcileByGroup({});
                    }
                }
            ];

            commandsReconcile = [
                {
                    key: "matchTransactionMenuItem",
                    text: "Match",
                    onClick: () => {
                        onMatch(matchInput);
                        setSelectedByGroup({});
                        setSelectedReconcileByGroup({});
                    }
                }
            ];
        } else {
            // cannot update status for transactions + bank account transactions at the same time
            commands = [];
            commandsReconcile = [];
        }
    }

    const settledBalance =
        positionsByInstrumentId && positionsByInstrumentId[instrumentId] ? positionsByInstrumentId[instrumentId].amount : 0;
    const settledBankBalance =
        bankBalancesByInstrumentId && bankBalancesByInstrumentId[instrumentId]
            ? parseFloat(bankBalancesByInstrumentId[instrumentId].balance)
            : 0;

    const balanceCheck = [
        {
            settledBalance: settledBalance,
            settledBankBalance: settledBankBalance,
            diff: settledBalance - settledBankBalance
        }
    ];

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete selected item(s)?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: { input: modal.payload.input, transactions: modal.payload.transactions }
                    }}
                    setModal={setModal}
                    onYes={() => {
                        if (modal.payload.transactions) {
                            onUpdate(modal.payload.input as unknown as UpdateTransactionInput[]);
                            setSelectedByGroup({});
                        } else {
                            onUpdateReconcileStatus(modal.payload.input as unknown as Partial<BankAccountTransactionInput>[]);
                            setSelectedReconcileByGroup({});
                        }
                    }}
                    onNo={() => {
                        // disable eslint
                    }}
                />
            ) : null}
            <div className={"row justify-content-center mt-4"}>
                <div className="col-12 col-xl-3 col-l-3 col-m-3">
                    <div>
                        <Grid data={balanceCheck} sortable tableClassName="table-xs" hideDownload={true}>
                            <Column className="nowrap" format={twoDecPriceFormat} field="settledBalance" title="Settled balance"></Column>
                            <Column
                                className="nowrap"
                                format={twoDecPriceFormat}
                                field="settledBankBalance"
                                title="Settled bank balance"
                            ></Column>
                            <Column
                                field="diff"
                                format={twoDecPriceFormat}
                                className={(d) => {
                                    if (d && d.diff && Math.abs(round(d.diff, 2)) !== 0) {
                                        return "right nowrap btn-sm btn-danger text-white";
                                    } else {
                                        return "right nowrap ";
                                    }
                                }}
                            ></Column>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="row-col mt-4">
                <div className="row center">
                    <div className="col-6 ">
                        <h2>{header}</h2>
                    </div>

                    <div className="col-6">
                        <h2>{headerReconcile}</h2>
                    </div>
                </div>
                <div className="row-col">
                    {spinner ? <Spinner label="Updating..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.medium} /> : null}
                </div>
                {headerGroups.map((statusGroup) => (
                    <div key={statusGroup} className="mt-4 mb-4">
                        <div className="row-col center">
                            <h4>{statusGroup}</h4>
                        </div>
                        <>
                            <div className="row mt-2">
                                <div className="col-6">
                                    {itemsByGroup && Object.keys(itemsByGroup) && itemsByGroup[statusGroup] ? (
                                        <div>
                                            <div>
                                                {showDataByStatusGroup && showDataByStatusGroup[statusGroup] ? (
                                                    <div>
                                                        <TransactionsGrid
                                                            key={`${JSON.stringify(itemsByGroup[statusGroup])}-${location.search}-${showDataByStatusGroup[statusGroup]}`}
                                                            items={itemsByGroup[statusGroup]}
                                                            onSelectedChange={selectedChange}
                                                            canSelect={true}
                                                            commands={selectedByGroup[statusGroup] ? commands : []}
                                                            visibleColumns={visibleColumns}
                                                            showExcelButton={false}
                                                        />
                                                        <span
                                                            className="glyphicon glyphicon-minus mt-5"
                                                            onClick={() => {
                                                                const newShowDataByStatusGroup = cloneDeep(showDataByStatusGroup);
                                                                newShowDataByStatusGroup[statusGroup] = false;
                                                                setShowDataByStatusGroup(newShowDataByStatusGroup);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <TransactionsGrid
                                                            key={`${JSON.stringify(itemsByGroup[statusGroup])}-${location.search}`}
                                                            items={itemsByGroup[statusGroup].slice(0, 16)}
                                                            onSelectedChange={selectedChange}
                                                            canSelect={true}
                                                            commands={selectedByGroup[statusGroup] ? commands : []}
                                                            visibleColumns={visibleColumns}
                                                            showExcelButton={false}
                                                        />
                                                        {itemsByGroup[statusGroup].length > 15 ? (
                                                            <span
                                                                className="glyphicon glyphicon-plus mt-5"
                                                                onClick={() => {
                                                                    const newShowDataByStatusGroup = cloneDeep(showDataByStatusGroup);
                                                                    newShowDataByStatusGroup[statusGroup] = true;
                                                                    setShowDataByStatusGroup(newShowDataByStatusGroup);
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-6">
                                    {itemsReconcileByGroup && Object.keys(itemsReconcileByGroup) && itemsReconcileByGroup[statusGroup] ? (
                                        <div>
                                            <div>
                                                {showReconcileDataByStatusGroup && showReconcileDataByStatusGroup[statusGroup] ? (
                                                    <div>
                                                        <TransactionsGrid
                                                            key={`${JSON.stringify(sortBy(itemsReconcileByGroup[statusGroup], "valueDate").reverse())}-${
                                                                location.search
                                                            }-${showReconcileDataByStatusGroup[statusGroup]}`}
                                                            items={sortBy(itemsReconcileByGroup[statusGroup], "valueDate").reverse()}
                                                            onSelectedChange={selectedChangeReconcile}
                                                            canSelect={true}
                                                            commands={selectedReconcileByGroup[statusGroup] ? commandsReconcile : []}
                                                            visibleColumns={visibleReconcileColumns}
                                                            showExcelButton={false}
                                                        />
                                                        <span
                                                            className="glyphicon glyphicon-minus mt-5"
                                                            onClick={() => {
                                                                const newShowReconcileDataByStatusGroup =
                                                                    cloneDeep(showReconcileDataByStatusGroup);
                                                                newShowReconcileDataByStatusGroup[statusGroup] = false;
                                                                setShowReconcileDataByStatusGroup(newShowReconcileDataByStatusGroup);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <TransactionsGrid
                                                            key={`${JSON.stringify(sortBy(itemsReconcileByGroup[statusGroup], "valueDate").reverse())}-${
                                                                location.search
                                                            }`}
                                                            items={sortBy(itemsReconcileByGroup[statusGroup], "valueDate")
                                                                .reverse()
                                                                .slice(0, 16)}
                                                            onSelectedChange={selectedChangeReconcile}
                                                            canSelect={true}
                                                            commands={selectedReconcileByGroup[statusGroup] ? commandsReconcile : []}
                                                            visibleColumns={visibleReconcileColumns}
                                                            showExcelButton={false}
                                                        />
                                                        {itemsReconcileByGroup[statusGroup].length > 15 ? (
                                                            <span
                                                                className="glyphicon glyphicon-plus mt-5"
                                                                onClick={() => {
                                                                    const newShowReconcileDataByStatusGroup =
                                                                        cloneDeep(showReconcileDataByStatusGroup);
                                                                    newShowReconcileDataByStatusGroup[statusGroup] = true;
                                                                    setShowReconcileDataByStatusGroup(newShowReconcileDataByStatusGroup);
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    </div>
                ))}
            </div>
        </div>
    );
};
