import React, { useState, useEffect, ReactElement } from "react";
import { cloneDeep } from "lodash";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Dropdown, Alert } from "react-bootstrap";

import { YesNoModal } from "../../../components/YesNoModal";
import { Grid, Column } from "../../../../../components/src";
import { useGetPartyQuery, useDeletePartyExternalAccountMutation } from "../PartyQuery.generated";
import { externalAccountTypes, NewExternalAccountButton, ExternalAccountForm } from "../ExternalAccountForm";

interface PartyExternalAccountsTabPropsType {
    partyId: string;
}

export const PartyExternalAccountsTab = ({ partyId }: PartyExternalAccountsTabPropsType): ReactElement => {
    const navigate = useNavigate();
    const { itemId } = useParams<"itemId">();

    const [currentParty, setCurrentParty] = useState(cloneDeep(null));
    const [alert, setAlert] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });

    const [_, deletePartyExternalAccount] = useDeletePartyExternalAccountMutation();

    useEffect(() => {
        if (data) {
            setCurrentParty(cloneDeep(data.party));
        }
    }, [data]);

    const selectExternalAccount = (accountId): void => {
        navigate(`/parties/${partyId}/externalAccounts/${accountId}`, { replace: true });
    };

    if (loading) return <div>Loading</div>;
    if (error) return <div>{"Error: " + error.message}</div>;
    if (!currentParty) return <div></div>;

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete external account with id " + modal.payload.externalAccountId + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deletePartyExternalAccount({ partyId: modal.payload.partyId, externalAccountId: modal.payload.externalAccountId })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    setAlert(error.graphQLErrors[0].message);
                                } else {
                                    refetch();
                                    navigate(`/parties/${partyId}/externalAccounts`);
                                }
                            })
                            .catch((error) => {
                                setAlert(error.graphQLErrors[0].message);
                            });
                    }}
                />
            ) : null}

            <div id="partyexternalaccountsform" className="partyexternalaccountsform form">
                <div className="form-row mb-3">
                    External account represents a netting-set like a securities external account or an ISDA agreement.
                </div>
                <div className="form-row mb-3 d-flex">
                    <div className="ms-auto">
                        <NewExternalAccountButton page={`parties/${partyId}/externalAccounts`} />
                    </div>
                </div>
                {alert ? (
                    <div className="row">
                        <div className="col-12">
                            <Alert style={{ marginTop: "10px" }} variant="danger" onClose={() => setAlert(null)} dismissible>
                                <p>{alert}</p>
                            </Alert>
                        </div>
                    </div>
                ) : null}
                <div className="row">
                    <div className={itemId ? "col-sm-12 col-lg-8" : "col"}>
                        <Grid
                            key={`ca-${currentParty.externalAccounts.length}`}
                            data={currentParty.externalAccounts}
                            sortable
                            tableClassName="table-xs"
                        >
                            <Column
                                field="_id"
                                title="ID"
                                format={(field) => {
                                    return (
                                        <a
                                            href="#selectExternalAccount"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                selectExternalAccount(field);
                                            }}
                                        >
                                            {field}
                                        </a>
                                    );
                                }}
                            />
                            <Column field="name" className="grid-column-sticky" />
                            <Column
                                field="_id"
                                title=""
                                format={(value) => {
                                    return (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="danger" size="sm">
                                                Delete
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={async () => {
                                                        setModal({
                                                            showModal: true,
                                                            payload: { externalAccountId: value, partyId: partyId }
                                                        });
                                                    }}
                                                >
                                                    Delete external account
                                                </Dropdown.Item>
                                                <Dropdown.Item>Cancel</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Grid>
                    </div>

                    {itemId ? (
                        <div className="col-sm-12 col-lg-4">
                            <Link className="close btn-close float-end" to={`/parties/${partyId}/externalAccounts`} />
                            <ExternalAccountForm
                                key={itemId}
                                id={itemId.startsWith("new") ? null : itemId}
                                cid={partyId}
                                type={
                                    itemId.startsWith("new")
                                        ? externalAccountTypes.find((d) => d.toLowerCase() === itemId.substring(3, itemId.length))
                                        : null
                                }
                                onCreate={() => {
                                    refetch();
                                }}
                                onUpdate={() => {
                                    refetch();
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
