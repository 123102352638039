import React, { Fragment } from "react";
import { Column, Grid } from "../../../../components/src";
import { gql, useQuery } from "urql";
import { groupBy } from "lodash";
import { numberFormatFun } from "../../../../common/src";

const GetFunds = gql`
    query funds($startDate: GraphQLDateString) {
        parties(filter: { typeIn: [Fund], statusIn: [Confirmed] }) {
            _id
            name
        }
        transactionItems(filter: { itemTypeIn: [ManagementCost], startDate: $startDate }) {
            clientId
            amount
            valueDate
        }
    }
`;

const getStartDate = (): string => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - MAX_COLUMNS);
    return startDate.toISOString().slice(0, 10);
};

const moneyFormat = numberFormatFun("# ##0.00");

const MAX_COLUMNS: number = 3;

export const MonthlyCostsPage = (): React.ReactElement => {
    const startDate = getStartDate();

    const [{ fetching, error, data }] = useQuery({
        query: GetFunds,
        variables: { startDate },
        requestPolicy: "cache-and-network"
    });

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const itemsByValueDate = groupBy(data.transactionItems, (i) => i.valueDate);

    const columns: string[] = Object.keys(itemsByValueDate)
        .sort((a, b) => (a > b ? -1 : 0))
        .slice(0, MAX_COLUMNS);

    const gridColumns = [
        { name: "name", title: "Fund", className: "" },
        ...columns.map((c) => {
            return { name: c, title: c, className: "right" };
        })
    ];

    const gridData = data.parties.map((fund) => {
        const item = {
            name: fund.name
        };
        columns.forEach((column) => {
            const sum = itemsByValueDate[column]
                .filter((x) => x.clientId == fund._id)
                .reduce((acc, currentValue) => acc + currentValue.amount, 0);
            item[column] = moneyFormat(sum);
        });
        return item;
    });

    return (
        <Fragment>
            <Grid header="Costs - Monthly" data={gridData} sortable tableClassName="table-xs">
                {gridColumns.map((col, i) => (
                    <Column className={`nowrap ${col.className}`} key={i} field={col.name} title={col.title} />
                ))}
            </Grid>
        </Fragment>
    );
};
