import { cloneDeep, round } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {
    BrokerTransaction,
    BrokerTransactionType,
    CollectionNameEnum,
    MatchingStatus,
    MatchTransactionsInput,
    MiniTransaction,
    RoundTransactionInput,
    SourceType,
    TicketInput,
    TicketReferenceInput,
    TicketStatusEnum,
    TicketTypeEnum
} from "../types.generated";

dayjs.extend(utc);
dayjs.extend(timezone);

const matchToleranceValue = (firstValue: number, secondValue: number, tolerance: number): boolean => {
    // Matching values with given tolerance
    if (firstValue === secondValue || Math.abs(round(firstValue - secondValue, 2)) <= tolerance) {
        return true;
    } else {
        return false;
    }
};

const createTicketInput = (errorMessage: string, clientId: string, transactionId?: string, brokerTransactionId?: string): TicketInput => {
    // Creating tickets for operations team
    // TODO: remove current ids when operations is attached to User somehow...
    const references: TicketReferenceInput[] = [];
    let comment = "";
    if (transactionId) {
        references.push({ collection: CollectionNameEnum.Transaction, documentId: transactionId });
        comment = comment + "[Transaction](https://portal.qipple.com/reconcile/brokertransactions/minitransaction/" + transactionId + "). ";
    }
    if (brokerTransactionId) {
        references.push({ collection: CollectionNameEnum.BrokerTransaction, documentId: brokerTransactionId });
        comment =
            comment +
            "[Broker transaction](https://portal.qipple.com/reconcile/brokertransactions/brokertransaction/" +
            brokerTransactionId +
            "). ";
    }
    return {
        title: errorMessage,
        clientId: clientId,
        type: TicketTypeEnum.MatchBrokerTransactionError,
        references: references,
        state: {
            status: TicketStatusEnum.Open,
            assigneeIds: ["64a5176b9b966a71b8f0c805", "60f6cdb3b9bfeb0012c74344"],
            comment: comment
        }
    };
};

// Matching booked transactions with the counterparty's transactions
export const matchTransactionsFnc = (
    brokerTransactions: BrokerTransaction[],
    clientTransactions: MiniTransaction[]
): { updateFieldsList: MatchTransactionsInput[]; roundTransactionsList: RoundTransactionInput[]; ticketsList: TicketInput[] } => {
    const counterpartyTransactions = cloneDeep(brokerTransactions);
    const transactions = cloneDeep(clientTransactions);
    const updateFieldsList: MatchTransactionsInput[] = [];
    const roundTransactionsList: RoundTransactionInput[] = [];
    const ticketsList: TicketInput[] = [];
    const todaysDate = new Date().toISOString().slice(0, 10);
    for (let c = 0; c < transactions.length; c++) {
        const transaction = transactions[c];
        let matchingStatus = MatchingStatus.Mismatch;
        if (transaction.source !== SourceType.Coacs) {
            for (let b = 0; b < counterpartyTransactions.length; b++) {
                const counterpartyTransaction = counterpartyTransactions[b];
                const counterpartyQuantity =
                    counterpartyTransaction.type === BrokerTransactionType.Sell
                        ? -Math.abs(counterpartyTransaction.quantity)
                        : Math.abs(counterpartyTransaction.quantity);
                const counterpartySettlementAmount =
                    counterpartyTransaction.type === BrokerTransactionType.Sell
                        ? Math.abs(counterpartyTransaction.settlementAmount)
                        : -Math.abs(counterpartyTransaction.settlementAmount);

                if (
                    counterpartyTransaction.instrument &&
                    counterpartyTransaction.client &&
                    counterpartyTransaction.broker &&
                    transaction.instrumentId.toString() === counterpartyTransaction.instrument._id.toString() &&
                    transaction.clientId.toString() === counterpartyTransaction.client._id.toString() &&
                    transaction.brokerId.toString() === counterpartyTransaction.broker._id.toString() &&
                    transaction.quantity === counterpartyQuantity
                ) {
                    matchingStatus = MatchingStatus.Confirmed;
                    // Removing old errors
                    //counterpartyTransaction.error = null;
                    //transaction.error = null;
                    let newError: string;

                    if (transaction.currency !== counterpartyTransaction.currency) {
                        newError = newError ? newError + ", currency does not match" : "Currency does not match";
                        matchingStatus = MatchingStatus.Matched;
                    }

                    if (
                        dayjs.tz(transaction.tradeTimestamp, "Europe/Stockholm").format("YYYY-MM-DD") !== counterpartyTransaction.tradeDate
                    ) {
                        newError = newError ? newError + ", trade date does not match" : "Trade date does not match";

                        matchingStatus = MatchingStatus.Matched;
                    }

                    if (transaction.valueDate !== counterpartyTransaction.valueDate) {
                        newError = newError ? newError + ", value date does not match" : "Value date does not match";
                        matchingStatus = MatchingStatus.Matched;
                    }

                    // Check if settlement amount match or not
                    if (
                        !transaction.settlementAmount ||
                        !counterpartyTransaction.settlementAmount ||
                        !matchToleranceValue(Math.abs(transaction.settlementAmount), Math.abs(counterpartySettlementAmount), 0)
                    ) {
                        // Check if tranasctions match and generate error messages/confirming

                        // Price should be an exact match
                        if (Math.abs(transaction.price - counterpartyTransaction.price) !== 0) {
                            newError = newError ? newError + ", price does not match" : "Price does not match";
                            matchingStatus = MatchingStatus.Matched;
                            matchingStatus = MatchingStatus.Matched;
                        }

                        if (
                            (transaction.commission ||
                                counterpartyTransaction.commission ||
                                (transaction.commission && counterpartyTransaction.commission)) &&
                            !matchToleranceValue(transaction.commission, -counterpartyTransaction.commission, 0)
                        ) {
                            newError = newError ? newError + ", commission does not match" : "Commission does not match";
                            matchingStatus = MatchingStatus.Matched;
                        }

                        if (
                            (transaction.stampDuty ||
                                counterpartyTransaction.stampDuty ||
                                (transaction.stampDuty && counterpartyTransaction.stampDuty)) &&
                            !matchToleranceValue(transaction.stampDuty, -counterpartyTransaction.stampDuty, 0)
                        ) {
                            newError = newError ? newError + ", stampDuty does not match" : "StampDuty does not match";
                            matchingStatus = MatchingStatus.Matched;
                        }

                        if (
                            !newError &&
                            transaction.settlementAmount &&
                            counterpartyTransaction.settlementAmount &&
                            !matchToleranceValue(Math.abs(transaction.settlementAmount), Math.abs(counterpartySettlementAmount), 0)
                        ) {
                            const rounding = round(counterpartySettlementAmount - transaction.settlementAmount, 2);
                            roundTransactionsList.push({
                                _id: transaction._id,
                                rounding: rounding
                            });
                        }
                    }
                    // Update status, transactions, correspondingTransactionId, brokerTradeId, error  when matched/unmatched
                    const updateFields: any = {};
                    if (matchingStatus === MatchingStatus.Confirmed) {
                        updateFields.transactionId = transaction._id;
                        updateFields.brokerTransactionId = counterpartyTransaction._id;
                        updateFields.brokerExternalId = counterpartyTransaction.externalId;
                        updateFields.matchingStatus = matchingStatus;
                        updateFieldsList.push(updateFields);

                        // To know which brokerTransactions are matched
                        counterpartyTransaction.correspondingTransactionId = transaction._id;
                    } else {
                        // Only update if new information
                        const correspondingTransactionIdString = counterpartyTransaction.correspondingTransactionId
                            ? counterpartyTransaction.correspondingTransactionId.toString()
                            : null;
                        if (
                            transaction.error !== newError ||
                            counterpartyTransaction.error !== newError ||
                            transaction.brokerTradeId !== counterpartyTransaction.externalId ||
                            correspondingTransactionIdString !== transaction._id
                        ) {
                            updateFields.transactionError = newError;
                            updateFields.brokerTransactionError = newError;
                            updateFields.transactionId = transaction._id;
                            updateFields.brokerTransactionId = counterpartyTransaction._id;
                            updateFields.brokerExternalId = counterpartyTransaction.externalId;
                            updateFields.matchingStatus = matchingStatus;
                            updateFieldsList.push(updateFields);
                        }

                        // To know which brokerTransactions are matched/confirmed
                        counterpartyTransaction.correspondingTransactionId = transaction._id;
                    }

                    // Creating ticket for matched transaction, only if new error
                    if (newError && matchingStatus === MatchingStatus.Matched && counterpartyTransaction.error !== newError) {
                        ticketsList.push(
                            createTicketInput(
                                newError,
                                counterpartyTransaction.clientId
                                    ? counterpartyTransaction.clientId
                                    : counterpartyTransaction.client
                                      ? counterpartyTransaction.client._id
                                      : null,
                                counterpartyTransaction.correspondingTransactionId
                                    ? counterpartyTransaction.correspondingTransactionId
                                    : null,
                                counterpartyTransaction._id
                            )
                        );
                    }
                }
            }
            if (matchingStatus === MatchingStatus.Mismatch && transaction.error !== "No matching broker transaction found.") {
                // No matching broker transaction found
                const noMatchError = "No matching broker transaction found.";
                const updateFields: MatchTransactionsInput = {};
                updateFields.transactionError = noMatchError;
                updateFields.transactionId = transaction._id;
                updateFields.matchingStatus = matchingStatus;
                updateFieldsList.push(updateFields);

                // Only creating tickets for unmatched transactions on the settlement day, wait as long as possible for brokerTransactions
                if (todaysDate >= transaction.valueDate) {
                    ticketsList.push(
                        createTicketInput(
                            noMatchError,
                            transaction.clientId ? transaction.clientId : transaction.client ? transaction.client._id : null,
                            transaction._id
                        )
                    );
                }
            }
        }
    }

    // Add error message to broker transaction if no matching transaction
    for (let c = 0; c < counterpartyTransactions.length; c++) {
        const counterpartyTransaction = counterpartyTransactions[c];
        if (!counterpartyTransaction.correspondingTransactionId && counterpartyTransaction.error !== "No matching transaction found.") {
            const updateFields: MatchTransactionsInput = {};
            const noMatchError = "No matching transaction found.";
            updateFields.brokerTransactionError = noMatchError;
            updateFields.brokerTransactionId = counterpartyTransaction._id;
            updateFields.matchingStatus = MatchingStatus.Mismatch;
            updateFieldsList.push(updateFields);

            // Only creating tickets for unmatched transactions on the settlement day, make sure we have as much time as possible to book
            if (todaysDate >= counterpartyTransaction.valueDate) {
                ticketsList.push(
                    createTicketInput(
                        noMatchError,
                        counterpartyTransaction.clientId
                            ? counterpartyTransaction.clientId
                            : counterpartyTransaction.client
                              ? counterpartyTransaction.client._id
                              : null,
                        null,
                        counterpartyTransaction._id
                    )
                );
            }
        }
    }
    //Return update for transaciton-brokerTransaction
    return { updateFieldsList: updateFieldsList, roundTransactionsList: roundTransactionsList, ticketsList: ticketsList };
};
