import React from "react";
import { gql, useQuery } from "urql";
import save from "save-file";
import { useParams } from "react-router";
//import saveAs from "file-saver";

const GET_ATTACHMENT = gql`
    query getAttachment($_id: GraphQLObjectId!) {
        attachment(_id: $_id) {
            fileId
            fileName
            base64
            mimeType
        }
    }
`;

export const DownloadFile = (): any => {
    const params: any = useParams();
    const { fileId } = params;

    const [{ data }] = useQuery({ query: GET_ATTACHMENT, variables: { _id: fileId }, requestPolicy: "network-only" });

    if (!data) {
        return;
    }

    const dataURI = "data:" + data.attachment.mimeType + ";base64," + data.attachment.base64;
    save(dataURI, data.attachment.fileName);
    window.close();
};
