import React, { Fragment } from "react";

interface ColumnI {
    field: string;
    title?: string | React.ReactElement;
    format?: (arg1: any, arg2?: any, arg3?: any) => void | number | string | React.ReactElement;
    className?: string | ((arg: any) => void);
    topTitle?: string;
    topTitleColspan?: number | ((arg: any) => void);
    onClick?: (e: any, i?: any) => void;
}

export const Column = ({}: ColumnI): React.ReactElement => {
    return <Fragment />;
};
