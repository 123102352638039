import React, { useEffect, useRef, useState } from "react";

interface ICopyToClipboardButton {
    id: string;
    clipboard: any;
    className?: string;
    onClick: () => void;
}

export const CopyToClipboardButton = ({ id, clipboard, className, onClick }: ICopyToClipboardButton): React.ReactElement => {
    const target = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        if (clipboard) {
            if (window["clipboardData"] && window["clipboardData"].setData) {
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                }, 2000);
                window["clipboardData"].setData("Text", clipboard);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                const textarea = document.createElement("textarea");
                textarea.textContent = clipboard;
                textarea.style.position = "fixed";
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand("copy");
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    false;
                } finally {
                    setTooltipOpen(true);
                    setTimeout(() => {
                        setTooltipOpen(false);
                    }, 2000);
                    document.body.removeChild(textarea);
                }
            }
        }
    }, [clipboard]);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={"copy-clipboard-button " + (className ? className : "")}>
            {tooltipOpen && <span className="tooltiptext">Copied!</span>}
            <button
                id={id}
                ref={target}
                type="button"
                className="btn btn-light btn-sm pt-0"
                title="Copy to Clipboard"
                onClick={handleClick}
            >
                <svg
                    className="bi bi-clipboard"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    />
                    <path
                        fillRule="evenodd"
                        d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    />
                </svg>
            </button>
        </div>
    );
};
