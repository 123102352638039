import React, { Fragment } from "react";
import { gql, useQuery } from "urql";
import { Grid, Column } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";
import {
    AgreementType,
    InstrumentModelTypeEnum,
    CurrencyEnum,
    PartyExternalAccountType,
    IssuerTypeEnum,
    TradingManagerColumn
} from "../../types.generated";
import { Link } from "react-router-dom";

const caTypeCollateral: string[] = Object.values(AgreementType).sort();
const securityModels = [InstrumentModelTypeEnum.Bond, InstrumentModelTypeEnum.Stock];
const cashModels = [InstrumentModelTypeEnum.Balance];
const noImTypes = [PartyExternalAccountType.Gmsla, PartyExternalAccountType.Isda];

const getTradingmanager = gql`
    query getTradingmanager($clientIds: [GraphQLObjectId!], $endDate: GraphQLDateString) {
        tradingmanager(
            filterZeroPositions: true
            filter: { endDate: $endDate, clientIds: $clientIds }
            lookThrough: false
            groupPositionsBy: ExternalAccountId
        ) {
            name
            instrumentId
            quantity
            currency
            exposure
            issuerId
            issuerName
            issuerType
            externalAccountId
            externalAccountName
            externalAccountType
            baseCurrency
            instrument {
                modelType
                creditRating
                isin
            }
            valuationDate
        }
    }
`;

interface CollateralViewItem {
    type: string;
    externalAccountName: string;
    exposure: string;
    posted: number;
    netExposure: number;
    currency: CurrencyEnum;
}

// Temporary function until end point can handle split of initial margin bookings
const groupNettingSets = (securities: TradingManagerColumn[], cash: TradingManagerColumn[], derivatives: TradingManagerColumn[]) => {
    const sumNettingSets = function (positions, externalAccountName_sum) {
        return positions
            .filter(({ externalAccountName }) => externalAccountName === externalAccountName_sum)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };
    const nettingSetsGrouped: CollateralViewItem[] = [];
    const secCash = securities.concat(cash);
    const derivCash = derivatives.concat(cash);
    const positions = secCash.concat(derivatives);
    for (let i = 0; i < positions.length; i++) {
        if (!nettingSetsGrouped.map((a) => a.externalAccountName).includes(positions[i].externalAccountName)) {
            if (caTypeCollateral.includes(positions[i].externalAccountType)) {
                if (noImTypes.includes(positions[i].externalAccountType)) {
                    nettingSetsGrouped.push({
                        type: positions[i].externalAccountType,
                        externalAccountName: positions[i].externalAccountName,
                        exposure: sumNettingSets(derivatives, positions[i].externalAccountName),
                        posted: sumNettingSets(secCash, positions[i].externalAccountName),
                        netExposure: sumNettingSets(positions, positions[i].externalAccountName),
                        currency: positions[i].baseCurrency
                    });
                } else {
                    nettingSetsGrouped.push({
                        type: "Cash/VM/Derivative",
                        externalAccountName: positions[i].externalAccountName,
                        exposure: sumNettingSets(derivatives, positions[i].externalAccountName),
                        posted: sumNettingSets(cash, positions[i].externalAccountName),
                        netExposure: sumNettingSets(derivCash, positions[i].externalAccountName),
                        currency: positions[i].baseCurrency
                    });
                    nettingSetsGrouped.push({
                        type: "Asset/IM",
                        externalAccountName: positions[i].externalAccountName,
                        exposure: "",
                        posted: sumNettingSets(securities, positions[i].externalAccountName),
                        netExposure: sumNettingSets(securities, positions[i].externalAccountName),
                        currency: positions[i].baseCurrency
                    });
                }
            }
        }
    }
    return nettingSetsGrouped;
};

// Temporary function until end point for collateral position is in place
const collateralFilter = (positions: TradingManagerColumn[]) => {
    const collateralSecurities: TradingManagerColumn[] = [];
    const collateralCash: TradingManagerColumn[] = [];
    const collateralDerivatives: TradingManagerColumn[] = [];
    const securitesAccountPositions: TradingManagerColumn[] = [];
    for (let i = 0; i < positions.length; i++) {
        if (caTypeCollateral.includes(positions[i].externalAccountType)) {
            if (cashModels.includes(positions[i].instrument.modelType)) {
                collateralCash.push(positions[i]);
            } else if (securityModels.includes(positions[i].instrument.modelType)) {
                collateralSecurities.push(positions[i]);
            } else {
                collateralDerivatives.push(positions[i]);
            }
        } else if (positions[i].externalAccountType === PartyExternalAccountType.SecuritiesAccount) {
            securitesAccountPositions.push(positions[i]);
        }
    }
    return [collateralSecurities, collateralCash, collateralDerivatives, securitesAccountPositions];
};

const securitesAccountPositionsFilter = (positions: TradingManagerColumn[]) => {
    const securitesAccountPositions: TradingManagerColumn[] = [];
    for (let i = 0; i < positions.length; i++) {
        console.log(positions[i].instrument.creditRating);
        if (positions[i].instrument.creditRating === "AAA" || positions[i].issuerType === IssuerTypeEnum.Government) {
            securitesAccountPositions.push(positions[i]);
        }
    }
    return securitesAccountPositions;
};

export interface CollateralProps {
    clientIds: string[];
    endDate: string;
}

export const Collateral = ({ clientIds, endDate }: CollateralProps): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({
        query: getTradingmanager,
        variables: { clientIds, endDate },
        requestPolicy: "network-only"
    });

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}</div>;

    console.log(data.tradingmanager);
    const collateralPositions: TradingManagerColumn[][] = collateralFilter(data.tradingmanager);
    const securities: TradingManagerColumn[] = collateralPositions[0];
    const cash: TradingManagerColumn[] = collateralPositions[1];
    const derivatives: TradingManagerColumn[] = collateralPositions[2];
    const securitesAccountPositionsFiltered: TradingManagerColumn[] = securitesAccountPositionsFilter(collateralPositions[3]);
    const nettingSetsGrouped: CollateralViewItem[] = groupNettingSets(securities, cash, derivatives);

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <Grid header="SECURITIES (minus = we receive security)" data={securities} sortable tableClassName="table-xs">
                        <Column field="externalAccountName" title="External account name" className="grid-column-sticky" />
                        <Column
                            field="name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.instrumentId ? (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="instrument.isin" title="Isin" />
                        <Column field="currency" />
                        <Column field="quantity" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="exposure" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="valuationDate" title="Valuation date" />
                    </Grid>
                </div>
                <div className="col">
                    <Grid
                        header="Sum netting sets (minus = cpty has exposure on us)"
                        data={nettingSetsGrouped}
                        sortable
                        tableClassName="table-xs"
                    >
                        <Column field="externalAccountName" title="External account name" className="grid-column-sticky" />
                        <Column field="currency" />
                        <Column field="type" />
                        <Column field="exposure" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="posted" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="netExposure" className="nowrap" title="Net exposure" format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="CASH" data={cash} sortable tableClassName="table-xs">
                        <Column field="externalAccountName" title="External account name" className="grid-column-sticky" />
                        <Column field="name" className="nowrap maxwidth150px left" />
                        <Column field="currency" />
                        <Column field="quantity" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="exposure" className="nowrap" format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="DERIVATIVES" data={derivatives} sortable tableClassName="table-xs">
                        <Column field="externalAccountName" title="External account name" className="grid-column-sticky" />
                        <Column
                            field="name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.instrumentId ? (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="currency" />
                        <Column field="quantity" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="exposure" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="valuationDate" title="Valuation date" />
                    </Grid>
                </div>

                <div className="col">
                    <Grid
                        header="Available collateral (posted quantities excluded)"
                        data={securitesAccountPositionsFiltered}
                        sortable
                        tableClassName="table-xs"
                    >
                        <Column
                            field="name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.instrumentId ? (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="instrument.isin" title="Isin" />
                        <Column field="currency" />
                        <Column field="quantity" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column field="exposure" className="nowrap" format={numberFormatFun("# ##0")} />
                        <Column
                            field="issuerName"
                            title="Issuer name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.issuerId ? (
                                    <Link to={"/parties/" + item.issuerId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="issuerType" title="Issuer type" />
                        <Column field="instrument.creditRating" title="Rating" />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};
