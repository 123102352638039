import React from "react";

interface IHighlightTextPropsType {
    text: string;
    highlight: string;
}

export const HighlightText = ({ text, highlight }: IHighlightTextPropsType) => {
    if (highlight === "") return <>{text}</>;
    try {
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return (
            <span>
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: "bold" } : {}}>
                        {part}
                    </span>
                ))}
            </span>
        );
    } catch (error) {
        return <>{text}</>;
    }
};
