import { round } from "lodash";
import save from "save-file";

export interface FlatFileExportRow {
    text: string;
    date: string;
    accountNumber: string;
    costBearer: string;
    verificationNumber: number;
    companyId: number;
    verificationSeries: string;
    vpNumber: string;
    quantity: number;
    amount: number;
}

export const convertAmountToString = (amount: number): string => {
    let amountString: string = round(amount, 2).toFixed(2);
    // Bliwa spec: comma as decimal separator
    amountString = amountString.replace(".", ",");
    // Remove - if present since we add sign in the end
    amountString = amountString.replace("-", "");
    const signString = amount < 0 ? "-" : "+";
    return signString + amountString;
};

export const convertQuantityToString = (quantity: number, stringLength = 36): string => {
    // No quantity for party instruments per Bliwa specification
    if (!quantity && quantity !== 0) return "".padEnd(stringLength);
    else {
        let quantityString: string = round(quantity, 4).toFixed(4);
        // Bliwa spec: comma as decimal separator
        quantityString = quantityString.replace(".", ",");
        // Remove - if present since we add sign in the end
        quantityString = quantityString.replace("-", "");
        const signString = quantity < 0 ? "-" : "+";
        return signString + quantityString;
    }
};

export const convertRowToFlatFileString = (row: FlatFileExportRow): string => {
    // Start with transaction text: position start at 1 in spec => 0 here. length 14.
    let fileString = "Captor output:";
    // Date: position start at 32 in spec =>31 here.
    fileString = fileString.padEnd(31);
    fileString = fileString + row.date.replaceAll("-", "");
    // Account number: position start at 41 in spec =>40 here.
    fileString = fileString.padEnd(40);
    fileString = fileString + row.accountNumber;
    // Amount: position start at 48 in spec =>47 here.
    fileString = fileString.padEnd(47);
    fileString = fileString + convertAmountToString(row.amount);
    // Cost bearer: position start at 86 in spec =>85 here.
    fileString = fileString.padEnd(85);
    fileString = fileString + row.costBearer;
    // Verification number: position start at 181 in spec =>180 here.
    fileString = fileString.padEnd(180);
    fileString = fileString + row.verificationNumber.toString();
    // Company id: position start at 187 in spec =>186 here.
    fileString = fileString.padEnd(186);
    fileString = fileString + row.companyId.toString();
    // Verification series: position start at 189 in spec =>188 here.
    fileString = fileString.padEnd(188);
    fileString = fileString + row.verificationSeries;
    // VP number: position start at 204 in spec =>203 here.
    fileString = fileString.padEnd(203);
    fileString = fileString + row.vpNumber;
    // Quantity: position start at 212 in spec =>211 here.
    fileString = fileString.padEnd(211);
    fileString = fileString + convertQuantityToString(row.quantity);

    return fileString;
};

export const writeToFlatFile = (fileName: string, exportRows: FlatFileExportRow[]) => {
    let fileData = "";
    for (const exportRow of exportRows) {
        fileData = fileData + convertRowToFlatFileString(exportRow) + "\n";
    }

    save(fileName, fileData);
};
