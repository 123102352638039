export const msPerDay = 86400000;

/**
 * Yearfraction basis
 */
export enum YearFractionBasis {
    ActualActual = "ActualActual",
    Actual365 = "Actual365",
    Actual360 = "Actual360"
}

export class DateHelper {
    static today() {
        const res = new Date();
        res.setUTCHours(0, 0, 0, 0);
        return res;
    }
    static dateToString(d) {
        const res = d.toISOString();
        if (d.getTime() % msPerDay === 0) {
            return res.substring(0, 10);
        }
        return res.replace(/T/, " ").replace(/\..+/, "");
    }
    static addYears(date, n) {
        return DateHelper.addMonths(date, 12 * n);
    }
    static isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }
    static getDaysInMonth(year, month) {
        let res = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
        if (month === 1 && DateHelper.isLeapYear(year)) res = 29;
        return res;
    }
    static getEasterDay(y) {
        const d = (19 * (y % 19) + 24) % 30;
        const e = (2 * (y % 4) + 4 * (y % 7) + 6 * d + 5) % 7;
        const f = d + e;
        const month = f + 22 <= 31 ? 3 : 4;
        const day = f + (month === 3 ? 22 : -9) + (f - 9 === 26 ? -7 : 0) + (d === 28 && e === 6 ? -7 : 0);
        return DateHelper.getDayOfYear(y, month - 1, day);
    }
    static getDayOfYear(year, month, day) {
        const daysToMonth365 = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365];
        const daysToMonth366 = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366];
        return (DateHelper.isLeapYear(year) ? daysToMonth366 : daysToMonth365)[month] + day;
    }
    static getEndOfMonth(year, month) {
        const d = DateHelper.getDaysInMonth(year, month);
        return new Date(Date.UTC(year, month, d));
    }
    static toDate(timestamp: Date | string | number): Date {
        let t: Date;
        if (timestamp instanceof Date) {
            t = timestamp as Date;
        } else if (typeof timestamp === "string") {
            t = new Date(timestamp);
        } else if (typeof timestamp === "number") {
            t = new Date(timestamp / (timestamp >= 1 && timestamp <= 401768 ? 1 : msPerDay));
        }
        return t;
    }

    static getMonth(timestamp) {
        return DateHelper.toDate(timestamp).getUTCMonth();
    }
    static getDay(timestamp) {
        return DateHelper.toDate(timestamp).getUTCDate();
    }

    static getDayOfWeek(timestamp) {
        return DateHelper.toDate(timestamp).getUTCDay();
    }

    static getYear(date) {
        return DateHelper.toDate(date).getUTCFullYear();
    }
    static dateAddDays(timestamp, days) {
        // return new Date(timestamp.getTime() + days * msPerDay);
        const res = new Date(Date.UTC(timestamp.getUTCFullYear(), timestamp.getUTCMonth(), timestamp.getUTCDate() + days, 0, 0, 0));
        res.setUTCHours(0, 0, 0, 0);
        return res;
    }
    static addDays(timestamp, n) {
        const type = typeof timestamp;
        if (type === "number") {
            if (timestamp >= 0 && timestamp < 2e6) {
                // Assume number is whole days
                return timestamp + n;
            }
            return DateHelper.dateAddDays(new Date(timestamp), n).getTime(); // Assume timestamp is in milliseconds
        }
        if (type === "string") {
            // Assume a data formatted string
            return DateHelper.timestampToString(DateHelper.addDays(new Date(timestamp), n));
        }
        if (timestamp instanceof Date) {
            return DateHelper.dateAddDays(timestamp, n);
        }
        if (typeof timestamp.addDays !== "undefined") {
            return timestamp.addDays(n);
        }
        throw new Error("Cannot add days to timestamp");
    }
    // static timestampGetYear(timestamp) {
    //     if (timestamp instanceof Date) {
    //         return timestamp.getUTCFullYear();
    //     }
    //     if (typeof timestamp === "number") {
    //         return new Date(timestamp.getTime()).getUTCFullYear();
    //     }
    //     if (typeof timestamp === "string") {
    //         return new Date(timestamp).getUTCFullYear();
    //     }
    //     if (typeof timestamp.year !== "undefined") {
    //         return timestamp.year;
    //     }
    //     throw new Error("Cannot get year of timestamp");
    // }
    static dateAddMonths(timestamp, n) {
        const res = new Date(timestamp.getTime());
        const d = res.getUTCDate();
        res.setUTCDate(1);
        res.setUTCMonth(res.getUTCMonth() + n);
        const m = Math.min(d, DateHelper.getDaysInMonth(res.getUTCFullYear(), res.getUTCMonth()));
        res.setUTCDate(m);
        return res;
    }
    static addMonths(timestamp, n) {
        const type = typeof timestamp;
        if (type === "number") {
            return DateHelper.dateAddMonths(new Date(timestamp), n).getTime();
        }
        if (type === "string") {
            // Assume a data formatted string
            return DateHelper.timestampToString(DateHelper.dateAddMonths(new Date(timestamp), n));
        }
        if (timestamp instanceof Date) {
            return DateHelper.dateAddMonths(timestamp, n);
        }
        if (typeof timestamp.addMonths !== "undefined") {
            return timestamp.addMonths(n);
        }
        throw new Error("Cannot add months to timestamp");
    }
    static timestampToString(timestamp) {
        const type = typeof timestamp;
        if (type === "string") {
            return timestamp;
        }
        if (timestamp instanceof Date) {
            return DateHelper.dateToString(timestamp);
        }
        if (type === "number" || typeof timestamp.toString !== "undefined") {
            return timestamp.toString();
        }
        throw new Error("Cannot stringify timestamp");
    }
    static timestampToComparable(timestamp) {
        const type = typeof timestamp;
        if (type === "string") {
            return new Date(timestamp).getTime();
        }
        if (type === "number") {
            return timestamp;
        }
        if (timestamp instanceof Date) {
            return timestamp.getTime();
        }
        if (typeof timestamp.number !== "undefined") {
            return timestamp.number;
        }
        throw new Error("Cannot compare timestamp");
    }
    static isDate = (s) => /^[12][90][0-9]{2}-[01][0-9]-[0-3][0-9]$/.exec(s);

    /**
     * Returns yearfrac based on actual/365
     * @param {Date} start Start timestamp
     * @param {Date} end End timestamp
     */
    static yearFrac(start, end) {
        return (end - start) / 365.0 / msPerDay;
    }

    /**
     * Returns yearfraction based on basis
     * @param {Date} start Start timestamp
     * @param {Date} end End timestamp
     * @param {YearFractionBasis} basis
     */
    static yearFraction(start, end, basis = YearFractionBasis.ActualActual) {
        // Error timestampToComparable if either date is invalid
        const sDate = DateHelper.timestampToComparable(start);
        const eDate = DateHelper.timestampToComparable(end);

        // Return zero if start and end are the same
        if (sDate === eDate) return 0;

        // Return negative year fraction if start is later than end
        if (sDate > eDate) return -DateHelper.yearFraction(end, start, basis);

        if (basis === YearFractionBasis.Actual365) return (eDate - sDate) / 365.0 / msPerDay;
        else if (basis === YearFractionBasis.Actual360) return (eDate - sDate) / 360.0 / msPerDay;
        else if (basis === YearFractionBasis.ActualActual) {
            let fullYears = 0;
            let timestamp = DateHelper.timestampToComparable(sDate);
            while (DateHelper.addYears(timestamp, 1) < eDate) {
                fullYears += 1;
                timestamp = DateHelper.addYears(timestamp, 1);
            }
            let yearDays = 365.0;
            const timestampYear = DateHelper.getYear(new Date(timestamp));
            const eDateYear = DateHelper.getYear(new Date(eDate));
            if (DateHelper.isLeapYear(timestampYear)) {
                const temp = DateHelper.timestampToComparable(timestampYear.toString() + "-02-29");
                if (timestamp <= temp && eDate > temp) yearDays += 1.0;
            } else if (DateHelper.isLeapYear(eDateYear)) {
                const temp = DateHelper.timestampToComparable(eDateYear.toString() + "-02-29");
                if (timestamp <= temp && eDate > temp) yearDays += 1.0;
            }

            return fullYears + (eDate - timestamp) / yearDays / msPerDay;
        } else new Error("Unknown year fraction basis");
    }
}
