import React, { useMemo, useState, useEffect } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";

import { useQueryArgs, usePrevious } from "../common/Utils";
import { InstrumentFilterInput, InstrumentStatusEnum } from "../types.generated";
import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter, SelectColumnFilterArrayOfIds, SelectColumnFilterType } from "../components/react-table/ReactTableFilters";

const GET_INSTRUMENTS = gql`
    query instruments($filter: InstrumentFilterInput) {
        instruments(filter: $filter) {
            _id
            clientIds
            clients {
                _id
                name
            }
            issuerId
            issuer {
                name
            }
            issuerProgramId
            issuerProgram {
                name
            }
            name
            isin
            productType
            type
            modelType
            longName
            bloombergTicker
            currency
            bloombergDailyDownload
            updateTimestamp
            status
        }
    }
`;

export const InstrumentsPage = (): React.ReactElement => {
    const { queryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    const [variables, setVariables] = useState(null);

    const [{ fetching, data, error }] = useQuery({
        query: GET_INSTRUMENTS,
        variables: variables,
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            let filter: InstrumentFilterInput = {};

            if (queryArgs.issuerProgramIdIn) {
                filter = { ...filter, issuerProgramIdIn: queryArgs.issuerProgramIdIn as string[] };
            }

            setVariables({ filter });
        }
    }, [previousQueryArgs, queryArgs]);

    const columns = useMemo(() => {
        const clientsById: Record<string, { _id: string; name: string }> = {};

        if (data) {
            data.instruments.forEach((instrument) => {
                instrument.clients.forEach((client) => {
                    if (!(client._id in clientsById)) {
                        clientsById[client._id] = client;
                    }
                });
            });
        }
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 150
            },
            {
                header: "Clients",
                accessorKey: "clientIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    if (clientsById[id]) {
                                        return (
                                            <Link className="row" key={id} to={`/parties/${id}`}>
                                                {clientsById[id].name}
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(clientsById)),
                size: 100
            },
            {
                header: "Issuer",
                id: "issuer.name",
                accessorKey: "issuer.name",
                accessorFn: (row) => (row.issuer ? row.issuer.name : null),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuer) {
                        return <Link to={`/parties/${row.original.issuerId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 100
            },
            {
                header: "Issuer program",
                id: "issuerProgram.name",
                accessorKey: "issuerProgram.name",
                accessorFn: (row) => (row.issuerProgram ? row.issuerProgram.name : null),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerProgram) {
                        return <Link to={`/issuerprograms/${row.original.issuerProgramId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 100
            },
            {
                header: "Name",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Isin",
                accessorKey: "isin",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Long name",
                accessorKey: "longName"
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Bloomberg ticker",
                accessorKey: "bloombergTicker",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Product type",
                accessorKey: "productType",
                filter: SelectColumnFilter
            },
            {
                header: "Type",
                accessorKey: "type",
                filter: SelectColumnFilter
            },
            {
                header: "Model type",
                accessorKey: "modelType",
                filter: SelectColumnFilter
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(InstrumentStatusEnum)
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp",
                size: 150
            }
        ];
    }, [data]);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.instruments === null) return <div>No instruments</div>;
    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto p-2">
                    <Link to={"/instruments/new"}>New</Link>
                </div>
            </div>
            <p>Number of instruments: {data.instruments.length}</p>

            <ReactTable
                columns={columns}
                data={data.instruments}
                defaultHiddenColumns={["_id", "issuer.name", "issuerProgram.name", "status"]}
                exportToXlsxFile="instruments.xlsx"
            />
        </div>
    );
};
