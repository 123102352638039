export interface NestedObject {
    [key: string]: any;
}

export function flattenKeys(obj: NestedObject, prefix = ""): NestedObject {
    const result: NestedObject = {};

    for (const key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
            let newKey = key;
            if (prefix !== "") {
                newKey = key[0].toUpperCase() + key.slice(1);
            }
            newKey = `${prefix}${newKey}`;
            const flattenedKeys = flattenKeys(obj[key], newKey);
            for (const flattenedKey in flattenedKeys) {
                result[flattenedKey] = flattenedKeys[flattenedKey];
            }
        } else {
            let newKey = key;
            if (prefix !== "") {
                newKey = key[0].toUpperCase() + key.slice(1);
            }
            newKey = `${prefix}${newKey}`;
            result[newKey] = obj[key];
        }
    }

    return result;
}
