import React from "react";
import { Svgs } from "../../../components/src";
import { gql, useQuery } from "urql";

export const getMe = gql`
    query {
        me {
            _id
            unreadTicketsCount
        }
    }
`;

export const TicketNotification = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getMe });
    if (fetching) return <div />;
    if (error) return <div>{error.toString()}</div>;
    return (
        <div id="tickets-assigned-notification" className="me-2">
            <a
                href="/tickets"
                type=""
                title={data.me.unreadTicketsCount > 0 ? "You have unread tickets assigned" : "You have no unread tickets assigned"}
                className="mt-2 me-2 navbar-brand position-relative"
            >
                {data.me.unreadTicketsCount > 0 ? <span className="notification-indicator" /> : null}
                <Svgs.Bell width={18} height={18} />
            </a>
        </div>
    );
};
