import React, { useState, useMemo, Fragment, useCallback } from "react";
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { numberFormatFun } from "../../../common/src";
import { DateForm } from "../common/dateForm";
import { flattenKeys } from "../common/flattenKeys";
import { useQueryState } from "../common/use-query-state";
import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { calculateDefaultDates } from "./reporting/SwedishFinancialAuthoritiesPage";

export const GET_SHARE_REGISTER = gql`
    query shareRegister($date: GraphQLDateString, $fromDate: GraphQLDateString!, $toDate: GraphQLDateString!) {
        shareRegister(filter: { tradeDateEnd: $date }) {
            clientAccount
            isin
            date
            units
            amount
            value
            instrument {
                _id
                name
                currency
            }
            fundId
            fund {
                _id
                name
            }
            customer {
                _id
                clientAccount
                clientAccountLabel
                organizationName
                swedishFinancialAuthorityCategory
                swedishFinancialAuthorityCategoryString
            }
        }

        shareRegisterUnits(filter: { tradeDateEnd: $date }) {
            isin
            instrument {
                _id
                name
                currency
            }
            units
            date
            fundId
            fund {
                _id
                name
            }
        }

        shareRegisterDelta(fromDate: $fromDate, toDate: $toDate) {
            clientAccount
            isin
            date
            units
            amount
            value
            instrument {
                _id
                name
                currency
            }
            fundId
            fund {
                _id
                name
            }
            customer {
                _id
                clientAccount
                clientAccountLabel
                organizationName
                swedishFinancialAuthorityCategory
                swedishFinancialAuthorityCategoryString
            }
        }
    }
`;

const SHARE_REGISTERS = gql`
    query shareRegisters($fromDate: GraphQLDateString!, $toDate: GraphQLDateString!) {
        shareRegisters(fromDate: $fromDate, toDate: $toDate) {
            clientAccount
            isin
            date
            units
            amount
            value
            instrument {
                _id
                name
                currency
            }
            fundId
            fund {
                _id
                name
            }
            customer {
                _id
                clientAccount
                clientAccountLabel
                organizationName
                swedishFinancialAuthorityCategory
                swedishFinancialAuthorityCategoryString
            }
        }
    }
`;

const REGISTEREXTRACT = gql`
    query registerExtracts($dateIn: [GraphQLDateString!]) {
        registerExtracts(filter: { dateIn: $dateIn }) {
            clientId
            date
            items {
                taClientAccount
                isin
                quantity
            }
        }
    }
`;

const allTabs = {
    units: "units",
    accounts: "accounts",
    delta: "delta",
    dayPositions: "dayPositions"
};

export const ShareRegisterPage = (): React.ReactElement => {
    const [dates] = useState(calculateDefaultDates());
    const [fromDate] = useQueryState("fromDate", dates.fromDate);
    const [toDate] = useQueryState("toDate", dates.toDate);

    const today = new Date().toISOString().slice(0, 10);
    const [date] = useQueryState("date", today);
    const navigate = useNavigate();
    const location = useLocation();
    const { tabId } = useParams<"tabId">();
    const [tabs] = useState<Record<string, string>>(allTabs);
    const [activeTabId, setActiveTabId] = useState(tabId);

    const [{ fetching, error, data }] = useQuery({
        query: GET_SHARE_REGISTER,
        variables: { date, fromDate, toDate },
        requestPolicy: "cache-and-network"
    });

    const [{ fetching: _fetchingShareRegisters, error: errorShareRegisters, data: dataShareRegisters }] = useQuery({
        query: SHARE_REGISTERS,
        variables: { fromDate, toDate },
        requestPolicy: "cache-and-network",
        pause: tabId !== allTabs.dayPositions
    });

    const [{ fetching: fetchingRegisterExtract, error: errorRegisterExtract, data: dataRegisterExtract }] = useQuery({
        query: REGISTEREXTRACT,
        variables: { dateIn: [dates.toDate] },
        requestPolicy: "cache-and-network",
        pause: tabId !== allTabs.accounts
    });

    const { shareRegister, shareRegisterUnits, shareRegisterDelta } = useMemo(() => {
        const shareRegister = [];
        const shareRegisterUnits = [];
        const shareRegisterDelta = [];

        if (data && data.shareRegister) {
            for (const item of cloneDeep(data.shareRegister)) {
                const data = flattenKeys(item);
                data.exposure = parseFloat(data.value) * parseFloat(data.units);
                shareRegister.push(data);
            }
        }

        if (data && data.shareRegisterUnits) {
            for (const item of cloneDeep(data.shareRegisterUnits)) {
                const data = flattenKeys(item);
                data.exposure = parseFloat(data.value) * parseFloat(data.units);
                shareRegisterUnits.push(data);
            }
        }

        if (data && data.shareRegisterDelta) {
            for (const item of cloneDeep(data.shareRegisterDelta)) {
                const data = flattenKeys(item);
                data.exposure = parseFloat(data.value) * parseFloat(data.units);
                shareRegisterDelta.push(data);
            }
        }

        return { shareRegister, shareRegisterUnits, shareRegisterDelta };
    }, [data]);

    const shareRegisters = useMemo(() => {
        const shareRegisters = [];
        if (dataShareRegisters && dataShareRegisters.shareRegisters) {
            for (const item of cloneDeep(dataShareRegisters.shareRegisters)) {
                const data = flattenKeys(item);
                data.exposure = parseFloat(data.value) * parseFloat(data.units);
                shareRegisters.push(data);
            }
        }
        return shareRegisters;
    }, [dataShareRegisters]);

    const onTabChange = useCallback(
        (tabId) => {
            if (!(tabId in tabs)) {
                navigate("/shareregister/accounts" + location.search, { replace: true });
                setActiveTabId("accounts");
            } else {
                if (tabId !== activeTabId) {
                    navigate("/shareregister/" + tabId + location.search, { replace: true });
                    setActiveTabId(tabId);
                }
            }
        },
        [tabs, navigate, location.search, activeTabId]
    );

    const columns = useMemo(
        () => [
            {
                header: "Date",
                accessorKey: "date",
                size: 40
            },
            {
                header: "Client account",
                accessorKey: "clientAccount",
                cell: (cellProps) => {
                    const clientAccount = cellProps.getValue();
                    return <Link to={`/backoffice/customers?clientAccount=${clientAccount}`}>{clientAccount}</Link>;
                },
                size: 65
            },
            {
                header: "Isin",
                accessorKey: "isin",
                filter: SelectColumnFilter,
                size: 70
            },
            {
                header: "Instrument",
                id: "instrumentName",
                accessorKey: "instrumentName",
                filter: SelectColumnFilter,
                /*cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row && row.original && row.original.instrument_id) {
                        return (
                            <Link to={`/instruments/${row.original.instrument_id}`} target={"_blank"}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else {
                        return cellProps.getValue();
                    }
                },*/
                size: 50
            },
            {
                header: "RegisterExtract",
                accessorKey: "register",
                size: 70,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <div style={{ textAlign: "right" }}>{numberFormatFun("# ##0.0000")(row.original.register)}</div>;
                }
            },
            {
                header: "Units",
                accessorKey: "units",
                size: 70,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <div style={{ textAlign: "right" }}>{numberFormatFun("# ##0.0000")(row.original.units)}</div>;
                }
            },
            {
                header: "Price",
                accessorKey: "value",
                filterFn: "startsWith",
                size: 50,
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{numberFormatFun("# ##0.0000")(cellProps.getValue())}</div>
            },
            {
                header: "Exposure",
                accessorKey: "exposure",
                filterFn: "startsWith",
                size: 70,
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{numberFormatFun("# ##0.00")(cellProps.getValue())}</div>
            },
            {
                header: "Currency",
                id: "instrumentCurrency",
                accessorKey: "instrumentCurrency",
                size: 30
            },
            {
                header: "Amount",
                accessorKey: "amount",
                size: 70
            },
            {
                header: "Fund",
                id: "fundName",
                accessorKey: "fundName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.fundName) {
                        return <Link to={`/parties/${row.original.fund_id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 200
            },
            {
                header: "Customer",
                id: "customerClientAccountLabel",
                accessorKey: "customerClientAccountLabel",
                size: 200
            },
            {
                header: "Swedish financial authority category",
                id: "swedishFinancialAuthorityCategory",
                accessorKey: "customerSwedishFinancialAuthorityCategory",
                filter: SelectColumnFilter,
                size: 200
            }
        ],
        []
    );

    const columnsUnits = useMemo(
        () => [
            {
                header: "Date",
                accessorKey: "date",
                size: 40
            },
            {
                header: "Isin",
                accessorKey: "isin",
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "Units",
                accessorKey: "units",
                size: 50
            },
            {
                header: "Instrument",
                id: "instrumentName",
                accessorKey: "instrumentName",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row && row.original && row.original.instrumentName) {
                        return (
                            <Link to={`/instruments/${row.original.instrument_id}`} target={"_blank"}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else {
                        return cellProps.getValue();
                    }
                },
                size: 50
            },
            {
                header: "Fund",
                id: "fundName",
                accessorKey: "fundName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.fund) {
                        return <Link to={`/parties/${row.original.fundId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 200
            }
        ],
        []
    );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (errorRegisterExtract) {
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(errorRegisterExtract, null, 2)}</pre>
            </div>
        );
    }

    if (fetching || fetchingRegisterExtract)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    //console.log(shareRegister);

    // add registerextract value
    if (dataRegisterExtract && dataRegisterExtract.registerExtracts.length > 0) {
        const items = dataRegisterExtract.registerExtracts[0].items.map((x) => {
            return { taClientAccount: x.taClientAccount, isin: x.isin, quantity: x.quantity, purchaseAmount: x.purchaseAmount };
        });

        shareRegister.forEach((x) => {
            const item = items.find((i) => i.isin == x.isin && i.taClientAccount == x.clientAccount);
            if (item) {
                x.register = item.quantity;
            }
        });
    }

    return (
        <Fragment>
            {activeTabId == "delta" ? (
                <div className="mb-3">
                    <div className="d-flex">
                        <DateForm defaultDateString={fromDate} dateName={"fromDate"}></DateForm>
                    </div>
                    <div className="d-flex">
                        <DateForm defaultDateString={toDate} dateName={"toDate"}></DateForm>
                    </div>
                </div>
            ) : (
                <div className="d-flex mb-3">
                    <DateForm defaultDateString={date} dateName={"date"}></DateForm>
                </div>
            )}
            <div className="row">
                <div className="col-12">
                    <Tabs onSelect={onTabChange} activeKey={activeTabId} transition={false}>
                        {"accounts" in tabs ? (
                            <Tab eventKey="accounts" title={"Accounts"}>
                                <ReactTable
                                    columns={columns}
                                    data={shareRegister}
                                    defaultHiddenColumns={["amount"]}
                                    exportToXlsxFile="shareRegister.xlsx"
                                />
                            </Tab>
                        ) : null}

                        {"units" in tabs ? (
                            <Tab eventKey="units" title={"Units"}>
                                <ReactTable
                                    columns={columnsUnits}
                                    data={shareRegisterUnits}
                                    defaultHiddenColumns={null}
                                    exportToXlsxFile="units.xlsx"
                                />
                            </Tab>
                        ) : null}

                        {"delta" in tabs ? (
                            <Tab eventKey="delta" title={"Delta"}>
                                <ReactTable
                                    columns={columns}
                                    data={shareRegisterDelta}
                                    defaultHiddenColumns={null}
                                    exportToXlsxFile="delta.xlsx"
                                />
                            </Tab>
                        ) : null}

                        {"dayPositions" in tabs ? (
                            <Tab eventKey="dayPositions" title={"Day positions"}>
                                {errorShareRegisters ? (
                                    <div>
                                        <p>error:</p>
                                        <pre> {JSON.stringify(errorShareRegisters, null, 2)}</pre>
                                    </div>
                                ) : (
                                    <ReactTable
                                        columns={columns}
                                        data={shareRegisters}
                                        defaultHiddenColumns={["amount"]}
                                        exportToXlsxFile="dayPositions.xlsx"
                                    />
                                )}
                            </Tab>
                        ) : null}
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
};
