import React from "react";
import { gql, useQuery } from "urql";
import { valueFormat, priceFormat, DateHelper } from "../../../common/src";
import { keyBy, round } from "lodash";
import {
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    GroupingState,
    useReactTable
} from "@tanstack/react-table";

const getData = gql`
    query getData($filter: PositionFilter) {
        tradingmanager(filterZeroPositions: true, filter: $filter, lookThrough: false) {
            instrumentId
            clientId
            accountId
            name
            isin
            modelType
            quantity
            currency
            value: exposure
            localValue: localExposure
            valuationPrice
            valuationDate
            fxRate
            bloombergTicker
        }
    }
`;

type TradingManagerColumn = {
    instrumentId: string;
    name: string;
    isin: string;
    bloombergTicker: string;
    modelType: string;
    clientId: string;
    accountId: string;
    currency: string;
    quantity: number;
    value: number;
    localValue: number;
    fxRate: number;
    valuationDate: string;
    valuationPrice: number;
};

enum FundCategory {
    InterestRate = "InterestRate",
    Equity = "Equity"
}

function Table({ columns, data }) {
    const [grouping, setGrouping] = React.useState<GroupingState>(["root", "category", "fund"]);
    const [expanded, setExpanded] = React.useState<ExpandedState>({
        "root:Captor": true,
        "root:Captor>category:Equity": true,
        "root:Captor>category:InterestRate": true
    });

    const table = useReactTable({
        data,
        columns,
        state: {
            grouping,
            expanded
        },
        onGroupingChange: setGrouping,
        onExpandedChange: setExpanded,
        getSubRows: (row: any) => row.subRows,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <div className="captor-table">
            <table className="table table-striped table-xs">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="tr" role="row">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{ width: header.getSize() }}
                                        className="th"
                                        role="columnheader"
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div>
                                                {header.column.getCanGroup() && header.depth > 1 ? (
                                                    <span className="me-1">
                                                        <input
                                                            id="cb"
                                                            type="checkbox"
                                                            title="Toggle GroupBy"
                                                            checked={header.column.getIsGrouped()}
                                                            onClick={header.column.getToggleGroupingHandler()}
                                                            readOnly
                                                        ></input>
                                                    </span>
                                                ) : null}
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} className="tr" role="row">
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id} className="td" role="cell" style={{ width: cell.column.getSize() }}>
                                            {cell.getIsGrouped() ? (
                                                // If it's a grouped cell, add an expander and row count
                                                <>
                                                    <span
                                                        {...{
                                                            onClick: row.getToggleExpandedHandler(),
                                                            style: { cursor: "pointer", paddingRight: "5px" }
                                                        }}
                                                    >
                                                        {row.getIsExpanded() ? "⬇" : "➡"}
                                                    </span>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                                                </>
                                            ) : cell.getIsAggregated() ? (
                                                // If the cell is aggregated, use the Aggregated renderer for cell
                                                flexRender(
                                                    cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )
                                            ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                // Otherwise, just render the regular cell
                                                flexRender(cell.column.columnDef.cell, cell.getContext())
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
export function PortfolioGrouperPage(): React.ReactElement {
    const date = DateHelper.dateToString(DateHelper.dateAddDays(new Date(), -1));
    // Get this data later?
    const funds = [
        {
            _id: "594a2cf723d2773014ec27b7",
            name: "Captor Scilla Global Equity",
            category: FundCategory.Equity
        },
        {
            _id: "58e64b9523d2772e1859b705",
            name: "Captor Iris Bond",
            category: FundCategory.InterestRate
        },
        {
            _id: "5b0d638cafcedd32f03a8ac7",
            name: "Captor Dahlia Green Bond",
            category: FundCategory.InterestRate
        },
        {
            _id: "605b2e5cc34cf5001154c90d",
            name: "Captor Aster Global Credit",
            category: FundCategory.InterestRate
        },
        {
            _id: "62690a20071ef07765246144",
            name: "Captor Aster Global Credit Short-Term",
            category: FundCategory.InterestRate
        },
        {
            _id: "62690582071ef0776524606c",
            name: "Captor Aster Global High Yield",
            category: FundCategory.InterestRate
        }
    ];

    const fundsById = keyBy(funds, "_id");

    const columns = React.useMemo(
        () => [
            {
                header: "Captor",
                columns: [
                    {
                        header: "Root",
                        accessorKey: "root",
                        getIsGrouped: () => true,
                        size: 80
                    },
                    {
                        header: "Category",
                        accessorKey: "category",
                        getIsGrouped: () => true,
                        size: 110
                    },
                    {
                        header: "Fund",
                        accessorKey: "fund",
                        getIsGrouped: () => true,
                        size: 180
                    },
                    {
                        header: "Name",
                        accessorKey: "name",
                        size: 90,
                        // Use another two-stage aggregator here to
                        // first count the UNIQUE values from the rows
                        // being aggregated, then sum those counts if
                        // they are aggregated further
                        aggregationFn: "uniqueCount",
                        aggregatedCell: (cellProps) => `${cellProps.getValue()} Unique Names`
                    },
                    {
                        header: "Currency",
                        accessorKey: "currency",
                        size: 80
                    },
                    {
                        header: "Type",
                        accessorKey: "modelType",
                        size: 80
                    },
                    {
                        header: "Position",
                        accessorKey: "quantity",
                        size: 90,
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                        aggregatedCell: () => null
                    },
                    {
                        header: "Price",
                        accessorKey: "price",
                        size: 90,
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{priceFormat(cellProps.getValue())}</div>,
                        aggregatedCell: () => null
                    },
                    {
                        header: "Local value",
                        accessorKey: "localValue",
                        size: 120,
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                        aggregatedCell: () => null
                    },
                    {
                        header: "Value",
                        accessorKey: "value",
                        size: 120,
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>,
                        aggregationFn: "sum",
                        aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{valueFormat(cellProps.getValue())}</div>
                    },
                    {
                        header: "Fx rate",
                        accessorKey: "fxRate",
                        size: 90,
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{priceFormat(cellProps.getValue())}</div>,
                        aggregatedCell: () => null
                    },
                    {
                        header: "Date",
                        accessorKey: "valuationDate",
                        size: 80
                    }
                ]
            }
        ],
        []
    );

    const [{ fetching, error, data: tmData }] = useQuery({
        query: getData,
        variables: { filter: { endDate: date, clientIds: Object.keys(fundsById) } }
    });

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const gqlData = tmData.tradingmanager as unknown as TradingManagerColumn[];
    const data = gqlData.map((d) => {
        const fund = fundsById[d.clientId];
        return {
            ...d,
            root: "Captor",
            fund: fund.name,
            category: fund.category,
            price: round((10000 * d.localValue) / d.quantity) / 10000
        };
    });

    return <Table columns={columns} data={data} />;
}
