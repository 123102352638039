import { emptyObjectId, ProfitLoss, isPositionBuildingType, isFeeType, InventoryItem, PortfolioLedgerPosition, round } from "..";
import { AccountingTransactionExtended, AccountingTransactionType, JournalEntryExtended, RealizedType, TransactionStatus } from "./types";

import {
    Party,
    Instrument,
    TAccountChart,
    TAccountMappingSelectorKeyEnum,
    TAccountTypeEnum,
    AccountingBatchType,
    JournalEntry,
    Valuation,
    TransactionItem,
    Transaction,
    TransactionItemType
} from "../types.generated";

//import { round } from "../timeseries";
import {
    TAccountMappingValues,
    defaultTAccountMappingValues,
    negateAccountMappingValue,
    getAccountMappingValue,
    getTAccountMapping,
    defaultTAccountType,
    TAccountMappingExtended
} from "./TAccountChart";

import { sha256 } from "sha.js";
import { cloneDeep, keyBy } from "lodash";

export const amountTol = 1e-7;
export const quantityTol = 0.01;

export class GeneralLedger {
    constructor(
        journalEntries: JournalEntryExtended[],
        party: Party | null = null,
        instruments: Instrument[] | null = null,
        accountingRunId: string | null = null,
        openingBalanceMappingValues: TAccountMappingValues | null = null,
        equityMappingValues: TAccountMappingValues | null = null,
        roundingMappingValues: TAccountMappingValues | null = null,
        roundingDecimals = 0,
        tAccountChart: TAccountChart | null = null
    ) {
        this.journalEntries = journalEntries;
        this.party = party;
        this.instruments = instruments;
        this.accountingRunId = accountingRunId;
        this.openingBalanceMappingValues =
            openingBalanceMappingValues && Object.keys(openingBalanceMappingValues).length > 0
                ? openingBalanceMappingValues
                : { ...defaultTAccountMappingValues };
        this.roundingMappingValues =
            roundingMappingValues && Object.keys(roundingMappingValues).length > 0
                ? roundingMappingValues
                : { ...defaultTAccountMappingValues };
        this.equityMappingValues =
            equityMappingValues && Object.keys(equityMappingValues).length > 0 ? equityMappingValues : { ...defaultTAccountMappingValues };
        this.roundingDecimals = roundingDecimals;
        this.openingBalanceBatch = AccountingBatchType.IB;
        this.openingBalanceValuationBatch = AccountingBatchType.BVI;
        this.closingBalanceValuationBatch = AccountingBatchType.BVU;
        this.manualBatch = AccountingBatchType.M;
        this.manualReverseBatch = AccountingBatchType.MR;
        this.automatedBatch = AccountingBatchType.A;
        this.automatedReverseBatch = AccountingBatchType.AR;
        this.transactionBatch = AccountingBatchType.T;
        this.tAccountChart = tAccountChart;
        this.maxBatchNumbers = {};
        journalEntries.forEach((d) => {
            const n = this.maxBatchNumbers[d.batch];
            this.maxBatchNumbers[d.batch] = Math.max(typeof n !== "undefined" ? n : 0, d.number);
        });
    }
    journalEntries: JournalEntryExtended[];
    party: Party;
    instruments: Instrument[];
    accountingRunId: string;
    openingBalanceMappingValues: TAccountMappingValues;
    roundingMappingValues: TAccountMappingValues;
    equityMappingValues: TAccountMappingValues;
    roundingDecimals: number;
    openingBalanceBatch: AccountingBatchType;
    openingBalanceValuationBatch: AccountingBatchType;
    closingBalanceValuationBatch: AccountingBatchType;
    manualBatch: AccountingBatchType;
    manualReverseBatch: AccountingBatchType;
    automatedBatch: AccountingBatchType;
    automatedReverseBatch: AccountingBatchType;
    transactionBatch: AccountingBatchType;
    tAccountChart: TAccountChart;
    maxBatchNumbers: { [batch: string]: number };

    nextBatchNumber(batch: AccountingBatchType): number {
        let number = 1;
        if (typeof this.maxBatchNumbers[batch] !== "undefined") {
            number = this.maxBatchNumbers[batch] + 1;
        }
        return number;
    }

    addJournalEntry(transactionId: string, batch: AccountingBatchType, effectiveDate: string, description: string): JournalEntryExtended {
        const number = this.nextBatchNumber(batch);
        const f = this.journalEntries.findIndex((d) => d.batch === batch && d.number === number);
        if (f >= 0) {
            throw new Error(`JournalEntry already exists ${batch}${number}`);
        }
        this.maxBatchNumbers[batch] = number;
        const je: JournalEntryExtended = {
            __typename: "JournalEntry",
            _id: emptyObjectId,
            portfolioTransactionId: transactionId,
            clientId: this.party ? this.party._id : emptyObjectId,
            accountingRunId: this.accountingRunId,
            batch,
            number,
            effectiveDate,
            description,
            transactions: [] as AccountingTransactionExtended[]
        };

        this.journalEntries.push(je);
        return je;
    }

    addJournalEntryTransaction(
        portfolioTransactionId: string,
        instrumentId: string,
        type: AccountingTransactionType,
        mappingRule: TAccountMappingValues,
        negate: boolean,
        amount: number,
        quantity?: number,
        valueDate?: string
    ): AccountingTransactionExtended {
        const mv = mappingRule[type];
        if (typeof mv === "undefined" || mv === null || mv === "") {
            throw new Error(
                `Invalid mapping value for type=${type}, transactionId=${portfolioTransactionId}, instrumentId=${instrumentId}`
            );
        }
        const tAccountNumber = getAccountMappingValue(
            (!negate && amount >= 0) || (negate && amount < 0) || type === AccountingTransactionType.InitialCost
                ? mv
                : negateAccountMappingValue(mv)
        );

        const je = this.journalEntries.find((d) => d.portfolioTransactionId === portfolioTransactionId);
        if (typeof je === "undefined") {
            throw new Error(`JournalEntry is missing (transactionId=${portfolioTransactionId})`);
        }
        const t: AccountingTransactionExtended = {
            __typename: "AccountingTransaction",
            tAccountNumber,
            type,
            instrumentId,
            amount,
            journalEntry: je
        };
        if (quantity && (type === AccountingTransactionType.InitialCost || type === AccountingTransactionType.ShareCapital)) {
            t.quantity = quantity;
        }
        if (valueDate && type === AccountingTransactionType.InitialCost) {
            t.valueDate = valueDate;
        }
        je.transactions.push(t);
        return t;
    }

    bookTransactionItem(
        transactionItem: TransactionItem,
        inventoryChange: InventoryItem,
        income: InventoryItem,
        realizedProfitLoss: ProfitLoss,
        mappingValues: TAccountMappingValues
    ): AccountingTransactionExtended[] {
        const inventoryChangeAmt = inventoryChange.amount * inventoryChange.fxRate;
        const rplFromPrice = round(realizedProfitLoss.fromPrice, this.roundingDecimals);
        const rplFromFx = round(realizedProfitLoss.fromFxTotal, this.roundingDecimals);
        const initialCost = round(
            realizedProfitLoss.fromPrice + realizedProfitLoss.fromFxTotal + inventoryChangeAmt,
            this.roundingDecimals
        );
        const incomeValue = round(income === null ? 0 : income.amount * income.fxRate, this.roundingDecimals);
        const quantity = round(
            transactionItem.quantity,
            transactionItem.instrument && (transactionItem.instrument.quantityDecimals || transactionItem.instrument.quantityDecimals === 0)
                ? transactionItem.instrument.quantityDecimals
                : this.roundingDecimals
        );

        const instrumentId = transactionItem.instrumentId;
        const transactionId = transactionItem.transactionId;

        const res = [];
        if (
            TransactionItemType.CreateRedeem === transactionItem.type ||
            TransactionItemType.CreateRedeemAdjustmentAmount === transactionItem.type ||
            TransactionItemType.CreateRedeemAdjustmentShares === transactionItem.type
        ) {
            res.push(
                this.addJournalEntryTransaction(
                    transactionId,
                    instrumentId,
                    AccountingTransactionType.ShareCapital,
                    mappingValues,
                    false,
                    round(transactionItem.amount, this.roundingDecimals),
                    quantity,
                    transactionItem.valueDate
                )
            );
        } else if (transactionItem.type === TransactionItemType.DividendPaid) {
            res.push(
                this.addJournalEntryTransaction(
                    transactionId,
                    instrumentId,
                    AccountingTransactionType.DividendPaid,
                    mappingValues,
                    false,
                    round(transactionItem.amount, this.roundingDecimals)
                )
            );
        } else {
            if (initialCost !== 0 || isPositionBuildingType(transactionItem.type)) {
                res.push(
                    this.addJournalEntryTransaction(
                        transactionId,
                        instrumentId,
                        AccountingTransactionType.InitialCost,
                        mappingValues,
                        false,
                        initialCost,
                        quantity,
                        transactionItem.valueDate
                    )
                );
            }

            if (incomeValue !== 0 || isFeeType(transactionItem.type)) {
                if (transactionItem.type === TransactionItemType.Dividend) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Dividend,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.Interest) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Interest,
                            mappingValues,
                            true,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.AccruedInterest) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Interest,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.ManagementFee) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.ManagementFee,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.ManagementCost) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.ManagementCost,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.CustodyFee) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.CustodyFee,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.Rebate) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Rebate,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.Commission) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Commission,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.StampDuty) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.StampDuty,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.TaxRestitution) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.TaxRestitution,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.ForeignTax) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.ForeignTax,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (transactionItem.type === TransactionItemType.Tax) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Tax,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                } else if (isFeeType(transactionItem.type)) {
                    res.push(
                        this.addJournalEntryTransaction(
                            transactionId,
                            instrumentId,
                            AccountingTransactionType.Fee,
                            mappingValues,
                            false,
                            incomeValue
                        )
                    );
                }
            }
            if (rplFromPrice !== 0) {
                res.push(
                    this.addJournalEntryTransaction(
                        transactionId,
                        instrumentId,
                        AccountingTransactionType.Realized,
                        mappingValues,
                        true,
                        -rplFromPrice
                    )
                );
            }
            if (rplFromFx !== 0) {
                res.push(
                    this.addJournalEntryTransaction(
                        transactionId,
                        instrumentId,
                        AccountingTransactionType.RealizedFx,
                        mappingValues,
                        true,
                        -rplFromFx
                    )
                );
            }
        }

        return res;
    }

    /**
     * Post process the journal entry:
     * 1) Add rounding if transactions don't sum to zero.
     * 2) Sort transactions in T-account number order (amount if same T-account).
     */
    postProcessJournalEntry(
        journalEntry: JournalEntryExtended,
        zeroBalanceTAccountNumber: string,
        zeroBalanceType: AccountingTransactionType,
        zeroBalanceInstrumentId: string
    ): void {
        const s = round(
            journalEntry.transactions.reduce((sum, d) => sum + d.amount, 0),
            this.roundingDecimals
        );
        if (s !== 0) {
            journalEntry.transactions.push({
                __typename: "AccountingTransaction",
                amount: -s,
                instrumentId: zeroBalanceInstrumentId,
                journalEntry: journalEntry,
                tAccountNumber: zeroBalanceTAccountNumber, // this.roundingMappingValues[AccountingTransactionType.InitialCost]
                type: zeroBalanceType //AccountingTransactionType.Rounding
            });
        }
        journalEntry.transactions.sort((d1, d2) => {
            if (d1.tAccountNumber !== d2.tAccountNumber) {
                return d1.tAccountNumber < d2.tAccountNumber ? -1 : 1;
            }
            return d1.amount - d2.amount;
        });
    }

    getInstrument(instrumentId: string): Instrument {
        let instr: Instrument = this.instruments ? this.instruments.find((d) => d._id === instrumentId) : null;
        if (!instr) {
            const tmp = this.party ? this.party.instruments.find((d) => d._id === instrumentId) : null;
            instr = tmp as unknown as Instrument;
        }
        return instr;
    }

    bookTransactionItems(
        positions: PortfolioLedgerPosition[],
        tAccountMappings: TAccountMappingExtended[],
        startDate: string,
        endDate: string
    ): void {
        for (let i = 0; i < positions.length; i++) {
            const pos = positions[i];
            const instr = this.getInstrument(pos.instrumentId);
            const { values: mvs /*, index*/ } = getTAccountMapping(null, instr, tAccountMappings);
            // console.log(pos.instrumentId, instr, mvs, index);
            for (let j = 0; j < pos.calcPoints.length; j++) {
                const cp = pos.calcPoints[j];
                if (cp.realizedType === RealizedType.Valuation || cp.date <= startDate || cp.date > endDate || !cp.transactionItem) {
                    continue;
                }
                this.bookTransactionItem(cp.transactionItem, cp.inventoryChange, cp.income, cp.realizedProfitLoss, mvs);
            }
        }
    }

    bookClosingBalanceValuations(positions: PortfolioLedgerPosition[], tAccountMappings: TAccountMappingExtended[], endDate: string): void {
        for (let i = 0; i < positions.length; i++) {
            const pos = positions[i];
            const instr = this.getInstrument(pos.instrumentId);
            const { values: mvs } = getTAccountMapping(null, instr, tAccountMappings);
            let excludeFound = false;
            // Not creating BVU for any positions which has createRedeem or dividendPaid transaction item
            for (const calcPoint of pos.calcPoints) {
                if (
                    calcPoint.transactionItem &&
                    (TransactionItemType.CreateRedeem === calcPoint.transactionItem.type ||
                        TransactionItemType.CreateRedeemAdjustmentAmount === calcPoint.transactionItem.type ||
                        TransactionItemType.CreateRedeemAdjustmentShares === calcPoint.transactionItem.type ||
                        TransactionItemType.DividendPaid === calcPoint.transactionItem.type)
                ) {
                    excludeFound = true;
                }
            }
            if (!excludeFound) {
                this.addClosingBalanceValuation(pos.instrumentId, null, endDate, mvs, pos.endUnrealizedProfitLoss, pos.endAccrued);
            }
        }
    }

    addClosingBalanceValuation(
        instrumentId: string,
        description: string,
        effectiveDate: string,
        mappingValues: TAccountMappingValues,
        upl: ProfitLoss,
        accrued: number
    ): JournalEntryExtended {
        const addTransaction = (journalEntry: JournalEntryExtended, type: AccountingTransactionType, negate: boolean, amount: number) => {
            const mv = mappingValues[type];
            if (typeof mv === "undefined" || mv === null || mv === "") {
                throw new Error(`Invalid mapping value for type=${type}, instrumentId=${instrumentId}`);
            }
            const tAccountNumber = getAccountMappingValue(
                (!negate && amount >= 0) || (negate && amount < 0) || type === AccountingTransactionType.ValueChange
                    ? mv
                    : negateAccountMappingValue(mv)
            );
            amount = round(amount, this.roundingDecimals);
            if (amount === 0) {
                return;
            }
            journalEntry.transactions.push({
                __typename: "AccountingTransaction",
                instrumentId,
                amount,
                tAccountNumber,
                journalEntry,
                type
            });
        };

        const p = round(upl.fromPrice, this.roundingDecimals);
        const fx = round(upl.fromFxTotal, this.roundingDecimals);
        const acc = round(accrued, this.roundingDecimals);
        if ([p, fx, acc].every((d) => d === 0)) {
            return;
        }
        const je = this.addJournalEntry(emptyObjectId, this.closingBalanceValuationBatch, effectiveDate, description);

        addTransaction(je, AccountingTransactionType.ValueChange, false, p + fx);
        addTransaction(je, AccountingTransactionType.Interest, true, -accrued);
        addTransaction(je, AccountingTransactionType.Unrealized, true, -p);
        addTransaction(je, AccountingTransactionType.UnrealizedFx, true, -fx);
        addTransaction(je, AccountingTransactionType.AccruedInterest, false, accrued);

        return je;
    }

    clearJournalEntries(): void {
        this.journalEntries = [];
        this.maxBatchNumbers = {};
    }

    addMirrorBalanceValuations(
        journalEntries: JournalEntryExtended[],
        endDate: string,
        previousEndDate: string,
        batchType: AccountingBatchType,
        mirrorBatchType: AccountingBatchType
    ): void {
        // Mirror only batch on previous end date since earlier already mirrored
        for (let i = 0; i < journalEntries.length; i++) {
            const jec: any = journalEntries[i];
            // Only match batch on previous end date
            if (jec.batch === batchType && jec.effectiveDate === previousEndDate) {
                const journalEntry = this.addJournalEntry(emptyObjectId, mirrorBatchType, endDate, null);
                // Mirror
                journalEntry.transactions = jec.transactions.map((t) => {
                    const newTransaction: Partial<AccountingTransactionExtended> = {
                        journalEntry,
                        type: t.type,
                        instrumentId: t.instrumentId,
                        tAccountNumber: t.tAccountNumber,
                        amount: -t.amount
                    };
                    if (
                        (t.type === AccountingTransactionType.InitialCost || t.type === AccountingTransactionType.ForwardCash) &&
                        t.quantity
                    ) {
                        newTransaction.quantity = -t.quantity;
                    }
                    if (t.type === AccountingTransactionType.InitialCost && t.valueDate) {
                        newTransaction.valueDate = t.valueDate;
                    }

                    return newTransaction;
                });
            }
        }
    }

    /**
     * mirrorBalanceValuations
     * Find all opening and closing balance valuations, exclude closing balance valuations on end date. Create mirror transactions that
     * closes the previous balance valuations. Do same thing for automated (equivalent of closing) and automated reverse
     * (equivalent of opening)
     * @param journalEntries All journal entries
     * @param endDate End date
     * @param previousEndDate Previous accounting end date
     */
    mirrorBalanceValuations(
        journalEntries: JournalEntryExtended[],
        endDate: string,
        previousEndDate: string,
        addAutomatedReverse: boolean
    ): void {
        this.addMirrorBalanceValuations(journalEntries, endDate, previousEndDate, AccountingBatchType.BVU, AccountingBatchType.BVI);
        if (addAutomatedReverse) {
            this.addMirrorBalanceValuations(journalEntries, endDate, previousEndDate, AccountingBatchType.A, AccountingBatchType.AR);
        }
    }

    /**
     * mirrorManuals
     * Find all manual journal entries, exclude manual journal entries on end date.
     * Create mirror transactions with batch = ManualReverse that closes the manual journal entries.
     * @param journalEntries All journal entries
     * @param endDate End date
     * @param previousEndDate Previous accounting end date
     */
    mirrorManuals(journalEntries: JournalEntryExtended[], endDate: string, previousEndDate: string): void {
        this.addMirrorBalanceValuations(journalEntries, endDate, previousEndDate, this.manualBatch, this.manualReverseBatch);
    }

    getTAccountType(tAccountNumber: string, tAccountChart: TAccountChart): TAccountTypeEnum {
        const ta = tAccountChart ? tAccountChart.tAccounts.find((d) => d.number === tAccountNumber) : null;
        if (ta && ta.type) {
            return ta.type;
        }
        return defaultTAccountType(tAccountNumber);
    }

    createNewPeriodOpeningBalances(
        journalEntries: JournalEntryExtended[],
        startDate: string,
        previousPeriod: string,
        currentPeriod: string
    ): void {
        this.clearJournalEntries();
        let _obMax = 0;
        const tJes = journalEntries.filter((je) => je.batch === this.openingBalanceBatch);
        if (tJes.length > 0) {
            _obMax = Math.max.apply(
                null,
                tJes.map((je) => je.number)
            );
        }
        // Should be 0 if first quarter of year
        if (previousPeriod === currentPeriod) {
            this.maxBatchNumbers[this.openingBalanceBatch] = _obMax;
        } else {
            this.maxBatchNumbers[this.openingBalanceBatch] = 0;
        }

        const dict: { [id: string]: AccountingTransactionExtended[] } = {};
        // Only for type initialCost to determine quantity on new opening balances
        const quantityByInstrumentId: { [id: string]: number } = {};
        for (let i = 0; i < journalEntries.length; i++) {
            const je = journalEntries[i];

            if (![AccountingBatchType.A, AccountingBatchType.AR, AccountingBatchType.M, AccountingBatchType.MR].includes(je.batch)) {
                for (let j = 0; j < je.transactions.length; j++) {
                    const t = je.transactions[j];
                    if (t.type === AccountingTransactionType.InitialCost) {
                        if (!quantityByInstrumentId[t.instrumentId]) {
                            quantityByInstrumentId[t.instrumentId] = 0;
                        }
                        quantityByInstrumentId[t.instrumentId] += t.quantity ? t.quantity : 0;
                    }
                    const typ = this.getTAccountType(t.tAccountNumber, this.tAccountChart);
                    if (typ === TAccountTypeEnum.Income) {
                        continue;
                    }
                    const key = t.instrumentId;
                    let transactions = dict[key];
                    if (!transactions) {
                        transactions = [];
                        dict[key] = transactions;
                    }
                    const f = transactions.find((d) => d.tAccountNumber === t.tAccountNumber);
                    if (f) {
                        f.amount += t.amount;
                    } else {
                        transactions.push({ ...t, journalEntry: null });
                    }
                }
            }
        }

        let openingBalanceTAccountNumber = this.openingBalanceMappingValues[AccountingTransactionType.InitialCost];
        if (openingBalanceTAccountNumber === defaultTAccountMappingValues.InitialCost) {
            openingBalanceTAccountNumber = defaultTAccountMappingValues.OpeningBalance;
        }
        // Only creating opening balance if quantity >= quantityTol
        Object.keys(dict).forEach((instrumentId) => {
            let openingBalanceTransactions = dict[instrumentId];
            openingBalanceTransactions = openingBalanceTransactions.filter((t) => {
                if (Math.abs(quantityByInstrumentId[t.instrumentId]) >= quantityTol) {
                    return t;
                }
            });
            if (openingBalanceTransactions.length === 0) {
                return;
            }

            const je = this.addJournalEntry(emptyObjectId, this.openingBalanceBatch, startDate, "Opening Balance");

            openingBalanceTransactions.forEach((t) => {
                // Adding quantity to opening balances, transactions of type InitialCost
                if (t.type === AccountingTransactionType.InitialCost) {
                    t.quantity = 0;
                    if (quantityByInstrumentId[t.instrumentId]) {
                        t.quantity = quantityByInstrumentId[t.instrumentId];
                    }
                }
                t.journalEntry = je;
            });
            je.transactions = openingBalanceTransactions;
            this.postProcessJournalEntry(je, openingBalanceTAccountNumber, AccountingTransactionType.OpeningBalance, instrumentId);
        });
    }

    /**
     * Help function to toHashCode
     */
    static journalEntriesToCsv(journalEntries: JournalEntryExtended[], roundingDecimals: number, separator = ","): string {
        if (!journalEntries) {
            return "";
        }
        const arr = [];
        journalEntries.forEach((je) =>
            arr.push(
                ...je.transactions.map((t) => ({
                    instrumentId: t.instrumentId,
                    tAccountNumber: t.tAccountNumber,
                    amount: round(t.amount, roundingDecimals),
                    quantity: t.quantity ? round(t.quantity, roundingDecimals) : null,
                    valueDate: t.valueDate ? t.valueDate : null
                }))
            )
        );
        // Sort by instrumentId, tAccountNumber and amount (as number)
        arr.sort((d1, d2) => {
            let c = d1.instrumentId === d2.instrumentId ? 0 : d1.instrumentId < d2.instrumentId ? -1 : 1;
            if (c !== 0) {
                return c;
            }
            c = d1.tAccountNumber === d2.tAccountNumber ? 0 : d1.tAccountNumber < d2.tAccountNumber ? -1 : 1;
            if (c !== 0) {
                return c;
            }
            return d1.amount - d2.amount;
        });
        return arr.map((t) => [t.instrumentId, t.tAccountNumber, t.amount.toFixed(roundingDecimals)].join(separator)).join("\n");
    }

    static computeHashCode(journalEntries: JournalEntryExtended[], roundingDecimals: number): string {
        const csv = GeneralLedger.journalEntriesToCsv(journalEntries, roundingDecimals, ",");
        return new sha256().update(csv).digest("hex");
    }

    toCsv(separator = ","): string {
        return GeneralLedger.journalEntriesToCsv(this.journalEntries, this.roundingDecimals, separator);
    }

    toHashCode(): string {
        return GeneralLedger.computeHashCode(this.journalEntries, this.roundingDecimals);
    }

    static createAdjustmentJournalEntry(
        type: AccountingTransactionType,
        typeNegate: AccountingTransactionType,
        gl: GeneralLedger,
        number: number,
        endDate: string,
        instrumentId: string,
        tAccountNumber: string,
        tAccountNumberNegate: string,
        amount: number,
        quantity?: number
    ): JournalEntryExtended {
        const automatedJournalEntry = {
            __typename: "JournalEntry",
            _id: emptyObjectId,
            portfolioTransactionId: emptyObjectId,
            clientId: gl.party._id,
            accountingRunId: gl.accountingRunId,
            batch: AccountingBatchType.A,
            number,
            effectiveDate: endDate,
            description: null,
            transactions: [
                {
                    __typename: "AccountingTransaction",
                    instrumentId: instrumentId,
                    amount: -amount,
                    tAccountNumber: tAccountNumber,
                    journalEntry: null,
                    type: type,
                    quantity: quantity ? -quantity : null
                },
                {
                    __typename: "AccountingTransaction",
                    instrumentId: instrumentId,
                    amount: amount,
                    tAccountNumber: tAccountNumberNegate,
                    journalEntry: null,
                    type: typeNegate,
                    quantity: quantity ? quantity : null
                }
            ]
        };
        automatedJournalEntry.transactions[0].journalEntry = automatedJournalEntry;
        automatedJournalEntry.transactions[1].journalEntry = automatedJournalEntry;

        return automatedJournalEntry as JournalEntryExtended;
    }

    static createAccounting(
        positions: PortfolioLedgerPosition[],
        tAccountMappings: TAccountMappingExtended[],
        party: Party,
        instruments: Instrument[],
        transactions: Transaction[],
        manualJournalEntries: JournalEntry[],
        startDate: string,
        openingDate: string,
        endDate: string,
        previousPeriod: string,
        currentPeriod: string,
        previousJournalEntries: JournalEntryExtended[],
        fxValuationsByInstrumentName: Record<string, Valuation>
    ): GeneralLedger {
        const { values: opMvs } = getTAccountMapping(TAccountMappingSelectorKeyEnum.OpeningBalance, null, tAccountMappings);
        const { values: roundMvs } = getTAccountMapping(TAccountMappingSelectorKeyEnum.Rounding, null, tAccountMappings);
        const { values: equityMvs } = getTAccountMapping(TAccountMappingSelectorKeyEnum.Equity, null, tAccountMappings);
        const gl = new GeneralLedger([], party, instruments, emptyObjectId, opMvs, equityMvs, roundMvs, 2);

        // Start with small date since we want to find max of previous journal entries. Might not be needed since start date given?
        let previousEndDate = "1901-01-01";
        for (let i = 0; i < previousJournalEntries.length; i++) {
            if (previousJournalEntries[i].effectiveDate > previousEndDate) previousEndDate = previousJournalEntries[i].effectiveDate;
        }

        if (previousPeriod !== currentPeriod) {
            gl.createNewPeriodOpeningBalances(previousJournalEntries, openingDate, previousPeriod, currentPeriod);
        }
        // Here we should get all batch "max" numbers! Start with zero
        let aMax = 0;
        let arMax = 0;
        let bviMax = 0;
        let bvuMax = 0;
        let ibMax = 0;
        let mMax = 0;
        let mrMax = 0;
        let tMax = 0;
        // Should be 0 if first period of accounting period. If not check accounting periods previous journal entries
        if (previousPeriod === currentPeriod) {
            for (const je of previousJournalEntries) {
                if (je.batch === AccountingBatchType.A && je.number > aMax) aMax = je.number;
                else if (je.batch === AccountingBatchType.AR && je.number > arMax) arMax = je.number;
                else if (je.batch === AccountingBatchType.BVI && je.number > bviMax) bviMax = je.number;
                else if (je.batch === AccountingBatchType.BVU && je.number > bvuMax) bvuMax = je.number;
                else if (je.batch === AccountingBatchType.IB && je.number > ibMax) ibMax = je.number;
                else if (je.batch === AccountingBatchType.M && je.number > mMax) mMax = je.number;
                else if (je.batch === AccountingBatchType.MR && je.number > mrMax) mrMax = je.number;
                else if (je.batch === AccountingBatchType.T && je.number > tMax) tMax = je.number;
            }
        }
        // Update max batch numbers
        gl.maxBatchNumbers[AccountingBatchType.A] = aMax;
        gl.maxBatchNumbers[AccountingBatchType.AR] = arMax;
        gl.maxBatchNumbers[AccountingBatchType.BVI] = bviMax;
        gl.maxBatchNumbers[AccountingBatchType.BVU] = bvuMax;
        gl.maxBatchNumbers[AccountingBatchType.IB] = ibMax;
        gl.maxBatchNumbers[AccountingBatchType.M] = mMax;
        gl.maxBatchNumbers[AccountingBatchType.MR] = mrMax;
        gl.maxBatchNumbers[AccountingBatchType.T] = tMax;

        // Create T-batch
        transactions
            .filter((t: Transaction) => t.status !== TransactionStatus.Deleted && t.tradeDate > startDate && t.tradeDate <= endDate)
            .forEach((t: Transaction) => {
                gl.addJournalEntry(t._id, gl.transactionBatch, t.tradeDate, t.description);
            });

        for (let i = 0; i < positions.length; i++) {
            const pos = positions[i];
            gl.bookTransactionItems([pos], tAccountMappings, startDate, endDate);
        }

        let addReverseJournalEntries = false;

        // Not adding manual/automated reverse for first quarter of the year

        if (previousPeriod === currentPeriod) {
            addReverseJournalEntries = true;
        }

        gl.mirrorBalanceValuations(previousJournalEntries, endDate, previousEndDate, addReverseJournalEntries);

        if (addReverseJournalEntries) {
            gl.mirrorManuals(previousJournalEntries, endDate, previousEndDate);
        }

        for (let i = 0; i < positions.length; i++) {
            const pos = positions[i];
            gl.bookClosingBalanceValuations([pos], tAccountMappings, endDate);
        }

        // If initialCost + valueChange<0 add journal entries which moves amounts to result side
        const journalEntryTransactionsByInstrumentId: Record<
            string,
            {
                transactions: AccountingTransactionExtended[];
                initialCostValueChangeTotal: number;
                tradeDateBalance: number;
                valueDateBalance: number;
                initialCost: number;
                initialCostQuantity: number;
                valueChange: number;
            }
        > = {};

        const allInstrumentsById = keyBy((party.instruments as unknown[]).concat(instruments), "_id") as Record<string, Instrument>;
        // Skipping this step for the first quarter of the year, all previous journal entries are included in IB in the new general ledger
        if (previousPeriod === currentPeriod) {
            cloneDeep(previousJournalEntries).forEach((je) => {
                je.transactions.forEach((transaction) => {
                    if (
                        (AccountingTransactionType.InitialCost === transaction.type ||
                            AccountingTransactionType.ValueChange === transaction.type) &&
                        je.batch !== AccountingBatchType.A &&
                        je.batch !== AccountingBatchType.AR &&
                        round(transaction.amount, 2) !== 0
                    ) {
                        if (!journalEntryTransactionsByInstrumentId[transaction.instrumentId]) {
                            journalEntryTransactionsByInstrumentId[transaction.instrumentId] = {
                                transactions: [],
                                initialCostValueChangeTotal: 0,
                                tradeDateBalance: 0,
                                valueDateBalance: 0,
                                initialCost: 0,
                                initialCostQuantity: 0,
                                valueChange: 0
                            };
                        }
                        if (!transaction.journalEntry) {
                            transaction.journalEntry = je;
                        }

                        if (transaction.type === AccountingTransactionType.InitialCost) {
                            journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCost += transaction.amount;
                            journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCostQuantity += transaction.quantity
                                ? transaction.quantity
                                : 0;
                        }

                        if (transaction.type === AccountingTransactionType.ValueChange) {
                            journalEntryTransactionsByInstrumentId[transaction.instrumentId].valueChange += transaction.amount;
                        }

                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCostValueChangeTotal += transaction.amount;
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].transactions.push(transaction);
                    }
                });
            });
        }

        cloneDeep(gl.journalEntries).forEach((je) => {
            je.transactions.forEach((transaction) => {
                if (
                    (AccountingTransactionType.InitialCost === transaction.type ||
                        AccountingTransactionType.ValueChange === transaction.type) &&
                    je.batch !== AccountingBatchType.A &&
                    je.batch !== AccountingBatchType.AR &&
                    round(transaction.amount, 2) !== 0
                ) {
                    if (!journalEntryTransactionsByInstrumentId[transaction.instrumentId]) {
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId] = {
                            transactions: [],
                            initialCostValueChangeTotal: 0,
                            tradeDateBalance: 0,
                            valueDateBalance: 0,
                            initialCost: 0,
                            initialCostQuantity: 0,
                            valueChange: 0
                        };
                    }
                    if (!transaction.journalEntry) {
                        transaction.journalEntry = je;
                    }

                    if (transaction.type === AccountingTransactionType.InitialCost) {
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCost += transaction.amount;
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCostQuantity += transaction.quantity
                            ? transaction.quantity
                            : 0;
                    }

                    if (transaction.type === AccountingTransactionType.ValueChange) {
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].valueChange += transaction.amount;
                    }

                    journalEntryTransactionsByInstrumentId[transaction.instrumentId].initialCostValueChangeTotal += transaction.amount;
                    journalEntryTransactionsByInstrumentId[transaction.instrumentId].transactions.push(transaction);
                }

                // Calculating if need to adjust using forward cash
                if (AccountingTransactionType.InitialCost === transaction.type) {
                    if (!journalEntryTransactionsByInstrumentId[transaction.instrumentId]) {
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId] = {
                            transactions: [],
                            initialCostValueChangeTotal: 0,
                            tradeDateBalance: 0,
                            valueDateBalance: 0,
                            initialCost: 0,
                            initialCostQuantity: 0,
                            valueChange: 0
                        };
                    }
                    if (!transaction.journalEntry) {
                        transaction.journalEntry = je;
                    }

                    journalEntryTransactionsByInstrumentId[transaction.instrumentId].tradeDateBalance += transaction.quantity
                        ? transaction.quantity
                        : 0;
                    transaction.valueDate = transaction.valueDate ? transaction.valueDate : transaction.journalEntry.effectiveDate;
                    if (transaction.valueDate <= endDate) {
                        journalEntryTransactionsByInstrumentId[transaction.instrumentId].valueDateBalance += transaction.quantity
                            ? transaction.quantity
                            : 0;
                    }
                }
            });
        });

        for (const instrumentId of Object.keys(journalEntryTransactionsByInstrumentId)) {
            // Moving amount to result t-account if initialCost + valueChange accounting transactions are <0
            if (round(journalEntryTransactionsByInstrumentId[instrumentId].initialCostValueChangeTotal, 2) < 0) {
                const { values: mappingValues } = getTAccountMapping(null, gl.getInstrument(instrumentId), tAccountMappings);
                if (round(journalEntryTransactionsByInstrumentId[instrumentId].initialCost, 2) !== 0) {
                    const mv = mappingValues[AccountingTransactionType.InitialCost];
                    if (typeof mv === "undefined" || mv === null || mv === "") {
                        throw new Error(
                            `Invalid mapping value for type=${AccountingTransactionType.InitialCost}, instrumentId=${instrumentId}`
                        );
                    }

                    const tAccountNumberNegate = getAccountMappingValue(negateAccountMappingValue(mv));
                    const tAccountNumber = getAccountMappingValue(mv);

                    // Unnecessary to add if booked on the same account
                    if (tAccountNumberNegate !== tAccountNumber) {
                        const number = gl.nextBatchNumber(AccountingBatchType.A);
                        gl.maxBatchNumbers[AccountingBatchType.A] = number;

                        const automatedJournalEntry = cloneDeep(
                            this.createAdjustmentJournalEntry(
                                AccountingTransactionType.InitialCost,
                                AccountingTransactionType.InitialCost,
                                gl,
                                number,
                                endDate,
                                instrumentId,
                                tAccountNumber,
                                tAccountNumberNegate,
                                journalEntryTransactionsByInstrumentId[instrumentId].initialCost,
                                journalEntryTransactionsByInstrumentId[instrumentId].initialCostQuantity
                            )
                        );

                        gl.journalEntries.push(automatedJournalEntry);
                    }
                }
                if (round(journalEntryTransactionsByInstrumentId[instrumentId].valueChange, 2) !== 0) {
                    const mv = mappingValues[AccountingTransactionType.ValueChange];
                    if (typeof mv === "undefined" || mv === null || mv === "") {
                        throw new Error(
                            `Invalid mapping value for type=${AccountingTransactionType.ValueChange}, instrumentId=${instrumentId}`
                        );
                    }

                    const tAccountNumberNegate = getAccountMappingValue(negateAccountMappingValue(mv));
                    const tAccountNumber = getAccountMappingValue(mv);

                    // Unnecessary to add if booked on the same account
                    if (tAccountNumberNegate !== tAccountNumber) {
                        const number = gl.nextBatchNumber(AccountingBatchType.A);
                        gl.maxBatchNumbers[AccountingBatchType.A] = number;
                        const automatedJournalEntry = cloneDeep(
                            this.createAdjustmentJournalEntry(
                                AccountingTransactionType.ValueChange,
                                AccountingTransactionType.ValueChange,
                                gl,
                                number,
                                endDate,
                                instrumentId,
                                tAccountNumber,
                                tAccountNumberNegate,
                                journalEntryTransactionsByInstrumentId[instrumentId].valueChange
                            )
                        );
                        gl.journalEntries.push(automatedJournalEntry);
                    }
                }
            }

            // Adjustment between valueDate balance and tradeDate balance
            const adjustmentAmount = -round(
                journalEntryTransactionsByInstrumentId[instrumentId].tradeDateBalance -
                    journalEntryTransactionsByInstrumentId[instrumentId].valueDateBalance,
                gl.roundingDecimals
            );
            if (adjustmentAmount !== 0) {
                const { values: mappingValues } = getTAccountMapping(null, gl.getInstrument(instrumentId), tAccountMappings);
                const mv = mappingValues[AccountingTransactionType.ForwardCash];
                if (typeof mv === "undefined" || mv === null || mv === "") {
                    throw new Error(
                        `Invalid mapping value for type=${AccountingTransactionType.ForwardCash}, instrumentId=${instrumentId}`
                    );
                }
                // Only creating these adjustments if mapping is specified
                if (mv !== "15FCA/24FCA") {
                    const tAccountNumberForwardCash =
                        -adjustmentAmount < 0 ? getAccountMappingValue(negateAccountMappingValue(mv)) : getAccountMappingValue(mv);

                    const mvInitialCost = mappingValues[AccountingTransactionType.InitialCost];
                    if (typeof mvInitialCost === "undefined" || mvInitialCost === null || mvInitialCost === "") {
                        throw new Error(
                            `Invalid mapping value for type=${AccountingTransactionType.InitialCost}, instrumentId=${instrumentId}`
                        );
                    }
                    const tAccountNumberInitialCost =
                        adjustmentAmount < 0
                            ? getAccountMappingValue(negateAccountMappingValue(mvInitialCost))
                            : getAccountMappingValue(mvInitialCost);

                    // Calculate adjustmentAmount in accounting currency
                    let adjustmentAmountAccountingLocalCurrency = adjustmentAmount;
                    if (allInstrumentsById[instrumentId] && allInstrumentsById[instrumentId].currency !== gl.party.accountingCurrency) {
                        const fxInstrumentName = allInstrumentsById[instrumentId].currency + gl.party.accountingCurrency;
                        if (fxValuationsByInstrumentName[fxInstrumentName]) {
                            adjustmentAmountAccountingLocalCurrency =
                                fxValuationsByInstrumentName[fxInstrumentName].records[0].prices[0].value *
                                adjustmentAmountAccountingLocalCurrency;
                        }
                    }

                    const number = gl.nextBatchNumber(AccountingBatchType.A);
                    gl.maxBatchNumbers[AccountingBatchType.A] = number;

                    const automatedJournalEntry = cloneDeep(
                        this.createAdjustmentJournalEntry(
                            AccountingTransactionType.ForwardCash,
                            AccountingTransactionType.InitialCost,
                            gl,
                            number,
                            endDate,
                            instrumentId,
                            tAccountNumberForwardCash,
                            tAccountNumberInitialCost,
                            adjustmentAmountAccountingLocalCurrency,
                            adjustmentAmount
                        )
                    );

                    gl.journalEntries.push(automatedJournalEntry);
                }
            }
        }

        gl.journalEntries.forEach((je) => {
            gl.postProcessJournalEntry(
                je,
                roundMvs[AccountingTransactionType.InitialCost],
                AccountingTransactionType.Rounding,
                emptyObjectId
            );
        });

        return gl;
    }
}
