import React from "react";
import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import { Language } from "../Language";
import createPlotlyComponent from "react-plotly.js/factory";
import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface ICurrencyPieProps {
    data: any;
    locale?: Language;
    showHeader: boolean;
    width?: number;
    height?: number;
    showReferencePortfolio: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const CurrencyPie = ({
    data,
    showHeader,
    config,
    showReferencePortfolio,
    layout,
    locale = Language.Sv,
    ...props
}: ICurrencyPieProps): React.ReactElement => {
    let traces: any;
    let header = null;

    if (locale === "En") {
        header = "Currency Allocation";
    } else {
        if (locale === "Sv") {
            header = "Valutaallokering";
        }
    }
    const trace1 = {
        labels: data.data.currencies.map((d) => d.key),
        values: data.data.currencies.map((d) => d.value),
        textinfo: "percent+label",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        font: { size: 20 },
        type: "pie",
        hole: 0.4,
        sort: false
    };

    if (showReferencePortfolio) {
        const trace2 = {
            labels: data.data.currencies.map((d) => d.key),
            values: data.data.currencies.map((d) => d.referenceValue),
            textinfo: "percent+label",
            textposition: "inside",
            hovertemplate: "<extra></extra>%{label}<br>%{percent}",
            font: { size: 20 },
            type: "pie",
            hole: 0.4,
            sort: false
        };

        traces = [trace1, trace2];
    }
    traces = [trace1];

    const color = chroma("#2186C5");

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: [0, 0.5, -0.5, 1, -1, 1.5, -1.5, 2, -2, 2.5, -2.5].map((d) => color.darken(d).hex()),
        showlegend: true,
        margin: { t: -20, b: 0, l: 30, r: 0 },
        legend: { traceorder: "normal", x: 4, y: 0.5, orientation: "v" }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}

            <Plot data={traces as Plotly.PlotData[]} config={thisConfig as Plotly.Config} layout={thisLayout} {...props} />
        </React.Fragment>
    );
};
