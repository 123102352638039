import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ReactTable } from "../../components/react-table/ReactTable";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { Panel } from "@fluentui/react";
import { CustomerForm } from "./CustomerForm";

const CUSTOMERS = gql`
    query customers {
        customers {
            _id
            clientNumber
            clientAccount
            clientAccountLabel
            organizationName
            swedishFinancialAuthorityCategory
            swedishFinancialAuthorityCategoryString
        }
    }
`;

export const CustomersPage = (): React.ReactElement => {
    const params: any = useParams();
    const navigate = useNavigate();

    const [{ fetching, data, error }] = useQuery({
        query: CUSTOMERS,
        requestPolicy: "cache-and-network"
    });

    const { id } = params;

    const columns = useMemo(() => {
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/backoffice/customers/${row.original._id}`}>#</Link>;
                },
                size: 8
            },
            {
                header: "Client number",
                id: "clientNumber",
                accessorKey: "clientNumber",
                size: 20
            },
            {
                header: "Client account",
                id: "clientAccount",
                accessorKey: "clientAccount",
                size: 20
            },
            {
                header: "Organization name",
                id: "organizationName",
                accessorKey: "organizationName"
            },
            {
                header: "Client account label",
                id: "clientAccountLabel",
                accessorKey: "clientAccountLabel"
            },
            {
                header: "Swedish financial authority category",
                id: "swedishFinancialAuthorityCategory",
                accessorKey: "swedishFinancialAuthorityCategory",
                filter: SelectColumnFilter
            },
            {
                header: "Swedish financial authority category string",
                id: "swedishFinancialAuthorityCategoryString",
                accessorKey: "swedishFinancialAuthorityCategoryString"
            }
        ];
    }, []);

    if (fetching) return <div>Loading...</div>;

    if (error) {
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    }

    if (data.customers === null || data.customers.length === 0) return <div>No documents</div>;

    return (
        <div>
            <div>Number of documents: {data.customers.length}</div>
            <Link to={"/backoffice/customers/new"}>New customer</Link>
            <ReactTable columns={columns} data={data.customers} defaultHiddenColumns={[]} />
            <Panel
                isOpen={!!id}
                isBlocking={false}
                onDismiss={() => navigate(`/backoffice/customers`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                <CustomerForm id={id} />
            </Panel>
        </div>
    );
};
