import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { TimeSeries } from "../../../common/src";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);
interface IReturnVolScatterProps {
    timeseries: TimeSeries[];
    locale?: Language;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}
export const ReturnVolScatter = ({
    timeseries,
    locale = Language.Sv,
    config,
    layout,
    ...props
}: IReturnVolScatterProps): React.ReactElement => {
    let header: string = null;
    let xtext: string = null;
    let ytext: string = null;
    if (locale === "En") {
        header = "Return Vs Volatility";
        xtext = "volatility";
        ytext = "return";
    } else {
        if (locale === "Sv") {
            header = "Avkastning Vs volatilitet";
            xtext = "volatilitet";
            ytext = "avkastning";
        }
    }
    const hoverinit = "<extra></extra>";
    const yhover = " %{y:.1%}<br>";
    const xhover = " %{x:.1%}";
    const hovertemp: string = hoverinit.concat(ytext, yhover, xtext, xhover);
    const weeklyTimeSeries = timeseries.map((serie) => serie.resampleToWeekly());

    const trace: Partial<Plotly.PlotData> = {
        mode: "text+markers",
        marker: {
            line: { width: 0 },
            size: [...Array(timeseries.length)].map(() => 20),
            color: [...Array(timeseries.length)].map((d, i) => PlotlyDefaults.getColor(i % 4))
        },
        hovertemplate: hovertemp,
        textposition: "auto",
        x: weeklyTimeSeries.map((serie) => serie.volatility()),
        y: timeseries.map((serie) => serie.annualizedGeometricReturn()),
        text: timeseries.map((serie) => serie.name),
        textfont: {
            size: 8
        }
    };

    const localLayout: Partial<Plotly.Layout> = {
        showlegend: false,
        xaxis: {
            autorange: true,
            tickformat: ".1%",
            title: { text: xtext }
        },
        yaxis: {
            autorange: true,
            tickformat: ".1%",
            title: { text: ytext }
        },
        margin: { l: 45, b: 40, r: 10 }
    };

    // autorange 'close' option. 'range' provided outside overrides the auto calculated one
    if (layout?.xaxis?.autorange === "close") {
        const maxX = Math.max.apply(null, trace.x);
        const minX = Math.min.apply(null, trace.x);
        localLayout.xaxis.range = [minX - minX * 0.5, maxX + maxX * 0.5];
    }

    if (layout?.yaxis?.autorange === "close") {
        const minY = Math.min.apply(null, trace.y);
        const maxY = Math.max.apply(null, trace.y);
        localLayout.yaxis.range = [minY - minY * 0.8, maxY + maxY * 0.8];
    }

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            <h3>{header}</h3>
            <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
