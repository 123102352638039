import React from "react";
import { groupBy } from "lodash";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

import { Language } from "../Language";

interface ICountryPieProps {
    data: any;
    locale?: Language;
    layout?: Partial<Plotly.Layout>;
    style?: React.CSSProperties | undefined;
}

export const CountryPie = ({ data, locale = Language.Sv, layout, ...props }: ICountryPieProps): React.ReactElement => {
    const countryData = groupBy(data, "instrument.issuer.country");
    const exposureByCountry = {};
    let header = null;
    if (locale === "En") {
        header = "Country Allocation";
    } else {
        if (locale === "Sv") {
            header = "Landallokering";
        }
    }

    for (const [key, value] of Object.entries(countryData)) {
        if (key !== "undefined") {
            let totalExposure = 0;
            for (let i = 0; i < value.length; i++) {
                totalExposure += value[i].exposure;
            }
            if (key === "null") {
                exposureByCountry["Unknown"] = totalExposure;
            } else {
                exposureByCountry[key] = totalExposure;
            }
        }
    }

    const trace = {
        labels: Object.keys(exposureByCountry),
        values: Object.values(exposureByCountry),
        textinfo: "percent+label",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        font: { size: 20 },
        type: "pie" as any,
        sort: false,
        hole: 0.4
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#43a04c", "#307e41", "#1F5F32", "#bdbdbd"],
        showlegend: false,
        legend: { orientation: "v", traceorder: "normal" }
    };
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    return (
        <React.Fragment>
            <h3>{header}</h3>

            <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
