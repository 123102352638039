import React from "react";
import { cloneDeep } from "lodash";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { TimeSeries, AlignMethod } from "../../common/src";

import { PlotlyDefaults } from "./index";

const Plot = createPlotlyComponent(Plotly);

type PerformanceTimeSeriesChartInput = {
    masterTimeSerie: TimeSeries;
    timeseries: TimeSeries[];
    config?: PartialDeep<Plotly.Config>;
    layout?: PartialDeep<Plotly.Layout>;
    startDate?: string;
    style?: React.CSSProperties | undefined;
};
export const RelativeTimeSeriesChart = ({
    masterTimeSerie,
    timeseries,
    config,
    layout,
    startDate,
    ...props
}: PerformanceTimeSeriesChartInput): React.ReactElement => {
    const maxArray = [0.05];
    const diffTimeSeries = [];

    let masterTs = cloneDeep(masterTimeSerie);

    const tsArray = cloneDeep(timeseries);
    if (startDate) {
        masterTs = masterTs.range(new Date(startDate), masterTs.end);
        for (let i = 0; i < tsArray.length; i++) {
            tsArray[i] = TimeSeries.align(masterTs, tsArray[i], AlignMethod.Latest);
        }
    }

    if (tsArray && tsArray.length > 0) {
        for (let i = 0; i < tsArray.length; i++) {
            const ts = tsArray[i];
            if (ts.name !== masterTs.name) {
                const diff = masterTs.normalize(1).subtract(ts.normalize(1));
                diff.name = ts.name;
                const max = diff.__values.reduce((p, c) => Math.max(Math.abs(c), p), 0);
                maxArray.push(max);
                diffTimeSeries.push(diff);
            }
        }
    }

    const colors = ["#66BC6B", "#BDBDBD", "#B3E5FC", "#1F5F32", "#757575", "#01579B", "#AB47BC", "#4A148C"];

    const traces = diffTimeSeries.map((ts, index) => PlotlyDefaults.toTrace(ts, ts.name, colors[index + 1])) as Partial<Plotly.PlotData>[];

    for (let i = 0; i < traces.length; i++) {
        traces[i].fill = "tozeroy";
    }
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const localLayout: PartialDeep<Plotly.Layout> = {
        showlegend: false,
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        yaxis: { tickformat: ".1%" },
        xaxis: {
            title: "",
            hoverformat: "%Y-%m-%d",
            zeroline: false
        },
        width: null,
        height: null,
        margin: { l: 70, b: 70, t: 5 },
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",
            x: 0.01,
            xanchor: "left",
            y: 0.99,
            yanchor: "top"
        }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }
    return <Plot data={traces} layout={thisLayout as Plotly.Layout} config={thisConfig} useResizeHandler={true} {...props} />;
};

export default RelativeTimeSeriesChart;
