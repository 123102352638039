import { emptyObjectId } from "..";
import { cloneDeep, keyBy } from "lodash";

import {
    Instrument,
    TAccountMappingSelector,
    TAccountMappingSelectorKeyEnum,
    TAccountChart,
    TAccountMapping,
    TAccountTypeEnum
} from "../types.generated";

export interface TAccountMappingExtended extends TAccountMapping {
    tAccountChart: TAccountChart;
}

/*
export interface TAccountChart {
    _id: string;
    name: string;
    version: number;
    clientId: string;
    locked: boolean;
    tAccounts: TAccount[];
    tAccountMappings: TAccountMapping[];
}*/

/*
export enum TAccountMappingSelectorKey {
    OpeningBalance = "OpeningBalance",
    Rounding = "Rounding",
    Equity = "Equity"
}



export interface TAccount {
    number: string;
    type: TAccountType;
    description: string;
    sruCode: string;
    group1: string;
    group2: string;
    group3: string;
    group4: string;
    group5: string;
}
*/

/*
export interface TAccountMapping {
    tAccountChart: TAccountChart;
    selector: TAccountMappingSelector;
    values: TAccountMappingItem[];
}

export interface TAccountMappingSelector {
    key: TAccountMappingSelectorKey;
    instrumentId: string;
    productTypes: string[];
    notMatchProductTypes: boolean; // Means selector match if not in productTypes
    currencies: string[];
    notMatchCurrencies: boolean; // Means selector match if not in currencies
    categories: string[];
    notMatchCategories: boolean; // Means selector match if not in categories
}
*/
export interface TAccountMappingResult {
    values: TAccountMappingValues;
    name: string;
    index: number;
    tAccountChart: TAccountChart;
}
export interface TAccountMappingItem {
    key: string;
    value: string;
}

export type TAccountMappingValues = { [key: string]: string };

export const defaultTAccountMappingValues: TAccountMappingValues = {
    InitialCost: "13INIT",
    ValueChange: "14VAL",
    ForwardCash: "15FCA/24FCA",
    AccruedInterest: "17ACC/29ACC",
    Dividend: "82DIV",
    Fee: "65FEE",
    Interest: "83INT/81INT",
    Unrealized: "82UP/82UN",
    UnrealizedFx: "82UFXP/82UFXN",
    Realized: "82RP/82RN",
    RealizedFx: "82RFXP/82RFXN",
    ManagementFee: "82MGT",
    ManagementCost: "82MGTC",
    CustodyFee: "82CUS",
    Rebate: "82REB",
    Commission: "82COM",
    StampDuty: "82STMP",
    TaxRestitution: "82RES",
    ForeignTax: "82FTX",
    Tax: "82TAX",
    DividendPaid: "20DIVP",
    OpeningBalance: "20OPEN",
    Equity: "20EQT",
    ShareCapital: "20SC",
    Rounding: "37RND"
};

export function defaultTAccountType(accountNumber: string): TAccountTypeEnum {
    if (!accountNumber || accountNumber.match(/^1/)) {
        // if starts with 1
        return TAccountTypeEnum.Asset;
    }
    if (accountNumber.match(/^2/)) {
        // if starts with 2
        return TAccountTypeEnum.Liability;
    }
    return TAccountTypeEnum.Income; //IncomeExpense;
}

export function newTAccountMappingSelector(): TAccountMappingSelector {
    return {
        __typename: "TAccountMappingSelector",
        key: null,
        instruments: [],
        productTypes: null,
        notMatchProductTypes: false,
        currencies: null,
        notMatchCurrencies: false,
        categories: null,
        notMatchCategories: false
    };
}

export function matchSelector(key: string, instrument: Instrument, selector: TAccountMappingSelector): boolean {
    let keyMatch = true;
    let instrumentIdMatch = true;
    let productTypesMatch = true;
    let currenciesMatch = true;
    let categoriesMatch = true;
    if (key || selector.key) {
        keyMatch = selector.key === key;
    }
    if (instrument) {
        if (selector.instruments && selector.instruments.length > 0 && selector.instruments[0].instrumentId.toString() !== emptyObjectId) {
            const selectorInstrumentsById = keyBy(cloneDeep(selector.instruments), "instrumentId");
            if (selectorInstrumentsById[emptyObjectId]) {
                delete selectorInstrumentsById[emptyObjectId];
            }
            if (selectorInstrumentsById[instrument._id]) {
                instrumentIdMatch = selectorInstrumentsById[instrument._id].instrumentId === instrument._id;
            } else {
                instrumentIdMatch = false;
            }
        } else {
            if (selector.productTypes) {
                if (typeof instrument.productType === "undefined" || instrument.productType === null) {
                    productTypesMatch = false;
                } else {
                    const f = selector.productTypes.findIndex((d) => d === instrument.productType) >= 0;
                    productTypesMatch = (selector.notMatchProductTypes !== true && f) || (selector.notMatchProductTypes === true && !f);
                }
            }
            if (selector.currencies) {
                if (typeof instrument.currency === "undefined" || instrument.currency === null) {
                    currenciesMatch = false;
                } else {
                    const f = selector.currencies.findIndex((d) => d === instrument.currency) >= 0;
                    currenciesMatch = (selector.notMatchCurrencies !== true && f) || (selector.notMatchCurrencies === true && !f);
                }
            }
            if (selector.categories) {
                if (typeof instrument.category === "undefined" || instrument.category === null) {
                    categoriesMatch = false;
                } else {
                    const f = selector.categories.findIndex((d) => d === instrument.category) >= 0;
                    categoriesMatch = (selector.notMatchCategories !== true && f) || (selector.notMatchCategories === true && !f);
                }
            }
        }
    }
    return keyMatch && instrumentIdMatch && productTypesMatch && currenciesMatch && categoriesMatch;
}

export function selectorToString(selector: TAccountMappingSelector, instruments: Instrument[] = null): string {
    if (selector.key) {
        return `key IS "${selector.key}"`;
    }
    let res = "";

    if (selector.instruments && selector.instruments.length > 0) {
        const ins: string[] = [];
        for (const selectorInstrument of selector.instruments) {
            let instrumentFound = null;
            if (instruments) {
                instrumentFound = instruments.find((d) => d._id === selectorInstrument.instrumentId);
            }
            if (instrumentFound) {
                ins.push(instrumentFound.name);
            } else {
                ins.push(selectorInstrument.instrumentId);
            }
        }

        if (ins.length > 0) {
            res += `instruments ARE [${ins.map((name) => `"${name}"`).join(",")}]`;
        }
    }
    if (selector.productTypes) {
        res +=
            (res ? " AND " : "") +
            `productType${selector.notMatchProductTypes === true ? " NOT" : ""} IN [${selector.productTypes
                .map((d) => `"${d}"`)
                .join(",")}]`;
    }
    if (selector.currencies) {
        res +=
            (res ? " AND " : "") +
            `currency${selector.notMatchCurrencies === true ? " NOT" : ""} IN [${selector.currencies.map((d) => `"${d}"`).join(",")}]`;
    }
    if (selector.categories) {
        res +=
            (res ? " AND " : "") +
            `category${selector.notMatchCategories === true ? " NOT" : ""} IN [${selector.categories.map((d) => `"${d}"`).join(",")}]`;
    }

    if (!res) {
        return "TRUE";
    }
    return res;
}

export function getTAccountMapping(
    key: TAccountMappingSelectorKeyEnum | null,
    instrument: Instrument | null,
    mappings: TAccountMappingExtended[]
): TAccountMappingResult {
    let match: TAccountMappingItem[] = null;
    let name: string = null;
    let tAccountChart: TAccountChart = null;
    let index = -1;

    for (let i = 0; i < mappings.length; i++) {
        const m = mappings[i];
        if (matchSelector(key, instrument, m.selector)) {
            match = m.values;
            name = selectorToString(m.selector);
            index = i;
            tAccountChart = m.tAccountChart;
        }
    }
    const res = { ...defaultTAccountMappingValues };
    if (match) {
        match.forEach((m) => (res[m.key] = m.value));
    }
    return { values: res, name, index, tAccountChart };
}

const accountMappingValueRegex = /^([^\s]+)\s?\/\s?([^\s]+)$/; // Regex to split two account numbers separated by slash

export function getAccountMappingValue(value: string): string {
    if (!value) {
        return null;
    }
    const m = value.match(accountMappingValueRegex);
    if (m) {
        return m[1].trim();
    }
    return value.trim();
}

export function negateAccountMappingValue(value: string): string {
    if (!value) {
        return null;
    }
    const m = value.match(accountMappingValueRegex);
    if (m) {
        return (m[2] + "/" + m[1]).trim();
    }
    return value.trim();
}
