import React, { useMemo, Fragment } from "react";
import { useQuery, gql } from "urql";
import { Link } from "react-router-dom";
import { Svgs } from "../../../components/src";

import { numberFormatFun } from "../../../common/src";
import { useQueryState } from "../common/use-query-state";
import { Page } from "../components/Page";
import { defaultDate, DateForm } from "../common/dateForm";
import { dateToYYYYMMDDString } from "../common/Utils";
import { cloneDeep, sortBy } from "lodash";
import { PartyExternalAccountType, PartyType } from "../types.generated";
import {
    SelectColumnFilter,
    SelectColumnFilterType,
    SelectNotZeroColumnFilter,
    notEquals
} from "../components/react-table/ReactTableFilters";
import { ReactTable } from "../components/react-table/ReactTable";

const GET_RECONCILE_QUANTITY = gql`
    query reconcilePositions($date: GraphQLDateString!) {
        reconcilePositions(date: $date) {
            id
            isCashAccount
            clientId
            client {
                name
                types
            }
            positionQuantity
            instrumentId
            instrument {
                name
                isin
                iban
                quantityDecimals
                type
                modelType
            }
            custodianId
            custodian {
                name
            }
            externalAccountId
            externalAccount {
                name
                type
            }
            custodianQuantity
            positionDate
            custodianDate
            custodianPositionId
            diff
            comment
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const PositionReconciliationPage = (): React.ReactElement => {
    const [endDate] = useQueryState("endDate", dateToYYYYMMDDString(defaultDate()));
    const columns = useMemo(
        () => [
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.clientId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 170
            },
            {
                header: "Client type",
                id: "client.types",
                accessorKey: "client.types",
                accessorFn: (row) => (row.client ? row.client.types.join(", ") : null),
                filter: SelectColumnFilterType(PartyType),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <div>{row.original.client.types.join(", ")}</div>;
                    } else {
                        return null;
                    }
                },
                size: 170
            },
            {
                header: "Custodian",
                id: "custodian.name",
                accessorKey: "custodian.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.custodian) {
                        return <Link to={`/parties/${row.original.custodianId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 170
            },
            {
                header: "Instrument",
                id: "instrument.name",
                accessorKey: "instrument.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                size: 120
            },
            {
                header: "ISIN",
                id: "instrument.isin",
                accessorKey: "instrument.isin",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 120
            },
            {
                header: "IBAN",
                id: "instrument.iban",
                accessorKey: "instrument.iban",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 120
            },
            {
                header: "Model type",
                id: "instrument.modelType",
                accessorKey: "instrument.modelType",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                size: 140,
                filter: SelectColumnFilter
            },
            {
                header: "Position date",
                accessorKey: "positionDate",
                size: 80
            },
            {
                header: "Custodian date",
                accessorKey: "custodianDate",
                filter: SelectColumnFilter,
                size: 80
            },
            {
                header: "External account id",
                id: "externalAccount.name",
                accessorKey: "externalAccount.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client && row.original.externalAccount) {
                        return (
                            <Link to={`/parties/${row.original.clientId}/externalAccounts/${row.original.externalAccountId}`}>
                                {row.original.externalAccount.name}
                            </Link>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 170
            },
            {
                header: "Position quantity",
                accessorKey: "positionQuantity",
                filterFn: "startsWith",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "right" }}>
                            {numberFormatFun("# ##0." + "#".repeat(row.original.instrument.quantityDecimals))(cellProps.getValue())}
                        </div>
                    );
                },
                size: 120
            },
            {
                header: "Custodian quantity",
                accessorKey: "custodianQuantity",
                filterFn: "startsWith",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "right" }}>
                            {numberFormatFun("# ##0." + "#".repeat(row.original.instrument.quantityDecimals))(cellProps.getValue())}
                        </div>
                    );
                },
                size: 120
            },
            {
                header: "Diff",
                accessorKey: "diff",
                filter: SelectNotZeroColumnFilter,
                filterFn: notEquals,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "right" }}>
                            {numberFormatFun("# ##0." + "#".repeat(row.original.instrument.quantityDecimals))(cellProps.getValue())}
                        </div>
                    );
                },
                size: 100
            },
            {
                header: "#",
                accessorKey: "custodianPositionId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.custodianPositionId) {
                        return <Link to={`/reconciliation/custodianpositions/${row.original.custodianPositionId}`}>{"#"}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 30
            },
            {
                header: "Comment",
                accessorKey: "comment",
                filter: SelectColumnFilter
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                accessorFn: (row) => (row.updateUserInfo ? row.updateUserInfo.name : null),
                filter: SelectColumnFilter
            }
        ],
        []
    );

    const [{ fetching, error, data }] = useQuery({
        query: GET_RECONCILE_QUANTITY,
        requestPolicy: "network-only",
        variables: { dateSweden: endDate, date: endDate }
    });

    if (fetching)
        return (
            <Page className="loader">
                <div>
                    <div>Fetching reconciliation</div>
                    <Svgs.Loader />
                </div>
            </Page>
        );
    if (error) return <div>`Error! ${error.message}`</div>;

    const clonedData = cloneDeep(data);

    let positions: any[] = clonedData.reconcilePositions;
    //positions = positions.filter((position) => position.isCashAccount !== true); // else isCashAccount===null get filtered
    positions = positions.filter(
        (position) =>
            position.externalAccount.type === PartyExternalAccountType.SecuritiesAccount ||
            position.externalAccount.type === PartyExternalAccountType.Equity ||
            position.externalAccount.type === PartyExternalAccountType.CashAccount
    );
    const result = sortBy(positions, "clientId", "externalAccountId", "instrument.name");

    return (
        <Fragment>
            <div className="d-flex mb-2">
                <DateForm defaultDateString={endDate} dateName="endDate"></DateForm>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <div>Please note that positions posted or held as collateral are handled elsewhere</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>Number of positions: {result.length}</p>
                    <ReactTable columns={columns} data={result} defaultHiddenColumns={[]} />
                </div>
            </div>
        </Fragment>
    );
};
