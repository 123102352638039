import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);
interface IEsgShareProps {
    data: any;
    locale?: Language;
    showHeader: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const EsgShare = ({ data, showHeader, locale = Language.Sv, layout, config, ...props }: IEsgShareProps): React.ReactElement => {
    let header: string = null;
    let explanation: string = null;
    if (locale === Language.En) {
        header = "Sustainability";
        explanation = "The diagram shows the portion of the fund that passes or does not pass Captor's standard screening.";
    } else {
        if (locale === Language.Sv) {
            header = "Hållbarhet";
            explanation = "Diagrammet visar hur stor del av fonden som klarar eller inte klarar Captors standardscreening.";
        }
    }
    const trace: Partial<Plotly.PlotData> = {
        labels: data.data.esgScreenings.map((d) => d.esgScreening),
        values: data.data.esgScreenings.map((d) => d.value),
        textinfo: "label+percent",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        //font: { size: 20 },
        type: "pie",
        hole: 0.4
        //sort: false
    };

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#43a04c", "#307e41", "#1F5F32", "#bdbdbd"],
        showlegend: false,
        legend: { orientation: "v", traceorder: "normal" }
    };
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}

            <Plot data={[trace]} config={thisConfig} layout={thisLayout} {...props} />

            <small>{explanation}</small>
        </React.Fragment>
    );
};
