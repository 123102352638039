import React, { ReactElement } from "react";
import { gql, useQuery } from "urql";
import stableStringify from "json-stable-stringify";
import { isValidMongoDBObjectID } from "../common/Utils";
import { useParams } from "react-router-dom";

const getValuation = gql`
    query getValuation($_id: GraphQLObjectId!) {
        valuation(_id: $_id) {
            _id
            instrumentId
            date
            updateTimestamp
            records {
                source
                bloombergTicker
                writeTimestamp
                writeUser
                updateUserInfo {
                    name
                }
                prices {
                    type
                    value
                    currency
                }
            }
        }
    }
`;
export const ValuationPage = (): ReactElement => {
    const params: any = useParams();
    let id = "000000000000000000000000";
    if (isValidMongoDBObjectID(params.id)) {
        id = params.id;
    }
    const [{ fetching, error, data }] = useQuery({ query: getValuation, variables: { _id: id } });
    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    if (!isValidMongoDBObjectID(params.id)) {
        return <p>error: not a valid objectId</p>;
    }

    return <pre>{stableStringify(data.valuation, { space: "  " })}</pre>;
};
