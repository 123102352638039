import React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { useQueryState } from "../../common/use-query-state";
import { DateForm } from "../../common/dateForm";
import { TradingManager } from ".";

export interface Props {
    lookThrough: boolean;
    tradingView: boolean;
}

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const TradingManagerView = (props: Props): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));

    const { id }: any = useParams();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }

    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
                {!check_today_date(endDate) ? (
                    <Alert className="ms-2" variant={"danger"}>
                        Historical or future date selected
                    </Alert>
                ) : null}
            </div>
            <br />
            <div className="row">
                <div className={"col-12"}>
                    <TradingManager
                        clientIds={[id]}
                        endDate={endDate as string}
                        lookThrough={props.lookThrough}
                        tradingView={props.tradingView}
                    />
                </div>
            </div>
        </div>
    );
};
