import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Column } from "../../../../components/src";
import { gql, useQuery } from "urql";

import { usePrevious } from "../../common/Utils";
import { Party, TradingManagerColumn, InstrumentModelTypeEnum, Nav, Instrument, CurrencyEnum } from "../../types.generated";
import { numberFormatFun } from "../../../../common/src";
import { Link } from "react-router-dom";

const getTradingmanager = gql`
    query getTradingmanager(
        $clientIds: [GraphQLObjectId!]
        $endDate: GraphQLDateString
        $lookThrough: Boolean
        $clientId: GraphQLObjectId
    ) {
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: $lookThrough) {
            name
            instrumentId
            quantity
            currency
            localExposure
            modelType
            accountName
            accountId
            instrument {
                model {
                    legs {
                        currency
                        payLeg
                        cashFlows {
                            exDate
                            notional
                            payDate
                        }
                    }
                }
            }
        }
        party(_id: $clientId) {
            _id
            accountingCurrency
            fundInfo {
                classes {
                    instrumentId
                    fxHedgeAccountId
                }
            }
            accounts {
                _id
                name
            }
        }
        navs(clientId: $clientId, lastOnly: true) {
            date
            data {
                classes {
                    instrumentId
                    navCurrency
                    instrument {
                        name
                    }
                    navAfterAdjustmentsAfterOrders
                }
            }
        }
    }
`;

interface TradingManagerColumnExtended extends TradingManagerColumn {
    hedgeType?: string;
    valueDate?: string;
    amount1?: number;
    currency1?: string;
    amount2?: number;
    currency2?: string;
}

interface FxExposure {
    currency: string;
    localExposure: number;
}

interface FxExposureCalc extends FxExposure {
    cash?: number;
    hedgedAmount?: number;
    hedgeDiff?: number;
    hedgeDiffIncCash?: number;
}

interface CashFlow {
    currency: string;
    amount?: number;
}

interface CashFlowDate extends CashFlow {
    exDate?: string;
    valueDate: string;
}

const createAccountTypeDict = (party: Party) => {
    const dictAccountType: Record<string, string> = {};
    const fxHedgeAccountIds =
        party.fundInfo && party.fundInfo.classes ? party.fundInfo.classes.map(({ fxHedgeAccountId }) => fxHedgeAccountId) : [];
    party.accounts.forEach((account) => {
        if (fxHedgeAccountIds.indexOf(account._id) !== -1) {
            dictAccountType[account.name] = "Class hedge";
        } else {
            dictAccountType[account.name] = "Asset hedge";
        }
    });
    return dictAccountType;
};

const getClassData = (party: Party, navData: Nav[], hedgingPositionsType: TradingManagerColumnExtended[]) => {
    const classData: Record<string, any>[] = [];
    const fxHedgeInstrumentIds =
        party.fundInfo && party.fundInfo.classes
            ? party.fundInfo.classes
                  .filter(({ fxHedgeAccountId }) => fxHedgeAccountId !== "000000000000000000000000")
                  .map(({ instrumentId }) => instrumentId)
            : [];
    if (navData && navData.length) {
        navData[0].data.classes.forEach((fundClass) => {
            if (fxHedgeInstrumentIds.indexOf(fundClass.instrumentId) !== -1) {
                const fxHedgeAccountId = party.fundInfo.classes
                    .filter(({ instrumentId }) => instrumentId === fundClass.instrumentId)
                    .map(({ fxHedgeAccountId }) => fxHedgeAccountId);
                const hedgingAmounts = getHedgingAmountItemsForAccount(hedgingPositionsType, fxHedgeAccountId[0]);
                const sumhedgingAmountsAssets = sumHedgingAmounts(hedgingAmounts);
                const hedgeAmount = sumhedgingAmountsAssets
                    .filter(({ currency }) => currency === fundClass.navCurrency)
                    .map(({ amount }) => amount)[0];
                classData.push({
                    name: fundClass.instrument.name,
                    currency: fundClass.navCurrency,
                    nav: fundClass.navAfterAdjustmentsAfterOrders,
                    hedgedAmount: hedgeAmount,
                    hedgeDiff: Number(fundClass.navAfterAdjustmentsAfterOrders) - hedgeAmount
                });
            }
        });
    }

    return classData;
};

// Sum local exposure for each currency
const getFxExposureAssets = (data: [TradingManagerColumn], hedgingCurrency: string, fxHedgeModels: string[], cashModels: string[]) => {
    const fxExposure: FxExposure[] = [];

    const sumFxExposure = function (tradingmanager: [TradingManagerColumn], currency_sum: string, fxHedgeModels, cashModels) {
        return tradingmanager
            .filter(({ currency }) => currency === currency_sum)
            .filter(({ modelType }) => fxHedgeModels.indexOf(modelType) === -1)
            .filter(({ modelType }) => cashModels.indexOf(modelType) === -1)
            .reduce(function (prev, cur) {
                return prev + cur.localExposure;
            }, 0);
    };

    const uniqueCurrenciesSorted = [...new Set(data.map(({ currency }) => currency))].sort();
    uniqueCurrenciesSorted.forEach((ccy) => {
        const sumTemp = sumFxExposure(data, ccy, fxHedgeModels, cashModels);
        if (ccy !== hedgingCurrency) {
            fxExposure.push({ currency: ccy, localExposure: sumTemp });
        }
    });
    return fxExposure;
};

// Sum local exposure for each currency
const getFxExposureCash = (data: TradingManagerColumn[], cashModels: string[]) => {
    const fxExposure: FxExposure[] = [];

    const sumFxExposure = function (tradingmanager: TradingManagerColumn[], currency_sum: string, cashModels: string[]) {
        return tradingmanager
            .filter(({ currency }) => currency === currency_sum)
            .filter(({ modelType }) => cashModels.indexOf(modelType) !== -1)
            .reduce(function (prev, cur) {
                return prev + cur.localExposure;
            }, 0);
    };

    const uniqueCurrenciesSorted = [...new Set(data.map(({ currency }) => currency))].sort();
    uniqueCurrenciesSorted.forEach((ccy) => {
        const sumTemp = sumFxExposure(data, ccy, cashModels);
        fxExposure.push({ currency: ccy, localExposure: sumTemp });
    });
    return fxExposure;
};

const getHedgingPositions = (data: TradingManagerColumn[], fxHedgeModels: string[]) => {
    return data.filter(({ modelType }) => fxHedgeModels.indexOf(modelType) !== -1);
};

const getFxSwapCashFlows = (instrument: Instrument) => {
    const cashFlows: CashFlowDate[] = [];
    for (let i = 0; i < 2; i++) {
        const cfItem = instrument.model.legs[i].cashFlows[0];
        let amountSign: number;
        if (instrument.model.legs[i].payLeg === true) {
            amountSign = -cfItem.notional;
        } else {
            amountSign = cfItem.notional;
        }
        cashFlows.push({
            currency: instrument.model.legs[i].currency,
            amount: amountSign,
            exDate: cfItem.exDate,
            valueDate: cfItem.payDate
        });
    }
    return cashFlows;
};

const getHedgingAmountItems = (hedgingPositionsType: TradingManagerColumnExtended[], hedgeType: string) => {
    const hedgingAmountItems: CashFlowDate[] = [];
    hedgingPositionsType.forEach((pos) => {
        if (hedgeType.toLowerCase() === "all") {
            const fxSwapCashflows = getFxSwapCashFlows(pos.instrument);
            fxSwapCashflows[0].amount = fxSwapCashflows[0].amount * pos.quantity;
            fxSwapCashflows[1].amount = fxSwapCashflows[1].amount * pos.quantity;
            hedgingAmountItems.push(...fxSwapCashflows);
        } else if (hedgeType === pos.hedgeType) {
            const fxSwapCashflows = getFxSwapCashFlows(pos.instrument);
            fxSwapCashflows[0].amount = fxSwapCashflows[0].amount * pos.quantity;
            fxSwapCashflows[1].amount = fxSwapCashflows[1].amount * pos.quantity;
            hedgingAmountItems.push(...fxSwapCashflows);
        }
    });
    return hedgingAmountItems;
};

const getHedgingAmountItemsForAccount = (hedgingPositionsType: TradingManagerColumnExtended[], accountId: string) => {
    const hedgingAmountItems = [];
    hedgingPositionsType.forEach((pos) => {
        if (pos.accountId === accountId) {
            const fxSwapCashflows = getFxSwapCashFlows(pos.instrument);
            fxSwapCashflows[0].amount = fxSwapCashflows[0].amount * pos.quantity;
            fxSwapCashflows[1].amount = fxSwapCashflows[1].amount * pos.quantity;
            hedgingAmountItems.push(...fxSwapCashflows);
        }
    });
    return hedgingAmountItems;
};

const sumHedgingAmounts = (hedgingAmountItems: CashFlowDate[]) => {
    const sumResult: CashFlow[] = [];
    const sumFunc = function (data: CashFlowDate[], currency_sum: string) {
        return data
            .filter(({ currency }) => currency === currency_sum)
            .reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
    };

    const uniqueCurrenciesSorted = [...new Set(hedgingAmountItems.map(({ currency }) => currency))].sort();
    uniqueCurrenciesSorted.forEach((ccy) => {
        const sumTemp = sumFunc(hedgingAmountItems, ccy);
        sumResult.push({ currency: ccy, amount: sumTemp });
    });
    return sumResult;
};

const sumHedgingAmountsPerValueDate = (hedgingAmountItems: CashFlowDate[], hedgingCurrency: string) => {
    const sumFunc = function (data: CashFlowDate[], currency_sum: string, date_sum: string) {
        return data
            .filter(({ currency }) => currency === currency_sum)
            .filter(({ valueDate }) => valueDate === date_sum)
            .reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
    };

    const uniqueCurrenciesSorted: string[] = [...new Set(hedgingAmountItems.map(({ currency }) => currency))].sort();
    const uniqueValueDatesSorted: string[] = [...new Set(hedgingAmountItems.map(({ valueDate }) => valueDate))].sort();
    const sumResult: Record<string, string>[] = [];
    uniqueCurrenciesSorted.forEach((ccy) => {
        const valueDateSum = [];
        uniqueValueDatesSorted.forEach((valueDate) => {
            const sumTemp = sumFunc(hedgingAmountItems, ccy, valueDate);
            valueDateSum.push({ valueDate: valueDate, sum: sumTemp });
        });
        if (ccy !== hedgingCurrency) {
            const newItem: Record<string, string> = {};
            newItem.currency = ccy;
            valueDateSum.forEach((valueDateSumItem) => {
                newItem[valueDateSumItem.valueDate] = valueDateSumItem.sum;
            });
            sumResult.push(newItem);
        }
    });
    return { sumResult, uniquevalueDatesSorted: uniqueValueDatesSorted };
};

const mergeAssetData = (main_data: FxExposure[], add_data_cash: FxExposure[], add_data_hedge: CashFlow[]) => {
    const newData: FxExposureCalc[] = main_data.map((item) => Object.assign({}, item, { selected: false }));
    newData.forEach((item) => {
        const test = add_data_cash.filter(({ currency }) => currency === item.currency);
        item.cash = test.length === 0 ? 0 : test[0].localExposure;
    });
    newData.forEach((item) => {
        const test = add_data_hedge.filter(({ currency }) => currency === item.currency);
        item.hedgedAmount = test.length === 0 ? 0 : test[0].amount;
        item.hedgeDiff = item.localExposure + item.hedgedAmount;
        item.hedgeDiffIncCash = item.hedgeDiff + item.cash;
    });
    return newData;
};

const addHedgingPositionsType = (positions: TradingManagerColumn[], dictAccountType: Record<string, string>) => {
    const newData: TradingManagerColumnExtended[] = positions.map((item) => Object.assign({}, item, { selected: false }));
    newData.forEach((item) => {
        const cashfFlows = getFxSwapCashFlows(item.instrument);
        item.hedgeType = dictAccountType[item.accountName];
        item.valueDate = cashfFlows[0].valueDate;
        if (cashfFlows[0].currency === "SEK") {
            item.amount1 = cashfFlows[1].amount;
            item.currency1 = cashfFlows[1].currency;
            item.amount2 = cashfFlows[0].amount;
            item.currency2 = cashfFlows[0].currency;
        } else {
            item.amount1 = cashfFlows[0].amount;
            item.currency1 = cashfFlows[0].currency;
            item.amount2 = cashfFlows[1].amount;
            item.currency2 = cashfFlows[1].currency;
        }
    });
    return newData;
};

export function FxRisk({
    clientId,
    endDate,
    lookThrough
}: {
    clientId: string;
    endDate: string;
    lookThrough: boolean;
}): React.ReactElement {
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);

    const [{ fetching, error, data }] = useQuery({
        query: getTradingmanager,
        requestPolicy: "network-only",
        variables: { clientIds: [clientId], endDate, lookThrough, clientId }
    });

    const [tradingmanager, setTradingmanager] = useState(null);
    const [partyData, setpartyData] = useState(null);
    const [navData, setnavData] = useState(null);

    useEffect(() => {
        if (data) {
            setTradingmanager(data.tradingmanager);
            // TODO: Is this the way to handle several data fields?
            setpartyData(data.party);
            setnavData(data.navs);
        } else if (id !== previousId) {
            setTradingmanager(null);
            setpartyData(null);
            setnavData(null);
        }
    }, [tradingmanager, data, id, previousId]);

    if (fetching) return <div>Loading</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (!tradingmanager) return <div>"No data found"</div>;
    if (!partyData) return <div>No party found or you do not have access to this page</div>;

    const fxHedgeModels = [InstrumentModelTypeEnum.FxSwap];
    const cashModels = [InstrumentModelTypeEnum.Balance];
    const hedgingCurrency = partyData ? partyData.accountingCurrency : CurrencyEnum.SEK;

    const accountTypeDict = createAccountTypeDict(partyData);
    const fxExposureAssets = getFxExposureAssets(tradingmanager, hedgingCurrency, fxHedgeModels, cashModels);
    const fxExposureCash = getFxExposureCash(tradingmanager, cashModels);
    const hedgingPositions = getHedgingPositions(tradingmanager, fxHedgeModels);
    const hedgingPositionsType = addHedgingPositionsType(hedgingPositions, accountTypeDict);

    const classData = getClassData(partyData, navData, hedgingPositionsType);

    const hedgingAmountsAll = getHedgingAmountItems(hedgingPositionsType, "all");
    const hedgingAmountsAssets = getHedgingAmountItems(hedgingPositionsType, "Asset hedge");
    const hedgingAmountsClass = getHedgingAmountItems(hedgingPositionsType, "Class hedge");
    const hedgingAmountsPerValueDateStructAll = sumHedgingAmountsPerValueDate(hedgingAmountsAll, hedgingCurrency);
    const hedgingAmountsPerValueDateStructAssets = sumHedgingAmountsPerValueDate(hedgingAmountsAssets, hedgingCurrency);
    const hedgingAmountsPerValueDateStructClass = sumHedgingAmountsPerValueDate(hedgingAmountsClass, hedgingCurrency);

    const sumhedgingAmountsAssets = sumHedgingAmounts(hedgingAmountsAssets);
    const mergedData = mergeAssetData(fxExposureAssets, fxExposureCash, sumhedgingAmountsAssets);

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <Grid header="FX risk => adjust with external trade" data={mergedData} sortable tableClassName="table-xs">
                        <Column field="currency" title="Currency" className="grid-column-sticky" />
                        <Column field="localExposure" title="Assets" format={numberFormatFun("# ##0")} />
                        <Column field="hedgedAmount" title="Hedged amount" format={numberFormatFun("# ##0")} />
                        <Column field="hedgeDiff" title="Hedge diff" format={numberFormatFun("# ##0")} />
                        <Column field="cash" title="Cash" format={numberFormatFun("# ##0")} />
                        <Column field="hedgeDiffIncCash" title="Diff+cash" format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
                <div className="col">
                    <Grid header="FX risk (classes) => adjust with internal trade" data={classData} sortable tableClassName="table-xs">
                        <Column field="name" title="Class name" className="grid-column-sticky" />
                        <Column field="currency" title="Currency" />
                        <Column field="nav" title="NAV" format={numberFormatFun("# ##0")} />
                        <Column field="hedgedAmount" title="Hedged amount" format={numberFormatFun("# ##0")} />
                        <Column field="hedgeDiff" title="Hedge diff" format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid
                        header="Hedging ladder (all) => rolls with external hedge"
                        data={hedgingAmountsPerValueDateStructAll.sumResult}
                        sortable
                        tableClassName="table-xs"
                    >
                        <Column field="currency" title="Currency" className="grid-column-sticky" />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[0]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[1]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[2]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[3]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[4]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructAll.uniquevalueDatesSorted[5]} format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid
                        header="Hedging ladder (assets)"
                        data={hedgingAmountsPerValueDateStructAssets.sumResult}
                        sortable
                        tableClassName="table-xs"
                    >
                        <Column field="currency" title="Currency" className="grid-column-sticky" />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[0]}
                            format={numberFormatFun("# ##0")}
                        />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[1]}
                            format={numberFormatFun("# ##0")}
                        />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[2]}
                            format={numberFormatFun("# ##0")}
                        />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[3]}
                            format={numberFormatFun("# ##0")}
                        />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[4]}
                            format={numberFormatFun("# ##0")}
                        />
                        <Column
                            field={hedgingAmountsPerValueDateStructAssets.uniquevalueDatesSorted[5]}
                            format={numberFormatFun("# ##0")}
                        />
                    </Grid>
                </div>
                <div className="col">
                    <Grid
                        header="Hedging ladder (classes) => rolls with internal trade"
                        data={hedgingAmountsPerValueDateStructClass.sumResult}
                        sortable
                        tableClassName="table-xs"
                    >
                        <Column field="currency" title="Currency" className="grid-column-sticky" />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[0]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[1]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[2]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[3]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[4]} format={numberFormatFun("# ##0")} />
                        <Column field={hedgingAmountsPerValueDateStructClass.uniquevalueDatesSorted[5]} format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="Hedging positions per account" data={hedgingPositionsType} sortable tableClassName="table-xs">
                        <Column field="accountName" title="Account" className="grid-column-sticky" />
                        <Column
                            field="name"
                            title="Name"
                            format={(value, item) => {
                                return item && item.instrumentId ? (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="modelType" title="Model type" />
                        <Column field="hedgeType" title="Hedge type" />
                        <Column field="valueDate" title="Value date" />
                        <Column field="quantity" title="Quantity" />
                        <Column field="amount1" title="Amount 1" format={numberFormatFun("# ##0")} />
                        <Column field="currency1" title="Currency 1" />
                        <Column field="amount2" title="Amount 2" format={numberFormatFun("# ##0")} />
                        <Column field="currency2" title="Currency 2" />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}
