import React from "react";
import { useParams } from "react-router-dom";

import { Counterparty } from ".";

export const CounterpartyView = (): React.ReactElement => {
    const { id } = useParams<"id">();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }
    return (
        <div>
            <Counterparty clientId={id} />
        </div>
    );
};
