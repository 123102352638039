import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { PartyBaseTab } from "./tabs/PartyBaseTab";
import { PartyAccountsTab } from "./tabs/PartyAccountsTab";
import { PartyExternalAccountsTab } from "./tabs/PartyExternalAccountsTab";
import { PartyInstrumentsTab } from "./tabs/PartyInstrumentsTab";
import { PartyFundInfoTab } from "./tabs/PartyFundInfoTab";
import { useGetPartyQuery } from "./PartyQuery.generated";
import { usePrevious } from "../../common/Utils";
import { PartyType } from "../../types.generated";
import { cloneDeep } from "lodash";
import { PartyIssuerTab } from "./tabs/PartyIssuerTab";
import { PartyDocsTab } from "./tabs/PartyDocsTab";

export interface PartiesParamTypes {
    id: string;
    tabId?: string;
    itemId?: string;
}

const availableTabs = (types?: readonly PartyType[]): Record<string, string> => {
    const tabs: Record<string, string> = {
        base: "base"
    };

    if (types && types.includes(PartyType.Fund)) {
        tabs.fundInfo = "fundInfo";
    }

    if (types && types.includes(PartyType.Issuer)) {
        tabs.issuer = "issuer";
    }

    if (types && types.includes(PartyType.Client)) {
        tabs.accounts = "accounts";
        tabs.externalAccounts = "externalAccounts";
        tabs.instruments = "instruments";
    }

    tabs.docs = "docs";

    return tabs;
};

export const PartyPage = (): ReactElement => {
    const navigate = useNavigate();
    const { id: partyId, tabId } = useParams<"id" | "tabId">();
    const previousPartyId: string = usePrevious(partyId);
    const [activeTabId, setActiveTabId] = useState(tabId);
    const [party, setParty] = useState(null);

    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (previousPartyId && previousPartyId !== partyId) {
            setParty(null);
            refetch();
        } else if (partyId === "new" && party === null) {
            setParty({ _id: "000000000000000000000000", name: "", longName: "", types: [] });
        } else if (data) {
            if (party === null) {
                setParty(data.party);
            }
        }
    }, [previousPartyId, partyId, data, party, setParty, refetch]);

    useEffect(() => {
        const types = data && data.party ? cloneDeep(data.party.types) : null;
        const tabs = availableTabs(types);
        if (!(tabId in tabs)) {
            setActiveTabId("base");
        } else {
            setActiveTabId(tabId);
        }
    }, [tabId, data, setActiveTabId]);

    const onTabChange = useCallback(
        (tabId) => {
            const types = data && data.party ? cloneDeep(data.party.types) : null;
            const tabs = availableTabs(types);
            if (!(tabId in tabs)) {
                navigate("/parties/" + partyId + "/base", { replace: true });
                setActiveTabId("base");
            } else {
                if (tabId !== activeTabId) {
                    navigate("/parties/" + partyId + "/" + tabId, { replace: true });
                    setActiveTabId(tabId);
                }
            }
        },
        [setActiveTabId, activeTabId, partyId, data, navigate]
    );

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const types = data && data.party ? cloneDeep(data.party.types) : null;
    const tabs = availableTabs(types);

    if (!party) return <div>No party found with that ID</div>;

    return (
        <div>
            <div>
                <h3>{party.name}</h3>
            </div>
            <div className="row">
                <div className="card card-body bg-light col-12">
                    <Tabs onSelect={onTabChange} activeKey={activeTabId} transition={false}>
                        <Tab eventKey="base" title="Base">
                            <PartyBaseTab partyId={partyId} />
                        </Tab>
                        {party.fundInfo ? (
                            <Tab eventKey="fundInfo" title="Fund info">
                                <PartyFundInfoTab partyId={partyId} />
                            </Tab>
                        ) : null}
                        {"accounts" in tabs ? (
                            <Tab eventKey="accounts" title="Accounts">
                                <PartyAccountsTab partyId={partyId} />
                            </Tab>
                        ) : null}
                        {"externalAccounts" in tabs ? (
                            <Tab eventKey="externalAccounts" title="External accounts">
                                <PartyExternalAccountsTab partyId={partyId} />
                            </Tab>
                        ) : null}
                        {"instruments" in tabs ? (
                            <Tab eventKey="instruments" title="Instruments">
                                <PartyInstrumentsTab partyId={partyId} />
                            </Tab>
                        ) : null}
                        {"issuer" in tabs ? (
                            <Tab eventKey="issuer" title="Issuer">
                                <PartyIssuerTab partyId={partyId} />
                            </Tab>
                        ) : null}
                        {"docs" in tabs ? (
                            <Tab eventKey="docs" title="Docs">
                                <PartyDocsTab partyId={partyId} />
                            </Tab>
                        ) : null}
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
