import React from "react";
import { useParams, Link } from "react-router-dom";

import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "./MiniTransactionForm";

export function MiniTransactionPage(): React.ReactElement {
    let { id }: any = useParams();

    let type = miniTransactionTypes[0];

    if (id && id.startsWith("new")) {
        type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
        id = "new";
    }

    return (
        <div className="container page">
            <div className="row">
                <NewMiniTransactionButton page="minitransactions" />
            </div>
            <div className="row mt-5">
                {id ? (
                    <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                        <div className="container">
                            <Link className="close ms-2 mb-1" to={`/minitransactions`}>
                                x
                            </Link>
                            <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
