import React, { Fragment, ReactElement, useState } from "react";
import { browserName, isMobile } from "react-device-detect";

const setLocalDismissState = (dismiss: boolean) => {
    const dismissByDate = new Date();
    dismissByDate.setDate(dismissByDate.getDate() + 30); // next 30 days

    if (dismiss) {
        localStorage.setItem("alert-dismiss-edge", dismissByDate.toISOString());
    } else {
        localStorage.removeItem("alert-dismiss-edge");
    }
};

const getLocalDismissState = (): boolean => {
    const dismissDateString = localStorage.getItem("alert-dismiss-edge");
    return dismissDateString ? new Date() < new Date(dismissDateString) : false;
};

/**
 * Alert for slow response in Edge browser. Dismissable for 30 days.
 */
export const EdgeAlert = (): ReactElement => {
    const [dismiss, setDismiss] = useState(getLocalDismissState());
    const handleDismiss = () => {
        setDismiss(true);
        setLocalDismissState(true);
    };

    return (
        <Fragment>
            {!isMobile && browserName === "Edge" && !dismiss ? (
                <div id="alert-warning-edge" className="alert alert-danger alert-dismissible fade show border-0" role="alert" style={{ margin: 0 }}>
                    This site is optimized for Chrome. You may have issues with other browsers. We noticed 5-10 times slower response in
                    Edge compared to Chrome browser, when Edge is in "Security Enhanced Mode". See
                    https://learn.microsoft.com/en-us/deployedge/microsoft-edge-security-browse-safer about WebAssembly
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleDismiss} />
                </div>
            ) : null}
        </Fragment>
    );
};
