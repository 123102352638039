import React from "react";
import { useQuery, gql } from "urql";
import { useParams } from "react-router-dom";

export const getCurveDefinition = gql`
    query getCurveDefinition($_id: GraphQLObjectId!) {
        curveDefinition(_id: $_id) {
            _id
            clientId
            name
            updateTimestamp
            type
            definition {
                currency
                dayCount
                forwardPeriod
                type
                instruments {
                    instrumentId
                    instrument {
                        name
                    }
                }
            }
        }
    }
`;

export const CurveDefinitionPage = (): React.ReactElement => {
    const { id }: any = useParams();
    const [{ fetching, error, data }] = useQuery({ query: getCurveDefinition, variables: { _id: id } });

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <pre>{JSON.stringify(data.curveDefinition, null, 2)}</pre>
        </div>
    );
};
