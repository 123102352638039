import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";
import { Alert } from "react-bootstrap";

import { YesNoModal } from "../components/YesNoModal";
import { SubmitButton, TextField, SelectField, DateField, NumberField, TransactionField } from "../components/form";
import { useAlertTimeOut, usePrevious } from "../common/Utils";
import { BrokerTransactionStatus, MatchingStatus, TransactionStatus, TransactionType } from "../types.generated";

const GET_BROKER_TRANSACTION = gql`
    query brokerTransactions($_id: GraphQLObjectId!) {
        brokerTransactions(filter: { idIn: [$_id] }) {
            _id
            broker {
                _id
                name
            }
            client {
                _id
                name
            }
            type
            description
            isin
            instrument {
                _id
                name
            }
            price
            quantity
            commission
            stampDuty
            currency
            settlementAmount
            tradeDate
            valueDate
            reportId
            externalId
            correspondingTransactionId
            error
            status
            updateTimestamp
        }
    }
`;

const GET_DATA = gql`
    query miniTransactions($type: [TransactionType], $filter: TransactionFilterInput) {
        miniTransactions(type: $type, filter: $filter) {
            _id
            externalId
            brokerId
            brokerTradeId
            source
            status
        }
    }
`;

const UPDATE_BROKER_TRANSACTION = gql`
    mutation UpdateBrokerTransaction($input: UpdateBrokerTransactionInput!) {
        updateBrokerTransaction(input: $input) {
            _id
            broker {
                _id
                name
            }
            client {
                _id
                name
            }
            type
            description
            isin
            instrument {
                _id
                name
            }
            price
            quantity
            commission
            stampDuty
            currency
            settlementAmount
            tradeDate
            valueDate
            reportId
            externalId
            correspondingTransactionId
            error
            status
            updateTimestamp
        }
    }
`;
const MATCH_TRANSACTIONS = gql`
    mutation MatchTransactions($input: [MatchTransactionsInput!]!) {
        matchTransactions(input: $input) {
            brokerTransaction {
                _id
                broker {
                    _id
                    name
                }
                client {
                    _id
                    name
                }
                type
                description
                isin
                instrument {
                    _id
                    name
                }
                price
                quantity
                commission
                stampDuty
                currency
                settlementAmount
                tradeDate
                valueDate
                reportId
                externalId
                correspondingTransactionId
                error
                status
                updateTimestamp
            }
        }
    }
`;

export interface Props {
    id?: string;
    startDate: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    refetch?: Function;
    className?: string;
}
export function BrokerTransactionForm(props: Props): React.ReactElement {
    const previousProps = usePrevious(props);
    const id = props.id ? props.id : "000000000000000000000000";

    const [{ fetching: getBrokerTransactionLoading, error: getBrokerTransactionError, data: getBrokerTransactionData }, refetch] = useQuery(
        {
            query: GET_BROKER_TRANSACTION,
            variables: { _id: id },
            requestPolicy: "network-only"
        }
    );

    const [{ fetching: loading, error: getDataError, data }] = useQuery({
        query: GET_DATA,
        variables: { type: [TransactionType.StockTrade], filter: { statusNotIn: [TransactionStatus.Deleted], startDate: props.startDate } },
        requestPolicy: "network-only"
    });

    const [values, setValues] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [__stateUpdate, updateBrokerTransaction] = useMutation(UPDATE_BROKER_TRANSACTION);
    const [__stateMatch, matchTransactions] = useMutation(MATCH_TRANSACTIONS);

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (stableStringify(props) !== stableStringify(previousProps)) {
            setValues(null);
        } else if (!getBrokerTransactionLoading && !getBrokerTransactionError) {
            if (values === null && getBrokerTransactionData.brokerTransactions) {
                const inputValues = cloneDeep(getBrokerTransactionData.brokerTransactions[0]);
                inputValues.clientName = inputValues.client ? inputValues.client.name : null;
                inputValues.brokerName = inputValues.broker ? inputValues.broker.name : null;
                inputValues.instrumentName = inputValues.instrument ? inputValues.instrument.name : null;
                setValues(inputValues);
            }
        }
    }, [props, previousProps, values, getBrokerTransactionData, getBrokerTransactionError, getBrokerTransactionLoading]);

    if (getBrokerTransactionLoading || loading) return <div className="miniform-loading">Loading</div>;

    if (getBrokerTransactionError) return <div>{"Error: " + getBrokerTransactionError.message}</div>;
    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (!values) return <div></div>;

    const miniTransactions = cloneDeep(data.miniTransactions);
    const stockTrades = [{ brokerId: null, brokerTradeId: null, name: "No match", source: "", _id: "" }];
    miniTransactions.forEach((transaction) => {
        transaction["name"] = transaction.name ? transaction["name"] : transaction["externalId"];
        delete transaction["externalId"];

        if (
            values.broker &&
            transaction.brokerId.toString() === values.broker._id.toString() &&
            ((!transaction.brokerTradeId && transaction.status === TransactionStatus.Pending) ||
                (values.correspondingTransactionId && transaction._id.toString() === values.correspondingTransactionId.toString()))
        ) {
            // can only match with transactions that have not been matched and have same broker
            stockTrades.push(transaction);
        }
    });

    let errorMessage: string;

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete broker confirmation with id " + modal.payload._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let updatedValues: any;
                        updateBrokerTransaction({
                            input: {
                                _id: modal.payload._id,
                                status: BrokerTransactionStatus.Deleted,
                                error: modal.payload.error,
                                description: modal.payload.description
                            }
                        })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    updatedValues = result.data.updateBrokerTransaction;
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The broker transaction with id '${modal.payload._id}' was deleted successfully!`
                                    });
                                }
                            })
                            .catch((error) => {
                                errorMessage = error;
                            })
                            .finally(() => {
                                if (updatedValues) {
                                    refetch();
                                    updatedValues.clientName = updatedValues.client ? updatedValues.client.name : null;
                                    updatedValues.brokerName = updatedValues.broker ? updatedValues.broker.name : null;
                                    updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                    setValues(updatedValues);
                                    if (props.refetch) {
                                        props.refetch();
                                    }
                                }
                            });
                    }}
                />
            ) : null}

            <h3>Broker Confirmation</h3>

            <div id="brokertransactionform" className="brokertransactionform form">
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    onSubmit={async (submitValues, { setSubmitting }) => {
                        const input = { ...submitValues };
                        let updatedValues: any = null;
                        if (input._id !== null) {
                            if (
                                input.correspondingTransactionId &&
                                values.correspondingTransactionId !== input.correspondingTransactionId
                            ) {
                                await matchTransactions({
                                    input: [
                                        {
                                            brokerTransactionId: values._id,
                                            brokerExternalId: values.externalId,
                                            transactionId: input.correspondingTransactionId,
                                            matchingStatus: MatchingStatus.Confirmed
                                        },
                                        {
                                            transactionError: "No matching broker transaction found.",
                                            transactionId: values.correspondingTransactionId,
                                            matchingStatus: MatchingStatus.Mismatch
                                        }
                                    ]
                                })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            updatedValues = result.data.matchTransactions.brokerTransaction;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `Broker transaction '${updatedValues._id}' was updated successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        errorMessage = error;
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        if (updatedValues) {
                                            refetch();
                                            updatedValues.clientName = updatedValues.client ? updatedValues.client.name : null;
                                            updatedValues.brokerName = updatedValues.broker ? updatedValues.broker.name : null;
                                            updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                            setValues(updatedValues);
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        }
                                    });
                            } else if (!input.correspondingTransactionId && values.correspondingTransactionId) {
                                matchTransactions({
                                    input: [
                                        {
                                            brokerTransactionId: values._id,
                                            brokerTransactionError: "No matching transaction found.",
                                            matchingStatus: MatchingStatus.Mismatch
                                        },
                                        {
                                            transactionError: "No matching broker transaction found.",
                                            transactionId: values.correspondingTransactionId,
                                            matchingStatus: MatchingStatus.Mismatch
                                        }
                                    ]
                                })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            updatedValues = result.data.matchTransactions.brokerTransaction;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `Broker transaction '${updatedValues._id}' was updated successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        errorMessage = error;
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        if (updatedValues) {
                                            refetch();
                                            updatedValues.clientName = updatedValues.client ? updatedValues.client.name : null;
                                            updatedValues.brokerName = updatedValues.broker ? updatedValues.broker.name : null;
                                            updatedValues.instrumentName = updatedValues.instrument ? updatedValues.instrument.name : null;
                                            setValues(updatedValues);
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        }
                                    });
                            } else {
                                if (input.status && input.status === BrokerTransactionStatus.Deleted) {
                                    setModal({ showModal: true, payload: input });
                                } else {
                                    updateBrokerTransaction({
                                        input: { _id: input._id, status: input.status, error: input.error, description: input.description }
                                    })
                                        .then((result) => {
                                            if (result.error) {
                                                setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                            } else {
                                                updatedValues = result.data.updateBrokerTransaction;
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `Broker transaction '${updatedValues._id}' was updated successfully!`
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            errorMessage = error;
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            if (updatedValues) {
                                                refetch();
                                                updatedValues.clientName = updatedValues.client ? updatedValues.client.name : null;
                                                updatedValues.brokerName = updatedValues.broker ? updatedValues.broker.name : null;
                                                updatedValues.instrumentName = updatedValues.instrument
                                                    ? updatedValues.instrument.name
                                                    : null;
                                                setValues(updatedValues);
                                                if (props.refetch) {
                                                    props.refetch();
                                                }
                                            }
                                        });
                                }
                            }
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, values, errors }) => {
                        if (Object.keys(errors).length > 0) {
                            console.log(errors);
                        }
                        return (
                            <Fragment>
                                <div className="row">
                                    <div>
                                        <Form autoComplete="off">
                                            {errorMessage ? (
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <div className="form-field-error" style={{ marginBottom: "0.5rem" }}>
                                                            {errorMessage}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="form-row">
                                                <TextField name="clientName" label="Client" className="col-6" disabled={true} />
                                                <TextField name="brokerName" label="Broker" className="col-6" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <DateField name="tradeDate" label="Trade date" className="col-4" disabled={true} />
                                                <DateField name="valueDate" label="Value date" className="col-4" disabled={true} />
                                                <TextField name="currency" label="Currency" className="col-4" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <TextField name="instrumentName" label="Instrument" className="col-4" disabled={true} />
                                                <NumberField name="quantity" label="Quantity" className="col-4" disabled={true} />
                                                <NumberField name="price" label="Price" className="col-4" disabled={true} />
                                            </div>
                                            <div className="form-row">
                                                <NumberField name="commission" label="Commission" className="col-4" disabled={true} />
                                                <NumberField name="stampDuty" label="Stamp duty" className="col-4" disabled={true} />
                                                <NumberField
                                                    name="settlementAmount"
                                                    label="Settlement amount"
                                                    className="col-4"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <TextField
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    className="col-6"
                                                    disabled={false}
                                                />
                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    className="col-6"
                                                    options={["Pending", "Confirmed", "Deleted"]}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <TransactionField
                                                    name="correspondingTransactionId"
                                                    label="Matched with"
                                                    className="col-12"
                                                    options={stockTrades}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <TextField
                                                    name="error"
                                                    label="Error"
                                                    className="col-4"
                                                    disabled={values._id ? false : true}
                                                />
                                                <TextField
                                                    name="externalId"
                                                    label={"External id"}
                                                    type="text"
                                                    className="col-4"
                                                    disabled={true}
                                                />

                                                <TextField name="_id" label="Id" type="text" className="col-4" disabled={true} />
                                            </div>
                                            <SubmitButton disabled={isSubmitting || Object.keys(errors).length > 0} label={"Save"} />
                                            {alert.visible ? (
                                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible className="mt-2">
                                                    {alert.message}
                                                </Alert>
                                            ) : null}
                                        </Form>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
