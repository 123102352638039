import { Row } from "@tanstack/react-table";

import { numberFormat, TimeSeries } from "../../../../common/src";

import { BenchmarkData, ProcessedData, SelectedRowType, AttributionInstrumentPerformance } from "./interfaces";
import { PartyAccount, PartyAccountType, InstrumentModelTypeEnum, PartyBenchmark } from "../../types.generated";

export const percentageFormat = (d: number) => numberFormat(d, Math.abs(d) > 0.995 ? "0%" : Math.abs(d) > 0.0995 ? "0.0%" : "0.00%");
// Assuming you have two Date objects, you can just subtract them to get the difference in milliseconds:
// Milliseconds per day 24*60*60*1000 = 86400000
const MS_PER_DAY = 86400000;
export const dayDiff = (d1: Date, d2: Date) => Math.floor((d2.getTime() - d1.getTime()) / MS_PER_DAY);

export const yearFracActual365 = (d1: Date, d2: Date) => (d2.getTime() - d1.getTime()) / 365 / MS_PER_DAY;

export const showModelIndex = (account: PartyAccount, accountLevels: any[], level: number): boolean => {
    // do not show model index for physical accounts since it will be the same as main index
    if (account && account.type === PartyAccountType.Physical) return false;
    else return Array.isArray(accountLevels) && accountLevels.length > level;
};

// Return calculation from values and cash flow timeseries
export const calculateReturn = (values: number[], cashFlows: number[], numberOfDays: number, endDateIndex: number = null): number => {
    if (endDateIndex === null) {
        endDateIndex = values.length - 1;
    }
    if (endDateIndex > numberOfDays) {
        let calculatedReturn = 1;
        // No return for first day by construction
        for (let i = endDateIndex - numberOfDays + 1; i < endDateIndex + 1; i++) {
            //portfolioReturn = values[i] - values[i - 1] - cashFlows[i];
            if (i === 1) {
                // Add also total return for first day values[0]-cashFlows[0]
                calculatedReturn *=
                    values[i - 1] === 0 ? 1 : 1 + (values[0] - cashFlows[0] + values[i] - values[i - 1] - cashFlows[i]) / values[i - 1];
            } else {
                calculatedReturn *= values[i - 1] === 0 ? 1 : 1 + (values[i] - values[i - 1] - cashFlows[i]) / values[i - 1];
            }
        }
        return calculatedReturn - 1;
    }
    return null;
};

// Return calculation from series. Since series for all dates calculation is straight forward
export const calculateReturnFromSeries = (series: number[], numberOfDays: number, endDateIndex: number = null): number => {
    if (endDateIndex === null) {
        endDateIndex = series.length - 1;
    }
    return endDateIndex < numberOfDays ? null : series[endDateIndex] / series[endDateIndex - numberOfDays] - 1;
};

export const getRowData = (
    row: Row<any>,
    processedData: ProcessedData,
    name: string,
    startDate: string,
    endDate: string,
    showModelIndex = true,
    manualMainBenchmark: BenchmarkData = null
): SelectedRowType => {
    const groupingValue: string = row.getIsGrouped() ? row.groupingValue : row.original.accountId;
    const {
        benchmarksById,
        showBenchmarksById,
        dateSeries,
        accountSeriesById,
        accountPositionsById,
        accountValuesById,
        riskFreeSeries,
        modelSeriesById,
        modelSeriesNameById
    } = processedData;
    // Clone everything to be sure. Benchmarks cloned below
    const mainBenchmark = manualMainBenchmark ? manualMainBenchmark : benchmarksById[groupingValue];
    const showBenchmarks = showBenchmarksById[groupingValue];
    const modelSeriesName = modelSeriesNameById[groupingValue] ? modelSeriesNameById[groupingValue] : "Model Weighted Index";

    const series: number[] = [];
    const modelSeries: number[] = [];
    const dates: string[] = [];
    const values: number[] = [];
    const positionFlag: number[] = [];

    if (row.getIsGrouped()) {
        for (let i = 0; i < dateSeries.length; i++) {
            dates.push(dateSeries[i]);
            series.push(accountSeriesById[groupingValue][i]);
            values.push(accountValuesById[groupingValue][i]);
            positionFlag.push(accountPositionsById[groupingValue][i]);
            modelSeries.push(modelSeriesById[groupingValue][i]);
        }
    } else {
        for (let i = 0; i < dateSeries.length; i++) {
            dates.push(dateSeries[i]);
            series.push(row.original.series[i]);
            values.push(row.original.values[i]);
            positionFlag.push(row.original.positionFlag[i]);
        }
    }

    const performance = new TimeSeries(dates, series, name);
    const instrumentPerformances = getInstrumentPerformancesFromRow(row);

    const benchmarks: TimeSeries[] = [];
    if (mainBenchmark) {
        benchmarks.push(mainBenchmark.series.clone());
    }

    if (showModelIndex && Array.isArray(modelSeries) && modelSeries.length) {
        // Copy series to be sure...
        const accountModelSeries: number[] = [];
        let hasNaN = false;
        for (let i = 0; i < modelSeries.length; i++) {
            accountModelSeries.push(modelSeries[i]);
            if (isNaN(modelSeries[i])) {
                hasNaN = true;
                break;
            }
        }
        if (!hasNaN) benchmarks.push(new TimeSeries(dateSeries, accountModelSeries, modelSeriesName));
    }

    if (Array.isArray(showBenchmarks) && showBenchmarks.length) {
        for (const showBenchmark of showBenchmarks) {
            // Filter out main benchmark since we already have added it.
            if (mainBenchmark && showBenchmark.instrumentId !== mainBenchmark.instrumentId) benchmarks.push(showBenchmark.series.clone());
        }
    }

    return {
        name,
        performance,
        values,
        instrumentPerformances,
        benchmarks,
        riskFree: riskFreeSeries.clone(),
        startDate,
        endDate,
        dateSeries,
        positionFlag
    };
};

export const calculateReturnSeries = (values: number[], cashFlows: number[]): number[] => {
    const returnSeries = [1];
    // Add also total return for first day values[0]-cashFlows[0] at day 2
    if (values[0] === 0) returnSeries.push(1);
    else {
        returnSeries.push(1 + (values[1] - cashFlows[0] - cashFlows[1]) / values[0]);
    }
    for (let i = 2; i < values.length; i++) {
        //portfolioReturn = values[i] - values[i - 1] - cashFlows[i];
        let totalReturn = 1;
        if (values[i - 1] !== 0) {
            totalReturn = 1 + (values[i] - values[i - 1] - cashFlows[i]) / values[i - 1];
        }
        returnSeries.push(returnSeries[i - 1] * totalReturn);
    }
    return returnSeries;
};

export const dateToIsoString = (date: Date): string => {
    return [date.getFullYear(), ("0" + (date.getMonth() + 1)).slice(-2), ("0" + date.getDate()).slice(-2)].join("-");
};

export const getTopBottomInstruments = (defaultInstrumentsValues: Record<string, number>): string[] => {
    const sortedDefaultInstruments: Record<string, number> = {};

    for (const [k, v] of Object.entries(defaultInstrumentsValues).sort((a, b) => a[1] - b[1])) sortedDefaultInstruments[k] = v;

    const sortedDefaultInstrumentsNames = Object.keys(sortedDefaultInstruments);
    let topBottomInstruments: string[] = [];
    if (sortedDefaultInstrumentsNames.length > 10) {
        const bottomInstruments = sortedDefaultInstrumentsNames.slice(0, 5);
        const topInstruments = sortedDefaultInstrumentsNames.slice(
            sortedDefaultInstrumentsNames.length - 5,
            sortedDefaultInstrumentsNames.length
        );
        topBottomInstruments = bottomInstruments.concat(topInstruments);
    } else {
        topBottomInstruments = sortedDefaultInstrumentsNames;
    }
    return topBottomInstruments;
};

export const getTopBottomInstrumentsFromSelectedRow = (selectedRow): string[] => {
    const instrumentsValues: Record<string, number> = {};
    for (let i = 0; i < selectedRow.instrumentPerformances.length; i++) {
        const instrumentPerformance = selectedRow.instrumentPerformances[i];
        instrumentsValues[instrumentPerformance.instrument.name] = instrumentPerformance.series[instrumentPerformance.series.length - 1];
    }
    return getTopBottomInstruments(instrumentsValues);
};

const getInstrumentPerformanceFromRow = (row: Row<any>): AttributionInstrumentPerformance => {
    const values: number[] = [];
    const series: number[] = [];
    const cashFlows: number[] = [];
    const startIndex = 0;
    const endIndex = row.original.series.length;
    for (let i = startIndex; i < endIndex; i++) {
        values.push(row.original.values[i]);
        series.push(row.original.series[i]);
        cashFlows.push(row.original.cashFlows[i]);
    }
    return {
        values,
        series,
        cashFlows,
        accountId: row.original.accountId,
        instrument: {
            name: row.original.instrument.name,
            shortName: row.original.instrument.name,
            currency: row.original.instrument.currency,
            modelType: row.original.instrument.modelType
        }
    };
};

const getInstrumentPerformancesFromRow = (row: Row<any>): AttributionInstrumentPerformance[] => {
    // Return full instrumentPerformances since start and end date handled in AttributionGraph component
    if (row.getIsGrouped()) {
        return row.getLeafRows().map((leafRow) => getInstrumentPerformanceFromRow(leafRow));
    } else {
        return [getInstrumentPerformanceFromRow(row)];
    }
};

export const excludeInAttribution = (modelType: InstrumentModelTypeEnum): boolean => {
    const excludeInAttribution = [
        InstrumentModelTypeEnum.Swap,
        InstrumentModelTypeEnum.Swaption,
        InstrumentModelTypeEnum.CdsIndex,
        InstrumentModelTypeEnum.FxSwap,
        InstrumentModelTypeEnum.PortfolioSwap,
        InstrumentModelTypeEnum.Balance
    ];
    return excludeInAttribution.includes(modelType);
};

export const aggregate2DArrayOfNumbers = (leafValues: number[][]) => {
    const aggregatedValue: number[] = [];
    for (let i = 0; i < leafValues.length; i++) {
        const values: number[] = leafValues[i];
        if (i === 0) {
            for (let j = 0; j < values.length; j++) {
                aggregatedValue.push(values[j]);
            }
        } else {
            for (let j = 0; j < values.length; j++) {
                aggregatedValue[j] += values[j];
            }
        }
    }
    return aggregatedValue;
};

export const isValidRow = (row: SelectedRowType): boolean => {
    if (!row.name) {
        return false;
    }
    if (row.performance.__values.includes(NaN)) {
        return false;
    }
    if (!row.endDate) {
        return false;
    }
    for (let i = 0; i < row.benchmarks.length; i++) {
        if (row.benchmarks[i].__values.includes(NaN)) {
            return false;
        }
    }
    return true;
};

export const getBenchmarkName = (benchmark: PartyBenchmark): string => {
    // Account benchmark comment to be refactored and used as visible label, see issue #2194
    return benchmark.comment
        ? benchmark.comment
        : benchmark.instrument.longName
          ? benchmark.instrument.longName
          : benchmark.instrument.name;
};
