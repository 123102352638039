import React, { useState, useCallback, useEffect, useContext } from "react";
import { gql, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQueryState } from "../../common/use-query-state";
import { Tabs, Tab } from "react-bootstrap";
import { TransactionsPage } from "../TransactionsPage";
import { ReconciliationPage } from "../ReconciliationPage";
import { DealBlotterGrid } from "../DealBlotterGrid";

import { DateForm } from "../../common/dateForm";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "../MiniTransactionForm";
import { serializeSwedenDate } from "../../components/dateFormater";
import { PartyType } from "../../types.generated";
import { ClientContextSelector } from "../../contexts/ClientContextSelector";
import { ClientContext } from "../../contexts/ClientContext";
import { Panel } from "@fluentui/react";

const getClient = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
            accountingCurrency
            fundInfo {
                classes {
                    startDate
                }
            }
        }
    }
`;

export interface PartiesParamTypes {
    clientDashName: string;
    tabId?: string;
    transId?: string;
}

const tabs = {
    dealblotter: "dealblotter",
    transactions: "transactions",
    reconciliation: "reconciliation"
};

export function OperationsTabPage(): React.ReactElement {
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(new Date().getFullYear() - 1, 0, 1)));
    const [id] = useQueryState("id", "");
    const navigate = useNavigate();
    const location = useLocation();
    const { tabId, transId } = useParams<"tabId" | "transId">();
    const [activeTabId, setActiveTabId] = useState(tabId);
    const { client: clientContext } = useContext(ClientContext);

    const [{ fetching, error, data }] = useQuery({ query: getClient });

    useEffect(() => {
        if (clientContext && clientContext.dashName) {
            if (!(tabId in tabs)) {
                navigate("/operations/" + clientContext.dashName + "/dealblotter");
                setActiveTabId("dealblotter");
            } else {
                if (tabId !== activeTabId) {
                    setActiveTabId(tabId);
                }
            }
        } /*else if (clientContext._id === emptyObjectId && (tabId || startDate || id)) {
            navigate("/operations/" + clientContext.dashName, { replace: true });
        }*/
    }, [tabId, data, setActiveTabId, clientContext, navigate, startDate, id, activeTabId]);

    const onTabChange = useCallback(
        (tabId) => {
            //const types = data && data.party ? cloneDeep(data.party.types) : null;
            //const tabs = availableTabs(types);
            if (clientContext && clientContext.dashName) {
                if (!(tabId in tabs)) {
                    navigate("/operations/" + clientContext.dashName + "/dealblotter", { replace: true });
                    setActiveTabId("dealblotter");
                } else {
                    if (tabId !== activeTabId) {
                        navigate("/operations/" + clientContext.dashName + "/" + tabId, { replace: true });
                        setActiveTabId(tabId);
                    }
                }
            } else {
                if (!(tabId in tabs)) {
                    navigate("/operations/" + "/dealblotter", { replace: true });
                    setActiveTabId("dealblotter");
                } else {
                    if (tabId !== activeTabId) {
                        navigate("/operations/" + tabId, { replace: true });
                        setActiveTabId(tabId);
                    }
                }
            }
        },
        [setActiveTabId, activeTabId, clientContext, navigate]
    );

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    // client
    const clientName = clientContext && clientContext.dashName ? clientContext.dashName.replaceAll("-", " ") : "-";
    const client = data.clients.find((c) => c.name == clientName);

    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Client]} />
                </div>
            </div>
            {client ? (
                <div className="row">
                    <div className="card card-body bg-light col-12">
                        <Tabs onSelect={onTabChange} activeKey={activeTabId} transition={false}>
                            <Tab eventKey="dealblotter" title="dealblotter">
                                <div className="d-flex">
                                    <DateForm defaultDateString={startDate} dateName={"startDate"}></DateForm>
                                    <div className="ms-auto pt-2">
                                        <NewMiniTransactionButton page={location.pathname + location.search} queryArgMode={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={"col-12"}>
                                        <DealBlotterGrid startDate={startDate} clientId={client._id} />
                                    </div>
                                </div>
                                <Panel
                                    isOpen={!!(transId || id)}
                                    isBlocking={false}
                                    onDismiss={() => navigate(location.pathname.replaceAll(transId, ""))}
                                    layerProps={{ eventBubblingEnabled: true }}
                                >
                                    {transId || id ? (
                                        <MiniTransactionForm
                                            id={transId}
                                            type={
                                                id && id.startsWith("new")
                                                    ? miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length))
                                                    : miniTransactionTypes[0]
                                            }
                                        />
                                    ) : null}
                                </Panel>
                            </Tab>

                            <Tab eventKey="transactions" title="transactions">
                                <TransactionsPage />
                            </Tab>
                            <Tab eventKey="reconciliation" title="reconciliation">
                                <ReconciliationPage />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
