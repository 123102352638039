import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "urql";
import { useQueryState } from "react-router-use-location-state";

import { DateHelper } from "../../../common/src";
import { DateForm } from "../common/dateForm";

import { ReactTable } from "../components/react-table/ReactTable";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";

export const bankAccountTransactions = gql`
    query bankAccountTransactions($filter: BankAccountTransactionFilterInput, $includeVersions: Boolean = false) {
        bankAccountTransactions(filter: $filter) {
            _id
            clientId
            client {
                _id
                name
            }
            instrumentId
            instrument {
                _id
                name
                bic
                iban
                currency
            }
            date
            externalId
            amount
            status
            reference
            description
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            versions @include(if: $includeVersions)
        }
    }
`;

export function BankAccountTransactionsPage(): React.ReactElement {
    const [startDate] = useQueryState("startDate", DateHelper.addMonths(new Date(), -1).toISOString().slice(0, 10));
    const [{ fetching, error, data }] = useQuery({
        query: bankAccountTransactions,
        requestPolicy: "cache-and-network",
        variables: { filter: { startDate } }
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: "_id",
                header: "Id",
                size: 90
            },
            {
                accessorKey: "externalId",
                header: "External id",
                size: 90
            },
            {
                id: "client.name",
                accessorKey: "client.name",
                header: "Client",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 75
            },
            {
                accessorKey: "instrument.iban",
                header: "IBAN",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return (
                            <Link to={`/parties/${row.original.client._id}/instruments/${row.original.instrument._id}`}>
                                {cellProps.getValue()}
                            </Link>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 90
            },
            {
                accessorKey: "date",
                header: "Date"
            },
            {
                accessorKey: "instrument.currency",
                header: "Currency",
                filter: SelectColumnFilter
            },
            {
                accessorKey: "amount",
                header: "Amount",
                filter: SelectColumnFilter
            },
            {
                accessorKey: "status",
                header: "Status",
                filter: SelectColumnFilter
            },
            {
                accessorKey: "reference",
                header: "Reference",
                filter: SelectColumnFilter
            },
            {
                accessorKey: "description",
                header: "Description",
                size: 120,
                filter: SelectColumnFilter
            },
            {
                accessorKey: "createTimestamp",
                header: "Create timestamp",
                size: 80
            },
            {
                accessorKey: "updateTimestamp",
                header: "Update timestamp",
                size: 80
            }
        ],
        []
    );

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!data) return <div></div>;

    return (
        <div>
            <div className="d-flex">
                <DateForm className="float-end" defaultDateString={startDate} dateName={"startDate"}></DateForm>
            </div>
            <p>Number of transactions: {data.bankAccountTransactions.length}</p>

            <ReactTable columns={columns} data={data.bankAccountTransactions} defaultHiddenColumns={["createTimestamp", "status", "_id"]} />
        </div>
    );
}
