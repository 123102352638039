import React, { useReducer, useMemo } from "react";
import { ClientContext, clientReducer, initialClientState } from "./ClientContext";

interface IContextProviderProps {
    children: React.ReactNode;
}

export const ContextProvider: React.FC<IContextProviderProps> = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const [clientState, clientDispatch] = useReducer(clientReducer, initialClientState);
    const clientContext = useMemo(() => ({ client: clientState, setClient: clientDispatch }), [clientState]);

    return <ClientContext.Provider value={clientContext}>{children}</ClientContext.Provider>;
};
