import React, { useState, ReactElement, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Formik, Form, ErrorMessage, useField, useFormikContext, FieldHookConfig } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import stableStringify from "json-stable-stringify";

import { DateField, TextField, InstrumentInput, SubmitButton } from "../components/form";
import { Svgs } from "../../../components/src";
import { usePrevious, useQueryArgs } from "../common/Utils";
import { serializeSwedenDate } from "../components/dateFormater";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { FetchInstrumentAccruedInterestButton } from "../components/form/FetchInstrumentAccruedInterestButton";

const GET_INSTRUMENTS = gql`
    query instruments($idIn: [GraphQLObjectId!]) {
        instruments(idIn: $idIn) {
            _id
            name
            bloombergTicker
            currency
        }
    }
`;

const CREATE_VALUATION = gql`
    mutation createValuation($input: CreateValuationInput!) {
        createValuation(input: $input) {
            _id
        }
    }
`;

const newKey = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

interface RecordInputProp {
    disabled: boolean;
    record: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onDelete: Function;
}

const RecordInput = ({ record, onDelete, disabled }: RecordInputProp) => {
    const [type, setType] = useState(record.type);
    const [currency] = useState(record.currency);
    const [value, setValue] = useState(record.value);

    const handleTypeChange = (e) => {
        record.type = e.target.value; //!important: change the referenced field
        setType(e.target.value);
    };

    const handleValueChange = (e) => {
        record.value = e.floatValue; //!important: change the referenced field
        setValue(e.floatValue);
    };

    const remove = () => {
        onDelete(record);
    };

    // see graphql 'PriceType'
    const types = ["AccruedInterest", "CleanPrice", "Price", "PriceEstimate"];

    return (
        <div className="form-group form-row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <select name="type" value={type} onChange={handleTypeChange} disabled={disabled} className="form-control form-select">
                    {types.map((c) => (
                        <option key={c} value={c}>
                            {c}
                        </option>
                    ))}
                </select>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <NumericFormat
                    name="value"
                    value={value}
                    className="form-control"
                    onValueChange={handleValueChange}
                    disabled={disabled}
                    thousandSeparator=" "
                    decimalSeparator="."
                />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <input type="text" name="currency" value={currency} readOnly={true} className="form-control" />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <button type="button" onClick={remove} disabled={disabled} className="btn btn-danger btn-sm">
                    x
                </button>
            </div>
        </div>
    );
};

type RecordsFieldPropTypes = FieldHookConfig<any[]> & {
    label: string;
    className: string;
    disabled: boolean;
    currency: string;
};

const RecordsField = ({ currency, label, className, disabled, ...props }: RecordsFieldPropTypes) => {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();

    const onAddNew = () => {
        field.value.push({ key: newKey(), type: "Price", currency: currency, value: 0.0 });
        setFieldValue(field.name, field.value);
    };

    const onDelete = (e) => {
        const item = field.value.find((v) => v.key === e.key);
        const ndx = field.value.indexOf(item);
        if (ndx > -1) {
            field.value.splice(ndx, 1);
            setFieldValue(field.name, field.value);
        }
    };

    const li: any[] = (field as unknown as any).value || []; // seems to be a race condition here

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <label>{label}</label> &nbsp;
            <button type="button" onClick={onAddNew} className="btn btn-secondary btn-sm">
                +
            </button>
            {li.map((record) => (
                <RecordInput key={record.key} record={record} disabled={disabled} onDelete={onDelete} />
            ))}
        </div>
    );
};

export function AddValuationPage(): ReactElement {
    const navigate = useNavigate();
    const { queryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    const [idIn, setIdIn] = useState<string[]>(null);

    const [instrument, setInstrument] = useState(null);
    const [valuation, setValuation] = useState(null);
    const [instruments, setInstruments] = useState([]);

    const [{ fetching, data, error }] = useQuery({ query: GET_INSTRUMENTS, variables: { idIn }, requestPolicy: "network-only" });
    const [_, createValuation] = useMutation(CREATE_VALUATION);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useEffect(() => {
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            setInstrument(null);
            setValuation(null);
            setInstruments(null);
            if (queryArgs.instrumentIdIn) {
                setIdIn([queryArgs.instrumentIdIn as string]);
            } else {
                setIdIn(null);
            }
        }
    }, [queryArgs, setInstrument, setValuation, setInstruments, previousQueryArgs]);

    useEffect(() => {
        if (data) {
            setInstruments(data.instruments);
            if (data.instruments.length === 1) {
                const instrumentIn = data.instruments[0];
                setInstrument(instrumentIn);
                setValuation({
                    instrumentId: instrumentIn._id,
                    date: new Date(),
                    bloombergTicker: instrumentIn.bloombergTicker,
                    source: "Bloomberg",
                    currency: instrumentIn.currency,
                    prices: [] // clear prices on instrument change})
                });
            }
        }
    }, [data]);

    if (fetching)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (error)
        return (
            <div className="loader">
                <h3>Failed loading data</h3>
            </div>
        );

    return (
        <div className="page">
            <h2>Add valuation</h2>

            <div className="row">
                <div className="col-md-6">
                    <label>Select instrument</label>
                    <InstrumentInput
                        name="Instrument1"
                        values={instruments ? instruments : []}
                        selected={instrument ? instrument._id : null}
                        className="form-control"
                        onChange={(e) => {
                            const val = {
                                instrumentId: e._id,
                                date: new Date(),
                                bloombergTicker: e.bloombergTicker,
                                source: "Bloomberg",
                                currency: e.currency,
                                prices: [] // clear prices on instrument change
                            };
                            setInstrument(e);
                            setValuation(val);
                        }}
                    />
                </div>
                <div className="col-md-6"></div>
            </div>

            {instrument ? (
                <Formik
                    key={"ins_" + instrument._id}
                    enableReinitialize={true}
                    initialValues={valuation}
                    validationSchema={Yup.object({
                        date: Yup.date().max(new Date()).min(new Date("1900-01-01")),
                        source: Yup.string().required().min(2),
                        prices: Yup.array().min(1)
                    })}
                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                        // clean input
                        const input = {
                            instrumentId: submitValues.instrumentId,
                            date: serializeSwedenDate(submitValues.date),
                            source: submitValues.source,
                            bloombergTicker: submitValues.bloombergTicker,
                            prices: submitValues.prices.map((p) => {
                                delete p.key;
                                return p;
                            })
                        };

                        createValuation({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    // redirect to edit valuation page on success
                                    setSubmitting(false);
                                    navigate("/valuation/" + result.data.createValuation._id, { replace: true });
                                    return true;
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form autoComplete="off">
                            <div className="row">
                                <div className="col-md-6">
                                    {instrument.bloombergTicker ? (
                                        <div>
                                            <span className="label label-default">{instrument.bloombergTicker}</span>
                                            ,&nbsp;&nbsp;
                                            <span>({instrument.currency})</span>{" "}
                                        </div>
                                    ) : (
                                        <div>{instrument.currency}</div>
                                    )}

                                    <div className="d-flex flex-row align-items-center mt-4">
                                        <DateField className="" name="date" label="Date *" disabled={false} />
                                        <FetchInstrumentAccruedInterestButton />
                                    </div>

                                    <TextField name="source" label="Source *" type="text" className="" disabled={isSubmitting} />

                                    <RecordsField
                                        key={"ins_" + instrument._id}
                                        name="prices"
                                        label="Prices *"
                                        className=""
                                        currency={instrument.currency}
                                        disabled={isSubmitting}
                                    />
                                    <ErrorMessage name="prices" render={(msg) => <div className="form-field-error">{msg}</div>} />
                                </div>

                                <div className="col-md-6"></div>
                            </div>

                            {alert.visible ? (
                                <div className="row">
                                    <div className="col">
                                        <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                            {alert.message}
                                        </Alert>
                                    </div>
                                </div>
                            ) : null}

                            <div className="row">
                                <div className="col">
                                    <SubmitButton disabled={isSubmitting} label="Create" />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </div>
    );
}
