import React, { useEffect, useState, useCallback } from "react";
import { cloneDeep, keyBy, sortBy } from "lodash";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { PartialDeep } from "type-fest";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

import { AttachmentForm } from "./AttachmentForm";
import {
    CurrencyEnum,
    CustodianApiPosition,
    Party,
    Instrument,
    CustodianApiPositionInput,
    StatusEnum,
    CustodianPositionType,
    PartyExternalAccountType
} from "../types.generated";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { usePrevious } from "../common/Utils";
import { TextField, SelectField, NumberField, DateField, SearchListField, SubmitButton } from "../components/form";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";

export const getClients = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
            externalAccounts {
                _id
                name
                custodianAccountNumber
                custodianId
                type
                partyInstruments {
                    _id
                    name
                    longName
                    currency
                    type
                }
                custodian {
                    _id
                    name
                }
            }
            instruments {
                _id
                name
                longName
                currency
                type
            }
        }

        instruments {
            _id
            name
            isin
            iban
            longName
            currency
            type
        }
    }
`;

export const getCustodianPositions = gql`
    query getCustodianPositions($idIn: [GraphQLObjectId!]) {
        custodianPositions(idIn: $idIn, include: [Automatic, Manual]) {
            _id
            status
            type
            clientId
            client {
                name
            }
            custodianId
            custodian {
                name
            }
            externalAccountId
            externalAccount {
                name
                custodianAccountNumber
                type
            }
            date
            isin
            instrumentId
            instrument {
                _id
                name
            }
            accountNumber
            attachments {
                fileId
                clientId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            cashAccountNumber
            currency
            price
            quantity
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const upsertCustodianPositions = gql`
    mutation upsertCustodianPositions($input: [CustodianApiPositionInput!]!) {
        upsertCustodianPositions(input: $input) {
            _id
            status
            type
            clientId
            client {
                name
            }
            custodianId
            custodian {
                name
            }
            externalAccountId
            externalAccount {
                name
                custodianAccountNumber
                type
            }
            date
            isin
            instrumentId
            instrument {
                _id
                name
            }
            accountNumber
            attachments {
                fileId
                clientId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            cashAccountNumber
            currency
            price
            quantity
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

const today = new Date().toISOString().slice(0, 10);
const defaultFormData: PartialDeep<CustodianApiPosition> = {
    __typename: "CustodianApiPosition",
    _id: "new",
    status: StatusEnum.Active,
    type: CustodianPositionType.Manual,
    clientId: "000000000000000000000000",
    externalAccountId: "000000000000000000000000",
    accountNumber: "",
    cashAccountNumber: "",
    date: today,
    isin: "SE0000108656",
    instrumentId: "558ba89433d865236cb94e8f",
    price: 0,
    quantity: 0,
    currency: CurrencyEnum.SEK,
    updateUserInfo: { name: "" }
};

export const CustodianPositionPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id }: any = useParams();
    const previousId: string = usePrevious(id);
    const [formData, setFormData] = useState(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });
    const isEditMode = id === "new" ? false : true;

    const [{ data, fetching, error }] = useQuery({
        query: getClients,
        requestPolicy: "cache-and-network"
    });

    const [{ data: dataPosition, fetching: fetchingPosition, error: errorPosition }] = useQuery({
        query: getCustodianPositions,
        variables: { idIn: [id] },
        requestPolicy: "network-only",
        pause: id === "new" ? true : false
    });

    const [_state, executeMutation] = useMutation(upsertCustodianPositions);

    useEffect(() => {
        if (previousId && previousId !== id) {
            setFormData(null);
        } else if (formData === null && id === "new") {
            const initFormData = cloneDeep(defaultFormData);
            setFormData(initFormData);
        } else if (formData === null && dataPosition && dataPosition.custodianPositions.length === 1) {
            const init = cloneDeep(dataPosition.custodianPositions[0]);
            if (!init.updateUserInfo) {
                init.updateUserInfo = { name: "" };
            }
            setFormData(init);
        }
    }, [dataPosition, formData, id, previousId]);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            if (dataPosition) {
                attachments = recursivelyRemoveKey(attachments, "__typename");
                const input: CustodianApiPositionInput[] = [
                    {
                        _id: dataPosition.custodianPositions[0]._id,
                        status: dataPosition.custodianPositions[0].status,
                        type: dataPosition.custodianPositions[0].type,
                        accountNumber: dataPosition.custodianPositions[0].accountNumber,
                        attachments: attachments,
                        cashAccountNumber: dataPosition.custodianPositions[0].cashAccountNumber
                            ? dataPosition.custodianPositions[0].cashAccountNumber
                            : "",
                        clientId: dataPosition.custodianPositions[0].clientId,
                        currency: dataPosition.custodianPositions[0].currency,
                        custodianId: dataPosition.custodianPositions[0].custodianId,
                        externalAccountId: dataPosition.custodianPositions[0].externalAccountId,
                        date: dataPosition.custodianPositions[0].date,
                        instrumentId: dataPosition.custodianPositions[0].instrumentId,
                        isin: dataPosition.custodianPositions[0].isin,
                        price: dataPosition.custodianPositions[0].price,
                        quantity: dataPosition.custodianPositions[0].quantity
                    }
                ];
                executeMutation({ input })
                    .then((result) => {
                        if ("error" in result && result.error) {
                            const message = formikUrqlErrorFormater(result.error);
                            setAlert({ color: "danger", visible: true, message });
                        } else {
                            setFormData(result.data.upsertCustodianPositions[0]);
                            //refetch();
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ color: "danger", visible: true, message: error.toString() });
                    });
            }
        },
        [dataPosition, executeMutation, setFormData]
    );

    if (fetching) return <p>Loading...</p>;
    if (fetchingPosition) return <p>Loading...</p>;

    if (error) {
        return (
            <div>
                <h3>error</h3>
                <pre>{JSON.stringify(error, null, 2)}</pre>;
            </div>
        );
    }

    if (errorPosition) {
        return (
            <div>
                <h3>error</h3>
                <pre>{JSON.stringify(errorPosition, null, 2)}</pre>;
            </div>
        );
    }

    if (id !== "new" && dataPosition.custodianPositions.length === 0) {
        return <div>No custodianPosition with id {id}</div>;
    }

    if (!formData) {
        return <div></div>;
    }

    const clientsById = keyBy(data.clients, "_id");
    let clients = data.clients.map((d) => ({ key: d._id, value: d.name }));
    clients = sortBy(clients, "value");

    const externalAccountsInClient = (client: Partial<Party>): { value: string; key: string }[] => {
        let externalAccounts: { value: string; key: string }[] = [];
        client.externalAccounts.forEach((externalAccount) => {
            if (externalAccount.custodian) {
                externalAccounts.push({
                    value: externalAccount.custodian.name + " " + externalAccount.name,
                    key: externalAccount._id
                });
            }
        });
        externalAccounts = sortBy(externalAccounts, "value");
        return externalAccounts;
    };

    const externalAccountByExternalAccountId: Record<
        string,
        {
            _id: string;
            name: string;
            custodianAccountNumber: string;
            custodianId: string;
            type: PartyExternalAccountType;
            partyInstruments: {
                _id: string;
                name: string;
            }[];
            custodian: {
                _id: string;
                name: string;
            };
        }
    > = {};
    const custodianById: Record<string, { _id: string; name: string }> = {};
    const custodianByExternalAccountId: Record<string, any> = {};
    const partyInstrumentsById: Record<string, any> = {};

    data.clients.forEach((client) => {
        client.externalAccounts.forEach((externalAccount) => {
            externalAccountByExternalAccountId[externalAccount._id] = externalAccount;
            //allExternalAccounts.push({
            //    value: externalAccount.custodian.name + " " + externalAccount.name,
            //    key: externalAccount._id
            if (externalAccount && externalAccount.custodian) {
                custodianById[externalAccount.custodian._id] = { _id: externalAccount.custodianId, name: externalAccount.custodian.name };
            }
            custodianByExternalAccountId[externalAccount._id] = {
                _id: externalAccount.custodianId,
                name: externalAccount.custodian ? externalAccount.custodian.name : "None"
            };
        });

        client.instruments.forEach((partyInstrument: Instrument) => {
            if (partyInstrument) {
                partyInstrumentsById[partyInstrument._id] = partyInstrument;
            }
        });
    });

    let custodians: { key: string; value: string }[] = Object.values(custodianById).map((custodian) => ({
        key: custodian._id,
        value: custodian.name
    }));
    custodians = sortBy(custodians, "value");
    custodians.unshift({ key: "000000000000000000000000", value: "None" });
    clients.unshift({ key: "000000000000000000000000", value: "None" });

    let allExternalAccounts: { key: string; value: string }[] = Object.values(externalAccountByExternalAccountId).map(
        (externalAccount) => ({
            key: externalAccount.custodianId,
            value: externalAccount.custodian ? externalAccount.custodian.name + " " + externalAccount.name : "None"
        })
    );
    allExternalAccounts = sortBy(allExternalAccounts, "value");

    const instruments = cloneDeep(data.instruments);
    const instrumentById: Record<string, any> = keyBy(instruments, "_id");

    let instrumentOptions = instruments;
    return (
        <div className="container page">
            <Button
                type="button"
                className="btn-sm mb-3"
                onClick={() => {
                    const win = window.open("/reconciliation/custodianpositions", "_self");
                    win.focus();
                }}
            >
                All custodian positions
            </Button>
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                validate={() => {
                    const errors: any = {};
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setErrors }) => {
                    const input: CustodianApiPositionInput[] = [
                        {
                            _id: submitValues._id === "new" ? null : submitValues._id,
                            status: submitValues.status,
                            type: submitValues.type,
                            accountNumber: submitValues.accountNumber ? submitValues.accountNumber : "",
                            cashAccountNumber: submitValues.cashAccountNumber ? submitValues.cashAccountNumber : "",
                            clientId: submitValues.clientId,
                            currency: submitValues.currency,
                            custodianId: submitValues.custodianId,
                            externalAccountId: submitValues.externalAccountId,
                            date: submitValues.date,
                            instrumentId: submitValues.instrumentId,
                            isin: submitValues.isin,
                            price: submitValues.price,
                            quantity: submitValues.quantity
                        }
                    ];

                    if (input[0].clientId === "000000000000000000000000") {
                        setErrors({ clientId: "No Client selected" });
                    } else if (input[0].externalAccountId === "000000000000000000000000") {
                        setErrors({ externalAccountId: "No externalAccount selected" });
                    } else {
                        await executeMutation({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    if (id === "new") {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(result.data.upsertCustodianPositions[0]._id);
                                        navigate(path.join("/"), { replace: true });
                                    } else {
                                        const init = cloneDeep(result.data.upsertCustodianPositions[0]);
                                        setFormData(init);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }
                }}
            >
                {({ isSubmitting, values: temp }) => {
                    const values: PartialDeep<CustodianApiPosition> = temp;

                    let externalAccounts = allExternalAccounts;
                    if (values.clientId in clientsById) {
                        externalAccounts = externalAccountsInClient(clientsById[values.clientId]);
                    }
                    if (externalAccounts.length > 0 && externalAccounts[0].key !== "000000000000000000000000") {
                        externalAccounts.unshift({ key: "000000000000000000000000", value: "None" });
                    }

                    const instrument = instrumentById[values.instrumentId];
                    if (instrument) {
                        values.isin = instrument.isin ? instrument.isin : "";
                    } else {
                        values.isin = "";
                    }

                    const custodian = custodianByExternalAccountId[values.externalAccountId];
                    if (custodian) {
                        values.custodianId = custodian._id;
                    } else {
                        values.custodianId = "000000000000000000000000";
                    }

                    const externalAccount = externalAccountByExternalAccountId[values.externalAccountId];
                    if (externalAccount) {
                        values.accountNumber = externalAccount.custodianAccountNumber;
                        if (externalAccount.type === PartyExternalAccountType.CashAccount) {
                            instrumentOptions = externalAccount.partyInstruments;
                        }
                    } else {
                        values.accountNumber = "";
                    }

                    return (
                        <Form autoComplete="off">
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <TextField name="_id" label={"Id"} type="text" className="" spellCheck="false" disabled={true} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="custodianId"
                                        label={<Link to={"/parties/" + values.custodianId}>Custodian</Link>}
                                        options={custodians}
                                        className=""
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="status"
                                        label={<div>Status</div>}
                                        options={Object.keys(StatusEnum)}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="type"
                                        label={<div>Type</div>}
                                        options={Object.keys(CustodianPositionType)}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="clientId"
                                        label={<Link to={"/parties/" + values.clientId}>Client</Link>}
                                        options={clients}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="externalAccountId"
                                        label={
                                            <Link to={"/parties/" + values.clientId + "/externalAccounts/" + values.externalAccountId}>
                                                External account
                                            </Link>
                                        }
                                        options={externalAccounts}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <TextField name="isin" label={"ISIN"} type="text" className="" spellCheck="false" disabled={true} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <DateField name="date" label="Date" className="" disabled={isSubmitting} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <NumberField name="price" label="Price" className="" disabled={isSubmitting} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <TextField
                                        name="accountNumber"
                                        label={"Account number"}
                                        type="text"
                                        className=""
                                        spellCheck="false"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <NumberField name="quantity" label="Quantity" className="" disabled={isSubmitting} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SelectField
                                        name="currency"
                                        label="Currency*"
                                        options={Object.keys(CurrencyEnum).sort()}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <SearchListField
                                        className=""
                                        name={"instrumentId"}
                                        label={
                                            values.instrumentId in partyInstrumentsById ? (
                                                <Link to={"/parties/" + values.clientId + "/instruments/" + values.instrumentId}>
                                                    Party instrument
                                                </Link>
                                            ) : (
                                                <Link to={"/instruments/" + values.instrumentId}>Instrument</Link>
                                            )
                                        }
                                        options={instrumentOptions}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <TextField
                                        name="updateUserInfo.name"
                                        label={"Updated by"}
                                        type="text"
                                        className=""
                                        spellCheck="false"
                                        disabled={true}
                                    />
                                    {alert.visible ? (
                                        <div className="form-row">
                                            <div className="col-12">
                                                <Alert
                                                    style={{ marginTop: "10px" }}
                                                    variant={alert.color}
                                                    onClose={onDismissAlert}
                                                    dismissible
                                                >
                                                    {alert.message}
                                                </Alert>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="d-flex flex-row-reverse">
                                        <SubmitButton
                                            className="btn btn-success ms-2"
                                            disabled={isSubmitting}
                                            label={id === "new" ? "Create" : "Update"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <div className="row">
                <div className="col ms-1 mt-3" style={{ maxWidth: "531px" }}>
                    {isEditMode && formData ? (
                        <AttachmentForm
                            clientId={formData.clientId}
                            attachments={formData.attachments}
                            onChange={callBackOnChangeAttachment}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
