import React, { Fragment, ReactElement } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { DateField } from "../../components/form";
import { redirect } from "../../components/TAccountChartForm";
import { ISelectDropdownOption, SelectDropdown } from "../../components/SelectDropdown";

function SelectClientButton({
    id,
    endDate,
    clients,
    buttonText,
    page,
    createTAccountChart,
    cloneTAccountChart,
    recentTAccountCharts
}): ReactElement {
    const selectedClient = clients.find((d) => d._id === id);
    const navigate = useNavigate();
    return (
        <SelectDropdown
            id="selectClientButton"
            placeholder={buttonText}
            className="form-control form-select"
            defaultValue={selectedClient ? selectedClient._id : null}
            options={clients.map((c: any) => {
                return { key: c._id, text: c.name };
            })}
            onChange={async (e: ISelectDropdownOption) => {
                if (createTAccountChart && !recentTAccountCharts[e.text]) {
                    await createTAccountChart({ input: { name: e.text, clientId: e.key } })
                        .then(async (result) => {
                            redirect(navigate, result.data.createTAccountChart._id, -1, -1);
                        })
                        .catch((error) => {
                            console.log(error.toString());
                        });
                } else if (cloneTAccountChart && recentTAccountCharts[e.text]) {
                    await cloneTAccountChart({ name: recentTAccountCharts[e.text].name, clientId: e.key })
                        .then(async (result) => {
                            redirect(navigate, result.data.cloneTAccountChart._id, -1, -1);
                        })
                        .catch((error) => {
                            console.log(error.toString());
                        });
                } else {
                    navigate(`/${page}/${e.key}${endDate ? "/" + endDate : ""}`);
                }
            }}
        />
    );
}

function DateForm({ id, endDate }): ReactElement {
    const navigate = useNavigate();
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ endDate: endDate ? endDate : new Date().toISOString().slice(0, 10) }}
            onSubmit={() => {
                /* No submit button */
            }}
            validate={({ endDate }: { endDate }) => {
                if (id && endDate) {
                    navigate(`/accountingpm/${id}/${endDate}`, { replace: true });
                }
            }}
        >
            {() => (
                <Form autoComplete="off" className="dateform form">
                    <DateField className="width-fixed" name="endDate" label="End date" disabled={false} />
                </Form>
            )}
        </Formik>
    );
}

interface IClientDateSelector {
    id: string;
    endDate: string;
    clients: any;
    buttonText: string;
    page: string;
    createTAccountChart?: any;
    cloneTAccountChart?: any;
    recentTAccountCharts?: any;
}

export const ClientDateSelector = ({ id, endDate, clients, buttonText, page }: IClientDateSelector): ReactElement => {
    return (
        <Fragment>
            <SelectClientButton
                id={id}
                endDate={endDate}
                clients={clients}
                buttonText={buttonText}
                page={page}
                createTAccountChart={null}
                cloneTAccountChart={null}
                recentTAccountCharts={null}
            />
            <DateForm id={id} endDate={endDate} />
        </Fragment>
    );
};

export const ClientSelector = ({
    id,
    endDate,
    clients,
    buttonText,
    page,
    createTAccountChart,
    cloneTAccountChart,
    recentTAccountCharts
}: IClientDateSelector): ReactElement => {
    return (
        <Fragment>
            <SelectClientButton
                id={id}
                endDate={endDate}
                clients={clients}
                buttonText={buttonText}
                page={page}
                createTAccountChart={createTAccountChart}
                cloneTAccountChart={cloneTAccountChart}
                recentTAccountCharts={recentTAccountCharts}
            />
        </Fragment>
    );
};
