import { Inventory } from "./PortfolioLedger";

/**
 * ProfitLoss is a helper class. It stores and keeps the relationship between the three profit & loss components:
 * 1) from price
 * 2) from fx and
 * 3) from the composite price&fx.
 * The relationships are:
 * total = fromPrice + fromFx + fromPriceFx
 * fromFxTotal = fromFx + fromPriceFx
 */
export class ProfitLoss {
    constructor(inv: Inventory = null, qty = 0, pce = 0, fxr = 0, zeroqtynoinitcost = false) {
        this.quantity = qty;
        if (inv === null) {
            this.fromPrice = 0;
            this.fromFx = 0;
            this.total = 0;
            return;
        }
        if (qty === 0) {
            this.fromPrice = zeroqtynoinitcost ? 0 : inv.initialCost;
            this.fromFx = inv.initialFxRate !== null ? inv.sumAmount * (fxr - inv.initialFxRate) : 0;
            this.total = this.fromPrice + this.fromFx;
            return;
        }
        const initFxr = inv.initialFxRate !== null ? inv.initialFxRate : fxr;
        this.fromPrice = -qty * (pce - inv.initialPrice) * initFxr;
        this.fromFx = -qty * (fxr - initFxr) * inv.initialPrice;
        this.total = -qty * (pce * fxr - inv.initialPrice * initFxr);
    }

    quantity: number;
    fromPrice: number;
    fromFx: number;
    total: number;
    get fromPriceFx(): number {
        return this.fromFxTotal - this.fromFx;
    }
    get fromFxTotal(): number {
        return this.total - this.fromPrice;
    }
    static clone(c: ProfitLoss = null): ProfitLoss {
        const res = new ProfitLoss();
        if (!c) {
            return res;
        }
        res.quantity = c.quantity;
        res.fromPrice = c.fromPrice;
        res.fromFx = c.fromFx;
        res.total = c.total;
        return res;
    }
    add(pl: ProfitLoss = null): ProfitLoss {
        const res = ProfitLoss.clone(this);
        if (!pl) {
            return res;
        }
        res.quantity = pl.quantity;
        res.fromPrice += pl.fromPrice;
        res.fromFx += pl.fromFx;
        res.total += pl.total;
        return res;
    }
    subtract(pl: ProfitLoss = null): ProfitLoss {
        const res = ProfitLoss.clone(this);
        if (!pl) {
            return res;
        }
        res.quantity = pl.quantity;
        res.fromPrice -= pl.fromPrice;
        res.fromFx -= pl.fromFx;
        res.total -= pl.total;
        return res;
    }
    negate(): ProfitLoss {
        let res = new ProfitLoss();
        res = res.subtract(this);
        return res;
    }
}
