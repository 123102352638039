import React, { Fragment, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { sortBy } from "lodash";
import { cloneDeep } from "lodash";
import { Panel, IColumn, ICommandBarItemProps } from "@fluentui/react";
import { twoDecPriceFormat, numberFormat } from "../../../common/src";

import { YesNoModal } from "../components/YesNoModal";
import { DateForm } from "../common/dateForm";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { MiniTransactionForm } from "../containers/MiniTransactionForm";
import { BrokerTransactionForm } from "../containers/BrokerTransactionForm";
import { useQueryState } from "../common/use-query-state";
import { MultipleSelectDetailsGrid } from "./MultipleSelectDetailsGrid";
import { BrokerTransactionStatus } from "../types.generated";

const GET_BROKER_CONFIRMATIONS = gql`
    query brokerTransactions($filter: BrokerTransactionFilterInput) {
        brokerTransactions(filter: $filter) {
            _id
            broker {
                _id
                name
            }
            client {
                _id
                name
            }
            type
            description
            isin
            instrument {
                _id
                name
            }
            price
            quantity
            commission
            stampDuty
            currency
            settlementAmount
            tradeDate
            valueDate
            reportId
            externalId
            correspondingTransactionId
            error
            status
            updateTimestamp
            updateUserInfo {
                name
            }
        }
    }
`;

const UPDATE_STATUS_BROKER_CONFIRMATIONS = gql`
    mutation UpdateBrokerTransaction($input: UpdateBrokerTransactionInput!) {
        updateBrokerTransaction(input: $input) {
            _id
            status
        }
    }
`;

const CONFIRMATION_GROUPS = [BrokerTransactionStatus.Pending, BrokerTransactionStatus.Confirmed];

const groupByStatus = (confirmations, confirmationGroups) => {
    const result = {};
    confirmationGroups.forEach((status) => {
        result[status] = [];
    });

    confirmations = sortBy(confirmations, "exDate").reverse();
    confirmations.forEach((swift) => {
        if (confirmationGroups.includes(swift.status)) {
            result[swift.status].push(swift);
        }
    });
    const optimalConfirmationGroups = cloneDeep(confirmationGroups);
    const optimalResult = cloneDeep(result);
    const emptyStatuses = [];
    for (const key in result) {
        if (result[key].length === 0) {
            delete optimalResult[key];
            emptyStatuses.push(key);
        }
    }
    for (let i = optimalConfirmationGroups.length - 1; i >= 0; i--) {
        const status = optimalConfirmationGroups[i];
        if (emptyStatuses.includes(status)) {
            optimalConfirmationGroups.splice(i, 1);
        }
    }
    return [optimalResult, optimalConfirmationGroups];
};

export const BrokerConfirmationsUpdateGrid = ({ items, onStatusUpdated }): React.ReactElement => {
    const location = useLocation();
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [stateUpdate, updateConfirmationStatus] = useMutation(UPDATE_STATUS_BROKER_CONFIRMATIONS);

    useEffect(() => {
        if (stateUpdate && stateUpdate.data && !stateUpdate.error && onStatusUpdated) {
            onStatusUpdated();
        }
    }, [onStatusUpdated, stateUpdate]);

    const onSelectedChange = (e) => {
        setSelected(e.map((i) => i._id));
    };

    const updateStatus = async (itemIds: string[], newStatus) => {
        const allUpdates = itemIds.map(
            async (id: string) =>
                await updateConfirmationStatus({
                    input: { _id: id, status: newStatus }
                })
        );
        await Promise.all(allUpdates);
    };

    const columns: IColumn[] = [
        {
            key: "updateTimestamp",
            name: "Update timestamp",
            fieldName: "updateTimestamp",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return <span>{dateFormater(item.updateTimestamp)}</span>;
            }
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 30,
            onRender: (item: any) => {
                return <Link to={"/brokerconfirmations/brokertransaction/" + item._id + location.search}>{item.type}</Link>;
            }
        },
        {
            key: "broker",
            name: "Broker",
            fieldName: "broker",
            isResizable: true,
            minWidth: 40,
            onRender: (item: any) => {
                return item.broker && item.broker._id ? <Link to={"/parties/" + item.broker._id}>{item.broker.name}</Link> : null;
            }
        },
        {
            key: "client",
            name: "Client",
            fieldName: "client",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 170,
            onRender: (item: any) => {
                return item.client && item.client._id ? <Link to={"/parties/" + item.client._id}>{item.client.name}</Link> : null;
            }
        },
        {
            key: "instrument",
            name: "Instrument",
            fieldName: "instrument",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return item.instrument && item.instrument._id ? (
                    <Link to={"/instruments/" + item.client._id}>{item.instrument.name}</Link>
                ) : null;
            }
        },
        {
            key: "price",
            name: "Price",
            fieldName: "price",
            isResizable: true,
            minWidth: 60,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {numberFormat(item.price, "# ##0.#######")}
                    </span>
                );
            }
        },
        {
            key: "quantity",
            name: "Quantity",
            fieldName: "quantity",
            isResizable: true,
            minWidth: 70,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.quantity)}
                    </span>
                );
            }
        },
        {
            key: "commission",
            name: "Commission",
            fieldName: "commission",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.commission)}
                    </span>
                );
            }
        },
        {
            key: "stampDuty",
            name: "Stamp duty",
            fieldName: "stampDuty",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.stampDuty)}
                    </span>
                );
            }
        },
        {
            key: "settlementAmount",
            name: "Settlement amount",
            fieldName: "settlementAmount",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.settlementAmount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80,
            onRender: (item: any) => {
                return item.tradeTimestamp ? <span>{serializeSwedenDate(item.tradeTimestamp)}</span> : <span>{item.tradeDate}</span>;
            }
        },
        {
            key: "valueDate",
            name: "Value date",
            fieldName: "valueDate",
            minWidth: 80
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 70
        },
        {
            key: "download",
            name: "Original",
            fieldName: "download",
            isResizable: true,
            minWidth: 50
        },
        {
            key: "externalId",
            name: "External id",
            fieldName: "externalId",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return item._id ? (
                    <Link to={"/brokerconfirmations/brokertransaction/" + item._id + location.search}>{item.externalId}</Link>
                ) : null;
            }
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            isResizable: true,
            minWidth: 60
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            isResizable: true,
            minWidth: 60
        },
        {
            key: "correspondingTransactionId",
            name: "Transaction",
            fieldName: "correspondingTransactionId",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return item.correspondingTransactionId ? (
                    <Link to={"/brokerconfirmations/minitransaction/" + item.correspondingTransactionId + location.search}>
                        {item.correspondingTransactionId}
                    </Link>
                ) : null;
            }
        },
        {
            key: "updateUserInfo",
            name: "Update user info",
            fieldName: "updateUserInfo",
            isResizable: true,
            minWidth: 110,
            onRender: (item: any) => {
                return item.updateUserInfo ? <span>{item.updateUserInfo.name}</span> : null;
            }
        }
    ];

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            updateStatus(selected, "Pending");
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            updateStatus(selected, "Confirmed");
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            setModal({
                                showModal: true,
                                payload: { newStatus: "Deleted", ids: selected }
                            });
                        }
                    }
                ]
            }
        }
    ];

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete selected broker confirmations?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updateStatus(selected, "Deleted");
                    }}
                />
            ) : null}
            <MultipleSelectDetailsGrid
                key={items}
                items={items}
                columns={columns}
                commands={selected && selected.length > 0 ? commands : []}
                onSelectedChange={onSelectedChange}
            />
        </div>
    );
};

export const BrokerConfirmationsPage = ({ startDate }: { startDate: string }): React.ReactElement => {
    // All brokerConfirmations except deleted
    const [{ fetching: loading, error, data }, refetch] = useQuery({
        query: GET_BROKER_CONFIRMATIONS,
        variables: { filter: { statusIn: CONFIRMATION_GROUPS, startDate: startDate } },
        requestPolicy: "network-only"
    });

    if (loading) return <p>Loading broker confirmations</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const allConfirmations = data.brokerTransactions;
    const [groups, optimalConfirmationGroups] = groupByStatus(allConfirmations, CONFIRMATION_GROUPS);

    return (
        <div>
            {optimalConfirmationGroups.map((status) => (
                <div key={status} className="mt-4">
                    <h2>{status}</h2>
                    <BrokerConfirmationsUpdateGrid key={groups[status]} items={groups[status]} onStatusUpdated={refetch} />
                </div>
            ))}
        </div>
    );
};

export function BrokerConfirmationsCompletePage(): React.ReactElement {
    const params: any = useParams();
    const id = params.id;
    const type = params.type;
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="d-flex">
                <DateForm defaultDateString={startDate} dateName={"startDate"}></DateForm>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <BrokerConfirmationsPage startDate={startDate} />
                </div>
            </div>
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/brokerconfirmations` + location.search)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                {id && type === "brokertransaction" ? <BrokerTransactionForm id={id} startDate={startDate} /> : null}
            </Panel>
        </Fragment>
    );
}
