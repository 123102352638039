/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertAgreementsMutationVariables = Types.Exact<{
  input: Array<Types.AgreementInput> | Types.AgreementInput;
}>;


export type UpsertAgreementsMutation = { __typename?: 'Mutation', upsertAgreements?: Array<{ __typename?: 'Agreement', _id: string, clientId: string, counterPartyIds: Array<string>, createTimestamp: any, description: string, partyIds: Array<string>, status: Types.AgreementStatusEnum, type: Types.AgreementTypeEnum, updateTimestamp: any, updateUserId: string, versions?: any | null, attachments: Array<{ __typename?: 'Attachment', fileId: string, clientId?: string | null, fileName?: string | null, mimeType?: string | null, mD5?: string | null, updateTimestamp?: any | null } | null>, client: { __typename?: 'PartyInfo', _id: string, name: string }, counterParties: Array<{ __typename?: 'PartyInfo', _id: string, name: string }>, parties?: Array<{ __typename?: 'PartyInfo', _id: string, name: string } | null> | null, approvedInfo?: Array<{ __typename?: 'ApprovedInfo', status: Types.ApprovedStatusEnum, comment?: string | null, updateUserId: string, updateTimestamp: any, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }> | null, updateUserInfo: { __typename?: 'UserInfo', name: string } } | null> | null };

export type GetPartiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPartiesQuery = { __typename?: 'Query', clients?: Array<{ __typename?: 'Party', _id: string, name: string } | null> | null, parties?: Array<{ __typename?: 'Party', _id: string, name: string } | null> | null };


export const UpsertAgreementsDocument = gql`
    mutation UpsertAgreements($input: [AgreementInput!]!) {
  upsertAgreements(input: $input) {
    _id
    attachments {
      fileId
      clientId
      fileName
      mimeType
      mD5
      updateTimestamp
    }
    client {
      _id
      name
    }
    clientId
    counterParties {
      _id
      name
    }
    counterPartyIds
    createTimestamp
    description
    parties {
      _id
      name
    }
    partyIds
    status
    type
    approvedInfo {
      status
      comment
      updateUserId
      updateUserInfo {
        name
      }
      updateTimestamp
    }
    updateTimestamp
    updateUserId
    updateUserInfo {
      name
    }
    versions
  }
}
    `;

export function useUpsertAgreementsMutation() {
  return Urql.useMutation<UpsertAgreementsMutation, UpsertAgreementsMutationVariables>(UpsertAgreementsDocument);
};
export const GetPartiesDocument = gql`
    query GetParties {
  clients: parties(filter: {typeIn: [Client]}) {
    _id
    name
  }
  parties(
    filter: {typeIn: [Client, Broker, ClearingBroker, Custodian, ClearingHouse, EsgDataProvider]}
  ) {
    _id
    name
  }
}
    `;

export function useGetPartiesQuery(options?: Omit<Urql.UseQueryArgs<GetPartiesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPartiesQuery, GetPartiesQueryVariables>({ query: GetPartiesDocument, ...options });
};