import React, { Fragment, ReactElement } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Panel } from "@fluentui/react";

import { useQueryState } from "../common/use-query-state";
import { DateForm } from "../common/dateForm";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "./MiniTransactionForm";
import { DealBlotterGrid } from "./DealBlotterGrid";
import { serializeSwedenDate } from "../components/dateFormater";

export function DealBlotterPage(): ReactElement {
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(new Date().getFullYear() - 1, 0, 1)));

    const params: any = useParams();
    const navigate = useNavigate();
    const location: any = useLocation();

    let id = params.id;

    let type = miniTransactionTypes[0];

    if (id) {
        if (id.startsWith("new")) {
            type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
            id = "new";
        }
    }

    return (
        <Fragment>
            <div className="d-flex">
                <DateForm defaultDateString={startDate} dateName={"startDate"}></DateForm>
                <div className="ms-auto">
                    <NewMiniTransactionButton page="dealblotter" />
                </div>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <DealBlotterGrid startDate={startDate} />
                </div>
            </div>

            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => {
                    navigate({
                        pathname: "/dealblotter",
                        search: location.search
                    });
                }}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id ? <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} /> : null}
            </Panel>
        </Fragment>
    );
}
