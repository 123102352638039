import React from "react";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";
import { groupBy } from "lodash";

import { Column, Grid } from "../../../components/src";
import { emptyObjectId } from "../../../common/src";

import { BaseCalendar, Calendar } from "../types.generated";

const GetBaseCalendars = gql`
    query baseCalendars($filter: BaseCalendarFilterInput) {
        baseCalendars(filter: $filter) {
            _id
            name
            description
            clientIds
            year
            status
            clients {
                name
            }
            baseEvents {
                event
                date
                state
            }
            status
            source
        }
    }
`;

const GetParty = gql`
    query party($_id: GraphQLObjectId) {
        party(_id: $_id) {
            name
            fundInfo {
                calendarNames
                calendar {
                    names
                    calendarEvents {
                        events {
                            name
                            state
                            event
                        }
                        date
                        state
                    }
                }
            }
        }
    }
`;

export const CalendarPage = (): React.ReactElement => {
    // calendarId or partyId
    const { id } = useParams<"id">();

    const [{ fetching: loading, error, data }] = useQuery({
        query: GetBaseCalendars,
        variables: { filter: { idIn: [id] } },
        requestPolicy: "cache-and-network"
    });

    const [{ fetching: loadingParty, error: errorParty, data: dataParty }] = useQuery({
        query: GetParty,
        variables: { _id: id },
        requestPolicy: "cache-and-network"
    });

    if (loading || loadingParty) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorParty) return <p>error: {JSON.stringify(errorParty, null, 2)}</p>;

    const baseCalendar: BaseCalendar = data && data.baseCalendars ? data.baseCalendars[0] : [];

    const partyName = dataParty && dataParty.party ? dataParty.party.name : "No name";

    const calendar: Calendar =
        dataParty && dataParty.party && dataParty.party.fundInfo && dataParty.party.fundInfo.calendar
            ? dataParty.party.fundInfo.calendar
            : {};

    return (
        <div>
            <div className="row">
                {id && id !== emptyObjectId && baseCalendar ? (
                    <div className="col-12">
                        <Grid header={baseCalendar.name + "-" + baseCalendar.year} data={[baseCalendar]} sortable tableClassName="table-xs">
                            <Column field="name" title="Name" />
                            <Column field="description" title="Description" />
                            <Column field="source" title="Source" />
                            <Column field="year" title="Year" />
                            <Column field="status" title="Status" />
                            <Column
                                field="clients"
                                title="Clients (owners)"
                                format={(value, item) => {
                                    return item.clients.map((client) => client.name).join(", ");
                                }}
                            />
                        </Grid>

                        <Grid header={""} data={baseCalendar.baseEvents} sortable tableClassName="table-xs">
                            <Column field="date" title="Date" />
                            <Column field="event" title="Event" />
                            <Column field="state" title={"State"} />
                        </Grid>
                    </div>
                ) : id && id !== emptyObjectId && calendar ? (
                    <div className="col-8">
                        <Grid
                            header={partyName + ": " + calendar.names.join(" - ")}
                            data={calendar.calendarEvents}
                            sortable
                            tableClassName="table-xs"
                        >
                            <Column field="date" title="Date" />
                            <Column field="state" title={"State"} />
                            <Column
                                field="events"
                                title="Calendars"
                                format={(value, item) => {
                                    const eventsByState = groupBy(value, "state");
                                    return eventsByState[item.state].map((event) => event.name).join(", ");
                                }}
                            />
                        </Grid>
                    </div>
                ) : (
                    <p>No calendars found with id/for fund with id {id}</p>
                )}
            </div>
        </div>
    );
};
