import React, { Fragment, useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { startCase, sortBy, keyBy, cloneDeep } from "lodash";
import { useNavigate, useLocation, Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";
import { Alert, Button } from "react-bootstrap";
import dayjs from "dayjs";

import { emptyObjectId } from "../../../common/src";

import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { YesNoModal } from "../components/YesNoModal";
import { useAlertTimeOut, usePrevious, useQueryArgs } from "../common/Utils";
import {
    SubmitButton,
    TextField,
    SelectField,
    DateField,
    NumberField,
    SearchListField,
    TransactionField,
    CheckBoxField,
    NullableDateField,
    TimestampField
} from "../components/form";
import { AttachmentTransactionForm } from "./AttachmentTransactionForm";
import { isValidMongoDBObjectID } from "../common/Utils";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import {
    SourceType,
    CurrencyEnum,
    AgreementType,
    PartyType,
    Instrument,
    TransactionStatus,
    TransactionType,
    PartyAccountType,
    InstrumentProductTypeEnum,
    InstrumentTypeEnum,
    InstrumentModelTypeEnum,
    MiniTransaction
} from "../types.generated";
import { BestExecutionType, TransactionItemType } from "../types.generated";

export const miniTransactionTypes = [
    TransactionType.StockTrade,
    TransactionType.BondTrade,
    TransactionType.DerivativeTrade,
    "CloseDerivativeTrade",
    TransactionType.Collateral,
    TransactionType.Transfer,
    "FxTransfer",
    TransactionType.Dividend,
    TransactionType.Fee,
    TransactionType.Interest,
    TransactionType.Insert,
    TransactionType.FundTrade,
    TransactionType.CreateRedeem
];
const noExternalIdTypes = [
    TransactionType.Collateral,
    TransactionType.Transfer,
    "FxTransfer",
    TransactionType.Fee,
    TransactionType.Interest,
    "CloseDerivativeTrade",
    TransactionType.Insert,
    TransactionType.FundTrade
];

const defaultFatFingerTolerance = 50;

const GET_MINI_TRANSACTION = gql`
    query getMiniTransaction($id: GraphQLObjectId) {
        miniTransaction(_id: $id) {
            _id
            type
            accountId
            tradeTimestamp
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            brokerTradeId
            corporateActionSwift {
                _id
                externalId
            }
            brokerTransaction {
                _id
                externalId
            }
            source
            instrumentId
            externalAccountId
            currency
            valueDate
            settlementAmount
            clientId
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            dividendPaid
            taxRestitution
            payableReceivable
            reinvestedDividend
            reinvestedRebate
            fxRate
            accruedInterest
            agreementType
            uniqueTradeId
            clearingHouseId
            clearingBrokerId
            secondInstrumentId
            secondAmount
            secondCurrency
            secondAccountId
            bonusShares
            fee
            itemType
            parentId
            carryOwnCostDate
            initialMargin
            loan
            rounding
        }
    }
`;

const GET_DATA = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            name
            _id
            types
            inLedgerTradeDate
            instruments {
                _id
                name
                longName
                currency
            }
            accounts {
                _id
                name
                type
                description
            }
            externalAccounts {
                _id
                name
            }
        }

        brokers: parties(filter: { typeIn: [Broker] }) {
            _id
            name
            legalEntityIdentifier
        }

        clearingBrokersUnsorted: parties(filter: { typeIn: [ClearingBroker] }) {
            _id
            name
            legalEntityIdentifier
        }

        clearingHousesUnsorted: parties(filter: { typeIn: [ClearingHouse] }) {
            _id
            name
            legalEntityIdentifier
        }

        derivativeTransactions: miniTransactions(type: [DerivativeTrade]) {
            _id
            externalId
            source
        }
        insertTransactions: miniTransactions(type: [Insert]) {
            _id
            externalId
            source
            brokerId
            agreementType
            quantity
            instrument {
                model {
                    _t
                }
            }
        }
    }
`;

const GET_INSTRUMENTS = gql`
    query instruments($filter: InstrumentFilterInput) {
        instruments(filter: $filter) {
            _id
            isin
            name
            longName
            bloombergTicker
            currency
            productType
        }
    }
`;

const CREATE_MINI_TRANSACTIONS = gql`
    mutation CreateMiniTransactions($input: [CreateMiniTransactionInput!]!) {
        createMiniTransactions(input: $input) {
            _id
            type
            accountId
            tradeTimestamp
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            brokerTradeId
            corporateActionSwift {
                _id
                externalId
            }
            brokerTransaction {
                _id
                externalId
            }
            source
            instrumentId
            externalAccountId
            currency
            valueDate
            settlementAmount
            clientId
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            dividendPaid
            taxRestitution
            payableReceivable
            reinvestedDividend
            reinvestedRebate
            fxRate
            accruedInterest
            agreementType
            uniqueTradeId
            clearingHouseId
            clearingBrokerId
            secondInstrumentId
            secondAccountId
            secondAmount
            secondCurrency
            bonusShares
            fee
            itemType
            parentId
            carryOwnCostDate
            initialMargin
            loan
            rounding
        }
    }
`;

const UPDATE_MINI_TRANSACTION = gql`
    mutation UpdateMiniTransaction($input: UpdateMiniTransactionInput!) {
        updateMiniTransaction(input: $input) {
            _id
            type
            accountId
            tradeTimestamp
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            brokerTradeId
            corporateActionSwift {
                _id
                externalId
            }
            brokerTransaction {
                _id
                externalId
            }
            source
            instrumentId
            externalAccountId
            currency
            valueDate
            settlementAmount
            clientId
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            dividendPaid
            taxRestitution
            payableReceivable
            reinvestedDividend
            reinvestedRebate
            fxRate
            accruedInterest
            agreementType
            uniqueTradeId
            clearingHouseId
            clearingBrokerId
            secondInstrumentId
            secondAccountId
            secondAmount
            secondCurrency
            bonusShares
            fee
            itemType
            parentId
            carryOwnCostDate
            initialMargin
            loan
            rounding
        }
    }
`;

// const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

const agreementTypes = [{ key: null, value: "None" }].concat(
    sortBy(
        Object.keys(AgreementType).map((name) => {
            return { key: name, value: name };
        }),
        "value"
    )
);

const bestExecutionTypes = [{ key: null, value: "None" }].concat(
    sortBy(
        Object.keys(BestExecutionType).map((name) => {
            return { key: name, value: name };
        }),
        "value"
    )
);

const feeItemTypes = [
    { key: null, value: "None" },
    { key: TransactionItemType.Commission, value: "Commission" },
    { key: TransactionItemType.CustodyFee, value: "Custody Fee" },
    { key: TransactionItemType.Fee, value: "Fee" },
    { key: TransactionItemType.ForeignTax, value: "Foreign Tax" },
    { key: TransactionItemType.ManagementFee, value: "Management Fee" },
    { key: TransactionItemType.ManagementCost, value: "Management Cost" },
    { key: TransactionItemType.Rebate, value: "Rebate" }
];

const interestItemTypes = [
    { key: null, value: "None" },
    { key: TransactionItemType.Interest, value: "Interest" },
    { key: TransactionItemType.AccruedInterest, value: "Accrued Interest" }
];

const derivativeTradeInstrumentTypes = [
    InstrumentModelTypeEnum.Swap,
    InstrumentModelTypeEnum.Swaption,
    InstrumentModelTypeEnum.FxSwap,
    InstrumentModelTypeEnum.EquityBasketOption,
    InstrumentModelTypeEnum.EquityUnitOption,
    InstrumentModelTypeEnum.GenericSwap,
    InstrumentModelTypeEnum.GenericFxSwap,
    InstrumentModelTypeEnum.PortfolioSwap
];
const today = new Date().toISOString().slice(0, 10);

type MiniTransactionFormType = Omit<MiniTransaction & { insertSecurity: boolean }, "__typename">;

function resetValuesFunc(type): MiniTransactionFormType {
    return {
        clientId: emptyObjectId,
        _id: null,
        type,
        accountId: emptyObjectId,
        tradeTimestamp: today,
        effectiveDate: null,
        description: null,
        status: TransactionStatus.Pending,
        error: null,
        bestExecutionType: null,
        brokerId: emptyObjectId,
        uniqueTradeId: null,
        brokerTradeId: null,
        source: null,
        instrumentId: null,
        externalAccountId: emptyObjectId,
        currency: CurrencyEnum.SEK,
        valueDate: today,
        attachments: [],
        /* */
        quantity: null,
        price: null,
        amount: null,
        commission: null,
        stampDuty: null,
        rounding: null,
        foreignTax: null,
        dividendPaid: false,
        taxRestitution: false,
        reinvestedDividend: false,
        reinvestedRebate: false,
        fxRate: null,
        insertSecurity: false,
        initialMargin: false,
        loan: false,
        accruedInterest: null,
        agreementType: null,
        clearingHouseId: emptyObjectId,
        clearingBrokerId: emptyObjectId,
        secondInstrumentId: null,
        secondAccountId: emptyObjectId,
        secondAmount: null,
        secondCurrency: CurrencyEnum.SEK,
        bonusShares: null,
        fee: null,
        parentId: null,
        /* */
        settlementAmount: null,
        payableReceivable: null
    };
}

export interface Props {
    id?: string;
    type?: string;
    parentId?: string;
    clientId?: string;
    queryArgMode?: boolean;
}

export function MiniTransactionForm(props: Props): React.ReactElement {
    const previousProps = usePrevious(props);
    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const copyValues = useRef(null);

    const [values, setValues] = useState(null);

    const id = props.id ? props.id : emptyObjectId;
    const parentId = props.parentId ? props.parentId : null;

    const navigate = useNavigate();
    const location = useLocation();
    const alertRef = useRef<HTMLElement | null>(null);

    const [{ fetching: getMiniTransactionLoading, error: getMiniTransactionError, data: getMiniTransactionData }, refetch] = useQuery({
        query: GET_MINI_TRANSACTION,
        pause: id === emptyObjectId,
        variables: { id: id },
        requestPolicy: "network-only"
    });

    const [{ fetching: loading, error: getDataError, data }] = useQuery({
        query: GET_DATA
    });

    const [{ fetching: loadingInstruments, error: getInstrumentsError, data: dataInstruments }] = useQuery({
        query: GET_INSTRUMENTS,
        variables: { filter: { typeIn: [InstrumentTypeEnum.Instrument] } }
    });

    const [clientId, setClientId] = useState(props.clientId ? props.clientId : null);
    const [client, setClient] = useState({
        _id: null,
        name: "",
        types: [],
        inLedgerTradeDate: null,
        instruments: [],
        accounts: [],
        externalAccounts: []
    });
    const [isLocked, setIsLocked] = useState(false);
    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [_, createMiniTransactions] = useMutation(CREATE_MINI_TRANSACTIONS);
    const [__, updateMiniTransaction] = useMutation(UPDATE_MINI_TRANSACTION);

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        setTimeout(() => {
            if (alert && alert.visible) {
                alertRef.current?.scrollIntoView({
                    behavior: "smooth"
                });
            }
        }, 200);
    }, [alert]);

    useEffect(() => {
        if (copyValues && copyValues.current) {
            setValues(copyValues.current);
            copyValues.current = null;
        } else if (stableStringify(props) !== stableStringify(previousProps)) {
            setValues(null);
        } else if (!loading && !getMiniTransactionLoading && !getDataError && !getMiniTransactionError) {
            if (values === null) {
                if (props.id) {
                    const inputValues = cloneDeep(getMiniTransactionData.miniTransaction);

                    if (
                        inputValues.externalId &&
                        inputValues.externalId.includes("CLOSE") &&
                        inputValues.parentId &&
                        inputValues.parentId !== emptyObjectId
                    ) {
                        inputValues.type = "CloseDerivativeTrade";
                    }

                    if (!inputValues.clearingBrokerId) {
                        inputValues.clearingBrokerId = emptyObjectId;
                    }

                    setValues(inputValues);
                    setClientId(getMiniTransactionData.miniTransaction.clientId);
                } else {
                    if (parentId) {
                        const resetValues = resetValuesFunc(props.type);
                        resetValues.parentId = parentId;
                        if (props.clientId) {
                            resetValues.clientId = props.clientId;
                        }
                        setValues(resetValues);
                    } else {
                        const resetValues = resetValuesFunc(props.type);
                        if (props.clientId) {
                            resetValues.clientId = props.clientId;
                        }
                        setValues(resetValues);
                    }
                }
            } else if (
                values.externalId &&
                values.externalId.includes("CLOSE") &&
                values.parentId &&
                values.parentId !== emptyObjectId &&
                values.type !== "CloseDerivativeTrade"
            ) {
                const valuesCloseDerivativeTrade = cloneDeep(values);
                valuesCloseDerivativeTrade.type = "CloseDerivativeTrade";
                setValues(valuesCloseDerivativeTrade);
            } else if (
                values._id &&
                values.type === TransactionType.Transfer &&
                values.currency &&
                values.secondCurrency &&
                values.currency !== values.secondCurrency
            ) {
                const valuesFxTransfer = cloneDeep(values);
                valuesFxTransfer.type = "FxTransfer";
                setValues(valuesFxTransfer);
            } else if (values._id && values.type === TransactionType.Insert) {
                if (!values.insertSecurity && (values.quantity || values.quantity === 0) && (values.price || values.price === 0)) {
                    values.insertSecurity = true;
                    setValues(values);
                }
            }
        }
    }, [
        previousProps,
        props,
        values,
        data,
        clientId,
        parentId,
        getMiniTransactionData,
        getDataError,
        getMiniTransactionError,
        getMiniTransactionLoading,
        loading
    ]);

    // reset accountId on Client change!!!
    useEffect(() => {
        if (data && values) {
            const clientData = data.clients.find((c) => c._id === clientId);
            const v = cloneDeep(values);
            let resetClientSpecifics = false;
            if (clientData && clientData._id && client._id !== clientData._id) {
                setClient(clientData);
            }

            if (clientData && clientData.accounts && clientData.accounts.length > 0) {
                const acc = clientData.accounts.find((a) => a._id === v.accountId);
                if (!acc && v.accountId !== emptyObjectId) {
                    v.accountId = emptyObjectId;
                    resetClientSpecifics = true;
                }
            }
            if (clientData && clientData.externalAccounts && clientData.externalAccounts.length > 0) {
                const acc = clientData.externalAccounts.find((a) => a._id === v.externalAccountId);
                if (!acc && v.externalAccountId !== emptyObjectId) {
                    v.externalAccountId = emptyObjectId;
                    resetClientSpecifics = true;
                }
            }
            if (clientId === emptyObjectId && v.accountId !== emptyObjectId) {
                v.accountId = emptyObjectId; // reset if no client
                resetClientSpecifics = true;
            }
            if (clientId === emptyObjectId && v.externalAccountId !== emptyObjectId) {
                v.externalAccountId = emptyObjectId; // reset if no client
                resetClientSpecifics = true;
            }
            if (resetClientSpecifics) setValues(v);

            if (client && values._id && values.tradeTimestamp && values.status !== TransactionStatus.History) {
                setIsLocked(
                    client.inLedgerTradeDate
                        ? dayjs.tz(client.inLedgerTradeDate, "Europe/Stockholm").toDate().setHours(0, 0, 0, 0) >=
                              dayjs.tz(values.tradeTimestamp, "Europe/Stockholm").toDate().setHours(0, 0, 0, 0)
                        : false
                );
            } else if (!values._id && isLocked) {
                // This is needed after copying a transaction that is locked to make the fields for
                // the new transaction possible to edit
                setIsLocked(false);
            }
        } else if (values === null && id === emptyObjectId) {
            if (parentId) {
                const resetValues = resetValuesFunc(props.type);
                resetValues.parentId = parentId;
                if (props.clientId) {
                    resetValues.clientId = props.clientId;
                }
                setValues(resetValues);
            } else {
                const resetValues = resetValuesFunc(props.type);
                if (props.clientId) {
                    resetValues.clientId = props.clientId;
                }
                setValues(resetValues);
            }
        }
    }, [client, clientId, data, id, isLocked, parentId, props, values]);

    if (loading || getMiniTransactionLoading) return <div className="miniform-loading">Loading</div>;

    if (getMiniTransactionError) return <div>{"Error: " + getMiniTransactionError.message}</div>;

    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (getInstrumentsError) return <div>{"Error: " + getInstrumentsError.message}</div>;

    if (values && !miniTransactionTypes.includes(values.type))
        return <div>{"Error: MiniTransaction is not implemented for type " + values.type}</div>;

    if (values === null) return <div></div>;

    let instruments: ({ _id: string; name: string } & Partial<Instrument>)[] = [];
    if (!loadingInstruments && dataInstruments) {
        instruments = cloneDeep(dataInstruments.instruments);
    }

    let { clients, brokers } = cloneDeep(data);
    brokers = sortBy(brokers, "name");
    const { clearingBrokersUnsorted, clearingHousesUnsorted, derivativeTransactions, insertTransactions } = cloneDeep(data);
    const clearingHouses = sortBy(clearingHousesUnsorted, "name");
    const clearingBrokers = sortBy(clearingBrokersUnsorted, "name");

    derivativeTransactions.forEach((transaction) => {
        transaction["name"] = transaction.name ? transaction["name"] : transaction["externalId"];
        delete transaction["externalId"];
    });

    const insertDerivativeTransactions = [];
    insertTransactions.forEach((transaction) => {
        transaction["name"] = transaction.name ? transaction["name"] : transaction["externalId"];
        delete transaction["externalId"];
        if (
            transaction.quantity &&
            transaction.instrument &&
            transaction.instrument.model &&
            derivativeTradeInstrumentTypes.includes(transaction.instrument.model._t)
        ) {
            insertDerivativeTransactions.push(transaction);
        }
    });

    const derivativeAndInsertDerivativeTransactions = derivativeTransactions
        .concat(insertDerivativeTransactions)
        .concat({ _id: emptyObjectId, name: "No parent transaction", source: "", __typename: "MiniTransaction" });

    //Filter instruments options for FundTrade
    if (values && values.type === TransactionType.FundTrade) {
        instruments = instruments.reduce(function (filtered, item) {
            if (item.productType === InstrumentProductTypeEnum.Fund) filtered.push(item);

            return filtered;
        }, []);
    }

    // clearingHouseId
    const clearingHouse = clearingHouses.find((clearingHouse) => clearingHouse._id === null);
    if (!clearingHouse) {
        clearingHouses.unshift({ _id: emptyObjectId, name: "No clearing house" });
    } else if (clearingHouse.name.match("clearing")) {
        clearingHouse.name = "No clearing house";
    }

    // clearingBrokerId
    const clearingBroker = clearingBrokers.find((clearingBroker) => clearingBroker._id === null);
    if (!clearingBroker) {
        clearingBrokers.unshift({ _id: emptyObjectId, name: "No clearing broker" });
    } else if (clearingBroker.name.match("clearing")) {
        clearingBroker.name = "No clearing house";
    }

    // brokerId
    if (!brokers.find((d) => d._id === null)) {
        brokers.unshift({ _id: emptyObjectId, name: "No broker" });
    } else if (
        values.type &&
        (values.type === TransactionType.Collateral ||
            values.type === TransactionType.BondTrade ||
            values.type === TransactionType.DerivativeTrade)
    ) {
        // making sure No broker alternative is removed from brokers list,
        //otherwise issue with alternative remaining after choosing ex. stockTrade
        if (values.type === TransactionType.Collateral) {
            clearingBrokers.unshift({ _id: emptyObjectId, name: "No clearing broker" });
            brokers === brokers.push(clearingBrokers);
        }
        brokers = brokers.filter(function (obj) {
            return obj._id !== null;
        });
        // default broker is first one
        if (values.brokerId === emptyObjectId) {
            values.brokerId = brokers[0]._id;
        }
    }

    // Fee itemType - default first one
    if (values.type === TransactionType.Fee && !values.itemType) {
        values.itemType = feeItemTypes[0].key;
    }

    // Interest itemType - default first one
    if (values.type === TransactionType.Interest && !values.itemType) {
        values.itemType = interestItemTypes[0].key;
    }

    clients = sortBy(clients, "name");

    const instrumentsById = keyBy(instruments, "_id");

    const currencies = Object.values(CurrencyEnum).sort();

    let partyInstrumentsById: Record<string, any> = {};

    if (client && client.instruments) {
        if (values.type === TransactionType.Transfer || values.type === "FxTransfer") {
            instruments = [];
        }
        const instrumentsByCurrency: Record<string, Instrument[]> = {};
        if (
            values.type &&
            !values.type.endsWith("Trade") &&
            values.type !== TransactionType.Insert &&
            values.itemType !== TransactionItemType.AccruedInterest
        ) {
            const instrumentsByName = keyBy(instruments, "name");
            client.instruments.forEach((instrument) => {
                instrumentsByName[instrument.name] = instrument;
                instrumentsByCurrency[instrument.currency] = instrumentsByCurrency[instrument.currency]
                    ? instrumentsByCurrency[instrument.currency].concat([instrument as Instrument])
                    : [instrument as Instrument];
            });
            instruments = Object.values(instrumentsByName);
        }
        // Transfer can only transfer between same currencies, restricting which instruments are valid options
        if (values.type === TransactionType.Transfer && values.instrumentId && values.currency && !values.secondInstrumentId) {
            instruments = instrumentsByCurrency[values.currency] as ({ _id: string; name: string } & Partial<Instrument>)[];
        } else if (values.type === TransactionType.Transfer && values.secondInstrumentId && values.secondCurrency && !values.instrumentId) {
            instruments = instrumentsByCurrency[values.secondCurrency] as ({ _id: string; name: string } & Partial<Instrument>)[];
        }

        partyInstrumentsById = keyBy(client.instruments, "_id");
    }

    //Change instruments options for Insert cash
    if (values && values.type === TransactionType.Insert && !values.insertSecurity) {
        instruments = Object.values(partyInstrumentsById);
    }

    // accountId + externalAccountId
    let accounts = [{ _id: emptyObjectId, name: "Select account", description: "" }];
    let externalAccounts = [{ _id: emptyObjectId, name: "Select external account" }];
    if (client) {
        const clientAccounts = client.accounts.reduce(function (filtered, item) {
            if (item.type === PartyAccountType.Physical) {
                filtered.push({ _id: item._id, name: item.name, description: item.description });
            }

            return filtered;
        }, []);
        accounts = accounts.concat(clientAccounts);

        const clientExternalAccounts = client.externalAccounts.reduce(function (filtered, item) {
            filtered.push({ _id: item._id, name: item.name });

            return filtered;
        }, []);
        externalAccounts = externalAccounts.concat(clientExternalAccounts);
    }

    const sources = [{ key: null, value: "None" }].concat(
        sortBy(
            Object.keys(SourceType).map((item) => {
                return { key: item, value: item };
            }),
            "value"
        )
    );

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete transaction with id " + modal.payload._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let mutationData: any;
                        updateMiniTransaction({ input: modal.payload })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    mutationData = result.data.updateMiniTransaction;
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The transaction '${mutationData._id}' was updated and deleted successfully!`
                                    });
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                if (mutationData && mutationData._id) {
                                    setValues(mutationData);
                                    setClientId(mutationData.clientId);
                                    refetch();
                                }
                            });
                    }}
                />
            ) : null}

            <h3>{startCase(values.type)}</h3>

            <div id="minitransactionform" className="minitransactionform form">
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={values}
                    validate={(validateValues) => {
                        const { type } = values;
                        const noExternalId = noExternalIdTypes.findIndex((d) => d === type) >= 0;
                        const tradeType = type && type.endsWith("Trade");

                        if (!values.clientId) {
                            values.clientId = clientId;
                        } else {
                            setClientId(values.clientId);
                        }

                        if (
                            values.type !== "CloseDerivativeTrade" &&
                            validateValues.clientId &&
                            values.clientId.toString() !== validateValues.clientId.toString()
                        ) {
                            setClientId(validateValues.clientId);
                        }

                        // Insert security
                        if (
                            (validateValues.quantity || validateValues.quantity === 0) &&
                            (validateValues.price || validateValues.price === 0) &&
                            validateValues.type === TransactionType.Insert &&
                            !validateValues.insertSecurity
                        ) {
                            validateValues.insertSecurity = true;
                            setValues(validateValues);
                        }

                        // Insert close derivative trade
                        if (
                            validateValues.externalId &&
                            validateValues.externalId.includes("CLOSE") &&
                            validateValues.parentId &&
                            validateValues.parentId !== emptyObjectId
                        ) {
                            validateValues.type = "CloseDerivativeTrade";
                            setValues(validateValues);
                        }

                        //Insert derivative trade
                        if (
                            validateValues.bestExecutionType === "Maintenance" &&
                            validateValues.source === "Internal" &&
                            validateValues.type === TransactionType.Insert &&
                            validateValues.brokerId &&
                            validateValues.brokerId !== emptyObjectId &&
                            validateValues.agreementType &&
                            (!validateValues.parentId || validateValues.parentId === emptyObjectId)
                        ) {
                            validateValues.insert = true;
                            validateValues.type = TransactionType.DerivativeTrade;
                            setValues(validateValues);
                        }

                        if (!validateValues.brokerId) {
                            validateValues.brokerId = brokers.length > 0 ? brokers[0]._id : null;
                            setValues(validateValues);
                        }

                        // Filter accounts+externalAccounts based on client
                        if (!validateValues.accountId) {
                            validateValues.accountId = accounts.length > 0 ? accounts[0]._id : null;
                            setValues(validateValues);
                        }
                        if (!validateValues.externalAccountId) {
                            validateValues.externalAccountId = externalAccounts.length > 0 ? externalAccounts[0]._id : null;
                            setValues(validateValues);
                        }

                        if (!validateValues.itemType && validateValues.type === TransactionType.Fee) {
                            validateValues.itemType = feeItemTypes[0].key;
                            setValues(validateValues);
                        }

                        if (!validateValues.itemType && validateValues.type === TransactionType.Interest) {
                            validateValues.itemType = interestItemTypes[0].key;
                            setValues(validateValues);
                        }

                        /*if (!validateValues.instrumentId) {
                        validateValues.instrumentId = instruments.length > 0 ? instruments[0]._id : null;
                        setValues(validateValues);
                        }

                        if (!validateValues.secondInstrumentId && validateValues.type === TransactionType.Transfer) {
                            validateValues.secondInstrumentId = instruments.length > 0 ? instruments[0]._id : null;
                            setValues(validateValues);
                        }*/

                        if (!validateValues.agreementType) {
                            validateValues.agreementType = agreementTypes.length > 0 ? agreementTypes[0].key : null;
                            setValues(validateValues);
                        }

                        if (!validateValues.currency) {
                            if (validateValues.instrumentId in instrumentsById) {
                                validateValues.currency = instrumentsById[validateValues.instrumentId].currency;
                            } else if (validateValues.instrumentId in partyInstrumentsById) {
                                validateValues.currency = partyInstrumentsById[validateValues.instrumentId].currency;
                            } else {
                                validateValues.currency = currencies[0];
                            }
                            setValues(validateValues);
                        }

                        if (!validateValues.secondCurrency && type === "FxTransfer") {
                            if (validateValues.secondInstrumentId in partyInstrumentsById) {
                                validateValues.secondCurrency = partyInstrumentsById[validateValues.secondInstrumentId].currency;
                            } else {
                                validateValues.secondCurrency = currencies[0];
                            }
                            setValues(validateValues);
                        }

                        /*if (!validateValues.parentId && validateValues.type === "CloseDerivativeTrade") {
                            validateValues.parentId =
                                derivativeAndInsertDerivativeTransactions.length > 0 ? derivativeAndInsertDerivativeTransactions[0]._id : null;
                        }*/
                        if (
                            validateValues.clientId !== values.clientId ||
                            validateValues.currency !== values.currency ||
                            validateValues.instrumentId !== values.instrumentId ||
                            ((type === "FxTransfer" || type === TransactionType.Transfer) &&
                                validateValues.secondInstrumentId !== values.secondInstrumentId) ||
                            ((type === "FxTransfer" || type === TransactionType.Transfer) &&
                                validateValues.secondCurrency !== values.secondCurrency)
                        ) {
                            if (values.instrumentId !== validateValues.instrumentId) {
                                if (validateValues.instrumentId in partyInstrumentsById) {
                                    validateValues.currency = partyInstrumentsById[validateValues.instrumentId].currency;
                                }
                                if (validateValues.instrumentId in instrumentsById) {
                                    validateValues.currency = instrumentsById[validateValues.instrumentId].currency;
                                }
                            }
                            if (
                                values.secondInstrumentId !== validateValues.secondInstrumentId &&
                                (type === "FxTransfer" || type === TransactionType.Transfer)
                            ) {
                                if (validateValues.secondInstrumentId in partyInstrumentsById) {
                                    validateValues.secondCurrency = partyInstrumentsById[validateValues.secondInstrumentId].currency;
                                }
                                if (validateValues.secondInstrumentId in instrumentsById) {
                                    validateValues.secondCurrency = instrumentsById[validateValues.secondInstrumentId].currency;
                                }
                            }
                            setValues(validateValues);
                        }

                        //Revert transaction (creates one revert transaction and one new transaction), only Dividend + Interest atm
                        if (validateValues.revertTransaction) {
                            if (!validateValues.newValueDate) {
                                validateValues.newValueDate = validateValues.valueDate;
                            }
                            if (!validateValues.newStatus) {
                                validateValues.newStatus = TransactionStatus.Pending;
                            }
                            if (!validateValues.newCurrency) {
                                validateValues.newCurrency = validateValues.currency;
                            }

                            setValues(validateValues);
                        }

                        const errors: any = {};
                        if (
                            (tradeType && type !== "CloseDerivativeTrade" && type !== TransactionType.FundTrade) ||
                            (type === TransactionType.Insert && validateValues.insertSecurity)
                        ) {
                            ["price", "quantity"].forEach((d) => {
                                if (typeof validateValues[d] !== "number") {
                                    errors[d] = "Required";
                                }
                            });
                            if (validateValues.brokerId === null && type !== TransactionType.StockTrade) {
                                errors.brokerId = "Required";
                            }
                        }
                        let requiredFields = ["tradeTimestamp", "valueDate"];

                        if (type !== "CloseDerivativeTrade") {
                            requiredFields.push("currency", "clientId");
                        }
                        if (
                            (type !== "CloseDerivativeTrade" && type !== TransactionType.FundTrade && type !== TransactionType.Insert) ||
                            (type === TransactionType.Insert && values.insertSecurity)
                        ) {
                            requiredFields.push("accountId");
                        }

                        if (!noExternalId && !validateValues.insert) {
                            requiredFields.push("externalId");
                            requiredFields.push("source");
                        }

                        const regExpExternalId = new RegExp("[^A-Z0-9-_.]");
                        if (validateValues.externalId && regExpExternalId.test(validateValues.externalId)) {
                            errors.externalId = "Only valid characters: capital A-Z, numbers 0-9 and three special characters .-_";
                        }

                        if (type !== "CloseDerivativeTrade") {
                            requiredFields.push("instrumentId");
                        }

                        if (type === TransactionType.Fee || type === TransactionType.Interest) {
                            requiredFields.push("itemType");
                        }

                        if (type === "CloseDerivativeTrade") {
                            requiredFields.push("parentId");
                        }

                        if (type === TransactionType.FundTrade) {
                            requiredFields.push("quantity");
                        }

                        if (type === TransactionType.BondTrade || type === TransactionType.Collateral) {
                            requiredFields.push("brokerId");
                        }

                        if (type === TransactionType.DerivativeTrade) {
                            requiredFields.push("uniqueTradeId");
                            requiredFields.push("agreementType");
                        }

                        if (type === TransactionType.Transfer) {
                            requiredFields.push("secondInstrumentId");
                            requiredFields.push("amount");
                        }

                        if (type === TransactionType.Collateral) {
                            requiredFields.push("quantity");
                            requiredFields.push("agreementType");
                        }

                        if (
                            ((tradeType && !validateValues.insert) || type === TransactionType.Transfer || type === "FxTransfer") &&
                            type !== "CloseDerivativeTrade" &&
                            type !== TransactionType.FundTrade
                        ) {
                            requiredFields.push("bestExecutionType");
                        }

                        if (
                            (!tradeType || type === "CloseDerivativeTrade") &&
                            type !== TransactionType.Collateral &&
                            type !== TransactionType.Insert
                        ) {
                            requiredFields.push("amount");
                        }

                        if (type === "FxTransfer") {
                            requiredFields.push("secondCurrency");
                            requiredFields.push("secondInstrumentId");
                            requiredFields.push("secondAmount");
                        }

                        if ((type === TransactionType.Dividend || type === TransactionType.Interest) && validateValues.revertTransaction) {
                            requiredFields = requiredFields.concat(["newValueDate", "newCurrency", "newAmount"]);
                        }

                        requiredFields.forEach((d) => {
                            if (
                                (!validateValues[d] && validateValues[d] !== 0) ||
                                (validateValues[d] && validateValues[d] === emptyObjectId) ||
                                validateValues[d] === "None"
                            ) {
                                errors[d] = "Required";
                            }
                        });

                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    onSubmit={(submitValues, { setSubmitting, setErrors }) => {
                        let input = { ...submitValues };
                        if (input.valueDate instanceof Date) {
                            input.valueDate = input.valueDate.toISOString().slice(0, 10);
                        }
                        ["brokerId", "clearingHouseId", "clearingBrokerId"].forEach((d) => {
                            if (input[d] === "") {
                                input[d] = null;
                            }
                        });
                        delete input.brokerTransaction;
                        delete input.corporateActionSwift;
                        delete input.settlementAmount;
                        delete input.payableReceivable;
                        delete input.updateTimestamp;
                        if (input.type === "CloseDerivativeTrade" && !input.insert) {
                            input.close = true;
                            input.type = TransactionType.DerivativeTrade;
                        } else if (input.type === "CloseDerivativeTrade" && input.insert) {
                            input.close = true;
                            input.type = TransactionType.Insert;
                        } else if (input.type === TransactionType.DerivativeTrade && input.insert) {
                            delete input.parentId;
                            input.type = TransactionType.Insert;
                        }

                        delete input.insert;
                        if (!input.insertSecurity && input.type === TransactionType.Insert) {
                            delete input.quantity;
                            delete input.price;
                        }
                        delete input.insertSecurity;

                        if (input.type === TransactionType.Transfer) {
                            input.secondCurrency = input.currency;
                            input.secondAmount = -input.amount;
                        }

                        if (input.type === "FxTransfer") {
                            input.type = TransactionType.Transfer;
                        }

                        // Only applies to transfer and only relevant if set to id other than default emptyObjectId
                        if (
                            ((input.type === TransactionType.Transfer || input.type === "FxTransfer") &&
                                input.secondAccountId === emptyObjectId) ||
                            (input.type !== TransactionType.Transfer && input.type !== "FxTransfer")
                        ) {
                            delete input.secondAccountId;
                        }

                        //Revert transaction (creates one revert transaction and one new transaction), only Dividend + Interest atm
                        if (input.revertTransaction) {
                            input.amount = input.newAmount;
                            input.status = input.newStatus;
                            input.tradeTimestamp = today;
                            input.valueDate = input.newValueDate;
                            input.currency = input.newCurrency;
                            input.foreignTax = input.newForeignTax;
                            input.rounding = input.newRounding;
                            input.parentId = input._id;
                            input._id = null;
                        }
                        delete input.newAmount;
                        delete input.newStatus;
                        delete input.newValueDate;
                        delete input.newCurrency;
                        delete input.newForeignTax;
                        delete input.newRounding;

                        if (input._id !== null) {
                            delete input.type;
                            delete input.source;
                            delete input.externalId;
                            delete input.clientId;
                            delete input.revertTransaction;
                            input = recursivelyRemoveKey(input, "__typename");
                            if (input.status && input.status === TransactionStatus.Deleted) {
                                setModal({ showModal: true, payload: input });
                            } else {
                                let mutationData: any;
                                updateMiniTransaction({ input })
                                    .then((result) => {
                                        if ("error" in result && result.error) {
                                            const message = formikUrqlErrorFormater(result.error, setErrors);
                                            setAlert({ color: "danger", visible: true, message });
                                        } else {
                                            mutationData = result.data.updateMiniTransaction;

                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `The transaction '${mutationData._id}' was updated successfully!`
                                            });
                                        }
                                        return true;
                                    })
                                    .catch((error) => {
                                        setAlert({ color: "danger", visible: true, message: error.toString() });
                                    })
                                    .finally(() => {
                                        if (mutationData && !mutationData.clearingBrokerId) {
                                            mutationData.clearingBrokerId = emptyObjectId;
                                        }
                                        if (mutationData && mutationData._id) {
                                            // Insert close derivative trade
                                            if (
                                                mutationData.externalId &&
                                                mutationData.externalId.includes("CLOSE") &&
                                                mutationData.parentId &&
                                                mutationData.parentId !== emptyObjectId
                                            ) {
                                                mutationData.type = "CloseDerivativeTrade";
                                            }

                                            //Insert derivative trade
                                            if (
                                                mutationData.bestExecutionType === "Maintenance" &&
                                                mutationData.source === "Internal" &&
                                                mutationData.type === TransactionType.Insert &&
                                                mutationData.brokerId &&
                                                mutationData.brokerId !== emptyObjectId &&
                                                mutationData.agreementType &&
                                                (!mutationData.parentId || mutationData.parentId === emptyObjectId)
                                            ) {
                                                mutationData.insert = true;
                                                mutationData.type = TransactionType.DerivativeTrade;
                                            }

                                            setValues(mutationData);
                                            setClientId(mutationData.clientId);
                                            refetch();
                                        }
                                        setSubmitting(false);
                                    });
                            }
                        } else {
                            delete input._id;
                            delete input.effectiveDate;
                            delete input.brokerTradeId;
                            delete input.error;

                            input = recursivelyRemoveKey(input, "__typename");

                            let mutationData: any;
                            createMiniTransactions({ input: [input] })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        mutationData = result.data.createMiniTransactions[0];
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `The transaction(s) '${mutationData._id}' was(were) created successfully!`
                                        });
                                    }
                                    return true;
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                })
                                .finally(() => {
                                    if (mutationData && mutationData._id) {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        // Using for closing positions on portfoliomanager->positions page, switching between
                                        // closing a derivativeTrade and showing the new closeDerivativeTrade created
                                        if (props.queryArgMode) {
                                            pushQueryArgs({ ...queryArgs, id: mutationData._id });
                                        } else {
                                            path.push(mutationData._id + location.search);
                                            navigate(path.join("/"), { replace: true });
                                        }

                                        if (!mutationData.clearingBrokerId) {
                                            mutationData.clearingBrokerId = emptyObjectId;
                                        }

                                        // Insert close derivative trade
                                        if (
                                            mutationData.externalId &&
                                            mutationData.externalId.includes("CLOSE") &&
                                            mutationData.parentId &&
                                            mutationData.parentId !== emptyObjectId
                                        ) {
                                            mutationData.type = "CloseDerivativeTrade";
                                        }

                                        //Insert derivative trade
                                        if (
                                            mutationData.bestExecutionType === "Maintenance" &&
                                            mutationData.source === "Internal" &&
                                            mutationData.type === TransactionType.Insert &&
                                            mutationData.brokerId &&
                                            mutationData.brokerId !== emptyObjectId &&
                                            mutationData.agreementType &&
                                            (!mutationData.parentId || mutationData.parentId === emptyObjectId)
                                        ) {
                                            mutationData.insert = true;
                                            mutationData.type = TransactionType.DerivativeTrade;
                                        }
                                        setValues(mutationData);
                                        refetch();
                                    }

                                    setSubmitting(false);
                                });
                        }

                        //setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, values, errors }) => {
                        const { type } = values;
                        const noExternalId = noExternalIdTypes.findIndex((d) => d === type) >= 0;
                        const tradeType = type && type.endsWith("Trade");

                        //if (Object.keys(errors).length > 0) {
                        //    console.log(errors);
                        //}
                        // values.tradeTimestamp = values.tradeTimestamp.substring(0, 10);

                        return (
                            <Fragment>
                                <div className="row">
                                    {isLocked && !values.revertTransaction ? (
                                        <div>
                                            <i style={{ color: "red" }}>{"Restrictions apply due to locked accounting"}</i>
                                        </div>
                                    ) : null}
                                </div>
                                {values._id && values.type !== TransactionType.CreateRedeem && !values.revertTransaction ? (
                                    //Not possible to create new createRedeems, only view and update

                                    <CopyMiniTransactionButton
                                        queryArgMode={props.queryArgMode}
                                        type={values.type}
                                        values={values}
                                        copyValues={copyValues}
                                    />
                                ) : null}
                                <div>
                                    <Form autoComplete="off">
                                        <div className="form-row">
                                            {type !== "CloseDerivativeTrade" ? (
                                                <SelectField
                                                    name="clientId"
                                                    label={<Link to={"/parties/" + values.clientId}>Client*</Link>}
                                                    options={[
                                                        { key: emptyObjectId, value: "Select client" },
                                                        ...clients
                                                            .map((d) => {
                                                                if (values && (values.type === TransactionType.Insert || values.insert)) {
                                                                    if (!d.types.includes(PartyType.Fund)) {
                                                                        return { key: d._id, value: d.name };
                                                                    }
                                                                } else {
                                                                    return { key: d._id, value: d.name };
                                                                }
                                                            })
                                                            .filter((client) => client)
                                                    ]}
                                                    className="col-12"
                                                    disabled={values._id ? true : isSubmitting}
                                                />
                                            ) : null}
                                            {(type !== "CloseDerivativeTrade" && type !== TransactionType.Insert) ||
                                            (type === TransactionType.Insert && values.insertSecurity) ? (
                                                <SelectField
                                                    key={`acc-${clientId}`}
                                                    name="accountId"
                                                    label={
                                                        values.clientId !== emptyObjectId && values.accountId !== emptyObjectId ? (
                                                            <Link to={"/parties/" + values.clientId + "/accounts/" + values.accountId}>
                                                                {type === TransactionType.FundTrade ? "Account" : "Account*"}
                                                            </Link>
                                                        ) : (
                                                            <div>{type === TransactionType.FundTrade ? "Account" : "Account*"}</div>
                                                        )
                                                    }
                                                    options={accounts.map((d) => ({ key: d._id, value: `${d.name} ${d.description}` }))}
                                                    className="col-12"
                                                    disabled={
                                                        isSubmitting ||
                                                        isLocked ||
                                                        !values.clientId ||
                                                        values.clientId === emptyObjectId ||
                                                        values.type === TransactionType.CreateRedeem
                                                    }
                                                />
                                            ) : null}
                                            {(type === "CloseDerivativeTrade" && !values._id) ||
                                            (type === TransactionType.DerivativeTrade && !values._id) ? (
                                                <CheckBoxField
                                                    name="insert"
                                                    label="Insert*"
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked}
                                                />
                                            ) : null}
                                            <div className="col-12">
                                                <TimestampField
                                                    name="tradeTimestamp"
                                                    label="Trade timestamp*"
                                                    className="ps-2 pe-2"
                                                    disabled={isSubmitting || isLocked || values.type === TransactionType.CreateRedeem}
                                                />
                                            </div>
                                            {(tradeType &&
                                                type !== TransactionType.StockTrade &&
                                                type !== TransactionType.FundTrade &&
                                                type !== "CloseDerivativeTrade") ||
                                            type === TransactionType.Collateral ||
                                            type === TransactionType.Fee ||
                                            type === TransactionType.Interest ? (
                                                <SelectField
                                                    name="brokerId"
                                                    label={
                                                        <Link to={"/parties/" + values.brokerId}>
                                                            {type === TransactionType.Collateral ? "Counterparty*" : "Broker*"}
                                                        </Link>
                                                    }
                                                    options={brokers.map((d) => ({ key: d._id, value: d.name }))}
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked}
                                                />
                                            ) : null}
                                            {type === TransactionType.StockTrade ? (
                                                <SelectField
                                                    name="brokerId"
                                                    label={<Link to={"/parties/" + values.brokerId}>Broker</Link>}
                                                    options={brokers.map((d) => ({ key: d._id, value: d.name }))}
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked}
                                                />
                                            ) : null}
                                            {type === TransactionType.Transfer || type === "FxTransfer" ? (
                                                <SelectField
                                                    name="brokerId"
                                                    label={<Link to={"/parties/" + values.brokerId}>Broker</Link>}
                                                    options={brokers.map((d) => ({ key: d._id, value: d.name }))}
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked}
                                                />
                                            ) : null}
                                            {(type === TransactionType.Transfer ||
                                                type === "FxTransfer" ||
                                                (tradeType && type !== "CloseDerivativeTrade" && type !== TransactionType.FundTrade)) &&
                                            !values.insert ? (
                                                <SelectField
                                                    name="bestExecutionType"
                                                    label="Best execution*"
                                                    options={bestExecutionTypes}
                                                    className="col-12"
                                                    disabled={isSubmitting}
                                                />
                                            ) : null}
                                            {type !== TransactionType.Transfer &&
                                            type !== "FxTransfer" &&
                                            !tradeType &&
                                            type !== TransactionType.Collateral ? (
                                                <SelectField
                                                    name="currency"
                                                    label="Currency*"
                                                    options={currencies}
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked || values.type === TransactionType.CreateRedeem}
                                                />
                                            ) : null}
                                            <DateField
                                                name="valueDate"
                                                label="Value date*"
                                                className="col-12"
                                                disabled={isSubmitting || values.revertTransaction}
                                            />
                                            {type === TransactionType.Transfer || type === "FxTransfer" ? (
                                                <NullableDateField
                                                    name="carryOwnCostDate"
                                                    label="Carry own cost date"
                                                    className="col-12"
                                                    disabled={isSubmitting || isLocked}
                                                />
                                            ) : null}
                                            {type !== TransactionType.Transfer &&
                                            type !== "FxTransfer" &&
                                            type !== "CloseDerivativeTrade" ? (
                                                <SearchListField
                                                    name="instrumentId"
                                                    label={
                                                        values.instrumentId in partyInstrumentsById ? (
                                                            <Link
                                                                to={"/parties/" + values.clientId + "/instruments/" + values.instrumentId}
                                                            >
                                                                PartyInstrument*
                                                            </Link>
                                                        ) : (
                                                            <Link to={"/instruments/" + values.instrumentId}>Instrument*</Link>
                                                        )
                                                    }
                                                    className="col-12"
                                                    options={instruments}
                                                    disabled={isSubmitting || isLocked || values.type === TransactionType.CreateRedeem}
                                                />
                                            ) : null}

                                            {tradeType && type !== "CloseDerivativeTrade" && type !== TransactionType.FundTrade ? (
                                                <Fragment>
                                                    <SelectField
                                                        name="currency"
                                                        label="Currency*"
                                                        options={currencies}
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NumberField
                                                        name="quantity"
                                                        label="Quantity*"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="price"
                                                        label="Price*"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                </Fragment>
                                            ) : null}
                                            {type === TransactionType.StockTrade ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="commission"
                                                        label="Commission"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="stampDuty"
                                                        label="Stamp duty"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NumberField
                                                        name="rounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NullableDateField
                                                        name="carryOwnCostDate"
                                                        label="Carry own cost date"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.BondTrade ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="accruedInterest"
                                                        label="Accrued interest"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="rounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />

                                                    <NullableDateField
                                                        name="carryOwnCostDate"
                                                        label="Carry own cost date"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.DerivativeTrade ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="accruedInterest"
                                                        label="Accrued interest"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="rounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="clearingHouseId"
                                                        label="Clearing house"
                                                        options={clearingHouses.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="clearingBrokerId"
                                                        label="Clearing broker"
                                                        options={clearingBrokers.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="agreementType"
                                                        label="Agreement type*"
                                                        className="col-12"
                                                        options={agreementTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NullableDateField
                                                        name="carryOwnCostDate"
                                                        label="Carry own cost date"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                    />

                                                    {/* <SelectField name="clearingHouseId" className="col-12" /> */}
                                                </Fragment>
                                            ) : type === TransactionType.FundTrade ? (
                                                <Fragment>
                                                    <Fragment>
                                                        <SelectField
                                                            name="currency"
                                                            label="Currency"
                                                            options={currencies}
                                                            className="col-12"
                                                            disabled={isSubmitting || isLocked}
                                                        />
                                                        <SelectField
                                                            key={`externalAccount-${clientId}`}
                                                            name="externalAccountId"
                                                            label={
                                                                values.clientId !== emptyObjectId &&
                                                                values.externalAccountId !== emptyObjectId ? (
                                                                    <Link
                                                                        to={
                                                                            "/parties/" +
                                                                            values.clientId +
                                                                            "/externalAccounts/" +
                                                                            values.externalAccountId
                                                                        }
                                                                    >
                                                                        {"External account"}
                                                                    </Link>
                                                                ) : (
                                                                    <div>{"External account"}</div>
                                                                )
                                                            }
                                                            options={externalAccounts.map((d) => ({ key: d._id, value: d.name }))}
                                                            className="col-12"
                                                            disabled={
                                                                isSubmitting ||
                                                                isLocked ||
                                                                !values.clientId ||
                                                                values.clientId === emptyObjectId
                                                            }
                                                        />
                                                        <CheckBoxField
                                                            name="reinvestedDividend"
                                                            label="Reinvested dividend"
                                                            className="col-12"
                                                            disabled={isSubmitting || isLocked}
                                                        />
                                                        <CheckBoxField
                                                            name="reinvestedRebate"
                                                            label="Reinvested rebate"
                                                            className="col-12"
                                                            disabled={isSubmitting || isLocked}
                                                        />
                                                        <NumberField
                                                            name="fxRate"
                                                            label="Fx rate"
                                                            className="col-12 col-sm-4"
                                                            disabled={isSubmitting || isLocked}
                                                            changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                        />
                                                        <NumberField
                                                            name="quantity"
                                                            label="Quantity*"
                                                            className="col-12 col-sm-4"
                                                            disabled={isSubmitting || isLocked}
                                                            changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                        />
                                                        <NumberField
                                                            name="price"
                                                            label="Price"
                                                            className="col-12 col-sm-4"
                                                            disabled={isSubmitting || isLocked}
                                                            changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                        />
                                                    </Fragment>
                                                    <NumberField
                                                        name="commission"
                                                        label="Commission"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount"
                                                        className="col-12 col-sm-4"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.Dividend ? (
                                                <Fragment>
                                                    <SelectField
                                                        key={`externalAccount-${clientId}`}
                                                        name="externalAccountId"
                                                        label={
                                                            values.clientId !== emptyObjectId &&
                                                            values.externalAccountId !== emptyObjectId ? (
                                                                <Link
                                                                    to={
                                                                        "/parties/" +
                                                                        values.clientId +
                                                                        "/externalAccounts/" +
                                                                        values.externalAccountId
                                                                    }
                                                                >
                                                                    {"External account"}
                                                                </Link>
                                                            ) : (
                                                                <div>{"External account"}</div>
                                                            )
                                                        }
                                                        options={externalAccounts.map((d) => ({ key: d._id, value: d.name }))}
                                                        className="col-12"
                                                        disabled={true}
                                                    />
                                                    <CheckBoxField
                                                        name="dividendPaid"
                                                        label="Dividend paid"
                                                        className="col-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <CheckBoxField
                                                        name="taxRestitution"
                                                        label="Tax restitution"
                                                        className="col-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="foreignTax"
                                                        label="Foreign tax"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="rounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.Fee ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount*"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <SelectField
                                                        name="itemType"
                                                        label="Fee type*"
                                                        className="col-12"
                                                        options={feeItemTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="agreementType"
                                                        label="Agreement type"
                                                        className="col-12"
                                                        options={agreementTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.Insert ? (
                                                <Fragment>
                                                    <CheckBoxField
                                                        name="insertSecurity"
                                                        label="Security (default cash)"
                                                        className="col-12"
                                                        disabled={values._id ? true : isSubmitting || isLocked}
                                                    />
                                                    {values.insertSecurity ? (
                                                        <Fragment>
                                                            <NumberField
                                                                name="quantity"
                                                                label="Quantity"
                                                                className="col-12 col-sm-6"
                                                                disabled={isSubmitting || isLocked}
                                                                changeTolerancePercent={
                                                                    values && values._id ? defaultFatFingerTolerance : null
                                                                }
                                                            />
                                                            <NumberField
                                                                name="price"
                                                                label="Price"
                                                                className="col-12 col-sm-6"
                                                                disabled={isSubmitting || isLocked}
                                                                changeTolerancePercent={
                                                                    values && values._id ? defaultFatFingerTolerance : null
                                                                }
                                                            />
                                                        </Fragment>
                                                    ) : null}
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.Interest ? (
                                                <Fragment>
                                                    <SelectField
                                                        name="itemType"
                                                        label="Interest type*"
                                                        className="col-12"
                                                        options={interestItemTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="agreementType"
                                                        label="Agreement type"
                                                        className="col-12"
                                                        options={agreementTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="foreignTax"
                                                        label="Foreign tax"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="rounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : type === "CloseDerivativeTrade" ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount*"
                                                        className="col-12"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                </Fragment>
                                            ) : type === TransactionType.Collateral ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="quantity"
                                                        label="Quantity*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <SelectField
                                                        name="currency"
                                                        label="Currency*"
                                                        options={currencies}
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <SelectField
                                                        name="agreementType"
                                                        label="Agreement type*"
                                                        className="col-12"
                                                        options={agreementTypes}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <CheckBoxField
                                                        name="initialMargin"
                                                        label="Initial margin"
                                                        className="col-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <CheckBoxField
                                                        name="loan"
                                                        label="Loan"
                                                        className="col-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : null}

                                            {type === "CloseDerivativeTrade" ? (
                                                <Fragment>
                                                    <NullableDateField
                                                        name="carryOwnCostDate"
                                                        label="Carry own cost date"
                                                        className="col-12"
                                                        disabled={isSubmitting}
                                                    />
                                                    <TransactionField
                                                        name="parentId"
                                                        label={
                                                            values.parentId && values.parentId !== emptyObjectId ? (
                                                                <Link to={"/transaction/" + values.parentId}>Parent transaction*</Link>
                                                            ) : (
                                                                "Parent transaction*"
                                                            )
                                                        }
                                                        className="col-12"
                                                        options={derivativeAndInsertDerivativeTransactions}
                                                        disabled={values._id || isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : null}

                                            {(tradeType && type !== "CloseDerivativeTrade" && !values.insert) ||
                                            type === TransactionType.Interest ||
                                            type === TransactionType.Dividend ? (
                                                <NumberField
                                                    name="settlementAmount"
                                                    label="Settlement amount"
                                                    disabled={true}
                                                    className="col-12 col-sm-6"
                                                />
                                            ) : null}
                                            {values.payableReceivable ? (
                                                <NumberField
                                                    name="payableReceivable"
                                                    label="Payable receivable"
                                                    className="col-12"
                                                    disabled={true}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="form-row">
                                            {type === TransactionType.Transfer || type === "FxTransfer" ? (
                                                <Fragment>
                                                    <SearchListField
                                                        name="instrumentId"
                                                        label={
                                                            values.instrumentId in partyInstrumentsById ? (
                                                                <Link
                                                                    to={
                                                                        "/parties/" +
                                                                        values.clientId +
                                                                        "/instruments/" +
                                                                        values.instrumentId
                                                                    }
                                                                >
                                                                    Instrument*
                                                                </Link>
                                                            ) : (
                                                                "Instrument*"
                                                            )
                                                        }
                                                        className="col-12"
                                                        options={
                                                            values.clientId && values.clientId !== emptyObjectId
                                                                ? instruments
                                                                : [{ _id: emptyObjectId, name: "Select instrument", description: "" }]
                                                        }
                                                        disabled={
                                                            isSubmitting ||
                                                            isLocked ||
                                                            !values.clientId ||
                                                            values.clientId === emptyObjectId
                                                        }
                                                    />
                                                    <NumberField
                                                        name="amount"
                                                        label="Amount*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <SelectField
                                                        name="currency"
                                                        label="Currency*"
                                                        options={currencies}
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : null}
                                            {type === TransactionType.Transfer || type === "FxTransfer" ? (
                                                <Fragment>
                                                    <SearchListField
                                                        name="secondInstrumentId"
                                                        label={
                                                            values.secondInstrumentId in partyInstrumentsById ? (
                                                                <Link
                                                                    to={
                                                                        "/parties/" +
                                                                        values.clientId +
                                                                        "/instruments/" +
                                                                        values.secondInstrumentId
                                                                    }
                                                                >
                                                                    Second instr.*
                                                                </Link>
                                                            ) : (
                                                                "Second instr.*"
                                                            )
                                                        }
                                                        className="col-12"
                                                        options={
                                                            values.clientId && values.clientId !== emptyObjectId
                                                                ? instruments
                                                                : [
                                                                      {
                                                                          _id: emptyObjectId,
                                                                          name: "Select second instrument",
                                                                          description: ""
                                                                      }
                                                                  ]
                                                        }
                                                        disabled={
                                                            isSubmitting ||
                                                            isLocked ||
                                                            !values.clientId ||
                                                            values.clientId === emptyObjectId
                                                        }
                                                    />
                                                    <SelectField
                                                        name="secondAccountId"
                                                        label={
                                                            <Link
                                                                to={"/parties/" + values.clientId + "/accounts/" + values.secondAccountId}
                                                            >
                                                                {"Second account"}
                                                            </Link>
                                                        }
                                                        className="col-12"
                                                        options={accounts.map((d) => ({
                                                            key: d._id,
                                                            value: `${d.name} ${d.description}`
                                                        }))}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : null}
                                            {type === "FxTransfer" ? (
                                                <Fragment>
                                                    <NumberField
                                                        name="secondAmount"
                                                        label="Second amount*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting || isLocked}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <SelectField
                                                        name="secondCurrency"
                                                        label="Second currency*"
                                                        className="col-12 col-sm-6"
                                                        options={currencies}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                    <TransactionField
                                                        name="parentId"
                                                        label={
                                                            values.parentId && values.parentId !== emptyObjectId ? (
                                                                <Link to={"/transaction/" + values.parentId}>Parent transaction</Link>
                                                            ) : (
                                                                "Parent transaction"
                                                            )
                                                        }
                                                        className="col-12"
                                                        options={derivativeAndInsertDerivativeTransactions}
                                                        disabled={isSubmitting || isLocked}
                                                    />
                                                </Fragment>
                                            ) : null}
                                        </div>
                                        <div className="form-row">
                                            <TextField
                                                name="description"
                                                label="Description"
                                                type="text"
                                                className="col-12"
                                                disabled={
                                                    isSubmitting || values.revertTransaction || values.type === TransactionType.CreateRedeem
                                                }
                                            />
                                            {noExternalId || values.insert ? (
                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    className="col-12 col-sm-6"
                                                    options={Object.keys(TransactionStatus)}
                                                    disabled={isSubmitting || values.revertTransaction}
                                                />
                                            ) : null}
                                            {values._id && values.type !== TransactionType.CreateRedeem ? (
                                                <Fragment>
                                                    <TextField
                                                        name="error"
                                                        label="Error"
                                                        className="col-12"
                                                        disabled={isSubmitting || values.revertTransaction}
                                                    />
                                                    <TextField
                                                        name="brokerTradeId"
                                                        label={
                                                            values.corporateActionSwift && values.corporateActionSwift._id ? (
                                                                <Link
                                                                    to={
                                                                        "/swiftcorporateactions/corporateaction/" +
                                                                        values.corporateActionSwift._id
                                                                    }
                                                                >
                                                                    Corporate action id
                                                                </Link>
                                                            ) : values.brokerTransaction && values.brokerTransaction._id ? (
                                                                <Link
                                                                    to={
                                                                        "/brokerconfirmations/brokertransaction/" +
                                                                        values.brokerTransaction._id
                                                                    }
                                                                >
                                                                    Broker transaction id
                                                                </Link>
                                                            ) : (
                                                                "Broker trade id"
                                                            )
                                                        }
                                                        className="col-12"
                                                        disabled={isSubmitting || values.revertTransaction}
                                                    />
                                                    <TextField
                                                        name="uniqueTradeId"
                                                        label="UTI"
                                                        className="col-12"
                                                        disabled={isSubmitting || values.revertTransaction}
                                                    />
                                                </Fragment>
                                            ) : null}
                                            {type === TransactionType.DerivativeTrade && !values._id ? (
                                                <Fragment>
                                                    <TextField
                                                        name="uniqueTradeId"
                                                        label="UTI*"
                                                        type="text"
                                                        className="col-12"
                                                        disabled={isSubmitting}
                                                    />
                                                </Fragment>
                                            ) : null}
                                        </div>
                                        <div className="form-row">
                                            {!noExternalId && !values.insert ? (
                                                <Fragment>
                                                    <SelectField
                                                        name="status"
                                                        label="Status"
                                                        className="col-12 col-sm-6"
                                                        options={Object.keys(TransactionStatus)}
                                                        disabled={isSubmitting || values.revertTransaction}
                                                    />
                                                    <SelectField
                                                        name="source"
                                                        label={"Source" + (noExternalId ? "" : "*")}
                                                        options={sources}
                                                        className="col-12 col-sm-6"
                                                        disabled={values._id || noExternalId ? true : false}
                                                    />
                                                    <TextField
                                                        name="externalId"
                                                        label={"External id" + (noExternalId ? "" : "*")}
                                                        type="text"
                                                        className="col-12"
                                                        disabled={values._id || noExternalId ? true : false}
                                                    />
                                                </Fragment>
                                            ) : null}
                                            <TextField
                                                name="_id"
                                                label={
                                                    values._id ? (
                                                        <Link to={"/transaction/" + values._id} target="_blank">
                                                            Id
                                                        </Link>
                                                    ) : (
                                                        <div>Id</div>
                                                    )
                                                }
                                                type="text"
                                                className="col-12"
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                {values._id &&
                                                (values.type === TransactionType.Dividend || values.type === TransactionType.Interest) &&
                                                values.parentId &&
                                                values.parentId !== emptyObjectId ? (
                                                    <Link to={"/transaction/" + values.parentId} target="_blank">
                                                        Parent transaction
                                                    </Link>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            {(type === TransactionType.Dividend || type === TransactionType.Interest) && values._id ? (
                                                <CheckBoxField
                                                    name="revertTransaction"
                                                    label="Revert transaction and add new"
                                                    className="col-12"
                                                    disabled={isSubmitting}
                                                />
                                            ) : null}
                                        </div>
                                        {values.revertTransaction &&
                                        (type === TransactionType.Dividend || type === TransactionType.Interest) ? (
                                            <>
                                                <div className="form-row">
                                                    <div className="col">
                                                        <i style={{ color: "red" }}>{"NEW " + type.toUpperCase()}</i>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <DateField
                                                        name="newValueDate"
                                                        label="Value date*"
                                                        className="col-12"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="newCurrency"
                                                        label="Currency*"
                                                        options={currencies}
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting}
                                                    />
                                                    <NumberField
                                                        name="newAmount"
                                                        label="Amount*"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="newForeignTax"
                                                        label="Foreign tax"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting}
                                                        changeTolerancePercent={values && values._id ? defaultFatFingerTolerance : null}
                                                    />
                                                    <NumberField
                                                        name="newRounding"
                                                        label="Rounding"
                                                        className="col-12 col-sm-6"
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="newStatus"
                                                        label="Status"
                                                        className="col-12 col-sm-6"
                                                        options={Object.keys(TransactionStatus)}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                        {alert.visible ? (
                                            <section ref={alertRef} id="alertSection">
                                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                    {alert.message}
                                                </Alert>
                                            </section>
                                        ) : null}
                                        <SubmitButton
                                            disabled={isSubmitting || Object.keys(errors).length > 0}
                                            label={values._id && !values.revertTransaction ? "Save" : "Create"}
                                            className="btn btn-primary"
                                        />
                                    </Form>
                                </div>
                                {values._id && isValidMongoDBObjectID(values._id) && !values.revertTransaction ? (
                                    <div className="row mt-3">
                                        <AttachmentTransactionForm transactionId={values._id} />
                                    </div>
                                ) : null}
                            </Fragment>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export function NewMiniTransactionButton({ page, queryArgMode }: { page: string; queryArgMode?: boolean }): React.ReactElement {
    const navigate = useNavigate();
    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const createMiniTransactionTypes = cloneDeep(miniTransactionTypes);
    //CreateRedeem is only available for viewing + updating, not creating
    const index = createMiniTransactionTypes.indexOf("CreateRedeem", 0);
    createMiniTransactionTypes.splice(index, 1);

    if (queryArgMode) {
        return (
            <DropdownButton title="New" align="end" size="sm">
                {createMiniTransactionTypes.map((type, i) => (
                    <Dropdown.Item key={i} as="button" onClick={() => pushQueryArgs({ ...queryArgs, id: "new" + type.toLowerCase() })}>
                        {type}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    } else {
        return (
            <DropdownButton title="New" align="end" size="sm">
                {createMiniTransactionTypes.map((type, i) => (
                    <Dropdown.Item key={i} as="button" onClick={() => navigate(`/${page}/new` + type.toLowerCase() + location.search)}>
                        {type}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }
}

function CopyMiniTransactionButton({
    queryArgMode,
    type,
    values,
    copyValues
}: {
    queryArgMode?: boolean;
    type: TransactionType;
    values: MiniTransactionFormType;
    copyValues: Record<"current", MiniTransactionFormType>;
}): React.ReactElement {
    const navigate = useNavigate();
    const { queryArgs, pushQueryArgs } = useQueryArgs();

    const path = location.pathname.split("/");
    path.pop();

    path.push("new" + type.toLowerCase() + location.search);

    const newValues = cloneDeep(values);
    newValues._id = null;
    newValues.externalId = null;
    newValues.parentId = null;

    if (queryArgMode) {
        return (
            <Button
                className="mt-4 me-1 mb-2"
                variant="success"
                size="sm"
                onClick={() => {
                    copyValues.current = newValues;
                    pushQueryArgs({ ...queryArgs, id: "new" + type.toLowerCase() });
                }}
            >
                Copy
            </Button>
        );
    } else {
        return (
            <Button
                className="mt-4 me-1 mb-2"
                variant="success"
                size="sm"
                onClick={() => {
                    copyValues.current = newValues;
                    navigate(path.join("/"));
                }}
            >
                Copy
            </Button>
        );
    }
}
