import React, { Fragment, useState, useContext, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";

import { emptyObjectId, numberFormat } from "../../../common/src";
import { Grid, Column } from "../../../components/src";

import { Page } from "../components/Page";
import { useQueryState } from "../common/use-query-state";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext } from "../contexts/ClientContext";
import { PartyType } from "../types.generated";

const GET_LIMIT_REPORT = gql`
    query report($clientId: GraphQLObjectId!, $date: GraphQLDateString) {
        party(_id: $clientId) {
            name
        }

        LimitReportV3: reports(clientId: $clientId, type: "LimitReportV3", name: "LimitReportV3", date: $date, lastOnly: true) {
            _id
            date
            type
            createTimestamp
            data
        }

        VaRLimitReport: reports(clientId: $clientId, type: "LimitReportData", name: "VaRLimitReport", date: $date, lastOnly: true) {
            _id
            date
            type
            createTimestamp
            data
        }
    }
`;

const linkFormater = (link, item) => {
    return (
        <div>
            {link ? (
                <a href={link} rel="noopener noreferrer">
                    {item}
                </a>
            ) : null}
        </div>
    );
};

const statusFormatter = (status) => {
    let color;
    if (status === "Ok") {
        color = "success";
    } else if (status === "Warning") {
        color = "warning";
    } else {
        color = "danger";
    }
    return (
        <div>
            <Badge bg={color}>{status}</Badge>
        </div>
    );
};

const formatFunction = (d: any) =>
    typeof d === "number" ? numberFormat(Number(d), Math.abs(d) > 0.995 ? "0%" : "0,0%") : typeof d === "string" ? d : "";

const parseData = (report, varReport, party) => {
    const rows = [];

    report.data.forEach((row) => {
        rows.push({ ...row.limit, ...row.result });
    });

    if (varReport) {
        if (report.date === varReport.date) {
            if (Array.isArray(varReport.data) && varReport.data.length) {
                const row = {
                    name: "Value at Risk",
                    type: "VaR",
                    value: varReport.data[0].vaR,
                    url: "https://en.wikipedia.org/wiki/Value_at_risk",
                    comment: "VaR",
                    maxValue: 0.03
                };
                // Special case for Iris
                if (varReport.data.length === 2 && party.name === "Captor Iris Bond") {
                    row.name = "Value at Risk vs comparison index";
                    row.type = "Relative VaR";
                    row.value = varReport.data[0].vaR / varReport.data[1].vaR;
                    row.maxValue = 1.3;
                    row.url = "https://captor.se/wp-content/uploads/2019/01/Captor-Iris-Bond-Fondbest%C3%A4mmelser-20190201.pdf";
                    row.comment = "Fund Specific";
                }
                // Special case for Asters
                if (
                    varReport.data.length === 1 &&
                    (party.name === "Captor Aster Global Credit" ||
                        party.name === "Captor Aster Global Credit Short Term" ||
                        party.name === "Captor Aster Global High Yield" ||
                        party.name === "Captor Global Fixed Income" ||
                        party.name === "Captor Perenne Short Term Bond")
                ) {
                    row.value = Math.abs(varReport.data[0].vaR / varReport.data[0].thVal);
                }
                // Max var different for Bliwa
                if (party.name === "Bliwa Liv") row.maxValue = 0.09;
                if (party.name === "Bliwa Skade") row.maxValue = 0.07;
                rows.push(row);
            }
        }
    }

    return {
        date: report.date,
        name: report.name,
        rows: rows
    };
};

export const Limits = ({ clientId }: { clientId: string }): React.ReactElement => {
    const [endDate, setDateQueryArg] = useQueryState("endDate", "2000-01-01");
    const date = endDate === "2000-01-01" ? null : endDate;

    const { tabId } = useParams<"tabId">();
    const { pathname } = useLocation();

    const [variables, setVariables] = useState<{ clientId: string; date: string }>(null);

    const [{ fetching, error, data }] = useQuery({
        query: GET_LIMIT_REPORT,
        requestPolicy: "cache-and-network",
        variables: variables,
        pause: variables ? false : true
    });

    useEffect(() => {
        if (stableStringify({ clientId, date }) !== stableStringify(variables)) {
            setVariables({ clientId, date });
        }
    }, [clientId, date, variables]);

    useEffect(() => {
        if (!date && data && "LimitReportV3" in data && data.LimitReportV3.length > 0) {
            //Only set query args in relevant tab or if on limit page
            if (
                date !== data.LimitReportV3[0].date &&
                ((tabId && tabId === "limits" && pathname.includes("portfoliorisk")) || !pathname.includes("portfoliorisk"))
            ) {
                setDateQueryArg(data.LimitReportV3[0].date);
            }
        }
    }, [data, date, pathname, setDateQueryArg, tabId]);

    if (fetching) return <div>Loading...</div>;
    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!data) {
        return <div></div>;
    }

    if (data.LimitReportV3.length === 0) {
        if (variables && variables.clientId && variables.clientId !== emptyObjectId) {
            if (data.LimitReportV3.length === 0) {
                return <Page header="Could not find a report" />;
            }
        } else {
            return <Page header="" />;
        }
    }

    const defaultReportData = {
        name: "",
        header: "",
        minValueWarningExist: false,
        maxValueWarningExist: false,
        rows: []
    };

    const report = data.LimitReportV3[0];
    let varLimitReport = null;
    if (data.VaRLimitReport && data.VaRLimitReport.length > 0) {
        varLimitReport = data.VaRLimitReport[0];
    }

    const limitReportInfo = cloneDeep(defaultReportData);

    const { name, rows } = parseData(report, varLimitReport, data.party);
    limitReportInfo.name = name;
    limitReportInfo.rows = rows;
    limitReportInfo.header = "Limit report for " + data.party.name;

    // loop limits to set format
    for (const row of rows) {
        if (row.minValueWarning) limitReportInfo.minValueWarningExist = true;
        if (row.maxValueWarning) limitReportInfo.maxValueWarningExist = true;
        // not bulletproof but for now
        if (row.valueField === "interestRateYieldDelta") {
            if (row.value) row.value = numberFormat(Number(row.value), "#\xa0##0.0");
            if (row.minValueWarning) row.minValueWarning = numberFormat(Number(row.minValueWarning), "#\xa0##0.0");
            if (row.maxValueWarning) row.maxValueWarning = numberFormat(Number(row.maxValueWarning), "#\xa0##0.0");
            if (row.minValue) row.minValue = numberFormat(Number(row.minValue), "#\xa0##0.0");
            if (row.maxValue) row.maxValue = numberFormat(Number(row.maxValue), "#\xa0##0.0");
        }
    }

    return (
        <Page header={limitReportInfo.name}>
            <Fragment>
                <Link to={"/report/" + data.LimitReportV3[0]._id}>
                    <div>Date: {data.LimitReportV3[0].date}</div>
                </Link>
                <br />
                <div className="card card-body bg-light">
                    <div className="bg-white px-3 py-2">
                        {limitReportInfo.rows && (
                            <Grid header={limitReportInfo.header} data={limitReportInfo.rows}>
                                <Column field="name" className="grid-column-sticky" />
                                <Column
                                    field="status"
                                    format={(status) => {
                                        return statusFormatter(status);
                                    }}
                                />
                                {limitReportInfo.minValueWarningExist ? (
                                    <Column field="minValueWarning" format={formatFunction} className="nowrap center" />
                                ) : null}
                                <Column field="minValue" format={formatFunction} className="nowrap center" />
                                <Column field="value" format={formatFunction} className="nowrap center" />
                                <Column field="maxValue" format={formatFunction} className="nowrap center" />
                                {limitReportInfo.maxValueWarningExist ? (
                                    <Column field="maxValueWarning" format={formatFunction} className="nowrap center" />
                                ) : null}
                                <Column
                                    className="nowrap"
                                    field="comment"
                                    format={(comment, item) => {
                                        return linkFormater(item.url, comment);
                                    }}
                                />
                            </Grid>
                        )}
                    </div>
                </div>
            </Fragment>
        </Page>
    );
};

export const LimitV3Page = (): React.ReactElement => {
    const { client } = useContext(ClientContext);

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <ClientContextSelector typeIn={[PartyType.Client]} />
                </div>
            </div>
            {client ? <Limits clientId={client._id} /> : null}
        </div>
    );
};
