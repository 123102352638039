import { round, cloneDeep } from "lodash";

import { exportToXlsx, sheetType } from "../common/exportToXlsx";
import { AgreementType, InstrumentProductTypeEnum, Party } from "../types.generated";

interface FormattedPosition {
    Instrumenttyp: string;
    Marknad: string;
    NamnPåInstrument: string;
    EmmitentNamn: string;
    EmmitentLEI: string;
    ISIN: string;
    Landkod: string;
    Valuta: string;
    Antal: number;
    NominelltBelopp: string | number;
    Kurs: number;
    Valutakurs: number;
    Marknadsvärde: number;
    UpplupenRäntaVärde: string | number;
    Värdepapperslån: string | number;
    AndelFondförmögenhet: string | number;
    Röstvärde: string | number;
    Ägarandel: string | number;
    Branschkod: number;
    BenämningPåBransch: string;
    Koncerntillhörighet: string;
    OTC: string;
    OptionsPremie: string | number;
    UAndelAvFondförmögenhet: string | number;
    UVärde: string | number;
    UEmittent: string;
    UISIN: string;
    UBranschkod: string | number;
    UBenämningPåBransch: string;
    U2Emittent: string;
    U2ISIN: string;
    U2Branschkod: string;
    U2BenämningPåBransch: string;
    Emission: string;
    EmittentOrganisationsnr: string;
    Emittentsektor: string;
    Emissionsdatum: string;
    Förfallodatum: string;
    Säkerhetstyp: string;
    SenasteSplitfaktor: any;
    SenasteSplitdatum: string;
    SenastUtdelatBelopp: any;
    SenasteUtdelningstyp: string;
    SenasteUtdelningsdatum: string;
    SenasteUtdelningsvaluta: string;
    TypAvIdentifikator: string;
    IdentifikatorKod: string;
}

interface GeneralInformation {
    Likvida_medel: number;
    Övriga_tillgångar_skulder: number;
    Fondförmögenhet: number;
}

interface BankAccount {
    Namn: string;
    Värde: number;
    Valuta: string;
    Valutakurs: number;
    Marknadsvärde: number;
    Andel_fondförmögenhet: number;
}

/*interface OTC {
    Namn: string;
    Värde: number;
    Andel_fondförmögenhet: number;
}*/

export function downloadReport(
    positionsData: any[],
    bankAccountsData: any[],
    positionsGroupedByExternalAccount: any[],
    portfolioValue: number,
    client: Party,
    date: string,
    types: { [key: string]: { instrumentType: string; market: string } },
    otcTypes: { [key: string]: boolean }
) {
    console.log("Download");
    const otcTypesExtended = cloneDeep(otcTypes);
    //otcTypesExtended[InstrumentModelTypeEnum.Bond] = true;
    //otcTypesExtended[InstrumentModelTypeEnum.Stock] = true;

    // Used to filter out zero positions (booked in different accounts)
    const clientPositions: any = {};
    for (const pos of positionsData) {
        if (pos.instrumentId in clientPositions) {
            clientPositions[pos.instrumentId] += pos.quantity;
        } else {
            clientPositions[pos.instrumentId] = pos.quantity;
        }
    }

    // Sorting positions by report standard
    const sortByType = { Stock: 6, Bond: 5, PortfolioSwap: 4, Swap: 3, Swaption: 2, FxSwap: 1, CdsIndex: 0 };
    positionsData.sort((a, b) => (sortByType[a.modelType] > sortByType[b.modelType] ? -1 : 1));

    const positionsReportFormat: any[] = [];

    const otcSum = {};

    const validAgreementTypes = Object.values(AgreementType).sort();

    const bicsToGics = {
        "10": "50",
        "11": "25",
        "12": "30",
        "13": "10",
        "14": "40",
        "15": "60",
        "16": "35",
        "17": "20",
        "18": "15",
        "19": "45",
        "20": "55",
        "30": "15",
        "31": "50",
        "32": "25",
        "33": "30",
        "34": "10",
        "35": "40",
        "36": "35",
        "37": "20",
        "38": "45",
        "39": "55",
        "0": "0"
    };

    // Using positions to generate report format
    positionsData.forEach(function (position) {
        if (Math.abs(clientPositions[position.instrumentId]) > 10e-7) {
            //calculate "Andel av fondförmögenhet"
            const share = round((position.exposure * 100) / portfolioValue, 2);

            // Adding issuerName + issuerLei for otc derivatives + bonds + stocks posted as securities (custodian of externalAccounts)
            if (otcTypesExtended[position.modelType]) {
                for (const p in positionsGroupedByExternalAccount) {
                    const groupedPosition = positionsGroupedByExternalAccount[p];
                    if (
                        groupedPosition.instrumentId.toString() === position.instrumentId.toString() &&
                        AgreementType[groupedPosition.externalAccountType]
                    ) {
                        position["issuerName"] =
                            groupedPosition.externalAccount &&
                            groupedPosition.externalAccount.custodian &&
                            groupedPosition.externalAccount.custodian.longName
                                ? groupedPosition.externalAccount.custodian.longName
                                : groupedPosition.externalAccount &&
                                  groupedPosition.externalAccount.custodian &&
                                  groupedPosition.externalAccount.custodian.name
                                ? groupedPosition.externalAccount.custodian.name
                                : "";
                        position["issuerLei"] =
                            groupedPosition.externalAccount &&
                            groupedPosition.externalAccount.custodian &&
                            groupedPosition.externalAccount.custodian.legalEntityIdentifier
                                ? groupedPosition.externalAccount.custodian.legalEntityIdentifier
                                : "";
                        position["externalAccountType"] = groupedPosition.externalAccountType;
                        position["externalAccount"] = groupedPosition.externalAccount ? groupedPosition.externalAccount : null;
                    }
                }
            }

            let reportType = position.modelType;
            if (reportType === "Bond" && position.issuerType === "EuCovered") {
                reportType = "BondEuCovered";
            } else if (reportType === "Bond" && position.issuerType === "Government") {
                reportType = "BondGovernment";
            }

            const bicsCode: number = position.issuerIndustryCode ? parseFloat(position.issuerIndustryCode.substring(0, 2)) : 0;
            const formattedPosition: FormattedPosition = {
                Instrumenttyp: types[reportType] ? types[reportType].instrumentType : "",
                Marknad: types[reportType] ? types[reportType].market : "",
                NamnPåInstrument: position.longName
                    ? position.longName.replace("&", "&amp;").replace(",", "")
                    : position.name
                    ? position.name.replace("&", "&amp;").replace(",", "")
                    : "",
                EmmitentNamn: position.issuerName ? position.issuerName.replace("&", "&amp;").replace(",", "") : "",
                EmmitentLEI: position.issuerLei ? position.issuerLei.replace("NO_LEI_", "").replace("-BRANCH", "") : "",
                ISIN: position.isin,
                Landkod: position.countryOfListing,
                Valuta: position.currency,
                Antal: position.quantity,
                NominelltBelopp: "",
                Kurs: round(position.valuationPrice, 2),
                Valutakurs: round(position.fxRate, 4),
                Marknadsvärde: round(position.exposure, 0),
                UpplupenRäntaVärde:
                    round(position.valuationAccruedInterest * position.quantity, 0) !== 0
                        ? round(position.valuationAccruedInterest * position.quantity, 0)
                        : 0,
                Värdepapperslån: "",
                AndelFondförmögenhet: share,
                Röstvärde: "",
                Ägarandel: "",
                Branschkod: bicsToGics[bicsCode],
                BenämningPåBransch: "",
                Koncerntillhörighet: "",
                OTC: otcTypes[position.modelType] ? "TRUE" : "",
                OptionsPremie: "",
                UAndelAvFondförmögenhet: otcTypes[position.modelType] ? share : "",
                UVärde: otcTypes[position.modelType] ? round(position.exposure, 0) : "",
                UEmittent:
                    otcTypes[position.modelType] && position.issuerName ? position.issuerName.replace("&", "&amp;").replace(",", "") : "",
                UISIN: otcTypes[position.modelType] ? position.isin : "",
                UBranschkod: otcTypes[position.modelType] ? bicsToGics[bicsCode] : "",
                UBenämningPåBransch: "",
                U2Emittent: "",
                U2ISIN: "",
                U2Branschkod: "",
                U2BenämningPåBransch: "",
                Emission: "",
                EmittentOrganisationsnr: "",
                Emittentsektor: "",
                Emissionsdatum: "",
                Förfallodatum: "",
                Säkerhetstyp: "",
                SenasteSplitfaktor: "",
                SenasteSplitdatum: "",
                SenastUtdelatBelopp: "",
                SenasteUtdelningstyp: "",
                SenasteUtdelningsdatum: "",
                SenasteUtdelningsvaluta: "",
                TypAvIdentifikator: "",
                IdentifikatorKod: ""
            };
            // Do not want to include positions which are internal in the fund, custodianId in this case is the same as client

            positionsReportFormat.push(formattedPosition);
        }
    });

    // Generating information to manually put into reporting system
    let totalCash = 0;
    let otherCash = 0;
    const bankAccountList = [];
    let bankAccount: BankAccount;

    bankAccountsData.forEach(function (cashAccount) {
        if (cashAccount.productType === InstrumentProductTypeEnum.CashAccount) {
            totalCash += cashAccount.exposure;
            bankAccount = {
                Namn: cashAccount.name,
                Värde: round(cashAccount.localExposure, 0),
                Valuta: cashAccount.currency,
                Valutakurs: round(cashAccount.fxRate, 4),
                Marknadsvärde: round(cashAccount.exposure, 0),
                Andel_fondförmögenhet: round((cashAccount.exposure * 100) / portfolioValue, 2)
            };
            bankAccountList.push(bankAccount);
        } else {
            otherCash += cashAccount.exposure;
        }
    });

    // Summation for each otc counterparty, NOT clearingAccount
    for (const p in positionsData) {
        const groupedPosition = positionsData[p];
        const name =
            groupedPosition.externalAccount && groupedPosition.externalAccount.custodian && groupedPosition.externalAccount.custodian.longName
                ? groupedPosition.externalAccount.custodian.longName
                : groupedPosition.externalAccount &&
                  groupedPosition.externalAccount.custodian &&
                  groupedPosition.externalAccount.custodian.name
                ? groupedPosition.externalAccount.custodian.name
                : "";
        if (
            validAgreementTypes.includes(groupedPosition["externalAccountType"]) &&
            groupedPosition["externalAccountType"] !== AgreementType.ClearingAccount &&
            groupedPosition["externalAccountType"] !== AgreementType.OmnibusClearingAccount
        ) {
            if (!otcSum[name]) {
                otcSum[name] = {
                    Namn: name,
                    Värde: round(groupedPosition.exposure, 0)
                };
            } else {
                otcSum[name].Värde += round(groupedPosition.exposure, 0);
            }
        }
    }

    // If total value <0 it should be set to 0
    for (const nettingSet in otcSum) {
        if (otcSum[nettingSet].Värde < 0) {
            otcSum[nettingSet].Värde = 0;
        }
    }

    const generalInformation: GeneralInformation[] = [
        { Likvida_medel: round(totalCash, 0), Övriga_tillgångar_skulder: round(otherCash, 0), Fondförmögenhet: round(portfolioValue, 0) }
    ];

    const otcListSum: any[] = [];

    for (const [_key, value] of Object.entries(otcSum)) {
        const share = round((value["Värde"] * 100) / portfolioValue, 2);
        value["Andel_fondförmögenhet"] = share;
        otcListSum.push(value);
    }

    const reportInformation: sheetType[] = [generalInformation, bankAccountList, otcListSum];

    let clientReportName = client.name.split(" ")[1];
    if (client.name.includes("Scilla Global")) {
        clientReportName = clientReportName + "G";
    }

    const reportData: [sheetType] = [positionsReportFormat];

    exportToXlsx(reportData, clientReportName + "_" + date + ".xlsx", [clientReportName + "_" + date]);
    exportToXlsx(reportInformation, "Info_" + clientReportName + "_" + date + ".xlsx", ["Allmän info", "Likvida medel", "OTC"]);
}
