import React from "react";

import { numberFormat } from "../../../common/src";

import { FactTable } from "../FactTable";

export function ComponentFactFundInfo({ data, className }: { data: any; className: string }): React.ReactElement {
    let fundInfos = typeof data.classes === "undefined" ? data : data.classes;
    fundInfos.sort((d1, d2) => (d1.name < d2.name ? -1 : d1.name === d2.name ? 0 : 1));
    fundInfos = fundInfos.filter((d) => d.enabled === true);
    if (fundInfos.length > 0) {
        const fundInfo = [
            { key: "", values: fundInfos.map((d) => `Klass${d.shareClass ? " " + d.shareClass : ""}`) },
            { key: "NAV", values: fundInfos.map((d) => d.navPerUnit) },
            { key: "Datum för NAV", values: fundInfos.map(() => data.navDate) },
            { key: "Utdelande", values: fundInfos.map((d) => (d.dividendPaying ? "Ja" : "Nej")) },
            { key: "Kortnamn Bloomberg", values: fundInfos.map((d) => d.bloombergTicker) },
            { key: "ISIN", values: fundInfos.map((d) => d.isin) },
            { key: "Handel", values: fundInfos.map(() => data.tradeFrequency) },
            { key: "Bryttid", values: fundInfos.map((d) => d.tradeCutOff) },
            { key: "Minsta investering", values: fundInfos.map((d) => numberFormat(d.minimumInitialSubscription, "# ##0")) },
            { key: "Förvaltningsavgift", values: fundInfos.map((d) => numberFormat(d.managementFee, "0,000%").replace(/0{1,2}%$/, "%")) },
            { key: "Valuta", values: fundInfos.map((d) => d.navCurrency) },
            { key: "Fondtyp", values: fundInfos.map(() => data.fundType) },
            {
                key: "Valutasäkrad",
                values: fundInfos.map((d) => {
                    if (d.fxHedged) {
                        return "Ja";
                    } else {
                        return "Nej";
                    }
                })
            },
            { key: "SFDR", values: fundInfos.map((d) => d.sfdr) }
        ];

        return <FactTable className={"firstheader" + (className ? " " + className : "")} list={fundInfo} />;
    } else {
        return null;
    }
}
