import React, { Fragment, useState, useEffect, useMemo } from "react";
import { gql, useQuery, useMutation } from "urql";
import save from "save-file";
import { useParams, useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";
import { Link } from "react-router-dom";

import { Svgs } from "../../../../components/src";

import { TAccountChartForm } from "../../components/TAccountChartForm";
import { ReactTable } from "../../components/react-table/ReactTable";
import { ClientSelector } from "./ClientDateSelector";
import { InstrumentTypeEnum, Party, PermissionAssetEnum, PermissionTypeEnum } from "../../types.generated";
import { SYSTEM_PARTY_ID } from "../../Constants";
import { usePrevious } from "../../common/Utils";
import { getTAccountChart, getAllTAccountCharts, getClients } from "./queries";
import { userHaveAccessTo } from "../../common/Permissions";

const GET_FORM_DATA = gql`
    query getFormData($filter: InstrumentFilterInput) {
        instruments(filter: $filter) {
            _id
            isin
            name
            longName
            bloombergTicker
            currency
        }
    }
`;

const UPDATE_T_ACCOUNTS_CHART = gql`
    mutation UpdateTAccountChart($input: UpdateTAccountChartInput) {
        updateTAccountChart(input: $input) {
            _id
        }
    }
`;

const IMPORT_TACCOUNTS = gql`
    mutation importTAccountChartAccounts($id: GraphQLObjectId!, $csv: String!) {
        importTAccountChartAccounts(_id: $id, csv: $csv) {
            _id
        }
    }
`;

const IMPORT_MAPPINGS = gql`
    mutation importTAccountChartMappings($id: GraphQLObjectId!, $csv: String!) {
        importTAccountChartMappings(_id: $id, csv: $csv) {
            _id
        }
    }
`;

const EXPORT_TACCOUNTS = gql`
    query tAccountChartAccountsExport($id: GraphQLObjectId!) {
        tAccountChartAccountsExport(_id: $id)
    }
`;

const EXPORT_MAPPINGS = gql`
    query tAccountChartMappingsExport($id: GraphQLObjectId!) {
        tAccountChartMappingsExport(_id: $id)
    }
`;

const CREATE_TACCOUNTCHART = gql`
    mutation createTAccountChart($input: CreateTAccountChartInput) {
        createTAccountChart(input: $input) {
            _id
            name
            version
            client {
                _id
                name
            }
            locked
        }
    }
`;

const CLONE_TACCOUNTCHART = gql`
    mutation cloneTAccountChart($name: String, $clientId: GraphQLObjectId!) {
        cloneTAccountChart(name: $name, clientId: $clientId) {
            _id
            name
            version
            client {
                _id
                name
            }
            locked
        }
    }
`;

const Checkbox = (props) => <input type="checkbox" {...props} />;

export function TAccountChartPage(): React.ReactElement {
    const { id, tAccountIndex: tAccountIndexString, tAccountMappingIndex: tAccountMappingIndexString }: any = useParams();
    const navigate = useNavigate();

    const [{ fetching: loadingInstruments, error: errorInstruments, data: dataInstruments }] = useQuery({
        query: GET_FORM_DATA,
        variables: { filter: { typeIn: [InstrumentTypeEnum.Instrument] } }
    });

    const [{ fetching: loadingTAccountChart, error: errorTAccountChart, data: dataTAccountChart }] = useQuery({
        query: getTAccountChart,
        variables: { id },
        pause: !id
    });

    const [{ fetching: loadingTAccountCharts, error: errorTAccountCharts, data: dataTAccountCharts }] = useQuery({
        query: getAllTAccountCharts,
        pause: id
    });

    // Fetch clients, dropdown for creating new tAChart
    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: getClients
    });

    const [_, updateTAccountChart] = useMutation(UPDATE_T_ACCOUNTS_CHART);
    const [__, importTAccountChartAccounts] = useMutation(IMPORT_TACCOUNTS);
    const [___, importTAccountChartMappings] = useMutation(IMPORT_MAPPINGS);
    const [____, createTAccountChart] = useMutation(CREATE_TACCOUNTCHART);
    const [_____, cloneTAccountChart] = useMutation(CLONE_TACCOUNTCHART);

    const [exportMappingsVariables, setExportMappingsVariables] = useState(null);

    const [{ fetching: _fetchingExportMappings, error: _errorExportMappings, data: dataExportMappings }] = useQuery({
        query: EXPORT_MAPPINGS,
        requestPolicy: "network-only",
        variables: exportMappingsVariables,
        pause: exportMappingsVariables ? false : true
    });
    const previousDataExportMappings = usePrevious(dataExportMappings);

    const [exportTAccountsVariables, setExportTAccountsVariables] = useState(null);
    const [{ fetching: _fetchingExportTAccounts, error: _errorExportTAccounts, data: dataExportTAccounts }] = useQuery({
        query: EXPORT_TACCOUNTS,
        requestPolicy: "network-only",
        variables: exportTAccountsVariables,
        pause: exportTAccountsVariables ? false : true
    });
    const previousDataExportTAccounts = usePrevious(dataExportTAccounts);

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Link to={`/taccountcharts/${row.original._id}`}>#</Link>
                        </div>
                    );
                },
                size: 0 // 'auto'
            },
            {
                header: "Type",
                accessorKey: "type",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const v = !row.original.client ? "Master" : "Client";
                    return <Link to={`/taccountcharts/${row.original._id}`}>{v}</Link>;
                },
                size: 0
            },
            {
                header: "Name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.client._id}`}>{row.original.client.name}</Link>;
                }
            },
            {
                header: "Version",
                accessorKey: "version",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            {" "}
                            <Link to={`/taccountcharts/${row.original._id}`}>{row.original.version}</Link>
                        </div>
                    );
                },
                size: 0
            },
            {
                header: "Locked",
                accessorKey: "locked",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Checkbox checked={row.original.locked} readOnly={true} />
                        </div>
                    );
                },
                size: 0
            }
        ],
        []
    );

    useEffect(() => {
        if (dataExportMappings && stableStringify(dataExportMappings) !== stableStringify(previousDataExportMappings)) {
            const dataURI = "data:text/csv;charset=utf-8;base64," + Base64.encode(dataExportMappings.tAccountChartMappingsExport);
            save(dataURI, `export-${id}-mappings.csv`).then(() => {
                setExportMappingsVariables(null);
            });
        }
    }, [dataExportMappings, id, previousDataExportMappings]);

    useEffect(() => {
        if (dataExportTAccounts && stableStringify(dataExportTAccounts) !== stableStringify(previousDataExportTAccounts)) {
            const dataURI = "data:text/csv;charset=utf-8;base64," + Base64.encode(dataExportTAccounts.tAccountChartAccountsExport);
            save(dataURI, `export-${id}-taccounts.csv`).then(() => {
                setExportTAccountsVariables(null);
            });
        }
    }, [dataExportTAccounts, id, previousDataExportTAccounts]);

    // loading
    if (loadingInstruments || loadingTAccountChart || loadingTAccountCharts || loadingClients) {
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );
    }

    // error
    if (errorInstruments || errorTAccountChart || errorTAccountCharts || errorClients) {
        return (
            <div className="loader">
                <h3>
                    Failed loading data:{" "}
                    {errorInstruments
                        ? errorInstruments.message
                        : errorTAccountChart
                          ? errorTAccountChart.message
                          : errorTAccountCharts.message
                            ? errorClients.message
                            : errorClients.message}
                </h3>
            </div>
        );
    }

    const tAccountIndex = typeof tAccountIndexString === "undefined" ? -1 : Number(tAccountIndexString);

    const tAccountMappingIndex = typeof tAccountMappingIndexString === "undefined" ? -1 : Number(tAccountMappingIndexString);

    // instruments
    let { instruments } = dataInstruments;

    // tAccountCharts
    const tAccountCharts = dataTAccountCharts ? cloneDeep(dataTAccountCharts.tAccountCharts) : null;
    if (tAccountCharts) {
        tAccountCharts.sort((d1, d2) => {
            let c = d1.client && !d2.client ? 1 : !d1.client && d2.client ? -1 : 0;
            if (c !== 0) {
                return c;
            }
            const n1 = d1.client ? d1.client.name : d1.name;
            const n2 = d2.client ? d2.client.name : d2.name;
            c = n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
            if (c !== 0) {
                return c;
            }
            return d2.version - d1.version;
        });
    }

    const recentTAccountChartsByName = {};
    for (const t in tAccountCharts) {
        const tAChart = tAccountCharts[t];
        if (tAChart.client.name && !recentTAccountChartsByName[tAChart.client.name]) {
            recentTAccountChartsByName[tAChart.client.name] = tAChart;
        } else if (
            tAChart.client.name &&
            recentTAccountChartsByName[tAChart.client.name] &&
            recentTAccountChartsByName[tAChart.client.name].version < tAChart.version
        ) {
            recentTAccountChartsByName[tAChart.client.name] = tAChart;
        }
    }

    // tAccountChart
    const tAccountChart = dataTAccountChart ? cloneDeep(dataTAccountChart.tAccountChart) : null;
    if (tAccountChart) {
        if (tAccountChart.client && tAccountChart.client.instruments) {
            instruments = instruments.slice();
            // eslint-disable-next-line prefer-spread
            instruments.push.apply(instruments, tAccountChart.client.instruments);
        }
        delete tAccountChart.__typename;
        tAccountChart.tAccounts.forEach((a) => {
            delete a.__typename;
        });
        tAccountChart.tAccountMappings.forEach((m) => {
            delete m.__typename;
            delete m.selector.__typename;
            m.values.forEach((v) => {
                delete v.__typename;
            });
        });
    }

    const accessClients: Party[] = [];
    if (dataClients) {
        //Check access client combined with asset accounting
        if (dataClients.me && dataClients.me.roles) {
            let access = false;
            for (const client of dataClients.clients) {
                if (dataClients.me.frontendRole && dataClients.me.frontendRole.assets.length === 0) {
                    access = true;
                } else {
                    for (const role of dataClients.me.roles) {
                        if (role.assets.includes(PermissionAssetEnum.Accounting) && role.clientIds.includes(client._id)) {
                            access = true;
                        }
                    }
                }
                // Exclude system party from client selection menu
                if (access && client._id !== SYSTEM_PARTY_ID) {
                    accessClients.push(client);
                }
            }
        }
    }

    const accountingReadWriteAccess =
        dataClients &&
        dataClients.me &&
        dataClients.me.roles &&
        userHaveAccessTo("Any", PermissionAssetEnum.Accounting, dataClients.me.roles, PermissionTypeEnum.ReadWrite)
            ? true
            : false;

    return (
        <Fragment>
            {tAccountChart && id ? (
                <TAccountChartForm
                    id={id}
                    tAccountChart={tAccountChart}
                    tAccountIndex={tAccountIndex}
                    tAccountMappingIndex={tAccountMappingIndex}
                    instruments={instruments}
                    accountingReadWriteAccess={accountingReadWriteAccess}
                    onSave={(value: any) => {
                        const input = cloneDeep(value);
                        delete input.client;
                        delete input.locked;
                        delete input.__typename;
                        input.tAccounts.forEach((a) => {
                            delete a.__typename;
                        });
                        input.tAccountMappings.forEach((m) => {
                            delete m.__typename;
                            delete m.selector.__typename;
                            m.values.forEach((v) => {
                                delete v.__typename;
                            });
                            if (m.selector && m.selector.instruments && m.selector.instruments.length) {
                                m.selector.instruments.forEach((instrument) => {
                                    delete instrument.__typename;
                                });
                            }
                        });
                        updateTAccountChart({ input });
                        navigate(`/taccountcharts/${id}`, { replace: true });
                    }}
                    onImport={async (args) => {
                        if (args.kind === "taccounts") {
                            await importTAccountChartAccounts({ id: tAccountChart._id, csv: args.import }).then(() => {
                                window.location.reload();
                            });
                        } else if (args.kind === "mappings") {
                            await importTAccountChartMappings({ id: tAccountChart._id, csv: args.import }).then(() => {
                                window.location.reload();
                            });
                        }
                    }}
                    onExport={(value) => {
                        if (value.kind === "taccounts") {
                            setExportTAccountsVariables({ id: value.id });
                        } else if (value.kind === "mappings") {
                            setExportMappingsVariables({ id: value.id });
                        }
                    }}
                />
            ) : (
                <div>
                    <div className="row">
                        <div className="col-6">
                            <h4>T-account charts</h4>
                        </div>
                        {accountingReadWriteAccess ? (
                            <div className="col-6">
                                <h4>Create new t-account chart</h4>
                            </div>
                        ) : null}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <ReactTable columns={columns} data={tAccountCharts ? tAccountCharts : []} />
                        </div>
                        {accountingReadWriteAccess ? (
                            <div className="col-6">
                                <ClientSelector
                                    id={id}
                                    endDate={null}
                                    clients={accessClients}
                                    buttonText={"Client"}
                                    page={"taccountcharts"}
                                    createTAccountChart={createTAccountChart}
                                    cloneTAccountChart={cloneTAccountChart}
                                    recentTAccountCharts={recentTAccountChartsByName}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </Fragment>
    );
}
