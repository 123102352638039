import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);

interface ISectorSpreadProps {
    data: any;
    locale: Language;
    cdsKey: string;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const SectorSpread = ({ data, locale, config, layout, cdsKey, ...props }: ISectorSpreadProps): React.ReactElement => {
    let header: string = null;
    let ytext: string = null;
    if (locale === "En") {
        header = "Sector Spreads";
        ytext = "Basis points";
    } else {
        if (locale === "Sv") {
            header = "Spread per sektor";
            ytext = "Räntepunkter";
        }
    }
    const trace1: Partial<Plotly.PlotData> = {
        x: data.data.cdsData[cdsKey].spreads.map((d) => d.sector),
        labels: data.data.cdsData[cdsKey].spreads.map((d) => d.sector),
        y: data.data.cdsData[cdsKey].spreads.map((d) => d.value.toFixed(2)),
        type: "bar",
        line: { width: 0 },
        marker: { color: PlotlyDefaults.getColor(1) }
    };

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        xaxis: {
            visible: true,
            showgrid: false
        },
        yaxis: {
            tickformat: ".0f",
            hoverformat: ".0f",
            tick0: 0,
            gridwidth: 2,
            title: { text: ytext, font: { size: 12 }, standoff: 10 }
        },
        margin: { l: 44, r: 50, t: 55, b: 100 },
        separators: ", ",
        showlegend: false,
        bargap: 0.5
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            <h3>{header}</h3>
            <Plot data={[trace1]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
