/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAgreementsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AgreementFilterInput>;
}>;


export type GetAgreementsQuery = { __typename?: 'Query', agreements?: Array<{ __typename?: 'Agreement', _id: string, clientId: string, counterPartyIds: Array<string>, createTimestamp: any, description: string, partyIds: Array<string>, status: Types.AgreementStatusEnum, type: Types.AgreementTypeEnum, updateTimestamp: any, updateUserId: string, attachments: Array<{ __typename?: 'Attachment', fileId: string, clientId?: string | null, fileName?: string | null, mimeType?: string | null, mD5?: string | null, updateTimestamp?: any | null } | null>, client: { __typename?: 'PartyInfo', _id: string, name: string }, counterParties: Array<{ __typename?: 'PartyInfo', _id: string, name: string }>, parties?: Array<{ __typename?: 'PartyInfo', _id: string, name: string } | null> | null, approvedInfo?: Array<{ __typename?: 'ApprovedInfo', status: Types.ApprovedStatusEnum, comment?: string | null, updateUserId: string, updateTimestamp: any, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }> | null, updateUserInfo: { __typename?: 'UserInfo', name: string } } | null> | null };


export const GetAgreementsDocument = gql`
    query GetAgreements($filter: AgreementFilterInput) {
  agreements(filter: $filter) {
    _id
    attachments {
      fileId
      clientId
      fileName
      mimeType
      mD5
      updateTimestamp
    }
    client {
      _id
      name
    }
    clientId
    counterParties {
      _id
      name
    }
    counterPartyIds
    createTimestamp
    description
    parties {
      _id
      name
    }
    partyIds
    status
    type
    approvedInfo {
      status
      comment
      updateUserId
      updateUserInfo {
        name
      }
      updateTimestamp
    }
    updateTimestamp
    updateUserId
    updateUserInfo {
      name
    }
    attachments {
      fileId
    }
  }
}
    `;

export function useGetAgreementsQuery(options?: Omit<Urql.UseQueryArgs<GetAgreementsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAgreementsQuery, GetAgreementsQueryVariables>({ query: GetAgreementsDocument, ...options });
};