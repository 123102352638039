import React from "react";
import { useParams } from "react-router-dom";
import { Transaction } from ".";
import { useQueryState } from "../../common/use-query-state";
import { serializeSwedenDate, serializeSwedenDateIsoString } from "../dateFormater";

interface TransactionViewPropTypes {
    viewAll: boolean;
}

export const TransactionView = ({ viewAll }: TransactionViewPropTypes): React.ReactElement => {
    const { id } = useParams<"id">();
    const [queryStartDate] = useQueryState("startDate", "");
    const selectedClientIds = id && id !== "all" ? [id] : [];

    let startDate = serializeSwedenDate("1990-01-01"); // viewAll
    if (!viewAll) {
        if (queryStartDate) {
            startDate = serializeSwedenDate(new Date(queryStartDate));
        } else if (id) {
            startDate = serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 30)));
        } else {
            startDate = serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 10)));
        }
    }

    const swedenIsoString = serializeSwedenDateIsoString(startDate);
    const endDate = serializeSwedenDateIsoString("2099-01-01");

    return (
        <div>
            <Transaction
                clientIds={selectedClientIds}
                tradeTimestampStart={swedenIsoString}
                tradeTimestampEnd={endDate}
                viewAll={viewAll}
            />
        </div>
    );
};
