import React, { ReactElement } from "react";
import { useQuery, gql, useMutation } from "urql";
import { cloneDeep } from "lodash";
import { sortBy } from "lodash";
import { Svgs } from "../../../components/src";
//import { useInterval } from "../common/Utils";

import { UpdateTransactionsStatusGrid } from "../components/UpdateTransactionStatusGrid";
import { ITransactionDataRow } from "../components/TransactionsGrid";
import { TransactionFilterInput, TransactionStatus, UpdateTransactionInput } from "../types.generated";
import { serializeSwedenDateIsoString } from "../components/dateFormater";
import { useLocation } from "react-router-dom";

const GET_TRANSACTIONS = gql`
    query transactions($filter: TransactionFilterInput) {
        transactions(filter: $filter) {
            _id
            clientId
            client {
                name
            }
            status
            externalId
            brokerId
            broker {
                name
            }
            tradeDate
            tradeTimestamp
            description
            type
            updateUserInfo {
                name
            }
            items {
                type
                amount
                quantity
                price
                currency
                valueDate
                instrumentId
                instrument {
                    _id
                    name
                    longName
                }
            }
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

const transactionGroups = [
    TransactionStatus.Pending,
    TransactionStatus.Preliminary,
    TransactionStatus.Confirmed,
    TransactionStatus.Instructed
];

const groupByStatus = (transactions, transactionGroups) => {
    const result = {};
    transactionGroups.forEach((status) => {
        result[status] = [];
    });

    transactions = sortBy(transactions, "tradeDate").reverse();
    transactions.forEach((transaction) => {
        if (transactionGroups.includes(transaction.status)) {
            result[transaction.status].push(transaction);
        }
    });

    return result;
};

type DealBlotterGridPropsType = {
    startDate: string;
    clientId?: string;
};

export const DealBlotterGrid = ({ startDate, clientId }: DealBlotterGridPropsType): ReactElement => {
    const swedenIsoString = serializeSwedenDateIsoString(startDate);
    const location: any = useLocation();
    const filter: TransactionFilterInput = {
        statusIn: [TransactionStatus.Preliminary, TransactionStatus.Pending, TransactionStatus.Confirmed, TransactionStatus.Instructed],
        tradeTimestampStart: swedenIsoString
    };

    // clientId filter is optional
    if (clientId) {
        filter.clientIds = [clientId];
    }

    const [{ error, data }, refetchData] = useQuery({
        query: GET_TRANSACTIONS,
        variables: { filter },
        requestPolicy: "cache-and-network",
        pause: false
    });

    /*useInterval(
        () => {
            if (fetching) return;
            refetchData({ requestPolicy: "cache-and-network" });
        },
        10000 // Delay in milliseconds or null to stop it
    );*/

    const [stateUpdateStatuses, updateTransactionsStatus] = useMutation(UPDATE_TRANSACTIONS);

    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (stateUpdateStatuses.error) return <p>error: {JSON.stringify(stateUpdateStatuses.error, null, 2)}</p>;

    if (!data) {
        return (
            <div className="loader">
                <div>
                    <div>Loading transactions</div>
                    <Svgs.Loader />
                </div>
            </div>
        );
    }

    const transactions = cloneDeep(data.transactions);

    transactions.forEach((transaction) => {
        const firstItem = transaction.items[0];
        transaction.settlementAmount = null;
        transaction.currency = null;
        if (firstItem) {
            transaction.valueDate = firstItem.valueDate;
        }
        transaction.items.forEach((transactionItem) => {
            if (transactionItem.type === "SettlementAmount") {
                transaction.settlementAmount = transactionItem.amount;
                transaction.currency = transactionItem.currency;
            } else {
                transaction.instrumentId = transactionItem.instrumentId;
                if (transactionItem.instrument && transactionItem.instrument.name) {
                    transaction.instrumentName = transactionItem.instrument.name;
                } else {
                    transaction.instrumentName = "";
                }
            }
        });
    });

    const groups = groupByStatus(transactions, transactionGroups);

    const handleStatusUpdate = async (input: UpdateTransactionInput[]) => {
        await updateTransactionsStatus({ input: input })
            .then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetchData();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toTransactionItems = (items: any): ITransactionDataRow[] => {
        return items.map((item: any) => {
            return {
                _id: item._id,
                clientId: item.clientId,
                clientName: item.client ? item.client.name : null,
                brokerId: item.brokerId,
                brokerName: item.broker ? item.broker.name : null,
                amount: item.settlementAmount,
                currency: item.currency,
                instrumentId: item.instrumentId,
                instrumentName: item.instrumentName,
                tradeDate: item.tradeDate,
                valueDate: item.valueDate,
                type: item.type,
                description: item.description,
                status: item.status,
                updatedByUserName: item.updateUserInfo ? item.updateUserInfo.name : ""
            };
        });
    };

    return (
        <React.Fragment>
            {transactionGroups.map((status) => (
                <div key={status} className="mt-4 mb-4">
                    <h2>{status}</h2>
                    <UpdateTransactionsStatusGrid
                        key={`sg-${groups[status].length}-${location.search}`}
                        items={toTransactionItems(groups[status])}
                        onUpdateStatus={handleStatusUpdate}
                    />
                </div>
            ))}
        </React.Fragment>
    );
};
