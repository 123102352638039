import React, { FormEvent, useEffect, useState } from "react";
import { gql, useClient, useQuery } from "urql";
import { useFormikContext } from "formik";
import { PriceType } from "../../types.generated";

const GET_ACCRUEDINTEREST = gql`
    query instrument($id: GraphQLObjectId, $date: GraphQLDateString) {
        instrument(_id: $id) {
            _id
            accruedInterestOnly(date: $date)
        }
    }
`;

const newKey = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const FetchInstrumentAccruedInterestButton = () => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [variables, setVariables] = useState(null);
    const client = useClient();

    useEffect(() => {
        setVariables({ id: values.instrumentId, date: new Date(values.date).toISOString().substring(0, 10) });
    }, [values]);

    const [{ fetching: _, data: __ }] = useQuery({
        query: GET_ACCRUEDINTEREST,
        variables,
        requestPolicy: "network-only",
        pause: true
    });

    const handleFetchAccured = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        client
            .query(GET_ACCRUEDINTEREST, variables)
            .toPromise()
            .then((result) => {
                if (result && result.data && result.data.instrument) {
                    // AccruedInterestOnly will probably fail for some instrument types where Accrued does not exist. Then just keep zero as value
                    const accruedInterestValue = result.data.instrument.accruedInterestOnly || 0;

                    // If "price items" already exist then remove them before adding
                    const newPrices = values.prices.filter((x) => x.type != PriceType.AccruedInterest && x.type != PriceType.CleanPrice);

                    // AccruedInterest
                    newPrices.push({
                        key: newKey(),
                        type: PriceType.AccruedInterest,
                        currency: values.currency,
                        value: accruedInterestValue
                    });

                    // CleanPrice
                    newPrices.push({ key: newKey(), type: PriceType.CleanPrice, currency: values.currency, value: 0 });

                    setFieldValue("prices", newPrices, true);
                }
            });
    };

    return (
        <button className="btn btn-secondary ms-3" onClick={handleFetchAccured}>
            Fetch accrued
        </button>
    );
};
