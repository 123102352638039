import React from "react";
import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);

interface ISectorsProps {
    data: any;
    locale: Language;
    showHeader: boolean;
    showReferencePortfolio: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
    barHeightRatio?: any;
}

export const Sectors = ({
    data,
    locale,
    barHeightRatio,
    showHeader,
    showReferencePortfolio,
    config,
    layout,
    ...props
}: ISectorsProps): React.ReactElement => {
    let refname: string = null;
    let header: string = null;
    if (locale === "En") {
        refname = "Market Cap Weights";
        header = "Sector Allocation";
    } else {
        if (locale === "Sv") {
            refname = "Marknadsvärdesvikt";
            header = "Sektorallokering";
        }
    }
    const trace1: Partial<Plotly.PlotData> = {
        name: refname,
        y: data.data.sectors.map((d) => d.key),
        x: data.data.sectors.map((d) => d.referenceValue),
        type: "bar",
        orientation: "h",
        hovertemplate: "%{x:.1%}",
        marker: {
            color: chroma(PlotlyDefaults.getColor(11)).darken(-0.3).hex()
        }
    };
    const trace2: Partial<Plotly.PlotData> = {
        name: data.clientName,
        y: data.data.sectors.map((d) => d.key),
        x: data.data.sectors.map((d) => d.value),
        type: "bar",
        orientation: "h",
        hovertemplate: "%{x:.1%}",
        marker: {
            color: chroma(PlotlyDefaults.getColor(1)).darken(0.1).hex()
        }
    };
    const plotlydata = showReferencePortfolio !== false ? [trace1, trace2] : [trace2];

    const localLayout: Partial<Plotly.Layout> = {
        showlegend: false,
        autosize: true,
        xaxis: { tickformat: ".0%", automargin: true },
        yaxis: {
            automargin: true,
            showgrid: false
        }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }
    if (barHeightRatio) {
        thisLayout.height = data.data.sectors.length * barHeightRatio;
    }
    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}
            <Plot data={plotlydata} config={thisConfig} layout={thisLayout} {...props} />
        </React.Fragment>
    );
};
