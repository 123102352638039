import React, { ReactElement } from "react";
import { gql, useMutation } from "urql";
import { AccountingRun } from "../../types.generated";

const CREATE_ACCOUNTING_RUN = gql`
    mutation CreateAccountingRun($input: CreateAccountingRunInput!) {
        createAccountingRun(input: $input) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
        }
    }
`;

interface ICreateAccountingRunButton {
    confirmedRun: AccountingRun;
    input: any;
    onCreated: () => void;
    onError: (msg: string) => void;
}

export const CreateAccountingRunButton = ({ input, confirmedRun, onCreated, onError }: ICreateAccountingRunButton): ReactElement => {
    const [__stateCreate, executeCreateAccountingRun] = useMutation(CREATE_ACCOUNTING_RUN);
    const disableCreateMode = confirmedRun && confirmedRun._id && !(input.endDate > confirmedRun.endDate);
    return (
        <>
            <div>
                {disableCreateMode ? (
                    <div>
                        <i style={{ color: "red" }}>
                            {"Confirmed accounting run exists for " + confirmedRun.endDate + ", choose a later date."}
                        </i>
                    </div>
                ) : null}
            </div>
            <div>
                <button
                    type="button"
                    onClick={async () => {
                        delete input._id;
                        await executeCreateAccountingRun({ input })
                            .then((result) => {
                                if (result.error) {
                                    onError(result.error.toString());
                                } else {
                                    onCreated();
                                }
                            })
                            .catch((error) => {
                                onError(error.message);
                            });
                    }}
                    className="save btn btn-primary btn-sm"
                    disabled={disableCreateMode ? true : false}
                >
                    Create accounting run
                </button>
            </div>
        </>
    );
};
