import React, { useState, useEffect, Fragment } from "react";
import { useQuery, gql } from "urql";
import { Link, createSearchParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";

import { Grid, Column } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";

import { MiniTransaction, Instrument, InstrumentModelTypeEnum, TradingManagerColumn } from "../../types.generated";
import { usePrevious } from "../../common/Utils";

const GET_TRADINGMANAGER = gql`
    query tradingmanager(
        $clientIds: [GraphQLObjectId!]
        $endDate: GraphQLDateString
        $lookThrough: Boolean
        $tradingView: Boolean = false
    ) {
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: $lookThrough) {
            name
            instrumentId
            isin
            longName
            quantity
            currency
            exposure
            localExposure
            issuerName
            issuerId
            valuationPrice
            valuationDate
            fxRate
            bloombergTicker
            sustainabilityClass
            modelType
            accountName
            maturityDate
            interestRateYieldDelta(date: $endDate) @include(if: $tradingView)
            creditYieldDelta(date: $endDate) @include(if: $tradingView)
            instrument {
                zeroSpread @include(if: $tradingView)
                modelType
                creditRating
                issuerProgram {
                    category
                }
                model {
                    legs {
                        notional
                    }
                }
                modelNotionalScaling
            }
        }
    }
`;

const GET_MINI_TRANSACTIONS = gql`
    query miniTransactions($type: [TransactionType], $filter: TransactionFilterInput) {
        miniTransactions(type: $type, filter: $filter) {
            _id
            parentId
            instrumentId
            type
            account {
                _id
                name
            }
        }
    }
`;

const getNotional = (quantity: number, instrument: Instrument) => {
    if (
        instrument &&
        (instrument.modelType === InstrumentModelTypeEnum.Swap || instrument.modelType === InstrumentModelTypeEnum.Swaption) &&
        quantity > 0.9999999 &&
        quantity < 1.0000001
    )
        return instrument.modelNotionalScaling;
    else if (
        instrument &&
        instrument.modelType === InstrumentModelTypeEnum.CdsIndex &&
        instrument.model &&
        Array.isArray(instrument.model.legs) &&
        instrument.model.legs.length &&
        instrument.model.legs[0].notional
    )
        return quantity * instrument.model.legs[0].notional;
    else return quantity;
};

const check_multiple_valuation_dates = (tradingmanager) => {
    const valuation_dates = [];
    let return_text: string;
    for (let i = 0; i < tradingmanager.length; i++) {
        if (tradingmanager[i].quantity === null || tradingmanager[i].quantity === 0) {
            continue;
        }
        if (!valuation_dates.includes(tradingmanager[i].valuationDate)) {
            valuation_dates.push(tradingmanager[i].valuationDate);
        }
    }
    if (valuation_dates.length !== 1) {
        return_text = "Mixed valuation dates";
    } else {
        return_text = "";
    }
    return return_text;
};

const sumIssuerExposure = function (tradingmanager: TradingManagerColumn[], issuer_sum: string) {
    return tradingmanager
        .filter(({ issuerName }) => issuerName === issuer_sum)
        .reduce(function (prev, cur) {
            return prev + cur.exposure;
        }, 0);
};

export interface PortfolioManagerCommentProps {
    clientIds: string[];
    endDate: string;
    lookThrough: boolean;
    tradingView: boolean;
}

export const TradingManager = ({ clientIds, endDate, lookThrough, tradingView }: PortfolioManagerCommentProps): React.ReactElement => {
    const previousProps: PortfolioManagerCommentProps = usePrevious({ clientIds, endDate, lookThrough, tradingView });
    const [variables, setVariables] = useState({ clientIds, endDate, lookThrough, tradingView });

    const [{ fetching: loading, error, data }] = useQuery({
        query: GET_TRADINGMANAGER,
        requestPolicy: "cache-and-network",
        variables: variables
    });

    const [instrumentIds, setInstrumentIds] = useState(null);
    const navigate = useNavigate();

    const filter = { instrumentIdsIn: instrumentIds, statusNotIn: ["Deleted"], clientIds: clientIds };
    const [{ fetching: getMiniTransactionsLoading, error: getMiniTransactionsError, data: getMiniTransactionsData }] = useQuery({
        query: GET_MINI_TRANSACTIONS,
        variables: { type: ["Insert", "DerivativeTrade"], filter: filter },
        requestPolicy: "cache-and-network",
        pause: !instrumentIds
    });

    useEffect(() => {
        if (stableStringify({ clientIds, endDate, lookThrough, tradingView }) !== stableStringify(previousProps)) {
            setVariables({ clientIds, endDate, lookThrough, tradingView });
        }
    }, [clientIds, data, endDate, lookThrough, previousProps, setInstrumentIds, tradingView]);

    useEffect(() => {
        if (data) {
            const derivativePositionsInstrumentIds = [];
            for (let i = 0; i < data.tradingmanager.length; i++) {
                const tradingManagerColumn = cloneDeep(data.tradingmanager[i]);
                tradingManagerColumn["creditRating"] = data.tradingmanager[i].instrument.creditRating;
                delete tradingManagerColumn["instrument"];
                if (
                    tradingManagerColumn.modelType === "Swap" ||
                    tradingManagerColumn.modelType === "Swaption" ||
                    tradingManagerColumn.modelType === "FxSwap" ||
                    tradingManagerColumn.modelType === "EquityBasketOption" ||
                    tradingManagerColumn.modelType === "EquityUnitOption" ||
                    tradingManagerColumn.modelType === "GenericSwap" ||
                    tradingManagerColumn.modelType === "GenericFxSwap" ||
                    tradingManagerColumn.modelType === "PortfolioSwap"
                )
                    derivativePositionsInstrumentIds.push(tradingManagerColumn.instrumentId);
            }
            setInstrumentIds(derivativePositionsInstrumentIds);
        }
    }, [data, setInstrumentIds]);

    if (loading) return <div> Loading... </div>;
    if (error) return <div>{"Error!" + error.message}</div>;
    if (getMiniTransactionsLoading) return <div> Loading transactions </div>;
    if (getMiniTransactionsError) return <div>{"Error!" + error.message}</div>;

    const portfolioNav = data.tradingmanager.reduce((acc, o) => acc + o.exposure, 0);
    const sumInterestRateYieldDelta = data.tradingmanager.reduce((acc, o) => acc + o.interestRateYieldDelta, 0);
    const sumCreditYieldDelta = data.tradingmanager.reduce((acc, o) => acc + o.creditYieldDelta, 0);
    const duration = (sumInterestRateYieldDelta / portfolioNav) * 10000;
    const creditDuration = (sumCreditYieldDelta / portfolioNav) * 10000;
    const sumBondsCreditYieldDelta: number = data.tradingmanager
        .filter((x) => x.modelType == InstrumentModelTypeEnum.Bond)
        .reduce((acc, o) => acc + o.creditYieldDelta, 0);
    const sumBondsExposure: number = data.tradingmanager
        .filter((x) => x.modelType == InstrumentModelTypeEnum.Bond)
        .reduce((acc, o) => acc + o.exposure, 0);
    const bonds = (sumBondsCreditYieldDelta / sumBondsExposure) * 10000;
    let averageSpread = 0;

    const tradingManagerIncludingRating = [];
    for (let i = 0; i < data.tradingmanager.length; i++) {
        const tradingManagerColumn = cloneDeep(data.tradingmanager[i]);
        tradingManagerColumn["creditRating"] = data.tradingmanager[i].instrument.creditRating;
        tradingManagerColumn["zeroSpread"] = data.tradingmanager[i].instrument.zeroSpread * 10000;
        averageSpread = averageSpread + tradingManagerColumn["zeroSpread"] * tradingManagerColumn["exposure"];
        if (data.tradingmanager[i].instrument.issuerProgram && data.tradingmanager[i].instrument.issuerProgram.category) {
            tradingManagerColumn["category"] = data.tradingmanager[i].instrument.issuerProgram.category;
        } else {
            tradingManagerColumn["category"] = "None";
        }
        delete tradingManagerColumn["instrument"];
        tradingManagerColumn["notional"] = getNotional(data.tradingmanager[i].quantity, data.tradingmanager[i].instrument);
        if (data.tradingmanager[i].issuerName === null) {
            tradingManagerColumn["issuerSum"] = 0;
        } else {
            tradingManagerColumn["issuerSum"] = sumIssuerExposure(data.tradingmanager, data.tradingmanager[i].issuerName) / portfolioNav;
        }
        tradingManagerIncludingRating.push(tradingManagerColumn);
    }
    averageSpread = averageSpread / portfolioNav;

    const transactionsByInstrumentIdAndAccountName: { [key: string]: MiniTransaction } = {};
    if (getMiniTransactionsData) {
        for (const transaction of getMiniTransactionsData.miniTransactions) {
            transactionsByInstrumentIdAndAccountName[transaction.instrumentId + "_" + transaction.account.name] = transaction;
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-sm-3">Portfolio value: {numberFormatFun("# ##0")(portfolioNav)}</div>
                <div className="col-sm-3">Duration: {numberFormatFun("# ##0.00")(duration)}</div>
                <div className="col-sm-3">Rates DV01 SEK: {numberFormatFun("# ##0.00")(sumInterestRateYieldDelta)}</div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-sm-3">{check_multiple_valuation_dates(tradingManagerIncludingRating)}</div>
                <div className="col-sm-3">
                    Credit duration: {numberFormatFun("# ##0.00")(creditDuration)} (Bonds:{" "}
                    {bonds ? numberFormatFun("# ##0.00")(bonds) : "n/a"})
                </div>
                <div className="col-sm-3">Portfolio spread: {numberFormatFun("# ##0")(averageSpread)}</div>
            </div>

            {tradingView ? (
                <Grid header="POSITIONS" data={tradingManagerIncludingRating} sortable tableClassName="table-xs" copyButton>
                    <Column
                        field="name"
                        className="nowrap left"
                        format={(d, pos) => {
                            const menu = ["Instrument"];
                            if (
                                transactionsByInstrumentIdAndAccountName &&
                                transactionsByInstrumentIdAndAccountName[pos.instrumentId + "_" + pos.accountName]
                            ) {
                                menu.push("Close position");
                            }
                            return (
                                <Dropdown>
                                    <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={pos.instrumentId} data-boundary="window">
                                        {pos.name.slice(0, 27)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {menu.map((menuItem, i) => {
                                            return (
                                                <Dropdown.Item
                                                    key={i}
                                                    onClick={() => {
                                                        if (menuItem === "Instrument") {
                                                            const win = window.open("/instruments/" + pos.instrumentId, "_blank");
                                                            win.focus();
                                                        } else {
                                                            const transaction =
                                                                transactionsByInstrumentIdAndAccountName[
                                                                    pos.instrumentId + "_" + pos.accountName
                                                                ];

                                                            const params = {
                                                                id: "newclosederivativetrade",
                                                                parentId: `${transaction._id}`
                                                            };
                                                            const searchParams = new URLSearchParams(location.search);
                                                            for (const [key, value] of searchParams.entries()) {
                                                                params[key] = value;
                                                            }

                                                            navigate({
                                                                pathname: `/pm/${clientIds}/positions`,
                                                                search: createSearchParams(params).toString()
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {menuItem}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            );
                        }}
                    />
                    <Column field="isin" className="nowrap" />
                    <Column field="currency" />
                    <Column field="notional" format={numberFormatFun("# ##0")} className="nowrap" />
                    <Column field="exposure" format={numberFormatFun("# ##0")} className="nowrap" />
                    <Column field="modelType" />
                    <Column
                        field="issuerName"
                        className="nowrap maxwidth150px left"
                        format={(value, item) => {
                            return item && item.issuerId ? (
                                <Link to={"/parties/" + item.issuerId} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column field="issuerSum" format={numberFormatFun("# ##0.00%")} />
                    <Column field="maturityDate" />
                    <Column title="DV01" field="interestRateYieldDelta" format={numberFormatFun("# ##0")} />
                    <Column title="Spread (bp)" field="zeroSpread" format={numberFormatFun("# ##0")} />
                </Grid>
            ) : (
                <Grid header="POSITIONS" data={tradingManagerIncludingRating} sortable tableClassName="table-xs" copyButton>
                    <Column field="accountName" title="Account" />
                    <Column
                        field="name"
                        className="nowrap left"
                        format={(d, pos) => {
                            const menu = ["Instrument"];
                            if (
                                transactionsByInstrumentIdAndAccountName &&
                                transactionsByInstrumentIdAndAccountName[pos.instrumentId + "_" + pos.accountName]
                            ) {
                                menu.push("Close Position");
                            }
                            return (
                                <Dropdown>
                                    <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={pos.instrumentId} data-boundary="window">
                                        {pos.name.slice(0, 27)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {menu.map((menuItem, i) => {
                                            return (
                                                <Dropdown.Item
                                                    key={i}
                                                    onClick={() => {
                                                        if (menuItem === "Instrument") {
                                                            const win = window.open("/instruments/" + pos.instrumentId, "_blank");
                                                            win.focus();
                                                        } else {
                                                            const transaction =
                                                                transactionsByInstrumentIdAndAccountName[
                                                                    pos.instrumentId + "_" + pos.accountName
                                                                ];

                                                            const params = {
                                                                id: "newclosederivativetrade",
                                                                parentId: `${transaction._id}`
                                                            };
                                                            const searchParams = new URLSearchParams(location.search);
                                                            for (const [key, value] of searchParams.entries()) {
                                                                params[key] = value;
                                                            }

                                                            navigate({
                                                                pathname: `/pm/${clientIds}/positions`,
                                                                search: createSearchParams(params).toString()
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {menuItem}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            );
                        }}
                    />
                    <Column field="bloombergTicker" title="Bloomberg ticker" className="nowrap" />
                    <Column field="currency" />
                    <Column field="quantity" format={numberFormatFun("# ##0")} className="nowrap" />
                    <Column field="valuationPrice" title="Valuation price" format={numberFormatFun("# ##0.00")} className="nowrap" />
                    <Column field="exposure" format={numberFormatFun("# ##0")} className="nowrap" />
                    <Column field="localExposure" title="Local exposure" format={numberFormatFun("# ##0")} className="nowrap" />
                    <Column field="valuationDate" title="Valuation date" className="nowrap" />
                    <Column field="fxRate" title="Fx rate" />
                    <Column
                        field="issuerName"
                        title="Issuer name"
                        className="nowrap maxwidth150px left"
                        format={(value, item) => {
                            return item && item.issuerId ? (
                                <Link to={"/parties/" + item.issuerId} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field="creditRating"
                        title="Credit rating"
                        format={(item) => {
                            if (item === "Not Applicable") {
                                return "N/A";
                            }
                            return item;
                        }}
                    />
                    <Column field="category" title="Issuer program" />
                    <Column field="modelType" title="Model type" />
                </Grid>
            )}
        </Fragment>
    );
};
