import React, { Fragment, useEffect, useState, useMemo } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Link, useParams, useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { cloneDeep, sortBy } from "lodash";
import { Alert } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Panel } from "@fluentui/react";

import { Grid, Column } from "../../../../components/src";
import { mapping, twoDecPriceFormat } from "../../../../common/src";
import { Svgs } from "../../../../components/src";

import { exportToXlsx } from "../../common/exportToXlsx";
import { YesNoModal } from "../../components/YesNoModal";
import { SelectColumnFilter } from "../../components/react-table/ReactTableFilters";
import { ReactTable } from "../../components/react-table/ReactTable";
import { MiniTransactionForm, miniTransactionTypes } from "../MiniTransactionForm";
import { AccountingRunStatusEnum, AccountingBatchType, PermissionTypeEnum, PermissionAssetEnum } from "../../types.generated";
import { ManualJournalEntryForm } from "../../common/accounting/ManualJournalEntryForm";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { getAccountingRunsWithJournalEntries, getAccountingRunWithJournalEntries, getMe, getTAccountCharts } from "./queries";
import { useQueryArgs } from "../../common/Utils";
import { userHaveAccessTo } from "../../common/Permissions";

const DELETE_JOURNAL_ENTRY = gql`
    mutation DeleteJournalEntry($_id: GraphQLObjectId!) {
        deleteJournalEntry(_id: $_id)
    }
`;

export function JournalEntriesForm(): React.ReactElement {
    const { id, clientId, accountingPeriod, maxNumber, type, journalEntryId }: any = useParams();

    const { queryArgs } = useQueryArgs();

    // fetch clientTAccountCharts, always using groupX from latest TAccountChart (nothing else)
    const [{ fetching: loadingClientTAccountCharts, error: errorClientTAccountCharts, data: dataClientTAccountCharts }] = useQuery({
        query: getTAccountCharts,
        variables: { clientId },
        pause: !clientId
    });

    const [{ fetching: loadingMe, error: errorMe, data: dataMe }] = useQuery({
        query: getMe
    });

    const [{ fetching: _fetchingAccountingRun, error: errorAccountingRun, data: dataAccountingRun }] = useQuery({
        query: getAccountingRunWithJournalEntries,
        variables: { id },
        pause: id ? false : true
    });

    const [{ fetching: _fetchingAccountingRuns, error: errorAccountingRuns, data: dataAccountingRuns }, refetchAccountingRuns] = useQuery({
        query: getAccountingRunsWithJournalEntries,
        variables: { clientId, accountingPeriod },
        pause: clientId && accountingPeriod ? false : true
    });

    const [_, deleteJournalEntry] = useMutation(DELETE_JOURNAL_ENTRY);

    // fetch previous accounting runs -
    const location: any = useLocation();
    const navigate = useNavigate();

    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [latestTAccountChart, setLatestTAccountChart] = useState(null);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useEffect(() => {
        if (dataClientTAccountCharts && dataClientTAccountCharts.tAccountCharts && !latestTAccountChart) {
            const sortedTAccountCharts = sortBy(dataClientTAccountCharts.tAccountCharts, "version");
            setLatestTAccountChart(sortedTAccountCharts[sortedTAccountCharts.length - 1]);
        }
    }, [dataClientTAccountCharts, latestTAccountChart]);

    const { currentAccountingRun, currentEndDate, journalEntries, firstRunOfYear } = useMemo(() => {
        let currentAccountingRun = null;
        let currentEndDate = null;
        let journalEntries = null;
        let firstRunOfYear = true;

        if (loadingClientTAccountCharts) return { currentAccountingRun, currentEndDate, journalEntries, firstRunOfYear };
        if (errorClientTAccountCharts) return { currentAccountingRun, currentEndDate, journalEntries, firstRunOfYear };

        if (dataAccountingRun && dataAccountingRun.accountingRun) {
            const ar = dataAccountingRun.accountingRun;
            journalEntries = ar.journalEntries;
            journalEntries.forEach((d) => (d.accountingRun = ar));
            currentAccountingRun = ar;
        } else if (dataAccountingRuns && dataAccountingRuns.accountingRuns) {
            journalEntries = [];
            if (
                (dataAccountingRuns.accountingRuns.length === 1 &&
                    dataAccountingRuns.accountingRuns[0].status === AccountingRunStatusEnum.Confirmed) ||
                dataAccountingRuns.accountingRuns.length > 1
            ) {
                firstRunOfYear = false;
            }
            dataAccountingRuns.accountingRuns.forEach((d) => {
                if (maxNumber && d.number > Number(maxNumber)) {
                    return;
                }
                //Make sure IBs not added twice, NOT for first accountingrun of the year
                if (maxNumber && d.number === Number(maxNumber) && dataAccountingRuns.accountingRuns.length !== 1) {
                    const je = cloneDeep(d.journalEntries);
                    je.forEach(function (e) {
                        e.accountingRun = d;
                        if (e.batch !== AccountingBatchType.IB) {
                            journalEntries.push(e);
                        }
                    });
                } else {
                    const je = cloneDeep(d.journalEntries);
                    je.forEach((e) => (e.accountingRun = d));
                    journalEntries.push(...je);
                }
                if (d.type === "Transaction" && (currentEndDate === null || d.endDate > currentEndDate)) {
                    currentEndDate = d.endDate;
                    currentAccountingRun = d;
                }
            });
        }

        return { currentAccountingRun, currentEndDate, journalEntries, firstRunOfYear };
    }, [dataAccountingRun, dataAccountingRuns, errorClientTAccountCharts, loadingClientTAccountCharts, maxNumber]);

    const { transactions, accountBalancesDict } = useMemo(() => {
        const transactions = [];
        const accountBalancesDict = {};
        if (journalEntries && journalEntries.length) {
            journalEntries.forEach((je) => {
                je.transactions.forEach((t) => {
                    t.journalEntry = je;
                    let d = accountBalancesDict[t.tAccountNumber];
                    if (typeof d === "undefined") {
                        d = { description: null, balance: 0 };
                        accountBalancesDict[t.tAccountNumber] = d;
                    }
                    d.balance += t.amount;
                    if (t.journalEntry.accountingRun.clientTAccountChart) {
                        const a = t.journalEntry.accountingRun.clientTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber);
                        if (a) {
                            d.description = a.description;
                        }
                    }
                });

                // eslint-disable-next-line prefer-spread
                transactions.push.apply(transactions, je.transactions);
            });
        }

        return { transactions, accountBalancesDict };
    }, [journalEntries]);

    const { transactionsGrouped } = useMemo(() => {
        const transactionsGrouped = { "Manual journal entries": [], "Journal entries": [] };

        if (transactions && transactions.length) {
            transactions.forEach((t) => {
                const res: any = {};
                res.batchAndNumber = t.journalEntry.batch + t.journalEntry.number;
                res._id = t.journalEntry._id;
                res.batch = t.journalEntry.batch;
                res.effectiveDate = t.journalEntry.effectiveDate;
                res.amount = t.amount;
                res.quantity = t.quantity ? t.quantity : null;
                res.type = t.type;
                res.instrumentName = t.instrument ? t.instrument.name : null;
                const tAccount = t.journalEntry.accountingRun.clientTAccountChart
                    ? t.journalEntry.accountingRun.clientTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber)
                    : null;
                const latestTAccount = latestTAccountChart
                    ? latestTAccountChart.tAccounts.find((d) => d.number === t.tAccountNumber)
                    : null;
                res.tAccount = t.tAccountNumber + (tAccount ? " - " + tAccount.description : "");
                res.portfolioTransactionId = t.journalEntry.portfolioTransactionId ? t.journalEntry.portfolioTransactionId : null;
                res.externalNumber = t.journalEntry.externalNumber ? t.journalEntry.externalNumber : null;
                res.portfolioTransaction = t.journalEntry.portfolioTransaction ? t.journalEntry.portfolioTransaction : null;
                if (
                    latestTAccount &&
                    mapping[latestTAccount.group1] &&
                    mapping[latestTAccount.group1].translation.Sv &&
                    mapping[latestTAccount.group1].translation.Sv[currentAccountingRun.client.accountingCompanyType]
                ) {
                    res.group1 = mapping[latestTAccount.group1].translation.Sv[currentAccountingRun.client.accountingCompanyType];
                } else {
                    res.group1 = latestTAccount ? latestTAccount.group1 : null;
                }
                if (
                    latestTAccount &&
                    mapping[latestTAccount.group1] &&
                    mapping[latestTAccount.group1][latestTAccount.group2] &&
                    mapping[latestTAccount.group1][latestTAccount.group2].translation.Sv &&
                    mapping[latestTAccount.group1][latestTAccount.group2].translation.Sv[currentAccountingRun.client.accountingCompanyType]
                ) {
                    res.group2 =
                        mapping[latestTAccount.group1][latestTAccount.group2].translation.Sv[
                            currentAccountingRun.client.accountingCompanyType
                        ];
                } else {
                    res.group2 = latestTAccount ? latestTAccount.group2 : null;
                }
                if (
                    latestTAccount &&
                    mapping[latestTAccount.group1] &&
                    mapping[latestTAccount.group1][latestTAccount.group2] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3].translation.Sv &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3].translation.Sv[
                        currentAccountingRun.client.accountingCompanyType
                    ]
                ) {
                    res.group3 =
                        mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3].translation.Sv[
                            currentAccountingRun.client.accountingCompanyType
                        ];
                } else {
                    res.group3 = latestTAccount ? latestTAccount.group3 : null;
                }
                if (
                    latestTAccount &&
                    mapping[latestTAccount.group1] &&
                    mapping[latestTAccount.group1][latestTAccount.group2] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4].translation.Sv &&
                    typeof mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4].translation
                        .Sv[currentAccountingRun.client.accountingCompanyType] !== "undefined"
                ) {
                    res.group4 =
                        mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4].translation.Sv[
                            currentAccountingRun.client.accountingCompanyType
                        ];
                } else {
                    res.group4 = latestTAccount ? latestTAccount.group4 : null;
                }

                if (
                    latestTAccount &&
                    mapping[latestTAccount.group1] &&
                    mapping[latestTAccount.group1][latestTAccount.group2] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4][
                        latestTAccount.group5
                    ] &&
                    mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4][
                        latestTAccount.group5
                    ].translation.Sv &&
                    typeof mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4][
                        latestTAccount.group5
                    ].translation.Sv[currentAccountingRun.client.accountingCompanyType] !== "undefined"
                ) {
                    res.group5 =
                        mapping[latestTAccount.group1][latestTAccount.group2][latestTAccount.group3][latestTAccount.group4][
                            latestTAccount.group5
                        ].translation.Sv[currentAccountingRun.client.accountingCompanyType];
                } else {
                    res.group5 = latestTAccount ? latestTAccount.group5 : null;
                }

                if (res.batch === AccountingBatchType.M) {
                    transactionsGrouped["Manual journal entries"].push(res);
                } else {
                    transactionsGrouped["Journal entries"].push(res);
                }
            });
        }

        return { transactionsGrouped };
    }, [transactions, latestTAccountChart, currentAccountingRun]);

    const { tAccountBalances, tAccountNumbers, number } = useMemo(() => {
        const tAccountBalances = Object.keys(accountBalancesDict).map((d) => ({ tAccountNumber: d, ...accountBalancesDict[d] }));
        tAccountBalances.sort((d1, d2) => (d1.tAccountNumber < d2.tAccountNumber ? -1 : 1));

        let manualBatchMax = 0;
        const manualPreviousJes =
            journalEntries && journalEntries.length ? journalEntries.filter((je) => je.batch === AccountingBatchType.M) : [];
        if (manualPreviousJes.length > 0) {
            manualBatchMax = Math.max.apply(
                null,
                manualPreviousJes.map((je) => je.number)
            );
        }
        //Should be 0 if first quarter of year for the first manual j.e. we create
        if (firstRunOfYear && !manualPreviousJes.length && dataAccountingRuns) {
            manualBatchMax = 0;
        }

        let number = 1;
        number = manualBatchMax + 1;

        if (journalEntries && journalEntries.length) {
            const f = journalEntries.findIndex((d) => d.batch === AccountingBatchType.M && d.number === number);
            if (f >= 0) {
                throw new Error(`JournalEntry already exists ${AccountingBatchType.M}${number}`);
            }
        }

        const tAccountNumbers: { key: string; value: string }[] = [];
        if (currentAccountingRun && currentAccountingRun.clientTAccountChart && currentAccountingRun.clientTAccountChart.tAccounts)
            currentAccountingRun.clientTAccountChart.tAccounts.forEach((tAccount) => {
                tAccountNumbers.push({ key: tAccount.number, value: tAccount.number + " - " + tAccount.description });
            });

        return { tAccountBalances, tAccountNumbers, number };
    }, [journalEntries, accountBalancesDict, firstRunOfYear, dataAccountingRuns, currentAccountingRun]);

    const accountingReadWriteAccess =
        dataMe &&
        dataMe.me &&
        dataMe.me.roles &&
        userHaveAccessTo(clientId, PermissionAssetEnum.Accounting, dataMe.me.roles, PermissionTypeEnum.ReadWrite)
            ? true
            : false;

    const columns = React.useMemo(
        () => [
            {
                header: "Batch and number",
                accessorKey: "batchAndNumber",
                size: 60,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const batchAndNumber = cellProps.getValue();
                    if (
                        row.original.batch === AccountingBatchType.M &&
                        row.original.effectiveDate === currentEndDate &&
                        accountingReadWriteAccess
                    ) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                    {batchAndNumber}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {["View", "Delete"].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    if (changeTo === "Delete") {
                                                        setModal({
                                                            showModal: true,
                                                            payload: row.original._id
                                                        });
                                                    } else {
                                                        navigate(`${location.pathname}/journalentry/${row.original._id}${location.search}`);
                                                    }
                                                }}
                                            >
                                                {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else if (
                        row.original.batch === AccountingBatchType.T &&
                        row.original.portfolioTransactionId &&
                        row.original.portfolioTransactionId.toString() !== "000000000000000000000000" &&
                        miniTransactionTypes.find((d) => d === row.original.portfolioTransaction.type && accountingReadWriteAccess)
                    ) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                    {batchAndNumber}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {["View transaction"].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    const locationSearch = location.search ? location.search + "&" : "?";

                                                    navigate(
                                                        `${location.pathname}${locationSearch}portfolioTransactionId=${row.original.portfolioTransactionId}&minitransactiontype=${row.original.portfolioTransaction.type}`
                                                    );
                                                }}
                                            >
                                                {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else {
                        return batchAndNumber;
                    }
                }
            },
            {
                header: "External number",
                accessorKey: "externalNumber",
                size: 55,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const externalNumber = cellProps.getValue();
                    if (row.original.batch === AccountingBatchType.M && row.original.effectiveDate === currentEndDate) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                    {externalNumber}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {["View", "Delete"].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    if (changeTo === "Delete") {
                                                        setModal({
                                                            showModal: true,
                                                            payload: row.original._id
                                                        });
                                                    } else {
                                                        navigate(`${location.pathname}/journalentry/${row.original._id}${location.search}`);
                                                    }
                                                }}
                                            >
                                                {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else if (
                        row.original.batch === AccountingBatchType.T &&
                        row.original.portfolioTransactionId &&
                        row.original.portfolioTransactionId.toString() !== "000000000000000000000000" &&
                        miniTransactionTypes.find((d) => d === row.original.portfolioTransaction.type)
                    ) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={row.original._id}>
                                    {externalNumber}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {["View transaction"].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    const locationSearch = location.search ? location.search + "&" : "?";

                                                    navigate(
                                                        `${location.pathname}${locationSearch}portfolioTransactionId=${row.original.portfolioTransactionId}&minitransactiontype=${row.original.portfolioTransaction.type}`
                                                    );
                                                }}
                                            >
                                                {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else {
                        return externalNumber;
                    }
                }
            },
            {
                header: "Batch",
                accessorKey: "batch",
                filter: SelectColumnFilter,
                size: 20
            },
            {
                header: "Effective date",
                accessorKey: "effectiveDate",
                size: 45
            },
            {
                header: "T account",
                accessorKey: "tAccount",
                size: 90,
                filter: SelectColumnFilter
            },
            {
                header: "Amount",
                accessorKey: "amount",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                size: 60
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                size: 60
            },
            {
                header: "Type",
                accessorKey: "type",
                filter: SelectColumnFilter,
                size: 40
            },
            {
                header: "Instrument name",
                accessorKey: "instrumentName",
                filter: SelectColumnFilter,
                size: 120
            },
            {
                header: "Group 1",
                accessorKey: "group1",
                size: 60,
                filter: SelectColumnFilter
            },
            {
                header: "Group 2",
                accessorKey: "group2",
                size: 60,
                filter: SelectColumnFilter
            },
            {
                header: "Group 3",
                accessorKey: "group3",
                size: 60,
                filter: SelectColumnFilter
            },
            {
                header: "Group 4",
                accessorKey: "group4",
                size: 60
            },
            {
                header: "Group 5",
                accessorKey: "group5",
                size: 60
            }
        ],
        [currentEndDate, accountingReadWriteAccess, navigate, location.pathname, location.search]
    );

    if (loadingClientTAccountCharts) return <div>{"Loading tAccountCharts"}</div>;

    if (errorClientTAccountCharts) return <div>{"Error tAccountCharts: " + errorClientTAccountCharts.message}</div>;

    if (loadingMe) return <div>{"Loading"}</div>;

    if (errorMe) return <div>{"Error: " + errorMe.message}</div>;

    if (errorAccountingRun)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorAccountingRun, null, 2)}</pre>
            </div>
        );

    if (errorAccountingRuns)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorAccountingRuns, null, 2)}</pre>
            </div>
        );

    if (!journalEntries) {
        return <p>Loading...</p>;
    }
    const handleDismiss = () => {
        const searchParams = new URLSearchParams(location.search);
        const p = {};
        for (const [key, value] of searchParams.entries()) {
            if (!["portfolioTransactionId", "minitransactiontype"].includes(key)) {
                p[key] = value;
            }
        }

        navigate({
            pathname: location.pathname,
            search: createSearchParams(p).toString()
        });
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete journal entry with id " + modal.payload + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deleteJournalEntry({ _id: modal.payload })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    refetchAccountingRuns();
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}
            <div className="row">
                {alert.visible ? (
                    <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert}>
                        {alert.message}
                    </Alert>
                ) : null}
            </div>
            <div id="are-buttons" className="row">
                <div className="col-12 right">
                    {currentAccountingRun &&
                    currentAccountingRun.status === AccountingRunStatusEnum.Pending &&
                    !type &&
                    accountingReadWriteAccess ? (
                        <div className="ms-auto p-2">
                            <button
                                type="button"
                                onClick={async () => {
                                    navigate({
                                        pathname: `${location.pathname}/journalentry/new`,
                                        search: location.search
                                    });
                                }}
                                className="save btn btn-primary btn-sm"
                            >
                                New journal entry
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
            <Fragment>
                <div className="journalentriesform row">
                    <Grid
                        className={"taccountbalances col-6" + (journalEntryId ? " col-sm-5 col-md-6 col-lg-7 col-xl-8 col-xxl-9" : "")}
                        header={"T-Account Balances"}
                        data={tAccountBalances}
                        tableClassName="table-xs"
                        sortable
                    >
                        <Column field="tAccountNumber" title="T-Account" className="center" />
                        <Column field="description" className="nowrap" />
                        <Column field="balance" className="nowrap right" format={twoDecPriceFormat} />
                    </Grid>
                    {type === "journalentry" && journalEntryId ? (
                        <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                            <div className="container">
                                <Link
                                    className="close ms-2 mb-1"
                                    to={`/journalentries/${clientId}/${accountingPeriod}/${maxNumber}${location.search}`}
                                >
                                    x
                                </Link>
                                <ManualJournalEntryForm
                                    journalEntryId={journalEntryId === "new" ? null : journalEntryId}
                                    clientId={clientId}
                                    accountingRunId={currentAccountingRun._id}
                                    effectiveDate={currentAccountingRun.endDate}
                                    manualJournalEntryNumber={number}
                                    tAccountNumbers={tAccountNumbers}
                                    onUpdated={() => {
                                        refetchAccountingRuns();
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    <Panel
                        isOpen={
                            queryArgs &&
                            typeof queryArgs.minitransactiontype !== "undefined" &&
                            typeof queryArgs.portfolioTransactionId !== "undefined"
                        }
                        onDismiss={handleDismiss}
                        isBlocking={false}
                        layerProps={{ eventBubblingEnabled: true }}
                    >
                        <MiniTransactionForm
                            clientId={clientId ? clientId : "000000000000000000000000"}
                            id={queryArgs.portfolioTransactionId as string}
                            type={queryArgs.minitransactiontype as string}
                            queryArgMode={true}
                        />
                    </Panel>
                </div>
                {Object.keys(transactionsGrouped).map((group) => {
                    const data = transactionsGrouped[group];
                    return (
                        <div key={group}>
                            <h3>{group}</h3>
                            <p>Nbr of rows {data.length}</p>
                            {data.length > 0 ? (
                                <div>
                                    <div
                                        style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                                        onClick={() => {
                                            exportToXlsx([data], group + ".xlsx");
                                        }}
                                    >
                                        <Svgs.Excel />
                                    </div>
                                    <ReactTable columns={columns} data={data} />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </Fragment>
        </div>
    );
}
