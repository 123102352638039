/* eslint-disable */
import type * as Types from '../../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteNavMutationVariables = Types.Exact<{
  _id: Types.Scalars['GraphQLObjectId']['input'];
}>;


export type DeleteNavMutation = { __typename?: 'Mutation', deleteNav?: boolean | null };

export type GetNavsQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['GraphQLDateString']['input']>;
}>;


export type GetNavsQuery = { __typename?: 'Query', navs?: Array<{ __typename?: 'Nav', _id: string, clientId: string, date: string, createTimestamp: any, status: Types.ReportStatusEnum, client?: { __typename?: 'Party', name: string } | null, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null, publishedTo?: Array<{ __typename?: 'PublishedToType', key: string }> | null } | null> | null };


export const DeleteNavDocument = gql`
    mutation deleteNav($_id: GraphQLObjectId!) {
  deleteNav(_id: $_id)
}
    `;

export function useDeleteNavMutation() {
  return Urql.useMutation<DeleteNavMutation, DeleteNavMutationVariables>(DeleteNavDocument);
};
export const GetNavsDocument = gql`
    query getNavs($startDate: GraphQLDateString) {
  navs(startDate: $startDate) {
    _id
    clientId
    client {
      name
    }
    date
    createTimestamp
    status
    updateUserInfo {
      name
    }
    publishedTo {
      key
    }
  }
}
    `;

export function useGetNavsQuery(options?: Omit<Urql.UseQueryArgs<GetNavsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNavsQuery, GetNavsQueryVariables>({ query: GetNavsDocument, ...options });
};