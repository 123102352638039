export type NavRange = {
    errorRange: [number, number];
    narrowRange: [number, number];
    withinErrorRange: boolean;
    withinNarrowRange: boolean;
    absHighTolerancePercent: number;
    absLowTolerancePercent: number;
};

export const calculateWithinRange = (
    frontOfficeNav: number,
    totalNavBeforeAdjustmentsBeforeOrders: number,
    riskLevel: number
): NavRange => {
    // More information found in https://github.com/CaptorAB/Python/blob/master/FrontOffice/frontOfficeNav.md
    const substantialErrorLimitsByIndex = [
        0, //       NOT USED
        0.001, //   KIID-Category 1
        0.002, //   KIID-Category 2
        0.003, //   KIID-Category 3
        0.005, //   KIID-Category 4
        0.005, //   KIID-Category 5
        0.005, //   KIID-Category 6
        0.005 //    KIID-Category 7
    ];

    const highTolerance = substantialErrorLimitsByIndex[riskLevel];
    const lowTolerance = highTolerance * 0.5; // The narrow band can be defined as half-way to broader

    // Edlund asked on 2022-04-12 to "2 * highTolerance" due to market movements
    //const errorRange: [number, number] = [frontOfficeNav - highTolerance * frontOfficeNav, frontOfficeNav + highTolerance * frontOfficeNav];
    const errorRange: [number, number] = [
        frontOfficeNav - 2 * highTolerance * frontOfficeNav,
        frontOfficeNav + 2 * highTolerance * frontOfficeNav
    ];
    const narrowRange: [number, number] = [frontOfficeNav - lowTolerance * frontOfficeNav, frontOfficeNav + lowTolerance * frontOfficeNav];

    let withinErrorRange = false;
    let withinNarrowRange = false;

    if (totalNavBeforeAdjustmentsBeforeOrders > errorRange[0] && totalNavBeforeAdjustmentsBeforeOrders < errorRange[1]) {
        withinErrorRange = true;
    }

    if (totalNavBeforeAdjustmentsBeforeOrders > narrowRange[0] && totalNavBeforeAdjustmentsBeforeOrders < narrowRange[1]) {
        withinNarrowRange = true;
    }

    const result: NavRange = {
        errorRange,
        narrowRange,
        withinErrorRange,
        withinNarrowRange,
        absHighTolerancePercent: highTolerance,
        absLowTolerancePercent: lowTolerance
    };

    return result;
};
