import React from "react";
import { numberFormatFun, numberFormat, TimeSeries, AlignMethod } from "../../common/src";
import { FactTable } from "./index";
import { Language } from "./Language";

const format = numberFormatFun("0.0%");
const integerFormat = numberFormatFun("0.00");

export enum PerformanceKeyEnum {
    Value = "Value",
    YearlyReturn = "YearlyReturn",
    Return = "Return",
    Volatility = "Volatility",
    ShareOfPositiveMonths = "ShareOfPositiveMonths",
    Correlation = "Correlation",
    Beta = "Beta",
    TrackingError = "TrackingError",
    InformationRatio = "InformationRatio",
    SharpeRatio = "SharpeRatio"
}

const PerformanceKeyEnglish = {
    [PerformanceKeyEnum.Value]: "Value",
    [PerformanceKeyEnum.YearlyReturn]: "Yearly return",
    [PerformanceKeyEnum.Return]: "Return",
    [PerformanceKeyEnum.Volatility]: "Volatility",
    [PerformanceKeyEnum.ShareOfPositiveMonths]: "Share of positive months",
    [PerformanceKeyEnum.Correlation]: "Correlation",
    [PerformanceKeyEnum.Beta]: "Beta",
    [PerformanceKeyEnum.TrackingError]: "Tracking error",
    [PerformanceKeyEnum.InformationRatio]: "Information ratio",
    [PerformanceKeyEnum.SharpeRatio]: "Sharpe ratio"
};

const PerformanceKeySwedish = {
    [PerformanceKeyEnum.Value]: "Värde",
    [PerformanceKeyEnum.YearlyReturn]: "Årsavkastning",
    [PerformanceKeyEnum.Return]: "Enkel avkastning",
    [PerformanceKeyEnum.Volatility]: "Volatilitet",
    [PerformanceKeyEnum.ShareOfPositiveMonths]: "Andel positiva månader",
    [PerformanceKeyEnum.Correlation]: "Korrelation",
    [PerformanceKeyEnum.Beta]: "Beta",
    [PerformanceKeyEnum.TrackingError]: "Aktiv risk",
    [PerformanceKeyEnum.InformationRatio]: "Informationskvot",
    [PerformanceKeyEnum.SharpeRatio]: "Sharpekvot"
};

const translatePerformanceKey = (locale: Language, input: PerformanceKeyEnum): string => {
    if (locale === Language.Sv) {
        return PerformanceKeySwedish[input];
    }
    const result = PerformanceKeyEnglish[input];
    return result;
};

type KeyPerformanceIndicatorsInput = {
    masterTimeSeriesName: string;
    timeseries: TimeSeries[];
    navValue?: number;
    riskFreeTimeSeries?: TimeSeries;
    locale?: Language;
    showItems?: PerformanceKeyEnum[];
};

export const KeyPerformanceIndicators = ({
    masterTimeSeriesName,
    timeseries,
    navValue,
    riskFreeTimeSeries,
    locale = Language.Sv,
    showItems = [
        PerformanceKeyEnum.Value,
        PerformanceKeyEnum.YearlyReturn,
        PerformanceKeyEnum.Return,
        PerformanceKeyEnum.Volatility,
        PerformanceKeyEnum.ShareOfPositiveMonths,
        PerformanceKeyEnum.Correlation,
        PerformanceKeyEnum.Beta,
        PerformanceKeyEnum.TrackingError,
        PerformanceKeyEnum.InformationRatio,
        PerformanceKeyEnum.SharpeRatio
    ]
}: KeyPerformanceIndicatorsInput): React.ReactElement => {
    const masterTs = timeseries.filter((e) => e.name === masterTimeSeriesName)[0];
    const masterTsResampled = masterTs.resampleToCalenderMonthly();

    const list = [{ key: null, values: timeseries.map((ts) => ts.name) }];

    let sharpeRatioFund;

    if (riskFreeTimeSeries) {
        const alignedriskFreeTimeSeries = TimeSeries.align(masterTs, riskFreeTimeSeries, AlignMethod.Latest);
        const fundVsRiskFree = masterTs
            .resampleToCalenderMonthly()
            .return()
            .subtract(alignedriskFreeTimeSeries.resampleToCalenderMonthly().return())
            .add(1)
            .cumProd();
        sharpeRatioFund = integerFormat(fundVsRiskFree.sharpeRatio());
    }

    const correlation: string[] = [];
    const beta: string[] = [];
    const trackingError: string[] = [];
    const value: string[] = [];
    const informationRatio: string[] = [];
    const sharpeRatio: string[] = [];
    const annualizedReturn: string[] = [];
    const simpleReturn: number[] = [];
    //const nonAnnualizedReturn: string[] = [];
    for (let i = 0; i < timeseries.length; i++) {
        const timeSerie = timeseries[i].resampleToCalenderMonthly();
        if (timeSerie.name === masterTs.name) {
            correlation[i] = "";
            beta[i] = "";
            trackingError[i] = "";
            value[i] = numberFormat(navValue, "# ##0");
            informationRatio[i] = "";
            sharpeRatio[i] = sharpeRatioFund ? sharpeRatioFund : integerFormat(masterTsResampled.sharpeRatio());
            //nonAnnualizedReturn[i] = format(masterTsResampled.valueReturn());
            annualizedReturn[i] = format(masterTsResampled.annualizedReturn());
        } else {
            const benchMarkReturn = timeSerie.annualizedReturn();
            correlation[i] = integerFormat(timeSerie.correlation(masterTsResampled));
            trackingError[i] = format(timeSerie.trackingError(masterTsResampled));
            beta[i] = integerFormat((timeSerie.correlation(masterTsResampled) * masterTsResampled.volatility()) / timeSerie.volatility());
            value[i] = "";
            informationRatio[i] = integerFormat(masterTsResampled.informationRatio(timeSerie));
            sharpeRatio[i] = integerFormat(timeSerie.sharpeRatio());
            //nonAnnualizedReturn[i] = format(timeSerie.valueReturn());
            annualizedReturn[i] = format(benchMarkReturn) + " [" + format(masterTsResampled.annualizedReturn() - benchMarkReturn) + "]";
        }

        simpleReturn[i] = timeSerie.valueReturn();
    }

    if (showItems.includes(PerformanceKeyEnum.Value)) {
        list.push({ key: translatePerformanceKey(locale, PerformanceKeyEnum.Value), values: value });
    }
    if (showItems.includes(PerformanceKeyEnum.YearlyReturn)) {
        list.push({
            key: translatePerformanceKey(locale, PerformanceKeyEnum.YearlyReturn),
            values: annualizedReturn
        });
    }
    if (showItems.includes(PerformanceKeyEnum.Return)) {
        list.push({
            key: translatePerformanceKey(locale, PerformanceKeyEnum.Return),
            values: timeseries.map((ts) => format(ts.resampleToCalenderMonthly().valueReturn()).toString())
        });
    }
    if (showItems.includes(PerformanceKeyEnum.Volatility)) {
        list.push({
            key: translatePerformanceKey(locale, PerformanceKeyEnum.Volatility),
            values: timeseries.map((ts) => format(ts.resampleToCalenderMonthly().volatility()).toString())
        });
    }
    if (showItems.includes(PerformanceKeyEnum.ShareOfPositiveMonths)) {
        list.push({
            key: translatePerformanceKey(locale, PerformanceKeyEnum.ShareOfPositiveMonths),
            values: timeseries.map((ts) => format(ts.resampleToMonthly().positiveShare()))
        });
    }
    if (showItems.includes(PerformanceKeyEnum.Correlation)) {
        list.push({ key: translatePerformanceKey(locale, PerformanceKeyEnum.Correlation), values: correlation });
    }
    if (showItems.includes(PerformanceKeyEnum.TrackingError)) {
        list.push({ key: translatePerformanceKey(locale, PerformanceKeyEnum.TrackingError), values: trackingError });
    }
    if (showItems.includes(PerformanceKeyEnum.InformationRatio)) {
        list.push({ key: translatePerformanceKey(locale, PerformanceKeyEnum.InformationRatio), values: informationRatio });
    }
    if (showItems.includes(PerformanceKeyEnum.SharpeRatio)) {
        list.push({ key: translatePerformanceKey(locale, PerformanceKeyEnum.SharpeRatio), values: sharpeRatio });
    }

    return <FactTable className="firstheader" list={list} />;
};
