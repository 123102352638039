import { keyBy, round } from "lodash";
import {
    AccountingBatchType,
    AccountingTransactionType,
    AgreementType,
    CountryCodeEnum,
    CurrencyEnum,
    Customer,
    FxValuation,
    Instrument,
    InstrumentModelTypeEnum,
    InstrumentProductTypeEnum,
    IssuerProgram,
    JournalEntry,
    Party,
    PartyExternalAccountType,
    PortfolioPerformance2,
    ShareRegisterItem,
    SwedishFinancialAuthorityCategoryEnum,
    TrueFalseNone
} from "../../types.generated";

export enum FiTypeEnum {
    SwedishHousingInstitute = "SwedishHousingInstitute",
    SwedishBank = "SwedishBank",
    SwedishFinancialServicesCompany = "SwedishFinancialServicesCompany",
    SwedishInsuranceCompany = "SwedishInsuranceCompany",
    SwedishGovernment = "SwedishGovernment",
    SwedishRegionalGovernment = "SwedishRegionalGovernment",
    SwedishNonFinancialCompany = "SwedishNonFinancialCompany",
    Emu = "Emu",
    OtherEu = "OtherEu",
    OtherForeign = "OtherForeign"
}

export const getFiTypeForBond = (issuer: Partial<Party>, issuerProgram: Partial<IssuerProgram>): FiTypeEnum => {
    // start to check if SE
    if (issuer.country === CountryCodeEnum.SE) {
        if (issuerProgram && issuerProgram.covered === TrueFalseNone.True) {
            return FiTypeEnum.SwedishHousingInstitute;
        } else {
            if (issuer.industryCode === "1410") {
                // BICS 1410 = Banking
                return FiTypeEnum.SwedishBank;
            } else if (issuer.industryCode === "1411") {
                // BICS 1411 = Financial Services
                return FiTypeEnum.SwedishFinancialServicesCompany;
            } else if (issuer.industryCode === "1412") {
                // BICS 1412 = Insurance
                return FiTypeEnum.SwedishInsuranceCompany;
            } else if (issuer.industryCode === "5011") {
                // BICS 5011 = Government Agencies
                return FiTypeEnum.SwedishGovernment;
            } else if (issuer.industryCode === "5012") {
                // BICS 5012 = Government Regional
                return FiTypeEnum.SwedishRegionalGovernment;
            } else {
                return FiTypeEnum.SwedishNonFinancialCompany;
            }
        }
    } else if (EMU_COUNTRIES[issuer.country]) {
        return FiTypeEnum.Emu;
    } // then EU
    else if (EU_COUNTRIES[issuer.country]) {
        return FiTypeEnum.OtherEu;
    } // foreign
    else {
        return FiTypeEnum.OtherForeign;
    }
};

const EMU_COUNTRIES = {
    [CountryCodeEnum.AT]: true,
    [CountryCodeEnum.BE]: true,
    [CountryCodeEnum.HR]: true,
    [CountryCodeEnum.CY]: true,
    [CountryCodeEnum.EE]: true,
    [CountryCodeEnum.FI]: true,
    [CountryCodeEnum.FR]: true,
    [CountryCodeEnum.DE]: true,
    [CountryCodeEnum.GR]: true,
    [CountryCodeEnum.IE]: true,
    [CountryCodeEnum.IT]: true,
    [CountryCodeEnum.LV]: true,
    [CountryCodeEnum.LT]: true,
    [CountryCodeEnum.LU]: true,
    [CountryCodeEnum.MT]: true,
    [CountryCodeEnum.NL]: true,
    [CountryCodeEnum.PT]: true,
    [CountryCodeEnum.SK]: true,
    [CountryCodeEnum.SI]: true,
    [CountryCodeEnum.ES]: true
};

const EU_COUNTRIES = {
    [CountryCodeEnum.AT]: true,
    [CountryCodeEnum.BE]: true,
    [CountryCodeEnum.HR]: true,
    [CountryCodeEnum.CY]: true,
    [CountryCodeEnum.EE]: true,
    [CountryCodeEnum.FI]: true,
    [CountryCodeEnum.FR]: true,
    [CountryCodeEnum.DE]: true,
    [CountryCodeEnum.GR]: true,
    [CountryCodeEnum.IE]: true,
    [CountryCodeEnum.IT]: true,
    [CountryCodeEnum.LV]: true,
    [CountryCodeEnum.LT]: true,
    [CountryCodeEnum.LU]: true,
    [CountryCodeEnum.MT]: true,
    [CountryCodeEnum.NL]: true,
    [CountryCodeEnum.PT]: true,
    [CountryCodeEnum.SK]: true,
    [CountryCodeEnum.SI]: true,
    [CountryCodeEnum.ES]: true,
    [CountryCodeEnum.BG]: true,
    [CountryCodeEnum.CZ]: true,
    [CountryCodeEnum.DK]: true,
    [CountryCodeEnum.HU]: true,
    [CountryCodeEnum.PL]: true,
    [CountryCodeEnum.RO]: true,
    [CountryCodeEnum.SE]: true
};

export class FiDataNumber {
    #value: number;
    readonly description: string;
    readonly field: string;

    constructor(field: string, description: string, value = 0) {
        // Init to zero
        this.#value = value;
        this.description = description;
        this.field = field;
    }

    toJson(): any {
        const json: any = {};
        json[this.field] = this.#value;
        return json;
    }
    add(value: number) {
        this.#value += value;
    }

    round(precision = 0) {
        // round value to integer
        this.#value = round(this.#value, precision);
    }

    get value() {
        return this.#value;
    }

    set value(x: number) {
        this.#value = x;
    }
}

export class FiDataString {
    #value: string;
    readonly description: string;
    readonly field: string;

    constructor(field: string, description: string, value = "") {
        // Init to empty string
        this.#value = value;
        this.description = description;
        this.field = field;
    }

    toJson(): any {
        const json: any = {};
        json[this.field] = this.#value;
        return json;
    }

    get value() {
        return this.#value;
    }

    set value(x: string) {
        this.#value = x;
    }
}

class BalanceSheet {
    //====================
    // Balance Sheet - B
    //====================

    //--------------
    // Assets
    //--------------
    // cash
    #cash: FiDataNumber;
    #sumCash: FiDataNumber;
    // financial instruments
    #sumGovernmentDebtObligations: FiDataNumber;
    #sumBonds: FiDataNumber;
    #sumAssetStocks: FiDataNumber;
    #sumAssetDerivatives: FiDataNumber;
    #sumAssetFinancialInstruments: FiDataNumber;
    // asset derivatives spec
    #swedishBankDerivatives: FiDataNumber;
    #swedishFinancialServicesCompanyDerivatives: FiDataNumber;
    #swedishInsuranceCompanyDerivatives: FiDataNumber;
    #swedishNonFinancialCompanyDerivatives: FiDataNumber;
    #sumForeignDerivatives: FiDataNumber;
    #emuDerivatives: FiDataNumber;
    #otherEuDerivatives: FiDataNumber;
    #otherForeignDerivatives: FiDataNumber;
    // receivables
    #otherReceivables: FiDataNumber;
    #ofWhichFundSubscriptions: FiDataNumber;
    #sumReceivables: FiDataNumber;
    // assets
    #sumAssets: FiDataNumber;
    //--------------
    // Liabilities
    //--------------
    #sumLiabilityDerivatives: FiDataNumber;
    // liability derivatives spec
    #liabilitySwedishBankDerivatives: FiDataNumber;
    #liabilitySwedishFinancialServicesCompanyDerivatives: FiDataNumber;
    #liabilitySwedishInsuranceCompanyDerivatives: FiDataNumber;
    #liabilitySwedishNonFinancialCompanyDerivatives: FiDataNumber;
    #sumLiabilityForeignDerivatives: FiDataNumber;
    #liabilityEmuDerivatives: FiDataNumber;
    #liabilityOtherEuDerivatives: FiDataNumber;
    #liabilityOtherForeignDerivatives: FiDataNumber;
    // other liabilities
    #otherLiabilities: FiDataNumber;
    #ofWhichFundRedemptions: FiDataNumber;
    // total liabilities
    #sumLiabilities: FiDataNumber;
    // fund
    #fundHouseholds: FiDataNumber;
    #sumFundSwedishFinancialInstitutions: FiDataNumber;
    #fundSwedishBanks: FiDataNumber;
    #fundSwedishHousingInstitutes: FiDataNumber;
    #fundSwedishInsuranceCompanies: FiDataNumber;
    #fundSwedishPensionInstitutes: FiDataNumber;
    #fundOtherSwedishFinancialInstitutions: FiDataNumber;
    #fundSwedishMunicipalAdministration: FiDataNumber;
    #fundSwedishSocialSecurityFunds: FiDataNumber;
    #fundOfWhichThePensionsAuthority: FiDataNumber;
    #sumFundForeign: FiDataNumber;
    #fundEmu: FiDataNumber;
    #fundEu: FiDataNumber;
    #fundOtherForeign: FiDataNumber;
    #sumFund: FiDataNumber;
    #sumFundAndLiabilities: FiDataNumber;
    // market value - D
    #swedishGovernmentBonds: FiDataNumber;
    #swedishRegionalGovernmentBonds: FiDataNumber;
    // bonds
    #swedishBankBonds: FiDataNumber;
    #swedishHousingInstituteBonds: FiDataNumber;
    #swedishFinancialServicesCompanyBonds: FiDataNumber;
    #swedishInsuranceCompanyBonds: FiDataNumber;
    #swedishNonFinancialCompanyBonds: FiDataNumber;
    #otherSwedishBorrowers: FiDataNumber;
    #sumForeignBorrowers: FiDataNumber;
    #emuBorrowers: FiDataNumber;
    #otherEuBorrowers: FiDataNumber;
    #otherForeignBorrowers: FiDataNumber;
    // stocks
    #swedishBankStocks: FiDataNumber;
    #swedishInsuranceCompanyStocks: FiDataNumber;
    #otherSwedishFinancialInstitutionStocks: FiDataNumber;
    #swedishNonFinancialInstitutionStocks: FiDataNumber;
    #sumForeignStocks: FiDataNumber;
    #emuStocks: FiDataNumber;
    #euStocks: FiDataNumber;
    #otherForeignStocks: FiDataNumber;
    #sumStocks: FiDataNumber;
    #sumDerivatives: FiDataNumber;
    #sumFinancialInstruments: FiDataNumber;

    constructor() {
        //====================
        // Balance Sheet - B
        //====================

        //--------------
        // Assets
        //--------------
        // cash
        this.#cash = new FiDataNumber("DP-FPSJ-1VQV", "Kassa och bank (som ej ingår i placeringsinriktningen) [B1]");
        this.#sumCash = new FiDataNumber("DP-JOHO-RKYI", "Summa likvida medel (B1 : B2) [B11]");
        this.#sumGovernmentDebtObligations = new FiDataNumber("DP-LE8I-GGWQ", "Belåningsbara statsskuldförbindelser m.m. [B13]");
        this.#sumBonds = new FiDataNumber("DP-NBDX-GTFO", "Obligationer och andra räntebärande värdepapper [B16]");
        this.#sumAssetStocks = new FiDataNumber(
            "DP-RZ2D-UMWG",
            "Aktier, såväl enligt 5 kap. 3 § som 5 kap. 5 § lagen om värdepappersfonder [B18]"
        );
        this.#sumAssetDerivatives = new FiDataNumber("DP-6SQA-DTXE", "Finansiella derivatinstrument [B22]");
        this.#swedishBankDerivatives = new FiDataNumber("DP-AFAH-SVV8", "Svenska banker [B26]");
        this.#swedishFinancialServicesCompanyDerivatives = new FiDataNumber("DP-KB0R-BVLR", "Övriga svenska kreditmarknadsbolag [B28]");
        this.#swedishInsuranceCompanyDerivatives = new FiDataNumber("DP-14BP-DQHY", "Svenska försäkringsbolag [B29]");
        this.#swedishNonFinancialCompanyDerivatives = new FiDataNumber("DP-LX2O-WAZZ", "Svenska icke-finansiella bolag [B31]");
        this.#sumForeignDerivatives = new FiDataNumber("DP-8A31-MHPK", "Utländska motparter [B33]");
        this.#emuDerivatives = new FiDataNumber("DP-J8H7-FR0N", "EMU [B34]");
        this.#otherEuDerivatives = new FiDataNumber("DP-GV5S-IV5I", "Övriga EU [B35]");
        this.#otherForeignDerivatives = new FiDataNumber("DP-FIE9-JKCK", "Övriga utlandet [B36]");
        this.#sumAssetFinancialInstruments = new FiDataNumber(
            "DP-OVFW-DV65",
            "Summa finansiella instrument (B12 : B13 + B15 : B16 + B18 : B19 + B22 + B37) [B38]"
        );
        this.#otherReceivables = new FiDataNumber("DP-QGRS-YMBP", "Övriga fordringar [B42]");
        this.#ofWhichFundSubscriptions = new FiDataNumber("DP-1JF9-FSUZ", "varav fondlikvidfordringar [B43]");
        this.#sumReceivables = new FiDataNumber("DP-9ETI-IFZV", "Summa fordringar (B39 + B41 : B42) [B46]");
        this.#sumAssets = new FiDataNumber("DP-XJ0F-6KUJ", "Summa tillgångar (B11 + B38 + B46) [B47]");
        this.#sumLiabilityDerivatives = new FiDataNumber("DP-0AUO-HUWZ", "Finansiella derivatinstrument (B53 : B63) [B52]");
        this.#liabilitySwedishBankDerivatives = new FiDataNumber("DP-TZ1O-5Q1Y", "Svenska banker [B56]");
        this.#liabilitySwedishFinancialServicesCompanyDerivatives = new FiDataNumber(
            "DP-16RO-G1VO",
            "Övriga svenska kreditmarknadsbolag [B58]"
        );
        this.#liabilitySwedishInsuranceCompanyDerivatives = new FiDataNumber("DP-GZX1-ZXW8", "Svenska försäkringsbolag [B59]");
        this.#liabilitySwedishNonFinancialCompanyDerivatives = new FiDataNumber("DP-4RHP-VWUI", "Svenska icke-finansiella bolag [B61]");
        this.#sumLiabilityForeignDerivatives = new FiDataNumber("DP-KUO5-V76Z", "Utländska motparter (B64 : B66) [B63]");
        this.#liabilityEmuDerivatives = new FiDataNumber("DP-WVRC-92KD", "EMU [B64]");
        this.#liabilityOtherEuDerivatives = new FiDataNumber("DP-KZHK-B5JV", "Övriga EU [B65]");
        this.#liabilityOtherForeignDerivatives = new FiDataNumber("DP-3OXI-13PT", "Övriga utlandet [B66]");
        this.#otherLiabilities = new FiDataNumber("DP-G738-ZKUO", "Övriga skulder [B68]");
        this.#ofWhichFundRedemptions = new FiDataNumber("DP-CONU-12LS", "varav fondlikvidskulder [B71]");
        this.#sumLiabilities = new FiDataNumber("DP-4JQA-MAZI", "Summa skulder (B48 : B50 + B52 + B67 : B68) [B73]");
        this.#fundHouseholds = new FiDataNumber("DP-1T5J-UQIC", "Svenska hushåll [B74]");
        this.#sumFundSwedishFinancialInstitutions = new FiDataNumber("DP-BRW1-SZDY", "Svenska finansiella bolag [B77]");
        this.#fundSwedishBanks = new FiDataNumber(
            "DP-2YFA-OTBO",
            "Banker (utom centralbanker) samt bankfilialer till banker i utlandet [B78]"
        );
        this.#fundSwedishHousingInstitutes = new FiDataNumber("DP-MXSO-KGBT", "Bostadsinstitut [B79]");
        this.#fundSwedishInsuranceCompanies = new FiDataNumber("DP-1PIP-ZHTH", "Försäkringsbolag [B88]");
        this.#fundSwedishPensionInstitutes = new FiDataNumber("DP-PFFR-COEU", "Pensionsinstitut [B90]");
        this.#fundOtherSwedishFinancialInstitutions = new FiDataNumber("DP-ELIH-P4KN", "Övriga svenska finansiella bolag [B96]");
        this.#fundSwedishMunicipalAdministration = new FiDataNumber("DP-AQAP-5RB0", "Kommunal förvaltning [B98]");
        this.#fundSwedishSocialSecurityFunds = new FiDataNumber("DP-WSOZ-RPR2", "Sociala trygghetsfonder [B99]");
        this.#fundOfWhichThePensionsAuthority = new FiDataNumber("DP-6SMB-VSJQ", "varav Pensionsmyndigheten [B100]");
        this.#sumFundForeign = new FiDataNumber("DP-O1CJ-PI5W", "Utländska innehavare [B102]");
        this.#fundEmu = new FiDataNumber("DP-9WHI-3XEY", "EMU [B103]");
        this.#fundEu = new FiDataNumber("DP-YIEX-RYWL", "Övriga EU [B104]");
        this.#fundOtherForeign = new FiDataNumber("DP-PXZ6-HUY1", "Övriga utlandet [B105]");
        this.#sumFund = new FiDataNumber("DP-UIWX-99OX", "Summa fondens värde (B74 + B76 : B77 + B97 : B99 + B101 : B102) [B106]");
        this.#sumFundAndLiabilities = new FiDataNumber("DP-YTQS-EG87", "Summa skulder och fondens värde (B73 + B106) (B107 = B47) [B107]");

        //====================
        // Market value - D
        //====================

        this.#swedishGovernmentBonds = new FiDataNumber("DP-WXBF-DD5X", "Svenska statsobligationer [D3]");
        this.#swedishRegionalGovernmentBonds = new FiDataNumber("DP-BRCU-VSET", "Svenska kommunobligationer [D6]");
        //
        this.#swedishBankBonds = new FiDataNumber("DP-PMSP-YEFO", "Svenska banker [D26]");
        this.#swedishHousingInstituteBonds = new FiDataNumber("DP-B27S-DXL8", "Svenska bostadsinstitut [D27]");
        this.#swedishFinancialServicesCompanyBonds = new FiDataNumber("DP-VEIR-GJSW", "Övriga svenska kreditmarknadsbolag [D28]");
        this.#swedishInsuranceCompanyBonds = new FiDataNumber("DP-OHXB-TAAG", "Svenska försäkringsbolag [D29]");
        this.#swedishNonFinancialCompanyBonds = new FiDataNumber("DP-NKDZ-ZMRV", "Svenska icke-finansiella bolag [D30]");
        this.#otherSwedishBorrowers = new FiDataNumber("DP-QSVK-MEU4", "Övriga svenska låntagare [D31]");
        this.#sumForeignBorrowers = new FiDataNumber("DP-JT2X-KPR4", "Utländska låntagare [D32]");
        this.#emuBorrowers = new FiDataNumber("DP-XNUI-NLGU", "EMU [D33]");
        this.#otherEuBorrowers = new FiDataNumber("DP-NFS0-XHQY", "Övriga EU [D34]");
        this.#otherForeignBorrowers = new FiDataNumber("DP-IJ7S-FOJO", "Övriga utlandet [D35]");
        this.#swedishBankStocks = new FiDataNumber("DP-RZ0J-6EY0", "Svenska banker [D37]");
        this.#swedishInsuranceCompanyStocks = new FiDataNumber("DP-OZGI-SBDO", "Svenska försäkringsbolag [D40]");
        this.#otherSwedishFinancialInstitutionStocks = new FiDataNumber("DP-G33T-03BP", "Övriga svenska finansiella bolag [D41]");
        this.#swedishNonFinancialInstitutionStocks = new FiDataNumber("DP-GR7Y-WICU", "Svenska icke-finansiella bolag [D42]");
        this.#sumForeignStocks = new FiDataNumber("DP-JWUV-UBUR", "Utländska företag [D43]");
        this.#emuStocks = new FiDataNumber("DP-O3YD-QBVD", "EMU [D44]");
        this.#euStocks = new FiDataNumber("DP-EYD6-MQ9N", "Övriga EU [D45]");
        this.#otherForeignStocks = new FiDataNumber("DP-MAY0-EBSN", "Övriga utlandet [D46]");
        this.#sumStocks = new FiDataNumber("DP-MBUX-P81M", "Summa aktier (D36 : D43) [D47]");
        this.#sumDerivatives = new FiDataNumber("DP-OINK-Z3ZE", "Finansiella derivatinstrument, netto [D68]");
        this.#sumFinancialInstruments = new FiDataNumber(
            "DP-I2SO-E2CO",
            "Summa finansiella instrument inklusive finansiella derivatinstrument, netto (B13 + B15 + B16 + D47 + D60 + D61 + B19 + D68 + B37 + B12) [D69]"
        );
    }

    // getters
    get sumFundAndLiabilities() {
        this.calculateSums();
        return this.#sumFundAndLiabilities;
    }

    get sumAssets() {
        this.calculateSums();
        return this.#sumAssets;
    }

    // add methods
    addToCash(value: number) {
        this.#cash.add(value);
    }
    addToStocks(issuer: Partial<Party>, value: number) {
        // start to check if SE
        if (issuer.country === CountryCodeEnum.SE) {
            if (issuer.industryCode === "1410") {
                // BICS 1410 - Banking
                this.#swedishBankStocks.add(value);
            } else if (issuer.industryCode === "1411") {
                // BICS 1411 - Financial Services
                this.#otherSwedishFinancialInstitutionStocks.add(value);
            } else if (issuer.industryCode === "1412") {
                // BICS 1412 = Insurance
                this.#swedishInsuranceCompanyStocks.add(value);
            } else {
                // otherwise non finacial
                this.#swedishNonFinancialInstitutionStocks.add(value);
            }
        } else if (EMU_COUNTRIES[issuer.country]) {
            this.#emuStocks.add(value);
        } // then EU
        else if (EU_COUNTRIES[issuer.country]) {
            this.#euStocks.add(value);
        } // foreign
        else {
            this.#otherForeignStocks.add(value);
        }
    }
    addToBonds(issuer: Partial<Party>, issuerProgram: Partial<IssuerProgram>, value: number) {
        const fiType = getFiTypeForBond(issuer, issuerProgram);
        if (fiType === FiTypeEnum.SwedishHousingInstitute) {
            this.#swedishHousingInstituteBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishBank) {
            this.#swedishBankBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishFinancialServicesCompany) {
            this.#swedishFinancialServicesCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishInsuranceCompany) {
            this.#swedishInsuranceCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishGovernment) {
            this.#swedishGovernmentBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishRegionalGovernment) {
            this.#swedishRegionalGovernmentBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishNonFinancialCompany) {
            this.#swedishNonFinancialCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.Emu) {
            this.#emuBorrowers.add(value);
        } else if (fiType === FiTypeEnum.OtherEu) {
            this.#otherEuBorrowers.add(value);
        } else if (fiType === FiTypeEnum.OtherForeign) {
            this.#otherForeignBorrowers.add(value);
        } else {
            throw new Error("Unknow FiType: " + fiType);
        }
    }
    addToDerivatives(custodian: Partial<Party>, value: number) {
        // start to check if SE
        if (custodian.country === CountryCodeEnum.SE) {
            if (custodian.industryCode === "1410") {
                // BICS 1410 = Banking
                if (value < 0) {
                    this.#liabilitySwedishBankDerivatives.add(-value);
                } else {
                    this.#swedishBankDerivatives.add(value);
                }
            } else if (custodian.industryCode === "1411") {
                if (value < 0) {
                    this.#liabilitySwedishFinancialServicesCompanyDerivatives.add(-value);
                } else {
                    this.#swedishFinancialServicesCompanyDerivatives.add(value);
                }
            } else if (custodian.industryCode === "1412") {
                // BICS 1412 = Insurance
                if (value < 0) {
                    this.#liabilitySwedishInsuranceCompanyDerivatives.add(-value);
                } else {
                    this.#swedishInsuranceCompanyDerivatives.add(value);
                }
            } else {
                if (value < 0) {
                    this.#liabilitySwedishNonFinancialCompanyDerivatives.add(-value);
                } else {
                    this.#swedishNonFinancialCompanyDerivatives.add(value);
                }
            }
        } else if (EMU_COUNTRIES[custodian.country]) {
            if (value < 0) {
                this.#liabilityEmuDerivatives.add(-value);
            } else {
                this.#emuDerivatives.add(value);
            }
        } // then EU
        else if (EU_COUNTRIES[custodian.country]) {
            if (value < 0) {
                this.#liabilityOtherEuDerivatives.add(-value);
            } else {
                this.#otherEuDerivatives.add(value);
            }
        } // foreign
        else {
            if (value < 0) {
                this.#liabilityOtherForeignDerivatives.add(-value);
            } else {
                this.#otherForeignDerivatives.add(value);
            }
        }
    }

    addToFund(customer: Partial<Customer>, value: number) {
        try {
            if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.Banks) {
                this.#fundSwedishBanks.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.Emu) {
                this.#fundEmu.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherEu) {
                this.#fundEu.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherForeign) {
                this.#fundOtherForeign.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.HousingInstitutes) {
                this.#fundSwedishHousingInstitutes.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.MunicipalAdministration) {
                this.#fundSwedishMunicipalAdministration.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SocialSecurityFunds) {
                this.#fundSwedishSocialSecurityFunds.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OfWhichThePensionsAuthority) {
                this.#fundOfWhichThePensionsAuthority.add(value);
                // add to social security fonds as well as this is the field used in sums
                this.#fundSwedishSocialSecurityFunds.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SwedishHouseholds) {
                this.#fundHouseholds.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.PensionInstitutes) {
                this.#fundSwedishPensionInstitutes.add(value);
            } else if (
                customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherSwedishFinancialInstitutions
            ) {
                this.#fundOtherSwedishFinancialInstitutions.add(value);
            } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SwedishInsuranceCompanies) {
                this.#fundSwedishInsuranceCompanies.add(value);
            } else {
                // we shall not come here but add to banks which is largest by far
                this.#fundSwedishBanks.add(value);
            }
        } catch (ex) {
            console.error({ customer });
            console.error({ value });
            throw ex;
        }
    }

    addToFundSubscriptions(value: number) {
        this.#ofWhichFundSubscriptions.add(value);
    }
    addToFundRedemtions(value: number) {
        this.#ofWhichFundRedemptions.add(value);
    }
    addToOtherLiabilities(value: number) {
        this.#otherLiabilities.add(value);
    }
    addToOtherReceivables(value: number) {
        this.#otherReceivables.add(value);
    }

    // methods
    toJson(): any {
        // round and calculate sums
        this.round();
        this.calculateSums();
        // put together json
        return {
            ...this.#cash.toJson(),
            ...this.#sumCash.toJson(),
            ...this.#sumGovernmentDebtObligations.toJson(),
            ...this.#sumBonds.toJson(),
            ...this.#sumAssetStocks.toJson(),
            ...this.#sumAssetDerivatives.toJson(),
            ...this.#swedishBankDerivatives.toJson(),
            ...this.#swedishFinancialServicesCompanyDerivatives.toJson(),
            ...this.#swedishInsuranceCompanyDerivatives.toJson(),
            ...this.#swedishNonFinancialCompanyDerivatives.toJson(),
            ...this.#sumForeignDerivatives.toJson(),
            ...this.#emuDerivatives.toJson(),
            ...this.#otherEuDerivatives.toJson(),
            ...this.#otherForeignDerivatives.toJson(),
            ...this.#sumAssetFinancialInstruments.toJson(),
            ...this.#otherReceivables.toJson(),
            ...this.#ofWhichFundSubscriptions.toJson(),
            ...this.#sumReceivables.toJson(),
            ...this.#sumAssets.toJson(),
            ...this.#sumLiabilityDerivatives.toJson(),
            ...this.#liabilitySwedishBankDerivatives.toJson(),
            ...this.#liabilitySwedishFinancialServicesCompanyDerivatives.toJson(),
            ...this.#liabilitySwedishInsuranceCompanyDerivatives.toJson(),
            ...this.#liabilitySwedishNonFinancialCompanyDerivatives.toJson(),
            ...this.#sumLiabilityForeignDerivatives.toJson(),
            ...this.#liabilityEmuDerivatives.toJson(),
            ...this.#liabilityOtherEuDerivatives.toJson(),
            ...this.#liabilityOtherForeignDerivatives.toJson(),
            ...this.#otherLiabilities.toJson(),
            ...this.#ofWhichFundRedemptions.toJson(),
            ...this.#sumLiabilities.toJson(),
            ...this.#fundHouseholds.toJson(),
            ...this.#sumFundSwedishFinancialInstitutions.toJson(),
            ...this.#fundSwedishBanks.toJson(),
            ...this.#fundSwedishHousingInstitutes.toJson(),
            ...this.#fundSwedishInsuranceCompanies.toJson(),
            ...this.#fundSwedishPensionInstitutes.toJson(),
            ...this.#fundOtherSwedishFinancialInstitutions.toJson(),
            ...this.#fundSwedishMunicipalAdministration.toJson(),
            ...this.#fundSwedishSocialSecurityFunds.toJson(),
            ...this.#fundOfWhichThePensionsAuthority.toJson(),
            ...this.#sumFundForeign.toJson(),
            ...this.#fundEmu.toJson(),
            ...this.#fundEu.toJson(),
            ...this.#fundOtherForeign.toJson(),
            ...this.#sumFund.toJson(),
            ...this.#sumFundAndLiabilities.toJson(),
            ...this.#swedishGovernmentBonds.toJson(),
            ...this.#swedishRegionalGovernmentBonds.toJson(),
            ...this.#swedishBankBonds.toJson(),
            ...this.#swedishHousingInstituteBonds.toJson(),
            ...this.#swedishFinancialServicesCompanyBonds.toJson(),
            ...this.#swedishInsuranceCompanyBonds.toJson(),
            ...this.#swedishNonFinancialCompanyBonds.toJson(),
            ...this.#otherSwedishBorrowers.toJson(),
            ...this.#sumForeignBorrowers.toJson(),
            ...this.#emuBorrowers.toJson(),
            ...this.#otherEuBorrowers.toJson(),
            ...this.#otherForeignBorrowers.toJson(),
            ...this.#swedishBankStocks.toJson(),
            ...this.#swedishInsuranceCompanyStocks.toJson(),
            ...this.#otherSwedishFinancialInstitutionStocks.toJson(),
            ...this.#swedishNonFinancialInstitutionStocks.toJson(),
            ...this.#sumForeignStocks.toJson(),
            ...this.#emuStocks.toJson(),
            ...this.#euStocks.toJson(),
            ...this.#otherForeignStocks.toJson(),
            ...this.#sumStocks.toJson(),
            ...this.#sumDerivatives.toJson(),
            ...this.#sumFinancialInstruments.toJson()
        };
    }

    adjustFundToAlignWithTotalAssets(adjustment: number): void {
        // TODO - more elegant than fixed bucket...
        // we add to this.#fundBanks
        this.#fundSwedishBanks.add(adjustment);
        this.calculateSums();
    }

    calculateSums(): void {
        // asset sums
        const sumCash = this.#cash.value;
        //gov
        const sumGovernmentDebtObligations = this.#swedishGovernmentBonds.value + this.#swedishRegionalGovernmentBonds.value;
        // bonds
        const sumForeignBorrowers = this.#emuBorrowers.value + this.#otherEuBorrowers.value + this.#otherForeignBorrowers.value;
        const sumBonds =
            this.#swedishBankBonds.value +
            this.#swedishHousingInstituteBonds.value +
            this.#swedishFinancialServicesCompanyBonds.value +
            this.#swedishInsuranceCompanyBonds.value +
            this.#swedishNonFinancialCompanyBonds.value +
            this.#otherSwedishBorrowers.value +
            sumForeignBorrowers;
        // stocks
        const sumForeignStocks = this.#emuStocks.value + this.#euStocks.value + this.#otherForeignStocks.value;
        const sumStocks =
            this.#swedishBankStocks.value +
            this.#swedishInsuranceCompanyStocks.value +
            this.#otherSwedishFinancialInstitutionStocks.value +
            this.#swedishNonFinancialInstitutionStocks.value +
            sumForeignStocks;
        // asset derivitavives
        const sumDerivativesForeign = this.#emuDerivatives.value + this.#otherEuDerivatives.value + this.#otherForeignDerivatives.value;
        const sumAssetDerivatives =
            this.#swedishBankDerivatives.value +
            this.#swedishFinancialServicesCompanyDerivatives.value +
            this.#swedishInsuranceCompanyDerivatives.value +
            this.#swedishNonFinancialCompanyDerivatives.value +
            sumDerivativesForeign;
        // asset finacial instruments
        const sumAssetFinancialInstruments = sumGovernmentDebtObligations + sumBonds + sumStocks + sumAssetDerivatives;

        // recievables
        const sumReceivables = this.#otherReceivables.value;
        const sumAssets = sumCash + sumAssetFinancialInstruments + sumReceivables;

        // liabilities derivitavives
        const sumLiabilityDerivativesForeign =
            this.#liabilityEmuDerivatives.value + this.#liabilityOtherEuDerivatives.value + this.#liabilityOtherForeignDerivatives.value;
        const sumLiabilityDerivatives =
            this.#liabilitySwedishBankDerivatives.value +
            this.#liabilitySwedishFinancialServicesCompanyDerivatives.value +
            this.#liabilitySwedishInsuranceCompanyDerivatives.value +
            this.#liabilitySwedishNonFinancialCompanyDerivatives.value +
            sumLiabilityDerivativesForeign;

        // liabilities sum
        const sumLiabilities = sumLiabilityDerivatives + this.#otherLiabilities.value;

        //fund
        const sumFundSwedishFinancialInstitutions =
            this.#fundSwedishBanks.value +
            this.#fundSwedishHousingInstitutes.value +
            this.#fundSwedishInsuranceCompanies.value +
            this.#fundSwedishPensionInstitutes.value +
            this.#fundOtherSwedishFinancialInstitutions.value;

        const sumFundForeign = this.#fundEmu.value + this.#fundEu.value + this.#fundOtherForeign.value;
        const sumFund =
            this.#fundHouseholds.value +
            sumFundSwedishFinancialInstitutions +
            this.#fundSwedishMunicipalAdministration.value +
            this.#fundSwedishSocialSecurityFunds.value +
            sumFundForeign;

        // fund and liabilities
        const sumFundAndLiabilities = sumFund + sumLiabilities;

        // D - sums
        const sumDerivatives = sumAssetDerivatives - sumLiabilityDerivatives;
        const sumFinancialInstruments = sumGovernmentDebtObligations + sumBonds + sumStocks + sumDerivatives;

        // update sums
        // asset sums
        this.#sumCash.value = sumCash;
        //gov
        this.#sumGovernmentDebtObligations.value = sumGovernmentDebtObligations;
        // bonds
        this.#sumForeignBorrowers.value = sumForeignBorrowers;
        this.#sumBonds.value = sumBonds;
        // stocks
        this.#sumForeignStocks.value = sumForeignStocks;
        this.#sumStocks.value = sumStocks;
        this.#sumAssetStocks.value = sumStocks;
        // asset derivitavives
        this.#sumForeignDerivatives.value = sumDerivativesForeign;
        this.#sumAssetDerivatives.value = sumAssetDerivatives;
        // asset finacial instruments
        this.#sumAssetFinancialInstruments.value = sumAssetFinancialInstruments;

        // recievables
        this.#sumReceivables.value = sumReceivables;
        // total assets
        this.#sumAssets.value = sumAssets;

        // liabilities derivitavives
        this.#sumLiabilityForeignDerivatives.value = sumLiabilityDerivativesForeign;
        this.#sumLiabilityDerivatives.value = sumLiabilityDerivatives;

        // liabilities sum
        this.#sumLiabilities.value = sumLiabilities;

        // fund
        this.#sumFundSwedishFinancialInstitutions.value = sumFundSwedishFinancialInstitutions;
        this.#sumFundForeign.value = sumFundForeign;
        this.#sumFund.value = sumFund;

        // fund and liabilities
        this.#sumFundAndLiabilities.value = sumFundAndLiabilities;

        // D - sums
        this.#sumDerivatives.value = sumDerivatives;
        this.#sumFinancialInstruments.value = sumFinancialInstruments;
    }

    round() {
        this.#cash.round();
        this.#sumCash.round();
        this.#sumGovernmentDebtObligations.round();
        this.#sumBonds.round();
        this.#sumAssetStocks.round();
        this.#sumAssetDerivatives.round();
        this.#swedishBankDerivatives.round();
        this.#swedishFinancialServicesCompanyDerivatives.round();
        this.#swedishInsuranceCompanyDerivatives.round();
        this.#swedishNonFinancialCompanyDerivatives.round();
        this.#sumForeignDerivatives.round();
        this.#emuDerivatives.round();
        this.#otherEuDerivatives.round();
        this.#otherForeignDerivatives.round();
        this.#sumAssetFinancialInstruments.round();
        this.#otherReceivables.round();
        this.#ofWhichFundSubscriptions.round();
        this.#sumReceivables.round();
        this.#sumAssets.round();
        this.#sumLiabilityDerivatives.round();
        this.#liabilitySwedishBankDerivatives.round();
        this.#liabilitySwedishFinancialServicesCompanyDerivatives.round();
        this.#liabilitySwedishInsuranceCompanyDerivatives.round();
        this.#liabilitySwedishNonFinancialCompanyDerivatives.round();
        this.#sumLiabilityForeignDerivatives.round();
        this.#liabilityEmuDerivatives.round();
        this.#liabilityOtherEuDerivatives.round();
        this.#liabilityOtherForeignDerivatives.round();
        this.#otherLiabilities.round();
        this.#ofWhichFundRedemptions.round();
        this.#sumLiabilities.round();
        this.#fundHouseholds.round();
        this.#sumFundSwedishFinancialInstitutions.round();
        this.#fundSwedishBanks.round();
        this.#fundSwedishHousingInstitutes.round();
        this.#fundSwedishInsuranceCompanies.round();
        this.#fundSwedishPensionInstitutes.round();
        this.#fundOtherSwedishFinancialInstitutions.round();
        this.#fundSwedishMunicipalAdministration.round();
        this.#fundSwedishSocialSecurityFunds.round();
        this.#fundOfWhichThePensionsAuthority.round();
        this.#sumFundForeign.round();
        this.#fundEmu.round();
        this.#fundEu.round();
        this.#fundOtherForeign.round();
        this.#sumFund.round();
        this.#sumFundAndLiabilities.round();
        this.#swedishGovernmentBonds.round();
        this.#swedishRegionalGovernmentBonds.round();
        this.#swedishBankBonds.round();
        this.#swedishHousingInstituteBonds.round();
        this.#swedishFinancialServicesCompanyBonds.round();
        this.#swedishInsuranceCompanyBonds.round();
        this.#swedishNonFinancialCompanyBonds.round();
        this.#otherSwedishBorrowers.round();
        this.#sumForeignBorrowers.round();
        this.#emuBorrowers.round();
        this.#otherEuBorrowers.round();
        this.#otherForeignBorrowers.round();
        this.#swedishBankStocks.round();
        this.#swedishInsuranceCompanyStocks.round();
        this.#otherSwedishFinancialInstitutionStocks.round();
        this.#swedishNonFinancialInstitutionStocks.round();
        this.#sumForeignStocks.round();
        this.#emuStocks.round();
        this.#euStocks.round();
        this.#otherForeignStocks.round();
        this.#sumStocks.round();
        this.#sumDerivatives.round();
        this.#sumFinancialInstruments.round();
    }
}

class TransactionSpecification {
    //==================
    // Assets - E
    //==================
    #swedishGovernmentBonds: FiDataNumber;
    #sumGovernmentDebtObligations: FiDataNumber;
    #swedishRegionalGovernmentBonds: FiDataNumber;
    #swedishBankBonds: FiDataNumber;
    #swedishHousingInstituteBonds: FiDataNumber;
    #swedishFinancialServicesCompanyBonds: FiDataNumber;
    #swedishInsuranceCompanyBonds: FiDataNumber;
    #swedishNonFinancialCompanyBonds: FiDataNumber;
    #otherSwedishBorrowers: FiDataNumber;
    #sumForeignBorrowers: FiDataNumber;
    #emuBorrowers: FiDataNumber;
    #otherEuBorrowers: FiDataNumber;
    #otherForeignBorrowers: FiDataNumber;
    #sumBonds: FiDataNumber;
    #swedishBankStocks: FiDataNumber;
    #swedishInsuranceCompanyStocks: FiDataNumber;
    #otherSwedishFinancialInstitutionStocks: FiDataNumber;
    #swedishNonFinancialInstitutionStocks: FiDataNumber;
    #sumForeignStocks: FiDataNumber;
    #emuStocks: FiDataNumber;
    #euStocks: FiDataNumber;
    #otherForeignStocks: FiDataNumber;
    #sumStocks: FiDataNumber;
    #sumDerivatives: FiDataNumber;
    #sumFinancialInstruments: FiDataNumber;

    //==================
    // Derivatives - F
    //==================
    // assets
    #swedishBankDerivatives: FiDataNumber;
    #swedishFinancialServicesCompanyDerivatives: FiDataNumber;
    #swedishInsuranceCompanyDerivatives: FiDataNumber;
    #swedishNonFinancialCompanyDerivatives: FiDataNumber;
    #sumForeignDerivatives: FiDataNumber;
    #emuDerivatives: FiDataNumber;
    #otherEuDerivatives: FiDataNumber;
    #otherForeignDerivatives: FiDataNumber;
    #sumAssetDerivatives: FiDataNumber;
    // liabilities
    #liabilitySwedishBankDerivatives: FiDataNumber;
    #liabilitySwedishFinancialServicesCompanyDerivatives: FiDataNumber;
    #liabilitySwedishInsuranceCompanyDerivatives: FiDataNumber;
    #liabilitySwedishNonFinancialCompanyDerivatives: FiDataNumber;
    #sumLiabilityForeignDerivatives: FiDataNumber;
    #liabilityEmuDerivatives: FiDataNumber;
    #liabilityOtherEuDerivatives: FiDataNumber;
    #liabilityOtherForeignDerivatives: FiDataNumber;
    #sumLiabilityDerivatives: FiDataNumber;
    constructor() {
        //==================
        // Assets - E
        //==================
        this.#swedishGovernmentBonds = new FiDataNumber("DP-YMBZ-AUGH", "Svenska statsobligationer [E3]");
        this.#swedishRegionalGovernmentBonds = new FiDataNumber("DP-4EJL-RUUZ", "Svenska kommunobligationer [E6]");
        this.#sumGovernmentDebtObligations = new FiDataNumber(
            "DP-R2TW-ACFO",
            "Summa belåningsbara statsskuldförbindelser m.m. (E1 : E7) [E16]"
        );
        this.#swedishBankBonds = new FiDataNumber("DP-ZD2S-1LEP", "Svenska banker [E28]");
        this.#swedishHousingInstituteBonds = new FiDataNumber("DP-9HMP-HPQH", "Svenska bostadsinstitut [E29]");
        this.#swedishFinancialServicesCompanyBonds = new FiDataNumber("DP-M3YS-BOAH", "Övriga svenska kreditmarknadsbolag [E30]");
        this.#swedishInsuranceCompanyBonds = new FiDataNumber("DP-ERF6-DFBF", "Svenska försäkringsbolag [E31]");
        this.#swedishNonFinancialCompanyBonds = new FiDataNumber("DP-W858-BWHU", "Svenska icke-finansiella bolag [E32]");
        this.#otherSwedishBorrowers = new FiDataNumber("DP-LG08-0UJH", "Övriga svenska låntagare [E33]");
        this.#sumForeignBorrowers = new FiDataNumber("DP-0SFZ-VG3O", "Utländska låntagare [E34]");
        this.#emuBorrowers = new FiDataNumber("DP-DNTV-B632", "EMU [E35]");
        this.#otherEuBorrowers = new FiDataNumber("DP-NRQN-ZNBH", "Övriga EU [E36]");
        this.#otherForeignBorrowers = new FiDataNumber("DP-YMZV-WTF5", "Övriga utlandet [E37]");
        this.#sumBonds = new FiDataNumber("DP-JX44-V6OT", "Summa obligationer (E28 : E34) [E38]");
        this.#swedishBankStocks = new FiDataNumber("DP-K600-6EDE", "Svenska banker [E40]");
        this.#swedishInsuranceCompanyStocks = new FiDataNumber("DP-1KOE-MVXC", "Svenska försäkringsbolag [E43]");
        this.#otherSwedishFinancialInstitutionStocks = new FiDataNumber("DP-9IIT-WR7U", "Övriga svenska finansiella bolag [E44]");
        this.#swedishNonFinancialInstitutionStocks = new FiDataNumber("DP-HJ7U-V4GR", "Svenska icke-finansiella bolag [E45]");
        this.#sumForeignStocks = new FiDataNumber("DP-B3CL-EEGR", "Utländska företag [E46]");
        this.#emuStocks = new FiDataNumber("DP-VGQ8-QTDL", "EMU [E47]");
        this.#euStocks = new FiDataNumber("DP-ZYSQ-5RCO", "Övriga EU [E48]");
        this.#otherForeignStocks = new FiDataNumber("DP-YNUF-PUZP", "Övriga utlandet [E49]");
        this.#sumStocks = new FiDataNumber("DP-DOZS-Q6AF", "Summa aktier (E39 : E46) [E50]");
        this.#sumDerivatives = new FiDataNumber("DP-91G4-XJ2F", "Finansiella derivatinstrument [E71]");
        this.#sumFinancialInstruments = new FiDataNumber(
            "DP-MODG-ZBKV",
            "Summa finansiella instrument (E16 + E27 + E38 + E50 + E63 : E64 + E65 + E67 + E71 : E72) [E73]"
        );
        //==================
        // Derivatives - F
        //==================
        // assets
        this.#swedishBankDerivatives = new FiDataNumber("DP-2DS6-YS3S", "Svenska banker [F4]");
        this.#swedishFinancialServicesCompanyDerivatives = new FiDataNumber("DP-YX8G-EAXG", "Övriga svenska kreditmarknadsbolag [F6]");
        this.#swedishInsuranceCompanyDerivatives = new FiDataNumber("DP-VGBW-LFEQ", "Svenska försäkringsbolag [F7]");
        this.#swedishNonFinancialCompanyDerivatives = new FiDataNumber("DP-2N55-OJRL", "Svenska icke-finansiella bolag [F9]");
        this.#sumForeignDerivatives = new FiDataNumber("DP-H6JP-BABZ", "Utländska motparter [F11]");
        this.#emuDerivatives = new FiDataNumber("DP-GELI-MMMZ", "EMU [F12]");
        this.#otherEuDerivatives = new FiDataNumber("DP-ZUBS-XNWC", "Övriga EU [F13]");
        this.#otherForeignDerivatives = new FiDataNumber("DP-BRGZ-7DOI", "Övriga utlandet [F14]");
        this.#sumAssetDerivatives = new FiDataNumber("DP-13LK-SBRA", "Summa finansiella derivatinstrument (F1 : F11) [F15]");
        // liabilities
        this.#liabilitySwedishBankDerivatives = new FiDataNumber("DP-IHRB-U7SH", "Svenska banker [F4]");
        this.#liabilitySwedishFinancialServicesCompanyDerivatives = new FiDataNumber(
            "DP-0CJ3-WEYU",
            "Övriga svenska kreditmarknadsbolag [F6]"
        );
        this.#liabilitySwedishInsuranceCompanyDerivatives = new FiDataNumber("DP-HCCZ-Y03A", "Svenska försäkringsbolag [F7]");
        this.#liabilitySwedishNonFinancialCompanyDerivatives = new FiDataNumber("DP-QEMC-UUQR", "Svenska icke-finansiella bolag [F9]");
        this.#sumLiabilityForeignDerivatives = new FiDataNumber("DP-BCJV-UBZR", "Utländska motparter [F11]");
        this.#liabilityEmuDerivatives = new FiDataNumber("DP-WCKK-TNQ1", "EMU [F12]");
        this.#liabilityOtherEuDerivatives = new FiDataNumber("DP-CCZQ-JQ9X", "Övriga EU [F13]");
        this.#liabilityOtherForeignDerivatives = new FiDataNumber("DP-CI8R-LTLX", "Övriga utlandet [F14]");
        this.#sumLiabilityDerivatives = new FiDataNumber("DP-BQQC-CG2H", "Summa finansiella derivatinstrument (F1 : F11) [F15]");
    }

    // methods
    addToStocks(issuer: Partial<Party>, value: number) {
        // start to check if SE
        if (issuer.country === CountryCodeEnum.SE) {
            if (issuer.industryCode === "1410") {
                // BICS 1410 - Banking
                this.#swedishBankStocks.add(value);
            } else if (issuer.industryCode === "1411") {
                // BICS 1411 - Financial Services
                this.#otherSwedishFinancialInstitutionStocks.add(value);
            } else if (issuer.industryCode === "1412") {
                // BICS 1412 = Insurance
                this.#swedishInsuranceCompanyStocks.add(value);
            } else {
                // otherwise non finacial
                this.#swedishNonFinancialInstitutionStocks.add(value);
            }
        } else if (EMU_COUNTRIES[issuer.country]) {
            this.#emuStocks.add(value);
        } // then EU
        else if (EU_COUNTRIES[issuer.country]) {
            this.#euStocks.add(value);
        } // foreign
        else {
            this.#otherForeignStocks.add(value);
        }
    }
    addToBonds(issuer: Partial<Party>, issuerProgram: Partial<IssuerProgram>, value: number) {
        const fiType = getFiTypeForBond(issuer, issuerProgram);
        if (fiType === FiTypeEnum.SwedishHousingInstitute) {
            this.#swedishHousingInstituteBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishBank) {
            this.#swedishBankBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishFinancialServicesCompany) {
            this.#swedishFinancialServicesCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishInsuranceCompany) {
            this.#swedishInsuranceCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishGovernment) {
            this.#swedishGovernmentBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishRegionalGovernment) {
            this.#swedishRegionalGovernmentBonds.add(value);
        } else if (fiType === FiTypeEnum.SwedishNonFinancialCompany) {
            this.#swedishNonFinancialCompanyBonds.add(value);
        } else if (fiType === FiTypeEnum.Emu) {
            this.#emuBorrowers.add(value);
        } else if (fiType === FiTypeEnum.OtherEu) {
            this.#otherEuBorrowers.add(value);
        } else if (fiType === FiTypeEnum.OtherForeign) {
            this.#otherForeignBorrowers.add(value);
        } else {
            throw new Error("Unknow FiType: " + fiType);
        }
    }
    addToDerivatives(custodian: Partial<Party>, value: number) {
        // start to check if SE
        if (custodian.country === CountryCodeEnum.SE) {
            if (custodian.industryCode === "1410") {
                // BICS 1410 = Banking
                if (value < 0) {
                    this.#liabilitySwedishBankDerivatives.add(-value);
                } else {
                    this.#swedishBankDerivatives.add(value);
                }
            } else if (custodian.industryCode === "1411") {
                if (value < 0) {
                    this.#liabilitySwedishFinancialServicesCompanyDerivatives.add(-value);
                } else {
                    this.#swedishFinancialServicesCompanyDerivatives.add(value);
                }
            } else if (custodian.industryCode === "1412") {
                // BICS 1412 = Insurance
                if (value < 0) {
                    this.#liabilitySwedishInsuranceCompanyDerivatives.add(-value);
                } else {
                    this.#swedishInsuranceCompanyDerivatives.add(value);
                }
            } else {
                if (value < 0) {
                    this.#liabilitySwedishNonFinancialCompanyDerivatives.add(-value);
                } else {
                    this.#swedishNonFinancialCompanyDerivatives.add(value);
                }
            }
        } else if (EMU_COUNTRIES[custodian.country]) {
            if (value < 0) {
                this.#liabilityEmuDerivatives.add(-value);
            } else {
                this.#emuDerivatives.add(value);
            }
        } // then EU
        else if (EU_COUNTRIES[custodian.country]) {
            if (value < 0) {
                this.#liabilityOtherEuDerivatives.add(-value);
            } else {
                this.#otherEuDerivatives.add(value);
            }
        } // foreign
        else {
            if (value < 0) {
                this.#liabilityOtherForeignDerivatives.add(-value);
            } else {
                this.#otherForeignDerivatives.add(value);
            }
        }
    }

    calculateSums() {
        //==================
        // Assets - E
        //==================
        const sumGovernmentDebtObligations = this.#swedishGovernmentBonds.value + this.#swedishRegionalGovernmentBonds.value;
        const sumForeignBorrowers = this.#emuBorrowers.value + this.#otherEuBorrowers.value + this.#otherForeignBorrowers.value;
        const sumBonds =
            this.#swedishBankBonds.value +
            this.#swedishHousingInstituteBonds.value +
            this.#swedishFinancialServicesCompanyBonds.value +
            this.#swedishInsuranceCompanyBonds.value +
            this.#swedishNonFinancialCompanyBonds.value +
            this.#otherSwedishBorrowers.value +
            sumForeignBorrowers;
        const sumForeignStocks = this.#emuStocks.value + this.#euStocks.value + this.#otherForeignStocks.value;
        const sumStocks =
            this.#swedishBankStocks.value +
            this.#swedishInsuranceCompanyStocks.value +
            this.#otherSwedishFinancialInstitutionStocks.value +
            this.#swedishNonFinancialInstitutionStocks.value +
            sumForeignStocks;

        //==================
        // Derivatives - F
        //==================
        // assets
        const sumDerivativesForeign = this.#emuDerivatives.value + this.#otherEuDerivatives.value + this.#otherForeignDerivatives.value;
        const sumAssetDerivatives =
            this.#swedishBankDerivatives.value +
            this.#swedishFinancialServicesCompanyDerivatives.value +
            this.#swedishInsuranceCompanyDerivatives.value +
            this.#swedishNonFinancialCompanyDerivatives.value +
            sumDerivativesForeign;
        // liabilities
        const sumLiabilityDerivativesForeign =
            this.#liabilityEmuDerivatives.value + this.#liabilityOtherEuDerivatives.value + this.#liabilityOtherForeignDerivatives.value;
        const sumLiabilityDerivatives =
            this.#liabilitySwedishBankDerivatives.value +
            this.#liabilitySwedishFinancialServicesCompanyDerivatives.value +
            this.#liabilitySwedishInsuranceCompanyDerivatives.value +
            this.#liabilitySwedishNonFinancialCompanyDerivatives.value +
            sumLiabilityDerivativesForeign;

        const sumDerivatives = sumAssetDerivatives - sumLiabilityDerivatives;
        const sumFinancialInstruments = sumGovernmentDebtObligations + sumBonds + sumStocks + sumDerivatives;

        //==================
        // Assets - E
        //==================
        this.#sumGovernmentDebtObligations.value = sumGovernmentDebtObligations;
        this.#sumForeignBorrowers.value = sumForeignBorrowers;
        this.#sumBonds.value = sumBonds;
        this.#sumForeignStocks.value = sumForeignStocks;
        this.#sumStocks.value = sumStocks;

        //==================
        // Derivatives - F
        //==================
        // assets
        this.#sumForeignDerivatives.value = sumDerivativesForeign;
        this.#sumAssetDerivatives.value = sumAssetDerivatives;
        // liabilities
        this.#sumLiabilityForeignDerivatives.value = sumLiabilityDerivativesForeign;
        this.#sumLiabilityDerivatives.value = sumLiabilityDerivatives;

        // total sums
        this.#sumDerivatives.value = sumDerivatives;
        this.#sumFinancialInstruments.value = sumFinancialInstruments;
    }

    toJson(): any {
        // round and calculate sums
        this.round();
        this.calculateSums();
        // put together json
        return {
            //==================
            // Assets - E
            //==================
            ...this.#swedishGovernmentBonds.toJson(),
            ...this.#swedishRegionalGovernmentBonds.toJson(),
            ...this.#sumGovernmentDebtObligations.toJson(),
            ...this.#swedishBankBonds.toJson(),
            ...this.#swedishHousingInstituteBonds.toJson(),
            ...this.#swedishFinancialServicesCompanyBonds.toJson(),
            ...this.#swedishInsuranceCompanyBonds.toJson(),
            ...this.#swedishNonFinancialCompanyBonds.toJson(),
            ...this.#otherSwedishBorrowers.toJson(),
            ...this.#sumForeignBorrowers.toJson(),
            ...this.#emuBorrowers.toJson(),
            ...this.#otherEuBorrowers.toJson(),
            ...this.#otherForeignBorrowers.toJson(),
            ...this.#sumBonds.toJson(),
            ...this.#swedishBankStocks.toJson(),
            ...this.#swedishInsuranceCompanyStocks.toJson(),
            ...this.#otherSwedishFinancialInstitutionStocks.toJson(),
            ...this.#swedishNonFinancialInstitutionStocks.toJson(),
            ...this.#sumForeignStocks.toJson(),
            ...this.#emuStocks.toJson(),
            ...this.#euStocks.toJson(),
            ...this.#otherForeignStocks.toJson(),
            ...this.#sumStocks.toJson(),
            ...this.#sumDerivatives.toJson(),
            ...this.#sumFinancialInstruments.toJson(),
            //==================
            // Derivatives - F
            //==================
            // assets
            ...this.#swedishBankDerivatives.toJson(),
            ...this.#swedishFinancialServicesCompanyDerivatives.toJson(),
            ...this.#swedishInsuranceCompanyDerivatives.toJson(),
            ...this.#swedishNonFinancialCompanyDerivatives.toJson(),
            ...this.#sumForeignDerivatives.toJson(),
            ...this.#emuDerivatives.toJson(),
            ...this.#otherEuDerivatives.toJson(),
            ...this.#otherForeignDerivatives.toJson(),
            ...this.#sumAssetDerivatives.toJson(),
            // liabilities
            ...this.#liabilitySwedishBankDerivatives.toJson(),
            ...this.#liabilitySwedishFinancialServicesCompanyDerivatives.toJson(),
            ...this.#liabilitySwedishInsuranceCompanyDerivatives.toJson(),
            ...this.#liabilitySwedishNonFinancialCompanyDerivatives.toJson(),
            ...this.#sumLiabilityForeignDerivatives.toJson(),
            ...this.#liabilityEmuDerivatives.toJson(),
            ...this.#liabilityOtherEuDerivatives.toJson(),
            ...this.#liabilityOtherForeignDerivatives.toJson(),
            ...this.#sumLiabilityDerivatives.toJson()
        };
    }
    round() {
        //==================
        // Assets - E
        //==================
        this.#swedishGovernmentBonds.round();
        this.#sumGovernmentDebtObligations.round();
        this.#swedishRegionalGovernmentBonds.round();
        this.#swedishBankBonds.round();
        this.#swedishHousingInstituteBonds.round();
        this.#swedishFinancialServicesCompanyBonds.round();
        this.#swedishInsuranceCompanyBonds.round();
        this.#swedishNonFinancialCompanyBonds.round();
        this.#otherSwedishBorrowers.round();
        this.#sumForeignBorrowers.round();
        this.#emuBorrowers.round();
        this.#otherEuBorrowers.round();
        this.#otherForeignBorrowers.round();
        this.#sumBonds.round();
        this.#swedishBankStocks.round();
        this.#swedishInsuranceCompanyStocks.round();
        this.#otherSwedishFinancialInstitutionStocks.round();
        this.#swedishNonFinancialInstitutionStocks.round();
        this.#sumForeignStocks.round();
        this.#emuStocks.round();
        this.#euStocks.round();
        this.#otherForeignStocks.round();
        this.#sumStocks.round();
        this.#sumDerivatives.round();
        this.#sumFinancialInstruments.round();
        //==================
        // Derivatives - F
        //==================
        // assets
        this.#swedishBankDerivatives.round();
        this.#swedishFinancialServicesCompanyDerivatives.round();
        this.#swedishInsuranceCompanyDerivatives.round();
        this.#swedishNonFinancialCompanyDerivatives.round();
        this.#sumForeignDerivatives.round();
        this.#emuDerivatives.round();
        this.#otherEuDerivatives.round();
        this.#otherForeignDerivatives.round();
        this.#sumAssetDerivatives.round();
        // liabilities
        this.#liabilitySwedishBankDerivatives.round();
        this.#liabilitySwedishFinancialServicesCompanyDerivatives.round();
        this.#liabilitySwedishInsuranceCompanyDerivatives.round();
        this.#liabilitySwedishNonFinancialCompanyDerivatives.round();
        this.#sumLiabilityForeignDerivatives.round();
        this.#liabilityEmuDerivatives.round();
        this.#liabilityOtherEuDerivatives.round();
        this.#liabilityOtherForeignDerivatives.round();
        this.#sumLiabilityDerivatives.round();
    }
}

class ShareClass {
    #shareClass: FiDataString;
    #sharePrice: FiDataNumber;
    #dividendPaid: FiDataNumber;
    #dividendDate: FiDataString;
    #salesFee: FiDataNumber;
    #redemptionFee: FiDataNumber;

    constructor(shareClass: string, sharePrice: number, dividendPaid = 0, dividendDate = "", salesFee = 0, redemptionFee = 0) {
        this.#shareClass = new FiDataString("DP-FR3N-TVQ6", "Andelsklass", shareClass);
        this.#sharePrice = new FiDataNumber("DP-JRX4-00C4", "Fondandelsvärde (kr med två decimaler) [H1]", sharePrice);
        this.#dividendPaid = new FiDataNumber("DP-8LYT-2UJC", "Lämnad utdelning (kr) [H2]", dividendPaid);
        this.#dividendDate = new FiDataString("DP-MTVA-8YCP", "Utdelningsdatum (YYYY-MM-DD) [H3]", dividendDate);
        this.#salesFee = new FiDataNumber("DP-80Y6-HJQN", "Avgift för försäljning (% med två decimaler) [H4]", salesFee);
        this.#redemptionFee = new FiDataNumber("DP-Z9E8-X526", "Avgift för inlösen (% med två decimaler) [H5]", redemptionFee);
    }

    // methods
    toJson(): any {
        this.round();
        // put together json
        let classJson = { ...this.#shareClass.toJson(), ...this.#sharePrice.toJson() };
        // add dividend?
        if (this.#dividendPaid.value !== 0) {
            classJson = { ...classJson, ...this.#dividendPaid.toJson(), ...this.#dividendDate.toJson() };
        }
        // add sales fee
        if (this.#salesFee.value !== 0) {
            classJson = { ...classJson, ...this.#salesFee.toJson() };
        }
        // add redemptions fee
        if (this.#redemptionFee.value !== 0) {
            classJson = { ...classJson, ...this.#redemptionFee.toJson() };
        }
        return classJson;
    }
    round() {
        // share price with 2 decimals
        this.#sharePrice.round(2);
        // dividend paid to int
        this.#dividendPaid.round();
        // fees in percent with two decimals - how to assert percent?
        this.#salesFee.round(2);
        this.#redemptionFee.round(2);
    }
}

class CreateRedeemTransactions {
    #fundHouseholds: FiDataNumber;
    #sumFundSwedishFinancialInstitutions: FiDataNumber;
    #fundBanks: FiDataNumber;
    #fundHousingInstitutes: FiDataNumber;
    #fundInsuranceCompanies: FiDataNumber;
    #fundPensionInstitutes: FiDataNumber;
    #fundOtherSwedishFinancialInstitutions: FiDataNumber;
    #fundMunicipalAdministration: FiDataNumber;
    #fundSocialSecurityFunds: FiDataNumber;
    #fundOfWhichThePensionsAuthority: FiDataNumber;
    #sumFundForeign: FiDataNumber;
    #fundEmu: FiDataNumber;
    #fundEu: FiDataNumber;
    #fundOtherForeign: FiDataNumber;
    #sumFund: FiDataNumber;
    #fundHouseholdsRedeem: FiDataNumber;
    #sumFundSwedishFinancialInstitutionsRedeem: FiDataNumber;
    #fundBanksRedeem: FiDataNumber;
    #fundHousingInstitutesRedeem: FiDataNumber;
    #fundInsuranceCompaniesRedeem: FiDataNumber;
    #fundPensionInstitutesRedeem: FiDataNumber;
    #fundOtherSwedishFinancialInstitutionsRedeem: FiDataNumber;
    #fundMunicipalAdministrationRedeem: FiDataNumber;
    #fundSocialSecurityFundsRedeem: FiDataNumber;
    #fundOfWhichThePensionsAuthorityRedeem: FiDataNumber;
    #sumFundForeignRedeem: FiDataNumber;
    #fundEmuRedeem: FiDataNumber;
    #fundEuRedeem: FiDataNumber;
    #fundOtherForeignRedeem: FiDataNumber;
    #sumFundRedeem: FiDataNumber;
    constructor() {
        this.#fundHouseholds = new FiDataNumber("DP-5EXO-9XFV", "Svenska hushåll [C1]");
        this.#sumFundSwedishFinancialInstitutions = new FiDataNumber("DP-YIWJ-JDHG", "Svenska finansiella bolag [C4]");
        this.#fundBanks = new FiDataNumber("DP-4KN0-4ZBH", "Banker (utom centralbanker) plus bankfilialer till banker i utlandet [C5]");
        this.#fundHousingInstitutes = new FiDataNumber("DP-D6C6-XAVF", "Bostadsinstitut [C6]");
        this.#fundInsuranceCompanies = new FiDataNumber("DP-JGKB-5JCT", "Försäkringsbolag [C15]");
        this.#fundPensionInstitutes = new FiDataNumber("DP-8WWN-IGBL", "Pensionsinstitut [C17]");
        this.#fundOtherSwedishFinancialInstitutions = new FiDataNumber("DP-4TFE-XOOE", "Övriga svenska finansiella bolag [C23]");
        this.#fundMunicipalAdministration = new FiDataNumber("DP-I4ZC-V0BS", "Kommunal förvaltning [C25]");
        this.#fundSocialSecurityFunds = new FiDataNumber("DP-C34D-6AI6", "Sociala trygghetsfonder [C26]");
        this.#fundOfWhichThePensionsAuthority = new FiDataNumber("DP-TEUM-XLON", "varav Pensionsmyndigheten [C27]");
        this.#sumFundForeign = new FiDataNumber("DP-06AG-XM7B", "Utländska innehavare [C29]");
        this.#fundEmu = new FiDataNumber("DP-EA6C-CQU4", "EMU [C30]");
        this.#fundEu = new FiDataNumber("DP-ZNTW-45AT", "Övriga EU [C31]");
        this.#fundOtherForeign = new FiDataNumber("DP-EIAP-HQRB", "Övriga utlandet [C32]");
        this.#sumFund = new FiDataNumber("DP-K7OW-ZDH3", "Summa (C1 + C3 : C4 + C24 : C26 + C28 : C29) [C33]");
        this.#fundHouseholdsRedeem = new FiDataNumber("DP-WPSH-10US", "Svenska hushåll [C1]");
        this.#sumFundSwedishFinancialInstitutionsRedeem = new FiDataNumber("DP-OPIM-K8FE", "Svenska finansiella bolag [C4]");
        this.#fundBanksRedeem = new FiDataNumber(
            "DP-UEDO-FU2L",
            "Banker (utom centralbanker) plus bankfilialer till banker i utlandet [C5]"
        );
        this.#fundHousingInstitutesRedeem = new FiDataNumber("DP-9LPY-LSPF", "Bostadsinstitut [C6]");
        this.#fundInsuranceCompaniesRedeem = new FiDataNumber("DP-G5ZK-XRKZ", "Försäkringsbolag [C15]");
        this.#fundPensionInstitutesRedeem = new FiDataNumber("DP-BBX3-8HIT", "Pensionsinstitut [C17]");
        this.#fundOtherSwedishFinancialInstitutionsRedeem = new FiDataNumber("DP-KNUA-IILF", "Övriga svenska finansiella bolag [C23]");
        this.#fundMunicipalAdministrationRedeem = new FiDataNumber("DP-XZHT-UZ2L", "Kommunal förvaltning [C25]");
        this.#fundSocialSecurityFundsRedeem = new FiDataNumber("DP-PC8X-ADUH", "Sociala trygghetsfonder [C26]");
        this.#fundOfWhichThePensionsAuthorityRedeem = new FiDataNumber("DP-1FLG-Z6WW", "varav Pensionsmyndigheten [C27]");
        this.#sumFundForeignRedeem = new FiDataNumber("DP-RMQQ-8MWS", "Utländska innehavare [C29]");
        this.#fundEmuRedeem = new FiDataNumber("DP-T8D2-BHDW", "EMU [C30]");
        this.#fundEuRedeem = new FiDataNumber("DP-7MX6-Y8PP", "Övriga EU [C31]");
        this.#fundOtherForeignRedeem = new FiDataNumber("DP-RFV6-AK8A", "Övriga utlandet [C32]");
        this.#sumFundRedeem = new FiDataNumber("DP-QCQN-NOW7", "Summa (C1 + C3 : C4 + C24 : C26 + C28 : C29) [C33]");
    }

    // methods
    addToFund(customer: Partial<Customer>, value: number) {
        if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.Banks) {
            if (value < 0) {
                this.#fundBanksRedeem.add(-value);
            } else {
                this.#fundBanks.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.Emu) {
            if (value < 0) {
                this.#fundEmuRedeem.add(-value);
            } else {
                this.#fundEmu.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherEu) {
            if (value < 0) {
                this.#fundEuRedeem.add(-value);
            } else {
                this.#fundEu.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherForeign) {
            if (value < 0) {
                this.#fundOtherForeignRedeem.add(-value);
            } else {
                this.#fundOtherForeign.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.HousingInstitutes) {
            if (value < 0) {
                this.#fundHousingInstitutesRedeem.add(-value);
            } else {
                this.#fundHousingInstitutes.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.MunicipalAdministration) {
            if (value < 0) {
                this.#fundMunicipalAdministrationRedeem.add(-value);
            } else {
                this.#fundMunicipalAdministration.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SocialSecurityFunds) {
            if (value < 0) {
                this.#fundSocialSecurityFundsRedeem.add(-value);
            } else {
                this.#fundSocialSecurityFunds.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OfWhichThePensionsAuthority) {
            if (value < 0) {
                this.#fundOfWhichThePensionsAuthorityRedeem.add(-value);
                // add to social security fonds as well as this is the field used in sums
                this.#fundSocialSecurityFundsRedeem.add(-value);
            } else {
                this.#fundOfWhichThePensionsAuthority.add(value);
                // add to social security fonds as well as this is the field used in sums
                this.#fundSocialSecurityFunds.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SwedishHouseholds) {
            if (value < 0) {
                this.#fundHouseholdsRedeem.add(-value);
            } else {
                this.#fundHouseholds.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.PensionInstitutes) {
            if (value < 0) {
                this.#fundPensionInstitutesRedeem.add(-value);
            } else {
                this.#fundPensionInstitutes.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.OtherSwedishFinancialInstitutions) {
            if (value < 0) {
                this.#fundOtherSwedishFinancialInstitutionsRedeem.add(-value);
            } else {
                this.#fundOtherSwedishFinancialInstitutions.add(value);
            }
        } else if (customer.swedishFinancialAuthorityCategory === SwedishFinancialAuthorityCategoryEnum.SwedishInsuranceCompanies) {
            if (value < 0) {
                this.#fundInsuranceCompaniesRedeem.add(-value);
            } else {
                this.#fundInsuranceCompanies.add(value);
            }
        } else {
            // we shall never come here but just in case add to banks which is largest by far
            if (value < 0) {
                this.#fundBanksRedeem.add(-value);
            } else {
                this.#fundBanks.add(value);
            }
        }
    }

    calculateSums() {
        // create
        const sumFundSwedishFinancialInstitutions =
            this.#fundBanks.value +
            this.#fundHousingInstitutes.value +
            this.#fundInsuranceCompanies.value +
            this.#fundPensionInstitutes.value +
            this.#fundOtherSwedishFinancialInstitutions.value;

        const sumFundForeign = this.#fundEmu.value + this.#fundEu.value + this.#fundOtherForeign.value;
        const sumFund =
            this.#fundHouseholds.value +
            sumFundSwedishFinancialInstitutions +
            this.#fundMunicipalAdministration.value +
            this.#fundSocialSecurityFunds.value +
            sumFundForeign;
        // redeem
        const sumFundSwedishFinancialInstitutionsRedeem =
            this.#fundBanksRedeem.value +
            this.#fundHousingInstitutesRedeem.value +
            this.#fundInsuranceCompaniesRedeem.value +
            this.#fundPensionInstitutesRedeem.value +
            this.#fundOtherSwedishFinancialInstitutionsRedeem.value;

        const sumFundForeignRedeem = this.#fundEmuRedeem.value + this.#fundEuRedeem.value + this.#fundOtherForeignRedeem.value;
        const sumFundRedeem =
            this.#fundHouseholdsRedeem.value +
            sumFundSwedishFinancialInstitutionsRedeem +
            this.#fundMunicipalAdministrationRedeem.value +
            this.#fundSocialSecurityFundsRedeem.value +
            sumFundForeignRedeem;

        // update sums
        this.#sumFundSwedishFinancialInstitutions.value = sumFundSwedishFinancialInstitutions;
        this.#sumFundForeign.value = sumFundForeign;
        this.#sumFund.value = sumFund;
        this.#sumFundSwedishFinancialInstitutionsRedeem.value = sumFundSwedishFinancialInstitutionsRedeem;
        this.#sumFundForeignRedeem.value = sumFundForeignRedeem;
        this.#sumFundRedeem.value = sumFundRedeem;
    }

    toJson(): any {
        this.round();
        this.calculateSums();
        // put together json
        return {
            ...this.#fundHouseholds.toJson(),
            ...this.#sumFundSwedishFinancialInstitutions.toJson(),
            ...this.#fundBanks.toJson(),
            ...this.#fundHousingInstitutes.toJson(),
            ...this.#fundInsuranceCompanies.toJson(),
            ...this.#fundPensionInstitutes.toJson(),
            ...this.#fundOtherSwedishFinancialInstitutions.toJson(),
            ...this.#fundMunicipalAdministration.toJson(),
            ...this.#fundSocialSecurityFunds.toJson(),
            ...this.#fundOfWhichThePensionsAuthority.toJson(),
            ...this.#sumFundForeign.toJson(),
            ...this.#fundEmu.toJson(),
            ...this.#fundEu.toJson(),
            ...this.#fundOtherForeign.toJson(),
            ...this.#sumFund.toJson(),
            ...this.#fundHouseholdsRedeem.toJson(),
            ...this.#sumFundSwedishFinancialInstitutionsRedeem.toJson(),
            ...this.#fundBanksRedeem.toJson(),
            ...this.#fundHousingInstitutesRedeem.toJson(),
            ...this.#fundInsuranceCompaniesRedeem.toJson(),
            ...this.#fundPensionInstitutesRedeem.toJson(),
            ...this.#fundOtherSwedishFinancialInstitutionsRedeem.toJson(),
            ...this.#fundMunicipalAdministrationRedeem.toJson(),
            ...this.#fundSocialSecurityFundsRedeem.toJson(),
            ...this.#fundOfWhichThePensionsAuthorityRedeem.toJson(),
            ...this.#sumFundForeignRedeem.toJson(),
            ...this.#fundEmuRedeem.toJson(),
            ...this.#fundEuRedeem.toJson(),
            ...this.#fundOtherForeignRedeem.toJson(),
            ...this.#sumFundRedeem.toJson()
        };
    }
    round() {
        this.#fundHouseholds.round();
        this.#sumFundSwedishFinancialInstitutions.round();
        this.#fundBanks.round();
        this.#fundHousingInstitutes.round();
        this.#fundInsuranceCompanies.round();
        this.#fundPensionInstitutes.round();
        this.#fundOtherSwedishFinancialInstitutions.round();
        this.#fundMunicipalAdministration.round();
        this.#fundSocialSecurityFunds.round();
        this.#fundOfWhichThePensionsAuthority.round();
        this.#sumFundForeign.round();
        this.#fundEmu.round();
        this.#fundEu.round();
        this.#fundOtherForeign.round();
        this.#sumFund.round();
        this.#fundHouseholdsRedeem.round();
        this.#sumFundSwedishFinancialInstitutionsRedeem.round();
        this.#fundBanksRedeem.round();
        this.#fundHousingInstitutesRedeem.round();
        this.#fundInsuranceCompaniesRedeem.round();
        this.#fundPensionInstitutesRedeem.round();
        this.#fundOtherSwedishFinancialInstitutionsRedeem.round();
        this.#fundMunicipalAdministrationRedeem.round();
        this.#fundSocialSecurityFundsRedeem.round();
        this.#fundOfWhichThePensionsAuthorityRedeem.round();
        this.#sumFundForeignRedeem.round();
        this.#fundEmuRedeem.round();
        this.#fundEuRedeem.round();
        this.#fundOtherForeignRedeem.round();
        this.#sumFundRedeem.round();
    }
}

export class FiReport {
    readonly reportingDate: string;
    #financialEntityId: string;
    // A - fund
    readonly fundStatus: FiDataString;
    readonly fundType: FiDataString;
    readonly totalUnits: FiDataNumber;
    readonly averageLiquidity: FiDataNumber;
    // B and D - balance sheet and market value specification
    readonly balanceSheet: BalanceSheet;
    // C - create redeems specifications
    readonly createRedeemTransactions: CreateRedeemTransactions;
    // E and F - transactions specification
    readonly transactionSpecification: TransactionSpecification;
    // H - transactions specification
    readonly shareClasses: ShareClass[];

    //

    constructor(reportingDate: string) {
        this.reportingDate = reportingDate;
        this.#financialEntityId = "";
        // A - fund
        this.fundStatus = new FiDataString("DP-LDK9-UEWX", "Status");
        // only active funds...
        this.fundStatus.value = "Aktiv";
        this.fundType = new FiDataString("DP-UB6U-AU2Q", "Typ av fond [A1-A6]");
        this.totalUnits = new FiDataNumber("DP-NF8W-QTS5", "Totalt antal fondandelar (st) [A7]");
        // since average liquidity is not in db. calculate from performance - check python?
        this.averageLiquidity = new FiDataNumber("DP-YOJU-KK1H", "Genomsnittlig likviditet (% med två decimaler) [A8]");

        // B and D - balance sheet and market value specification
        this.balanceSheet = new BalanceSheet();
        // C - create redeems specifications
        this.createRedeemTransactions = new CreateRedeemTransactions();
        // E and F - transactions specification
        this.transactionSpecification = new TransactionSpecification();
        // H share classes
        this.shareClasses = [];
    }

    // methods

    toJson(): any {
        this.averageLiquidity.round(2);
        this.totalUnits.round();
        return {
            schemaRef: "https://schemas.fi.se/nationell/vardespecial/20230301/vpspecq_schema.json",
            reportingDate: this.reportingDate,
            financialEntityId: this.#financialEntityId,
            consolidationScope: "Individual",
            data: {
                ...this.fundStatus.toJson(),
                ...this.fundType.toJson(),
                ...this.totalUnits.toJson(),
                ...this.averageLiquidity.toJson(),
                ...this.balanceSheet.toJson(),
                ...this.createRedeemTransactions.toJson(),
                ...this.transactionSpecification.toJson()
            },
            "DYN-ROWS-V_07_01": [],
            "DYN-ROWS-V_08_01": this.shareClasses.map((c) => c.toJson()),
            "DYN-ROWS-V_09_01": [],
            "DYN-ROWS-V_09_02": []
        };
    }

    #calculateAverageLiquidity(
        performance: Partial<PortfolioPerformance2>,
        instrumentsById: Record<string, Partial<Instrument>>,
        previousEndDate: string
    ) {
        const previousEndDateIndex = performance.dates.findIndex((element) => element === previousEndDate);
        const datesLength = performance.dates.length;
        const instrumentPerformancesLength = performance.instrumentPerformances.length;

        let totalLiquidity = 0;
        let count = 0;
        for (let i = previousEndDateIndex + 1; i < datesLength; i++) {
            let totalCash = 0;
            let total = 0;
            for (let j = 0; j < instrumentPerformancesLength; j++) {
                const instrument = instrumentsById[performance.instrumentPerformances[j].instrumentId];
                const value = performance.instrumentPerformances[j].values[i];
                if (instrument.productType === InstrumentProductTypeEnum.CashAccount) totalCash += value;
                total += value;
            }
            totalLiquidity += totalCash / total;
            count += 1;
        }

        // fi wants average liquidity in percent with two decimals
        const averageLiquidity = totalLiquidity / count;
        this.averageLiquidity.value = round(100 * averageLiquidity, 2);
    }

    processData(
        client: Partial<Party>,
        journalEntries: Partial<JournalEntry>[],
        instruments: Partial<Instrument>[],
        issuers: Partial<Party>[],
        issuerprograms: Partial<IssuerProgram>[],
        shareRegister: Partial<ShareRegisterItem>[],
        shareRegisterDelta: Partial<ShareRegisterItem>[],
        fxValuations: Partial<FxValuation>[],
        performance: Partial<PortfolioPerformance2>,
        previousEndDate: string
    ) {
        // set up dictionaries
        const instrumentsById = keyBy(instruments, (i) => i._id);
        const issuerprogramsById = keyBy(issuerprograms, (i) => i._id);
        const issuersById = keyBy(issuers, (i) => i._id);
        const externalAccountsById = keyBy(client.externalAccounts, (i) => i._id);

        // General fields
        // average liquidity
        this.#calculateAverageLiquidity(performance, instrumentsById, previousEndDate);

        // financial entity
        const financialEntityIdAlias = client.aliases ? client.aliases.find((alias) => alias.key === "financialEntityId") : null;
        if (!financialEntityIdAlias) throw new Error("No alias with key financialEntityId found for client: " + client._id);
        this.#financialEntityId = financialEntityIdAlias.value;

        // fund type
        const fiTypeOfFundAlias = client.aliases ? client.aliases.find((alias) => alias.key === "fiTypeOfFund") : null;
        if (!fiTypeOfFundAlias) throw new Error("No alias with key fiTypeOfFund found for client: " + client._id);
        this.fundType.value = fiTypeOfFundAlias.value;

        //----------------------
        // process client
        //----------------------
        // we need price in sek to calcualte market values
        const fxRateByName: Record<string, number> = {};
        for (const fxValuation of fxValuations) fxRateByName[fxValuation.name] = fxValuation.price;
        // add seksek for convenience
        fxRateByName[CurrencyEnum.SEK + CurrencyEnum.SEK] = 1;

        const priceByIsin: Record<string, number> = {};
        // since we want to exclude class instruments when processing journal entries
        const excludeInstrumentId: Record<string, boolean> = {};
        // TODO - remove me!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // due to erronous booking
        excludeInstrumentId["605b485fc34cf5001154c945"] = true;
        // TODO - remove me!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        if (client.fundInfo && Array.isArray(client.fundInfo.classes) && client.fundInfo.classes) {
            for (const shareClass of client.fundInfo.classes) {
                if (shareClass.enabled) {
                    const fxRate = fxRateByName[shareClass.instrument.currency + CurrencyEnum.SEK];
                    priceByIsin[shareClass.instrument.isin] = shareClass.instrument.valuations.price * fxRate;
                    excludeInstrumentId[shareClass.instrumentId] = true;
                }
            }
        } else {
            throw new Error("No fundInfo or fundInfo.classes array found for client id: " + client._id);
        }

        //--------------------------
        // process journal entries
        //--------------------------
        // we just want to process certain types
        const processAccountingTransactionType: Record<AccountingTransactionType, boolean> = {} as any;
        processAccountingTransactionType[AccountingTransactionType.AccruedInterest] = true;
        processAccountingTransactionType[AccountingTransactionType.InitialCost] = true;
        processAccountingTransactionType[AccountingTransactionType.ValueChange] = true;
        processAccountingTransactionType[AccountingTransactionType.ForwardCash] = true;

        const forwardCashByInstrumentId: Record<string, number> = {};
        const dividendPaidByInstrumentId: Record<string, { date: string; value: number }> = {};
        const amountByInstrumentId: Record<string, number> = {};
        const transactionAmountByInstrumentId: Record<string, number> = {};
        const numJournalEntries = journalEntries.length;
        for (let i = 0; i < numJournalEntries; i++) {
            const journalEntry = journalEntries[i];
            const numTransactions = journalEntry.transactions.length;
            for (let j = 0; j < numTransactions; j++) {
                const transaction = journalEntry.transactions[j];
                // dividend paid special case since booked on class instrument
                if (transaction.type === AccountingTransactionType.DividendPaid && journalEntry.effectiveDate > previousEndDate) {
                    if (!dividendPaidByInstrumentId[transaction.instrumentId]) {
                        dividendPaidByInstrumentId[transaction.instrumentId] = {
                            date: journalEntry.effectiveDate,
                            value: transaction.amount
                        };
                    } else {
                        // more than one dividend! which date???
                        throw new Error("More than one dividend paid during period for instrument: " + transaction.instrumentId);
                    }

                    continue;
                }
                // process or continue?
                if (excludeInstrumentId[transaction.instrumentId] || !processAccountingTransactionType[transaction.type]) continue;
                // process
                if (transaction.type === AccountingTransactionType.ForwardCash) {
                    if (!forwardCashByInstrumentId[transaction.instrumentId]) forwardCashByInstrumentId[transaction.instrumentId] = 0;
                    forwardCashByInstrumentId[transaction.instrumentId] += transaction.amount;
                } else {
                    if (!amountByInstrumentId[transaction.instrumentId]) amountByInstrumentId[transaction.instrumentId] = 0;
                    amountByInstrumentId[transaction.instrumentId] += transaction.amount;
                    // should we update transactions
                    if (journalEntry.batch === AccountingBatchType.T && journalEntry.effectiveDate > previousEndDate) {
                        if (!transactionAmountByInstrumentId[transaction.instrumentId]) {
                            transactionAmountByInstrumentId[transaction.instrumentId] = 0;
                        }
                        transactionAmountByInstrumentId[transaction.instrumentId] += transaction.amount;
                    }
                }
            }
        }
        // handle forward cash
        for (const value of Object.values(forwardCashByInstrumentId)) {
            // always positive
            if (value < 0) {
                this.balanceSheet.addToFundRedemtions(-value);
                this.balanceSheet.addToOtherLiabilities(-value);
            } else {
                this.balanceSheet.addToFundSubscriptions(value);
                this.balanceSheet.addToOtherReceivables(value);
            }
        }

        const derivativeExternalAccountTypes: Record<PartyExternalAccountType, boolean> = {} as any;
        Object.keys(AgreementType).forEach((t) => (derivativeExternalAccountTypes[t] = true));
        // process positions
        const bondLog: any = [];
        for (const [instrumentId, value] of Object.entries(amountByInstrumentId)) {
            const instrument = instrumentsById[instrumentId];
            const issuerprogram = instrument.issuerProgramId ? issuerprogramsById[instrument.issuerProgramId] : null;
            const issuer = instrument.issuerId ? issuersById[instrument.issuerId] : null;
            const externalAccount = externalAccountsById[instrument.firstTradeExternalAccountId];
            // assert external account and custodian as long as we are not balance - due to ROUNDING instrument...
            if (!externalAccount && instrument.modelType !== InstrumentModelTypeEnum.Balance) {
                console.error({ instrument });
                throw new Error("No external account with id " + instrument.firstTradeExternalAccountId);
            }
            const custodian = externalAccount ? issuersById[externalAccount.custodianId] : null;
            if (!custodian && instrument.modelType !== InstrumentModelTypeEnum.Balance) {
                throw new Error("No custodianfor external account with id " + instrument.firstTradeExternalAccountId);
            }

            // handle cash
            if (instrument.modelType === InstrumentModelTypeEnum.Balance) {
                // use external account first and fallback to product type
                if (
                    (externalAccount && derivativeExternalAccountTypes[externalAccount.type]) ||
                    instrument.productType === InstrumentProductTypeEnum.InitialMarginAccount ||
                    instrument.productType === InstrumentProductTypeEnum.VariationMarginAccount
                ) {
                    // margin accounts
                    if (value < 0) {
                        this.balanceSheet.addToOtherLiabilities(-value);
                    } else {
                        this.balanceSheet.addToOtherReceivables(value);
                    }
                } else {
                    // cash accounts
                    this.balanceSheet.addToCash(value);
                }
            } else if (instrument.modelType === InstrumentModelTypeEnum.Stock) {
                this.balanceSheet.addToStocks(issuer, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.Bond) {
                // TODO - remove this when debug finished!
                // bondLog.push({ name: instrument.name, type: getFiTypeForBond(issuer, issuerprogram), value });
                this.balanceSheet.addToBonds(issuer, issuerprogram, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.CdsIndex) {
                if (derivativeExternalAccountTypes[externalAccount.type]) this.balanceSheet.addToDerivatives(custodian, value);
                else this.balanceSheet.addToDerivatives(issuer, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.CdsBasket) {
                if (derivativeExternalAccountTypes[externalAccount.type]) this.balanceSheet.addToDerivatives(custodian, value);
                else this.balanceSheet.addToDerivatives(issuer, value);
            } else {
                // here we should be derivative - assert?

                if (!derivativeExternalAccountTypes[externalAccount.type]) {
                    throw new Error(
                        "Instrument of modelType: " +
                            instrument.modelType +
                            " on non dervatives external account type: " +
                            externalAccount.type
                    );
                }
                this.balanceSheet.addToDerivatives(custodian, value);
            }
        }
        // log if filled...
        if (Array.isArray(bondLog) && bondLog.length) console.log(bondLog);

        // process transactions
        for (const [instrumentId, value] of Object.entries(transactionAmountByInstrumentId)) {
            const instrument = instrumentsById[instrumentId];
            const issuerprogram = instrument.issuerProgramId ? issuerprogramsById[instrument.issuerProgramId] : null;
            const issuer = instrument.issuerId ? issuersById[instrument.issuerId] : null;
            const externalAccount = externalAccountsById[instrument.firstTradeExternalAccountId];
            if (!externalAccount) throw new Error("No external account with id " + instrument.firstTradeExternalAccountId);
            const custodian = issuersById[externalAccount.custodianId];
            if (!custodian) throw new Error("No custodianfor external account with id " + instrument.firstTradeExternalAccountId);

            if (instrument.modelType === InstrumentModelTypeEnum.Balance) {
                // no need to handle cash - only securities
                continue;
            } else if (instrument.modelType === InstrumentModelTypeEnum.Stock) {
                this.transactionSpecification.addToStocks(issuer, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.Bond) {
                this.transactionSpecification.addToBonds(issuer, issuerprogram, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.CdsIndex) {
                if (derivativeExternalAccountTypes[externalAccount.type]) this.transactionSpecification.addToDerivatives(custodian, value);
                else this.transactionSpecification.addToDerivatives(issuer, value);
            } else if (instrument.modelType === InstrumentModelTypeEnum.CdsBasket) {
                if (derivativeExternalAccountTypes[externalAccount.type]) this.transactionSpecification.addToDerivatives(custodian, value);
                else this.transactionSpecification.addToDerivatives(issuer, value);
            } else {
                // here we should be derivative - assert?

                if (!derivativeExternalAccountTypes[externalAccount.type]) {
                    throw new Error(
                        "Instrument of modelType: " +
                            instrument.modelType +
                            " on non dervatives external account type: " +
                            externalAccount.type
                    );
                }
                this.transactionSpecification.addToDerivatives(custodian, value);
            }
        }

        //--------------------------
        // process fund data
        //--------------------------
        // process share register
        // fi wants total units summed over classes which of corse is nonsense but...
        let totalFundUnits = 0;
        for (const shareRegisterItem of shareRegister) {
            if (shareRegisterItem.fundId === client._id) {
                const units = parseFloat(shareRegisterItem.units);
                // no need to add if 0
                if (Math.abs(units) < 1e-6) continue;
                totalFundUnits += units;
                const value = units * priceByIsin[shareRegisterItem.isin];
                this.balanceSheet.addToFund(shareRegisterItem.customer, value);
            }
        }

        // update total units
        this.totalUnits.value = totalFundUnits;

        // process share register delta
        for (const shareRegisterItem of shareRegisterDelta) {
            if (shareRegisterItem.fundId === client._id) {
                this.createRedeemTransactions.addToFund(shareRegisterItem.customer, parseFloat(shareRegisterItem.amount));
            }
        }

        // add classes
        if (client.fundInfo && Array.isArray(client.fundInfo.classes) && client.fundInfo.classes) {
            for (const shareClass of client.fundInfo.classes) {
                if (shareClass.enabled) {
                    const dividendPaid = dividendPaidByInstrumentId[shareClass.instrumentId];
                    const price = round(shareClass.instrument.valuations.price, 2);
                    if (dividendPaid) {
                        this.shareClasses.push(new ShareClass(shareClass.shareClass, price, dividendPaid.value, dividendPaid.date));
                    } else {
                        this.shareClasses.push(new ShareClass(shareClass.shareClass, price));
                    }
                }
            }
        }
    }

    postProcess(): void {
        //-----------------------
        // Balance Sheet
        //-----------------------
        this.balanceSheet.round();
        this.balanceSheet.calculateSums();
        // due to rounding issues
        if (this.balanceSheet.sumAssets.value !== this.balanceSheet.sumFundAndLiabilities.value) {
            // we accept 1e-4 of sumAssets for now since well below adjustment of nav for lowest risk class 0.1%...
            const diff = this.balanceSheet.sumAssets.value - this.balanceSheet.sumFundAndLiabilities.value;
            if (Math.abs(diff) > Math.abs(this.balanceSheet.sumAssets.value * 1e-4)) {
                throw new Error(
                    "Diff between sumAssets and sumFundAndLiabilities to large (larger than threshold 1e-4 * sumAssets): " + diff.toString()
                );
            }
            this.balanceSheet.adjustFundToAlignWithTotalAssets(
                this.balanceSheet.sumAssets.value - this.balanceSheet.sumFundAndLiabilities.value
            );
        }
        //----------------------------
        // Transaction specification
        //----------------------------
        this.transactionSpecification.round();
        this.transactionSpecification.calculateSums();
        //----------------------------
        // CreateRedeem specification
        //----------------------------
        this.createRedeemTransactions.round();
        this.createRedeemTransactions.calculateSums();
    }
}
