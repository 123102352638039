import React, { useState, useEffect, ReactElement } from "react";
import { cloneDeep } from "lodash";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Grid, Column } from "../../../../../components/src";
import { Dropdown, Alert } from "react-bootstrap";

import { YesNoModal } from "../../../components/YesNoModal";
import { useDeleteInstrumentMutation, useGetPartyQuery } from "../PartyQuery.generated";
import { PartyInstrumentForm } from "../PartyInstrumentForm";
import { CashOrBalanceInstrumentForm, instrumentTypes, NewCashOrBalanceInstrumentButton } from "../CashOrBalanceInstrumentForm";
import { InstrumentProductTypeEnum } from "../../../types.generated";
import { formikUrqlErrorFormater } from "../../../common/formik-urql-error-helper";
interface PartyInstrumentsTabPropsType {
    partyId: string;
}

export const PartyInstrumentsTab = ({ partyId }: PartyInstrumentsTabPropsType): ReactElement => {
    const navigate = useNavigate();
    const { itemId } = useParams<"itemId">();

    const [currentParty, setCurrentParty] = useState(cloneDeep(null));
    const [alert, setAlert] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });
    const [_, deleteInstrument] = useDeleteInstrumentMutation();

    useEffect(() => {
        if (data) {
            setCurrentParty(cloneDeep(data.party));
        }
    }, [data]);

    const selectInstrument = (instrumentId): void => {
        navigate(`/parties/${partyId}/instruments/${instrumentId}`, { replace: true });
    };

    if (loading) return <div>Loading</div>;
    if (error) return <div>{"Error: " + error.message}</div>;
    if (!currentParty) return <div></div>;

    const selectedInstrument = itemId ? currentParty.instruments.find((a) => a._id.toString() === itemId.toString()) : null;

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete party instrument with id " + modal.payload.instrumentId + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deleteInstrument({ _id: modal.payload.instrumentId })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    refetch();
                                    navigate(`/parties/${partyId}/instruments`);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}
            <div className="form-row">
                These are instruments such as bank cash accounts that are specific to party.
                <br />
            </div>
            <div id="partyinstrumentsform" className="partyinstrumentsform form">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="btn-toolbar mb-2" style={{ float: "right" }}>
                            <div className="mt-1">
                                <NewCashOrBalanceInstrumentButton page={"parties/" + partyId + "/instruments"} />
                            </div>
                        </div>
                    </div>
                </div>
                {alert ? (
                    <div className="row">
                        <div className="col-12">
                            <Alert style={{ marginTop: "10px" }} variant="danger" onClose={() => setAlert(null)} dismissible>
                                <p>{alert}</p>
                            </Alert>
                        </div>
                    </div>
                ) : null}
                <div className="row">
                    <div className={itemId ? "col-sm-12 col-lg-8" : "col"}>
                        <Grid data={currentParty.instruments} sortable tableClassName="table-xs">
                            <Column
                                field="_id"
                                format={(field) => {
                                    return (
                                        <a
                                            href="#selectInstrument"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                selectInstrument(field);
                                            }}
                                        >
                                            {field}
                                        </a>
                                    );
                                }}
                            />
                            <Column
                                field="name"
                                className="grid-column-sticky"
                                format={(value, item) => {
                                    return item && item._id ? <Link to={"/instruments/" + item._id}>{value}</Link> : value;
                                }}
                            />
                            <Column field="description" />
                            <Column title="IBAN" field="iban" />
                            <Column
                                field="_id"
                                title=""
                                format={(value) => {
                                    return (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="danger" size="sm">
                                                Delete
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={async () => {
                                                        setModal({ showModal: true, payload: { instrumentId: value, partyId: partyId } });
                                                    }}
                                                >
                                                    Delete instrument
                                                </Dropdown.Item>
                                                <Dropdown.Item>Cancel</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Grid>
                    </div>

                    {(itemId &&
                        selectedInstrument &&
                        (selectedInstrument.productType === InstrumentProductTypeEnum.CashAccount ||
                            selectedInstrument.productType === InstrumentProductTypeEnum.BalanceAccount)) ||
                    (itemId && !selectedInstrument) ||
                    (itemId && (itemId === "newcashaccount" || itemId === "newbalanceaccount")) ? (
                        <div className="col-sm-12 col-lg-4">
                            <Link className="close btn-close float-end" to={`/parties/${partyId}/instruments`} />
                            <CashOrBalanceInstrumentForm
                                id={itemId.startsWith("new") ? null : itemId}
                                cid={currentParty._id}
                                parties={data.parties ? data.parties : []}
                                productType={
                                    itemId.startsWith("new")
                                        ? instrumentTypes.find((d) => d.toLowerCase() === itemId.substring(3, itemId.length))
                                        : null
                                }
                                onCreate={() => {
                                    refetch();
                                }}
                                onUpdate={() => {
                                    refetch();
                                }}
                            />
                        </div>
                    ) : itemId &&
                      selectedInstrument &&
                      selectedInstrument.productType !== InstrumentProductTypeEnum.CashAccount &&
                      selectedInstrument.productType !== InstrumentProductTypeEnum.BalanceAccount ? (
                        <div className="col-sm-12 col-lg-4">
                            <Link className="close btn-close float-end" to={`/parties/${partyId}/instruments`} />
                            <PartyInstrumentForm
                                key={itemId}
                                partyId={currentParty._id}
                                instrument={selectedInstrument}
                                externalAccounts={currentParty.externalAccounts}
                                accounts={currentParty.accounts}
                                parties={data.parties ? data.parties : []}
                                onUpdate={() => {
                                    refetch();
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
