import React, { Fragment, useContext, ReactElement } from "react";

import { Page } from "../components/Page";
import { loginContext } from "./Login";
import { REACT_APP_AUTH_URI, REACT_APP_DB_NAME, REACT_APP_API_URI, REACT_APP_NAME } from "../env";

const linkFormater = (link, item): ReactElement => {
    return (
        <div>
            {link ? (
                <a href={link} rel="noopener noreferrer">
                    {item}
                </a>
            ) : null}
        </div>
    );
};

export const StatsPage = (): ReactElement => {
    const { login } = useContext(loginContext);

    return (
        <Page className="stats">
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <br />
                        <p>REACT_APP_AUTH_URI:{REACT_APP_AUTH_URI}</p>
                        <p>REACT_APP_DB_NAME:{REACT_APP_DB_NAME}</p>
                        <p>REACT_APP_API_URI:{REACT_APP_API_URI}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div>{JSON.stringify({ authorization: "Bearer " + login.token })}</div>
                    </div>
                </div>

                <hr />

                <h3>job API's</h3>

                {linkFormater(
                    `https://scheduler.${REACT_APP_NAME}.com/api/swagger/`,
                    `https://scheduler.${REACT_APP_NAME}.com/api/swagger/`
                )}
                {linkFormater(`https://pyjobapi.${REACT_APP_NAME}.com/swagger/`, `https://pyjobapi.${REACT_APP_NAME}.com/swagger/`)}
                {linkFormater(`https://nodejobapi.${REACT_APP_NAME}.com/`, `https://nodejobapi.${REACT_APP_NAME}.com/`)}
            </Fragment>
        </Page>
    );
};
