import {
    AccountingTransactionType,
    AccountingTransaction,
    JournalEntry,
    InstrumentProductTypeEnum,
    InstrumentCategory,
    PriceType,
    TransactionStatus,
    //Transaction,
    TransactionType,
    //TransactionItem,
    TransactionItemType,
    CurrencyEnum,
    AccountingRun
} from "../types.generated";

export interface AccountingTransactionExtended extends AccountingTransaction {
    journalEntry?: JournalEntryExtended;
}
export interface JournalEntryExtended extends JournalEntry {
    transactions?: AccountingTransactionExtended[];
    accountingRun?: AccountingRun;
}

export {
    AccountingTransactionType,
    InstrumentProductTypeEnum,
    InstrumentCategory,
    PriceType,
    TransactionStatus,
    TransactionType,
    TransactionItemType,
    CurrencyEnum
};

export const minDate = "0001-01-01";
export const minimumPosition = 1e-7;
export const emptyObjectId = "000000000000000000000000";
export const tradeTimeZone = "Europe/Stockholm";

export enum RealizedType {
    None = "None",
    Valuation = "Valuation",
    NoIncomeCost = "NoIncomeCost",
    IncomeCost = "IncomeCost",
    SettlementAmount = "SettlementAmount",
    RealizedProfitLoss = "RealizedProfitLoss"
}
