import React from "react";
import { useParams } from "react-router-dom";
import { useQueryState } from "../../common/use-query-state";
import { PreTrade } from "./PreTrade";

export interface Props {
    lookThrough: boolean;
}

export const PreTradeView = (props: Props): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));
    const { id } = useParams<"id">();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }
    return (
        <div>
            <PreTrade clientIds={[id]} endDate={endDate} lookThrough={props.lookThrough} />
        </div>
    );
};
