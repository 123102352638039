import React from "react";
import stableStringify from "json-stable-stringify";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";

import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";

interface CorporateActionPropTypes {
    corporateAction: any;
}

export const CorporateAction = ({ corporateAction }: CorporateActionPropTypes): React.ReactElement => {
    let text = "No corporate action found";
    if (corporateAction) {
        const corporateActionText = corporateAction.text.split("}{");
        const textString = corporateActionText.toString().split("\n");
        text = stableStringify(textString, { space: "  " });
    }
    return (
        <div>
            <pre>{text}</pre>
        </div>
    );
};

const GET_SWIFT = gql`
    query miniSwifts($miniFilterIn: MiniSwiftFilterInput) {
        miniSwifts(miniFilterIn: $miniFilterIn) {
            _id
            fundId
            fund {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            isin
            type
            instrument {
                _id
                name
            }
            currency
            amount
            foreignTax
            msgType
            caev
            status
            text
            externalId
            error
            correspondingTransactionId
            updateTimestamp
            parsed
            reportIds
            name
        }
    }
`;

export const CorporateActionPage = (): React.ReactElement => {
    const { id } = useParams<"id">();

    const [{ fetching, data, error }] = useQuery({
        query: GET_SWIFT,
        variables: {
            miniFilterIn: { idIn: [id], statusIn: ["Pending", "Preliminary", "Confirmed", "UpdatedPrevious", "Waiting", "Settled"] }
        }
    });

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.miniSwifts.length === 0) return <div>No corporateAction found</div>;

    const corporateAction = recursivelyRemoveKey(data.miniSwifts[0], "__typename");

    return <CorporateAction corporateAction={corporateAction} />;
};
