import React from "react";
import { gql, useQuery } from "urql";

import { Grid, Column } from "../../../../components/src";

const getFxValuations = gql`
    query getFxValuations($endDate: GraphQLSwedenDate) {
        fxValuations(date: $endDate) {
            instrumentId
            name
            price
            currency
            date
        }
    }
`;

export const Fx = ({ endDate }: { endDate: string }): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getFxValuations, variables: { endDate }, requestPolicy: "network-only" });
    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}</div>;
    if (data === "undefined" || typeof data === "undefined" || Object.entries(data).length === 0) {
        return null;
    }

    return (
        <div className="row">
            <div className="col">
                <Grid header="FX SEK cross rates" data={data.fxValuations} sortable tableClassName="table-xs">
                    <Column field="name" title="Name" />
                    <Column field="price" title="Value" />
                    <Column field="date" />
                </Grid>
            </div>
        </div>
    );
};
