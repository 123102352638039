/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GraphQLDateString: { input: string; output: string; }
  GraphQLJSON: { input: any; output: any; }
  GraphQLJSONObject: { input: any; output: any; }
  GraphQLObjectId: { input: string; output: string; }
  Timestamp: { input: any; output: any; }
};

export type WireCronSchedule = {
  __typename?: 'CronSchedule';
  day: Scalars['String']['output'];
  /** 0-6 for Sunday-Saturday */
  dayOfTheWeek: Scalars['String']['output'];
  hour: Scalars['String']['output'];
  minute: Scalars['String']['output'];
  month: Scalars['String']['output'];
};

export type WireCronScheduleInput = {
  day: Scalars['String']['input'];
  dayOfTheWeek: Scalars['String']['input'];
  hour: Scalars['String']['input'];
  minute: Scalars['String']['input'];
  month: Scalars['String']['input'];
};

export type WireJobDefinition = {
  __typename?: 'JobDefinition';
  _id: Scalars['GraphQLObjectId']['output'];
  args: Scalars['GraphQLJSON']['output'];
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<WireNameAndId>;
  createTimestamp: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  jobUser: WireNameAndId;
  jobUserId: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
  nextExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  runs: Array<WireJobRun>;
  schedule: WireCronSchedule;
  timezone: TimezoneEnum;
  type: JobTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: WireNameAndId;
  url: Scalars['String']['output'];
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};


export type WireJobDefinitionRunsArgs = {
  nbr?: InputMaybe<Scalars['Int']['input']>;
};

export type WireJobDefinitionFilterInput = {
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusIn?: InputMaybe<Array<JobTypeEnum>>;
};

export type WireJobDefinitionInput = {
  /** if _id is omitted, a Job will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  args: Scalars['GraphQLJSON']['input'];
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  description: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  jobUserId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  schedule: WireCronScheduleInput;
  timezone: TimezoneEnum;
  type: JobTypeEnum;
  url: Scalars['String']['input'];
};

export enum JobResultEnum {
  Enqueued = 'Enqueued',
  Failure = 'Failure',
  Processing = 'Processing',
  Running = 'Running',
  Succeeded = 'Succeeded'
}

export type WireJobRun = {
  __typename?: 'JobRun';
  _id: Scalars['GraphQLObjectId']['output'];
  endExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  jobDefinitionId: Scalars['GraphQLObjectId']['output'];
  log?: Maybe<Scalars['GraphQLJSON']['output']>;
  logId: Scalars['String']['output'];
  logUrl: Scalars['String']['output'];
  startExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  startStatusCode: Scalars['String']['output'];
  status?: Maybe<JobResultEnum>;
};

export type WireJobRunFilterInput = {
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export enum JobStatusEnum {
  Active = 'Active',
  Completed = 'Completed',
  Delayed = 'Delayed',
  Failed = 'Failed',
  Paused = 'Paused',
  Waiting = 'Waiting'
}

export enum JobTypeEnum {
  Manual = 'Manual',
  Scheduled = 'Scheduled'
}

export type WireMutation = {
  __typename?: 'Mutation';
  defaultMutation?: Maybe<Scalars['String']['output']>;
  executeJob: WireJobRun;
  upsertJobDefinitions: Array<WireJobDefinition>;
};


export type WireMutationExecuteJobArgs = {
  jobId: Scalars['GraphQLObjectId']['input'];
};


export type WireMutationUpsertJobDefinitionsArgs = {
  input: Array<WireJobDefinitionInput>;
};

export type WireNameAndId = {
  __typename?: 'NameAndId';
  _id: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
};

export type WireQuery = {
  __typename?: 'Query';
  defaultQuery?: Maybe<Scalars['String']['output']>;
  jobDefinitions: Array<WireJobDefinition>;
  jobRuns: Array<WireJobRun>;
};


export type WireQueryJobDefinitionsArgs = {
  filter?: InputMaybe<WireJobDefinitionFilterInput>;
};


export type WireQueryJobRunsArgs = {
  filter: WireJobRunFilterInput;
};

export enum TimezoneEnum {
  AmericaChicago = 'AmericaChicago',
  AmericaNew_York = 'AmericaNew_York',
  AsiaHong_Kong = 'AsiaHong_Kong',
  AsiaTokyo = 'AsiaTokyo',
  EuropeAmsterdam = 'EuropeAmsterdam',
  EuropeBerlin = 'EuropeBerlin',
  EuropeBrussels = 'EuropeBrussels',
  EuropeCopenhagen = 'EuropeCopenhagen',
  EuropeHelsinki = 'EuropeHelsinki',
  EuropeLondon = 'EuropeLondon',
  EuropeLuxembourg = 'EuropeLuxembourg',
  EuropeOslo = 'EuropeOslo',
  EuropeParis = 'EuropeParis',
  EuropeStockholm = 'EuropeStockholm',
  EuropeZurich = 'EuropeZurich'
}
