import React, { Fragment, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useParams, Link, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { Button } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { Panel, IColumn, ICommandBarItemProps } from "@fluentui/react";

import { twoDecPriceFormat } from "../../../common/src";

import { useQueryState } from "../common/use-query-state";
import { YesNoModal } from "../components/YesNoModal";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { MiniTransactionForm, miniTransactionTypes } from "./MiniTransactionForm";
import { CorporateActionForm } from "./CorporateActionForm";
import { MultipleSelectDetailsGrid } from "./MultipleSelectDetailsGrid";
import { SwiftStatusEnum, TransactionStatus, TransactionType, UpdateSwiftInput, UpdateTransactionInput } from "../types.generated";
import { TooltipLabel } from "../components/TooltipLabel";

const GET_SWIFTS_MINI = gql`
    query miniSwifts($miniFilterIn: MiniSwiftFilterInput) {
        miniSwifts(miniFilterIn: $miniFilterIn) {
            _id
            fundId
            clientId
            fund {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            isin
            type
            instrument {
                _id
                name
            }
            currency
            amount
            foreignTax
            msgType
            caev
            status
            text
            externalId
            error
            correspondingTransactionId
            correspondingTransaction {
                _id
                status
            }
            updateTimestamp
            parsed
            reportIds
            name
        }
    }
`;
const GET_MINI_TRANSACTIONS = gql`
    query miniTransactions($status: [TransactionStatus], $type: [TransactionType]) {
        miniTransactions(status: $status, type: $type) {
            _id
            type
            accountId
            tradeTimestamp
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            broker {
                _id
                name
            }
            uniqueTradeId
            brokerTradeId
            source
            instrumentId
            instrument {
                _id
                name
                modelType
            }
            currency
            valueDate
            settlementAmount
            clientId
            client {
                _id
                name
            }
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            accruedInterest
            agreementType
            clearingHouseId
            secondInstrumentId
            secondAmount
            secondCurrency
            bonusShares
            fee
            itemType
            updateTimestamp
        }
    }
`;

const MATCH_COACS_TRANSACTIONS = gql`
    mutation MatchCoacsTransactions($input: [MatchCoacsTransactionsInput!]!) {
        matchCoacsTransactions(input: $input) {
            swiftTransaction {
                _id
                status
                error
                correspondingTransactionId
            }
            transaction {
                _id
                status
                error
                brokerTradeId
            }
        }
    }
`;

const UPDATE_SWIFT_TRANSACTION = gql`
    mutation UpdateSwifts($input: [UpdateSwiftInput!]!) {
        updateSwifts(input: $input) {
            _id
            status
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

const transactionGroups = [
    TransactionStatus.Pending + " - NOT MATCHED",
    TransactionStatus.Pending + " - MATCHED",
    TransactionStatus.Preliminary,
    TransactionStatus.Confirmed
];
const groupByStatus = (transactions, transactionGroups) => {
    const result = {};
    transactionGroups.forEach((status) => {
        result[status] = [];
    });

    transactions = sortBy(transactions, "valueDate");
    transactions.forEach((transaction) => {
        let status = transaction.status;

        if (status === TransactionStatus.Pending) {
            if (transaction.correspondingTransactionId || transaction.brokerTradeId) {
                status = TransactionStatus.Pending + " - MATCHED";
            } else {
                status = TransactionStatus.Pending + " - NOT MATCHED";
            }
        }

        if (transactionGroups.includes(status)) {
            result[status].push(transaction);
        }
    });

    return result;
};

export const CorporateActionsUpdateGrid = ({ items, onUpdateStatusClick, onMatchTransactionClick }): React.ReactElement => {
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const columns: IColumn[] = [
        {
            key: "fundName",
            name: "Client",
            fieldName: "fundName",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 170,
            onRender: (item: any) => {
                if (item.fund && item.fund._id) {
                    return <Link to={"/parties/" + item.fund._id}>{item.fundName}</Link>;
                } else if (item.client && item.client._id) {
                    return <Link to={"/parties/" + item.client._id}>{item.fundName}</Link>;
                } else {
                    return null;
                }
            }
        },
        {
            key: "externalId",
            name: "Event ref/external id",
            fieldName: "externalId",

            isResizable: true,
            minWidth: 110,
            onRender: (item: any) => {
                if (item.__typename === "MiniTransaction") {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + item._id}>{item.externalId}</Link>
                    ) : null;
                } else {
                    return <Link to={"/reconcile/corporateactions/corporateaction/" + item._id}>{item.externalId}</Link>;
                }
            }
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 70
        },

        {
            key: "type",
            name: "Type",
            fieldName: "type",
            isResizable: true,
            minWidth: 60,
            onRender: (item: any) => {
                if (item.__typename === "MiniTransaction") {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + item._id}>{item.type}</Link>
                    ) : (
                        <span>{item.type}</span>
                    );
                } else {
                    return <Link to={"/reconcile/corporateactions/corporateaction/" + item._id}>{item.caev}</Link>;
                }
            }
        },
        {
            key: "instrumentName",
            name: "Instrument",
            fieldName: "instrumentName",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return item.instrument && item.instrument._id ? (
                    <Link to={"/instruments/" + item.instrument._id}>{item.instrumentName}</Link>
                ) : (
                    <span>{item.instrumentName}</span>
                );
            }
        },
        {
            key: "foreignTax",
            name: "Foreign tax",
            fieldName: "foreignTax",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.foreignTax)}
                    </span>
                );
            }
        },
        {
            key: "amount",
            name: "Amount",
            fieldName: "amount",
            isResizable: true,
            minWidth: 80,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.amount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80
        },
        {
            key: "valueDate",
            name: "Value date",
            fieldName: "valueDate",
            minWidth: 80,
            onRender: (item: any) => {
                if (item.valueDate < new Date().toISOString().slice(0, 10) && item.status !== TransactionStatus.Settled) {
                    return (
                        <span
                            style={{
                                color: "white",
                                backgroundColor: "crimson"
                            }}
                        >
                            {item.valueDate}
                        </span>
                    );
                } else {
                    return <span>{item.valueDate}</span>;
                }
            }
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            isResizable: true,
            minWidth: 90
        },
        {
            key: "correspondingTransactionId",
            name: "Matched with",
            fieldName: "correspondingTransactionId",
            isResizable: true,
            minWidth: 90,
            onRender: (item: any) => {
                if (item.correspondingTransactionId) {
                    return (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + item.correspondingTransactionId}>
                            {item.correspondingTransactionId}
                        </Link>
                    );
                } else {
                    return item.brokerTradeId ? <span>{item.brokerTradeId}</span> : null;
                }
            }
        },
        {
            key: "updateTimestamp",
            name: "Update timestamp",
            fieldName: "updateTimestamp",
            isResizable: true,
            minWidth: 120,
            onRender: (item: any) => {
                return <span>{dateFormater(item.updateTimestamp)}</span>;
            }
        }
    ];

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Pending");
                            }
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to Preliminary",
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Preliminary");
                            }
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Confirmed");
                            }
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Settled");
                            }
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            setModal({
                                showModal: true,
                                payload: { newStatus: "Deleted", ids: selected }
                            });
                        }
                    }
                ]
            }
        }
    ];

    // Match Transaction commands
    if (selected && selected.length === 1) {
        if (selected[0].correspondingTransactionId) {
            const removeMatchMenuItem = {
                key: "removeMatchMenuItem",
                text: "Remove Match",
                cacheKey: selected[0]._id,
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(selected[0], "remove");
                    }
                }
            };
            commands.push(removeMatchMenuItem);
        } else if (
            selected[0].__typename === "MiniSwift" &&
            selected[0].status === SwiftStatusEnum.Pending &&
            !selected[0].correspondingTransactionId
        ) {
            const noMatchManuallyOkMenuItem = {
                key: "noMatchManuallyOkMenuItem",
                text: "No match, manually checked",
                cacheKey: selected[0]._id,
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(selected[0], "No match, manually checked");
                    }
                }
            };
            commands.push(noMatchManuallyOkMenuItem);
        }
    } else if (selected && selected.length === 2) {
        if (
            (selected[0].__typename === "MiniSwift" && selected[1].__typename === "MiniTransaction") ||
            (selected[0].__typename === "MiniTransaction" && selected[1].__typename === "MiniSwift")
        ) {
            const swift = selected[0].__typename === "MiniSwift" ? selected[0] : selected[1];
            const transaction = selected[0].__typename === "MiniSwift" ? selected[1] : selected[0];

            const matchTransactionSwift = {
                key: "matchTransactionMenuItem",
                text: "Match",
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(swift, transaction._id);
                    }
                }
            };

            commands.push(matchTransactionSwift);
        }
    }

    const onSelectedChange = (e) => {
        setSelected(e);
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={
                        modal.payload.__typename === "MiniTransaction"
                            ? " Are you sure you want to delete selected transactions?"
                            : " Are you sure you want to delete selected corporate actions?"
                    }
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        if (onUpdateStatusClick) {
                            onUpdateStatusClick(modal.payload.ids, "Deleted");
                        }
                    }}
                />
            ) : null}
            <MultipleSelectDetailsGrid
                key={items}
                items={items}
                columns={columns}
                commands={selected && selected.length > 0 ? commands : []}
                onSelectedChange={onSelectedChange}
            />
        </div>
    );
};

export const CorporateActionsPage = (): React.ReactElement => {
    let relevantSwifts: any[] = [];
    const inputListDelete: any[] = [];
    let unknownSwifts: any[] = [];
    let swiftsResulting: any[] = [];
    let transactionsSwifts: any[] = [];
    // All swifts in pending preliminary confirmed
    const filterAll = {
        statusIn: [SwiftStatusEnum.Pending, SwiftStatusEnum.Preliminary, SwiftStatusEnum.Confirmed, SwiftStatusEnum.Waiting]
    };
    const [{ fetching: loading, error, data: allSwifts }, refetch] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterAll },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // msgtype 564

    // CAEV: DRIP/DVOP, needs manual attention + 4:98A: <= todays date -> possibly preliminary confirmed
    const tomorrow = serializeSwedenDate(new Date().setDate(new Date().getDate() + 1));
    const today = serializeSwedenDate(new Date());
    const filterPrel = {
        statusIn: [SwiftStatusEnum.Pending, SwiftStatusEnum.Preliminary, SwiftStatusEnum.Confirmed, SwiftStatusEnum.Waiting],
        caev: ["DRIP", "DVOP"],
        rdteEnd: today,
        msgType: "564"
    };
    const [{ error: errorSwiftPrel, data: swiftPreliminary }, refetchSwiftPrel] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterPrel },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });
    // CAEV: DRIP/DVOP, needs manual attention + 4:98A: > todays date -> waiting until included in rdteEnd: today above
    const filterWait = {
        statusIn: [SwiftStatusEnum.Pending, SwiftStatusEnum.Preliminary, SwiftStatusEnum.Confirmed],
        caev: ["DRIP", "DVOP"],
        rdteStart: tomorrow,
        msgType: "564"
    };
    const [{ error: errorSwiftWait, data: swiftWait }, refetchSwiftWait] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterWait },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });
    // CAEV: DVCA/INTR/SHPR/CAPG/CAPD + 4:98A: <= todays date -> possibly confirm
    const filterConf = {
        statusIn: [SwiftStatusEnum.Pending, SwiftStatusEnum.Preliminary, SwiftStatusEnum.Confirmed],
        caev: ["DVCA", "INTR", "SHPR", "CAPG", "CAPD"],
        rdteEnd: today,
        msgType: "564"
    };
    const [{ error: errorSwiftConf, data: swiftConf }, refetchSwiftConf] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterConf },
        requestPolicy: "network-only"
        //pollInterval: 5000
    });
    // CAEV: DVCA/INTR/SHPR/CAPG/CAPD/DRIP/DVOP + 4:98A: >todays date
    const filterDrop = { caev: ["DVCA", "INTR", "SHPR", "CAPG", "CAPD", "DRIP", "DVOP"], rdteStart: tomorrow, msgType: "564" };
    const [{ error: errorSwiftDrop, data: swiftDrop }, refetchSwiftDrop] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterDrop },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // msgtype 566, all updates
    const filterUpdate = {
        statusIn: [SwiftStatusEnum.Pending, SwiftStatusEnum.Preliminary, SwiftStatusEnum.Confirmed, SwiftStatusEnum.Waiting],
        msgType: "566"
    };
    const [{ error: errorSwiftUpdate, data: swiftUpdate }, refetchSwiftUpdate] = useQuery({
        query: GET_SWIFTS_MINI,
        variables: { miniFilterIn: filterUpdate },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // For msgType 566, see if we have previous swift of type 564 and update that
    const [stateUpdate, updateSwifts] = useMutation(UPDATE_SWIFT_TRANSACTION);

    if (swiftConf && swiftUpdate && swiftPreliminary && allSwifts && swiftDrop) {
        relevantSwifts = cloneDeep(swiftPreliminary.miniSwifts.concat(swiftConf.miniSwifts, swiftUpdate.miniSwifts));
        const relevantSwiftsIds = [];
        relevantSwifts.forEach(function (obj) {
            obj.tradeDate = obj.exDate ? obj.exDate : null;
            obj.valueDate = obj.paymentDate ? obj.paymentDate : null;
            obj.fundName = obj.fund ? obj.fund.name : null;
            obj.instrumentName = obj.instrument ? obj.instrument.name : null;
            relevantSwiftsIds.push(obj._id);
        });
        const nonRelevantSwiftIds = [];

        swiftDrop.miniSwifts.forEach(function (obj) {
            //obj.tradeDate = obj.exDate ? obj.exDate : null;
            //obj.valueDate = obj.paymentDate ? obj.paymentDate : null;
            //obj.fundName = obj.fund ? obj.fund.name : null;
            //obj.instrumentName = obj.instrument ? obj.instrument.name : null;
            nonRelevantSwiftIds.push(obj._id);
            const input = {
                _id: obj._id,
                status: "Deleted"
            };
            inputListDelete.push(input);
        });

        const knowHowToHandleSwiftsIds = relevantSwiftsIds.concat(nonRelevantSwiftIds);

        // Get the unknown pile of swifts, removing the ones we know we want to look at and the ones we know we do not want to look at
        const allMiniSwiftsClone = cloneDeep(allSwifts.miniSwifts);
        unknownSwifts = allMiniSwiftsClone.filter(function (obj) {
            obj.tradeDate = obj.exDate ? obj.exDate : null;
            obj.valueDate = obj.paymentDate ? obj.paymentDate : null;
            obj.fundName = obj.fund ? obj.fund.name : null;
            obj.instrumentName = obj.instrument ? obj.instrument.name : null;
            return !knowHowToHandleSwiftsIds.includes(obj._id);
        });

        swiftsResulting = relevantSwifts.concat(unknownSwifts);
    }

    const [{ fetching: loadingT, error: errorT, data: dataT }, refetchT] = useQuery({
        query: GET_MINI_TRANSACTIONS,
        variables: {
            status: [TransactionStatus.Pending, TransactionStatus.Preliminary, TransactionStatus.Confirmed],
            type: [TransactionType.Dividend, TransactionType.Interest]
        },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    const [stateMatch, matchTransactions] = useMutation(MATCH_COACS_TRANSACTIONS);
    const [stateUpdateSwift, updateSwiftStatus] = useMutation(UPDATE_SWIFT_TRANSACTION);
    const [stateUpdateTransaction, updateTransactionStatus] = useMutation(UPDATE_TRANSACTIONS);

    if (loading) return <p>Loading swift transactions</p>;
    if (loadingT) return <p>Loading transactions</p>;
    if (stateUpdate.fetching || stateMatch.fetching) return <p>Loading...</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorT) return <p>error: {JSON.stringify(errorT, null, 2)}</p>;
    if (errorSwiftPrel) return <p>error: {JSON.stringify(errorSwiftPrel, null, 2)}</p>;
    if (errorSwiftWait) return <p>error: {JSON.stringify(errorSwiftWait, null, 2)}</p>;
    if (errorSwiftConf) return <p>error: {JSON.stringify(errorSwiftConf, null, 2)}</p>;
    if (errorSwiftDrop) return <p>error: {JSON.stringify(errorSwiftDrop, null, 2)}</p>;
    if (errorSwiftUpdate) return <p>error: {JSON.stringify(errorSwiftUpdate, null, 2)}</p>;
    if (stateUpdate.error) return <p>error: {JSON.stringify(stateUpdate.error, null, 2)}</p>;
    if (stateUpdateSwift.error) return <p>error: {JSON.stringify(stateUpdateSwift.error, null, 2)}</p>;
    if (stateUpdateTransaction.error) return <p>error: {JSON.stringify(stateUpdateTransaction.error, null, 2)}</p>;
    if (stateMatch.error) return <p>error: {JSON.stringify(errorT, null, 2)}</p>;

    // Filter transactions to show in pending list, keep relevant dividends and interests
    let extendedMiniTransactions;
    if (dataT.miniTransactions && swiftsResulting) {
        const miniTransactionsClone = cloneDeep(dataT.miniTransactions);
        extendedMiniTransactions = miniTransactionsClone.filter(function (t) {
            //return (!t.error || t.error === "No matching corporate action transaction found.") && t.instrument.type === "Bond";
            t.tradeDate = serializeSwedenDate(t.tradeTimestamp);
            t.fundName = t.client ? t.client.name : null;
            t.instrumentName = t.instrument ? t.instrument.name : null;
            return t.instrument.modelType === "Bond" || t.instrument.modelType === "Stock";
        });

        // Filter transactions to show (not show both swift and transaction if matched)
        const filteredMiniTransactions = extendedMiniTransactions.filter(function (t) {
            return (
                (!t.error && t.status === "Pending") ||
                (t.error === "No matching corporate action transaction found." && t.status === "Pending")
            );
        });
        transactionsSwifts = [...swiftsResulting, ...filteredMiniTransactions];
    }

    // Update status for DRIP/DVOP with ex-date further ahead to waiting
    if (swiftWait && swiftWait.miniSwifts && swiftWait.miniSwifts.length) {
        const swiftsWaitingInput: UpdateSwiftInput[] = [];
        for (let s = 0; s < swiftWait.length; s++) {
            const swift = cloneDeep(swiftWait[s]);
            swiftsWaitingInput.push({ _id: swift._id, status: SwiftStatusEnum.Waiting, clientId: swift.clientId });
        }
        if (swiftsWaitingInput.length) {
            updateSwiftStatus({
                input: swiftsWaitingInput
            })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchSwiftPrel();
                        refetchSwiftWait();
                        refetchSwiftConf();
                        refetchSwiftDrop();
                        refetchSwiftUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const groups = groupByStatus(transactionsSwifts, transactionGroups);

    const updateStatus = async (items, changeTo) => {
        const updateTransactionsInput: UpdateTransactionInput[] = [];
        const updateSwiftsInput: UpdateSwiftInput[] = [];
        for (const item of items) {
            // Different mutations for swiftCorporateActions/transactions
            if (item.type && item.type === "ISO15022") {
                updateSwiftsInput.push({ _id: item._id, status: changeTo, clientId: item.clientId });
            } else {
                updateTransactionsInput.push({ _id: item._id, status: changeTo });
            }
        }
        if (updateTransactionsInput.length) {
            updateTransactionStatus({
                input: updateTransactionsInput
            }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetchT();
                }
            });
        }
        if (updateSwiftsInput.length) {
            updateSwiftStatus({
                input: updateSwiftsInput
            })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchSwiftPrel();
                        refetchSwiftWait();
                        refetchSwiftConf();
                        refetchSwiftDrop();
                        refetchSwiftUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const doMatchTransactions = (item, changeTo) => {
        if (changeTo === "remove") {
            const matchTransaction = [
                {
                    swiftTransactionId: item._id,
                    swiftExternalId: item.externalId,
                    swiftTransactionError: null,
                    transactionId: item.correspondingTransactionId,
                    transactionError: null,
                    matchingStatus: "Mismatch"
                }
            ];
            matchTransactions({ input: matchTransaction })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchT();
                        refetchSwiftPrel();
                        refetchSwiftWait();
                        refetchSwiftConf();
                        refetchSwiftDrop();
                        refetchSwiftUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            console.log("matching coacs and transactions", matchTransaction);
        } else if (changeTo !== "No match, manually checked") {
            const matchTransaction = [
                {
                    swiftTransactionId: item._id,
                    swiftExternalId: item.externalId,
                    swiftTransactionError: "Manually matched",
                    transactionId: changeTo,
                    transactionError: "Manually matched",
                    matchingStatus: "Confirmed"
                }
            ];

            console.log(matchTransaction);
            matchTransactions({ input: matchTransaction }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                    refetchT();
                    refetchSwiftPrel();
                    refetchSwiftWait();
                    refetchSwiftConf();
                    refetchSwiftDrop();
                    refetchSwiftUpdate();
                }
            });
            console.log("matching coacs and transactions", matchTransaction);
        } else {
            const updateSwift = {
                _id: item._id,
                clientId: item.clientId,
                status: "Settled",
                correspondingTransactionId: null,
                error: changeTo
            };

            console.log(updateSwift);
            updateSwifts({ input: [updateSwift] }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                    refetchSwiftPrel();
                    refetchSwiftWait();
                    refetchSwiftConf();
                    refetchSwiftDrop();
                    refetchSwiftUpdate();
                }
            });
            console.log("updating swift", updateSwift);
        }
    };

    return (
        <div>
            <br />
            <Button
                variant="info"
                type="submit"
                style={{ marginLeft: "2px" }}
                onClick={() => {
                    const win = window.open("/swiftcorporateactions", "_blank");
                    win.focus();
                }}
            >
                <TooltipLabel text={"Coacs page"} content={"Page showing all coacs from a certain start date. All statuses are shown."} />
            </Button>
            <br />
            <br />
            <Fragment>
                {transactionGroups.map((status) => (
                    <div key={status} className="mt-4">
                        <h2>{status}</h2>
                        <CorporateActionsUpdateGrid
                            items={groups[status]}
                            onMatchTransactionClick={doMatchTransactions}
                            onUpdateStatusClick={updateStatus}
                        />
                    </div>
                ))}
            </Fragment>
        </div>
    );
};

export function CorporateActionsCompletePage(): React.ReactElement {
    const params: any = useParams();
    const { id, type } = params;

    // Needed for corporate action form, possible transactions to match with
    const [exDateStart] = useQueryState("exDateStart", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="row">
                <div className={"col-12"}>
                    <CorporateActionsPage />
                </div>
            </div>
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/corporateactions`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                {id && type === "corporateaction" ? <CorporateActionForm id={id} tradeDate={exDateStart} /> : null}
            </Panel>
        </Fragment>
    );
}
