import React, { useState, useEffect, ReactElement } from "react";
import { cloneDeep, keyBy } from "lodash";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { Grid, Column } from "../../../../../components/src";

import { YesNoModal } from "../../../components/YesNoModal";
import { useGetPartyQuery, useDeletePartyAccountMutation } from "../PartyQuery.generated";
import { PartyAccountForm } from "../PartyAccountForm";
import { Mermaid } from "../../../components/Mermaid";
interface PartyAccountsTabPropsType {
    partyId: string;
}

export const PartyAccountsTab = ({ partyId }: PartyAccountsTabPropsType): ReactElement => {
    const navigate = useNavigate();
    const { itemId } = useParams<"itemId">();

    const [currentPartyData, setCurrentPartyData] = useState(cloneDeep(null));
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId }
    });
    const [_, deletePartyAccount] = useDeletePartyAccountMutation();

    useEffect(() => {
        if (data) {
            setCurrentPartyData(cloneDeep(data));
        }
    }, [data]);

    const selectAccount = (accountId): void => {
        navigate(`/parties/${partyId}/accounts/${accountId}`, { replace: true });
    };

    if (loading) return <div>Loading</div>;
    if (error) return <div>{"Error: " + error.message}</div>;
    if (!currentPartyData) return <div></div>;

    const selectedAccount =
        itemId && currentPartyData.party && currentPartyData.party.accounts
            ? currentPartyData.party.accounts.find((a) => a._id.toString() === itemId.toString())
            : null;

    const accountsById =
        currentPartyData && currentPartyData.party && currentPartyData.party.accounts
            ? keyBy(currentPartyData.party.accounts, "_id")
            : null;

    let md = `~~~mermaid
    graph TB\n`;
    let treeExist = false;
    if (currentPartyData && currentPartyData.party && currentPartyData.party.accounts) {
        currentPartyData.party.accounts.forEach((account) => {
            if (account.parentAccountId !== "000000000000000000000000") {
                const parent = accountsById[account.parentAccountId];
                if (parent) {
                    treeExist = true;
                    const parentDescription = parent.description || "parent.description";
                    const accountDescription = account.description || "account.description";
                    md = md + account.parentAccountId + "(" + parentDescription + ")-->" + account._id + "(" + accountDescription + ") \n";
                }
            }
        });
    }

    md = md + "~~~\n";

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete account with id " + modal.payload.accountId + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deletePartyAccount({ partyId: modal.payload.partyId, accountId: modal.payload.accountId })
                            .then(() => {
                                refetch();
                                navigate(`/parties/${partyId}/accounts`);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }}
                />
            ) : null}
            <div id="partyaccountsform" className="partyaccountsform form">
                <div className="row">{treeExist ? <Mermaid markdown={md} /> : null}</div>

                <div className="form-row mb-5">Account is a transaction grouper, like a portfolio.</div>
                <Button
                    className="mt-2"
                    variant="success"
                    size="sm"
                    onClick={() => {
                        navigate(`/parties/${partyId}/accounts/new`);
                    }}
                >
                    Add account
                </Button>

                <div className="row mb-5">
                    <div className={itemId ? "col-sm-12 col-lg-7" : "col"}>
                        {currentPartyData && currentPartyData.party ? (
                            <div>
                                <Grid data={currentPartyData.party.accounts} sortable tableClassName="table-xs">
                                    <Column
                                        field="_id"
                                        format={(field) => {
                                            return (
                                                <a
                                                    href="#selectAccount"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        selectAccount(field);
                                                    }}
                                                >
                                                    {field}
                                                </a>
                                            );
                                        }}
                                    />
                                    <Column field="name" className="grid-column-sticky" />
                                    <Column field="type" />
                                    <Column field="description" />
                                    <Column
                                        field="_id"
                                        title=""
                                        format={(value) => {
                                            return (
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="danger" size="sm">
                                                        Delete
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={async () => {
                                                                setModal({
                                                                    showModal: true,
                                                                    payload: { accountId: value, partyId: partyId }
                                                                });
                                                            }}
                                                        >
                                                            Delete account
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>Cancel</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            );
                                        }}
                                    />
                                </Grid>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    {itemId && currentPartyData && currentPartyData.party ? (
                        <div className="col-sm-12 col-lg-5">
                            <Link className="close btn-close float-end" to={`/parties/${partyId}/accounts`} />
                            <PartyAccountForm
                                key={itemId}
                                partyId={currentPartyData.party._id}
                                account={selectedAccount}
                                externalAccounts={currentPartyData.party.externalAccounts}
                                accounts={currentPartyData.party.accounts}
                                partyInstruments={currentPartyData.party.instruments}
                                instruments={currentPartyData.instruments}
                                onCreate={() => {
                                    refetch();
                                    navigate(`/parties/${partyId}/accounts`);
                                }}
                                onUpdate={() => {
                                    refetch();
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
