import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);
interface IValuePerformanceTimeSeriesChartProps {
    value: any;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const ValuePerformanceTimeSeriesChart = ({
    value,
    config,
    layout,
    ...props
}: IValuePerformanceTimeSeriesChartProps): React.ReactElement => {
    const portf = value;

    let ts1 = portf.returnTimeSeries;
    ts1 = ts1.mult(1 / ts1.startValue);
    const ts1m1 = ts1.add(-1);
    const data = [PlotlyDefaults.toTrace(ts1m1, portf.name, PlotlyDefaults.getColor(0))];
    const max = ts1m1.values.reduce((p, c) => Math.max(Math.abs(c), p), 0);
    if (portf.benchmarkTimeSeries && portf.benchmarkTimeSeries.length > 0) {
        let ts2 = portf.benchmarkTimeSeries;
        ts2 = ts2.range(ts1.start, ts1.end);
        ts2 = ts2.mult(ts1.latestValue(ts2.start) / ts2.startValue);
        const tr2: any = PlotlyDefaults.toTrace(ts2.add(-1), portf.benchmarkName, "rgba(120, 20, 130, .7)");
        tr2.line.dash = "dot";
        data.push(tr2);
    }
    const localLayout = {
        autosize: true,
        xaxis: { hoverformat: "%b %d" },
        yaxis: { tickformat: max < 0.06 ? ".1%f" : "%f" },
        width: null,
        height: null
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return <Plot data={data} layout={thisLayout} config={thisConfig} {...props} />;
};
