/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetClientsUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientsUsersQuery = { __typename?: 'Query', me?: { __typename?: 'Me', _id: string } | null, ticketUsers?: Array<{ __typename?: 'TicketUser', _id: string, name: string, clients?: Array<{ __typename?: 'PartyInfo', _id: string, name: string } | null> | null }> | null };

export type GetTicketsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TicketFilterInput>;
}>;


export type GetTicketsQuery = { __typename?: 'Query', tickets?: Array<{ __typename?: 'Ticket', _id: string, title: string, clientId: string, type: Types.TicketTypeEnum, creatorId: string, createTimestamp: any, updateTimestamp?: any | null, client: { __typename?: 'PartyInfo', _id: string, name: string }, creator: { __typename?: 'UserInfo', name: string }, state: { __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', _id: string, name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }, previousStates: Array<{ __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }> } | null> | null };


export const GetClientsUsersDocument = gql`
    query getClientsUsers {
  me {
    _id
  }
  ticketUsers {
    _id
    name
    clients {
      _id
      name
    }
  }
}
    `;

export function useGetClientsUsersQuery(options?: Omit<Urql.UseQueryArgs<GetClientsUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetClientsUsersQuery, GetClientsUsersQueryVariables>({ query: GetClientsUsersDocument, ...options });
};
export const GetTicketsDocument = gql`
    query getTickets($filter: TicketFilterInput) {
  tickets(filter: $filter) {
    _id
    title
    clientId
    client {
      _id
      name
    }
    type
    creatorId
    creator {
      name
    }
    createTimestamp
    updateTimestamp
    state {
      status
      assigneeIds
      assignees {
        _id
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
    previousStates {
      status
      assigneeIds
      assignees {
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
  }
}
    `;

export function useGetTicketsQuery(options?: Omit<Urql.UseQueryArgs<GetTicketsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTicketsQuery, GetTicketsQueryVariables>({ query: GetTicketsDocument, ...options });
};