import React from "react";
import { FactTable } from "../FactTable";
import { numberFormat, DateHelper } from "../../../common/src";

interface IPortfolioMonthlyReturnsProps {
    value: any;
    header: string;
}

export default class PortfolioMonthlyReturns extends React.Component<IPortfolioMonthlyReturnsProps> {
    render() {
        const ts = this.props.value;
        const { header } = this.props;
        const monthArray = function () {
            const res = [];
            for (let i = 0; i < 12; i++) res.push(Number.NaN);
            return res;
        };
        const addNewYear = function (tbl, y) {
            const curr = { year: y, values: monthArray() };
            tbl.push(curr);
            return curr;
        };
        const yearlyReturns = {};
        const tbl = [];
        let curr = null;
        ts.__values.forEach(function (d, i) {
            const timestamp = ts.__dates[i];
            let y = DateHelper.getYear(timestamp);
            let yr = yearlyReturns[y];
            if (typeof yr === "undefined") {
                yr = 1;
            }
            yearlyReturns[y] = yr * (1 + d);
            let m = DateHelper.getMonth(timestamp);
            if (!curr || curr.year !== y) {
                curr = addNewYear(tbl, y);
            }
            curr.values[m] = d;
        });
        tbl.sort((x, y) => y.year - x.year);
        tbl.forEach((d) => {
            const r = yearlyReturns[d.year] - 1;
            d.yearReturn = r;
        });
        const heads = ["Helår", "Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
        const list = [{ key: null, values: heads }];
        tbl.forEach((d) => {
            const item = {
                key: d.year,
                values: [d.yearReturn].concat(d.values).map((e) => (typeof e === "number" ? numberFormat(e, "0.0%") : e))
            };
            list.push(item);
        });
        return <FactTable className="portfolio-monthly-returns" header={header} list={list} />;
    }
}
