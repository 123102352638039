import React from "react";
import { Link } from "react-router-dom";
import { Alias, CollectionNameEnum } from "../types.generated";

export const defaultAlias: Alias = {
    __typename: "Alias",
    collection: CollectionNameEnum.Party,
    documentId: "000000000000000000000000",
    key: "",
    value: "",
    comment: ""
};

export const aliasLabel = (collection: CollectionNameEnum, documentId: string): React.ReactElement | string => {
    if (collection === CollectionNameEnum.Party) {
        return <Link to={"/parties/" + documentId}>{collection}</Link>;
    } else {
        return collection;
    }
};
