import React, { useEffect, useState, useMemo } from "react";
import { cloneDeep, sortBy, keyBy } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import { useQuery, useMutation } from "urql";

import { PartialDeep } from "type-fest";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import * as Yup from "yup";

import { recursivelyRemoveKey } from "../../../../common/src";

import {
    NoteFilterInput,
    Note,
    NoteInput,
    CollectionNameEnum,
    NoteReference,
    NoteAssetEnum,
    NoteTag,
    NoteStatusEnum
} from "../../types.generated";
import { SYSTEM_PARTY_ID } from "../../Constants";
import { usePrevious } from "../../common/Utils";
import { SearchMultipleSelectField, MaybeInListField, SearchListField, SelectField, SubmitButton, TextField } from "../../components/form";
import { useQueryState } from "../../common/use-query-state";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";

import { GET_NOTES, GET_OPTIONS, UPSERT_NOTES } from "./queries";
import { LinkLookup, ButtonsView, NoteView } from "./NoteComponents";
import { NotePageProps } from "./NoteComponents";
import { YesNoModal } from "../../components/YesNoModal";
import { MarkDownField } from "../../components/form/MarkDownField";

const defaultFormData: PartialDeep<Note> = {
    __typename: "Note",
    _id: null,
    title: "",
    clientIds: [SYSTEM_PARTY_ID],
    creatorId: "",
    refs: [],
    tags: [],
    data: "",
    asset: NoteAssetEnum.Base,
    createTimestamp: "",
    updateTimestamp: "",
    updateUserId: "",
    status: NoteStatusEnum.Active
};

export const defaultRef: NoteReference = {
    __typename: "NoteReference",
    collection: CollectionNameEnum.None,
    documentId: "000000000000000000000000"
};

export const defaultTag: NoteTag = {
    __typename: "NoteTag",
    type: "",
    value: ""
};

const getDefaultTag = () => {
    const tag = cloneDeep(defaultTag);
    return tag;
};

export const NotePage = ({ routeToNotes, routeToNotesText }: NotePageProps): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);
    const [edit, setEdit] = useQueryState("edit", false);
    const [formData, setFormData] = useState<PartialDeep<Note>>(null);
    const filter: NoteFilterInput = { idIn: [id === "new" ? "000000000000000000000000" : id] };
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [{ data, error }, refetch] = useQuery({
        query: GET_NOTES,
        variables: { filter },
        requestPolicy: "cache-and-network"
    });

    const [{ data: dataOptions, fetching: fetchingOptions, error: errorOptions }] = useQuery({
        query: GET_OPTIONS,
        requestPolicy: "cache-and-network"
    });

    const [_state, executeMutation] = useMutation(UPSERT_NOTES);

    useEffect(() => {
        if (previousId && previousId !== id) {
            setFormData(null);
            refetch();
        } else if (data && formData === null && id === "new") {
            const initFormData = cloneDeep(defaultFormData);
            setFormData(initFormData);
        } else if (formData === null && data && data.notes.length === 1) {
            const init = cloneDeep(data.notes[0]);
            setFormData(init);
        }
    }, [previousId, id, data, formData, refetch]);

    const { refOptions, docNameById } = useMemo(() => {
        if (dataOptions) {
            const newRefOptions: Record<string, { _id: string; name: string }[]> = {};

            const parties = sortBy(dataOptions.parties, "name");
            const instruments = sortBy(dataOptions.instruments, "name");
            const issuerprograms = sortBy(dataOptions.issuerprograms, "name");
            const screens = sortBy(dataOptions.screens, "name");

            parties.push({ _id: "000000000000000000000000", name: "None" });
            instruments.push({ _id: "000000000000000000000000", name: "None" });
            issuerprograms.push({ _id: "000000000000000000000000", name: "None" });
            screens.push({ _id: "000000000000000000000000", name: "None" });

            newRefOptions[CollectionNameEnum.Instrument] = instruments;
            newRefOptions[CollectionNameEnum.IssuerProgram] = issuerprograms;
            newRefOptions[CollectionNameEnum.Party] = parties;
            newRefOptions[CollectionNameEnum.Screen] = screens;
            newRefOptions[CollectionNameEnum.None] = [{ _id: "000000000000000000000000", name: "None" }];

            let docNameById: Record<string, { _id: string; name: string }> = {};
            for (const collection of Object.keys(newRefOptions)) {
                docNameById = { ...docNameById, ...keyBy(newRefOptions[collection], "_id") };
            }

            return { refOptions: newRefOptions, docNameById: docNameById };
        }
        return { refOptions: {}, docNameById: {} };
    }, [dataOptions]);

    if (!formData) return <p>Loading...</p>;

    if (error) {
        const message = formikUrqlErrorFormater(error as any, null);
        return (
            <div>
                <h3>error</h3>
                <p>{message}</p>;
            </div>
        );
    }

    if (fetchingOptions || !refOptions) return <p>Loading....</p>;

    if (errorOptions) {
        const message = formikUrqlErrorFormater(errorOptions as any, null);
        return (
            <div>
                <h3>error</h3>
                <p>{message}</p>;
            </div>
        );
    }

    if (id !== "new" && data.notes.length === 0) {
        return <div>No note with id {id}</div>;
    }

    if (!formData) {
        return <div></div>;
    }

    const existingTypes: Record<string, string> = {};
    const existingValues: Record<string, string> = {};

    if (dataOptions) {
        dataOptions.notes.forEach((note) => {
            const tags: NoteTag[] = note.tags;
            tags.forEach((tag) => {
                if (tag.type) {
                    if (!existingTypes[tag.type]) {
                        existingTypes[tag.type] = tag.type;
                    }
                }
                if (tag.value) {
                    if (!existingValues[tag.value]) {
                        existingValues[tag.value] = tag.value;
                    }
                }
            });
        });
    }

    const clients = sortBy(dataOptions.clients, "name");

    return (
        <div className="page">
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete note with id " + modal.payload[0]._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        executeMutation({ input: modal.payload })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    if (id === "new") {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(result.data.upsertNotes[0]._id);
                                        navigate(path.join("/"), { replace: true });
                                    } else {
                                        const init = cloneDeep(result.data.upsertNotes[0]);

                                        setFormData(init);
                                        setEdit(false);
                                    }
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}
            <Button
                type="button"
                className="btn-sm mb-3"
                onClick={() => {
                    // not sure I like this button instead of Link
                    const win = window.open(routeToNotes, "_self");
                    win.focus();
                }}
            >
                {routeToNotesText}
            </Button>
            {edit || id === "new" ? (
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={formData}
                    validationSchema={Yup.object({
                        title: Yup.string()
                            .required("Please enter a title, minimum 5 characters")
                            .min(5)
                            .matches(/^([a-zA-Z0-9_-]*)$/, "Must only contain a-zA-Z09_-"),
                        tags: Yup.array(
                            Yup.object({
                                key: Yup.string().matches(/^([a-zA-Z0-9_-]*)$/, "Must only contain a-zA-Z09_-"),
                                value: Yup.string().matches(/^([a-zA-Z0-9_-]*)$/, "Must only contain a-zA-Z09_-")
                            })
                        )
                    })}
                    validate={(_note) => {
                        const errors: any = {};
                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    onSubmit={async (submitValues, { setErrors }) => {
                        let input: NoteInput[] = [
                            {
                                _id: submitValues._id === "new" ? null : submitValues._id,
                                title: submitValues.title,
                                clientIds: submitValues.clientIds,
                                data: submitValues.data,
                                refs: submitValues.refs,
                                tags: submitValues.tags,
                                asset: submitValues.asset,
                                status: NoteStatusEnum.Active
                            }
                        ];
                        input = recursivelyRemoveKey(input, "__typename");
                        if (input[0].status && input[0].status === NoteStatusEnum.Deleted) {
                            setModal({ showModal: true, payload: input });
                        } else {
                            await executeMutation({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        if (id === "new") {
                                            const path = location.pathname.split("/");
                                            path.pop();
                                            path.push(result.data.upsertNotes[0]._id);
                                            navigate(path.join("/"), { replace: true });
                                        } else {
                                            const init = cloneDeep(result.data.upsertNotes[0]);
                                            setFormData(init);
                                            setEdit(false);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                });
                        }
                    }}
                >
                    {({ isSubmitting, values }) => {
                        return (
                            <Form autoComplete="off">
                                <div className="row">
                                    <div className="col-md-9">{id === "new" ? <h3>New note</h3> : <h3>Edit note</h3>}</div>
                                    <div className="col-md-3">{id === "new" ? null : ButtonsView(null, setEdit)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-9 mt-3">
                                        <div className="form-row">
                                            <TextField
                                                name="title"
                                                label={<label>Title</label>}
                                                type="text"
                                                className="col-12"
                                                spellCheck="false"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <MarkDownField
                                                name="data"
                                                label={"Note"}
                                                type="text"
                                                className="col-12"
                                                style={{ height: `calc(100vh - 25rem` }}
                                                initialEditMode={true}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 mt-3">
                                        <SearchMultipleSelectField
                                            name="clientIds"
                                            label="This document is owned by"
                                            className=""
                                            disabled={isSubmitting}
                                            options={clients.map((d) => ({ key: d._id, value: d.name, text: d.name }))}
                                        />
                                        <SelectField
                                            name={"status"}
                                            label={"Status"}
                                            options={Object.keys(NoteStatusEnum)}
                                            className=""
                                            disabled={isSubmitting}
                                        />

                                        <SelectField
                                            name="asset"
                                            label={"Asset"}
                                            options={Object.keys(NoteAssetEnum).filter((v) => v !== NoteAssetEnum.Esg)}
                                            className=""
                                            disabled={isSubmitting}
                                        />

                                        <FieldArray
                                            name="refs"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="form                                                                                                                                                                                                                                                                                                                                                                                        ">
                                                        {values.refs && values.refs.length > 0 ? (
                                                            values.refs.map((ref, index) => (
                                                                <div key={index} className="form-group">
                                                                    <SelectField
                                                                        className=""
                                                                        name={`refs[${index}].collection`}
                                                                        label="Collection"
                                                                        options={Object.keys(refOptions).sort()}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                    {values.refs[index].collection !== CollectionNameEnum.None ? (
                                                                        <SearchListField
                                                                            className=""
                                                                            name={`refs[${index}].documentId`}
                                                                            label={
                                                                                <LinkLookup
                                                                                    collection={ref.collection}
                                                                                    documentId={ref.documentId}
                                                                                />
                                                                            }
                                                                            options={refOptions[ref.collection]}
                                                                            disabled={false}
                                                                        />
                                                                    ) : null}

                                                                    <Button
                                                                        className="btn-sm me-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Button
                                                                        className="btn-sm"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            const ref = cloneDeep(defaultRef);
                                                                            arrayHelpers.insert(index, ref);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className=""
                                                                type="button"
                                                                onClick={() => {
                                                                    const ref = cloneDeep(defaultRef);
                                                                    arrayHelpers.push(ref);
                                                                }}
                                                            >
                                                                {/* show this when user has removed all items */}
                                                                Add a ref
                                                            </Button>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        />

                                        <FieldArray
                                            name="tags"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="form mt-2">
                                                        {values.tags && values.tags.length > 0 ? (
                                                            values.refs.map((tag, index) => (
                                                                <div key={index} className="form-group">
                                                                    <MaybeInListField
                                                                        className=""
                                                                        name={`tags[${index}].type`}
                                                                        label="key"
                                                                        disabled={isSubmitting}
                                                                        options={Object.values(existingTypes).map((v) => {
                                                                            return { key: v, value: v };
                                                                        })}
                                                                    />
                                                                    <MaybeInListField
                                                                        className=""
                                                                        name={`tags[${index}].value`}
                                                                        label="value"
                                                                        disabled={isSubmitting}
                                                                        options={Object.values(existingValues).map((v) => {
                                                                            return { key: v, value: v };
                                                                        })}
                                                                    />

                                                                    <Button
                                                                        className="btn-sm me-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Button
                                                                        className="btn-sm"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            const tag = getDefaultTag();
                                                                            arrayHelpers.insert(index, tag);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className=""
                                                                type="button"
                                                                onClick={() => {
                                                                    const tag = getDefaultTag();
                                                                    arrayHelpers.push(tag);
                                                                }}
                                                            >
                                                                {/* show this when user has removed all items */}
                                                                Add a tag
                                                            </Button>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                {alert.visible ? (
                                    <div className="row">
                                        <div className="col-12 form-row">
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="mt-2 mb-2">
                                    <SubmitButton
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                        label={id === "new" ? "Create" : "Update"}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                NoteView(formData, ButtonsView(setEdit), docNameById)
            )}
        </div>
    );
};
