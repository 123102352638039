import { MarginType, ExposureCollateralType, FxValuation, ReconciledExposureCollateralType, CurrencyEnum } from "../../types.generated";

interface FxValuationsByCurrency {
    [key: string]: FxValuation;
}

interface ExposureCollateralGroupedType {
    exposure: ReconciledExposureCollateralType[];
    variationMarginCollateralSecurities: ReconciledExposureCollateralType[];
    initialMarginCollateralSecurities: ReconciledExposureCollateralType[];
}

interface NettingSetsGroupedType {
    variationMargin: ReconciledExposureCollateralType[];
    initialMargin: ReconciledExposureCollateralType[];
}

export const sumByCurrency = (
    data: ReconciledExposureCollateralType[],
    baseCurrency: CurrencyEnum,
    fxValuationsByCurrency: FxValuationsByCurrency
): ReconciledExposureCollateralType[] => {
    const sumPerCurrency: Record<string, Partial<ReconciledExposureCollateralType> & { name: string }> = {};

    const sumPerBaseCurrency: Record<string, Partial<ReconciledExposureCollateralType> & { name: string }> = {
        [baseCurrency]: { name: "Sum base ccy", currency: baseCurrency }
    };

    const loopNumberByCurrency: Record<string, number> = {};
    const loopNumberByBaseCurrency: Record<string, number> = {};
    for (const position of data) {
        if (!sumPerCurrency[position.currency]) {
            sumPerCurrency[position.currency] = {
                name: "Sum",
                currency: position.currency
            };
        }
        if (!loopNumberByCurrency[position.currency]) {
            loopNumberByCurrency[position.currency] = 0;
        }
        if (!loopNumberByBaseCurrency[position.baseCurrency]) {
            loopNumberByBaseCurrency[position.baseCurrency] = 0;
        }

        for (const key of Object.keys(position)) {
            // No point in looking at sum of quantity, excluding these
            if (typeof position[key] === "number" && key !== "quantity" && key !== "counterpartyQuantity" && key !== "quantityDifference") {
                if (!sumPerCurrency[position.currency][key]) {
                    sumPerCurrency[position.currency][key] = 0;
                }
                if (!sumPerBaseCurrency[baseCurrency][key]) {
                    sumPerBaseCurrency[baseCurrency][key] = 0;
                }
                sumPerCurrency[position.currency][key] += position[key] ? position[key] : 0;

                let fxRate = null;
                if (fxValuationsByCurrency[position.currency + baseCurrency]) {
                    fxRate = fxValuationsByCurrency[position.currency + baseCurrency].price;
                } else if (fxValuationsByCurrency[baseCurrency + position.currency]) {
                    fxRate = 1 / fxValuationsByCurrency[baseCurrency + position.currency].price;
                } else {
                    fxRate = 1;
                    console.log(
                        "No fxRate found for " + position.externalAccount.name + " and currencies " + position.currency + baseCurrency
                    );
                }
                sumPerBaseCurrency[baseCurrency][key] += position[key] ? position[key] * fxRate : 0;
                sumPerBaseCurrency[baseCurrency].agreementType = position.agreementType;
            }
        }
        // If one position has missingReport === false then we know a report has been received ->
        // newNettingSet.missingReport = false

        sumPerCurrency[position.currency].missingReport =
            loopNumberByCurrency[position.currency] !== 0 &&
            (sumPerCurrency[position.currency].missingReport === false || !sumPerCurrency[position.currency].missingReport)
                ? false
                : position.missingReport;
        sumPerBaseCurrency[position.baseCurrency].missingReport =
            loopNumberByBaseCurrency[position.baseCurrency] !== 0 &&
            (sumPerBaseCurrency[position.baseCurrency].missingReport === false || !sumPerBaseCurrency[position.baseCurrency].missingReport)
                ? false
                : position.missingReport;

        sumPerCurrency[position.currency].agreementType = position.agreementType;
        sumPerBaseCurrency[baseCurrency].agreementType = position.agreementType;
        sumPerCurrency[position.currency].externalAccount = position.externalAccount;
        sumPerBaseCurrency[baseCurrency].externalAccount = position.externalAccount;
        sumPerCurrency[position.currency].externalAccountId = position.externalAccountId;
        sumPerBaseCurrency[baseCurrency].externalAccountId = position.externalAccountId;
        loopNumberByCurrency[position.currency] += 1;
        loopNumberByBaseCurrency[position.baseCurrency] += 1;
    }

    if (Object.keys(sumPerCurrency)) {
        for (const currency of Object.keys(sumPerCurrency)) {
            sumPerCurrency[currency].exposureDifference = sumPerCurrency[currency].exposure + sumPerCurrency[currency].counterpartyExposure;
        }
    }
    if (Object.keys(sumPerBaseCurrency)) {
        for (const currency of Object.keys(sumPerBaseCurrency)) {
            sumPerBaseCurrency[currency].exposureDifference =
                sumPerBaseCurrency[currency].exposure + sumPerBaseCurrency[currency].counterpartyExposure;
        }
    }

    const sumRows = Object.values(sumPerCurrency).concat(Object.values(sumPerBaseCurrency)) as any;

    return sumRows;
};

export const groupClientGridData = (
    reconciledExposureCollateralByExternalAccount: Record<string, ReconciledExposureCollateralType[]>,
    reconciledNettingSets: ReconciledExposureCollateralType[],
    endDate: string,
    fxValuationsByCurrency: Record<string, FxValuation>
): {
    nettingSetsGroupedByExternalAccount: Record<string, NettingSetsGroupedType>;
    exposureCollateralGrouped: Record<string, ExposureCollateralGroupedType>;
    variationMarginSummary: ReconciledExposureCollateralType[];
} => {
    //Grouped by external account name
    const exposureCollateralGrouped: Record<string, ExposureCollateralGroupedType> = {};
    const nettingSetsGroupedByExternalAccount: Record<string, NettingSetsGroupedType> = {};

    const variationMarginSummary: ReconciledExposureCollateralType[] = [];

    if (Object.entries(reconciledExposureCollateralByExternalAccount).length) {
        for (const [key] of Object.entries(reconciledExposureCollateralByExternalAccount)) {
            exposureCollateralGrouped[key] = {
                exposure: [],
                variationMarginCollateralSecurities: [],
                initialMarginCollateralSecurities: []
            };
            reconciledExposureCollateralByExternalAccount[key].forEach((item) => {
                if (item.type === ExposureCollateralType.Exposure) {
                    exposureCollateralGrouped[key].exposure.push(item);
                } else if (item.type === ExposureCollateralType.CollateralSecurity && item.marginType === MarginType.VariationMargin) {
                    exposureCollateralGrouped[key].variationMarginCollateralSecurities.push(item);
                } else if (item.type === ExposureCollateralType.CollateralSecurity && item.marginType === MarginType.InitialMargin) {
                    exposureCollateralGrouped[key].initialMarginCollateralSecurities.push(item);
                }
            });
        }
    }

    // Net exposure initial/variation margin by externalAccountName
    if (reconciledNettingSets) {
        reconciledNettingSets.forEach((nettingSet) => {
            if (!nettingSetsGroupedByExternalAccount[nettingSet.externalAccount.name]) {
                nettingSetsGroupedByExternalAccount[nettingSet.externalAccount.name] = { variationMargin: [], initialMargin: [] };
            }
            if (
                nettingSet.variationMarginCollateral !== 0 ||
                nettingSet.counterpartyVariationMarginCollateral !== 0 ||
                nettingSet.variationMarginSecuritiesCollateral !== 0 ||
                nettingSet.counterpartyVariationMarginSecuritiesCollateral !== 0 ||
                nettingSet.counterpartyMarginCall !== 0 ||
                nettingSet.counterpartyNetExposure !== 0 ||
                nettingSet.netExposure !== 0
            ) {
                nettingSetsGroupedByExternalAccount[nettingSet.externalAccount.name].variationMargin.push(nettingSet);
            }
            if (
                nettingSet.initialMarginCollateral !== 0 ||
                nettingSet.counterpartyInitialMarginCollateral !== 0 ||
                nettingSet.initialMarginSecuritiesCollateral !== 0 ||
                nettingSet.counterpartyInitialMarginSecuritiesCollateral !== 0 ||
                nettingSet.counterpartyInitialMarginNetRequirement !== 0 ||
                nettingSet.initialMarginNetRequirement !== 0 ||
                nettingSet.counterpartyInitialMarginNetRequirement !== 0
            ) {
                nettingSetsGroupedByExternalAccount[nettingSet.externalAccount.name].initialMargin.push(nettingSet);
            }
        });
    }

    // Adding summation rows to summation table
    for (const externalAccount in exposureCollateralGrouped) {
        if (
            exposureCollateralGrouped[externalAccount] &&
            exposureCollateralGrouped[externalAccount].exposure &&
            exposureCollateralGrouped[externalAccount].exposure.length
        ) {
            const baseCurrency = exposureCollateralGrouped[externalAccount].exposure[0].baseCurrency;
            const sumRows = sumByCurrency(exposureCollateralGrouped[externalAccount].exposure, baseCurrency, fxValuationsByCurrency);
            exposureCollateralGrouped[externalAccount].exposure = exposureCollateralGrouped[externalAccount].exposure.concat(sumRows);
        }

        if (
            exposureCollateralGrouped[externalAccount] &&
            exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities &&
            exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities.length
        ) {
            const baseCurrency = exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities[0].baseCurrency;
            const sumRows = sumByCurrency(
                exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities,
                baseCurrency,
                fxValuationsByCurrency
            );
            exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities =
                exposureCollateralGrouped[externalAccount].variationMarginCollateralSecurities.concat(sumRows);
        }

        if (
            exposureCollateralGrouped[externalAccount] &&
            exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities &&
            exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities.length
        ) {
            const baseCurrency = exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities[0].baseCurrency;
            const sumRows = sumByCurrency(
                exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities,
                baseCurrency,
                fxValuationsByCurrency
            );
            exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities =
                exposureCollateralGrouped[externalAccount].initialMarginCollateralSecurities.concat(sumRows);
        }
    }
    // Adding summation rows to each externalAccount group
    for (const externalAccount in nettingSetsGroupedByExternalAccount) {
        if (
            nettingSetsGroupedByExternalAccount[externalAccount] &&
            nettingSetsGroupedByExternalAccount[externalAccount].variationMargin &&
            nettingSetsGroupedByExternalAccount[externalAccount].variationMargin.length
        ) {
            const baseCurrency = nettingSetsGroupedByExternalAccount[externalAccount].variationMargin[0].baseCurrency;
            const sumRows = sumByCurrency(
                nettingSetsGroupedByExternalAccount[externalAccount].variationMargin,
                baseCurrency,
                fxValuationsByCurrency
            );

            nettingSetsGroupedByExternalAccount[externalAccount].variationMargin =
                nettingSetsGroupedByExternalAccount[externalAccount].variationMargin.concat(sumRows);

            for (const sumRow of sumRows) {
                if ((sumRow as ReconciledExposureCollateralType & { name: string }).name === "Sum") {
                    variationMarginSummary.push(sumRow);
                }
            }
        }

        if (
            nettingSetsGroupedByExternalAccount[externalAccount] &&
            nettingSetsGroupedByExternalAccount[externalAccount].initialMargin &&
            nettingSetsGroupedByExternalAccount[externalAccount].initialMargin.length
        ) {
            const baseCurrency = nettingSetsGroupedByExternalAccount[externalAccount].initialMargin[0].baseCurrency;
            const sumRows = sumByCurrency(
                nettingSetsGroupedByExternalAccount[externalAccount].initialMargin,
                baseCurrency,
                fxValuationsByCurrency
            );
            nettingSetsGroupedByExternalAccount[externalAccount].initialMargin =
                nettingSetsGroupedByExternalAccount[externalAccount].initialMargin.concat(sumRows);
        }
    }

    return { nettingSetsGroupedByExternalAccount, exposureCollateralGrouped, variationMarginSummary };
};
