import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import stableStringify from "json-stable-stringify";
import { useQuery } from "urql";
import { useNavigate, useParams } from "react-router-dom";

import { emptyObjectId } from "../../../common/src";

import { SelectField, TextField } from "../components/form";
import { usePrevious } from "../common/Utils";
import { bankAccountTransactions } from "./BankAccountTransactionsPage";
import { GET_TRANSACTION } from "./TransactionPage";
import { GET_INSTRUMENT } from "./InstrumentPage";
import { getParty } from "./party/PartyQuery";
import { getCosts } from "./backoffice/CostPage";

const collections = [
    { key: "none", value: "None" },
    { key: "bankAccountTransaction", value: "BankAccountTransaction" },
    { key: "cost", value: "Cost" },
    { key: "instrument", value: "Instrument" },
    { key: "party", value: "Party" },
    { key: "transaction", value: "Transaction" }
];

export const VersionsPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const { id, type } = useParams<"id" | "type">();
    const previousId = usePrevious(id);
    const previousType = usePrevious(type);

    const [{ fetching: transactionLoading, error: transactionError, data: transactionData }, refetch] = useQuery({
        query: GET_TRANSACTION,
        variables: { _id: id, includeVersions: true },
        requestPolicy: "network-only",
        pause: !id || !type || id === emptyObjectId || type !== "transaction"
    });
    const [{ fetching: bankAccTransactionLoading, error: bankAccTransactionError, data: bankAccTransactionData }, refetchBankAccT] =
        useQuery({
            query: bankAccountTransactions,
            variables: { filter: { idIn: [id] }, includeVersions: true },
            requestPolicy: "network-only",
            pause: !id || !type || id === emptyObjectId || type !== "bankAccountTransaction"
        });
    const [{ fetching: instrumentLoading, error: instrumentError, data: instrumentData }, refetchInstrument] = useQuery({
        query: GET_INSTRUMENT,
        variables: { id: id, includeVersions: true },
        requestPolicy: "network-only",
        pause: !id || !type || id === emptyObjectId || type !== "instrument"
    });
    const [{ fetching: partyLoading, error: partyError, data: partyData }, refetchParty] = useQuery({
        query: getParty,
        variables: { _id: id, includeVersions: true },
        requestPolicy: "network-only",
        pause: !id || !type || id === emptyObjectId || type !== "party"
    });

    const [{ fetching: costLoading, error: costError, data: costData }, refetchCost] = useQuery({
        query: getCosts,
        variables: { filter: { idIn: [id] }, includeVersions: true },
        requestPolicy: "network-only",
        pause: !id || !type || id === emptyObjectId || type !== "cost"
    });

    const [formData, setFormData] = useState({ type: "none", id: emptyObjectId });
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!id || !type) {
            navigate("/versions/none/" + emptyObjectId, { replace: true });
        }

        if (id !== formData.id || type !== formData.type) {
            setFormData({ id, type });
        }

        if (previousId !== id || previousType !== type) {
            setData(null);
        }

        if (!data) {
            if (type === "transaction" && id !== emptyObjectId && transactionData && transactionData.transaction) {
                setData(transactionData.transaction);
            } else if (
                type === "bankAccountTransaction" &&
                id !== emptyObjectId &&
                bankAccTransactionData &&
                bankAccTransactionData.bankAccountTransactions
            ) {
                setData(bankAccTransactionData.bankAccountTransactions[0]);
            } else if (type === "cost" && id !== emptyObjectId && costData && costData.costs) {
                setData(costData.costs[0]);
            } else if (type === "instrument" && id !== emptyObjectId && instrumentData && instrumentData.instrument) {
                setData(instrumentData.instrument);
            } else if (type === "party" && id !== emptyObjectId && partyData && partyData.party) {
                setData(partyData.party);
            }
        }
    }, [
        previousId,
        id,
        transactionData,
        formData,
        previousType,
        type,
        data,
        navigate,
        bankAccTransactionData,
        instrumentData,
        partyData,
        costData
    ]);

    if (transactionLoading || bankAccTransactionLoading || instrumentLoading || partyLoading || costLoading) return <div>Loading</div>;

    if (transactionError) return <div>{"Error transaction: " + transactionError.message}</div>;
    if (bankAccTransactionError) return <div>{"Error bank account transaction: " + bankAccTransactionError.message}</div>;
    if (instrumentError) return <div>{"Error instrument: " + instrumentError.message}</div>;
    if (partyError) return <div>{"Error party: " + partyError.message}</div>;
    if (costError) return <div>{"Error cost: " + costError.message}</div>;

    const { versions, ...dataWithoutVersions } = data || {};

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validate={(validateFormData) => {
                const errors: any = {};
                if (formData && JSON.stringify(validateFormData) !== JSON.stringify(formData)) {
                    navigate("/versions/" + validateFormData.type + "/" + validateFormData.id, { replace: true });
                    setFormData(validateFormData);
                    refetch();
                    refetchBankAccT();
                    refetchInstrument();
                    refetchParty();
                    refetchCost();
                }

                return Object.keys(errors).length > 0 ? errors : {};
            }}
            onSubmit={null}
        >
            {({ isSubmitting }) => (
                <Fragment>
                    <Form autoComplete="off">
                        <SelectField className="col-3" name="type" label="Type" options={collections} disabled={isSubmitting} />
                    </Form>
                    <Form autoComplete="off">
                        <TextField className="col-3" name="id" label="Id" disabled={isSubmitting} />
                    </Form>
                    {dataWithoutVersions ? (
                        <div className="row" style={{ padding: "0.8rem" }}>
                            <div className="col-6">
                                <h4>Current version</h4>
                                <div className="p-3 border rounded">
                                    <pre>{stableStringify(dataWithoutVersions, { space: "  " })}</pre>
                                </div>
                            </div>
                            <div className="col-6">
                                <h4>Previous versions</h4>
                                <div className="p-3 border rounded">
                                    <pre>{stableStringify(versions, { space: "  " })}</pre>
                                </div>
                            </div>
                        </div>
                    ) : (
                        "No data found."
                    )}
                </Fragment>
            )}
        </Formik>
    );
};
