import React, { useState } from "react";
import { gql, useQuery } from "urql";

import { Svgs } from "../../../components/src";
import { Grid, Column } from "../../../components/src";

import { useQueryState } from "../common/use-query-state";
import { Page } from "../components/Page";
import { defaultDate, DateForm } from "../common/dateForm";
import { dateToYYYYMMDDString } from "../common/Utils";
import { cloneDeep, sortBy } from "lodash";
import { Link } from "react-router-dom";

const getReconcileFundUnits = gql`
    query getReconcileFundUnits($date: GraphQLDateString!, $filterOutZeroDiff: Boolean) {
        FundUnits: reconcileFundUnits(date: $date, filterOutZeroDiff: $filterOutZeroDiff) {
            reportedNbr
            calculatedNbr
            shareRegisterNbr
            unitsAfterOrders
            isin
            instrumentId
            instrument {
                name
            }
            diff
            shareRegisterDiff
            reporterId
            reporter {
                name
            }
            comment
        }
    }
`;

const ReconciliationDataGrid = ({ filterOutZeroDiff, endDate }) => {
    const [{ fetching, error, data }] = useQuery({
        query: getReconcileFundUnits,
        variables: { filterOutZeroDiff, date: endDate },
        requestPolicy: "network-only"
    });

    if (fetching)
        return (
            <Page className="loader">
                <div>
                    <div>Fetching reconciliation</div>
                    <Svgs.Loader />
                </div>
            </Page>
        );
    if (error) return <div>`Error! ${error.message}`</div>;
    //if (errorNavs) return <div>`Error! ${errorNavs.message}`</div>;
    //console.log(data);

    const tables = {};

    let fundUnits = cloneDeep(data.FundUnits);
    fundUnits = sortBy(fundUnits, "instrument.name");
    tables["FundUnits"] = fundUnits;

    return (
        <div>
            {Object.keys(tables).map((key) => (
                <div key={"div_" + key}>
                    <p>Number of items: {tables[key].length}</p>

                    <Grid header={key} data={tables[key]}>
                        <Column
                            field="diffs"
                            title="Diffs"
                            format={(v, item) => {
                                return item.diff || item.shareRegisterDiff ? "⚠️" : "✅";
                            }}
                        />
                        <Column field="calculatedNbr" title="Calculated nbr" />
                        <Column field="reportedNbr" title="Reported nbr" />
                        <Column field="shareRegisterNbr" title="Share register nbr" />
                        {key === "FundUnits" ? <Column field="unitsAfterOrders" title="Units after orders" /> : null}
                        <Column field="instrumentId" title="Instrument id" />
                        <Column
                            field="isin"
                            className="grid-column-sticky"
                            format={(value, item) => {
                                return (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                );
                            }}
                        />
                        <Column
                            field="instrumentId"
                            title="Name"
                            format={(_, item) => {
                                if (item.instrument && item.instrument.name) {
                                    return (
                                        <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                            {item.instrument.name}
                                        </Link>
                                    );
                                } else {
                                    return "";
                                }
                            }}
                        />
                        <Column
                            field="reporterName"
                            title="Reporter name"
                            format={(value, item) => {
                                return (
                                    <Link to={"/parties/" + item.reporterId} target="_blank">
                                        {item.reporter.name}
                                    </Link>
                                );
                            }}
                        />
                        <Column field="diff" />
                        <Column field="shareRegisterDiff" title="Share register diff" />
                        <Column field="comment" />
                    </Grid>
                </div>
            ))}
        </div>
    );
};

const Checkbox = (props) => <input type="checkbox" {...props} />;

export const ReconciliationPage = (): React.ReactElement => {
    const [filterOutZeroDiff, setFilterOutZeroDiff] = useState(false);
    const [endDate] = useQueryState("endDate", dateToYYYYMMDDString(defaultDate()));

    const handleCheckboxChange = (e) => {
        setFilterOutZeroDiff(e.target.checked);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col col-sm-3 col-lg-2">
                    <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <label>
                        <Checkbox checked={filterOutZeroDiff} onChange={handleCheckboxChange} />
                        <span>Filter out zero diff</span>
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ReconciliationDataGrid filterOutZeroDiff={filterOutZeroDiff} endDate={endDate} />
                </div>
            </div>
        </div>
    );
};
