import { gql } from "urql";

export const GET_NOTES = gql`
    query notes($filter: NoteFilterInput) {
        notes(filter: $filter) {
            _id
            asset
            title
            clientIds
            status
            clients {
                _id
                name
            }
            refs {
                collection
                documentId
            }
            tags {
                type
                value
            }
            data
            createTimestamp
            updateTimestamp
            creatorId
            creator {
                name
            }
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const UPSERT_NOTES = gql`
    mutation upsertNotes($input: [NoteInput!]!) {
        upsertNotes(input: $input) {
            _id
            asset
            title
            clientIds
            status
            clients {
                _id
                name
            }
            refs {
                collection
                documentId
            }
            tags {
                type
                value
            }
            data
            createTimestamp
            updateTimestamp
            creatorId
            creator {
                name
            }
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const GET_OPTIONS = gql`
    query {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }

        parties {
            _id
            name
        }

        instruments {
            _id
            name
            longName
        }

        issuerprograms {
            _id
            name
            issuer {
                name
            }
        }

        screens {
            _id
            name: _id
        }

        notes {
            refs {
                collection
                documentId
            }
            tags {
                type
                value
            }
        }
    }
`;
