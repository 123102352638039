import React, { Fragment, useContext, useEffect } from "react";
import { gql, useQuery } from "urql";
import { Formik, Form } from "formik";
import { cloneDeep, keyBy } from "lodash";

import {
    EsgShare,
    ScreeningInformation,
    SustainableDevelopmentGoals,
    UseOfProceeds,
    EsgCategory,
    EsgPie,
    Grid,
    Column
} from "../../../components/src";

import { useQueryState } from "../common/use-query-state";
import { DateField } from "../components/form";
import { InstrumentModelTypeEnum, PartyType, SustainableDevelopmentGoal } from "../types.generated";
import { PermissionTypeEnum, PermissionAssetEnum, EsgComponentEnum } from "../types.generated";
import { userHaveAccessTo } from "../common/Permissions";
import { serializeSwedenDate } from "../components/dateFormater";
import { emptyObjectId, numberFormatFun } from "../../../common/src";
import { Link, useNavigate } from "react-router-dom";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext } from "../contexts/ClientContext";
import { useQueryArgs } from "../common/Utils";

const GET_DATA = gql`
    query getData($endDate: GraphQLDateString, $clientId: GraphQLObjectId!) {
        me {
            _id
            name
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
            frontendRole {
                assets
            }
        }
        reports: reports(type: "FundKeyMeasures", clientId: $clientId, lastOnly: true, statusIn: [Active]) {
            _id
            name
            date
            clientId
            clientName
            data
        }

        positions: tradingmanager(filterZeroPositions: true, lookThrough: true, filter: { endDate: $endDate, clientIds: [$clientId] }) {
            exposure
            modelType
            instrument {
                name
                _id
                issuerId
                issuer {
                    name
                }
                issuerProgram {
                    _id
                    spoProvider {
                        name
                        _id
                    }
                    category
                    sustainableDevelopmentGoals
                    name
                    useOfProceeds
                    issuer {
                        name
                        issuer {
                            transitionCompany
                            captorDnshCompliant
                            ghgEmissions
                            carbonFootprint
                            ghgIntensityOfInvesteeCompanies
                            fossilFuelCompaniesExposure
                            nonRenewableEnergyShare
                            energyIntensityHighImpact
                            activitiesAffectingBiodiversityAreas
                            pollutionOfWater
                            hazardousWasteRatio
                            deforestation
                            recordableWorkAccidents
                            lackOfProcessesAndCompliance
                            unadjustedGenderPayGap
                            managementSupervisoryGenderDiversity
                            esg {
                                globalStandardScreen {
                                    status
                                }
                                productInvolvement {
                                    involvement
                                    range
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const EsgGraphs = ({ client, esgComponents }: { client: any; esgComponents: [string] }): React.ReactElement => {
    const [date, setDate] = useQueryState("date", serializeSwedenDate(new Date()));

    const variables = { endDate: date, clientId: client._id };
    const [{ data, fetching: loading, error }] = useQuery({
        query: GET_DATA,
        variables: variables,
        requestPolicy: "cache-and-network"
    });

    const sustainableDevelopmentGoalsEnum = Object.values(SustainableDevelopmentGoal);
    const sustainableDevelopmentGoalsDataUnordered = {};
    let sustainableDevelopmentGoalsData = {};

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!esgComponents.length)
        return (
            <div>
                <p>No esg components on party found</p>
            </div>
        );

    const useOfProceedsData = {};
    const transitionCompaniesData = { Yes: 0, No: 0 };
    const transitionCompaniesDataIssuers = { Yes: [], No: [] };
    const transitionCompaniesByIssuerId: Record<string, any> = {};
    const spoProviderData = {};
    const spoProviderDataIssuers: any = {};
    const categoryData = {};
    const categoryDataIssuers: any = {};
    const esgCategoryData = [];
    const globalStandardScreenData = {};
    const globalStandardScreenDataIssuers: any = {};
    const productInvolvementData = {};
    const productInvolvementDataIssuers: any = {};
    let latestFundReport;

    let totalExposure = 0;

    const excludeModelTypes = [
        InstrumentModelTypeEnum.Balance,
        InstrumentModelTypeEnum.FxSwap,
        InstrumentModelTypeEnum.Swaption,
        InstrumentModelTypeEnum.EquityUnitOption,
        InstrumentModelTypeEnum.EquityBasketOption,
        InstrumentModelTypeEnum.PortfolioSwap,
        InstrumentModelTypeEnum.Swap,
        InstrumentModelTypeEnum.GenericFxSwap,
        InstrumentModelTypeEnum.GenericSwap
    ];
    if (data && esgComponents.length > 0) {
        let instrument;
        let goal;
        let position;
        if (data.reports && data.reports.length > 0) {
            latestFundReport = data.reports[0];
        }
        for (let i = 0; i < data.positions.length; i++) {
            position = data.positions[i];
            totalExposure = totalExposure + position.exposure;
            if (!excludeModelTypes.includes(position.modelType)) {
                instrument = position.instrument;
                if (instrument.issuerProgram && instrument.issuerProgram.sustainableDevelopmentGoals) {
                    for (let i = 0; i < instrument.issuerProgram.sustainableDevelopmentGoals.length; i++) {
                        goal = instrument.issuerProgram.sustainableDevelopmentGoals[i];
                        sustainableDevelopmentGoalsDataUnordered[goal] =
                            goal in sustainableDevelopmentGoalsDataUnordered
                                ? sustainableDevelopmentGoalsDataUnordered[goal] + position.exposure
                                : position.exposure;
                    }
                }
                if (instrument.issuer || (instrument.issuerProgram && instrument.issuerProgram.issuer)) {
                    let use;
                    if (instrument.issuerProgram && instrument.issuerProgram.useOfProceeds) {
                        for (let i = 0; i < instrument.issuerProgram.useOfProceeds.length; i++) {
                            use = instrument.issuerProgram.useOfProceeds[i];
                            useOfProceedsData[use] =
                                use in useOfProceedsData ? useOfProceedsData[use] + position.exposure : position.exposure;
                        }
                    }
                    if (instrument.issuerProgram && instrument.issuerProgram.issuer && instrument.issuerProgram.issuer.issuer) {
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        const issuer = instrument.issuerProgram.issuer.issuer;
                        if (issuer.transitionCompany) {
                            if (!transitionCompaniesDataIssuers["Yes"].includes(issuerName)) {
                                transitionCompaniesDataIssuers["Yes"].push(issuerName);
                            }
                            transitionCompaniesData["Yes"] = transitionCompaniesData["Yes"] + position.exposure;

                            transitionCompaniesByIssuerId[instrument.issuerId] = { name: issuerName };

                            transitionCompaniesByIssuerId[instrument.issuerId].exposure =
                                (transitionCompaniesByIssuerId[instrument.issuerId].exposure || 0) + position.exposure;

                            transitionCompaniesByIssuerId[instrument.issuerId].captorDnshCompliant = issuer.captorDnshCompliant
                                ? "YES"
                                : "NO";
                            transitionCompaniesByIssuerId[instrument.issuerId].pai = [];
                            if (issuer.ghgEmissions) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("GHG emissions");
                            }
                            if (issuer.carbonFootprint) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("Carbon footprint");
                            }
                            if (issuer.ghgIntensityOfInvesteeCompanies) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("GHG intensity of investee companies");
                            }
                            if (issuer.fossilFuelCompaniesExposure) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Exposure to companies in the fossil fuel sector"
                                );
                            }
                            if (issuer.nonRenewableEnergyShare) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Share of non-renewable energy consumption and production"
                                );
                            }
                            if (issuer.energyIntensityHighImpact) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Energy intensity per high impact climate sector"
                                );
                            }
                            if (issuer.activitiesAffectingBiodiversityAreas) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Activities negatively affecting biodiversity sensitive areas"
                                );
                            }
                            if (issuer.pollutionOfWater) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("Pollution of water");
                            }
                            if (issuer.hazardousWasteRatio) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("Hazardous waste ratio");
                            }
                            if (issuer.deforestation) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("Deforestation");
                            }
                            if (issuer.recordableWorkAccidents) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push("Rate of recordable work-related accidents");
                            }
                            if (issuer.lackOfProcessesAndCompliance) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Lack of processes and compliance mechanisms, OECD guidelines."
                                );
                            }
                            if (issuer.unadjustedGenderPayGap) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Unadjusted gender pay gap between female and employees."
                                );
                            }
                            if (issuer.managementSupervisoryGenderDiversity) {
                                transitionCompaniesByIssuerId[instrument.issuerId].pai.push(
                                    "Management and supervisory board gender diversity"
                                );
                            }
                        } else {
                            if (!transitionCompaniesDataIssuers["No"].includes(issuerName)) {
                                transitionCompaniesDataIssuers["No"].push(issuerName);
                            }
                            transitionCompaniesData["No"] = transitionCompaniesData["No"] + position.exposure;
                        }
                    } else {
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;
                        if (!transitionCompaniesDataIssuers["No"].includes(issuerName)) {
                            transitionCompaniesDataIssuers["No"].push(issuerName);
                        }
                        transitionCompaniesData["No"] = transitionCompaniesData["No"] + position.exposure;
                    }
                    if (instrument.issuerProgram && instrument.issuerProgram.spoProvider) {
                        const spoProviderName = instrument.issuerProgram.spoProvider.name;
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        if (spoProviderName in spoProviderDataIssuers && !spoProviderDataIssuers[spoProviderName].includes(issuerName)) {
                            spoProviderDataIssuers[spoProviderName].push(issuerName);
                        } else if (!(spoProviderName in spoProviderDataIssuers)) {
                            spoProviderDataIssuers[spoProviderName] = [issuerName];
                        }
                        spoProviderData[spoProviderName] =
                            spoProviderName in spoProviderData ? spoProviderData[spoProviderName] + position.exposure : position.exposure;
                    } else {
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        if ("None" in spoProviderDataIssuers && !spoProviderDataIssuers["None"].includes(issuerName)) {
                            spoProviderDataIssuers["None"].push(issuerName);
                        } else if (!("None" in spoProviderDataIssuers)) {
                            spoProviderDataIssuers["None"] = [issuerName];
                        }
                        spoProviderData["None"] = spoProviderData["None"] ? spoProviderData["None"] + position.exposure : position.exposure;
                    }
                    if (instrument.issuerProgram && instrument.issuerProgram.category) {
                        const category = instrument.issuerProgram.category;
                        const issuerName = instrument.issuerProgram.issuer.name;

                        if (category in categoryDataIssuers && !categoryDataIssuers[category].includes(issuerName)) {
                            categoryDataIssuers[category].push(issuerName);
                        } else if (!(category in categoryDataIssuers)) {
                            categoryDataIssuers[category] = [issuerName];
                        }
                        categoryData[category] = category in categoryData ? categoryData[category] + position.exposure : position.exposure;
                    } else {
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        categoryData["None"] = categoryData["None"] ? categoryData["None"] + position.exposure : position.exposure;

                        if ("None" in categoryDataIssuers && !categoryDataIssuers["None"].includes(issuerName)) {
                            categoryDataIssuers["None"].push(issuerName);
                        } else if (!("None" in categoryDataIssuers)) {
                            categoryDataIssuers["None"] = [issuerName];
                        }
                    }
                    if (
                        instrument.issuerProgram &&
                        instrument.issuerProgram.issuer &&
                        instrument.issuerProgram.issuer.issuer &&
                        instrument.issuerProgram.issuer.issuer.esg &&
                        instrument.issuerProgram.issuer.issuer.esg.globalStandardScreen &&
                        instrument.issuerProgram.issuer.issuer.esg.globalStandardScreen.length
                    ) {
                        let status;
                        for (let i = 0; i < instrument.issuerProgram.issuer.issuer.esg.globalStandardScreen.length; i++) {
                            status = instrument.issuerProgram.issuer.issuer.esg.globalStandardScreen[i].status;
                            globalStandardScreenData[status] =
                                status in globalStandardScreenData
                                    ? globalStandardScreenData[status] + position.exposure
                                    : position.exposure;
                        }

                        const issuerName = instrument.issuerProgram.issuer.name;

                        if (status in globalStandardScreenDataIssuers && !globalStandardScreenDataIssuers[status].includes(issuerName)) {
                            globalStandardScreenDataIssuers[status].push(issuerName);
                        } else if (!(status in globalStandardScreenDataIssuers)) {
                            globalStandardScreenDataIssuers[status] = [issuerName];
                        }
                    } else {
                        globalStandardScreenData["Not available/applicable"] = globalStandardScreenData["Not available/applicable"]
                            ? globalStandardScreenData["Not available/applicable"] + position.exposure
                            : position.exposure;
                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        if (
                            "Not available/applicable" in globalStandardScreenDataIssuers &&
                            !globalStandardScreenDataIssuers["Not available/applicable"].includes(issuerName)
                        ) {
                            globalStandardScreenDataIssuers["Not available/applicable"].push(issuerName);
                        } else if (!("Not available/applicable" in globalStandardScreenDataIssuers)) {
                            globalStandardScreenDataIssuers["Not available/applicable"] = [issuerName];
                        }
                    }
                    if (
                        instrument.issuerProgram &&
                        instrument.issuerProgram.issuer &&
                        instrument.issuerProgram.issuer.issuer &&
                        instrument.issuerProgram.issuer.issuer.esg &&
                        instrument.issuerProgram.issuer.issuer.esg.productInvolvement &&
                        instrument.issuerProgram.issuer.issuer.esg.productInvolvement.length
                    ) {
                        productInvolvementData["Involved"] = productInvolvementData["Involved"]
                            ? productInvolvementData["Involved"] + position.exposure
                            : position.exposure;

                        const issuerName = instrument.issuerProgram.issuer.name;

                        if (
                            "Involved" in productInvolvementDataIssuers &&
                            !productInvolvementDataIssuers["Involved"].includes(issuerName)
                        ) {
                            productInvolvementDataIssuers["Involved"].push(issuerName);
                        } else if (!("Involved" in productInvolvementDataIssuers)) {
                            productInvolvementDataIssuers["Involved"] = [issuerName];
                        }
                    } else {
                        productInvolvementData["Not involved"] = productInvolvementData["Not involved"]
                            ? productInvolvementData["Not involved"] + position.exposure
                            : position.exposure;

                        const issuerName =
                            instrument.issuerProgram && instrument.issuerProgram.issuer.name
                                ? instrument.issuerProgram.issuer.name
                                : instrument.issuer.name;

                        if (
                            "Not involved" in productInvolvementDataIssuers &&
                            !productInvolvementDataIssuers["Not involved"].includes(issuerName)
                        ) {
                            productInvolvementDataIssuers["Not involved"].push(issuerName);
                        } else if (!("Not involved" in productInvolvementDataIssuers)) {
                            productInvolvementDataIssuers["Not involved"] = [issuerName];
                        }
                    }
                } else {
                    return (
                        <div>
                            <p>
                                {"Error regarding instrument: " +
                                    instrument.name +
                                    ", either you do not have access to this instrument, issuer on instrument is missing or issuer on instruments issuerProgram is missing."}
                            </p>
                        </div>
                    );
                }
            }
        }

        if (sustainableDevelopmentGoalsEnum && sustainableDevelopmentGoalsDataUnordered) {
            // Including all goals in plot, even the ones that are 0%
            sustainableDevelopmentGoalsEnum.forEach((goal) => {
                if (!Object.keys(sustainableDevelopmentGoalsDataUnordered).includes(goal)) {
                    sustainableDevelopmentGoalsDataUnordered[goal] = 0;
                }
            });
            sustainableDevelopmentGoalsData = Object.fromEntries(
                Object.entries(sustainableDevelopmentGoalsDataUnordered).sort(([a], [b]) => {
                    const aNumber = parseFloat(a.substr(4, a.length));
                    const bNumber = parseFloat(b.substr(4, b.length));
                    return aNumber - bNumber;
                })
            );
        }
    }

    const transitionCompaniesTable = Object.keys(transitionCompaniesByIssuerId).map((issuerId) => {
        const issuerData = transitionCompaniesByIssuerId[issuerId];
        return {
            name: issuerData.name,
            issuerId: issuerId,
            share: (issuerData.exposure / totalExposure) * 100,
            dnshCompliant: issuerData.captorDnshCompliant,
            pai: issuerData.pai
        };
    });

    const pieLayout = {
        autosize: true,
        colorway: ["#43a04c", "#2186c5", "#ba46b8", "#009688", "#9e9e9e", "#e5878a"],
        legend: {
            orientation: "h",
            y: -0.15
        },
        margin: { t: 70 }
    };
    const layoutSPOP: any = cloneDeep(pieLayout);
    layoutSPOP.title = "SPO provider";
    const layoutC: any = cloneDeep(pieLayout);
    layoutC.title = "Category";
    const layoutGSS: any = cloneDeep(pieLayout);
    const layoutPI: any = cloneDeep(pieLayout);
    layoutPI.title = "Product involvement";
    const layoutTC: any = cloneDeep(pieLayout);
    layoutTC.title = "Transition companies";
    const layoutEsgShare: any = cloneDeep(pieLayout);
    layoutEsgShare.title = "Hållbarhet";
    layoutEsgShare.colorway = ["#43a04c", "#307e41", "#1F5F32", "#bdbdbd"];

    const categoryColor = [];
    Object.keys(categoryData).forEach((category) => {
        if (category === "Green") {
            categoryColor.push("#2ca02c");
        } else if (category === "Sustainable") {
            categoryColor.push("#1f77b4");
        } else if (category === "None") {
            categoryColor.push("#ff7f0e");
        } else if (category === "Social") {
            categoryColor.push("#e377c2");
        } else if (category === "Transition") {
            categoryColor.push("#9467bd");
        }
    });

    for (const category in categoryData) {
        esgCategoryData.push({ sustainabilityClass: category, value: categoryData[category] });
    }

    return (
        <div className="row">
            <div className="col-12">
                <Formik
                    enableReinitialize={true}
                    initialValues={{ date }}
                    validate={(validateFormData) => {
                        const errors: any = {};
                        // date
                        const sweDate = serializeSwedenDate(validateFormData.date);
                        if (sweDate !== date) {
                            setDate(sweDate);
                        }

                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    onSubmit={null}
                >
                    {({ isSubmitting }) => (
                        <Fragment>
                            <Form autoComplete="off">
                                <div className="row">
                                    <DateField className="" name="date" label="Date" disabled={isSubmitting} />
                                </div>
                            </Form>
                        </Fragment>
                    )}
                </Formik>

                <Fragment>
                    <div className="row mt-4">
                        <h2>{client.name}</h2>
                    </div>
                    <div className="row mt-4">
                        {esgComponents.includes(EsgComponentEnum.ScreeningInformation) ? (
                            <div className="col-6 col-lg-6 col-pr-6">
                                <ScreeningInformation />
                            </div>
                        ) : null}
                        {latestFundReport && esgComponents.includes(EsgComponentEnum.CdsIndexEsgScreenings) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <EsgShare
                                    showHeader={false}
                                    data={latestFundReport}
                                    style={{ height: "350", width: "500" }}
                                    layout={layoutEsgShare}
                                />
                            </div>
                        ) : null}

                        {Object.keys(sustainableDevelopmentGoalsData).length &&
                        esgComponents.includes(EsgComponentEnum.SustainableDevelopmentGoals) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <SustainableDevelopmentGoals
                                    sustainableDevelopmentGoals={sustainableDevelopmentGoalsData}
                                    totalExposure={totalExposure}
                                    layout={{ height: 350, width: 460, margin: { r: 10, b: 20, l: 10, t: 0 }, showlegend: false }}
                                />
                            </div>
                        ) : null}
                        {Object.keys(useOfProceedsData).length && esgComponents.includes(EsgComponentEnum.UseOfProceeds) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <h3>Use Of Proceeds</h3>
                                <UseOfProceeds
                                    useOfProceeds={useOfProceedsData}
                                    totalExposure={totalExposure}
                                    layout={{ height: 350, width: 460, margin: { r: 10, b: 23, t: 0 }, showlegend: false }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <br />
                    <div className="row">
                        {Object.keys(categoryData).length && esgComponents.includes(EsgComponentEnum.EsgCategory) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <h3>Category</h3>
                                <EsgCategory data={esgCategoryData} showHeader={false} style={{ height: "210", width: "460" }} />
                            </div>
                        ) : null}
                        {Object.keys(globalStandardScreenData).length && esgComponents.includes(EsgComponentEnum.GlobalStandardScreen) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <EsgPie
                                    data={globalStandardScreenData}
                                    header={"Global Standard Screen"}
                                    showLegend={true}
                                    style={{ height: "250", width: "460" }}
                                    textInfo={"percent"}
                                    layout={layoutGSS}
                                />
                            </div>
                        ) : null}
                    </div>
                    <br />
                    <div className="row">
                        {Object.keys(transitionCompaniesData).length && esgComponents.includes(EsgComponentEnum.TransitionCompanies) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <EsgPie
                                    data={transitionCompaniesData}
                                    header={"Transition Companies"}
                                    textData={transitionCompaniesDataIssuers}
                                    showLegend={true}
                                    style={{ height: "210", width: "460" }}
                                />
                            </div>
                        ) : null}
                        {Object.keys(spoProviderData).length && esgComponents.includes(EsgComponentEnum.SpoProviders) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <EsgPie
                                    data={spoProviderData}
                                    header={"SPO Providers"}
                                    showLegend={true}
                                    style={{ height: "210", width: "460" }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <br />
                    <div className="row">
                        {userHaveAccessTo("Any", PermissionAssetEnum.Esg, data.me.roles, PermissionTypeEnum.ReadWrite) &&
                        esgComponents.includes(EsgComponentEnum.ProductInvolvementRanges) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <h3>Transition Companies</h3>
                                <Grid data={transitionCompaniesTable} sortable tableClassName="table-xs">
                                    <Column
                                        className="nowrap"
                                        field="name"
                                        title="Company"
                                        format={(value, item) => {
                                            return <Link to={"/parties/" + item.issuerId}>{value}</Link>;
                                        }}
                                    />
                                    <Column field="share" title="Share, %" format={numberFormatFun("# ##0.##")} />
                                    <Column field="dnshCompliant" title="DNSH compliant" />
                                    <Column field="pai" title="PAI" />
                                </Grid>
                            </div>
                        ) : null}
                        {Object.keys(productInvolvementData).length && esgComponents.includes(EsgComponentEnum.ProductInvolvement) ? (
                            <div className="col-12 col-lg-6 col-pr-6">
                                <EsgPie
                                    data={productInvolvementData}
                                    header={"Product Involvement"}
                                    textData={productInvolvementDataIssuers}
                                    showLegend={true}
                                    style={{ height: "210", width: "460" }}
                                    textInfo={"percent"}
                                />
                            </div>
                        ) : null}
                    </div>
                </Fragment>
            </div>
        </div>
    );
};

const GET_FUNDS = gql`
    query {
        funds: parties(filter: { typeIn: [Fund] }) {
            _id
            name
            fundInfo {
                esgComponents
            }
        }
    }
`;

export function EsgPage(): React.ReactElement {
    const { client: clientContext } = useContext(ClientContext);
    const { queryArgs } = useQueryArgs();
    const navigate = useNavigate();

    const [result] = useQuery({
        query: GET_FUNDS
    });
    const { data, fetching: loading, error } = result;

    useEffect(() => {
        if (clientContext._id === emptyObjectId && Object.keys(queryArgs).length) {
            navigate("/esg/" + clientContext.dashName, { replace: true });
        }
    }, [clientContext._id, clientContext.dashName, queryArgs, navigate]);

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const fundsByName = keyBy(data.funds, "name");

    if (
        clientContext &&
        clientContext._id !== emptyObjectId &&
        !Object.keys(fundsByName).includes(clientContext.dashName.replace(/-/g, " "))
    ) {
        return (
            <div className="loader">
                <h3>No client with that name</h3>
            </div>
        );
    }

    const fund = clientContext && clientContext.dashName ? fundsByName[clientContext.dashName.replace(/-/g, " ")] : null;

    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} />
                </div>
            </div>

            {fund ? <EsgGraphs client={fund} esgComponents={fund.fundInfo.esgComponents}></EsgGraphs> : null}
        </div>
    );
}
