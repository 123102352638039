import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface EsgCategoryProps {
    data: any;
    showHeader: boolean;
    showLegend?: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const EsgCategory = ({ data, showHeader, config, layout, showLegend, ...props }: EsgCategoryProps): React.ReactElement => {
    const trace: Partial<Plotly.PlotData> = {
        labels: data.sort().map((d) => d.sustainabilityClass),
        values: data.map((d) => d.value),
        textinfo: "label+percent",
        textposition: "inside",
        //font: { size: 20 },
        type: "pie",
        hole: 0.4
        //sort: false
    };

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#2ca02c", "#1F5F32", "#2ca02c", "#C8E4C9", "#bdbdbd"],
        //colorway: [0, 1, -1, 2, -2, 3, -3].map((d) => color.darken(d).hex()),
        showlegend: showLegend,
        legend: { orientation: "v", traceorder: "normal" }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader ? <h3>Hållbarhet</h3> : null}
            <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
        </React.Fragment>
    );
};
