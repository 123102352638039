import { TimeSeries } from "./timeseries";
import { MondayToFridayCalendar } from "./calendar";
import seedrandom from "seedrandom";

export enum RandomTimeSeriesPriceType {
    Stock = "Stock",
    Bond = "Bond",
    Index = "Index",
    Swap = "Swap",
    Fx = "Fx",
    Future = "Future"
}

const { log, sqrt, cos, PI, floor, exp } = Math;

export class RandomNumberGenerator {
    constructor(seed: string) {
        this.rng = seedrandom(seed);
    }

    rng: seedrandom.prng;

    rand(): number {
        return this.rng();
    }
    random(): number {
        return this.rng();
    }
    randN(): number {
        let u = 0,
            v = 0;
        while (u === 0) {
            u = this.rng(); //Converting [0,1) to (0,1)
        }
        while (v === 0) {
            v = this.rng();
        }
        return sqrt(-2.0 * log(u)) * cos(2.0 * PI * v);
    }
    randomNormal(): number {
        return this.randN();
    }
    randX(): number {
        let r = this.rng();
        while (r === 0) {
            r = this.rng();
        }
        return -log(r);
    }
    randomPoisson(): number {
        return this.randX();
    }
    randomInt(max: number): number {
        return floor(max * this.rng());
    }
    randomSeed(): string {
        return this.randomInt(4294967296).toFixed(0);
    }
    randomItem(arr: any[] | string): any {
        const n = this.randomInt(arr.length);
        return arr[n];
    }
    randomNames(count: number, minchars = 2, maxchars = 4): string[] {
        const chars = "ABCDEGHKLNRSTUVZ";
        const res = {};
        let n = 0;
        while (n < count) {
            const b = minchars + this.randomInt(1 + maxchars - minchars);
            let s = "";
            for (let i = 0; i < b; i++) {
                s += this.randomItem(chars);
            }
            if (typeof res[s] === "undefined") {
                res[s] = true;
                n++;
            }
        }
        return Object.keys(res);
    }
    static longNameToName(n: string): string {
        return n
            .split(" ")
            .map((e) => e.slice(0, 1))
            .join("");
    }
    randomLongNames(count, minwords = 2, maxwords = 4): string[] {
        const randomWords = [
            "Alpha",
            "Bravo",
            "Charlie",
            "Delta",
            "Echo",
            "Golf",
            "Hotel",
            "Kilo",
            "Lima",
            "November",
            "Romeo",
            "Sierra",
            "Tango",
            "Uniform",
            "Victor",
            "Zulu"
        ];
        const res = {};
        let n = 0;
        while (n < count) {
            const b = minwords + this.randomInt(1 + maxwords - minwords);
            let s = "";
            for (let i = 0; i < b; i++) {
                s += (s !== "" ? " " : "") + this.randomItem(randomWords);
            }
            if (typeof res[s] === "undefined") {
                res[s] = true;
                n++;
            }
        }
        return Object.keys(res);
    }

    randomObjectId() {
        const x8 = () => floor(this.randomInt(4294967296)).toString(16);
        return x8() + x8() + x8();
    }

    randomTimeSeries(start, end, priceType) {
        let sigmaMin = 0.15;
        let sigmaMax = 0.4;
        let yearlyReturnAvg = 0.06;
        let yearlyReturnStdev = 0.15;
        if (priceType === RandomTimeSeriesPriceType.Bond || priceType === RandomTimeSeriesPriceType.Swap) {
            sigmaMin = 0.02;
            sigmaMax = 0.07;
        }
        if (priceType === RandomTimeSeriesPriceType.Future || priceType === RandomTimeSeriesPriceType.Index) {
            sigmaMin = 0.075;
            sigmaMax = 0.15;
        }
        if (priceType === RandomTimeSeriesPriceType.Fx) {
            sigmaMin = 0.08;
            sigmaMax = 0.12;
        }
        if (priceType === RandomTimeSeriesPriceType.Stock) {
            sigmaMin = 0.15;
            sigmaMax = 0.3;
        }
        const name = this.randomNames(1)[0];
        const sigma = sigmaMin + (sigmaMax - sigmaMin) * this.rand();
        if (priceType === RandomTimeSeriesPriceType.Bond || priceType === RandomTimeSeriesPriceType.Fx) {
            yearlyReturnAvg = 0.0;
            yearlyReturnStdev = 0.0;
        }
        if (priceType === RandomTimeSeriesPriceType.Swap) {
            yearlyReturnAvg = -0.02;
            yearlyReturnStdev = 0.0;
        }
        const yearlyReturn = yearlyReturnAvg + yearlyReturnStdev * this.randN();
        const autoCorr = 0.02 * this.randN();
        let startValue = 100.0 * exp(this.randN());
        if (priceType === RandomTimeSeriesPriceType.Bond) {
            startValue = 100.0 * exp(0.01 * this.randN());
        } else if (priceType === RandomTimeSeriesPriceType.Stock) {
            startValue = 100.0 * exp(0.5 * this.randN());
        } else if (priceType === RandomTimeSeriesPriceType.Fx) {
            startValue = 5.0 + 10.0 * this.rand();
        } else if (priceType === RandomTimeSeriesPriceType.Future || priceType === RandomTimeSeriesPriceType.Index) {
            startValue = 1000.0 * exp(0.1 * this.randN());
        }
        const tsSeed = this.randomSeed();
        // console.log(`priceType=${priceType}, s0=${startValue}, σ=${sigma}, r=${yearlyReturn}, ρ=${autoCorr}, seed=${tsSeed}`);

        const cal = new MondayToFridayCalendar();
        const isBusinessDay = (d) => cal.isBusinessDay(d);
        let ts = TimeSeries.generateRandomTimeSeries(start, end, isBusinessDay, yearlyReturn, sigma, autoCorr, 1, tsSeed);
        ts = ts.mult(startValue / ts.startValue);
        if (priceType === RandomTimeSeriesPriceType.Swap) {
            ts = ts.add(-ts.startValue);
        }
        ts.name = name;
        return ts;
    }
}
