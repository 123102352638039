import React, { useState } from "react";
import stableStringify from "json-stable-stringify";
import { gql, useQuery } from "urql";
import { Tabs, Tab } from "react-bootstrap";

import { Grid, Column } from "../../../components/src";
import { isArrayOfObjects, getKeysFromArrayOfObjects } from "../common/Utils";
import { Page } from "../components/Page";
import { isValidMongoDBObjectID } from "../common/Utils";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { useQueryState } from "../common/use-query-state";
import { useParams } from "react-router-dom";
import { exportToXlsx } from "../common/exportToXlsx";
import { Excel } from "../../../components/src/Svgs";

interface IReportTabGrid {
    data: any[];
    transpose: boolean;
}

const ReportTabGrid = ({ data, transpose }: IReportTabGrid): React.ReactElement => {
    const isTable: boolean = isArrayOfObjects(data);
    let dataTable = [];

    if (isTable) {
        for (let i = 0; i !== data.length; ++i) {
            const row = data[i];
            if (row) {
                Object.keys(row).forEach((key) => {
                    if (data[i][key] !== null && typeof data[i][key] !== "string" && typeof data[i][key] !== "number") {
                        data[i][key] = stableStringify(data[i][key], { space: "  " });
                    }
                });
            }
        }
        dataTable = data;

        if (transpose) {
            dataTable = [];
            const rows = getKeysFromArrayOfObjects(data);

            Object.values(rows).forEach((key) => {
                const row = { "0": key };

                for (let i = 0; i !== data.length; ++i) {
                    const column = data[i];

                    if (column) {
                        row[(i + 1).toString()] = column[key];
                    }
                }
                dataTable.push(row);
            });
        }
    }

    const fields = getKeysFromArrayOfObjects(dataTable);

    console.log("DATA: ", dataTable);
    console.log("FIelds: ", fields);

    return (
        <div>
            {isTable ? (
                <Grid data={dataTable} sortable tableClassName="table-xs" hideDownload={true}>
                    {fields.map((field, i) => (
                        <Column key={i} field={field} title={transpose ? "" : field} className={i === 0 ? "grid-column-sticky" : ""} />
                    ))}
                </Grid>
            ) : (
                <pre>{stableStringify(data, { space: "  " })}</pre>
            )}
        </div>
    );
};

const Table = ({ data, transpose, name }: { data: any; transpose: boolean; name: string }) => {
    const [activeTab, setActiveTab] = useState(null);

    if (!data) {
        data = { data: [] };
    }

    if (isArrayOfObjects(data)) {
        data = { data: data };
    }
    const tabs = {};
    Object.keys(data).forEach((key) => {
        tabs[key] = key;
    });

    return (
        <div>
            <div className="row">
                <div className="col">
                    <button
                        id="export-excel"
                        className="export-link"
                        type="button"
                        onClick={() => {
                            //console.log(items);

                            exportToXlsx(Object.values(data), name + ".xlsx", Object.keys(data));
                        }}
                    >
                        <Excel />
                    </button>
                </div>
            </div>
            <div className="card card-body bg-light">
                <Tabs onSelect={setActiveTab} activeKey={activeTab ? activeTab : tabs[Object.keys(tabs)[0]]} transition={false}>
                    {Object.keys(tabs).map((tabKey) => (
                        <Tab key={tabKey} eventKey={tabKey} title={tabs[tabKey]}>
                            <ReportTabGrid data={data[tabKey]} transpose={transpose} />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

const GET_REPORT = gql`
    query getReport($_id: GraphQLObjectId!) {
        report(_id: $_id) {
            _id
            status
            clientId
            clientName
            createTimestamp
            date
            name
            reportId
            sourceId
            sourceName
            type
            data
        }
    }
`;

export const ReportPage = (): React.ReactElement => {
    const { id } = useParams<"id">();
    const [{ fetching, error, data }] = useQuery({ query: GET_REPORT, variables: { _id: id }, pause: !isValidMongoDBObjectID(id) });
    const [table, setTable] = useQueryState("table", false);
    const [transpose, setTranspose] = useQueryState("transpose", false);

    if (!isValidMongoDBObjectID(id) && id !== "new") {
        return <Page header={"id: ''" + id + "'' is not a valid id format"} />;
    }

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>`Error! ${error.message}`</div>;

    if (!data.report) return <div>no report found</div>;

    const report = recursivelyRemoveKey(data.report, "__typename");

    const reportData = report.data;
    delete report.data;

    return (
        <div>
            <button
                id="startlocalbutton"
                className="btn btn-primary"
                type="button"
                onClick={() => {
                    setTable(!table);
                }}
            >
                {table ? "Show as json" : "Show as table"}
            </button>
            {table ? (
                <button
                    id="btnTranspose"
                    className="ms-1 btn btn-primary me-2"
                    type="button"
                    onClick={() => {
                        setTranspose(!transpose);
                    }}
                >
                    Transpose
                </button>
            ) : null}
            <pre className="mt-3">{stableStringify(report, { space: "  " })}</pre>
            {table ? (
                <Table data={reportData} transpose={transpose} name={report.name}></Table>
            ) : (
                <pre className="mt-3">{stableStringify(reportData, { space: "  " })}</pre>
            )}
        </div>
    );
};
