import React, { useEffect } from "react";
import { Callout, DefaultEffects, FocusZone, FocusZoneDirection, ICalloutContentStyles, SearchBox } from "@fluentui/react";
import { HighlightText } from "./HighlightText";

export interface ICustomAutosuggest<T = { _id: string; name: string }> {
    name?: string;
    className?: string;
    toSuggestionText: (e: T) => string;
    toDisplayText: (e: T) => string;
    values: T[];
    onChange: (e: T) => void;
    selected: T;
    maxListCount?: number;
    placeholder?: string;
    initialInputFocus?: boolean;
    disabled?: boolean;
}

export function CustomAutosuggest<T>({
    name = "autosuggestSearchBox",
    className = "form-control",
    toSuggestionText,
    toDisplayText,
    values,
    selected,
    onChange,
    maxListCount = null,
    placeholder = "Search",
    initialInputFocus = true,
    disabled = false
}: ICustomAutosuggest<T>): React.ReactElement {
    const textInput = React.useRef<HTMLDivElement>(null);
    const [query, setQuery] = React.useState("");
    const [isSuggestionsVisible, setIsSuggestionsVisible] = React.useState(false);
    const [isSuggestionsFocused, setIsSuggestionsFocused] = React.useState(false);

    //console.log("CustomAutosuggest values: ", values);

    useEffect(() => {
        const defaultQuery = selected && toDisplayText ? toDisplayText(selected) : "";
        setQuery(defaultQuery);
    }, [selected, toDisplayText]);

    const getDisplayText = (suggestion: T | string) => {
        if (typeof suggestion == "string") {
            return suggestion;
        } else {
            return toDisplayText(suggestion);
        }
    };

    const getSuggestionText = (suggestion: T | string): string => {
        if (typeof suggestion == "string") {
            return suggestion;
        } else {
            return toSuggestionText(suggestion);
        }
    };

    const filterSuggestions = (value: string) => {
        if (!value) {
            return values.slice(0, 30);
        }
        const escapeRegexCharacters = (str: string) => str.replace(/[.*+?^${}()|[\]\\@]/g, "\\$&");
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === "") {
            return [];
        }
        const regex = new RegExp(escapedValue, "i");
        const res: { value: T; text: string; sortOrder: number }[] = [];
        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            const text: string = getSuggestionText(values[i]);
            if (text) {
                const sortOrder = text.search(regex);
                if (sortOrder >= 0) {
                    res.push({ value, text, sortOrder });
                }
            }
        }
        res.sort((d1, d2) => {
            const c = d1.sortOrder - d2.sortOrder;
            return c !== 0 ? c : d1.text < d2.text ? -1 : d1.text > d2.text ? 1 : 0;
        });
        let result: T[] = res.map((d) => d.value);
        const m = maxListCount ? maxListCount : 50;
        if (result.length > m) {
            result = result.slice(0, m);
        }
        return result;
    };

    const getCalloutWidth = () => {
        const calloutWidth = textInput.current?.offsetWidth;
        return calloutWidth + "px!important";
    };

    const typeAheadCalloutStyle: Partial<ICalloutContentStyles> = {
        root: {
            boxShadow: DefaultEffects.elevation4,
            borderRadius: 2,
            marginTop: 0,
            width: getCalloutWidth(),
            minWidth: "200px",
            overflow: "hidden",
            left: "0px!important",
            selectors: {
                "@media(max-width: 600px)": {
                    top: "0px",
                    left: "0px!important",
                    minWidth: "200px"
                }
            }
        },
        container: {
            zIndex: 3
        },
        calloutMain: {
            minHeight: "fit-content",
            maxHeight: "200px!important",
            height: "100%"
        }
    };

    const showSuggestions = () => {
        setIsSuggestionsVisible(true);
    };

    const hideSuggestions = () => {
        const querySelectedOrEmpty = selected ? getDisplayText(selected) : "";
        setQuery(querySelectedOrEmpty);
        setIsSuggestionsVisible(false);
    };

    const onSuggestionClick = (suggestion: any) => {
        const newQuery = getDisplayText(suggestion);
        setQuery(newQuery);
        hideSuggestions();
        if (onChange) {
            onChange(suggestion);
        }
    };

    const filteredSuggestions = filterSuggestions(query);

    return (
        <div id="autosuggestSearchBox-container" ref={textInput} className={className}>
            <SearchBox
                id={name}
                name={name}
                showIcon={false}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
                onChange={(_, newValue) => {
                    setQuery(newValue || "");
                    showSuggestions();
                    setIsSuggestionsFocused(false);
                }}
                onClear={() => {
                    setQuery("");
                    showSuggestions();
                    if (onChange) {
                        onChange(null);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                        setIsSuggestionsFocused(true);
                    }
                }}
                onClick={() => {
                    showSuggestions();
                }}
                value={query}
            />

            {filteredSuggestions && isSuggestionsVisible ? (
                <Callout
                    styles={typeAheadCalloutStyle}
                    isBeakVisible={false}
                    target={textInput.current}
                    onDismiss={() => {
                        hideSuggestions();
                    }}
                    setInitialFocus={isSuggestionsFocused}
                    doNotLayer={true}
                >
                    <FocusZone direction={FocusZoneDirection.vertical} shouldFocusOnMount={!initialInputFocus}>
                        {filteredSuggestions.length > 0 ? (
                            filteredSuggestions.map((suggestion: any, i: number) => {
                                if (maxListCount && maxListCount <= i) {
                                    return null;
                                }
                                const text = getSuggestionText(suggestion);

                                return (
                                    <div
                                        key={i}
                                        tabIndex={0}
                                        data-is-focusable={true}
                                        className="searchbox-suggestion-item"
                                        onClick={(_) => onSuggestionClick(suggestion)}
                                        role="listitem"
                                    >
                                        <HighlightText text={text} highlight={query} />
                                    </div>
                                );
                            })
                        ) : (
                            <div key="no-result" role="listitem" data-is-focusable={true}>
                                No result
                            </div>
                        )}
                    </FocusZone>
                </Callout>
            ) : null}
        </div>
    );
}
