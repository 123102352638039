import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { gql, useMutation, useQuery } from "urql";
import { stringify, parse, DocumentOptions, SchemaOptions, ParseOptions, CreateNodeOptions, ToStringOptions } from "yaml";
import { cloneDeep, keyBy, round, sortBy } from "lodash";

import { SelectField, SubmitButton, DateField, TextField } from "../../components/form";
import { IAnnualReportProps } from "./AnnualReportProps";
import { UPDATE_REPORT } from "./AnnualReportPage";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { useAlertTimeOut } from "../../common/Utils";
import { UpdateReportInput, ReportStatusEnum } from "../../types.generated";
import { YamlField } from "../../components/form/YamlField";
import { recursivelyRemoveKey } from "../../../../common/src";
import { YesNoModal } from "../../components/YesNoModal";

export const GET_ClIENTS = gql`
    query getClients($typeIn: [PartyType!], $startDate: GraphQLDateString, $endDate: GraphQLDateString) {
        clients: parties(filter: { typeIn: $typeIn }) {
            _id
            name
            dashName
            fundOrgNumber: legalNumber
            firstTradeDate
            fundInfo {
                fundCompany {
                    name
                    website
                }
                classes {
                    mainClass
                    instrumentId
                    instrument {
                        timeSeries(typeIn: "VaR", startDate: $startDate, endDate: $endDate) {
                            items {
                                value
                                date
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const RECALCULATE_REPORT = gql`
    query annualReports($date: GraphQLDateString!, $clientIds: [GraphQLObjectId!]!) {
        annualReports(date: $date, clientIds: $clientIds) {
            resultSheet
            balanceSheet
            equityChange
            keyFigures
            positions
            issuers
            ratings
        }
    }
`;

const options: DocumentOptions & SchemaOptions & ParseOptions & CreateNodeOptions & ToStringOptions = {
    defaultKeyType: "PLAIN",
    defaultStringType: "QUOTE_DOUBLE"
};

export const AnnualReportEditor = (props: IAnnualReportProps): React.ReactElement => {
    const _location = useLocation();
    const _navigate = useNavigate();

    const [{ fetching, error, data: clientData }] = useQuery({
        query: GET_ClIENTS,
        variables: {
            typeIn: ["Client"],
            startDate: props && props.report && props.report.data ? props.report.data.AnnualReport.startDate : "",
            endDate: props && props.report && props.report.data ? props.report.data.AnnualReport.date : ""
        },
        requestPolicy: "cache-and-network",
        pause: !props || !props.report || !props.report.data
    });

    const [recalculateInput, setRecalculateInput] = useState(null);

    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [data, setData] = useState<IAnnualReportProps["report"]>({
        ...cloneDeep(props.report),
        text: stringify(props.report.data, options)
    });
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [{ fetching: fetchingRecalculatedData, error: errorRecalculatedData, data: reCalculatedData }] = useQuery({
        query: RECALCULATE_REPORT,
        variables: {
            date: recalculateInput ? recalculateInput.date : null,
            clientIds: recalculateInput ? recalculateInput.clientIds : null
        },
        requestPolicy: "cache-and-network",
        pause: !recalculateInput
    });

    useAlertTimeOut(alert, setAlert, 5);

    const [_, updateReport] = useMutation(UPDATE_REPORT);

    useEffect(() => {
        if (recalculateInput && reCalculatedData && reCalculatedData.annualReports && reCalculatedData.annualReports.length) {
            const recalculatedReport = cloneDeep(data);

            recalculatedReport.data["AnnualReport"] = {
                ...data.data["AnnualReport"],
                ...recursivelyRemoveKey(reCalculatedData.annualReports[0], "__typename")
            };

            recalculatedReport.text = stringify(recalculatedReport.data, options);

            recalculatedReport.data["AnnualReport"].date = recalculateInput.date;
            recalculatedReport.data["AnnualReport"].startDate = new Date(recalculateInput.date).getFullYear().toString() + "-01-01";

            recalculatedReport.data["AnnualReport"].previousDate = recalculatedReport.data["AnnualReport"].resultSheet[0][2];

            recalculatedReport.data["AnnualReport"].fundValue = "";
            recalculatedReport.data["AnnualReport"].previousFundValue = "";

            if (recalculatedReport.data["AnnualReport"].keyFigures && recalculatedReport.data["AnnualReport"].keyFigures.length) {
                for (const item of recalculatedReport.data["AnnualReport"].keyFigures) {
                    if (item[0] === "Fondförmögenhet, mkr") {
                        console.log("here: ", !isNaN(parseFloat(item[2])));
                        recalculatedReport.data["AnnualReport"].fundValue = item[1];
                        recalculatedReport.data["AnnualReport"].previousFundValue = item[2] && !isNaN(parseFloat(item[2])) ? item[2] : "0";
                    }
                }
            }

            recalculatedReport.data["AnnualReport"].semiAnnual =
                new Date(recalculateInput.date).getMonth().toString() === "5" ? true : false;

            if (clientData) {
                const clientsByClientId = keyBy(clientData.clients, "_id");
                const client = clientsByClientId[recalculateInput.clientIds[0]];
                recalculatedReport.data["AnnualReport"].fundName = client.name;
                recalculatedReport.data["AnnualReport"].fundOrgNumber = client.fundOrgNumber;
                recalculatedReport.data["AnnualReport"].fundCompany = client.fundInfo.fundCompany.name;
                recalculatedReport.data["AnnualReport"].fundCompanyWebsite = client.fundInfo.fundCompany.website;
                recalculatedReport.data["AnnualReport"].firstTradeDate = client.firstTradeDate;

                const mainClass = client.fundInfo.classes.find((fundClass) => {
                    if (fundClass.mainClass) {
                        return fundClass;
                    }
                });

                recalculatedReport.data["AnnualReport"].performancePercent = "";

                if (recalculatedReport.data["AnnualReport"].keyFigures && recalculatedReport.data["AnnualReport"].keyFigures.length) {
                    for (const item of recalculatedReport.data["AnnualReport"].keyFigures) {
                        if (item[0] === "Totalavkastning " + client.name + ", %") {
                            recalculatedReport.data["AnnualReport"].performancePercent = item[1];
                        }
                    }
                }

                if (
                    mainClass &&
                    mainClass.instrument.timeSeries &&
                    mainClass.instrument.timeSeries.length &&
                    mainClass.instrument.timeSeries[0].items
                ) {
                    const items = mainClass.instrument.timeSeries[0].items;

                    let max = items[0].value;

                    let min = items[0].value;

                    let totalValue = 0;
                    for (const item of items) {
                        if (item.value > max) {
                            max = item.value;
                        }
                        if (item.value < min) {
                            min = item.value;
                        }
                        totalValue += item.value;
                    }
                    const avg = totalValue / items.length;

                    recalculatedReport.data["AnnualReport"].varEndValuePercent = round(items[items.length - 1].value * 100, 2);

                    recalculatedReport.data["AnnualReport"].varHighValuePercent = round(max * 100, 2);

                    recalculatedReport.data["AnnualReport"].varLowValuePercent = round(min * 100, 2);

                    recalculatedReport.data["AnnualReport"].varAveragePercent = round(avg * 100, 2);
                } else {
                    console.log("No mainClass found, no VaR numbers updated.");
                }
            }
            recalculatedReport.clientId = recalculateInput.clientIds[0];
            recalculatedReport.date = recalculateInput.date;

            if (data.text !== recalculatedReport.text) {
                setData(recalculatedReport);
            } else {
                setRecalculateInput(null);
            }
        }
    }, [clientData, data, props, reCalculatedData, recalculateInput]);

    const clients = useMemo(() => {
        let result = [];
        if (clientData) {
            clientData.clients.forEach((client) => {
                result.push({ key: client._id, value: client.name });
            });
        }
        result = sortBy(result, "value");
        return result;
    }, [clientData]);

    if (fetching || fetchingRecalculatedData) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorRecalculatedData) return <p>Error recalculate: {JSON.stringify(errorRecalculatedData, null, 2)}</p>;

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"All numbers per " + modal.payload.date + " will be recalculated, are you sure you want to continue?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    noText="Re-calculate"
                    yesText="Cancel"
                    setModal={setModal}
                    onNo={() => {
                        setRecalculateInput({ date: modal.payload.date, clientIds: [modal.payload.clientId] });
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={data}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const parsedData = parse(submitValues.text); // this seems to sort the keys sadly

                    // New clientId + date is only submitted when clicking on Recalculate button since a lot of data in
                    // tables + other data needs to be recalculated when client + date is changed
                    const input: UpdateReportInput = {
                        _id: submitValues._id,
                        clientId: data.clientId,
                        data: parsedData,
                        date: data.date,
                        name: submitValues.name,
                        status: submitValues.status,
                        type: "AnnualReport"
                    };

                    await updateReport({ input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({ color: "success", visible: true, message: "updated" });
                                setData({
                                    ...cloneDeep(result.data.report),
                                    text: stringify(result.data.report.data, options)
                                });
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                        <div className="d-sm-flex">
                            <SelectField
                                name="clientId"
                                label={<Link to={"/parties/" + values.clientId}>Client</Link>}
                                options={clients}
                                className="me-4"
                                disabled={isSubmitting}
                            />
                            <DateField className="" name="date" label="Date" disabled={isSubmitting} />
                        </div>

                        <Button
                            type="button"
                            className="btn-sm mt-3"
                            onClick={() => {
                                setModal({ showModal: true, payload: values });
                            }}
                            disabled={props.report.status === ReportStatusEnum.Locked ? true : false}
                        >
                            Re-calculate table
                        </Button>

                        <div className="row mt-3">
                            <div className="col-4">
                                <TextField name="name" label="Name" className="me-4" disabled={isSubmitting} />
                            </div>
                            <div className="col-2">
                                <SelectField
                                    name="status"
                                    label="Status"
                                    options={Object.keys(ReportStatusEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <YamlField name="text" schema="/annualreport.schema.json" width="100%" />
                        </div>

                        {alert.visible ? (
                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                {alert.message}
                            </Alert>
                        ) : null}

                        <div className="pt-3 pb-3">
                            <SubmitButton
                                disabled={isSubmitting || props.report.status === ReportStatusEnum.Locked ? true : false}
                                label="Save report"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
