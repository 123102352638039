import React, { useState, Fragment, useEffect } from "react";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { cloneDeep, keyBy } from "lodash";

import { useAlertTimeOut, usePrevious } from "../../common/Utils";
import { Grid, Column } from "../../../../components/src";
import { Svgs } from "../../../../components/src";
import {
    NonAutoFillTextField,
    TextField,
    MultipleSelectField,
    TextAreaField,
    IntegerField,
    SubmitButton,
    SelectField
} from "../../components/form";
import { userHaveAccessTo } from "../../common/Permissions";
import { PermissionAssetEnum, RoleTypeEnum, UserStatusEnum } from "../../types.generated";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";

const GET_ME = gql`
    query {
        me {
            personalNumber
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

const GET_USER = gql`
    query user($id: GraphQLObjectId) {
        user(_id: $id) {
            _id
            status
            name
            company
            frontendRoleId
            description
            bidExpiration
            personalNumber
            email
            phoneNumber
            roleIds
            noteIds
            notes {
                _id
                data
                createTimestamp
            }
            logins {
                timeStamp
                userAgent
            }
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const GET_ROLES = gql`
    query {
        roles {
            _id
            name
            assets
            updateTimestamp
            permissionType
            roleType
            clients {
                _id
                name
            }
        }
    }
`;

const CREATE_USER = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            _id
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            _id
        }
    }
`;

const validationSchema = Yup.object({
    name: Yup.string().min(2, "Must be at least 2 characters").max(25, "Must be 25 characters or less").required("Required"),
    company: Yup.string().min(2, "Must be at least 2 characters").max(75, "Must be 75 characters or less").required("Required"),
    personalNumber: Yup.string()
        .min(2, "Must be at least 2 characters")
        .max(15, "Must be less than 15 characters")
        .required("Required")
        .matches(/^[a-z0-9]+$/, "Only digits and lowercase characters are allowed"),
    email: Yup.string().email("Invalid email addresss`").required("Required"),
    phoneNumber: Yup.string().max(20, "Must be 20 characters or less"),
    description: Yup.string().max(200, "Must be 200 characters or less"),
    password: Yup.string().required("Required").max(20, "Must be 20 characters or less"),
    confirmPassword: Yup.string().max(20, "Must be 20 characters or less")
});

const validationSchemaEditMode = Yup.object({
    name: Yup.string().min(2, "Must be at least 2 characters").max(25, "Must be 25 characters or less").required("Required"),
    company: Yup.string().min(2, "Must be at least 2 characters").max(75, "Must be 75 characters or less").required("Required"),
    personalNumber: Yup.string()
        .min(2, "Must be at least 2 characters")
        .max(15, "Must be less than 15 characters")
        .required("Required")
        .matches(/^[a-z0-9]+$/, "Only digits and lowercase characters are allowed"),
    email: Yup.string().email("Invalid email addresss`").required("Required"),
    phoneNumber: Yup.string().max(20, "Must be 20 characters or less"),
    description: Yup.string().max(200, "Must be 200 characters or less"),
    password: Yup.string().max(20, "Must be 20 characters or less"),
    confirmPassword: Yup.string().max(20, "Must be 20 characters or less")
});

export function AdminUserPage(): React.ReactElement {
    const { id }: any = useParams();
    const previousId = usePrevious(id);

    const isEditMode = !!id;
    const isCreateMode = !isEditMode;

    const [{ fetching: loadingMe, error: errorMe, data: getMe }] = useQuery({ query: GET_ME });
    const [{ fetching: loading, error, data }] = useQuery({ query: GET_USER, variables: { id }, pause: isCreateMode });
    const [{ fetching: loadingRoles, error: errorRoles, data: getRoles }] = useQuery({ query: GET_ROLES, requestPolicy: "network-only" });
    const [_, createUser] = useMutation(CREATE_USER);
    const [__, updateUser] = useMutation(UPDATE_USER);
    const navigate = useNavigate();
    const location = useLocation();
    const [table, setTable] = useState([]);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });
    const keys: string[] = ["client", "roleName", ...Object.keys(PermissionAssetEnum)];

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (id !== previousId) {
            setTable([]);
        } else if (getMe && data && data.user && getRoles) {
            const rolesById = keyBy(getRoles.roles, "_id");
            const table = [];
            data.user.roleIds.forEach((roleId) => {
                const role = cloneDeep(rolesById[roleId]);
                if (role.clients.length === 0) {
                    const row = { client: "All", roleName: role.name };
                    if (role.assets.length === 0) {
                        Object.keys(PermissionAssetEnum).map((asset) => {
                            if (asset !== "Admin") {
                                row[asset] = role.permissionType;
                            }
                        });
                    } else {
                        role.assets.forEach((asset) => {
                            row[asset] = role.permissionType;
                        });
                    }
                    table.push(row);
                } else {
                    role.clients.forEach((client) => {
                        if (client) {
                            const row = { client: client.name, roleName: role.name };
                            if (role.assets.length === 0) {
                                Object.keys(PermissionAssetEnum).map((asset) => {
                                    if (asset !== "Admin") {
                                        row[asset] = role.permissionType;
                                    }
                                });
                            } else {
                                role.assets.forEach((asset) => {
                                    row[asset] = role.permissionType;
                                });
                            }
                            table.push(row);
                        }
                    });
                }
            });
            setTable(table);
        }
    }, [id, previousId, getMe, data, getRoles]);

    if (loadingMe || loading || loadingRoles)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (errorMe) return <pre>{JSON.stringify(errorMe, null, 2)}</pre>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
    if (errorRoles) return <pre>{JSON.stringify(errorRoles, null, 2)}</pre>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Admin, getMe.me.roles)) {
        return <div />;
    }

    const { roles } = getRoles;

    // default user
    let user: any = {
        company: "Untitled",
        status: UserStatusEnum.Confirmed,
        name: "",
        personalNumber: "",
        frontendRoleId: "000000000000000000000000",
        email: "",
        phoneNumber: "+46",
        bidExpiration: 0,
        roleIds: [],
        noteIds: [],
        description: "",
        password: ""
    };

    // edit mode
    if (isEditMode) {
        user = cloneDeep(data.user);
        user.confirmpassword = "";
    }

    const pageTitle = isCreateMode ? "New user" : "Edit user";
    const submitButtonLabel = isCreateMode ? "Create" : "Update";

    const frontendRoles = roles.filter((role) => {
        if (role.roleType === RoleTypeEnum.Frontend) {
            return true;
        } else {
            return false;
        }
    });
    let frontendRoleOptions = frontendRoles.map((role) => ({ key: role._id, value: role.name }));
    frontendRoleOptions = [{ key: "000000000000000000000000", value: "Select One" }, ...frontendRoleOptions];

    const backendRoles = roles.filter((role) => {
        if (role.roleType === RoleTypeEnum.Backend) {
            return true;
        } else {
            return false;
        }
    });

    const backendRoleOptions = backendRoles.map((role) => ({ key: role._id, value: role.name }));

    //Latest one that has been signed
    let signedTermsOfService = null;
    if (user && user.notes) {
        const sortedTermsOfService = user.notes.sort(function (n1, n2) {
            return new Date(n2.createTimestamp).getTime() - new Date(n1.createTimestamp).getTime();
        });
        signedTermsOfService = sortedTermsOfService[0];
    }

    const logins = data && data.user && data.user.logins ? data.user.logins.sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 0)) : [];

    return (
        <div className="admin-page">
            <h1>{pageTitle}</h1>
            <div className="row">
                <Formik
                    enableReinitialize={true}
                    initialValues={user}
                    validationSchema={isEditMode ? validationSchemaEditMode : validationSchema}
                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                        delete submitValues.confirmpassword;
                        const input = submitValues;

                        if (isCreateMode) {
                            let newUserId: any;
                            await createUser({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        //resetForm(user);
                                        newUserId = result.data.createUser._id;
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `New user '${input.personalNumber}' created successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    // redirect to edit user page on success
                                    if (newUserId) {
                                        const path = location.pathname.split("/");
                                        path.pop();
                                        path.push(newUserId);
                                        navigate(path.join("/"), { replace: true });
                                    }
                                });
                        } else {
                            delete submitValues.confirmpassword;
                            delete input.__typename;
                            delete input.notes;
                            delete input.logins;
                            await updateUser({ input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `The user '${input.personalNumber}' updated successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Fragment>
                            <div className="col-sm-12">
                                <Form autoComplete="off">
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <TextField name="name" label="Name" type="text" className="" disabled={isSubmitting} />
                                            <SelectField
                                                name="status"
                                                label="Status"
                                                options={Object.keys(UserStatusEnum)}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                            <TextField
                                                name="personalNumber"
                                                label="Personal number"
                                                type="text"
                                                className=""
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                            <NonAutoFillTextField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                className="auto-complete-off"
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                            <SelectField
                                                name="frontendRoleId"
                                                label="Frontend role"
                                                options={frontendRoleOptions}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <TextField
                                                        name="phoneNumber"
                                                        label="Phone number"
                                                        type="text"
                                                        className=""
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    {" "}
                                                    <IntegerField
                                                        name="bidExpiration"
                                                        label="Bid expiration"
                                                        type="number"
                                                        className=""
                                                        disabled={isSubmitting}
                                                        min={0}
                                                        max={10000}
                                                    />
                                                </div>
                                            </div>

                                            <NonAutoFillTextField
                                                name="password"
                                                label="Password"
                                                type="password"
                                                className=""
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                            <NonAutoFillTextField
                                                name="confirmpassword"
                                                label="Confirm password"
                                                type="password"
                                                className=""
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <MultipleSelectField
                                                name="roleIds"
                                                label="Roles"
                                                options={backendRoleOptions}
                                                className=""
                                                disabled={isSubmitting}
                                                size={backendRoleOptions.length}
                                            />
                                            <TextField
                                                name="company"
                                                label="Company"
                                                type="text"
                                                className=""
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <TextAreaField
                                                name="description"
                                                label="Description"
                                                className=""
                                                disabled={isSubmitting}
                                                rows={4}
                                                cols={30}
                                            />
                                        </div>
                                    </div>
                                    {alert.visible ? (
                                        <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                            {alert.message}
                                        </Alert>
                                    ) : null}
                                    <SubmitButton disabled={isSubmitting} label={submitButtonLabel} />
                                </Form>
                            </div>
                        </Fragment>
                    )}
                </Formik>
            </div>
            {signedTermsOfService ? (
                <div className="mt-4 mb-1">
                    <Link className="link" to={"/notes/" + signedTermsOfService._id}>
                        Terms of service
                    </Link>
                </div>
            ) : null}
            <div className={"mt-4"}>
                <Grid header="Access" data={table} sortable tableClassName="table-xs">
                    {keys.map((field, i) => (
                        <Column
                            className="nowrap right"
                            key={i}
                            field={field}
                            title={field
                                .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before uppercase letters
                                .replace(/^./, (c) => c.toUpperCase()) // Capitalize the first letter
                                .replace(/ (\w)/g, (c) => c.toLowerCase())}
                        />
                    ))}
                </Grid>
            </div>
            <div className={"mt-4"}>
                <Grid header="Logins" data={logins} sortable tableClassName="table-xs">
                    <Column className="nowrap" field="timeStamp" title="timeStamp"></Column>
                    <Column className="nowrap" field="userAgent" title="userAgent"></Column>
                </Grid>
            </div>
        </div>
    );
}
