import React, { useCallback } from "react";
import { gql, useQuery, useMutation } from "urql";

import { AttachmentForm } from "./AttachmentForm";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";

const GET_TRANSACTION_ATTACHMENTS = gql`
    query getTransactionAttachments($_id: GraphQLObjectId!) {
        transaction(_id: $_id) {
            _id
            clientId
            attachments {
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
            clientId
            attachments {
                fileId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
        }
    }
`;

export const AttachmentTransactionForm = ({ transactionId }: { transactionId: string }): React.ReactElement => {
    const [{ fetching, data, error }, refetch] = useQuery({ query: GET_TRANSACTION_ATTACHMENTS, variables: { _id: transactionId } });

    const [_, updateTransactions] = useMutation(UPDATE_TRANSACTIONS);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            if (transactionId) {
                let input = {
                    _id: transactionId,
                    attachments
                };
                input = recursivelyRemoveKey(input, "__typename");
                updateTransactions({ input: [input] }).then(() => {
                    refetch();
                });
            }
        },
        [refetch, transactionId, updateTransactions]
    );

    if (fetching) return <p>Loading ...</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <AttachmentForm
                clientId={data.transaction.clientId}
                attachments={data.transaction.attachments}
                onChange={callBackOnChangeAttachment}
            />
        </div>
    );
};
