import React, { Fragment, useState, useEffect, ReactElement } from "react";
import { cloneDeep } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import { Alert, Button } from "react-bootstrap";

import { recursivelyRemoveKey } from "../../../../../common/src";

import { useAlertTimeOut } from "../../../common/Utils";
import { SubmitButton, TextField, DateField, SelectField, CheckboxItem } from "../../../components/form";
import { useGetPartyQuery, useUpdatePartyIssuerMutation } from "../PartyQuery.generated";
import {
    EngagementMethod,
    IorpEnum,
    ProductInvolvementIndicator,
    RangeEnum,
    StandardScreenNorm,
    StandardScreenPrinciple,
    StandardScreenStatus,
    ScienceBasedTargetInitiativeEnum
} from "../../../types.generated";
import { formikUrqlErrorFormater } from "../../../common/formik-urql-error-helper";
import { Grid, Column } from "../../../../../components/src";
import { Link } from "react-router-dom";
import { MarkDownField } from "../../../components/form/MarkDownField";

interface PartyIssuerTabPropsType {
    partyId: string;
}

export const PartyIssuerTab = ({ partyId }: PartyIssuerTabPropsType): ReactElement => {
    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });

    const [currentParty, setCurrentParty] = useState(cloneDeep(null));
    const [_, updatePartyIssuer] = useUpdatePartyIssuerMutation();
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (data) {
            const p = cloneDeep(data.party);
            if (p.issuer) {
                p.issuer.transitionCompany = p.issuer.transitionCompany || false;
                p.issuer.captorDnshCompliant = p.issuer.captorDnshCompliant || false;
                p.issuer.ghgEmissions = p.issuer.ghgEmissions || false;
                p.issuer.carbonFootprint = p.issuer.carbonFootprint || false;
                p.issuer.ghgIntensityOfInvesteeCompanies = p.issuer.ghgIntensityOfInvesteeCompanies || false;
                p.issuer.fossilFuelCompaniesExposure = p.issuer.fossilFuelCompaniesExposure || false;
                p.issuer.nonRenewableEnergyShare = p.issuer.nonRenewableEnergyShare || false;
                p.issuer.energyIntensityHighImpact = p.issuer.energyIntensityHighImpact || false;
                p.issuer.activitiesAffectingBiodiversityAreas = p.issuer.activitiesAffectingBiodiversityAreas || false;
                p.issuer.pollutionOfWater = p.issuer.pollutionOfWater || false;
                p.issuer.hazardousWasteRatio = p.issuer.hazardousWasteRatio || false;
                p.issuer.deforestation = p.issuer.deforestation || false;
                p.issuer.recordableWorkAccidents = p.issuer.recordableWorkAccidents || false;
                p.issuer.lackOfProcessesAndCompliance = p.issuer.lackOfProcessesAndCompliance || false;
                p.issuer.unadjustedGenderPayGap = p.issuer.unadjustedGenderPayGap || false;
                p.issuer.managementSupervisoryGenderDiversity = p.issuer.managementSupervisoryGenderDiversity || false;
                p.issuer.scienceBasedTargetInitiative = p.issuer.scienceBasedTargetInitiative || ScienceBasedTargetInitiativeEnum.NoTarget;
                p.issuer.nearTermTargetClassification = p.issuer.nearTermTargetClassification || "";
                p.issuer.netZeroCommitted = p.issuer.netZeroCommitted || false;
            }
            setCurrentParty(p);
        }
    }, [data, refetch]);

    if (loading) return <div>Loading</div>;

    if (error) return <div>{"Error: " + error.message}</div>;

    if (!currentParty) return <div></div>;

    const defaultGlobalStandardScreensDefinition = {
        status: StandardScreenStatus.Compliant,
        norm: StandardScreenNorm.None,
        date: "",
        principle: StandardScreenPrinciple.None,
        comment: ""
    };
    const defaultShareholderEngagementsDefinition = {
        method: EngagementMethod.Voting,
        norm: StandardScreenNorm.None,
        date: "",
        agm: "",
        principle: StandardScreenPrinciple.None,
        comment: ""
    };

    const defaultCommentsDefinition = {
        date: "",
        comment: ""
    };

    const defaultProductInvolvementDefinition = {
        involvement: ProductInvolvementIndicator.AdultEntertainment,
        range: RangeEnum.R0To5
    };

    return (
        <div id="partyissuerform" className="partyissuerform form">
            <Formik
                enableReinitialize={true}
                initialValues={
                    currentParty.issuer
                        ? currentParty.issuer
                        : {
                              iorp: IorpEnum.None,
                              transitionCompany: false,
                              captorDnshCompliant: false,
                              ghgEmissions: false,
                              carbonFootprint: false,
                              ghgIntensityOfInvesteeCompanies: false,
                              fossilFuelCompaniesExposure: false,
                              nonRenewableEnergyShare: false,
                              energyIntensityHighImpact: false,
                              activitiesAffectingBiodiversityAreas: false,
                              pollutionOfWater: false,
                              hazardousWasteRatio: false,
                              deforestation: false,
                              recordableWorkAccidents: false,
                              lackOfProcessesAndCompliance: false,
                              unadjustedGenderPayGap: false,
                              managementSupervisoryGenderDiversity: false,
                              scienceBasedTargetInitiative: ScienceBasedTargetInitiativeEnum.NoTarget,
                              netZeroCommitted: false,
                              nearTermTargetClassification: "",
                              esg: {
                                  globalStandardScreen: [],
                                  shareholderEngagement: [],
                                  comments: [],
                                  productInvolvement: []
                              }
                          }
                }
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const input: any = recursivelyRemoveKey(submitValues, "__typename");

                    await updatePartyIssuer({ partyId: partyId, input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({ color: "success", visible: true, message: `The party has been updated successfully!` });
                                refetch();
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => {
                    return (
                        <Fragment>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form autoComplete="off">
                                        <div className="form-row">
                                            <SelectField
                                                name="iorp"
                                                label="Iorp"
                                                options={Object.keys(IorpEnum).sort()}
                                                className="col-5"
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="transitionCompany" label="Transition company" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="captorDnshCompliant"
                                                label="Compliant with Captor DNSH requirement for a transition company"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <label className="font-weight-bold">PAI considered</label>
                                        </div>
                                        <div className="form-row" style={{ marginTop: 12 }}>
                                            <p>
                                                <u>Climate and other environmental related indicators</u>
                                            </p>
                                        </div>

                                        <div className="form-row" style={{ marginTop: 4 }}>
                                            <CheckboxItem name="ghgEmissions" label="1. GHG emissions" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="carbonFootprint" label="2. Carbon footprint" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="ghgIntensityOfInvesteeCompanies"
                                                label="3. GHG intensity of investee companies"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="fossilFuelCompaniesExposure"
                                                label="5. Exposure to companies in the fossil fuel sector"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="nonRenewableEnergyShare"
                                                label="6. Share of non-renewable energy consumption and production"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="energyIntensityHighImpact"
                                                label="7. Energy consumption intensity per high impact climate sector"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="activitiesAffectingBiodiversityAreas"
                                                label="8. Activities negatively affecting biodiversity sensitive areas"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="pollutionOfWater" label="9. Pollution of water" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="hazardousWasteRatio" label="10. Hazardous waste ratio" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="deforestation" label="16. Deforestation (additional)" />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 12 }}>
                                            <p>
                                                <u>
                                                    Indicators for social and employee, respect for human rights, anti-corruption, and
                                                    anti-bribery matters
                                                </u>
                                            </p>
                                        </div>
                                        <div className="form-row" style={{ marginTop: 4 }}>
                                            <CheckboxItem
                                                name="recordableWorkAccidents"
                                                label="2. Rate of recordable work-related accidents (additional)"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="lackOfProcessesAndCompliance"
                                                label="11. Lack of processes and compliance mechanisms to monitor compliance with OECD
                                                Guidelines for Multinational Enterprises or the UN Guiding principles, including the
                                                principles and rights set out in the eight fundamental conventions identified in the ILO Declaration and the International Bill of Human Rights (additional)"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="unadjustedGenderPayGap"
                                                label="12. Unadjusted gender pay gap between female and employees"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <CheckboxItem
                                                name="managementSupervisoryGenderDiversity"
                                                label="13. Management and supervisory board gender diversity"
                                            />
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <label className="font-weight-bold">Environmental, social and governance</label>
                                        </div>
                                        <div className="form-row" style={{ marginTop: 8 }}>
                                            <label>Global standard screen</label>
                                        </div>
                                        <FieldArray
                                            name="esg.globalStandardScreen"
                                            render={(arrayHelpers) => (
                                                <Fragment>
                                                    {values.esg &&
                                                    values.esg.globalStandardScreen &&
                                                    values.esg.globalStandardScreen.length > 0 ? (
                                                        values.esg.globalStandardScreen.map((item, index) => (
                                                            <div key={index} className="">
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.globalStandardScreen[${index}].status`}
                                                                        label={"Status*"}
                                                                        options={Object.keys(StandardScreenStatus).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                    <SelectField
                                                                        name={`esg.globalStandardScreen[${index}].norm`}
                                                                        label={"Norm*"}
                                                                        options={Object.keys(StandardScreenNorm).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.globalStandardScreen[${index}].principle`}
                                                                        label={"Principle*"}
                                                                        options={Object.keys(StandardScreenPrinciple).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                    <DateField
                                                                        className="col-5"
                                                                        name={`esg.globalStandardScreen[${index}].date`}
                                                                        label="Date*"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <TextField
                                                                        name={`esg.globalStandardScreen[${index}].comment`}
                                                                        label="Comment*"
                                                                        className="col-5"
                                                                        disabled={false}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-3">
                                                                        <Button
                                                                            className="me-1 mt-4 btn-danger btn-sm"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            -
                                                                        </Button>

                                                                        <Button
                                                                            className="btn-sm mt-4"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(
                                                                                    index,
                                                                                    cloneDeep(defaultGlobalStandardScreensDefinition)
                                                                                )
                                                                            }
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="form-row">
                                                            <div className="col-1">
                                                                <Button
                                                                    className="btn-sm ms-1"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(cloneDeep(defaultGlobalStandardScreensDefinition))
                                                                    }
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        />
                                        <div className="form-row" style={{ marginTop: 14 }}>
                                            <label>Shareholder engagement</label>
                                        </div>
                                        <FieldArray
                                            name="esg.shareholderEngagement"
                                            render={(arrayHelpers) => (
                                                <Fragment>
                                                    {values.esg &&
                                                    values.esg.shareholderEngagement &&
                                                    values.esg.shareholderEngagement.length > 0 ? (
                                                        values.esg.shareholderEngagement.map((item, index) => (
                                                            <div key={index} className="">
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.shareholderEngagement[${index}].method`}
                                                                        label={"Status*"}
                                                                        options={Object.keys(EngagementMethod).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />

                                                                    <DateField
                                                                        className="col-5"
                                                                        name={`esg.shareholderEngagement[${index}].date`}
                                                                        label="Date*"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.shareholderEngagement[${index}].principle`}
                                                                        label={"Principle*"}
                                                                        options={Object.keys(StandardScreenPrinciple).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                    <DateField
                                                                        className="col-5"
                                                                        name={`esg.shareholderEngagement[${index}].agm`}
                                                                        label="Agm*"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.shareholderEngagement[${index}].norm`}
                                                                        label={"Norm*"}
                                                                        options={Object.keys(StandardScreenNorm).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                    <TextField
                                                                        name={`esg.shareholderEngagement[${index}].comment`}
                                                                        label="Comment*"
                                                                        className="col-5"
                                                                        disabled={false}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-3">
                                                                        <Button
                                                                            className="me-1 mt-4 btn-danger btn-sm"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            -
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-sm mt-4"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(
                                                                                    index,
                                                                                    cloneDeep(defaultShareholderEngagementsDefinition)
                                                                                )
                                                                            }
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="form-row">
                                                            <div className="col-1">
                                                                <Button
                                                                    className="btn-sm ms-1"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(
                                                                            cloneDeep(defaultShareholderEngagementsDefinition)
                                                                        )
                                                                    }
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        />
                                        <div className="form-row" style={{ marginTop: 14 }}>
                                            <div className="col">
                                                <label>Product involvement</label>
                                            </div>
                                        </div>
                                        <FieldArray
                                            name="esg.productInvolvement"
                                            render={(arrayHelpers) => (
                                                <Fragment>
                                                    {values.esg &&
                                                    values.esg.productInvolvement &&
                                                    values.esg.productInvolvement.length > 0 ? (
                                                        values.esg.productInvolvement.map((item, index) => (
                                                            <div key={index} className="">
                                                                <div className="form-row">
                                                                    <SelectField
                                                                        name={`esg.productInvolvement[${index}].involvement`}
                                                                        label={"Involvement*"}
                                                                        options={Object.keys(ProductInvolvementIndicator).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                    <SelectField
                                                                        name={`esg.productInvolvement[${index}].range`}
                                                                        label={"Range*"}
                                                                        options={Object.keys(RangeEnum).sort()}
                                                                        className="col-5"
                                                                        disabled={isSubmitting}
                                                                        size={12}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-5">
                                                                        <Button
                                                                            className="me-1 mt-4 btn-danger btn-sm"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            -
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-sm mt-4"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(
                                                                                    index,
                                                                                    cloneDeep(defaultProductInvolvementDefinition)
                                                                                )
                                                                            }
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <Button
                                                                    className="btn-sm ms-1"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(cloneDeep(defaultProductInvolvementDefinition))
                                                                    }
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        />
                                        <div className="form-row" style={{ marginTop: 14 }}>
                                            <div className="col">
                                                <label>Comments</label>
                                            </div>
                                        </div>
                                        <FieldArray
                                            name="esg.comments"
                                            render={(arrayHelpers) => (
                                                <Fragment>
                                                    {values.esg && values.esg.comments && values.esg.comments.length > 0 ? (
                                                        values.esg.comments.map((item, index) => (
                                                            <div key={index} className="">
                                                                <div className="form-row">
                                                                    <TextField
                                                                        name={`esg.comments[${index}].comment`}
                                                                        label="Comment*"
                                                                        className="col-5"
                                                                        disabled={false}
                                                                    />
                                                                    <DateField
                                                                        className="col-5"
                                                                        name={`esg.comments[${index}].date`}
                                                                        label="date*"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-5">
                                                                        <Button
                                                                            className="me-1 mt-4 btn-danger btn-sm"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            -
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-sm mt-4"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(
                                                                                    index,
                                                                                    cloneDeep(defaultCommentsDefinition)
                                                                                )
                                                                            }
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <Button
                                                                    className="btn-sm ms-1"
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.push(cloneDeep(defaultCommentsDefinition))}
                                                                >
                                                                    +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        />
                                        <div className="form-row mt-4">
                                            <SelectField
                                                name="scienceBasedTargetInitiative"
                                                label="Science based target tnitiative"
                                                options={Object.keys(ScienceBasedTargetInitiativeEnum).sort()}
                                                className="col-sm-5"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row ms-1" style={{ marginTop: 8 }}>
                                            <CheckboxItem name="netZeroCommitted" label="Net zero committed" />
                                        </div>
                                        <div className="form-row">
                                            <MarkDownField
                                                name="nearTermTargetClassification"
                                                label="Near term target classification"
                                                className="col-sm-5"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        {alert.visible ? (
                                            <Alert variant={alert.color} style={{ marginTop: "10px" }} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                        <SubmitButton disabled={isSubmitting} label="Update" />
                                    </Form>
                                </div>
                                <div className="col-sm-6">
                                    <Grid
                                        header="Instruments"
                                        data={data.issuerInstruments}
                                        tableClassName="table-xs issuerinstrumentsgrid"
                                    >
                                        <Column
                                            field="name"
                                            className="grid-column-sticky"
                                            format={(value, item) => {
                                                return (
                                                    <Link to={"/instruments/" + item._id} target="_blank">
                                                        {value}
                                                    </Link>
                                                );
                                            }}
                                        />
                                        <Column field="currency" />
                                    </Grid>

                                    <Grid header="Issuer programs" data={data.issuerPrograms} tableClassName="table-xs issuerprogramsgrid">
                                        <Column
                                            field="name"
                                            format={(value, item) => {
                                                return (
                                                    <Link to={"/issuerprograms/" + item._id} target="_blank">
                                                        {value}
                                                    </Link>
                                                );
                                            }}
                                        />
                                        <Column field="category" />
                                        <Column
                                            field="spoProvider.name"
                                            title="SPO provider"
                                            format={(value, item) => {
                                                return (
                                                    <Link to={item.spoProvider ? "/parties/" + item.spoProvider._id : "#"} target="_blank">
                                                        {value}
                                                    </Link>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
};
