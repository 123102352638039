import React, { ReactElement } from "react";
import { Grid, Column } from "../../../../components/src";
import { toPositionGridViewItem, PortfolioLedgerPosition, valueFormat } from "../../../../common/src";

import { getTAccountMapping, TAccountMappingExtended, selectorToString } from "../../../../common/src/generalledger/TAccountChart";
import { Party, Instrument } from "../../types.generated";
import { Link } from "react-router-dom";

export const PositionsGrid = ({
    positions,
    party,
    instrumentsById,
    tAccountMappings
}: {
    positions: PortfolioLedgerPosition[];
    party: Party;
    instrumentsById: { [id: string]: Instrument | any };
    tAccountMappings: TAccountMappingExtended[];
}): ReactElement => {
    let grid = positions.map((d: PortfolioLedgerPosition) => toPositionGridViewItem(d, party, instrumentsById) as any);
    if (tAccountMappings) {
        const allInstruments = Object.values(instrumentsById);
        // eslint-disable-next-line prefer-spread
        allInstruments.push.apply(allInstruments, party.instruments);
        grid.forEach((g, i) => {
            const p = positions[i];
            let instr: any = instrumentsById[p.instrumentId];
            if (!instr) {
                instr = party.instruments.find((d) => d._id === p.instrumentId);
            }
            const { values, index, tAccountChart } = getTAccountMapping(null, instr, tAccountMappings);
            g.tAccountChart = tAccountChart;
            g.tAccountMappingValues = values;
            if (!tAccountMappings[index]) {
                g.tAccountMappingSelector = "No mapping";
            } else {
                g.tAccountMappingSelector = selectorToString(tAccountMappings[index].selector, allInstruments);
            }
            g.tAccountMappingIndex = index;
        });
    }
    grid = grid.filter((d) =>
        ["quantity", "startValue", "cashFlow", "realizedProfitLoss", "endUnrealizedProfitLoss", "startUnrealizedProfitLoss", "income"].some(
            (e) => Math.abs(d[e]) > 0.1
        )
    );
    grid.sort((d1, d2) => {
        const c = (d1.cashAccount ? 1 : 0) - (d2.cashAccount ? 1 : 0);
        if (c !== 0) {
            return c;
        }
        return (d1.name ? d1.name.toLocaleUpperCase() : null) < (d2.name ? d2.name.toLocaleUpperCase() : null) ? -1 : 1;
    });
    return (
        <div className="col-12">
            <Grid className="portfoliomanagergrid" header={"Positions"} data={grid} tableClassName="table-xs" sortable>
                <Column field="name" className="grid-column-sticky" />
                {/* <Column field="longName" /> */}
                <Column field="currency" />
                <Column className="right minwidth75px" field="quantity" format={valueFormat} />
                <Column className="right minwidth75px" field="endValue" title="End value" format={valueFormat} />
                <Column className="right minwidth75px" field="startValue" title="Start value" format={valueFormat} />
                <Column className="right minwidth75px" field="cashFlow" title="Cash flow" format={valueFormat} />
                <Column className="right minwidth75px" field="foProfitLoss" title="FO P&amp;L" format={valueFormat} />
                <Column className="right minwidth75px" field="realizedProfitLoss" title="RP&amp;L" format={valueFormat} />
                <Column className="right minwidth75px" field="endUnrealizedProfitLoss" title="End UP&amp;L" format={valueFormat} />
                <Column className="right minwidth75px" field="startUnrealizedProfitLoss" title="Start UP&amp;L" format={valueFormat} />
                <Column className="right minwidth75px" field="accrued" format={valueFormat} />
                <Column className="right minwidth75px" field="income" format={valueFormat} />
                <Column className="right minwidth75px" field="boProfitLoss" title="BO P&amp;L" format={valueFormat} />
                <Column className="nowrap" field="productType" title="Product type" />
                <Column field="category" />
                <Column
                    field="tAccountMappingSelector"
                    title="Mapping rule"
                    format={(value, item) => {
                        return item.tAccountChart ? (
                            <Link to={`/taccountcharts/${item.tAccountChart._id}/-1/${item.tAccountMappingIndex}`}>{value}</Link>
                        ) : (
                            value
                        );
                    }}
                />
            </Grid>
        </div>
    );
};
