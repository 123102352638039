import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { TimeSeries } from "../../../common/src";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);
interface IRelativePerformanceTimeSeriesChartProps {
    value: any;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const RelativePerformanceTimeSeriesChart = ({
    value,
    config,
    layout,
    ...props
}: IRelativePerformanceTimeSeriesChartProps): React.ReactElement => {
    let portf = value;
    let pm = portf.returnTimeSeries;
    let bm = portf.benchmarkTimeSeries;
    let max = 0.05;
    if (portf.benchmarkTimeSeries && portf.benchmarkTimeSeries.length > 0) {
        var start = pm.start.number > bm.start.number ? pm.start : bm.start;
        var end = pm.end.number < bm.end.number ? pm.end : bm.end;
        pm = pm.range(start, end);
        pm = pm.mult(1 / pm.startValue);
        bm = TimeSeries.align(pm, bm);
        bm = bm.mult(1 / bm.startValue);
        //diff = TimeSeries.weightedTimeSeries([1.0, -1.0], [pm.log, bm.log]).exp.add(-1);
        var diff = TimeSeries.weightedTimeSeries([1.0, -1.0], [pm, bm]);
        max = diff.__values.reduce((p, c) => Math.max(Math.abs(c), p), 0);
    }
    const data: any = [PlotlyDefaults.toTrace(diff, "", "rgba(67, 160, 76, 1.0)")];
    data[0].fill = "tozeroy";
    data[0].marker = { autocolorscale: true, color: "rgba(67, 160, 76, 0.7)" };

    const localLayout: Partial<Plotly.Layout> = {
        //widthHeightRatio: 2.0,
        yaxis: { tickformat: max < 0.06 ? ".1%f" : "%f" },
        xaxis: { hoverformat: "%b %d" }
        //legend: { showlegend: "false" }
    };
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return <Plot data={data} layout={thisLayout} config={thisConfig} {...props} />;
};
