import React, { ReactElement } from "react";
import { Grid, Column } from "../../../../components/src";
import { emptyObjectId } from "../../../../common/src";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AccountingRunStatusEnum } from "../../../../common/src/types.generated";

export const AccountingRunsGrid = ({
    accountingRuns,
    setModal,
    accountingReadWriteAccess
}: {
    accountingRuns: any[];
    setModal: any;
    accountingReadWriteAccess: boolean;
}): ReactElement => {
    return (
        <Grid className="accountingrunsgrid" header={"Accounting Runs"} data={accountingRuns} tableClassName="table-xs" sortable>
            <Column field="accountingPeriod" title="Acc. period" className="center" />
            <Column
                field="number"
                className="grid-column-sticky center"
                format={(value, item) => {
                    return <Link to={`/journalentries/${item.clientId}/${item.accountingPeriod}/${item.number}`}>{value}</Link>;
                }}
            />
            <Column field="endDate" title="End date" />
            <Column
                field="status"
                format={(d, item) => {
                    if (
                        (item.status === AccountingRunStatusEnum.Pending || item.status === AccountingRunStatusEnum.Preliminary) &&
                        accountingReadWriteAccess
                    ) {
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={item._id}>
                                    {item.status}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {[AccountingRunStatusEnum.Deleted].map((changeTo, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    setModal({
                                                        showModal: true,
                                                        payload: item._id
                                                    });
                                                }}
                                            >
                                                Change to {changeTo}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    } else {
                        return item.status;
                    }
                }}
            />
            <Column field="type" />
            <Column
                title="Master T-acc. chart"
                field="masterTAccountChartId"
                className="center"
                format={(_, item) => {
                    return item && item.masterTAccountChartId !== emptyObjectId ? (
                        <Link to={`/taccountcharts/${item.masterTAccountChartId}`}>Link</Link>
                    ) : (
                        ""
                    );
                }}
            />
            <Column
                title="Client T-acc. chart"
                field="clientTAccountChartId"
                className="center"
                format={(_, item) => {
                    return item && item.clientTAccountChartId !== emptyObjectId ? (
                        <Link to={`/taccountcharts/${item.clientTAccountChartId}`}>Link</Link>
                    ) : (
                        ""
                    );
                }}
            />
            <Column
                field="_id"
                title="Id"
                format={(value, item) => {
                    return <Link to={`/journalentries/${item.clientId}/${item.accountingPeriod}/${item.number}`}>{value}</Link>;
                }}
            />
        </Grid>
    );
};
