import {
    Valuations,
    Inventory,
    ProfitLoss,
    CalcPoint,
    calcRealizedType,
    isPositionBuildingType,
    isCashAccountType,
    InventoryItem,
    isRealizationType,
    calcRealization,
    minDate
} from ".";

import { RealizedType } from "../types";
import { PriceType, TransactionItem } from "../types.generated";

const { abs } = Math;

export class PortfolioLedgerPosition {
    constructor(
        instrumentId: string | null = null,
        valuations: Valuations,
        startDate: string,
        endDate: string,
        accountingCurrency: string
    ) {
        this.instrumentId = instrumentId;
        this.valuations = valuations;

        this.inventory = new Inventory();
        this.incomeInventory = new Inventory();
        this.realizedProfitLoss = new ProfitLoss();
        this.income = null;

        this.startUnrealizedProfitLoss = null;
        this.endUnrealizedProfitLoss = null;
        this.totalProfitLoss = null;

        this.startDate = startDate;
        this.endDate = endDate;
        this.accountingCurrency = accountingCurrency;
        this.currency = null;

        this.cashAccount = false;
        this.openingBalanceAmount = 0;
        this.startUpl = null;
        this.endUpl = null;
        this.startAccrued = 0;
        this.endAccrued = 0;

        this.startMarketValue = 0;
        this.endMarketValue = 0;
        this.cashFlow = 0;

        this.calcPoints = [];
        this.lastTradeTimestamp = null;
    }

    instrumentId: string;
    valuations: Valuations;

    currency: string;
    accountingCurrency: string;
    startDate: string;
    endDate: string;

    inventory: Inventory;
    incomeInventory: Inventory;
    realizedProfitLoss: ProfitLoss;
    startUnrealizedProfitLoss: ProfitLoss;
    endUnrealizedProfitLoss: ProfitLoss;
    totalProfitLoss: ProfitLoss;
    income: ProfitLoss;

    cashAccount: boolean;
    openingBalanceAmount: number;
    startUpl: ProfitLoss;
    endUpl: ProfitLoss;
    startAccrued: number;
    endAccrued: number;

    startMarketValue: number;
    endMarketValue: number;
    cashFlow: number;

    calcPoints: CalcPoint[];
    lastTradeTimestamp: Date;

    static calcPosition(
        instrumentId: string,
        transactionItems: TransactionItem[],
        valuations: Valuations,
        startDate: string,
        endDate: string,
        accountingCurrency: string
    ): PortfolioLedgerPosition {
        [instrumentId, transactionItems, valuations, startDate, endDate, accountingCurrency].forEach((a) => {
            if (typeof a === "undefined" || a === null) {
                throw new Error("Calc position missing required fields");
            }
        });
        const pos = new PortfolioLedgerPosition(instrumentId, valuations, startDate, endDate, accountingCurrency);

        pos.currency = PortfolioLedgerPosition.getBaseCurrency(transactionItems, pos.accountingCurrency);
        if (pos.currency === null || typeof pos.currency === "undefined") {
            console.log("meow");
        }
        pos.cashAccount = PortfolioLedgerPosition.isCashAccount(transactionItems);
        pos.cashFlow = 0;

        for (let j = 0; j < transactionItems.length; j++) {
            const item = transactionItems[j];
            if (pos.startUnrealizedProfitLoss === null && item.transaction.tradeDate > startDate) {
                pos.setUnrealizedProfitLoss(startDate, true);
            }
            const vals = pos.getTransactionItemValues(item);
            const realizeType = calcRealizedType(item, vals.quantity, startDate);
            const cp = pos.calcTransactionItem(item, vals, realizeType);
            pos.cashFlow += cp.cashFlow;
            pos.inventory = cp.inventory;
            pos.calcPoints.push(cp);
            pos.lastTradeTimestamp = item.transaction.tradeTimestamp;
        }
        if (pos.startUnrealizedProfitLoss === null) {
            pos.setUnrealizedProfitLoss(startDate, true);
        }
        const cp = pos.setUnrealizedProfitLoss(endDate, false);
        // pos.cashFlow *= cp.valuation.fxRate;
        // if (addClosingBalanceValuations) {
        // 	AddClosingBalanceValuation("BVI", "BVU", "Utgående balansvärdering " + Instrument.LongName, enddate, mappingrule,
        // endupl.Negate, -endaccrued);
        // }
        pos.income = PortfolioLedgerPosition.calcUnrealizedProfitLoss(pos.incomeInventory, cp.valuation.price, cp.valuation.fxRate, false);
        pos.totalProfitLoss = ProfitLoss.clone(pos.realizedProfitLoss);
        pos.totalProfitLoss = pos.totalProfitLoss.add(pos.endUnrealizedProfitLoss);
        pos.totalProfitLoss = pos.totalProfitLoss.subtract(pos.startUnrealizedProfitLoss);
        pos.totalProfitLoss = pos.totalProfitLoss.subtract(pos.income);
        return pos;
    }

    addTransactionItem(item: TransactionItem): void {
        const vals = this.getTransactionItemValues(item);
        const realizeType = calcRealizedType(item, vals.quantity, this.startDate);
        let cp = this.calcTransactionItem(item, vals, realizeType);
        this.cashFlow += cp.cashFlow;
        this.inventory = cp.inventory;
        this.calcPoints.push(cp);
        this.lastTradeTimestamp = item.transaction.tradeTimestamp;
        cp = this.setUnrealizedProfitLoss(this.endDate, false);
        this.income = PortfolioLedgerPosition.calcUnrealizedProfitLoss(
            this.incomeInventory,
            cp.valuation.price,
            cp.valuation.fxRate,
            false
        );
        this.totalProfitLoss = ProfitLoss.clone(this.realizedProfitLoss);
        this.totalProfitLoss = this.totalProfitLoss.add(this.endUnrealizedProfitLoss);
        this.totalProfitLoss = this.totalProfitLoss.subtract(this.startUnrealizedProfitLoss);
        this.totalProfitLoss = this.totalProfitLoss.subtract(this.income);
    }

    /**
     * Returns the currency of the first position building transaction item.
     * @param transactionItems
     */
    static getBaseCurrency(transactionItems: TransactionItem[], defaultCurrency: string): string {
        for (let i = 0; i < transactionItems.length; i++) {
            const ti = transactionItems[i];
            if (isPositionBuildingType(ti.type)) {
                return ti.currency;
            }
        }
        return defaultCurrency;
    }

    static isCashAccount(items: TransactionItem[]): boolean {
        return items.every((f) => isCashAccountType(f.type));
    }

    getTransactionItemValues(item: TransactionItem): InventoryItem {
        const res: InventoryItem = new InventoryItem(item.amount, 0, 0, 1);
        // if (item.type === TransactionItemType.SettlementAmount) {
        //     res.quantity = 0;
        //     res.price = 0;
        // } else
        if (this.cashAccount) {
            res.quantity = item.amount;
            res.price = 1;
        } else {
            if (item.quantity !== null) {
                res.quantity = item.quantity;
            }
            if (item.price !== null) {
                res.price = item.price;
            }
        }
        if (item.currency !== this.accountingCurrency) {
            if (!item.fxRate) {
                const fxPairName = item.currency + this.accountingCurrency;
                const p = this.valuations.getFxValue(fxPairName, item.transaction.tradeDate);
                if (!p) {
                    throw new Error(`FxRate needed for ${fxPairName} at ${item.transaction.tradeDate}`);
                }
                res.fxRate = p;
            } else {
                res.fxRate = item.fxRate;
            }
        }
        return res;
    }

    calcTransactionItem(ti: TransactionItem, itemValues: InventoryItem, rt: RealizedType): CalcPoint {
        let cashFlow = 0;
        let inventoryChange = InventoryItem.clone(itemValues);
        if (rt === RealizedType.IncomeCost || rt === RealizedType.RealizedProfitLoss || rt === RealizedType.SettlementAmount) {
            cashFlow = inventoryChange.amount * inventoryChange.fxRate;
        }
        let income: InventoryItem = null;
        if (rt === RealizedType.IncomeCost || rt === RealizedType.SettlementAmount) {
            this.incomeInventory.add(inventoryChange);
            if (rt === RealizedType.IncomeCost) {
                income = inventoryChange;
            }
        }
        if (ti !== null && !isRealizationType(ti.type)) {
            inventoryChange = new InventoryItem();
        }
        const res = calcRealization(this.inventory, inventoryChange);
        if (rt === RealizedType.RealizedProfitLoss) {
            this.realizedProfitLoss = this.realizedProfitLoss.add(res.realizedProfitLoss);
        } else {
            res.realizedProfitLoss = new ProfitLoss();
        }
        let ignoreLedger = false;
        if (ti === null || ti.transaction.tradeDate <= this.startDate) {
            ignoreLedger = true;
        }
        return {
            realizedProfitLoss: res.realizedProfitLoss,
            inventory: res.inventory,
            income,
            inventoryChange,
            ignoreLedger,
            transactionItem: ti,
            realizedType: rt,
            date: ti ? ti.transaction.tradeDate : minDate,
            valuation: null,
            cashFlow
        };
    }
    static calcUnrealizedProfitLoss(inv: Inventory, mp: number, mfx: number, zeroqtynoinitcost: boolean): ProfitLoss {
        if (inv === null) {
            return new ProfitLoss();
        }
        const res = new ProfitLoss(inv, inv === null ? 0 : -inv.position, mp, mfx, zeroqtynoinitcost);
        res.quantity *= -1;
        return res;
    }
    static calcAccrued(inv: Inventory, accruedInterest: number, marketFxRate: number): number {
        return (inv ? inv.position : 0) * accruedInterest * marketFxRate;
    }
    setUnrealizedProfitLoss(date: string, start: boolean): CalcPoint {
        const cp = this.calcTransactionItem(null, null, RealizedType.Valuation);
        this.calcPoints.push(cp);
        this.getValuation(cp, date, start);
        const uplz = PortfolioLedgerPosition.calcUnrealizedProfitLoss(this.inventory, cp.valuation.price, cp.valuation.fxRate, false);
        const upl = PortfolioLedgerPosition.calcUnrealizedProfitLoss(this.inventory, cp.valuation.price, cp.valuation.fxRate, true);
        const accrued = PortfolioLedgerPosition.calcAccrued(this.inventory, cp.valuation.accruedInterest, cp.valuation.fxRate);
        if (start) {
            this.startUpl = upl;
            this.startAccrued = accrued;
            this.startUnrealizedProfitLoss = uplz;
            this.openingBalanceAmount = cp.inventory.initialCost;
        } else {
            this.endUpl = upl;
            this.endAccrued = accrued;
            this.endUnrealizedProfitLoss = uplz;
        }
        return cp;
    }
    getValuation(calcPoint: CalcPoint, date: string, start: boolean): void {
        let fx = 1.0;
        let p = this.cashAccount ? 1.0 : 0.0;
        let a = 0.0;
        const pos = calcPoint.inventory.position;
        if (this.currency && this.currency !== this.accountingCurrency) {
            const fxPairName = this.currency + this.accountingCurrency;
            fx = this.valuations.getFxValue(fxPairName, date);
            if (fx === null) {
                throw new Error(`FX rate needed for ${fxPairName}, date=${date}`);
            }
        }
        if ((!start || (start && abs(pos) > 1e-7)) && !this.cashAccount) {
            const tmp = this.valuations.getPrice(this.instrumentId, date, this.currency);
            p = Valuations.priceByTypes(tmp, [PriceType.Price, PriceType.CleanPrice]);
            // const tmp2 = this.valuations.getPrice(this.instrumentId, date, this.currency);
            a = Valuations.priceByTypes(tmp, [PriceType.AccruedInterest]);
        }
        calcPoint.date = date;
        calcPoint.valuation = { price: p, fxRate: fx, accruedInterest: a };
        // calcPoint.marketPrice = p;
        // calcPoint.marketFxRate = fx;
        // calcPoint.accruedInterest = a;
        const mv = pos * (p + a) * fx;
        if (start) {
            this.startMarketValue = mv;
        } else {
            this.endMarketValue = mv;
        }
    }
}
