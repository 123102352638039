import React from "react";

export interface ISelectDropdownOption {
    key: string;
    text: string;
    data?: any;
}

interface ISelectDropdownProps {
    id?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    options: ISelectDropdownOption[];
    defaultValue?: string;
    onChange?: (e: ISelectDropdownOption) => void;
}

export function SelectDropdown({
    id,
    placeholder,
    className,
    disabled,
    options,
    defaultValue,
    onChange,
    ...props
}: ISelectDropdownProps): React.ReactElement {
    return (
        <select
            id={id}
            name={id}
            className={className}
            disabled={disabled}
            defaultValue={defaultValue ? defaultValue : "none"}
            onChange={(event) => {
                if (onChange) {
                    const option = options.find((v) => v.key === event.target.value);
                    onChange(option);
                }
            }}
            {...props}
        >
            {defaultValue ? null : (
                <option value="none" hidden disabled>
                    {placeholder}
                </option>
            )}
            {options.map((item: ISelectDropdownOption) => (
                <option key={item.key} value={item.key}>
                    {item.text}
                </option>
            ))}
        </select>
    );
}
