import React from "react";
import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);
interface CurrenciesProps {
    data: any;
    locale: Language;
    showHeader: boolean;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const Currencies = ({ data, locale, showHeader, config, layout, ...props }: CurrenciesProps): React.ReactElement => {
    let refname: string = null;
    let header: string = null;
    let explanation: string = null;
    if (locale === "En") {
        refname = "Market Cap Weights";
        header = "Currency Allocation";
        explanation = "The graph shows the currency allocation of the fund next to the allocation of the equivalent market cap portfolio.";
    } else {
        if (locale === "Sv") {
            refname = "Marknadsvärdesvikt";
            header = "Valutaallokering";
            explanation = "Grafen visar valutaallokering i fonden jämte den marknadsvärdeviktade underliggande populationen.";
        }
    }
    const trace1: Partial<Plotly.PlotData> = {
        name: refname,
        y: data.data.currencies.map((d) => d.key),
        x: data.data.currencies.map((d) => d.referenceValue),
        type: "bar",
        orientation: "h",
        hovertemplate: "%{y}: %{x:.1%}",
        marker: {
            color: chroma(PlotlyDefaults.getColor(11)).darken(-0.3).hex()
        }
    };
    const trace2: Partial<Plotly.PlotData> = {
        name: data.clientName,
        y: data.data.currencies.map((d) => d.key),
        x: data.data.currencies.map((d) => d.value),
        type: "bar",
        orientation: "h",
        hovertemplate: "%{y}: %{x:.1%}",
        marker: {
            color: chroma(PlotlyDefaults.getColor(1)).darken(0.1).hex()
        }
    };
    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        margin: { l: 40 },
        xaxis: { tickformat: ".0%" },
        yaxis: { showgrid: false },
        legend: {
            x: 0.99,
            xanchor: "right",
            y: 0.4,
            yanchor: "bottom"
        }
    };

    //if (barHeightRatio) {
    //    layout.height = data.data.currencies.length * barHeightRatio;
    //}

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}
            <Plot data={[trace1, trace2]} layout={thisLayout} config={thisConfig} {...props} />
            <br />
            <small>{explanation}</small>
        </React.Fragment>
    );
};
