import React, { useState, useEffect, useContext } from "react";
import { DateHelper, emptyObjectId, SwedenCalendar } from "../../../../common/src";
import { gql, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { MiniTransactionForm } from "../MiniTransactionForm";
import { DateForm } from "../../common/dateForm";
import { CollateralGrid } from "./CollateralGrid";
import { InstrumentProductTypeEnum, PartyType, TransactionType } from "../../types.generated";
import { cloneDeep, keyBy, sortBy } from "lodash";
import { usePrevious, useQueryArgs } from "../../common/Utils";
import { ClientContextSelector } from "../../contexts/ClientContextSelector";
import { ClientContext } from "../../contexts/ClientContext";
import { Panel } from "@fluentui/react";

const getReconcileExposureCollateral = gql`
    query reconcileExposureCollateral($clientIds: [GraphQLObjectId!]!, $date: GraphQLDateString!) {
        reconcileExposureCollateral(clientIds: $clientIds, date: $date) {
            externalAccountId
            externalAccount {
                name
            }
            agreementType
            clientId
            client {
                name
            }
            instrumentId
            instrument {
                isin
                name
            }
            exposure
            variationMarginPosted
            variationMarginCollateral
            variationMarginSecuritiesCollateral
            netExposure
            quantity
            quantityDifference
            counterpartyNetExposure
            currency
            baseCurrency
            exposureDifference
            variationMarginCollateralDifference
            variationMarginSecuritiesCollateralDifference
            netExposureDifference
            counterpartyExposure
            counterpartyQuantity
            counterpartyVariationMarginPosted
            counterpartyVariationMarginCollateral
            counterpartyVariationMarginSecuritiesCollateral
            minimumTransferAmount
            roundingAmount
            threshold
            marginCall
            counterpartyMarginCall
            valuationDate
            counterpartyValuationDate
            initialMarginRequirement
            initialMarginCollateral
            initialMarginSecuritiesCollateral
            counterpartyInitialMarginCollateral
            counterpartyInitialMarginSecuritiesCollateral
            initialMarginCollateralDifference
            initialMarginSecuritiesCollateralDifference
            initialMarginNetRequirement
            counterpartyInitialMarginNetRequirement
            counterpartyInitialMarginPosted
            netInitialMarginRequirementDifference
            marginCallsMade
            interestRateYieldDeltaSum
            creditYieldDeltaSum
            reconcileType
            type
            marginType
            missingReport
        }
    }
`;

const getParties = gql`
    query {
        funds: parties(filter: { typeIn: [Fund], statusIn: [Confirmed] }) {
            _id
            name
            instruments {
                _id
                productType
            }
        }
    }
`;

export type ClientInfo = {
    _id: string;
    label: string;
    value: string;
};

export const CollateralPage = (): React.ReactElement => {
    const { client } = useContext(ClientContext);
    const { queryArgs, replaceQueryArgs } = useQueryArgs();
    const previousQueryArgs: { endDate: string } = usePrevious(queryArgs);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [partiesById, setPartiesById] = useState({});
    const [collateralInstrumentIdsByClientId, setCollateralInstrumentIdsByClientId] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const [{ data: dataParties, fetching: loadingParties, error: errorParties }] = useQuery({ query: getParties });
    const [{ data: dataReconciled, fetching: loadingReconciled, error: errorReconciled }, refetch] = useQuery({
        query: getReconcileExposureCollateral,
        variables: { clientIds: Object.keys(partiesById), date: queryArgs.endDate },
        pause: !Object.keys(partiesById).length || !queryArgs.endDate
    });

    const params = useParams();
    let id = params.id;

    const marginCallTypes = [TransactionType.Insert, TransactionType.Transfer];

    let type = marginCallTypes[0];

    if (id && id.startsWith("new")) {
        type = marginCallTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
        id = "new";
    }

    useEffect(() => {
        // Adjusting to prev business date if needed
        const swedenCalendar = new SwedenCalendar();
        const date = (queryArgs.endDate as string) || new Date().toISOString().slice(0, 10);
        let previousDate = DateHelper.dateAddDays(new Date(date), -1);
        const todaysDate = DateHelper.dateToString(DateHelper.today()).substring(0, 10);
        const yesterday = DateHelper.dateAddDays(new Date(todaysDate), -1);
        let endDate = cloneDeep(date);

        if (!Object.keys(partiesById).length && dataParties && !loadingParties && !errorParties) {
            const instrumentIdsByClientId: Record<string, string[]> = {};
            for (const fund of dataParties.funds) {
                instrumentIdsByClientId[fund._id] = [];
                for (const instrument of fund.instruments) {
                    if (
                        instrument.productType === InstrumentProductTypeEnum.VariationMarginAccount ||
                        instrument.productType === InstrumentProductTypeEnum.InitialMarginAccount
                    ) {
                        instrumentIdsByClientId[fund._id].push(instrument._id);
                    }
                }
            }
            setCollateralInstrumentIdsByClientId(instrumentIdsByClientId);
            setPartiesById(keyBy(sortBy(dataParties.funds, "name"), "_id"));
        }

        if (selectedClientId !== client._id) {
            setSelectedClientId(client._id);
        }

        if (new Date(date) >= new Date(todaysDate) || !swedenCalendar.isBusinessDay(date)) {
            while (!swedenCalendar.isBusinessDay(previousDate) || new Date(previousDate) > new Date(yesterday)) {
                previousDate = DateHelper.dateAddDays(previousDate, -1);
            }
            endDate = DateHelper.dateToString(previousDate).substring(0, 10);
        }

        if (
            !previousQueryArgs ||
            previousQueryArgs.endDate !== endDate ||
            (previousQueryArgs && previousQueryArgs.endDate === endDate && date !== endDate)
        ) {
            if (Object.keys(partiesById).length) {
                refetch({
                    variables: { date: endDate, clientIds: Object.keys(partiesById) }
                });
            }
            replaceQueryArgs({ endDate: endDate });
        }
    }, [
        client._id,
        dataParties,
        errorParties,
        loadingParties,
        partiesById,
        previousQueryArgs,
        queryArgs.endDate,
        refetch,
        replaceQueryArgs,
        selectedClientId
    ]);

    if (loadingReconciled) return <p>Loading exposure collateral data</p>;
    if (errorReconciled)
        return (
            <p>
                Error exposure collateral data:{" "}
                {errorReconciled.message ? errorReconciled.message : JSON.stringify(errorReconciled, null, 2)}
            </p>
        );
    if (loadingParties) return <p>Loading funds</p>;
    if (errorParties) return <p>Error funds: {errorParties.message ? errorParties.message : JSON.stringify(errorParties, null, 2)}</p>;

    return (
        <div className="container form">
            <div className="row">
                <div className="col-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} defaultClientName="Summary" />
                </div>
                <div className="col-8 ms-auto p-2 text-end">
                    <DropdownButton title="New margin call" align="end" size="sm">
                        {marginCallTypes.map((type, i) => (
                            <Dropdown.Item
                                key={i}
                                as="button"
                                onClick={() =>
                                    navigate(`/reconcile/collateral/${client.dashName}/new${type.toLowerCase()}${location.search}`)
                                }
                            >
                                {type}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <DateForm
                        defaultDateString={new Date().toISOString().slice(0, 10)}
                        dateName={"endDate"}
                        label="End date"
                        className="width-fixed"
                    ></DateForm>
                </div>
            </div>

            <div className="row">
                {dataReconciled &&
                dataReconciled.reconcileExposureCollateral &&
                dataReconciled.reconcileExposureCollateral.length &&
                queryArgs ? (
                    <div className={"col-12"}>
                        <CollateralGrid
                            reconciledExposureCollateral={dataReconciled.reconcileExposureCollateral}
                            clientIds={selectedClientId !== emptyObjectId ? [selectedClientId] : Object.keys(partiesById)}
                            endDate={queryArgs.endDate as string}
                            partiesById={partiesById}
                            collateralInstrumentIds={collateralInstrumentIdsByClientId}
                            clientDashName={selectedClientId !== emptyObjectId && client ? client.dashName : null}
                        />
                    </div>
                ) : null}
            </div>

            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/collateral/${client.dashName}${location.search}`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} />
            </Panel>
        </div>
    );
};
