import React, { useEffect, useState } from "react";
import { gql } from "urql";
import { Link } from "react-router-dom";

import { twoDecPriceFormat } from "../../../common/src";
import { useGetDataQuery } from "./InstrumentPositionsPage.generated";
import { useQueryState } from "../common/use-query-state";
import { DateForm } from "../common/dateForm";
import { useQueryArgs } from "../common/Utils";
import { TableGrouper } from "../components/react-table/TableGrouper";

export const GET_DATA = gql`
    query getData($instrumentIds: [GraphQLObjectId!]!, $date: GraphQLDateString, $filterZeroPositions: Boolean) {
        accountPositions: instrumentAccountPositions(
            instrumentIds: $instrumentIds
            date: $date
            groupPositionsBy: AccountId
            filterZeroPositions: $filterZeroPositions
        ) {
            instrumentId
            instrument {
                name
            }
            accountId
            account {
                name
                description
            }
            externalAccountId
            externalAccount {
                name
                description
            }
            clientId
            client {
                name
            }
            quantity
        }
        externalAccountPositions: instrumentAccountPositions(
            instrumentIds: $instrumentIds
            date: $date
            groupPositionsBy: ExternalAccountId
            filterZeroPositions: $filterZeroPositions
        ) {
            instrumentId
            instrument {
                name
            }
            accountId
            account {
                name
                description
            }
            externalAccountId
            externalAccount {
                name
                description
            }
            clientId
            client {
                name
            }
            quantity
        }
    }
`;

interface AccountData {
    total: string;
    instrumentId: string;
    clientId: string;
    clientName: string;
    name: string;
    accountId: string;
    accountName: string;
    quantity: number;
}
interface ExternalAccountData {
    total: string;
    instrumentId: string;
    clientId: string;
    clientName: string;
    name: string;
    externalAccountId: string;
    externalAccountName: string;
    quantity: number;
}

export const InstrumentPositionsPage = (): React.ReactElement => {
    const { queryArgs } = useQueryArgs();
    const [accountData, setAccountData] = useState<AccountData[]>([]);
    const [externalAccountData, setExternalAccountData] = useState<ExternalAccountData[]>([]);
    // See https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
    const today = new Date();
    const evaluationDate = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000).toISOString().substring(0, 10);
    const [date, _setDate] = useQueryState("date", evaluationDate);

    const [{ data, fetching, error }] = useGetDataQuery({
        variables: { instrumentIds: queryArgs.instrumentIds, filterZeroPositions: true, date },
        requestPolicy: "cache-and-network",
        pause: !queryArgs.instrumentIds
    });

    const columns = React.useMemo(
        () => [
            {
                header: "Positions",
                columns: [
                    {
                        header: "Total",
                        accessorKey: "total"
                    },
                    {
                        header: "ClientId",
                        accessorKey: "clientId",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0]
                    },
                    {
                        header: "Client",
                        accessorKey: "clientName",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0],
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            return (
                                <Link to={`/parties/${row.original.clientId}`} target={"_blank"}>
                                    {cellProps.getValue()}
                                </Link>
                            );
                        }
                    },
                    {
                        header: "Account",
                        accessorKey: "accountName",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0],
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            return (
                                <Link to={`/parties/${row.original.clientId}/accounts/${row.original.accountId}`} target={"_blank"}>
                                    {cellProps.getValue()}
                                </Link>
                            );
                        }
                    },
                    {
                        header: "AccountId",
                        accessorKey: "accountId",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0]
                    },
                    {
                        header: "ExternalAccount",
                        accessorKey: "externalAccountName",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0],
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            return (
                                <Link
                                    to={`/parties/${row.original.clientId}/externalAccounts/${row.original.externalAccountId}`}
                                    target={"_blank"}
                                >
                                    {cellProps.getValue()}
                                </Link>
                            );
                        }
                    },
                    {
                        header: "ExternalAccountId",
                        accessorKey: "externalAccountId",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0]
                    },
                    {
                        header: "InstrumentId",
                        accessorKey: "instrumentId",
                        aggregationFn: (_leafValues: any, aggregatedValues: any) => aggregatedValues[0]
                    },
                    {
                        header: "Name",
                        accessorKey: "name",
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            return (
                                <Link to={`/instruments/${row.original.instrumentId}`} target={"_blank"}>
                                    {cellProps.getValue()}
                                </Link>
                            );
                        }
                    },
                    {
                        header: "Quantity",
                        accessorKey: "quantity",
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                        aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                        aggregationFn: "sum"
                    }
                ]
            }
        ],
        []
    );

    useEffect(() => {
        if (data) {
            const accountData_: AccountData[] = [];
            const externalAccountData_: ExternalAccountData[] = [];

            for (const accountPosition of data.accountPositions) {
                accountData_.push({
                    total: "Total",
                    clientId: accountPosition.clientId,
                    clientName: accountPosition.client.name,
                    instrumentId: accountPosition.instrumentId,
                    name: accountPosition.instrument.name,
                    accountId: accountPosition.accountId,
                    accountName: accountPosition.account.name,
                    quantity: accountPosition.quantity
                });
            }
            for (const externalAccountPosition of data.externalAccountPositions) {
                externalAccountData_.push({
                    total: "Total",
                    clientId: externalAccountPosition.clientId,
                    clientName: externalAccountPosition.client.name,
                    instrumentId: externalAccountPosition.instrumentId,
                    name: externalAccountPosition.instrument.name,
                    externalAccountId: externalAccountPosition.externalAccountId,
                    externalAccountName: externalAccountPosition.externalAccount.name,
                    quantity: externalAccountPosition.quantity
                });
            }

            setAccountData(accountData_);
            setExternalAccountData(externalAccountData_);
        }
    }, [data]);

    const accountHiddenColumns = ["clientId", "accountId", "externalAccountId", "instrumentId", "externalAccountName"];
    const externalAccountHiddenColumns = ["clientId", "accountId", "externalAccountId", "instrumentId", "accountName"];
    const accountGroupBy = ["total", "clientName", "accountName"];
    const externalAccountGroupBy = ["total", "clientName", "externalAccountName"];
    const expandedPositions = {};

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!queryArgs.instrumentIds) return <div>No instrument ids specified!</div>;

    return (
        <div className="container">
            <div>
                <div className="row">
                    <div className="col col-sm-3 col-lg-2">
                        <DateForm defaultDateString={date} dateName={"date"}></DateForm>
                    </div>
                </div>
                <div className="d-inline-flex flex-wrap mt-2">
                    <div className="me-3">
                        <h4>Account Positions</h4>
                        <TableGrouper
                            columns={columns}
                            data={accountData}
                            expanded={expandedPositions}
                            groupBy={accountGroupBy}
                            hiddenColumns={accountHiddenColumns}
                            compactMode={true}
                            captionColumn="name"
                        />
                    </div>
                </div>
                <div className="d-inline-flex flex-wrap mt-2">
                    <div className="me-3">
                        <h4>Custody Account Positions</h4>
                        <TableGrouper
                            columns={columns}
                            data={externalAccountData}
                            expanded={expandedPositions}
                            groupBy={externalAccountGroupBy}
                            hiddenColumns={externalAccountHiddenColumns}
                            compactMode={true}
                            captionColumn="name"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
