import React, { Fragment, ReactElement } from "react";
import { gql, useQuery } from "urql";
import { TimeSeries, AlignMethod } from "../../../../common/src";
import { TimeSeriePlot } from "./TimeSeriePlot";

type TimeSeriesOperationPlotPropsTypes = {
    params: any[];
    startDate?: string;
    endDate: string;
    rebase?: boolean;
    alignDateRange?: boolean;
    addTypeInName?: boolean;
    useRangeSelector?: boolean;
    customMargin?: Record<string, number>;
};

export const TimeSeriesOperationPlot = ({
    params,
    startDate,
    endDate,
    rebase = false,
    alignDateRange = false,
    addTypeInName = true,
    useRangeSelector = true,
    customMargin = {}
}: TimeSeriesOperationPlotPropsTypes): ReactElement => {
    const start = startDate ? ` startDate: "${startDate}",` : "";
    const query = gql`
        query timeseries {
            ${params
                .map(
                    (item, idx: number) => `
                    ts${idx}: timeserie(_id: "${item._id}") {
                            instrument {
                                _id
                                name
                                longName
                                exchanged: timeSeries(exchanged: ${item.exchangedCurrency}, fxHedged: ${item.fxHedged},${start} endDate: "${endDate}") {
                                    type
                                    values
                                    dates
                                }
                            }
                        }
                    `
                )
                .join("")}
        }
    `;

    //console.log(query.loc.source.body);

    const [{ data, fetching: loading, error }] = useQuery({
        query,
        requestPolicy: "cache-and-network"
    });

    if (loading) return <p>Loading time series</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    let tss: TimeSeries[] = [];
    const instrumentIds: string[] = [];

    params.forEach((value, index) => {
        const t = data[`ts${index}`];

        let ts = TimeSeries.fromJson(t.instrument.exchanged[0]);

        let name: string = t.instrument.longName;
        const tType: string = t.instrument.exchanged.type ? t.instrument.exchanged.type : "";
        name = addTypeInName ? name + " " + tType : name;
        const fxHedgedLbl = value.fxHedged ? " FX Hedged" : "";
        ts.name = `${name} (${value.exchangedCurrency}${fxHedgedLbl})`;

        if (rebase) {
            ts = ts.normalize(1);
        }

        tss.push(ts);
        instrumentIds.push(t.instrument._id);
    });

    if (alignDateRange) {
        let datesRange: string[] = tss[0].__dates.map((d) => d.toISOString());
        tss.forEach((ts) => {
            const dates = ts.__dates.map((d) => d.toISOString());
            datesRange = datesRange.filter((f) => dates.includes(f));
        });

        const masterTimeSerie = new TimeSeries(datesRange, [], "master");

        const alignedTss: TimeSeries[] = tss.map((ts) => {
            return TimeSeries.align(masterTimeSerie, ts, AlignMethod.Latest);
        });

        tss = alignedTss;
    }

    return (
        <Fragment>
            <TimeSeriePlot tss={tss} instrumentIds={instrumentIds} useRangeSelector={useRangeSelector} customMargin={customMargin} />
        </Fragment>
    );
};
