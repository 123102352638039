import React, { Fragment, useState } from "react";
import { gql, useQuery } from "urql";
import { sortBy } from "lodash";
import { Grid, Column } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";
import { serializeDate, daysFromTodayCount, yesterday } from "../dateFormater";
import { CashAccountLadder, TransactionStatus } from "../../types.generated";

const getCashLadder = gql`
    query getCashLadder($clientIds: [GraphQLObjectId!]!, $endDate: GraphQLDateString, $excludeStatus: [TransactionStatus!]) {
        cashLadder(clientIds: $clientIds, endDate: $endDate, statusNotIn: $excludeStatus) {
            instrumentId
            instrument {
                name
                currency
                productType
            }
            dates
            balances
        }
    }
`;

interface CashLadderItem {
    name: string;
    currency: string;
    type: string;
    balance: number;
    valueDate: string;
}

// Arrange data from raw data into format
const arrangeCashLadder = (data: CashAccountLadder[]) => {
    const balanceAllDates: CashLadderItem[][] = [];
    for (let i = 0; i < data.length; i++) {
        const balanceAccount: CashLadderItem[] = [];
        for (let j = 0; j < data[i].balances.length; j++) {
            balanceAccount.push({
                name: data[i].instrument.name,
                currency: data[i].instrument.currency,
                type: data[i].instrument.productType,
                balance: data[i].balances[j],
                valueDate: data[i].dates[j]
            });
        }
        balanceAllDates.push(balanceAccount);
    }
    return balanceAllDates;
};

// Rearranges data so dates becomes properties with value balance
const reArrangeData = (balances: CashLadderItem[][]) => {
    const sumBalances: any[] = []; // not sure how type this without rewriting all below code
    const dateList: string[] = [];
    balances.forEach((balance) => {
        const newItem = {};
        for (const prop in balance[0]) {
            if (Object.prototype.hasOwnProperty.call(balance[0], prop)) {
                if (!["valueDate", "balance"].includes(prop)) newItem[prop] = balance[0][prop];
            }
        }
        balance.forEach((balanceItem) => {
            const dateItem = serializeDate(balanceItem.valueDate);
            newItem[dateItem] = balanceItem.balance;
            if (!dateList.includes(dateItem)) dateList.push(dateItem);
        });
        sumBalances.push(newItem);
    });
    return [sumBalances, dateList];
};

// Sum across accounts on currency and only type CashAccount
const aggregateOnCurrency = (balances: CashLadderItem[][]) => {
    const cashAccountName = "CashAccount";
    const sumCashAccount = function (cashAccounts: CashLadderItem[], currency_sum: string, valueDate_sum: string, type_sum: string) {
        return cashAccounts
            .filter(({ currency }) => currency === currency_sum)
            .filter(({ valueDate }) => valueDate === valueDate_sum)
            .filter(({ type }) => type === type_sum)
            .reduce(function (prev, cur) {
                return prev + cur.balance;
            }, 0);
    };
    let balancesAggregated: CashLadderItem[] = [];
    balances.forEach((balance) => {
        balancesAggregated = balancesAggregated.concat(balance);
    });
    const allBalances: CashLadderItem[][] = [];
    const currenciesSummed: string[] = [];

    balances.forEach((balance: CashLadderItem[]) => {
        if (balance.length > 0) {
            //Only sum on bank accounts
            if (balance[0].type !== cashAccountName) return;
            // Check if already sum the currency
            if (currenciesSummed.includes(balance[0].currency)) return;
            const ccyBalance: CashLadderItem[] = [];
            balance.forEach((balanceItem) => {
                ccyBalance.push({
                    name: balanceItem.currency,
                    currency: balanceItem.currency,
                    balance: sumCashAccount(balancesAggregated, balanceItem.currency, balanceItem.valueDate, cashAccountName),
                    type: balanceItem.type,
                    valueDate: balanceItem.valueDate
                });
            });
            currenciesSummed.push(balance[0].currency);
            allBalances.push(ccyBalance);
        }
    });
    return allBalances;
};

// Re-align array to start from today and end X days in the future. Assumes input data has points on all calendar days
const alignDates = (balancesPerAccount: CashLadderItem[][], maxDaysIntoTheFuture: number) => {
    const alignedData: CashLadderItem[][] = [];
    for (let l = 0; l < balancesPerAccount.length; l++) {
        let inFuture = false;
        let countDaysFuture = 0;
        const alignedAccount: CashLadderItem[] = [];
        for (let k = 0; k < balancesPerAccount[l].length; k++) {
            if (balancesPerAccount[l][k].valueDate !== yesterday() && inFuture === false) {
                continue;
            } else if (balancesPerAccount[l][k].valueDate === yesterday()) {
                inFuture = true;
                continue;
            }
            // Skip weekends
            const weekday = serializeDate(balancesPerAccount[l][k].valueDate, "dddd");
            const isWeekend = weekday === "Sunday" || weekday === "Saturday";
            if (isWeekend === true) {
                continue;
            }
            alignedAccount.push({
                name: balancesPerAccount[l][k].name,
                currency: balancesPerAccount[l][k].currency,
                type: balancesPerAccount[l][k].type,
                valueDate: balancesPerAccount[l][k].valueDate,
                balance: balancesPerAccount[l][k].balance
            });
            countDaysFuture = countDaysFuture + 1;
            if (countDaysFuture > maxDaysIntoTheFuture + 1) break;
        }
        alignedData.push(alignedAccount);
    }
    return alignedData;
};

// Loop all balances and fill future dates if missing for up to X days. This means getting last available balance,
// ie today or earlier then fill the future balance if no events.
const fillFutureValueDates = (balances: CashLadderItem[][], daysToFillFuture: number) => {
    const newBalance: CashLadderItem[][] = balances;
    for (let j = 0; j < balances.length; j++) {
        const itemToMoveFwd = balances[j][balances[j].length - 1];
        const daysFromToday = daysFromTodayCount(itemToMoveFwd.valueDate);
        for (let z = 1; z < daysToFillFuture + daysFromToday; z++) {
            newBalance[j].push({
                name: itemToMoveFwd.name,
                currency: itemToMoveFwd.currency,
                type: itemToMoveFwd.type,
                balance: itemToMoveFwd.balance,
                valueDate: serializeDate(itemToMoveFwd.valueDate, "YYYY-MM-DD", z)
            });
        }
    }
    return newBalance;
};

// Loop alla balances and fill back in time up to todays date (-1) if only future dates exist
const fillPastValueDates = (balances: CashLadderItem[][]) => {
    const newBalance: CashLadderItem[][] = balances;
    for (let j = 0; j < balances.length; j++) {
        const firstItem: CashLadderItem = balances[j][0];
        const daysFromToday = daysFromTodayCount(firstItem.valueDate);

        // Check if first item is after todays date, then add zero balances back in time
        // Other functions need to know "yesterday's date" therefore one day back in time is needed.
        if (daysFromToday < 0) {
            for (let z = 1; z < -daysFromToday + 2; z++) {
                newBalance[j].unshift({
                    name: firstItem.name,
                    currency: firstItem.currency,
                    type: firstItem.type,
                    balance: 0,
                    valueDate: serializeDate(firstItem.valueDate, "YYYY-MM-DD", -z)
                });
            }
        } else if (daysFromToday === 0 && balances[j].length === 1) {
            const daysBack = 7;
            for (let z = 1; z < daysBack + 2; z++) {
                newBalance[j].unshift({
                    name: firstItem.name,
                    currency: firstItem.currency,
                    type: firstItem.type,
                    balance: 0,
                    valueDate: serializeDate(firstItem.valueDate, "YYYY-MM-DD", -z)
                });
            }
        }
    }
    return newBalance;
};

// Loop all balance items and fill blanks so balance exist on all days even though no transaction on that day
const fillBlankValueDates = (balances: CashLadderItem[][]) => {
    const balanceAllDates: CashLadderItem[][] = [];
    for (let j = 0; j < balances.length; j++) {
        const balanceAccount: CashLadderItem[] = [];
        for (let i = 0; i < balances[j].length; i++) {
            // First date is always the start
            if (i === 0) {
                balanceAccount.push({
                    name: balances[j][i].name,
                    currency: balances[j][i].currency,
                    type: balances[j][i].type,
                    balance: balances[j][i].balance,
                    valueDate: balances[j][i].valueDate
                });
                continue;
            } else {
                let expectedDate = serializeDate(balanceAccount[balanceAccount.length - 1].valueDate, "YYYY-MM-DD", 1);
                while (balances[j][i].valueDate !== expectedDate) {
                    balanceAccount.push({
                        name: balances[j][i].name,
                        currency: balances[j][i].currency,
                        type: balances[j][i].type,
                        balance: balanceAccount[balanceAccount.length - 1].balance,
                        valueDate: expectedDate
                    });
                    expectedDate = serializeDate(expectedDate, "YYYY-MM-DD", 1);
                }
                balanceAccount.push({
                    name: balances[j][i].name,
                    currency: balances[j][i].currency,
                    type: balances[j][i].type,
                    balance: balances[j][i].balance,
                    valueDate: balances[j][i].valueDate
                });
            }
        }
        balanceAllDates.push(balanceAccount);
    }
    return balanceAllDates;
};

export interface CashLadderProps {
    clientId: string;
    endDate?: string;
}

const Checkbox = (props) => <input type="checkbox" {...props} />;

export const CashLadder = ({ clientId, endDate }: CashLadderProps): React.ReactElement => {
    const [checkedExcludePending, setCheckedExcludePending] = useState(false);

    let excludeStatus: TransactionStatus[] = [];
    if (checkedExcludePending === true) {
        excludeStatus = [TransactionStatus.Pending, TransactionStatus.Preliminary, TransactionStatus.Deleted];
    }

    const [{ fetching, error, data }] = useQuery({
        query: getCashLadder,
        variables: { clientIds: [clientId], endDate, excludeStatus },
        requestPolicy: "network-only"
    });

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}</div>;
    if (data === "undefined" || typeof data === "undefined" || Object.entries(data).length === 0) {
        return null;
    }

    const handleCheckboxExcludePending = (e) => {
        setCheckedExcludePending(e.target.checked);
    };

    const maxFutureDayPoints = 6; // Make this argument in portal? Currently cannot create the columns based on this.
    const balanceValueDates = arrangeCashLadder(data.cashLadder);
    const balanceAllBlankDates = fillBlankValueDates(balanceValueDates);
    const balanceAllBlankDatesPastAdded = fillPastValueDates(balanceAllBlankDates);
    const daysToFillFuture = maxFutureDayPoints + 10; // Some extra days to compensate for weekends
    const balanceAllFutureDates = fillFutureValueDates(balanceAllBlankDatesPastAdded, daysToFillFuture);
    const alignedDataAccount = alignDates(balanceAllFutureDates, maxFutureDayPoints);
    const groupedCurrency = aggregateOnCurrency(alignedDataAccount);
    const arrangedDataAccount = reArrangeData(alignedDataAccount);
    const arrangedDataCurrency = reArrangeData(groupedCurrency);
    arrangedDataAccount[0] = sortBy(arrangedDataAccount[0], "name");
    arrangedDataCurrency[0] = sortBy(arrangedDataCurrency[0], "name");
    if (arrangedDataCurrency[0].length === 0) {
        arrangedDataCurrency[0] = [{ x: "" }];
        arrangedDataCurrency[1] = [];
        for (let i = 0; i < maxFutureDayPoints + 1; i++) {
            arrangedDataCurrency[1].push("x");
        }
        arrangedDataAccount[0] = [{ x: "" }];
        arrangedDataAccount[1] = [];
        for (let j = 0; j < maxFutureDayPoints + 1; j++) {
            arrangedDataAccount[1].push("x");
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col mb-2">
                    <label>
                        <Checkbox checked={checkedExcludePending} onChange={handleCheckboxExcludePending} />
                        <span> Exclude transactions in [pending, preliminary] </span>
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="Per currency (only cash accounts)" data={arrangedDataCurrency[0]} sortable tableClassName="table-xs">
                        <Column field="name" className="grid-column-sticky" />
                        <Column field={arrangedDataCurrency[1][0]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][1]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][2]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][3]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][4]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][5]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataCurrency[1][6]} format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="Per account" data={arrangedDataAccount[0]} sortable tableClassName="table-xs">
                        <Column field="name" className="grid-column-sticky" />
                        <Column field="type" />
                        <Column field="currency" />
                        <Column field={arrangedDataAccount[1][0]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][1]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][2]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][3]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][4]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][5]} format={numberFormatFun("# ##0")} />
                        <Column field={arrangedDataAccount[1][6]} format={numberFormatFun("# ##0")} />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};
