import React, { useContext } from "react";
import useAxios from "axios-hooks";
import { gql, useQuery } from "urql";
import queryString from "query-string";
import { keyBy } from "lodash";

import { DateHelper, emptyObjectId, TimeSeries } from "../../../common/src";
import { PeriodReturnsTable, TimeSeriesChart, KeyPerformanceIndicators, ComponentFactFundInfo } from "../../../components/src";
import { PartyType } from "../types.generated";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext } from "../contexts/ClientContext";
import { REACT_APP_API_URI } from "../env";

export const FundInfo = ({ isins }: { isins: string[] }): React.ReactElement => {
    const [{ data, loading, error }] = useAxios(REACT_APP_API_URI + "/public/api/fundinfo?" + queryString.stringify({ isins: isins }));

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const fundInfo = data[0];

    const date = fundInfo.navDate;

    const timeseries = [];
    const fundClass = fundInfo.classes.find((d) => d.shareClass === "C");
    const fundTimeSeries = new TimeSeries(fundClass.returnTimeSeries.dates, fundClass.returnTimeSeries.values, fundInfo.name);

    //add fund first
    timeseries.push(fundTimeSeries);

    for (let i = 0; i < fundInfo.benchmarks.length; i++) {
        const bm = fundInfo.benchmarks[i];
        let bmTs = new TimeSeries(bm.returnTimeSeries.dates, bm.returnTimeSeries.values, bm.name);
        bmTs = TimeSeries.align(fundTimeSeries, bmTs);
        if (bm.currency != bm.instrumentCurrency) {
            const fxName = bm.instrumentCurrency + bm.currency;
            const fxRate = fundInfo.fxRates.find((d) => d.name === fxName);
            const fxTs = new TimeSeries(fxRate.dates, fxRate.values, fxRate);
            const fxTsAligned = TimeSeries.align(fundTimeSeries, fxTs);
            bmTs = bmTs.mult(fxTsAligned);
        }
        timeseries.push(bmTs);
    }

    const ranges = ["1M", "3M", "6M", "1Y"];
    if (fundTimeSeries.resampleToMonthly().__values.length >= 36) {
        ranges.push("3Y");
    }

    const years = [];
    const year = new Date().getFullYear();
    for (let i = year; i >= year - 3; i--) {
        if (i >= DateHelper.getYear(fundTimeSeries.__dates[0])) {
            years.push(i);
        }
    }

    const allClasses = fundInfo.classes.slice();
    allClasses.sort((d1, d2) => d1.shareClass - d2.shareClass);

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-4 text-center">
                        <h4>Värdeutveckling</h4>
                        <div className="row">
                            <TimeSeriesChart timeseries={timeseries} />
                        </div>
                        <div className="row">
                            <h4>Avkastning (%)</h4>

                            <PeriodReturnsTable timeseries={timeseries} ranges={ranges} />
                            <PeriodReturnsTable timeseries={timeseries} ranges={years} />
                        </div>
                    </div>
                    <div className="col-4">
                        <header className="sideTitle">
                            <h4>Fakta om fonden</h4>
                        </header>

                        {fundInfo.classes.map((d, i) => {
                            const classes = allClasses.slice(i, i + 1);

                            return <ComponentFactFundInfo key={i} data={{ ...fundInfo, classes }} className="evenrowsgray fundclass" />;
                        })}
                    </div>
                </div>

                <div className="row"></div>
                <div className="col-12 value-performance-time-series text-center">
                    <h4>Nyckeltal - {date.toString()}</h4>
                    <KeyPerformanceIndicators
                        masterTimeSeriesName={fundTimeSeries.name}
                        timeseries={timeseries}
                        navValue={fundInfo.navValue}
                    />
                </div>
            </div>
        </div>
    );
};

const GET_FUNDS = gql`
    query {
        funds: parties(filter: { typeIn: [Fund] }) {
            _id
            name
            fundInfo {
                classes {
                    startNAVPerUnit
                    shareClass
                    instrument {
                        _id
                        name
                        isin
                    }
                }
            }
        }
    }
`;

export function FundInfoPage(): React.ReactElement {
    const { client: clientContext } = useContext(ClientContext);

    const [{ fetching, data, error }] = useQuery({ query: GET_FUNDS });

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const fundsByName = keyBy(data.funds, "name");

    if (
        clientContext &&
        clientContext._id !== emptyObjectId &&
        !Object.keys(fundsByName).includes(clientContext.dashName.replace(/-/g, " "))
    ) {
        return (
            <div className="loader">
                <h3>No fund with that name</h3>
            </div>
        );
    }
    const fund = clientContext && clientContext.dashName ? fundsByName[clientContext.dashName.replace(/-/g, " ")] : null;
    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} />
                </div>
            </div>
            {fund ? <FundInfo isins={fund.fundInfo.classes.map((c) => c.instrument.isin)}></FundInfo> : null}
        </div>
    );
}
