import React from "react";
import { useParams } from "react-router-dom";

import { CashLadder } from ".";
import { serializeDate } from "../dateFormater";

export const CashLadderView = (): React.ReactElement => {
    const { id }: any = useParams();
    const endDate = serializeDate(new Date(), "YYYY-MM-DD", 30);
    if (typeof id === "undefined" || id === "all") return <div>"Select a client"</div>;
    return (
        <div>
            <CashLadder clientId={id} endDate={endDate} />
        </div>
    );
};
