import React from "react";
import { gql, useQuery } from "urql";
import { Grid, Column } from "../../../../components/src";
import { InstrumentModelTypeEnum } from "../../types.generated";

import { numberFormatFun } from "../../../../common/src";

const getTradingmanagerCash = gql`
    query getTradingmanagerCash($clientIds: [GraphQLObjectId!], $endDate: GraphQLDateString) {
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: true) {
            name
            currency
            accountName
            amount
            modelType
            exposure
        }
    }
`;

const filterCashAccounts = (positions): any[] => {
    if (typeof positions === "undefined") {
        return positions;
    }

    const cashAccounts = [];
    for (let i = 0; i < positions.length; i++) {
        if (positions[i].modelType === InstrumentModelTypeEnum.Balance) {
            cashAccounts.push(positions[i]);
        }
    }
    return cashAccounts;
};

const groupCurrencyCashAccounts = (cashAccounts) => {
    const sumCashAccountAmount = function (cashAccounts, currency_sum) {
        return cashAccounts
            .filter(({ currency }) => currency === currency_sum)
            .reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
    };
    const sumCashAccountExposure = function (cashAccounts, currency_sum) {
        return cashAccounts
            .filter(({ currency }) => currency === currency_sum)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };
    const cashAccountsGrouped = [];
    for (let i = 0; i < cashAccounts.length; i++) {
        if (!cashAccountsGrouped.map((a) => a.name).includes(cashAccounts[i].currency)) {
            const newObject: any = {};
            newObject.name = cashAccounts[i].currency;
            newObject.amount = sumCashAccountAmount(cashAccounts, cashAccounts[i].currency);
            newObject.exposure = sumCashAccountExposure(cashAccounts, cashAccounts[i].currency);
            newObject.amountDiv1000 = newObject.amount / 1000;
            cashAccountsGrouped.push(newObject);
        }
    }
    return cashAccountsGrouped;
};

const calculate_cash_nav = (cashAccountsGrouped, positions) => {
    const cashAccountsNav = [];
    const nav = positions.reduce((accum, item) => accum + item.exposure, 0);
    const all_cash = cashAccountsGrouped.reduce((accum, item) => accum + item.exposure, 0);

    for (let i = 0; i < cashAccountsGrouped.length; i++) {
        const newObject: any = {};
        newObject.name = cashAccountsGrouped[i].name;
        newObject.value = (cashAccountsGrouped[i].exposure / nav) * 100;
        cashAccountsNav.push(newObject);
    }
    const newObject: any = {};
    newObject.name = "Tot cash";
    newObject.value = (all_cash / nav) * 100;
    cashAccountsNav.push(newObject);
    return cashAccountsNav;
};

export interface Props {
    clientIds: string[];
    endDate?: string;
}

export const CashAccount = ({ clientIds, endDate }: Props): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({
        query: getTradingmanagerCash,
        variables: { clientIds, endDate },
        requestPolicy: "network-only"
    });

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>Error! ${error.message}</div>;
    if (data === "undefined" || typeof data === "undefined" || Object.entries(data).length === 0) {
        return <div></div>;
    }
    const cashAccounts = filterCashAccounts(data.tradingmanager);
    const cashAccountsGrouped = groupCurrencyCashAccounts(cashAccounts);
    const cashPercentOfNav = calculate_cash_nav(cashAccountsGrouped, data.tradingmanager);

    return (
        <div className="row">
            <div className="col">
                <Grid header="Cash accounts" data={cashAccounts} sortable tableClassName="table-xs">
                    <Column field="accountName" title="Account" />
                    <Column field="name" title="Name" />
                    <Column field="amount" format={numberFormatFun("# ##0")} />
                    <Column field="currency" />
                </Grid>
            </div>
            <div className="col">
                <Grid header="% of nav" data={cashPercentOfNav} sortable tableClassName="table-xs">
                    <Column field="name" />
                    <Column field="value" title="% Nav" format={numberFormatFun("# ##0.##")} />
                </Grid>
            </div>
            <div className="col">
                <Grid header="Per currency" data={cashAccountsGrouped} sortable tableClassName="table-xs">
                    <Column field="name" />
                    <Column field="amount" format={numberFormatFun("# ##0")} />
                    <Column field="amountDiv1000" title="Div 1000" format={numberFormatFun("###0.000")} />
                </Grid>
            </div>
        </div>
    );
};
