import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { Editor } from "@monaco-editor/react";
import { SchemasSettings, configureMonacoYaml } from "monaco-yaml";
import YamlWorker from "./../../yaml.worker.js?worker";

type YamlFieldPropTypes = FieldHookConfig<string> & {
    // absolute or relative path is supported, eg. '/annualreport.schema.json' or "http://localhost:3000/annualreport.schema.json",
    schema?: string;
    width?: string;
    height?: string;
};

window.MonacoEnvironment = {
    getWorker(moduleId, label) {
        switch (label) {
            //case "editorWorkerService":
            //    return new Worker(new URL("monaco-editor/esm/vs/editor/editor.worker", import.meta.url));
            case "yaml":
                return new YamlWorker();
            default:
                throw new Error(`Unknown label ${label}`);
        }
    }
};

export function YamlField({ schema, width = "90vh", height = "90vh", ...props }: YamlFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const handleEditorDidMount = (_, monaco) => {
        const defaultSchema: SchemasSettings = {
            uri: schema,
            //schema,
            fileMatch: ["*"]
        };
        configureMonacoYaml(monaco, {
            enableSchemaRequest: true,
            schemas: [defaultSchema],
            validate: true
        });
    };

    const handleEditorValidation = (markers) => {
        markers.forEach((marker) => console.log("onValidate:", marker.message));
    };

    return (
        <div>
            <div id="editor" className={"form-group"}>
                <Editor
                    width={width}
                    height={height}
                    language="yaml"
                    theme="light"
                    //defaultValue={field.value || ""}
                    onMount={handleEditorDidMount}
                    onValidate={handleEditorValidation}
                    value={field.value || ""}
                    onChange={(value) => {
                        setFieldValue(field.name, value, true);
                    }}
                />
            </div>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}
