import React, { Fragment, ReactElement } from "react";
import { useNavigate, useParams, useLocation, createSearchParams } from "react-router-dom";

import { MiniTransactionForm, NewMiniTransactionButton } from "../MiniTransactionForm";
import { PortfolioManager } from "./PortfolioManager";
import { useQueryArgs } from "../../common/Utils";
import { miniTransactionTypes } from "../MiniTransactionForm";
import { Panel } from "@fluentui/react";

export function PortfolioManagerPage(): ReactElement {
    const params: any = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { queryArgs } = useQueryArgs();
    let id = null; //params.id;
    const clientId = params.id;

    if (queryArgs.id) {
        id = queryArgs.id;
    }

    let type = miniTransactionTypes[0];

    if (id && id.startsWith("new")) {
        type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));

        id = "new";
    }

    const handleDismiss = () => {
        const searchParams = new URLSearchParams(location.search);
        const p = {};
        for (const [key, value] of searchParams.entries()) {
            if (!["id", "parentId"].includes(key)) {
                p[key] = value;
            }
        }

        navigate({
            pathname: location.pathname,
            search: createSearchParams(p).toString()
        });
    };

    return (
        <Fragment>
            <div className="card card-body bg-light">
                <div className="row">
                    <div className="col-12 text-end">
                        <NewMiniTransactionButton page={location.pathname} queryArgMode={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PortfolioManager />
                    </div>
                </div>
                <Panel isOpen={id != null} onDismiss={handleDismiss} isBlocking={false} layerProps={{ eventBubblingEnabled: true }}>
                    {id ? (
                        <MiniTransactionForm
                            clientId={clientId}
                            id={id === "new" ? null : id}
                            type={id === "new" ? type : null}
                            parentId={queryArgs.parentId && type === "CloseDerivativeTrade" ? (queryArgs.parentId as string) : null}
                            queryArgMode={true}
                        />
                    ) : null}
                </Panel>
            </div>
        </Fragment>
    );
}
