import { groupBy, keyBy } from "lodash";
import { minimumPosition } from "../types";

import {
    Instrument,
    InstrumentProductTypeEnum,
    InstrumentCategory,
    TransactionType,
    TransactionStatus,
    TransactionItemType,
    Party,
    Transaction,
    TransactionItem
} from "../types.generated";

import { MockValuations, Valuations } from "./Valuation";
import { ProfitLoss, PortfolioLedgerPosition, RealizedType } from ".";

const { sign, abs } = Math;

export function isTradeType(type: TransactionType): boolean {
    return (
        type === TransactionType.Trade ||
        type === TransactionType.BondTrade ||
        //type === TransactionType.EquityTrade ||  // commented out by Andreas
        type === TransactionType.FundTrade ||
        type === TransactionType.StockTrade ||
        type === TransactionType.DerivativeTrade ||
        type === TransactionType.Insert
    );
}

export function isCollateralType(type: TransactionItemType): boolean {
    return (
        type === TransactionItemType.Collateral ||
        type === TransactionItemType.CollateralPledge ||
        type === TransactionItemType.CollateralPledgeInitialMargin ||
        type === TransactionItemType.SecurityLoan
    );
}

export function isFeeType(type: TransactionItemType): boolean {
    return (
        type === TransactionItemType.ManagementFee ||
        type === TransactionItemType.ManagementCost ||
        type === TransactionItemType.CustodyFee ||
        type === TransactionItemType.Rebate ||
        type === TransactionItemType.Fee ||
        type === TransactionItemType.Commission ||
        type === TransactionItemType.StampDuty ||
        type === TransactionItemType.ForeignTax ||
        type === TransactionItemType.Tax ||
        type === TransactionItemType.TaxRestitution
    );
}

export function isCreateRedeemType(type: TransactionItemType): boolean {
    return (
        type === TransactionItemType.CreateRedeemAdjustmentShares || type === TransactionItemType.CreateRedeem
        // type === TransactionItemType.CreateRedeemAdjustmentAmount
    );
}

export function isPositionBuildingType(type: TransactionItemType): boolean {
    return (
        type === TransactionItemType.CreateRedeemAdjustmentShares ||
        type === TransactionItemType.Collateral ||
        type === TransactionItemType.CreateRedeem ||
        type === TransactionItemType.CollateralPledge ||
        type === TransactionItemType.CollateralPledgeInitialMargin ||
        type === TransactionItemType.PayableReceivable ||
        type === TransactionItemType.SecurityLoan ||
        type === TransactionItemType.Security ||
        type === TransactionItemType.SettlementAmount
    );
}

export function sortTransactions(transactions: Transaction[]): Transaction[] {
    transactions.sort(function (d1, d2) {
        const c = d1.tradeTimestamp.getTime() - d2.tradeTimestamp.getTime();
        if (c !== 0) {
            return c;
        }
        return d1._id < d2._id ? -1 : 1;
    });
    return transactions;
}

export type TransactionItemDictionary = { [id: string]: TransactionItem[] };

/**
 * The function filters out deleted + historic transactions and transactions after end date. Then splits all transaction items and returns a
 * dictionary of key = instrumentId and value = array of items.
 * @param transactions Array of input transactions
 * @param endDate End (the last including) date
 */
export function groupTransactionItemsByInstrument(transactions: Transaction[], endDate: string): TransactionItemDictionary {
    const transactionItems = transactions.reduce((arr, t) => {
        for (let i = 0; i < t.items.length; i++) {
            if (t.status === TransactionStatus.Deleted || t.status === TransactionStatus.History || t.tradeDate > endDate) {
                continue;
            }
            const ti = t.items[i];
            ti.transaction = t;
            arr.push(ti);
        }
        return arr;
    }, []);
    return groupBy(transactionItems, (d) => d.instrumentId);
}

/**
 * InventoryItem is the value fields of a transaction item. It is used to pass data to the Inventory object.
 */
export class InventoryItem {
    constructor(amount = 0, quantity = 0, price = 0, fxRate = 0) {
        this.amount = amount;
        this.quantity = quantity;
        this.price = price;
        this.fxRate = fxRate;
    }

    static clone(item: InventoryItem = null): InventoryItem {
        return new InventoryItem(item ? item.amount : 0, item ? item.quantity : 0, item ? item.price : 0, item ? item.fxRate : 0);
    }
    amount: number;
    quantity: number;
    price: number;
    fxRate: number;
}

export function getValuationInstrumentIds(
    itemsByInstrument: TransactionItemDictionary,
    fxPairInstruments: Instrument[],
    startDate: string,
    endDate: string
): { sequrityInstrumentIds: string[]; fxPairInstrumentIds: string[] } {
    const valuations = MockValuations.fromArray(null, null);
    const ids = Object.keys(itemsByInstrument);
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        PortfolioLedgerPosition.calcPosition(id, itemsByInstrument[id], valuations, startDate, endDate, "SEK");
    }
    const fxPairInstrumentsByName = keyBy(fxPairInstruments, "name");
    const askedFxPairNames = Object.keys(valuations.askedFxPairNames);
    const sequrityInstrumentIds = Object.keys(valuations.askedInstrumentIds);
    const fxPairInstrumentIds = askedFxPairNames.map((d) => fxPairInstrumentsByName[d]._id);
    return { sequrityInstrumentIds, fxPairInstrumentIds };
}

export function calcPositions(
    itemsByInstrument: TransactionItemDictionary,
    valdb: Valuations,
    startDate: string,
    endDate: string,
    currency: string
): PortfolioLedgerPosition[] {
    const ids = Object.keys(itemsByInstrument);
    const res: PortfolioLedgerPosition[] = [];
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const items = itemsByInstrument[id];
        const pos = PortfolioLedgerPosition.calcPosition(id, items, valdb, startDate, endDate, currency);
        res.push(pos);
    }
    return res;
}

export interface PositionGridViewItem {
    id: string;
    name: string;
    longName: string;
    cashAccount: boolean;
    currency: string;
    quantity: number;
    endValue: number;
    startValue: number;
    cashFlow: number;
    foProfitLoss: number;
    realizedProfitLoss: number;
    endUnrealizedProfitLoss: number;
    startUnrealizedProfitLoss: number;
    accrued: number;
    income: number;
    boProfitLoss: number;
    lastTradeTimestamp: Date;
    productType: InstrumentProductTypeEnum;
    category: InstrumentCategory;
}

export function toPositionGridViewItem(
    position: PortfolioLedgerPosition,
    party: Party,
    instrumentsById: { [id: string]: Instrument }
): PositionGridViewItem {
    let instr = instrumentsById[position.instrumentId];
    if (!instr) {
        const tmp = party.instruments.find((d) => d._id === position.instrumentId);
        instr = tmp as unknown as Instrument;
    }
    if (!instr) {
        console.log("No instrument found, id =", position.instrumentId);
    }
    const res = {
        id: position.instrumentId,
        name: instr ? instr.name : null,
        longName: instr ? instr.longName : null,
        cashAccount: position.cashAccount,
        quantity: position.inventory.position,
        currency: position.currency,
        endValue: position.endMarketValue,
        startValue: position.startMarketValue,
        cashFlow: position.cashFlow,
        foProfitLoss: position.endMarketValue - position.startMarketValue - position.cashFlow,
        realizedProfitLoss: position.realizedProfitLoss.total,
        endUnrealizedProfitLoss: position.endUnrealizedProfitLoss.total,
        startUnrealizedProfitLoss: position.startUnrealizedProfitLoss.total,
        income: position.income.total,
        accrued: position.endAccrued - position.startAccrued,
        boProfitLoss: position.totalProfitLoss.total + position.endAccrued - position.startAccrued,
        lastTradeTimestamp: position.lastTradeTimestamp,
        productType: instr ? instr.productType : null,
        category: instr ? instr.category : null
    };
    return res;
}

export function isCashAccountType(type: TransactionItemType): boolean {
    return type === TransactionItemType.SettlementAmount || isFeeType(type) || type === TransactionItemType.Interest;
}

export function isRealizationType(type: TransactionItemType): boolean {
    return !(
        type === TransactionItemType.Dividend ||
        type === TransactionItemType.DividendPaid ||
        type === TransactionItemType.Interest ||
        type === TransactionItemType.AccruedInterest ||
        //type === TransactionItemType.Unknown ||
        isFeeType(type) ||
        isCollateralType(type)
    );
}

export function addDays(date: string, n: number): string {
    return new Date(new Date(date).getTime() + 86400000 * n).toJSON().slice(0, 10);
}

export function calcRealizedType(item: TransactionItem, qty: number, startDate: string): RealizedType {
    if (
        item.type === TransactionItemType.Dividend ||
        item.type === TransactionItemType.DividendPaid ||
        item.type === TransactionItemType.Interest ||
        item.type === TransactionItemType.AccruedInterest ||
        isFeeType(item.type)
    ) {
        return item.transaction.tradeDate > startDate ? RealizedType.IncomeCost : RealizedType.NoIncomeCost;
    } else if (item.type === TransactionItemType.SettlementAmount) {
        if (qty !== 0.0) {
            return item.transaction.tradeDate > startDate ? RealizedType.RealizedProfitLoss : RealizedType.NoIncomeCost;
        } else {
            return RealizedType.SettlementAmount;
        }
    } else if (isCreateRedeemType(item.type)) {
        return RealizedType.None;
    } else if (isTradeType(item.transaction.type) && !isCollateralType(item.type)) {
        return item.transaction.tradeDate > startDate ? RealizedType.RealizedProfitLoss : RealizedType.NoIncomeCost;
    } else if (item.type === TransactionItemType.Security) {
        return RealizedType.NoIncomeCost;
    }
    return RealizedType.None;
}

export interface CalcPointValuation {
    accruedInterest: number;
    fxRate: number;
    price: number;
}

export interface CalcPoint {
    realizedProfitLoss: ProfitLoss;
    inventory: Inventory;
    income: InventoryItem;
    inventoryChange: InventoryItem;
    ignoreLedger: boolean;
    transactionItem: TransactionItem;
    date: string;
    valuation: CalcPointValuation;
    realizedType: RealizedType;
    cashFlow: number;
}

export class Inventory {
    constructor(position = 0, initialCost = 0, sumAmount = 0) {
        this.position = position;
        this.initialCost = initialCost;
        this.sumAmount = sumAmount;
        this.items = [];
    }
    position: number;
    initialCost: number;
    sumAmount: number;
    items: InventoryItem[];
    static clone(inv: Inventory): Inventory {
        const res = new Inventory();
        if (inv) {
            inv.items.forEach((d) => res.add(InventoryItem.clone(d)));
        }
        return res;
    }
    add(itm: InventoryItem): void {
        this.position += itm.quantity;
        this.initialCost += itm.amount * itm.fxRate;
        this.sumAmount += itm.amount;
        this.items.push(itm);
        return;
    }
    get initialPrice(): number {
        return this.position === 0 ? 0 : this.sumAmount / this.position;
    }
    get initialFxRate(): number {
        return this.sumAmount === 0 ? null : this.initialCost / this.sumAmount;
    }
}

export interface RealizationResult {
    inventory: Inventory;
    // inventoryItem: InventoryItem;
    realizedProfitLoss: ProfitLoss;
}

export function calcRealization(inventory: Inventory, itm: InventoryItem): RealizationResult {
    const res: RealizationResult = { inventory: null, realizedProfitLoss: null };
    // res.inventoryItem = InventoryItem.clone(itm);
    res.inventory = Inventory.clone(inventory);
    res.realizedProfitLoss = new ProfitLoss();
    const pos = res.inventory.position;
    const rq = itm.quantity;
    if (pos === 0 || rq === 0 || sign(rq) === sign(pos)) {
        res.inventory.add(itm);
    } else {
        if (sign(rq) !== sign(pos)) {
            if (abs(rq) >= abs(pos)) {
                const prc = itm.amount / rq;
                res.realizedProfitLoss = new ProfitLoss(res.inventory, -pos, prc, itm.fxRate, false);
                res.inventory = new Inventory();
                if (abs(rq - pos) > minimumPosition) {
                    // earlier: rq !== pos
                    const qty = pos + rq;
                    const amt = qty * prc;
                    res.inventory.add(new InventoryItem(amt, qty, prc, itm.fxRate));
                }
            } else {
                res.realizedProfitLoss = new ProfitLoss(res.inventory, rq, itm.amount / rq, itm.fxRate, false);
                const iprc = res.inventory.initialPrice;
                const ifxr = res.inventory.initialFxRate !== null ? res.inventory.initialFxRate : 0;
                res.inventory = new Inventory();
                const qty = pos + rq;
                const amt = qty * iprc;
                res.inventory.add(new InventoryItem(amt, qty, iprc, ifxr));
            }
        }
    }
    return res;
}

export interface AccountingParameters {
    accountingCurrency: string;
    startDate: string;
    endDate: string;
    roundingDecimals: number;
    accountingPeriod: string;
    addClosingBalanceValuations: boolean;
}
