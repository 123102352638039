import React, { Fragment, useContext, ReactElement } from "react";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";
import { isChrome, isSafari, isMobile, browserName } from "react-device-detect";
import { gql, useQuery } from "urql";
import useAxios from "axios-hooks";
import axios from "axios";

import { Svgs } from "../../../components/src";
import { userHaveAccessTo } from "../common/Permissions";
import { loginContext, getDefaultLoginValue } from "./Login";
import { PermissionTypeEnum, PermissionAssetEnum } from "../types.generated";
import {
    REACT_APP_DB_NAME,
    REACT_APP_API_URI,
    REACT_APP_AUTH_URI,
    REACT_APP_NODEJOBAPI_URI,
    REACT_APP_GIT_HASH,
    VITE_DATE,
    REACT_APP_PYJOBAPI_URI
} from "../env";

interface IMenuLinkPropTypes {
    value: {
        title: string;
        to: string;
        absolute: boolean;
        subMenu: {
            title: string;
            to: string;
            absolute: boolean;
        }[];
    };
    className?: string;
    onClose: any;
}

class MenuLink extends React.Component<IMenuLinkPropTypes> {
    subNavToggle(event) {
        const n = event.currentTarget;
        const cont = n.nextSibling.nextSibling;
        let h = cont.clientHeight;
        if (h === 0) {
            cont.childNodes.forEach((d) => {
                h += d.clientHeight;
            });
            h += 12;
        }
        n.classList.toggle("show");
        cont.classList.toggle("show");
        if (cont.classList.contains("show")) {
            cont.style.cssText = `max-height: ${h}px; overflow: hidden; transition: max-height 0.25s ease-in-out`;
        } else {
            cont.style.cssText = `max-height: ${h}px; overflow: hidden`;
            window.setTimeout(() => {
                cont.style.cssText = "max-height: 0px; overflow: hidden; transition: max-height 0.25s ease-in-out";
            }, 10);
        }
    }

    subMenuTransitionEnd(event) {
        const n = event.target;
        if (n.classList.contains("show")) {
            n.style = null;
        }
    }

    render() {
        let { className } = this.props;
        const { value, onClose } = this.props;
        if (!className) {
            className = "menu-item";
        }
        return (
            <li className={className}>
                {value.subMenu ? (
                    <div>
                        {/*REPLACE THIS SPAN BELOW WITH FONT AWESOME */}
                        <span className="subNavToggle cursor__pointer" onClick={(e) => this.subNavToggle(e)} />

                        {value.absolute ? (
                            <a tabIndex={-1} href={value.to} rel="noopener noreferrer">
                                {value.title}
                            </a>
                        ) : (
                            <Link tabIndex={-1} to={value.to} onClick={(e) => onClose(e)}>
                                {value.title}
                            </Link>
                        )}
                        <ul
                            className="sub-menu"
                            onTransitionEnd={(e) => this.subMenuTransitionEnd(e)}
                            style={{
                                maxHeight: "0",
                                overflow: "hidden"
                            }}
                        >
                            {value.subMenu.map((d, i) => {
                                if (d.absolute) {
                                    return (
                                        <li key={i} id={i.toString()} className="sub-menu-item">
                                            <a tabIndex={-1} href={d.to} rel="noopener noreferrer">
                                                {d.title}
                                            </a>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={i} id={i.toString()} className="sub-menu-item">
                                            <Link tabIndex={-1} to={d.to} onClick={(e) => onClose(e)}>
                                                {d.title}
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                ) : (
                    <Fragment>
                        {value.absolute ? (
                            <a tabIndex={-1} href={value.to} rel="noopener noreferrer">
                                {value.title}
                            </a>
                        ) : (
                            <Link tabIndex={-1} to={value.to} onClick={(e) => onClose(e)}>
                                {value.title}
                            </Link>
                        )}
                    </Fragment>
                )}
            </li>
        );
    }
}

export const getMe = gql`
    query {
        me {
            _id
            name
            noteIds
            termsOfService {
                _id
                data
            }
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
            frontendRole {
                assets
            }
        }

        funds: parties(filter: { typeIn: [Fund], withTransactions: true, withEnabledFundClasses: true }) {
            _id
            name
            longName
            types
        }

        clients: parties(filter: { typeIn: [Client], withTransactions: true }) {
            _id
            name
            longName
            types
        }
    }
`;

export const SideNav = (): ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getMe });
    const { login, setLogin } = useContext(loginContext);
    const [__, request] = useAxios({ withCredentials: true }, { manual: true });
    if (fetching) return <div></div>;
    if (error) return <div>{error.toString()}</div>;

    let menu = [];

    let clients = sortBy(
        data.funds
            .concat(data.clients)
            .filter((client, index, allClients) => allClients.findIndex((client2) => client2._id === client._id) === index),
        "name"
    );

    // this is an ugly hack not to show counter parties with swaps. remove when https://github.com/CaptorAB/nodejs/issues/1045 is fixed
    clients = clients.filter((item) => !["SHB", "DANSKE"].includes(item.name));

    const frontendAssets: PermissionAssetEnum[] = data.me.frontendRole.assets;

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Performance)) {
        menu.push({
            title: "Performance",
            to: "/performance"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Position)) {
        const positionsMenu: any = {};
        positionsMenu.title = "Positions";
        positionsMenu.absolute = true;
        positionsMenu.subMenu = [];
        clients.forEach((client) => {
            positionsMenu.subMenu.push({ title: client.name, to: "/positions/" + client.name.trim().replace(/ /g, "-") });
        });
        menu.push(positionsMenu);
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Transaction)) {
        menu.push({
            title: "Toolbox",
            to: "/transactions",
            subMenu: [
                { title: "Deal Blotter", to: "/dealblotter" },
                { title: "Transactions", to: "/transactions" },
                { title: "Transaction Items", to: "/transactionitems" },
                { title: "Valuations", to: "/valuations" }
            ]
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Accounting)) {
        const subMenu = [
            { title: "Accounting PM", to: "/accountingpm" },
            { title: "T-account charts", to: "/taccountcharts" }
        ];

        if (
            frontendAssets.length === 0 ||
            (frontendAssets.includes(PermissionAssetEnum.Position) && frontendAssets.includes(PermissionAssetEnum.Transaction))
        ) {
            subMenu.push({
                title: "Financial statements",
                to: "/financialstatements"
            });
        }

        menu.push({
            title: "Accounting",
            to: "/accountingpm",
            subMenu: subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.BackOffice)) {
        menu.push({
            title: "FI reporting",
            absolute: true,
            subMenu: [
                { title: "Annual/semiannual reporting", to: "/reporting/annualreports" },
                { title: "Bond classification", to: "/reporting/classification" },
                { title: "Quarterly reporting", to: "/reporting/quarterly" }
            ]
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Instrument)) {
        const subMenu = [{ title: "Instruments", to: "/instruments" }];

        if (userHaveAccessTo("Any", PermissionAssetEnum.Instrument, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New Instrument", to: "/instruments/new" });
            subMenu.push({ title: "Valuation Mappings", to: "/valuationmappings" });
        }

        subMenu.push({ title: "Issuerprograms", to: "/issuerprograms" });
        if (userHaveAccessTo("Any", PermissionAssetEnum.Instrument, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New issuerprogram", to: "/issuerprograms/new" });
        }

        menu.push({
            title: "Instruments",
            to: "/instruments",
            subMenu: subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Party)) {
        const subMenu = [{ title: "Parties", to: "/parties" }];
        if (userHaveAccessTo("Any", PermissionAssetEnum.Party, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New Party", to: "/parties/new" });
        }
        menu.push({
            title: "Parties",
            to: "/parties",
            subMenu: subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Nav)) {
        const tradeDate = new Date().toISOString().slice(0, 10);
        let subMenu = [
            { title: "TransferAgent Orders", to: "/transferagentorders?tradeDate=" + tradeDate },
            { title: "Order Summary", to: "/transferagentorders/summary" }
        ];
        subMenu = [
            ...subMenu,
            ...data.funds.map((fund) => {
                return { title: fund.name, to: "/calculatenav/" + fund.name.trim().replace(/ /g, "-") };
            })
        ];

        subMenu.push({ title: "navs", to: "/navs" });

        menu.push({
            title: "NAV",
            to: "/navs",
            subMenu: subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Report)) {
        menu.push({
            title: "Reports",
            to: "/reports"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.TimeSeries)) {
        menu.push({
            title: "Timeseries",
            to: "/timeseries"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.PortfolioManager)) {
        menu.push({
            title: "Portfolio Manager",
            to: "/pm"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Limit)) {
        menu.push({
            title: "Limit reports",
            to: "/limitreportv3"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Job)) {
        menu.push({
            title: "JOBS",
            to: "/jobs"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Reconciliation)) {
        menu.push({
            title: "Position reconciliation",
            to: "/positionreconciliation"
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Collateral)) {
        menu.push({ title: "Collateral Management", to: "/reconcile/collateral" });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.BackOffice)) {
        let subMenu = [
            { title: "AUM", to: "/aum" },
            { title: "Agreements", to: "/agreements" },
            { title: "Bank Accounts Reconciliation", to: "/reconcile/bankaccounts" },
            { title: "Bank Accounts Transactions", to: "/bankaccounttransactions" },
            { title: "Broker Transactions", to: "/reconcile/brokertransactions" },
            { title: "Cash balance", to: "/reconcile/cashbalance" },
            { title: "Corporate Actions", to: "/reconcile/corporateactions" },
            { title: "Fund Units", to: "/reconcile/fundunits" },
            { title: "Notes", to: "/notes" },
            { title: "Notes ESG", to: "/esgnotes" },
            { title: "Portfolio Grouper", to: "/grouper" },
            { title: "Units Reconciliation", to: "/reconciliation" },
            { title: "Valuation Mappings", to: "/valuationmappings" },
            { title: "Swaplab2 compare", to: "/swaplab2/compare" },
            { title: "Custodian Positions", to: "/reconciliation/custodianpositions" },
            { title: "share register", to: "/shareregister" },
            { title: "prices", to: "/prices" },
            { title: "quotes", to: "/quotes" },
            { title: "Customers", to: "/backoffice/customers" },
            { title: "Calendars", to: "/calendars" },
            { title: "Costs", to: "/backoffice/costs" },
            { title: "Costs - Monthly", to: "/monthlycosts" },
            { title: "Versions", to: "/versions" }
        ];
        subMenu = sortBy(subMenu, "title");

        menu.push({
            title: "Back office",
            to: "/backoffice",
            subMenu: subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Playground)) {
        if (login.token) {
            menu.push({
                title: "GraphQL",
                absolute: true,
                to: REACT_APP_API_URI + "/graphql"
            });
        }
    }

    if (userHaveAccessTo("Any", PermissionAssetEnum.Transaction, data.me.roles, PermissionTypeEnum.ReadWrite)) {
        menu.push({
            title: "Change Password",
            to: "/changepassword"
        });
    }

    if (userHaveAccessTo("Any", PermissionAssetEnum.Admin, data.me.roles, PermissionTypeEnum.Read)) {
        const subMenu = [
            { title: "Users", to: "/admin/users" },
            { title: "Clean cache", to: "/admin/cleancache" }
        ];

        if (userHaveAccessTo("Any", PermissionAssetEnum.Admin, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New User", to: "/admin/users/new" });
        }
        subMenu.push({ title: "Roles", to: "/admin/roles" });

        if (userHaveAccessTo("Any", PermissionAssetEnum.Admin, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New Role", to: "/admin/roles/new" });
        }

        menu.push({
            title: "Admin",
            absolute: true,
            subMenu
        });
    }

    if (frontendAssets.length === 0 || frontendAssets.includes(PermissionAssetEnum.Esg)) {
        const subMenu = [{ title: "Screens", to: "/screens" }];

        if (userHaveAccessTo("Any", PermissionAssetEnum.Esg, data.me.roles, PermissionTypeEnum.ReadWrite)) {
            subMenu.push({ title: "New screen", to: "/screens/new" });
        }
        subMenu.push({ title: "EsgDataProviders", to: "/parties?types=EsgDataProvider" });

        if (userHaveAccessTo("Any", PermissionAssetEnum.Esg, data.me.roles, PermissionTypeEnum.Read)) {
            subMenu.push({ title: "Fund data", to: "/esg" });
        }

        menu.push({
            title: "ESG",
            absolute: true,
            subMenu
        });
    }

    if (login.loggedIn === false) {
        return null;
    }

    menu = sortBy(menu, "title");
    menu = [].concat(...[{ title: "Home", to: "/" }], ...menu);

    return (
        <aside id="sideNav" className="sidenav">
            <div className="navToggleWrap">
                <button
                    className="navToggle"
                    onClick={() => {
                        const sel = document.querySelector("aside#sideNav");
                        if (sel) {
                            sel.classList.remove("show");
                        }
                    }}
                >
                    <span />
                    <span />
                </button>
                <p>Welcome</p>
                <p>{data && data.me ? data.me.name : ""}</p>
            </div>
            <nav id="mainNav" className="menu-main-nav-container">
                <ul id="menu-main-nav" className="menu">
                    {menu.map((d, i) => (
                        <MenuLink
                            key={i}
                            value={d}
                            onClose={() => {
                                const sel = document.querySelector("aside#sideNav");
                                if (sel) {
                                    sel.classList.remove("show");
                                }
                            }}
                        />
                    ))}
                </ul>
            </nav>
            <div>
                <Link
                    className="loginLink"
                    to="/"
                    onClick={async () => {
                        const _response = await request({
                            method: "DELETE",
                            url: REACT_APP_AUTH_URI + "/set",
                            headers: {
                                Accept: "application/json; charset=utf-8",
                                "Content-Type": "application/json",
                                authorization: `Bearer ${login.token}` // this should not be needed
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                                //console.log("unset cookies done1");
                            });
                        if (REACT_APP_DB_NAME === "prod") {
                            axios
                                .delete(REACT_APP_NODEJOBAPI_URI + "/set", {
                                    withCredentials: true,
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${login.token}` // this should not be needed
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                            axios
                                .post(
                                    REACT_APP_PYJOBAPI_URI + "/unset",
                                    {},
                                    {
                                        withCredentials: true,
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bearer ${login.token}` // this should not be needed
                                        }
                                    }
                                )
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                        //console.log(_response);
                        setLogin(getDefaultLoginValue());
                    }}
                >
                    <Svgs.SignOut />
                    Logout
                </Link>
            </div>
            <div className="mt-4 mb-1">
                <Link className="link" to="/termsofservice" style={{ color: "#dfdfdf" }}>
                    Terms of service
                </Link>
            </div>
            <div>
                {!isChrome || (!isMobile && isSafari) ? (
                    <div className="d-flex flex-column justify-content-center align-items-center p-4">
                        <div className="text-white mt-1">This site is optimized for Chrome. You may have issues with other browsers</div>
                    </div>
                ) : null}
            </div>
            <div>
                {browserName === "Edge" ? (
                    <div className="d-flex flex-column justify-content-center align-items-center p-4">
                        <div className="text-white mt-1">We noticed 5-10 times slower response in Edge compared to Chrome browser.</div>
                    </div>
                ) : null}
            </div>
            <div>
                <div>
                    <br />
                    <div style={{ color: "#dfdfdf" }}>Version: {REACT_APP_GIT_HASH}</div>
                    <div style={{ color: "#dfdfdf" }}>Date: {VITE_DATE}</div>
                    {REACT_APP_DB_NAME !== "prod" ? <div style={{ color: "#dfdfdf" }}>db: {REACT_APP_DB_NAME}</div> : null}
                    <br />
                </div>
            </div>
        </aside>
    );
};
