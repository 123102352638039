import React, { useState, useEffect, useContext } from "react";
import { keyBy, cloneDeep, groupBy } from "lodash";
import { Tabs, Tab } from "react-bootstrap";
import { gql, useMutation, useQuery } from "urql";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Panel } from "@fluentui/react";

import { Svgs } from "../../../components/src";
import { emptyObjectId } from "../../../common/src";

import { useInterval, usePrevious, useQueryArgs } from "../common/Utils";
import { DateForm } from "../common/dateForm";
import { Page } from "../components/Page";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "./MiniTransactionForm";
import { ITransactionDataRow } from "../components/TransactionsGrid";
import { useQueryState } from "../common/use-query-state";
import { serializeSwedenDate } from "../components/dateFormater";
import {
    BankAccountBalance,
    BankAccountTransaction,
    BankAccountTransactionInput,
    BankAccountTransactionStatusEnum,
    CashAccountBalance,
    CashBalance,
    InstrumentProductTypeEnum,
    MatchBankAccountTransactionsInput,
    PartyType,
    Position,
    Transaction,
    TransactionStatus,
    UpdateTransactionInput
} from "../types.generated";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext, IClient } from "../contexts/ClientContext";
import { BankAccountReconciliationGrid } from "./BankAccountsReconciliationGrid";

const GET_CASH_BALANCE = gql`
    query cashBalances($instrumentIds: [GraphQLObjectId!]!, $viewFromDate: GraphQLDateString) {
        cashBalances(instrumentIds: $instrumentIds, viewFromDate: $viewFromDate) {
            instrumentId
            instrument {
                name
                currency
                productType
            }
            balanceItems {
                transactionId
                transactionItemId
                transaction {
                    _id
                    type
                    items {
                        _id
                        type
                        instrumentId
                        valueDate
                    }
                    status
                    updateUserInfo {
                        name
                    }
                    updateTimestamp
                }
                transactionItem {
                    error
                }
                tradeDate
                valueDate
                amount
            }
        }
    }
`;

const GET_BANK_ACCOUNT_TRANSACTIONS = gql`
    query bankAccountTransactions($filter: BankAccountTransactionFilterInput) {
        bankAccountTransactions(filter: $filter) {
            _id
            clientId
            client {
                name
            }
            instrumentId
            instrument {
                _id
                name
                bic
                iban
                currency
                productType
            }
            date
            error
            externalId
            amount
            status
            reference
            description
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

const GET_SETTLED_POSITION_BALANCE = gql`
    query {
        positions(filter: { statusIn: [Settled] }, groupPositionsBy: ClientId) {
            amount
            instrumentId
        }
    }
`;

const GET_SETTLED_BANK_BALANCE = gql`
    query bankAccountBalances($filter: BankAccountBalanceFilterInput) {
        bankAccountBalances(filter: $filter) {
            balance
            instrumentId
        }
    }
`;

const GET_CLIENTS = gql`
    query {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
            instruments {
                name
                _id
            }
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

const UPSERT_BANK_ACCOUNT_TRANSACTIONS = gql`
    mutation upsertBankAccountTransactions($input: [BankAccountTransactionInput!]!) {
        upsertBankAccountTransactions(input: $input) {
            _id
        }
    }
`;

const MATCH_BANK_ACCOUNT_TRANSACTIONS = gql`
    mutation manuallyMatchBankAccountTransactions($input: [MatchBankAccountTransactionsInput!]!) {
        manuallyMatchBankAccountTransactions(input: $input) {
            transactionItemIds
            bankAccountTransactionIds
        }
    }
`;

const defaultViewFromDateString = () => {
    return serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 30)));
};

const transactionGroups = ["Not settled - future", "Not settled", "Settled"];

const groupByStatus = (transactions: BankAccountTransaction[] | CashBalance[], transactionGroups: string[]) => {
    const result: Record<string, BankAccountTransaction[] | CashBalance[]> = {};
    transactionGroups.forEach((status) => {
        result[status] = [];
    });

    if (transactions.length) {
        transactions.forEach((item) => {
            if (
                (item.transaction && item.transaction.status === TransactionStatus.Settled) ||
                item.status === BankAccountTransactionStatusEnum.Settled
            ) {
                item.statusGroup = item.transaction ? item.transaction.status : item.status;
            } else {
                const today = new Date().toISOString().slice(0, 10);
                if (item.date > today || item.valueDate > today) {
                    item.statusGroup = "Not settled - future";
                } else {
                    item.statusGroup = "Not settled";
                }
            }
            if (transactionGroups.includes(item.statusGroup)) {
                result[item.statusGroup].push(item);
            }
        });
    }

    return result;
};

export const BankAccountReconciliationPage = (): React.ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { queryArgs } = useQueryArgs();
    const { client } = useContext(ClientContext);
    const previousClient: IClient = usePrevious(client);
    const [viewFromDate] = useQueryState("viewFromDate", defaultViewFromDateString());
    const [tab, setTab] = useState(null);
    const [instrumentIds, setInstrumentIds] = useState([emptyObjectId]);
    const [spinner, setSpinner] = useState(false);
    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: GET_CLIENTS,
        requestPolicy: "cache-and-network"
    });

    const [{ fetching: loadingUpdateTransactionsStatus, error: errorUpdate }, updateTransactionsStatus] = useMutation(UPDATE_TRANSACTIONS);
    const [{ error: errorUpsertBankAccountTransactions }, updateBankAccountTransactionsStatus] =
        useMutation(UPSERT_BANK_ACCOUNT_TRANSACTIONS);
    const [{ fetching: loadingMatchBankAccountTransactions, error: errorMatchBankAccountTransactions }, matchBankAccountTransactions] =
        useMutation(MATCH_BANK_ACCOUNT_TRANSACTIONS);

    const params: any = useParams();
    let id = params.id;

    let type = miniTransactionTypes[0];

    if (id && id.startsWith("new")) {
        type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
        id = "new";
    }

    const [{ fetching: loadingCashBalance, error: errorCashBalance, data: dataCashBalances }, refetch] = useQuery({
        query: GET_CASH_BALANCE,
        variables: { viewFromDate: viewFromDate || defaultViewFromDateString(), instrumentIds },
        requestPolicy: "cache-and-network",
        pause: false
    });

    const [{ fetching: loadingPositionBalance, error: errorPositionBalance, data: dataPositionBalances }, refetchPositionBalances] =
        useQuery({
            query: GET_SETTLED_POSITION_BALANCE,
            variables: {},
            requestPolicy: "cache-and-network",
            pause: false
        });

    const [
        { fetching: loadingBankAccountTransactions, error: errorBankAccountTransactions, data: dataBankAccountTransactions },
        refetchBankAccountTransactions
    ] = useQuery({
        query: GET_BANK_ACCOUNT_TRANSACTIONS,
        variables: {
            filter: {
                startDate: viewFromDate || defaultViewFromDateString(),
                instrumentIdIn: instrumentIds,
                statusIn: [BankAccountTransactionStatusEnum.Pending, BankAccountTransactionStatusEnum.Settled]
            }
        },
        requestPolicy: "cache-and-network",
        pause: false
    });

    const [{ fetching: loadingBankBalance, error: errorBankBalance, data: dataBankBalances }, refetchBankBalances] = useQuery({
        query: GET_SETTLED_BANK_BALANCE,
        variables: { filter: { instrumentIdIn: instrumentIds, statusIn: [BankAccountTransactionStatusEnum.Settled] } },
        requestPolicy: "cache-and-network",
        pause: false
    });

    useInterval(
        () => {
            if (loadingCashBalance || loadingBankAccountTransactions || loadingPositionBalance || loadingBankBalance) return;
            refetch({ requestPolicy: "cache-and-network" });
            refetchPositionBalances({ requestPolicy: "cache-and-network" });
            refetchBankAccountTransactions({ requestPolicy: "cache-and-network" });
            refetchBankBalances({ requestPolicy: "cache-and-network" });
        },
        10000 // Delay in milliseconds or null to stop it
    );

    useEffect(() => {
        if (dataClients) {
            if (client) {
                if (
                    (client && instrumentIds.length === 1 && instrumentIds[0] === emptyObjectId) ||
                    (client && previousClient && client._id !== previousClient._id)
                ) {
                    for (let i = 0; i < dataClients.clients.length; i++) {
                        const clientData = dataClients.clients[i];
                        if (client._id === clientData._id) {
                            setInstrumentIds(clientData.instruments.map((instrument) => instrument._id));
                            break;
                        }
                    }
                }
            }
        }
        /*if (client && client._id === emptyObjectId && Object.keys(queryArgs).length) {
            navigate("/reconcile/bankaccounts/" + client.dashName);
        }*/
    }, [dataClients, client, instrumentIds, previousClient, queryArgs, navigate]);

    useEffect(() => {
        setSpinner(false);
    }, [client, viewFromDate, tab]);

    if (loadingClients) return <p>Loading clients</p>;
    if (errorClients) return <p>errorClients: {JSON.stringify(errorClients, null, 2)}</p>;
    if (errorUpdate) return <p>error: {JSON.stringify(errorUpdate, null, 2)}</p>;
    if (errorUpsertBankAccountTransactions) return <p>error: {JSON.stringify(errorUpsertBankAccountTransactions, null, 2)}</p>;
    if (errorMatchBankAccountTransactions) return <p>error: {JSON.stringify(errorMatchBankAccountTransactions, null, 2)}</p>;

    if (errorCashBalance) return <p>errorCashBalance: {JSON.stringify(errorCashBalance, null, 2)}</p>;

    if (errorBankAccountTransactions) return <p>errorBankAccountTransactions: {JSON.stringify(errorBankAccountTransactions, null, 2)}</p>;

    if (errorPositionBalance) return <p>errorPosition: {JSON.stringify(errorPositionBalance, null, 2)}</p>;

    if (errorBankBalance) return <p>errorBankAccountBalance {JSON.stringify(errorBankBalance, null, 2)}</p>;

    if (!dataCashBalances || !dataBankAccountTransactions || !dataPositionBalances || !dataBankBalances) {
        return (
            <Page className="loader">
                <div>
                    <div>Loading cash balance</div>
                    <Svgs.Loader />
                </div>
            </Page>
        );
    }

    const cashBalancesClone: CashAccountBalance[] = cloneDeep(dataCashBalances.cashBalances);
    const positionBalancesClone: Position[] = cloneDeep(dataPositionBalances.positions);
    const bankAccountTransactionsClone: BankAccountTransaction[] = cloneDeep(dataBankAccountTransactions.bankAccountTransactions);
    const bankBalancesClone: BankAccountBalance[] = cloneDeep(dataBankBalances.bankAccountBalances);

    const groupedByInstrumentId = keyBy(cashBalancesClone, "instrumentId");
    const groupedPositionsByInstrumentId = keyBy(positionBalancesClone, "instrumentId");
    const groupedBankAccountTransactionsByInstrumentId = groupBy(bankAccountTransactionsClone, "instrumentId");
    const groupedBankBalancesByInstrumentId = keyBy(bankBalancesClone, "instrumentId");

    const tabs: Record<string, string> = {};
    if (groupedByInstrumentId && Object.keys(groupedByInstrumentId)) {
        Object.keys(groupedByInstrumentId).forEach((instrumentId) => {
            //Only interested in cashAccounts
            if (groupedByInstrumentId[instrumentId].instrument.productType === InstrumentProductTypeEnum.CashAccount) {
                tabs[instrumentId] = groupedByInstrumentId[instrumentId].instrument.name;
            }
        });
    }
    if (groupedBankAccountTransactionsByInstrumentId && Object.keys(groupedBankAccountTransactionsByInstrumentId)) {
        Object.keys(groupedBankAccountTransactionsByInstrumentId).forEach((instrumentId) => {
            //Only interested in cashAccounts
            if (
                !tabs[instrumentId] &&
                groupedBankAccountTransactionsByInstrumentId[instrumentId][0].instrument.productType ===
                    InstrumentProductTypeEnum.CashAccount
            ) {
                tabs[instrumentId] = groupedBankAccountTransactionsByInstrumentId[instrumentId][0].instrument.name;
            }
        });
    }

    const tabNames: string[] = [];
    for (const key in tabs) {
        tabNames.push(tabs[key]);
    }
    const tabNamesSorted = tabNames.sort();
    const tabsSortedByName: Record<string, string> = {};
    tabNamesSorted.forEach((instrumentName) => {
        for (const tabKey in tabs) {
            if (tabs[tabKey] === instrumentName) {
                tabsSortedByName[tabKey] = instrumentName;
            }
        }
    });

    let activeTab: string = null;
    if (tab && tabsSortedByName[tab]) {
        activeTab = tab;
    } else if (Object.keys(tabsSortedByName).length > 0) {
        activeTab = Object.keys(tabsSortedByName)[0];
    }

    let groups: Record<string, CashBalance[]> = {};
    if (Object.keys(groupedByInstrumentId).length > 0 && groupedByInstrumentId[activeTab]) {
        groups = groupByStatus(groupedByInstrumentId[activeTab].balanceItems, transactionGroups) as unknown as Record<
            string,
            CashBalance[]
        >;
    }

    let bankAccountGroups: Record<string, BankAccountTransaction[]> = {};
    if (Object.keys(groupedBankAccountTransactionsByInstrumentId).length > 0 && groupedBankAccountTransactionsByInstrumentId[activeTab]) {
        bankAccountGroups = groupByStatus(groupedBankAccountTransactionsByInstrumentId[activeTab], transactionGroups) as unknown as Record<
            string,
            BankAccountTransaction[]
        >;
    }

    const handleUpdate = async (input: UpdateTransactionInput[]) => {
        setSpinner(true);
        await updateTransactionsStatus({ input: input })
            .then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleBankAccountTransactionStatusUpdate = async (inputBase: Partial<BankAccountTransactionInput>[]) => {
        setSpinner(true);
        const input = bankAccountTransactionsUpdateFormat(inputBase);
        await updateBankAccountTransactionsStatus({ input: input })
            .then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetchBankAccountTransactions();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleBankAccountTransactionsMatch = async (input: MatchBankAccountTransactionsInput[]) => {
        setSpinner(true);
        await matchBankAccountTransactions({ input: input })
            .then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                    refetchBankAccountTransactions();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toTransactionItems = (
        itemsGrouped: Record<string, CashBalance[]>,
        instrumentId: string,
        instrumentName: string,
        currency: string
    ): Record<string, ITransactionDataRow[]> => {
        const formattedDataByGroup = {};
        if (itemsGrouped && Object.keys(itemsGrouped)) {
            for (const group of Object.keys(itemsGrouped)) {
                const items = itemsGrouped[group];
                if (items) {
                    formattedDataByGroup[group] = items.map((item: any) => {
                        return {
                            _id: item.transactionId,
                            amount: item.amount,
                            currency: currency,
                            instrumentId: instrumentId,
                            instrumentName: instrumentName,
                            tradeDate: item.tradeDate,
                            valueDate: item.valueDate,
                            type: item.transaction.type,
                            //types: item.types,
                            description: item.description,
                            status: item.transaction.status,
                            transactionItemId: item.transactionItemId,
                            error: item.transactionItem.error,
                            updatedByUserName: item.transaction.updateUserInfo ? item.transaction.updateUserInfo.name : ""
                        };
                    });
                }
            }
        }
        return formattedDataByGroup;
    };

    const formatBankAccountTransactions = (
        itemsGrouped: Record<string, BankAccountTransaction[]>,
        instrumentId: string,
        instrumentName: string,
        currency: string
    ): Record<string, ITransactionDataRow[]> => {
        const formattedDataByGroup = {};
        if (itemsGrouped && Object.keys(itemsGrouped)) {
            for (const group of Object.keys(itemsGrouped)) {
                const items = itemsGrouped[group];
                if (items) {
                    formattedDataByGroup[group] = items.map((item: any) => {
                        return {
                            _id: item._id,
                            amount: parseFloat(item.amount),
                            currency: currency,
                            instrumentId: instrumentId,
                            instrumentName: instrumentName,
                            valueDate: item.date,
                            description: item.description,
                            reference: item.reference,
                            status: item.status,
                            error: item.error,
                            updatedByUserName: item.updateUserInfo ? item.updateUserInfo.name : ""
                        };
                    });
                }
            }
        }
        return formattedDataByGroup;
    };

    const bankAccountTransactionsUpdateFormat = (updateInput: Partial<BankAccountTransactionInput>[]): BankAccountTransactionInput[] => {
        const bankAccountTransactionsById = keyBy(bankAccountTransactionsClone, "_id");
        const updateArray: BankAccountTransactionInput[] = [];
        for (const input of updateInput) {
            const bankAccountTransaction = bankAccountTransactionsById[input._id];

            updateArray.push({
                _id: input._id,
                clientId: bankAccountTransaction.clientId,
                instrumentId: bankAccountTransaction.instrumentId,
                date: bankAccountTransaction.date,
                externalId: bankAccountTransaction.externalId,
                amount: bankAccountTransaction.amount,
                status: input.status,
                reference: bankAccountTransaction.reference,
                description: bankAccountTransaction.description
            });
        }

        return updateArray;
    };

    const transactionsByItemId: Record<string, Partial<Transaction>> = {};
    if (cashBalancesClone && cashBalancesClone.length) {
        cashBalancesClone.forEach((cashBalance) => {
            if (cashBalance.balanceItems && cashBalance.balanceItems.length) {
                cashBalance.balanceItems.forEach((balanceItem) => {
                    if (balanceItem.transaction) {
                        transactionsByItemId[balanceItem.transactionItemId] = balanceItem.transaction;
                    }
                });
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} />
                </div>
            </div>

            {client && client._id !== emptyObjectId ? (
                <div>
                    <div className="row mt-4">
                        <div className="col-xs-6 col-sm-4">
                            <DateForm defaultDateString={viewFromDate} dateName={"viewFromDate"} className="width-fixed"></DateForm>
                        </div>
                        <div className="col-xs-6 col-sm-8 text-end">
                            <NewMiniTransactionButton page={`reconcile/bankaccounts/${client.dashName}`} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12">
                            {activeTab && client && client._id ? (
                                <Tabs onSelect={setTab} activeKey={activeTab} transition={false}>
                                    {Object.keys(tabsSortedByName).map((instrumentId) => {
                                        const instrumentName = groupedByInstrumentId[instrumentId]
                                            ? groupedByInstrumentId[instrumentId].instrument.name
                                            : groupedBankAccountTransactionsByInstrumentId[instrumentId][0].instrument.name;
                                        const instrumentCurrency = groupedByInstrumentId[instrumentId]
                                            ? groupedByInstrumentId[instrumentId].instrument.currency
                                            : groupedBankAccountTransactionsByInstrumentId[instrumentId][0].instrument.currency;

                                        return (
                                            <Tab key={instrumentId} eventKey={instrumentId} title={tabsSortedByName[instrumentId]}>
                                                <Link to={"/parties/" + client._id + "/instruments/" + instrumentId}>Instrument</Link>

                                                <br />
                                                <div className={"row"}>
                                                    <div className={"col"}>
                                                        <BankAccountReconciliationGrid
                                                            key={`cb-${dataCashBalances.cashBalances.length}`}
                                                            itemsByGroup={toTransactionItems(
                                                                groups,
                                                                instrumentId,
                                                                instrumentName,
                                                                instrumentCurrency
                                                            )}
                                                            onUpdate={handleUpdate}
                                                            visibleColumns={[
                                                                "id",
                                                                "status",
                                                                "tradeDate",
                                                                "valueDate",
                                                                "type",
                                                                "amount",
                                                                "error"
                                                            ]}
                                                            header={"Transactions"}
                                                            itemsReconcileByGroup={formatBankAccountTransactions(
                                                                bankAccountGroups,
                                                                instrumentId,
                                                                instrumentName,
                                                                instrumentCurrency
                                                            )}
                                                            onUpdateReconcileStatus={handleBankAccountTransactionStatusUpdate}
                                                            onMatch={handleBankAccountTransactionsMatch}
                                                            visibleReconcileColumns={[
                                                                "description",
                                                                "reference",
                                                                "status",
                                                                "valueDate",
                                                                "amount",
                                                                "error"
                                                            ]}
                                                            headerReconcile={"Bank Account Transactions"}
                                                            headerGroups={transactionGroups}
                                                            spinner={
                                                                spinner &&
                                                                (loadingUpdateTransactionsStatus ||
                                                                    loadingMatchBankAccountTransactions ||
                                                                    loadingCashBalance ||
                                                                    loadingBankAccountTransactions)
                                                            }
                                                            transactionsByItemId={transactionsByItemId}
                                                            instrumentId={instrumentId}
                                                            positionsByInstrumentId={groupedPositionsByInstrumentId}
                                                            bankBalancesByInstrumentId={groupedBankBalancesByInstrumentId}
                                                        />
                                                    </div>
                                                </div>
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/bankaccounts/${client.dashName}${location.search}`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id ? <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} /> : null}
            </Panel>
        </div>
    );
};
