import React from "react";
import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import { PartialDeep } from "type-fest";
import createPlotlyComponent from "react-plotly.js/factory";

import { PlotlyDefaults } from "../index";
import { Language } from "../Language";

const Plot = createPlotlyComponent(Plotly);

interface ISectorPieProps {
    data: any;
    locale: Language;
    showHeader: boolean;
    cdsKey: string;
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: Partial<Plotly.Layout>;
}

export const SectorPie = ({ data, locale, showHeader, config, layout, cdsKey, ...props }: ISectorPieProps): React.ReactElement => {
    let header: string = null;
    if (locale === "En") {
        header = "Sector Allocation";
    } else {
        if (locale === "Sv") {
            header = "Sektorallokering";
        }
    }
    const trace: Partial<Plotly.PlotData> = {
        labels: data.data.cdsData[cdsKey].sectors.map((d) => d.sector),
        values: data.data.cdsData[cdsKey].sectors.map((d) => d.value),
        textinfo: "label+percent",
        textposition: "inside",
        hovertemplate: "<extra></extra>%{label}<br>%{percent}",
        //font: { size: 16 },
        type: "pie",
        hole: 0.3
        //sort: false
    };

    const color = chroma("#2186C5");

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: [0, 0.5, -0.5, 1, -1, 1.5, -1.5, 2, -2, 2.5, -2.5].map((d) => color.darken(d).hex()),
        showlegend: false,
        margin: { t: -20, b: 0, l: 30, r: 0 },
        legend: { traceorder: "normal", x: 4, y: 0.5, orientation: "h" }
    };

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <React.Fragment>
            {showHeader !== false ? <h3>{header}</h3> : null}

            <div className="pretty-print">
                <Plot data={[trace]} layout={thisLayout} config={thisConfig} {...props} />
            </div>
        </React.Fragment>
    );
};
