import React, { Fragment, useEffect, useState } from "react";
import { cloneDeep, round } from "lodash";
import Plotly from "plotly.js-finance-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";
import { Form, Formik } from "formik";

import { DateHelper, numberFormat } from "../../../../common/src";
import { Column, Grid, PlotlyDefaults, Svgs } from "../../../../components/src";
import { usePrevious, useQueryArgs } from "../../common/Utils";
import { InstrumentModelTypeEnum, CurrencyEnum, Party, InstrumentPerformance, PartyAccount, PartyAccountType } from "../../types.generated";
import { DateField, MultipleSelectField, SelectField } from "../../components/form";
import { getTopBottomInstruments } from "./functions";
import { GetPerformanceDataQuery } from "./queries.generated";
import { exportToXlsx } from "../../common/exportToXlsx";

// Unfortunately waterfall is not typed for now
const Plot: any = createPlotlyComponent(Plotly);

interface PerformanceData {
    // First day special case
    totalReturnDay0: number;
    totalReturn: number;
    series: number[];
    plotSeries: number[];
    waterfall: number;
}

export enum AttributionSelectorEnum {
    ModelTypes = "ModelTypes",
    Currencies = "Currencies",
    Instruments = "Instruments",
    Accounts = "Accounts"
}
const selectorToInstrumentProperty = (selector: AttributionSelectorEnum) => {
    // Should selector enum return property directly?
    if (selector === AttributionSelectorEnum.ModelTypes) {
        return "modelType";
    } else if (selector === AttributionSelectorEnum.Currencies) {
        return "currency";
    } else if (selector === AttributionSelectorEnum.Instruments) {
        return "name";
    }
    return "";
};

export enum PlotTypeEnum {
    Waterfall = "Waterfall",
    Series = "Series"
}
interface FormData {
    startDate?: string;
    endDate?: string;
    selector?: AttributionSelectorEnum;
    currencies?: CurrencyEnum[];
    accounts?: string[];
    modelTypes?: InstrumentModelTypeEnum[];
    instruments?: string[];
    plotType?: PlotTypeEnum.Series;
}

const MS_PER_DAY = 86400000;

export const daysBetween = (fromDate: string, toDate: string): number => {
    return (new Date(toDate).getTime() - new Date(fromDate).getTime()) / MS_PER_DAY;
};

const numberToDecimalString = (num: number, decimals: number) => {
    return round(num, decimals).toFixed(decimals);
};

const dataToTraces = (
    data: any,
    formData: FormData,
    plotTypeChosen: PlotTypeEnum,
    accountsById: Record<string, string>
): Plotly.Data[] | any[] => {
    // This will be input from page
    const groupBy = selectorToInstrumentProperty(formData.selector);
    const groupValues = [];
    if (formData.selector === AttributionSelectorEnum.ModelTypes) {
        groupValues.push(...formData.modelTypes);
    } else if (formData.selector === AttributionSelectorEnum.Currencies) {
        groupValues.push(...formData.currencies);
    } else if (formData.selector === AttributionSelectorEnum.Instruments) {
        groupValues.push(...formData.instruments);
    } else if (formData.selector === AttributionSelectorEnum.Accounts) {
        groupValues.push(...formData.accounts);
    } else {
        // Should not happen but...
        throw new Error("Unknown AttributionSelector");
    }
    const localData = cloneDeep(data);
    const otherGroup = "Other";

    const groupPerformances: Record<string, PerformanceData> = {};
    // Start with other group
    groupPerformances[otherGroup] = {
        totalReturnDay0: 0,
        totalReturn: 0,
        series: [],
        plotSeries: [],
        waterfall: 0
    };

    for (let i = 0; i < groupValues.length; i++) {
        groupPerformances[groupValues[i]] = {
            totalReturnDay0: 0,
            totalReturn: 0,
            series: [],
            plotSeries: [],
            waterfall: 0
        };
    }

    // Get start index - since we know we have all days it can be calculated

    const groupKeys = Object.keys(groupPerformances);

    if (localData && localData.dates && localData.values && localData.instrumentPerformances) {
        const seriesFactor = 1 / localData.series[daysBetween(localData.dates[0], formData.startDate)];
        // Calculate return series for each group using total as base to get additive return series
        let startDateIndex = 0;
        let endDateIndex = localData.dates.length;
        for (let i = 0; i < endDateIndex; i++) {
            // Since we loop from i=startDateIndex to i<endDateIndex
            if (localData.dates[i] === formData.startDate) startDateIndex = i;
            if (localData.dates[i] === formData.endDate) endDateIndex = i + 1;
        }
        const dates: string[] = [];
        const plotSeries: number[] = [];
        // Loop only relevant dates
        for (let i = startDateIndex; i < endDateIndex; i++) {
            dates.push(localData.dates[i]);
            plotSeries.push(100 * (seriesFactor * localData.series[i] - 1));
            for (let j = 0; j < localData.instrumentPerformances.length; j++) {
                const instrumentPerformance: InstrumentPerformance = localData.instrumentPerformances[j];
                // account
                if (formData.selector === AttributionSelectorEnum.Accounts) {
                    const account = accountsById[instrumentPerformance.accountId];

                    const group = groupPerformances[account] ? groupPerformances[account] : groupPerformances[otherGroup];
                    if (i === 0) {
                        group.totalReturn += instrumentPerformance.values[i] - instrumentPerformance.cashFlows[i];
                    } else {
                        group.totalReturn +=
                            instrumentPerformance.values[i] - instrumentPerformance.values[i - 1] - instrumentPerformance.cashFlows[i];
                    }
                }
                // instrument properties
                else {
                    const instrument = instrumentPerformance.instrument;

                    const group = groupPerformances[instrument[groupBy]]
                        ? groupPerformances[instrument[groupBy]]
                        : groupPerformances[otherGroup];
                    if (i === 0) {
                        group.totalReturn += instrumentPerformance.values[i] - instrumentPerformance.cashFlows[i];
                    } else {
                        group.totalReturn +=
                            instrumentPerformance.values[i] - instrumentPerformance.values[i - 1] - instrumentPerformance.cashFlows[i];
                    }
                }
            }
            // Calculate series and reset group returns
            let stackedValue = 0;
            for (let j = 0; j < groupKeys.length; j++) {
                let seriesValue = 0;
                if (i === 0) {
                    groupPerformances[groupKeys[j]].totalReturnDay0 = groupPerformances[groupKeys[j]].totalReturn;
                    seriesValue = 0;
                } else if (i === startDateIndex) {
                    seriesValue = 0;
                } else {
                    let totalReturn = groupPerformances[groupKeys[j]].totalReturn;
                    // If day two - add first day total return since first day forced to start with no return
                    if (i === 1) totalReturn += groupPerformances[groupKeys[j]].totalReturnDay0;

                    // Assert no division by zero?
                    // if (localData.values[i - 1] === 0) throw new Error("Performance value at date " + dates[i] + " zero. Division by zero!");
                    if (localData.values[i - 1] === 0) {
                        seriesValue = groupPerformances[groupKeys[j]].series[i - startDateIndex - 1];
                    } else {
                        // Scale with cumulative return
                        seriesValue =
                            groupPerformances[groupKeys[j]].series[i - startDateIndex - 1] +
                            (seriesFactor * localData.series[i - 1] * totalReturn) / localData.values[i - 1];
                    }
                }
                stackedValue += seriesValue;
                groupPerformances[groupKeys[j]].series.push(seriesValue);
                groupPerformances[groupKeys[j]].plotSeries.push(100 * stackedValue);
                groupPerformances[groupKeys[j]].totalReturn = 0;
                // Update waterfall to last series value in percent for output
                groupPerformances[groupKeys[j]].waterfall = 100 * seriesValue;
            }
        }
        // Set up traces Plotly
        const traces: any[] = [];
        if (plotTypeChosen === PlotTypeEnum.Series) {
            traces.push({ name: otherGroup, x: dates, y: groupPerformances[otherGroup].plotSeries, fill: "tozeroy", type: "scatter" });
            for (let i = 0; i < groupValues.length; i++) {
                traces.push({
                    name: groupValues[i],
                    x: dates,
                    y: groupPerformances[groupValues[i]].plotSeries,
                    fill: "tonexty",
                    type: "scatter"
                });
            }

            traces.push({ name: "Total", x: dates, y: plotSeries, type: "scatter" });
        } else {
            const x = [otherGroup];
            const y = [groupPerformances[otherGroup].waterfall];
            const measure = ["relative"];
            const text = [numberToDecimalString(groupPerformances[otherGroup].waterfall, 1) + " %"];

            for (let i = 0; i < groupValues.length; i++) {
                x.push(groupValues[i]);
                y.push(groupPerformances[groupValues[i]].waterfall);
                measure.push("relative");
                text.push(numberToDecimalString(groupPerformances[groupValues[i]].waterfall, 1) + " %");
            }

            x.push("Total");
            y.push(plotSeries.slice(-1)[0]);
            measure.push("total");
            text.push(numberToDecimalString(plotSeries.slice(-1)[0], 1) + " %");

            traces.push({
                type: "waterfall",
                orientation: "v",
                measure,
                x,
                textposition: "outside",
                text,
                y,
                connector: {
                    visible: false
                },
                decreasing: { marker: { color: "#D98880" } },
                increasing: { marker: { color: "#76D7C4" } },
                totals: { marker: { color: "#85C1E9" } }
            });
        }
        return traces;
    }

    return [];
};

const yesterday = DateHelper.dateAddDays(new Date(), -1).toISOString().substring(0, 10);

export const initFormData: FormData = {
    startDate: "2000-01-01",
    endDate: yesterday,
    accounts: [],
    currencies: [],
    modelTypes: [],
    instruments: []
};

interface AttributionGraphProps {
    data: PartialDeep<GetPerformanceDataQuery> & {
        topBottomInstruments?: string[];
        accountDescriptions?: string[];
        accountsById?: Record<string, PartyAccount>;
    };
    client: PartialDeep<Party>;
    plotType?: PlotTypeEnum;
    selector?: AttributionSelectorEnum;
    style?: { [key: string]: string };
    showForm?: boolean;
    showXlsxButton?: boolean;
    layout?: PartialDeep<Plotly.Layout>;
}

export const AttributionGraph = ({
    data,
    client,
    plotType,
    selector,
    style,
    showForm = true,
    showXlsxButton = true,
    layout
}: AttributionGraphProps): React.ReactElement => {
    const { queryArgs, pushQueryArgs: setFormData } = useQueryArgs();

    const [plotTypeChosen, setPlotTypeChosen] = useState(plotType ? plotType : null);

    const previousClientId: string = usePrevious(client._id);

    useEffect(() => {
        if (showForm && data) {
            if (client._id && previousClientId && previousClientId !== client._id) {
                setFormData(null);
            } else {
                if (!("plotType" in queryArgs)) {
                    const init = cloneDeep(initFormData);
                    init.startDate = data.performance.dates.length > 0 ? data.performance.dates[0] : "2000-01-01";
                    init.plotType = PlotTypeEnum.Series;
                    init.selector = AttributionSelectorEnum.ModelTypes;
                    setFormData(init);
                }
            }
        }
        if (queryArgs.plotType && queryArgs.plotType !== plotTypeChosen) {
            setPlotTypeChosen(queryArgs.plotType as PlotTypeEnum);
        }
    }, [client._id, data, plotType, plotTypeChosen, previousClientId, queryArgs, setFormData, showForm]);

    if (Object.values(queryArgs).length === 0) return <div></div>;

    const usedCurrencies: Record<string, boolean> = {};
    const usedModelTypes: Record<string, boolean> = {};
    const usedInstruments: Record<string, boolean> = {};
    const lastInstrumentValueByName: Record<string, number> = {};
    const instrumentPerformances = data ? data.performance.instrumentPerformances : [];

    for (const instrumentPerformance of instrumentPerformances as InstrumentPerformance[]) {
        if (instrumentPerformance && instrumentPerformance.instrument && instrumentPerformance.instrument.currency) {
            usedCurrencies[instrumentPerformance.instrument.currency] = true;
        }
        if (instrumentPerformance && instrumentPerformance.instrument && instrumentPerformance.instrument.modelType) {
            usedModelTypes[instrumentPerformance.instrument.modelType] = true;
        }
        if (instrumentPerformance && instrumentPerformance.instrument) {
            usedInstruments[instrumentPerformance.instrument.name] = true;
            lastInstrumentValueByName[instrumentPerformance.instrument.name] =
                instrumentPerformance.series[instrumentPerformance.series.length - 1];
        }
    }

    const accounts = data && data.client ? data.client.accounts : ([] as PartyAccount[]);
    const usedAccounts: Record<string, boolean> = {};
    const accountsById: Record<string, string> = {};

    if (data.accountsById) {
        for (const [accountId, account] of Object.entries(data.accountsById)) {
            if (account.description) accountsById[accountId] = account.description;
        }
    }

    for (const account of accounts) {
        if (account && account.type === PartyAccountType.Physical && account.description) {
            usedAccounts[account.description] = true;
        }
        if (account && account.description) accountsById[account._id] = account.description;
    }

    const currencyOptions: {
        key: string;
        value: string;
    }[] = Object.keys(usedCurrencies)
        .sort()
        .map((v) => {
            return { key: v, value: v };
        });

    const modelTypesOptions: {
        key: string;
        value: string;
    }[] = Object.keys(usedModelTypes)
        .sort()
        .map((v) => {
            return { key: v, value: v };
        });

    const instrumentsOptions: {
        key: string;
        value: string;
    }[] = Object.keys(usedInstruments)
        .sort()
        .map((v) => {
            return { key: v, value: v };
        });

    const accountOptions: {
        key: string;
        value: string;
    }[] = Object.keys(usedAccounts)
        .sort()
        .map((v) => {
            return { key: v, value: v };
        });

    let usedSelector = AttributionSelectorEnum.ModelTypes;
    // if more than 3 physical accounts use accounts
    if (Object.values(usedAccounts).length > 3) usedSelector = AttributionSelectorEnum.Accounts;
    // selector specified in args
    if (queryArgs.selector) usedSelector = queryArgs.selector as AttributionSelectorEnum;
    // selector specified in params
    if (selector) usedSelector = selector;

    const formData: FormData = {
        startDate: queryArgs.startDate ? (queryArgs.startDate as string) : yesterday,
        endDate: queryArgs.endDate ? (queryArgs.endDate as string) : yesterday,
        selector: usedSelector,
        accounts: [],
        currencies: [],
        modelTypes: [],
        instruments: [],
        plotType: PlotTypeEnum.Series
    };

    const performanceData = data ? data.performance : {};

    if (queryArgs.currencies) {
        if (typeof queryArgs.currencies === "string") {
            formData.currencies = [queryArgs.currencies as CurrencyEnum];
        } else {
            formData.currencies = queryArgs.currencies as CurrencyEnum[];
        }
    }

    if (queryArgs.modelTypes) {
        if (typeof queryArgs.modelTypes === "string") {
            formData.modelTypes = [queryArgs.modelTypes as InstrumentModelTypeEnum];
        } else {
            formData.modelTypes = queryArgs.modelTypes as InstrumentModelTypeEnum[];
        }
    }

    if (queryArgs.instruments) {
        if (typeof queryArgs.instruments === "string") {
            formData.instruments = [queryArgs.instruments];
        } else {
            if (queryArgs.instruments.length > 10 && showForm) {
                const usedInstrumentsValues: { [key: string]: number } = {};
                for (const instrumentName of queryArgs.instruments) {
                    if (lastInstrumentValueByName[instrumentName]) {
                        usedInstrumentsValues[instrumentName] = lastInstrumentValueByName[instrumentName];
                    }
                }

                const topBottomInstruments = getTopBottomInstruments(usedInstrumentsValues);
                formData.instruments = topBottomInstruments;
            } else {
                formData.instruments = queryArgs.instruments;
            }
        }
    }

    if (queryArgs.accounts) {
        if (typeof queryArgs.accounts === "string") {
            formData.accounts = [queryArgs.accounts];
        } else {
            formData.accounts = queryArgs.accounts;
        }
    }

    if (data && data.topBottomInstruments) {
        formData.instruments = data.topBottomInstruments;
    }
    if (data && data.accountDescriptions) {
        formData.accounts = data.accountDescriptions;
    }

    const defaultLayout = PlotlyDefaults.mergeLayout(PlotlyDefaults.getDefaultLayout(), {
        xaxis:
            plotTypeChosen === PlotTypeEnum.Series
                ? {
                      fixedrange: false,
                      title: {
                          text: "Date",
                          standoff: 30
                      },
                      automargin: true
                  }
                : {
                      type: "category",
                      automargin: true,
                      title: {
                          standoff: 30
                      }
                  },
        yaxis:
            plotTypeChosen === PlotTypeEnum.Series
                ? {
                      fixedrange: false,
                      title: {
                          text: "Cumulative return [%]",
                          standoff: 30
                      },
                      automargin: true
                  }
                : {
                      title: {
                          text: "Cumulative return [%]",
                          standoff: 30
                      },
                      type: "linear",
                      automargin: true
                  },
        colorway: [
            "#2186c5",
            "#43a04c",
            "#f44336",
            "#f8a031",
            "#ba46b8",
            "#009688",
            "#ff5722",
            "#ec407a",
            "#c0ca33",
            "#795548",
            "#e5878a",
            "#9e9e9e"
        ],
        margin: { l: 10, b: 0, t: 0, r: 0, pad: 0 },
        font: { size: 10, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        autosize: true,
        legend: {
            yanchor: "top",
            y: 1
        }
    } satisfies Partial<Plotly.Layout>);
    const thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, layout);

    const config2: Partial<Plotly.Config> = {
        responsive: true,
        displayModeBar: false,
        showSendToCloud: false,
        toImageButtonOptions: { format: "svg" },
        displaylogo: false,

        modeBarButtonsToRemove: [
            "zoom2d",
            "pan2d",
            "select2d",
            "resetViews",
            "toggleSpikelines",
            "hoverClosestCartesian",
            "hoverCompareCartesian",
            "lasso2d",
            "zoomIn2d",
            "zoomOut2d",
            "autoScale2d",
            "resetScale2d",
            "hoverClosestPie",
            "sendDataToCloud"
        ]
    };

    let traces = [];

    if (Object.keys(performanceData) && Object.keys(performanceData).length) {
        traces = dataToTraces(performanceData, formData as unknown as FormData, plotTypeChosen, accountsById);
    }

    const nonStackedTraces: { name: string; value: number }[] = [];
    let previousValue = 0;
    if (traces.length) {
        if (traces[0].type === "scatter") {
            for (let i = 0; i < traces.length; i++) {
                let value: number = traces[i] && traces[i].y && traces[i].y.length ? Number(traces[i].y[traces[i].y.length - 1]) : null;
                if (value || value === 0) {
                    const stackedValue = value;
                    value = i === traces.length - 1 ? stackedValue : stackedValue - previousValue;
                    previousValue = stackedValue;
                }
                const name: string = traces[i].name;
                nonStackedTraces.push({ name, value: value || value === 0 ? value : null });
            }
        } else if (traces[0].type === "waterfall") {
            for (let i = 0; i < traces[0].x.length; i++) {
                const value: number = traces[0] && traces[0].y ? Number(traces[0].y[i]) : null;
                const name: string = traces[0].x[i];
                nonStackedTraces.push({ name, value: value || value === 0 ? value : null });
            }
        }
    }

    return (
        <div className="container-fluid">
            <div className={!showForm ? "row-fluid" : "row"}>
                {showForm ? (
                    <div className="col-sm-4 col-md-3">
                        <Formik
                            enableReinitialize={true}
                            initialValues={formData}
                            validate={(validateFormData) => {
                                const errors: any = {};

                                if (validateFormData.selector === AttributionSelectorEnum.Currencies) {
                                    delete validateFormData.instruments;
                                    delete validateFormData.modelTypes;
                                    delete validateFormData.accounts;
                                } else if (validateFormData.selector === AttributionSelectorEnum.ModelTypes) {
                                    delete validateFormData.instruments;
                                    delete validateFormData.currencies;
                                    delete validateFormData.accounts;
                                } else if (validateFormData.selector === AttributionSelectorEnum.Instruments) {
                                    delete validateFormData.modelTypes;
                                    delete validateFormData.currencies;
                                    delete validateFormData.accounts;
                                } else if (validateFormData.selector === AttributionSelectorEnum.Accounts) {
                                    delete validateFormData.modelTypes;
                                    delete validateFormData.currencies;
                                    delete validateFormData.instruments;
                                } else {
                                    validateFormData.selector = AttributionSelectorEnum.Currencies;
                                    validateFormData.currencies = [];
                                    delete validateFormData.instruments;
                                    delete validateFormData.modelTypes;
                                }

                                setFormData(validateFormData);
                                return Object.keys(errors).length > 0 ? errors : {};
                            }}
                            onSubmit={null}
                        >
                            {({ isSubmitting }) => (
                                <Fragment>
                                    <Form autoComplete="off">
                                        <div className="container form print-none">
                                            <div className="row">
                                                <div className="col-lg">
                                                    <DateField
                                                        className=""
                                                        name="startDate"
                                                        label="Start date"
                                                        minDate={client.firstTradeDate}
                                                        disabled={isSubmitting}
                                                    />
                                                    <DateField
                                                        className=""
                                                        name="endDate"
                                                        label="End date"
                                                        minDate={client.firstTradeDate}
                                                        disabled={isSubmitting}
                                                    />
                                                    <SelectField
                                                        name="plotType"
                                                        label="Plot type"
                                                        options={Object.keys(PlotTypeEnum)
                                                            .sort()
                                                            .map((option) => ({ key: option, value: option }))}
                                                        className=""
                                                        disabled={isSubmitting}
                                                        size={Object.keys(PlotTypeEnum).length}
                                                    />
                                                    <SelectField
                                                        name="selector"
                                                        label="Selector"
                                                        options={Object.keys(AttributionSelectorEnum)
                                                            .sort()
                                                            .map((option) => ({ key: option, value: option }))}
                                                        className=""
                                                        disabled={isSubmitting}
                                                        size={Object.keys(AttributionSelectorEnum).length}
                                                    />
                                                    {formData.selector.toString() === AttributionSelectorEnum.Currencies.toString() ? (
                                                        <MultipleSelectField
                                                            name="currencies"
                                                            label="Currencies"
                                                            options={currencyOptions}
                                                            className=""
                                                            disabled={isSubmitting}
                                                            size={Object.values(currencyOptions).length}
                                                        />
                                                    ) : null}
                                                    {formData.selector.toString() === AttributionSelectorEnum.ModelTypes.toString() ? (
                                                        <MultipleSelectField
                                                            name="modelTypes"
                                                            label="Model types"
                                                            options={modelTypesOptions}
                                                            className=""
                                                            disabled={isSubmitting}
                                                            size={Object.values(modelTypesOptions).length}
                                                        />
                                                    ) : null}
                                                    {formData.selector.toString() === AttributionSelectorEnum.Instruments.toString() ? (
                                                        <MultipleSelectField
                                                            name="instruments"
                                                            label="Instruments"
                                                            options={instrumentsOptions}
                                                            className=""
                                                            disabled={isSubmitting}
                                                            size={Object.values(instrumentsOptions).length}
                                                        />
                                                    ) : null}
                                                    {formData.selector.toString() === AttributionSelectorEnum.Accounts.toString() ? (
                                                        <MultipleSelectField
                                                            name="accounts"
                                                            label="Accounts"
                                                            options={accountOptions}
                                                            className=""
                                                            disabled={isSubmitting}
                                                            size={Object.values(accountOptions).length}
                                                        />
                                                    ) : null}
                                                    <Grid data={nonStackedTraces} className="table-timeseries" tableClassName="table-xs">
                                                        <Column field="name" title="Group" className="nowrap left" />
                                                        <Column
                                                            field="value"
                                                            title="Cum return"
                                                            className="text-end"
                                                            format={(value) => (
                                                                <div className="text-end">{numberFormat(Number(value), "# ##0.00")}%</div>
                                                            )}
                                                        />
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Fragment>
                            )}
                        </Formik>
                    </div>
                ) : null}

                {Object.keys(performanceData) && Object.keys(performanceData).length ? (
                    <div className={!showForm ? "fluid-col-sm-8 fluid-col-md-9" : "col-sm-8 col-md-9"}>
                        <h4 className="text-center">Performance attribution</h4>
                        <div className="mb-3" style={{ width: "20px", height: "20px" }}>
                            {showXlsxButton ? (
                                <div
                                    className="print-none"
                                    onClick={() => {
                                        const data: Record<string, Record<string, string | number>> = {};
                                        traces.forEach((trace) => {
                                            for (const index in trace.x) {
                                                const date = trace.x[index];
                                                const value = trace.y[index];
                                                if (data[date]) {
                                                    data[date][trace.name] = value;
                                                } else {
                                                    const timeSeriesItem: Record<string, string | number> = {};
                                                    timeSeriesItem["Date"] = date;
                                                    timeSeriesItem[trace.name] = value;
                                                    data[date] = timeSeriesItem;
                                                }
                                            }
                                        });
                                        exportToXlsx([Object.values(data)], "attribution.xlsx", ["attribution"]);
                                    }}
                                >
                                    <Svgs.Excel />
                                </div>
                            ) : null}
                        </div>
                        <Plot
                            //data={dataToTraces(data, (formData as unknown) as FormData)}
                            data={traces}
                            layout={thisLayout}
                            useResizeHandler={true}
                            style={{
                                width: "100%",
                                height: style && style.height ? style.height : "calc(100vh - 100px)",
                                position: "sticky",
                                top: "0"
                            }}
                            config={config2}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
