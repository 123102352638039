import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";
import stableStringify from "json-stable-stringify";
import { Link, useParams } from "react-router-dom";

import swaplab2Url from "swaplab2/dist/swaplab2.wasm?url";
import { Swaplab2Module, setSwaplab2, CurrencyEnum } from "swaplab2";

import { QuantLibPeriod, QuantLibTimeUnitEnum, YieldDeltaBucket } from "swaplab2";
import { PlotlyDefaults } from "../../../../../components/src";
import { numberFormat, twoDecPriceFormat } from "../../../../../common/src";
import { Instrument, InstrumentModelTypeEnum, Position } from "../../../types.generated";
import { getData as GET_DATA } from "../../swaplab2/SwapLab2Page";
import { getResultsByInstrumentId } from "../../swaplab2/Utils";
import { useQueryState } from "../../../common/use-query-state";
import { usePrevious, useQueryArgs } from "../../../common/Utils";
import { yesterday } from "../../../components/dateFormater";
import { TableGrouper } from "../../../components/react-table/TableGrouper";
import { DateForm } from "../../../common/dateForm";

const Plot = createPlotlyComponent(Plotly);

const enum AccountModelTypeEnum {
    Account = "Account"
}

interface InterestRateBucketsData {
    name: string;
    totalValue: number;
    interestRateBuckets: YieldDeltaBucket[];
}
interface InterestRateBucketsProps {
    data: InterestRateBucketsData;
    config?: PartialDeep<Plotly.Config>;
    style?: React.CSSProperties | undefined;
    layout?: Partial<Plotly.Layout>;
}

const d3FormatValue = (values: number[]): string => {
    let max = -Infinity,
        min = Infinity,
        len = values.length;
    while (len--) {
        if (values[len] > max) {
            max = values[len];
        }
        if (values[len] < min) {
            min = values[len];
        }
    }
    if (Math.abs(max) > 9.95 || Math.abs(min) > 9.95) return "";
    if (Math.abs(max) > 0.995 || Math.abs(min) > 0.995) return ".1f";
    if (Math.abs(max) > 0.0995 || Math.abs(min) > 0.0995) return ".2f";
    if (Math.abs(max) > 0.00995 || Math.abs(min) > 0.00995) return ".3f";
    if (Math.abs(max) > 0.000995 || Math.abs(min) > 0.000995) return ".4f";
    if (Math.abs(max) > 0.0000995 || Math.abs(min) > 0.0000995) return ".5f";
    if (Math.abs(max) > 0.00000995 || Math.abs(min) > 0.00000995) return ".6f";
    if (Math.abs(max) > 0.000000995 || Math.abs(min) > 0.000000995) return ".7f";
    return "";
};

const getLastValuationDate = (positions: Position[]): string => {
    const valuationsByDate: Record<string, number> = {};
    for (const position of positions) {
        if (
            position.instrument &&
            position.instrument.model &&
            position.instrument.model._t !== InstrumentModelTypeEnum.Balance &&
            position.instrument.valuations &&
            position.instrument.valuations.date
        ) {
            valuationsByDate[position.instrument.valuations.date] = 1;
        }
    }
    const keys = Object.keys(valuationsByDate);
    if (Array.isArray(keys) && keys.length) {
        let maxKey = keys[0];
        for (const key of keys) {
            if (key > maxKey) maxKey = key;
        }
        return maxKey;
    }
    return null;
};

export const InterestRateBuckets = ({ data, layout, config, ...props }: InterestRateBucketsProps): React.ReactElement => {
    const xValues = data.interestRateBuckets.map((d) => periodToShortString(d.period));
    const y2Values = data.interestRateBuckets.map((d) => (10000 * d.value) / data.totalValue);
    const trace: Partial<Plotly.PlotData> = {
        name: data.name,
        x: xValues,
        y: data.interestRateBuckets.map((d) => d.value),
        type: "bar",
        line: { width: 0 },
        marker: { color: PlotlyDefaults.getColor(1) }
    };
    const trace2: Partial<Plotly.PlotData> = {
        name: data.name,
        x: xValues,
        y: y2Values,
        yaxis: "y2",
        type: "bar",
        line: { width: 0 },
        marker: { color: "rgba(0, 0, 0, 0)" }
        //visible: "legendonly"
    };

    const plotlyData: PartialDeep<Plotly.PlotData>[] = [trace, trace2];

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    // tickformat: "%"
    const localLayout: Partial<Plotly.Layout> = {
        showlegend: false,
        title: data.name,
        yaxis: { title: "Yield Delta [CCY]", tickformat: "s" },
        yaxis2: {
            title: "Fraction of total [BPS]",
            overlaying: "y",
            tickformat: d3FormatValue(y2Values),
            side: "right",
            gridcolor: "rgba(0, 0, 0, 0)"
        }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.getDefaultConfig();

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <Plot
            data={plotlyData as Plotly.PlotData[]}
            config={thisConfig as Plotly.Config}
            layout={thisLayout}
            useResizeHandler={true}
            {...props}
        />
    );
};

const zeroDecPriceFormat = (d: number) => numberFormat(d, "#\xa0##0");

const getNotional = (quantity: number, instrument: Instrument) => {
    if (
        instrument &&
        (instrument.modelType === InstrumentModelTypeEnum.Swap || instrument.modelType === InstrumentModelTypeEnum.Swaption) &&
        quantity > 0.9999999 &&
        quantity < 1.0000001
    )
        return instrument.modelNotionalScaling;
    else if (
        instrument &&
        (instrument.modelType === InstrumentModelTypeEnum.CdsIndex || instrument.modelType === InstrumentModelTypeEnum.CdsBasket) &&
        instrument.model &&
        Array.isArray(instrument.model.legs) &&
        instrument.model.legs.length &&
        instrument.model.legs[0].notional
    )
        return quantity * instrument.model.legs[0].notional;
    else return quantity;
};

const periodToShortString = (period: QuantLibPeriod): string => {
    if (period.unit === QuantLibTimeUnitEnum.Years) return period.value + "Y";
    else if (period.unit === QuantLibTimeUnitEnum.Months) return period.value + "M";
    else if (period.unit === QuantLibTimeUnitEnum.Weeks) return period.value + "W";
    else if (period.unit === QuantLibTimeUnitEnum.Days) return period.value + "D";
    else return null;
};

const isValidRow = (row: InterestRateBucketsData): boolean => {
    if (!row.name) {
        return false;
    }
    if (!Array.isArray(row.interestRateBuckets) || !row.interestRateBuckets.length) {
        return false;
    }
    return true;
};

interface PerformanceTabProps {
    client: { _id: string; name: string };
}

export const DurationRiskTab = ({ client }: PerformanceTabProps): React.ReactElement => {
    const [swaplab2Loaded, setSwaplab2Loaded] = useState(false);
    const [totalValue, setTotalValue] = useState<number>(null);
    const [table, setTable] = useState([]);
    const [baseCurrency, _setBaseCurrency] = useState<CurrencyEnum>(CurrencyEnum.SEK);
    const [sekDict, setSekDict] = useState<Record<string, number>>({});
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [clientId, setClientId] = useState<string>(null);
    const previousClient: PerformanceTabProps["client"] = usePrevious(client);
    const { queryArgs, pushQueryArgs } = useQueryArgs();
    const { tabId } = useParams<"tabId">();
    const previousTabId = usePrevious(tabId);

    // Yesterday since we need valuations...
    const [endDate] = useQueryState("endDate", yesterday());
    // this should set the data needed for the graph. TimeSeries for performance and array of TimeSeries for Benchmark
    const [selectedRow, setSelectedRow] = useState<InterestRateBucketsData>({
        name: "",
        totalValue: null,
        interestRateBuckets: []
    });
    const [firstSelectedRow, setFirstSelectedRow] = useState<InterestRateBucketsData>({
        name: "",
        totalValue: null,
        interestRateBuckets: []
    });

    const [{ fetching: loading, error, data }] = useQuery({
        query: GET_DATA,
        requestPolicy: "network-only",
        variables: { clientIdsIn: client._id, dateString: endDate, date: endDate, withFx: true, withMappings: true },
        pause: client ? false : true
    });

    // Default buckets - this should be made available in form later
    const defaultBuckets: YieldDeltaBucket[] = [];
    for (let i = 0; i < 21; i++) {
        defaultBuckets.push({ period: { value: i + 1, unit: QuantLibTimeUnitEnum.Years }, value: null });
    }
    const yieldDeltaBuckets = defaultBuckets;
    const numberOfBuckets = yieldDeltaBuckets.length;

    if (!swaplab2Loaded) {
        Swaplab2Module({ locateFile: (path) => (path.endsWith(".wasm") ? swaplab2Url : path) }).then((loaded) => {
            setSwaplab2(loaded);
            setSwaplab2Loaded(true);
        });
    }

    useEffect(() => {
        if (!clientId && client) {
            setClientId(client._id);
        }
        if (client && previousClient && client._id != previousClient._id) {
            setClientId(client._id);
            setSelectedRow({
                name: "",
                totalValue: null,
                interestRateBuckets: []
            });
        }

        //Only to make sure queryArg in Url when initializing the page
        if (
            endDate &&
            !queryArgs.endDate &&
            tabId &&
            tabId === "risk" &&
            (!previousClient || (previousClient && previousTabId !== tabId))
        ) {
            pushQueryArgs({ endDate });
        }
    }, [client, clientId, endDate, previousClient, previousTabId, pushQueryArgs, queryArgs.endDate, tabId]);

    useEffect(() => {
        try {
            if (data && client) {
                const fxDictionary: Record<string, number> = {};
                fxDictionary[baseCurrency + baseCurrency] = 1;
                for (const fxValuation of data.fxValuations) {
                    fxDictionary[fxValuation.name] = fxValuation.price;
                }
                const tableData = [];
                let totalMarketValue = 0;
                for (const position of data.positions as Position[]) {
                    if (!fxDictionary[position.currency + baseCurrency])
                        throw new Error("No fx rate for: " + position.currency + baseCurrency);
                    const value =
                        position.quantity *
                        fxDictionary[position.currency + baseCurrency] *
                        (position.isCashAccount ? 1 : position.instrument.valuations.price);
                    totalMarketValue += value;
                    const row = {
                        clientName: client.name,
                        _id: position.instrument._id,
                        name: position.instrument.name,
                        modelType: position.instrument.modelType ? position.instrument.modelType : AccountModelTypeEnum.Account,
                        currency: position.currency,
                        quantity: position.quantity,
                        notional: getNotional(position.quantity, position.instrument),
                        modifiedDuration: null,
                        value,
                        isCashAccount: position.isCashAccount,
                        creditYieldDelta: null,
                        interestRateYieldDelta: null,
                        bucket0: null,
                        zeroSpread: null
                    };
                    tableData.push(row);
                }
                setTable(tableData);
                setTotalValue(totalMarketValue);
                setSekDict(fxDictionary);
            }
        } catch (e) {
            setErrorMessage(e.toString());
        }
    }, [baseCurrency, client, data]);

    useEffect(() => {
        try {
            if (data && client && swaplab2Loaded) {
                const evalDate = getLastValuationDate(data.positions);

                const results = getResultsByInstrumentId(data.positions, data.valuationmappings, yieldDeltaBuckets, evalDate);
                const buckets: YieldDeltaBucket[] = [];
                // Since all results have the same buckets
                const result = Object.values(results)[0];
                const resultBuckets = result.interestRateYieldDeltaBuckets;
                for (let i = 0; i < numberOfBuckets; i++) {
                    buckets.push({ period: { value: resultBuckets[i].period.value, unit: resultBuckets[i].period.unit }, value: 0 });
                }
                const updatedTable = [];
                for (const row of table) {
                    const newRow = { ...row };
                    if (results[newRow._id]) {
                        // Swaps and swaptions have full notional on cash flows thus scale with 1. for bonds scale with quantity
                        if (!sekDict[newRow.currency + baseCurrency]) throw new Error("No fx rate for: " + newRow.currency + baseCurrency);
                        const scale: number =
                            newRow.modelType === InstrumentModelTypeEnum.Bond ||
                            newRow.modelType === InstrumentModelTypeEnum.CdsIndex ||
                            newRow.modelType === InstrumentModelTypeEnum.CdsBasket
                                ? sekDict[newRow.currency + baseCurrency] * newRow.quantity
                                : sekDict[newRow.currency + baseCurrency];
                        newRow.interestRateYieldDelta = results[newRow._id].interestRateYieldDelta * scale;
                        newRow.creditYieldDelta = results[newRow._id].creditYieldDelta * scale;
                        if (
                            Array.isArray(results[newRow._id].interestRateYieldDeltaBuckets) &&
                            results[newRow._id].interestRateYieldDeltaBuckets.length === numberOfBuckets
                        ) {
                            for (let i = 0; i < numberOfBuckets; i++) {
                                newRow["bucket" + i] = results[newRow._id].interestRateYieldDeltaBuckets[i].value * scale;
                                buckets[i].value += results[newRow._id].interestRateYieldDeltaBuckets[i].value * scale;
                            }
                        }
                        newRow.zeroSpread = results[newRow._id].zeroSpread;
                    }
                    updatedTable.push(newRow);
                }
                if (stableStringify(table) !== stableStringify(updatedTable)) {
                    setTable(updatedTable);
                    if (!isValidRow(selectedRow)) {
                        setSelectedRow({ name: client.name, totalValue: totalValue, interestRateBuckets: buckets });
                        setFirstSelectedRow({ name: client.name, totalValue: totalValue, interestRateBuckets: buckets });
                    }
                }
            }
        } catch (e) {
            setErrorMessage(e.toString());
        }
    }, [baseCurrency, client, data, numberOfBuckets, sekDict, selectedRow, swaplab2Loaded, table, totalValue, yieldDeltaBuckets]);

    const localBucketColumns = [
        {
            header: periodToShortString(yieldDeltaBuckets[0].period),
            accessorKey: "bucket0",
            aggregationFn: "sum",
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.isCashAccount) return <div></div>;
                else return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
            },
            aggregatedCell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.isCashAccount) return <div></div>;
                else return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
            },
            size: 60
        }
    ];
    for (let i = 1; i < numberOfBuckets; i++) {
        localBucketColumns.push({
            header: periodToShortString(yieldDeltaBuckets[i].period),
            accessorKey: "bucket" + i,
            aggregationFn: "sum",
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.isCashAccount) return <div></div>;
                else return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
            },
            aggregatedCell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.isCashAccount) return <div></div>;
                else return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
            },
            size: 60
        });
    }
    const bucketColumns = localBucketColumns;
    const columns = React.useMemo(
        () => [
            {
                header: "Client",
                accessorKey: "clientName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <span className="hover__highlight__tree" onClick={() => setSelectedRow(firstSelectedRow)}>
                            {row.original.clientName}
                        </span>
                    );
                }
            },
            {
                header: "Type",
                accessorKey: "modelType",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const buckets: YieldDeltaBucket[] = [];
                    for (let i = 0; i < numberOfBuckets; i++) {
                        buckets.push({
                            period: { value: yieldDeltaBuckets[i].period.value, unit: yieldDeltaBuckets[i].period.unit },
                            value: row.leafRows.reduce((acc, cur) => acc + cur.original["bucket" + i], 0)
                        });
                    }
                    return (
                        <span
                            className="hover__highlight__tree"
                            onClick={() =>
                                setSelectedRow({
                                    name: row.original.modelType,
                                    totalValue: totalValue,
                                    interestRateBuckets: buckets
                                })
                            }
                        >
                            {row.original.modelType}
                        </span>
                    );
                }
            },
            {
                header: "Name",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const buckets: YieldDeltaBucket[] = [];
                    for (let i = 0; i < numberOfBuckets; i++) {
                        buckets.push({
                            period: { value: yieldDeltaBuckets[i].period.value, unit: yieldDeltaBuckets[i].period.unit },
                            value: row.original["bucket" + i]
                        });
                    }
                    return (
                        <span
                            className="hover__highlight__tree"
                            onClick={() =>
                                setSelectedRow({
                                    name: row.original.name,
                                    totalValue: totalValue,
                                    interestRateBuckets: buckets
                                })
                            }
                        >
                            {row.original.name}
                        </span>
                    );
                },
                size: 260
            },
            {
                header: "isCashAccount",
                accessorKey: "isCashAccount"
            },
            {
                header: "Position",
                accessorKey: "quantity",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => <div></div>,
                size: 80
            },
            {
                header: "Notional",
                accessorKey: "notional",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: () => <div></div>,
                size: 80
            },
            {
                header: "Value",
                accessorKey: "value",
                aggregationFn: "sum",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>,
                aggregatedCell: (cellProps) => <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>,
                size: 80
            },
            {
                header: "Zero Spread",
                accessorKey: "zeroSpread",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return row.original.modelType === InstrumentModelTypeEnum.Bond ? (
                        <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue() * 10000)}</div>
                    ) : (
                        <div></div>
                    );
                },
                aggregatedCell: () => <div></div>,
                size: 80
            },
            {
                id: "creditDuration",
                header: "Credit Duration",
                accessorFn: (row) => {
                    if (
                        (row.modelType === InstrumentModelTypeEnum.Bond ||
                            row.modelType === InstrumentModelTypeEnum.CdsIndex ||
                            row.modelType === InstrumentModelTypeEnum.CdsBasket) &&
                        (row.creditYieldDelta || row.creditYieldDelta === 0) &&
                        row.value
                    ) {
                        if (row.modelType === InstrumentModelTypeEnum.Bond) return (10000 * row.creditYieldDelta) / row.value;
                        else return (10000 * row.creditYieldDelta) / row.notional / sekDict[row.currency + baseCurrency];
                    } else return null;
                },
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value || value === 0) {
                        return <div style={{ textAlign: "right" }}>{twoDecPriceFormat(value)}</div>;
                    } else {
                        return <div></div>;
                    }
                },
                aggregatedCell: (cellProps) => {
                    const { row } = cellProps;
                    const creditYieldDelta = row.leafRows.reduce((acc, cur) => acc + cur.original.creditYieldDelta, 0);
                    const value = row.leafRows.reduce((acc, cur) => acc + cur.original.value, 0);
                    if (
                        row.groupingColumnId === "clientName" ||
                        (row.groupingColumnId === "modelType" &&
                            row.groupingValue === InstrumentModelTypeEnum.Bond &&
                            value &&
                            (creditYieldDelta || creditYieldDelta === 0))
                    ) {
                        return <div style={{ textAlign: "right" }}>{twoDecPriceFormat((10000 * creditYieldDelta) / value)}</div>;
                    } else {
                        return <div></div>;
                    }
                },
                size: 80
            },
            {
                id: "creditYieldDelta",
                header: "Credit Delta",
                accessorFn: (row) => {
                    if (
                        (row.modelType === InstrumentModelTypeEnum.Bond ||
                            row.modelType === InstrumentModelTypeEnum.CdsIndex ||
                            row.modelType === InstrumentModelTypeEnum.CdsBasket) &&
                        (row.creditYieldDelta || row.creditYieldDelta === 0)
                    ) {
                        return row.creditYieldDelta;
                    } else return null;
                },
                aggregationFn: "sum",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value || value === 0) {
                        return <div style={{ textAlign: "right" }}>{twoDecPriceFormat(value)}</div>;
                    } else return <div></div>;
                },
                aggregatedCell: (cellProps) => {
                    const { row } = cellProps;
                    const creditYieldDelta = row.leafRows.reduce((acc, cur) => acc + cur.original.creditYieldDelta, 0);
                    if (
                        (row.groupingColumnId === "clientName" ||
                            (row.groupingColumnId === "modelType" &&
                                (row.groupingValue === InstrumentModelTypeEnum.Bond ||
                                    row.groupingValue === InstrumentModelTypeEnum.CdsIndex ||
                                    row.modelType === InstrumentModelTypeEnum.CdsBasket))) &&
                        (creditYieldDelta || creditYieldDelta === 0)
                    ) {
                        return <div style={{ textAlign: "right" }}>{twoDecPriceFormat(creditYieldDelta)}</div>;
                    } else {
                        return <div></div>;
                    }
                },
                size: 80
            },
            {
                id: "modifiedDuration",
                header: "Modified Duration",
                accessorFn: (row) => {
                    if (
                        row.modelType === InstrumentModelTypeEnum.Bond &&
                        (row.interestRateYieldDelta || row.interestRateYieldDelta === 0) &&
                        row.value
                    ) {
                        return (10000 * row.interestRateYieldDelta) / row.value;
                    } else return null;
                },
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) return <div style={{ textAlign: "right" }}>{twoDecPriceFormat(value)}</div>;
                    else return <div></div>;
                },
                aggregatedCell: (cellProps) => {
                    const { row } = cellProps;
                    const interestRateYieldDelta = row.leafRows.reduce((acc, cur) => acc + cur.original.interestRateYieldDelta, 0);
                    const value = row.leafRows.reduce((acc, cur) => acc + cur.original.value, 0);
                    if (
                        (row.groupingColumnId === "clientName" ||
                            (row.groupingColumnId === "modelType" && row.groupingValue === InstrumentModelTypeEnum.Bond)) &&
                        (interestRateYieldDelta || interestRateYieldDelta === 0) &&
                        value
                    )
                        return <div style={{ textAlign: "right" }}>{twoDecPriceFormat((10000 * interestRateYieldDelta) / value)}</div>;
                    else return <div></div>;
                },
                size: 80
            },
            {
                header: "Yield Delta",
                accessorKey: "interestRateYieldDelta",
                aggregationFn: "sum",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.isCashAccount) return <div></div>;
                    else return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
                },
                aggregatedCell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.isCashAccount) return <div></div>;
                    return <div style={{ textAlign: "right" }}>{zeroDecPriceFormat(cellProps.getValue())}</div>;
                },
                size: 80
            },
            ...bucketColumns
        ],
        [bucketColumns, firstSelectedRow, numberOfBuckets, yieldDeltaBuckets, totalValue, sekDict, baseCurrency]
    );
    const expandedPositions: Record<string, boolean> = {};
    const hiddenColumns = ["isCashAccount"];
    const groupBy = ["clientName", "modelType"];

    if (loading) return <p>Loading</p>;
    if (!data) return <p>Loading</p>;
    if (!swaplab2Loaded) return <p>Loading SwapLab2</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    if (errorMessage) {
        return (
            <div>
                <pre>{errorMessage}</pre>
            </div>
        );
    }

    return (
        <div>
            <div className="row mb-3 mt-3">
                <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
            </div>

            <div className="row">
                <TableGrouper
                    columns={columns}
                    data={table}
                    expanded={expandedPositions}
                    groupBy={groupBy}
                    hiddenColumns={hiddenColumns}
                    compactMode={true}
                    customColumnSize={true}
                    captionColumn="name"
                />
            </div>
            <div className="row">
                {isValidRow(selectedRow) ? <InterestRateBuckets data={selectedRow} style={{ width: "100%", height: "50vh" }} /> : null}
            </div>
            <div className="row">
                <Link className="float-end" to={"/riskdocs"}>
                    {"documentation"}
                </Link>
            </div>
        </div>
    );
};
